<ng-container *transloco="let t">
  <form>
    <fudis-heading [level]="2">{{t('PLAN.CHOOSE_COMPLETION_METHOD' | transloco)}}</fudis-heading>
    <div class="modal-header">
      <sis-modal-close-button/>
    </div>
    <div class="modal-body">
      <fieldset>
        <legend class="guidance">{{ 'PLAN.CHOOSE_COMPLETION_METHOD_HELP' | transloco }}</legend>
        <ng-container *ngFor="let completionMethod of getCompletionMethods(); let index = index">
          <ng-container *ngIf="{label: getCompletionMethodLabel(completionMethod, index) | async} as data">
                <sis-radio-button [control]="selectCompletionMethod()"
                                  [value]="completionMethod.localId"
                                  [label]="data.label" class="d-block"></sis-radio-button>
                <ng-container *ngTemplateOutlet="completionMethodContent; context: { completionMethod: completionMethod, index: index, label: data.label }"></ng-container>
          </ng-container>
        </ng-container>
      </fieldset>
      <ng-template #completionMethodContent let-completionMethod="completionMethod" let-index="index">
        <div class="sis-mb-md">
          <div class="sis-pl-xl">
              <fudis-dl [variant]="'compact'">
                <fudis-dl-item *ngIf="completionMethod.typeOfRequire === 'OPTIONAL_WITH_REQUIRE_RANGE'" id="range-help-{{index}}">
                  <fudis-dt [textContent]="t('COMPLETION_METHODS_LIST.DETAIL_VIEW.REQUIRED_RANGE')"></fudis-dt>
                  <fudis-dd [textContent]="completionMethod.require | intRange"></fudis-dd>
                </fudis-dl-item>
                <fudis-dl-item *ngIf="completionMethod.typeOfRequire !== 'ALL_SELECTED_REQUIRED'" id="description-help-{{index}}">
                  <fudis-dt [textContent]="t('COMPLETION_METHODS_LIST.DETAIL_VIEW.INSTRUCTIONS')"></fudis-dt>
                  <fudis-dd *ngIf="completionMethod.assessmentItemOptionalityDescription as description;" [textContent]="description ? (description | localizedMarkupString) : t('COMPLETION_METHODS_LIST.DETAIL_VIEW.NO_INSTRUCTIONS')"></fudis-dd>
                </fudis-dl-item>
              </fudis-dl>
              <ul *ngIf="completionMethod.typeOfRequire === 'ALL_SELECTED_REQUIRED'; else requireSelection">
                <li *ngFor="let assessmentItemId of completionMethod.assessmentItemIds">
                  <div *ngIf="assessmentItemId | assessmentItem | async as assessmentItem">
                    <p>{{ getAssessmentItemLabel(assessmentItem) }}</p>
                  </div>
                </li>
              </ul>
            <ng-template #requireSelection>
              <ng-container *ngFor="let assessmentItemId of completionMethod.assessmentItemIds">
                <div *ngIf="assessmentItemId | assessmentItem | async as assessmentItem"  class="sis-mt-sm">
                  <sis-checkbox [label]="getAssessmentItemLabel(assessmentItem)"
                                [checked]="isAssessmentItemSelectedInCompletionMethod(assessmentItemId, completionMethod)"
                                (checkedChange)="assessmentItemCheckboxClicked($event, completionMethod, assessmentItemId)"
                                [ariaDescribedBy]="getAriaDescribedByIndex(index)">
                  </sis-checkbox>
                </div>
              </ng-container>
            </ng-template>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="modal-footer confirm-dialog-footer">
      <sis-button (clicked)="cancel()" [hollow]="true">{{ t('BUTTON.CANCEL') }}</sis-button>
      <sis-button (clicked)="submit()">{{ t('BUTTON.CONFIRM') }}</sis-button>
    </div>
  </form>
</ng-container>
