import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { DegreeProgrammeAttainmentApplication } from 'common-typescript/types';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-degree-programme-attainment-application-answers',
    templateUrl: './degree-programme-attainment-application-answers.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DegreeProgrammeAttainmentApplicationAnswersComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.applications.degreeProgrammeAttainmentApplication.degreeProgrammeAttainmentApplicationAnswers',
        directiveName: 'sisDegreeProgrammeAttainmentApplicationAnswers',
    };

    @Input() application: DegreeProgrammeAttainmentApplication;

}
