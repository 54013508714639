import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { EnrolmentUpdateByStudentRequest, OtmId } from 'common-typescript/types';
import { CourseUnitRealisation, Enrolment } from 'common-typescript/types/generated/common-backend';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { ModalService } from 'sis-common/modal/modal.service';
import { ENROLMENT_VALIDATION_SERVICE } from 'sis-components/ajs-upgraded-modules';
import { AlertsService, AlertType } from 'sis-components/alerts/alerts-ng.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { SisFormBuilder } from 'sis-components/form/sis-form-builder.service';
import { CourseUnitRealisationEntityService } from 'sis-components/service/course-unit-realisation-entity.service';

import { EnrolmentStudentService } from '../../../service/enrolment-student.service';

export interface ModifyGroupsModalValues {
    enrolment: Enrolment;
}

@Component({
    selector: 'app-modify-groups-modal',
    templateUrl: './modify-groups-modal.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ModifyGroupsModalComponent implements OnInit, OnDestroy {
    constructor(
        private appErrorHandler: AppErrorHandler,
        private courseUnitRealisationEntityService: CourseUnitRealisationEntityService,
        private enrolmentStudentService: EnrolmentStudentService,
        public modal: NgbActiveModal,
        @Inject(ModalService.injectionToken) private values: ModifyGroupsModalValues,
        private fb: SisFormBuilder,
        private alertsService: AlertsService,
        @Inject(ENROLMENT_VALIDATION_SERVICE) private enrolmentValidationService: any,
        private translateService: TranslateService,
    ) { }

    enrolment: Enrolment;
    courseUnitRealisation: CourseUnitRealisation;
    form: FormGroup;
    endDateTime: string;
    private destroyed$ = new Subject<void>();
    enrolledCountsBySsgId = new Map<OtmId, number>();
    confirmedGroupsBySsgId = new Map<OtmId, boolean>();
    saving = false;

    ngOnInit(): void {
        this.enrolment = this.values.enrolment;
        this.enrolment.confirmedStudySubGroupIds?.forEach(id => this.confirmedGroupsBySsgId.set(id, true));
        this.courseUnitRealisationEntityService.getById(this.enrolment.courseUnitRealisationId)
            .pipe(
                this.appErrorHandler.defaultErrorHandler(),
                takeUntil(this.destroyed$),
            )
            .subscribe(
                (cur) => {
                    this.courseUnitRealisation = cur;
                    this.endDateTime = this.enrolmentValidationService.resolveConfirmedStudySubGroupModificationEnd(cur);
                    this.initForm();
                },
            );

        this.enrolmentStudentService.getCalculationConfig(this.enrolment.courseUnitRealisationId)
            .pipe(
                this.appErrorHandler.defaultErrorHandler(),
                takeUntil(this.destroyed$),
                map(calculationConfigForPerson => calculationConfigForPerson?.enrolmentAllocationCounts?.studySubGroupAllocationCounts),
            )
            .subscribe(
                (studySubGroupAllocationCounts) => {
                    studySubGroupAllocationCounts?.forEach((allocationCount) => {
                        this.enrolledCountsBySsgId.set(allocationCount.studySubGroupId, allocationCount.enrolledCount);
                    });
                },
            );
    }

    initForm() {
        const confirmedStudySubGroupIds = this.enrolment.confirmedStudySubGroupIds || [];

        this.form = this.fb.group(
            {
                studyGroupSets: this.fb.array(
                    (this.courseUnitRealisation.studyGroupSets || []).map(sgs => this.fb.group(
                        {
                            studySubGroups: this.fb.group(_.chain(sgs.studySubGroups)
                                .keyBy('id')
                                .mapValues(ssg => !!confirmedStudySubGroupIds.find(id => id === ssg.id))
                                .value()),
                        },
                        {
                            validators: () => {
                                const selectedIds = this.selectedSsgIds();
                                if (this.enrolmentValidationService.areConfirmedStudySubGroupsValidInStudyGroupSet(sgs, selectedIds)) {
                                    return null;
                                }
                                return {
                                    studyGroupSetRulesOffended: { translationKey: 'ENROLMENT.MODIFY_GROUPS_MODAL.INVALID_SGS_SELECTIONS' },
                                };
                            },
                        },
                    ))),
            },
        );
    }

    private selectedSsgIdsForSgs(studyGroupSet: FormGroup) {
        const studySubGroups = studyGroupSet.get('studySubGroups') as FormGroup;
        return Object.keys(studySubGroups.controls).filter(id => studySubGroups.get(id).value);
    }

    private selectedSsgIds() {
        const studyGroupSets = this.form?.get('studyGroupSets') as FormArray;
        if (!!studyGroupSets) {
            return studyGroupSets.controls.flatMap(this.selectedSsgIdsForSgs);
        }
        return [];
    }

    save() {
        this.form.markAllAsTouched();
        if (!this.form.valid) {
            this.alertsService.addFormSubmissionFailureAlert();
            return;
        }
        this.saving = true;
        const studySubGroupIds = this.selectedSsgIds();
        const request: EnrolmentUpdateByStudentRequest = { studySubGroupIds, enrolmentId: this.enrolment.id };
        this.enrolmentStudentService.updateEnrolment(request)
            .pipe(
                this.appErrorHandler.defaultErrorHandler(),
                take(1),
            )
            .subscribe(
                (newEnrolment) => {
                    this.alertsService.addAlert({
                        message: this.translateService.instant('ENROLMENT.MODIFY_GROUPS_MODAL.SUCCESS_ALERT'),
                        type: AlertType.SUCCESS,
                    });
                    // This (return of newEnrolment) should not be needed when we have gotten rid of js-data
                    this.modal.close(newEnrolment);
                },
            );
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
    }

    get studyGroupSetFormArray(): FormArray {
        return this.form.get('studyGroupSets') as FormArray;
    }

    getControlForStudyGroupSet(sgsIndex: number): FormControl {
        return this.studyGroupSetFormArray.at(sgsIndex) as FormControl;
    }

    getControlForStudySubGroup(sgsIndex: number, ssgId: OtmId): FormControl {
        return this.studyGroupSetFormArray.at(sgsIndex).get('studySubGroups').get(ssgId) as FormControl;
    }
}
