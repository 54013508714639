import angular from 'angular';
(function () {
  angular.module('student.profile').config(["$stateProvider", $stateProvider => {
    const getProfile = function (studentProfileService) {
      return studentProfileService.profile();
    };
    getProfile.$inject = ["studentProfileService"];
    $stateProvider.state('student.logged-in.profile', {
      url: '/profile',
      resolve: {
        profile: getProfile
      },
      views: {
        '': {
          controller: ["profile", function (profile) {
            this.profile = profile;
          }],
          template: '<student-profile></student-profile>'
        },
        'title@student.logged-in.profile': {
          template: ''
        },
        'secondaryNavigation@student.logged-in.profile': {
          template: '<app-profile-secondary-navigation></app-profile-secondary-navigation>'
        }
      },
      data: {
        showHeader: true,
        showHome: true,
        hideLastNode: false,
        browserTabTitle: 'APPLICATION_STUDENT_PROFILE'
      },
      headerParams: {
        skipBreadcrumb: false,
        displayNameKey: 'MY_INFORMATION'
      },
      redirectTo: 'student.logged-in.profile.study-right'
    }).state('student.logged-in.profile.attainment', {
      url: '/attainments',
      resolve: {
        myAttainments: ["commonStudentAttainmentService", "attainmentTreeDataMapper", "$log", (commonStudentAttainmentService, attainmentTreeDataMapper, $log) => commonStudentAttainmentService.loadAttainmentTree(attainmentTreeDataMapper).catch(error => {
          $log.error('Exception while loading attainment tree', error);
        })]
      },
      template: '<ui-view></ui-view>',
      headerParams: {
        displayPageTitleNameKey: 'MY_INFORMATION',
        displayNameKey: 'PROFILE.ATTAINMENTS'
      },
      redirectTo: 'student.logged-in.profile.attainment.valid-attainments'
    }).state('student.logged-in.profile.attainment.valid-attainments', {
      url: '/valid-attainments',
      headerParams: {
        displayPageTitleNameKey: 'MY_INFORMATION',
        displayNameKey: 'PROFILE.ATTAINMENT.VALID_ATTAINMENTS'
      },
      controller: ["$scope", "attainmentTreeDataMapper", "myAttainments", function ($scope, attainmentTreeDataMapper, myAttainments) {
        $scope.allAttainments = myAttainments.allAttainments;
        $scope.attainmentsDisplayed = 'VALID';
        $scope.modules = myAttainments.modules;
        $scope.courseUnits = myAttainments.courseUnits;
        $scope.assessmentItems = myAttainments.assessmentItems;
      }],
      template: '<student-attainments modules="modules" course-units="courseUnits" ' + 'assessment-items="assessmentItems" ' + 'attainments-displayed="attainmentsDisplayed" ' + 'all-attainments="allAttainments"></student-attainments>'
    }).state('student.logged-in.profile.attainment.failed-and-expired-attainments', {
      url: '/failed-and-expired-attainments',
      headerParams: {
        displayPageTitleNameKey: 'MY_INFORMATION',
        displayNameKey: 'PROFILE.ATTAINMENT.FAILED_AND_EXPIRED_ATTAINMENTS'
      },
      controller: ["$scope", "attainmentTreeDataMapper", "myAttainments", function ($scope, attainmentTreeDataMapper, myAttainments) {
        $scope.allAttainments = myAttainments.allAttainments;
        $scope.attainmentsDisplayed = 'INVALID';
        $scope.modules = myAttainments.invalidModules;
        $scope.courseUnits = myAttainments.invalidCourseUnits;
        $scope.assessmentItems = myAttainments.invalidAssessmentItems;
      }],
      template: '<student-attainments modules="modules" course-units="courseUnits" ' + 'assessment-items="assessmentItems" ' + 'attainments-displayed="attainmentsDisplayed" ' + 'all-attainments="allAttainments"></student-attainments>'
    }).state('student.logged-in.profile.applications', {
      url: '/applications',
      headerParams: {
        displayNameKey: 'PROFILE.TAB_APPLICATIONS'
      },
      redirectTo: 'student.logged-in.profile.applications.list',
      template: '<ui-view></ui-view>',
      resolve: {
        codebooks: ["$q", "languageJSDataModel", "gradeScaleJSDataModel", ($q, languageJSDataModel, gradeScaleJSDataModel) => $q.all([languageJSDataModel.lazyGetAll(), gradeScaleJSDataModel.lazyGetAll()])]
      }
    });
  }]);
})();