import { inject, Injectable } from '@angular/core';
import { ValidatablePlan } from 'common-typescript';
import {
    AssessmentItem,
    Attainment,
    CourseUnit,
    CourseUnitAttainment,
    Education,
    Module,
    ModuleAttainment,
    ModuleContentApplication,
    OtmId,
    Plan,
    StudentApplication,
    StudyRight,
} from 'common-typescript/types';
import _ from 'lodash';
import moment from 'moment';
import { combineLatest, Observable, of, switchMap, take } from 'rxjs';
import { map } from 'rxjs/operators';

import { toChildAttainmentIds } from '../attainment/AttainmentUtil';
import { AppErrorHandler } from '../error-handler/app-error-handler';
import { AssessmentItemEntityService } from '../service/assessment-item-entity.service';
import { AttainmentEntityService } from '../service/attainment-entity.service';
import { CourseUnitEntityService } from '../service/course-unit-entity.service';
import { EducationEntityService } from '../service/education-entity.service';
import { ModuleEntityService } from '../service/module-entity.service';
import { PlanEntityService } from '../service/plan-entity.service';
import { StudentApplicationEntityService } from '../service/student-application-entity.service';
import { StudyRightEntityService } from '../service/study-right-entity.service';

@Injectable({
    providedIn: 'root',
})
export class PlanLoaderService {

    private readonly moduleEntityService = inject(ModuleEntityService);
    private readonly educationEntityService = inject(EducationEntityService);
    private readonly studyRightEntityService = inject(StudyRightEntityService);
    private readonly attainmentEntityService = inject(AttainmentEntityService);
    private readonly studentApplicationEntityService = inject(StudentApplicationEntityService);
    private readonly courseUnitEntityService = inject(CourseUnitEntityService);
    private readonly assessmentItemEntityService = inject(AssessmentItemEntityService);
    private readonly planEntityService = inject(PlanEntityService);

    private readonly appErrorHandler = inject(AppErrorHandler);

    createValidatablePlan(planId: OtmId, studyRightId?: OtmId): Observable<ValidatablePlan> {
        if (!planId) {
            return of();
        }

        return this.planEntityService.getById(planId)
            .pipe(
                switchMap(fetchedPlan => this.getPlanData(fetchedPlan, studyRightId)),
                take(1),
                this.appErrorHandler.defaultErrorHandler(),
                map(([education, studentApplications, assItems, studyRight, [attainments, courseUnits, modules], fetchedPlan]) => this.initValidatablePlan(fetchedPlan, attainments, education, modules, courseUnits, assItems, studentApplications, studyRight)),
            );
    }

    initValidatablePlan(plan: Plan,
                        attainments: Attainment[],
                        education: Education,
                        modules: Module[],
                        courseUnits: CourseUnit[],
                        assItems: AssessmentItem[],
                        studentApplications: StudentApplication[],
                        studyRight: StudyRight,
    ): ValidatablePlan {
        // Akita doesn't allow object mutations, but ValidatablePlan -process will mutate
        // entities, so to handle this we got to deep clone some entities.
        return new ValidatablePlan(
            _.cloneDeep(plan),
            attainments,
            education,
            modules,
            _.cloneDeep(courseUnits),
            assItems,
            studentApplications as ModuleContentApplication[],
            studyRight,
        );
    }

    private getPlanData(plan: Plan, studyRightId?: OtmId) {
        if (!plan) {
            return of();
        }

        return combineLatest([
            this.educationEntityService.getById(plan.rootId),
            this.studentApplicationEntityService.getApplicationsForStudentByTypes(
                plan.userId,
                ['CUSTOM_MODULE_CONTENT_APPLICATION', 'REQUIRED_MODULE_CONTENT_APPLICATION'],
            ),
            this.assessmentItemEntityService.getByIds(plan?.assessmentItemSelections?.map(assItemSelection => assItemSelection?.assessmentItemId)),
            this.getStudyRight(plan, studyRightId),
            this.getAttainmentsModulesAndCourseUnits(plan),
            of(plan),
        ]);
    }

    private getAttainmentsModulesAndCourseUnits(plan: Plan): Observable<[Attainment[], CourseUnit[], Module[]]> {
        return this.attainmentEntityService.findForPerson(plan.userId, {
            attainmentState: ['ATTAINED', 'INCLUDED', 'SUBSTITUTED'],
            misregistration: false,
            primary: true,
        })
            .pipe(
                map((attainments: Attainment[]) => this.collectValidAttainments(attainments)),
                switchMap((attainments: Attainment[]) => combineLatest([
                    of(attainments),
                    this.getCourseUnits(attainments, plan),
                    this.getModules(attainments, plan),
                ]),
                ),
            );
    }

    // This methods logic is based on this service: frontend-angular/projects/sis-components/webapp/lib/service/validAttainmentFilter.service.js
    // 1. Remove "expired" attainments.
    // 2. Add attached attainments (e.g.: module can have cu:s attached to it), Note: these can be previously removed "expired" attainments, so those are brought
    // back to validAttainments (I don't know if this is a correct way, but it's how it has been done for years).
    private collectValidAttainments(attainments: Attainment[]): Attainment[] {
        const validAttainments = attainments?.filter(attainment => !(attainment.expiryDate && moment(attainment.expiryDate).isSameOrBefore(moment(), 'days')));

        const attachedAttainments: Attainment[] = [];
        validAttainments.forEach(validAtt => this.collectValidAttainmentsRecursively(validAtt, attainments, attachedAttainments));
        return _.unionBy(validAttainments, attachedAttainments, 'id');
    }

    private collectValidAttainmentsRecursively(attainment: Attainment, allAttainments: Attainment[], attachedAttainments: Attainment[]) {
        _.chain(toChildAttainmentIds(attainment))
            .map((childAttainmentId: string) => allAttainments.find(att => att.id === childAttainmentId))
            .compact()
            .forEach((attainmentObject: Attainment) => {
                this.collectValidAttainmentsRecursively(attainmentObject, allAttainments, attachedAttainments);
                attachedAttainments.push(attainmentObject);
            })
            .value();
    }

    private getCourseUnits(attainments: Attainment[], plan: Plan): Observable<CourseUnit[]> {
        const attainmentCuIds: string[] = attainments.filter(att => att.type === 'CourseUnitAttainment')
            .map((att: CourseUnitAttainment) => att.courseUnitId);
        const planSelectionCuIds: string[] = plan.courseUnitSelections.map(cuSelection => cuSelection.courseUnitId);
        const cuIds = [...attainmentCuIds, ...planSelectionCuIds];
        const uniqIds = _.uniq(cuIds ?? []).filter(Boolean);
        return this.courseUnitEntityService.getByIds(uniqIds);
    }

    private getModules(attainments: Attainment[], plan: Plan): Observable<Module[]> {
        const attainmentModuleIds: string[] = attainments.filter(att => att.type === 'ModuleAttainment' || att.type === 'DegreeProgrammeAttainment')
            .map((att: ModuleAttainment) => att.moduleId);
        const planSelectionModuleIds: string[] = plan.moduleSelections?.filter(moduleSelection => moduleSelection?.parentModuleId)
            .map(moduleSelection => moduleSelection.moduleId);
        const moduleIds = [...attainmentModuleIds, ...planSelectionModuleIds];
        const uniqIds = _.uniq(moduleIds ?? []).filter(Boolean);
        return this.moduleEntityService.getByIds(uniqIds);
    }

    private getStudyRight(plan: Plan, studyRightId?: OtmId): Observable<StudyRight> {
        return this.studyRightEntityService.findStudyRightsForPlan(plan)
            .pipe(
                map(studyRights => studyRightId ?
                    studyRights.find(studyRight => studyRight.id === studyRightId) :
                    studyRights[0],
                ));
    }
}
