import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Education, OtmId, Plan, PlanValidationState } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';

export interface StudyRightExtensionRequirementsValues {
    studyRightExtensionRequirements: {
        planState?: PlanValidationState;
        primaryPlan?: Plan;
        education: Education;
        courseUnitIdsWithNoTiming: OtmId[];
        courseUnitIdsWithTimingInThePast: OtmId[];
        customStudyDraftIdsWithNoTiming: OtmId[];
        customStudyDraftIdsWithTimingInThePast: OtmId[];
    };
}

@Component({
    selector: 'app-study-right-extension-requirements-modal',
    template: '<study-right-extension-requirements-modal (close)="activeModal.close($event ? $event.$value : null)" [resolve]="values"></study-right-extension-requirements-modal>',
    encapsulation: ViewEncapsulation.None,
})
export class StudyRightExtensionRequirementsModalComponent {
    constructor(@Inject(ModalService.injectionToken) public values: StudyRightExtensionRequirementsValues,
                public activeModal: NgbActiveModal) {}
}
