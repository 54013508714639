<div ngbDropdown #dropdown=ngbDropdown>
    <button ngbDropdownToggle
            id="search-filter-options-dropdown-button"
            (keydown)="onKeyboardButtonInteraction($event)"
            data-cy="search-filter-menu"
            type="button"
            class="btn btn-lg btn-search form-control"
            [class.disabled]="disable"
            [attr.aria-label]="'ARIA_LABEL.FILTER_SEARCH' | translate">
        {{(mainButtonKey || 'SEARCH.FILTER_TAGS.FILTER') | translate}}
        <sis-icon icon="chevron" iconClass="cw-90"></sis-icon>
    </button>
    <div href [tabIndex]="-1" ngbDropdownMenu class="dropdown-menu-right" role="menu"
         id="search-filter-options-dropdown-menu">
        <ng-container *ngFor="let group of menuItemGroups">
            <div class="dropdown-header">{{group.titleName | translate}}</div>
            <search-filter-menu-item ngbDropdownItem
                                     *ngFor="let parameterKey of group.parameterKeys"
                                     [id]="parameterKey"
                                     (keydown.escape)="$event.stopPropagation()"
                                     (keydown.enter)="$event.preventDefault()"
                                     (keydown)="onKeyboardMenuInteraction($event)"
                                     [option]="searchParameterOptions[parameterKey]"
                                     [parameterModel]="searchParams[parameterKey]"
                                     [searchParams]="searchParams"
                                     [university]="university"
                                     (onSelect)="onSelect(parameterKey, $event.values)"
                                     (searchAgain)="onSearchAgain()"
                                     role="menuitem">
            </search-filter-menu-item>
        </ng-container>
    </div>
</div>
