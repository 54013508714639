import angular from 'angular';
import _ from 'lodash';
import angularTranslate from 'angular-translate';
import moment from 'moment';
import { ISO_LOCAL_DATE_TIME_FORMAT } from 'common-typescript/constants';
import { dateUtils } from 'common-typescript';
export const enrolmentValidationServiceModule = 'sis-components.service.enrolmentValidation';
(function () {
  enrolmentValidationService.$inject = ["EnrolmentStudySubGroupPriority", "$translate"];
  angular.module(enrolmentValidationServiceModule, [angularTranslate]).constant('EnrolmentStudySubGroupPriority', {
    PRIMARY: 'PRIMARY',
    SUITABLE: 'SUITABLE',
    NOT_SUITABLE: 'NOT_SUITABLE'
  }).factory('enrolmentValidationService', enrolmentValidationService);

  /**
   * I would rather pass studyGroupSets array as separate argument to api methods instead of assuming that
   * enrolment contains course unit realisation etc, as it would make it more clear that which methods really need
   * the array and also the unit testing would be simpler. Unfortunately I don't have time to refactor ATM.
   */
  function enrolmentValidationService(EnrolmentStudySubGroupPriority, $translate) {
    const pPrimary = EnrolmentStudySubGroupPriority.PRIMARY;
    const pSuitable = EnrolmentStudySubGroupPriority.SUITABLE;
    const pNotSuitable = EnrolmentStudySubGroupPriority.NOT_SUITABLE;
    const priorities = [pPrimary, pSuitable, pNotSuitable];
    function sgsToCancelledGroupPriorities(studyGroupSet, studySubGroupPriorityMap) {
      return _.chain(sgsToCancelledGroups(studyGroupSet)).map('id').map(ssgId => _.get(studySubGroupPriorityMap, ssgId)).value();
      function sgsToCancelledGroups(sgs) {
        return _.filter(_.get(sgs, 'studySubGroups'), 'cancelled');
      }
    }
    function sgsToNonCancelledGroups(sgs) {
      return _.reject(_.get(sgs, 'studySubGroups'), 'cancelled');
    }
    function sgsToNonCancelledGroupPriorities(studyGroupSet, studySubGroupPriorityMap) {
      return _.chain(sgsToNonCancelledGroups(studyGroupSet)).map('id').map(ssgId => _.get(studySubGroupPriorityMap, ssgId)).value();
    }
    function isValidNumberOfGroupsForStudyGroupSet(studyGroupSet, count) {
      return (!studyGroupSet.subGroupRange.min || count >= studyGroupSet.subGroupRange.min) && (!studyGroupSet.subGroupRange.max || count <= studyGroupSet.subGroupRange.max);
    }
    function getValidPriorities(studySubGroup, studyGroupSet) {
      if (studySubGroup.cancelled) {
        return [pNotSuitable];
      }
      if (nonCancelledShouldBeSelectedAsPrimary(studyGroupSet)) {
        return [pPrimary];
      }
      return [pPrimary, pSuitable, pNotSuitable];
    }
    function defaultPriorityDropdownOptions() {
      return _.map(priorities, priority => ({
        value: priority,
        label: $translate.instant(`ENROLMENT.STUDY_SUB_GROUP_PRIORITY.${priority}`)
      }));
    }
    function nonCancelledShouldBeSelectedAsPrimary(studyGroupSet) {
      const {
        min
      } = studyGroupSet.subGroupRange;
      return _.isNumber(min) && _.size(sgsToNonCancelledGroups(studyGroupSet)) <= min;
    }
    const api = {
      /**
       * Creates <ssgId, priority dropdown options> map from study sub groups in course unit realisation. Will add info property
       * for the priority option that is selected. These options should work with dropdownSelect component. It also disables options
       * that will always be invalid.
       */
      createDropdownOptionsForStudySubGroups(courseUnitRealisation, enrolment) {
        const studySubGroupPriorityMap = api.getSavedEnrolmentPrioritiesBySsgId(enrolment);
        return _.chain(_.get(courseUnitRealisation, 'studyGroupSets')).flatMap(sgs => _.map(sgs.studySubGroups, ssg => ({
          sgs,
          ssg
        }))).keyBy('ssg.id').mapValues(pair => {
          const options = defaultPriorityDropdownOptions();
          setChosenInfo(pair.ssg, options);
          setDisabledOptions(pair.ssg, pair.sgs, options);
          return options;
        }).value();
        function setChosenInfo(ssg, options) {
          const priority = _.get(studySubGroupPriorityMap, ssg.id);
          if (_.includes(priorities, priority)) {
            const option = _.find(options, {
              value: priority
            });
            option.info = $translate.instant('ENROLMENT.CHOSEN');
          }
        }
        function setDisabledOptions(ssg, sgs, options) {
          const validPriorities = getValidPriorities(ssg, sgs);
          _.chain(options).reject(option => _.includes(validPriorities, option.value)).forEach(option => {
            option.disabled = true;
          }).value();
        }
      },
      createTargetStudySubGroupOptions(courseUnitRealisation, enrolment) {
        return _.chain(_.get(courseUnitRealisation, 'studyGroupSets')).filter(studyGroupSet => studyGroupSet.subGroupRange?.min >= 0 && studyGroupSet.subGroupRange?.min !== studyGroupSet.subGroupRange?.max).keyBy('localId').mapValues(sgs => {
          const targetStudySubGroupAmount = enrolment?.studyGroupSets?.find(esgs => esgs.localId === sgs.studyGroupSetId)?.targetStudySubGroupAmount;
          const maxStudySubGroupAmount = sgs.subGroupRange?.max ? sgs.subGroupRange?.max : _.size(sgsToNonCancelledGroups(sgs));
          return _.range(sgs.subGroupRange?.min, maxStudySubGroupAmount + 1).map(index => ({
            value: index,
            label: _.toString(index),
            info: targetStudySubGroupAmount === index ? $translate.instant('ENROLMENT.CHOSEN') : undefined
          }));
        }).value();
      },
      /**
       * Using 'saved' in the function name to underline the fact that this function does not ass-pull priorities
       * if selection is missing.
       */
      getSavedEnrolmentPrioritiesBySsgId(enrolment) {
        return _.chain(enrolment).get('studySubGroups').keyBy('studySubGroupId').mapValues('enrolmentStudySubGroupPriority').value();
      },
      getSavedTargetStudySubGroupAmountsBySgsId(enrolment) {
        return _.chain(enrolment).get('studyGroupSets').keyBy('studyGroupSetId').mapValues('targetStudySubGroupAmount').value();
      },
      /**
       * Creates <sgsId, targetSubGroupAmount> map with corresponding entries for all studyGroupSets that have a subGroupRange
       */
      createEnrolmentStudyGroupSetMap(enrolment, courseUnitRealisation) {
        return _.chain(courseUnitRealisation?.studyGroupSets).filter(sgs => sgs.subGroupRange?.min !== sgs.subGroupRange?.max).keyBy('localId').mapValues(sgs => {
          const current = _.find(enrolment?.studyGroupSets, esgs => esgs.studyGroupSetId === sgs.localId);
          const maxStudySubGroupAmount = sgs.subGroupRange?.max ? sgs.subGroupRange?.max : _.size(sgsToNonCancelledGroups(sgs));
          if (current?.targetStudySubGroupAmount >= sgs.subGroupRange.min && current?.targetStudySubGroupAmount <= maxStudySubGroupAmount) {
            return current.targetStudySubGroupAmount;
          }
          return undefined;
        }).value();
      },
      /**
       * Creates <ssgId, priority> map with corresponding entries for all studySubGroups. Those entries that
       * are 'missing' in enrolment are created with priority value 'NOT_SUITABLE'.
       */
      createEnrolmentStudySubGroupPriorityMap(enrolment, courseUnitRealisation) {
        const priorityMap = api.getSavedEnrolmentPrioritiesBySsgId(enrolment);
        // Add the only valid priority or 'NOT_SUITABLE' for missing study sub groups
        _.chain(courseUnitRealisation.studyGroupSets).flatMap(sgs => _.map(sgs.studySubGroups, ssg => ({
          sgs,
          ssg
        }))).filter(pair => !_.has(priorityMap, pair.ssg.id)).forEach(pair => {
          const validPriorities = getValidPriorities(pair.ssg, pair.sgs);
          priorityMap[pair.ssg.id] = _.size(validPriorities) === 1 ? _.head(validPriorities) : pNotSuitable;
        }).value();
        return priorityMap;
      },
      /**
       * Creates <ssgId, boolean> map by deducing if the selected priority in priorityMap is the only valid option for the ssg.
       * @param priorityMap a map given by createEnrolmentStudySubGroupPriorityMap method
       * @param courseUnitRealisation
       * @returns Map of type <ssgId, boolean>
       */
      createIsEditableBySsgIdMap(priorityMap, courseUnitRealisation) {
        return _.chain(_.get(courseUnitRealisation, 'studyGroupSets')).flatMap(sgs => _.map(sgs.studySubGroups, ssg => ({
          sgs,
          ssg
        }))).keyBy('ssg.id').mapValues(pair => {
          const validPriorities = getValidPriorities(pair.ssg, pair.sgs);
          if (_.size(validPriorities) !== 1) {
            return true;
          }
          return _.head(validPriorities) !== _.get(priorityMap, pair.ssg.id);
        }).value();
      },
      /**
       * Counts only non cancelled primary groups.
       */
      selectedPrimarySubGroupsCount(studySubGroupPriorityMap, studyGroupSet) {
        return _.chain(sgsToNonCancelledGroupPriorities(studyGroupSet, studySubGroupPriorityMap)).filter(priority => priority === pPrimary).size().value();
      },
      /**
       * Counts non cancelled primary and suitable groups.
       */
      selectedSuitableAndPrimarySubGroupsCount(studySubGroupPriorityMap, studyGroupSet) {
        return _.chain(sgsToNonCancelledGroupPriorities(studyGroupSet, studySubGroupPriorityMap)).filter(priority => _.includes([pSuitable, pPrimary], priority)).size().value();
      },
      /**
       * Check that there are correct number non cancelled groups are selected for study group set.
       * Check that every cancelled group is selected as NOT_SUITABLE.
       */
      validateStudySubGroupSelectionForStudyGroupSet(studySubGroupPriorityMap, studyGroupSet, studyGroupSetSelections) {
        const allCancelledNotPrimaryOrSuitable = api.areCancelledSubGroupPrioritiesNotPrimaryOrSuitable(studySubGroupPriorityMap, studyGroupSet);
        const selectedFromSubGroup = api.selectedSuitableAndPrimarySubGroupsCount(studySubGroupPriorityMap, studyGroupSet);
        const studyGroupSetTargetAmount = _.get(studyGroupSetSelections, studyGroupSet.localId) ? _.get(studyGroupSetSelections, studyGroupSet.localId) : studyGroupSet.subGroupRange.min;
        return selectedFromSubGroup >= studyGroupSet.subGroupRange.min && selectedFromSubGroup >= studyGroupSetTargetAmount && allCancelledNotPrimaryOrSuitable;
      },
      /**
       * Checking that not "primary or suitable" because we are ok with undefined selection for cancelled group
       */
      areCancelledSubGroupPrioritiesNotPrimaryOrSuitable(studySubGroupPriorityMap, studyGroupSet) {
        return _.every(sgsToCancelledGroupPriorities(studyGroupSet, studySubGroupPriorityMap), priority => !_.includes([pSuitable, pPrimary], priority));
      },
      /**
       * Check that there are correct number of selections made for all study group sets.
       */
      validateStudySubGroupSelections(studySubGroupPriorityMap, studyGroupSets) {
        if (!studyGroupSets) {
          return false;
        }
        return _.every(studyGroupSets, studyGroupSet => api.validateStudySubGroupSelectionForStudyGroupSet(studySubGroupPriorityMap, studyGroupSet));
      },
      /**
       * Check that there are correct number of selections made for all study group sets.
       * Returns the invalid studyGroupSet ids instead of boolean
       */
      validateStudySubGroupSelectionsAndReturnInvalidStudyGroupSetIds(studySubGroupPriorityMap, studyGroupSets) {
        if (!studyGroupSets) {
          return [];
        }
        return studyGroupSets.filter(studyGroupSet => !api.validateStudySubGroupSelectionForStudyGroupSet(studySubGroupPriorityMap, studyGroupSet)).map(invalidSgsList => invalidSgsList.localId);
      },
      /**
       * Check that there are correct number of selections made for all study group sets.
       * Returns the invalid studyGroupSet ids instead of boolean
       */
      validateStudyGroupSetsAndReturnInvalidStudyGroupSetIds(studySubGroupSelections, studyGroupSets, studyGroupSetSelections) {
        if (!studyGroupSets) {
          return [];
        }
        return studyGroupSets.filter(studyGroupSet => !api.validateStudySubGroupSelectionForStudyGroupSet(studySubGroupSelections, studyGroupSet, studyGroupSetSelections)).map(invalidSgsList => invalidSgsList.localId);
      },
      /**
       * Check that all priority selections are same in priorityMap and enrolment. Selections are checked only for
       * ssgs that are found in cur.
       */
      studySubGroupSelectionsAreSame(studySubGroupPriorityMap, enrolment, courseUnitRealisation) {
        const originalMap = api.getSavedEnrolmentPrioritiesBySsgId(enrolment);
        return _.chain(_.get(courseUnitRealisation, 'studyGroupSets')).flatMap('studySubGroups').map('id').every(ssgId => _.get(studySubGroupPriorityMap, ssgId) === _.get(originalMap, ssgId)).value();
      },
      studyGroupSetSelectionsAreSame(studyGroupSetMap, enrolment, courseUnitRealisation) {
        const originalMap = api.getSavedTargetStudySubGroupAmountsBySgsId(enrolment);
        return _.chain(_.get(courseUnitRealisation, 'studyGroupSets')).map('localId').every(sgsId => _.get(studyGroupSetMap, sgsId) === _.get(originalMap, sgsId)).value();
      },
      /**
       * Set priority selections from priority map to enrolment
       */
      assignNewPrioritiesToEnrolment(studySubGroupPriorityMap, enrolment) {
        enrolment.studySubGroups = api.constructEnrolmentStudySubGroups(studySubGroupPriorityMap, enrolment);
        return enrolment;
      },
      /**
       * Construct enrolmentStudySubGroups by copying existing array and assigning new priorities to each item.
       */
      constructEnrolmentStudySubGroups(studySubGroupPriorityMap, enrolment) {
        const studySubGroupMap = _.keyBy(enrolment.studySubGroups, 'studySubGroupId');
        _.forEach(_.keys(studySubGroupPriorityMap), ssgId => {
          const ssg = _.get(studySubGroupMap, ssgId, {
            studySubGroupId: ssgId,
            isInCalendar: false
          });
          ssg.enrolmentStudySubGroupPriority = studySubGroupPriorityMap[ssgId];
          studySubGroupMap[ssgId] = ssg;
        });
        return _.values(studySubGroupMap);
      },
      constructEnrolmentStudyGroupSets(studyGroupSetMap) {
        return _.map(studyGroupSetMap, (amount, id) => ({
          studyGroupSetId: id,
          targetStudySubGroupAmount: amount
        }));
      },
      /**
       * Automatically select all study sub groups that can be automatically selected with PRIMARY priority
       * Set other study sub groups as NOT_SUITABLE because calendar view will create missing study sub
       * groups. So this is to reduce the amount of rest calls.
       */
      createStudySubGroupsForEnrolment(courseUnitRealisation) {
        return _.chain(_.get(courseUnitRealisation, 'studyGroupSets')).flatMap(studyGroupSet => _.map(studyGroupSet.studySubGroups, studySubGroup => {
          const validPriorities = getValidPriorities(studySubGroup, studyGroupSet);
          const priority = _.size(validPriorities) > 1 ? pNotSuitable : _.head(validPriorities);
          return {
            studySubGroupId: studySubGroup.id,
            isInCalendar: priority === pPrimary,
            enrolmentStudySubGroupPriority: priority
          };
        })).value();
      },
      createStudyGroupSetsForEnrolment(courseUnitRealisation) {
        return _.chain(_.get(courseUnitRealisation, 'studyGroupSets')).filter(studyGroupSet => studyGroupSet.subGroupRange?.min !== studyGroupSet.subGroupRange?.max).flatMap(studyGroupSet => ({
          studyGroupSetId: studyGroupSet.localId,
          targetStudySubGroupAmount: undefined
        })).value();
      },
      /**
       * Counts number of study sub group ids in confirmedStudySubGroupIds that belong to this studyGroupSet and check subGroupRange
       * rule with that number. In other words the confirmedStudySubGroupIds can contain id of a group that does not belong to this
       * study group set.
       */
      areConfirmedStudySubGroupsValidInStudyGroupSet(studyGroupSet, confirmedStudySubGroupIds) {
        const confirmedStudySubGroupsCount = _.chain(sgsToNonCancelledGroups(studyGroupSet)).filter(ssg => _.includes(confirmedStudySubGroupIds, ssg.id)).size().value();
        return isValidNumberOfGroupsForStudyGroupSet(studyGroupSet, confirmedStudySubGroupsCount);
      },
      /**
       * Confirmed study sub groups are valid if for each study group set the confirmedStudySubGroupIds array is valid.
       */
      areConfirmedStudySubGroupsValid(courseUnitRealisation, confirmedStudySubGroupIds) {
        return _.every(courseUnitRealisation.studyGroupSets, sgs => api.areConfirmedStudySubGroupsValidInStudyGroupSet(sgs, confirmedStudySubGroupIds));
      },
      /**
       * Translation for staff. If you change this logic, update the Angular `EnrolmentValidationService.getSGSAllocationRuleType()`
       * as well.
       */
      getSgsAllocationRuleTranslationKey: studyGroupSet => {
        const min = _.get(studyGroupSet, 'subGroupRange.min');
        const allSubGroupsCancelled = _.every(studyGroupSet?.studySubGroups, ssg => ssg.cancelled);
        if (_.isNil(min)) {
          // studyGroupSet or studyGroupSet.subGroupRange is undefined
          return undefined;
        }
        const max = _.get(studyGroupSet, 'subGroupRange.max');
        if (!_.isNumber(max)) {
          return min === 0 ? 'COURSE_UNIT_REALISATION.ALLOCATION_RULE.0_NULL' : 'COURSE_UNIT_REALISATION.ALLOCATION_RULE.X_NULL';
        }
        if (max === 0 && _.size(studyGroupSet.studySubGroups) > 0 && !allSubGroupsCancelled) {
          return 'COURSE_UNIT_REALISATION.ALLOCATION_RULE.0_0_HAS_GROUPS';
        }
        if (max === 0) {
          return 'COURSE_UNIT_REALISATION.ALLOCATION_RULE.0_0';
        }
        if (min < max) {
          return 'COURSE_UNIT_REALISATION.ALLOCATION_RULE.X_Y';
        }
        if (min === _.size(studyGroupSet.studySubGroups)) {
          return 'COURSE_UNIT_REALISATION.ALLOCATION_RULE.X_X_GROUP_COUNT_X';
        }
        return 'COURSE_UNIT_REALISATION.ALLOCATION_RULE.X_X';
      },
      /**
       * Translation for staff.
       */
      getSgsAllocationRuleTranslationValues: studyGroupSet => {
        const min = _.get(studyGroupSet, 'subGroupRange.min');
        if (_.isNil(min)) {
          // studyGroupSet or studyGroupSet.subGroupRange is undefined
          return {};
        }
        return {
          min,
          max: studyGroupSet.subGroupRange.max,
          groupCount: studyGroupSet.studySubGroups ? studyGroupSet.studySubGroups.length : undefined
        };
      },
      /**
       * Translation for student.
       */
      getSgsSelectionRuleTranslationKey: studyGroupSet => {
        const min = _.get(studyGroupSet, 'subGroupRange.min');
        if (_.isNil(min)) {
          // studyGroupSet or studyGroupSet.subGroupRange is undefined
          return undefined;
        }
        const max = _.get(studyGroupSet, 'subGroupRange.max');
        if (!_.isNumber(max)) {
          return 'COURSE_UNIT_REALISATION.SELECTION_RULE.X_NULL';
        }
        if (max === 0) {
          return 'COURSE_UNIT_REALISATION.SELECTION_RULE.0_0';
        }
        if (min === 0) {
          return 'COURSE_UNIT_REALISATION.SELECTION_RULE.0_X';
        }
        if (min < max) {
          return 'COURSE_UNIT_REALISATION.SELECTION_RULE.X_Y';
        }
        if (min === _.size(studyGroupSet.studySubGroups)) {
          return 'COURSE_UNIT_REALISATION.SELECTION_RULE.X_X_GROUP_COUNT_X';
        }
        return 'COURSE_UNIT_REALISATION.SELECTION_RULE.X_X';
      },
      /**
       * Translation for student.
       */
      getSgsSelectionRuleTranslationValues: (studyGroupSet, studySubGroupPriorityMap) => {
        const min = _.get(studyGroupSet, 'subGroupRange.min');
        if (_.isNil(min)) {
          // studyGroupSet or studyGroupSet.subGroupRange is undefined
          return {};
        }
        const selected = min === studyGroupSet.subGroupRange.max ? api.selectedSuitableAndPrimarySubGroupsCount(studySubGroupPriorityMap, studyGroupSet) : api.selectedPrimarySubGroupsCount(studySubGroupPriorityMap, studyGroupSet);
        return {
          min,
          max: studyGroupSet.subGroupRange.max,
          selected
        };
      },
      resolveConfirmedStudySubGroupModificationEnd(courseUnitRealisation) {
        const endDate = _.get(courseUnitRealisation.activityPeriod, 'endDate');
        return dateUtils.minDateTime(endDate ? moment(endDate).format(ISO_LOCAL_DATE_TIME_FORMAT) : null, courseUnitRealisation.confirmedStudySubGroupModificationEnd);
      }
    };
    return api;
  }
})();