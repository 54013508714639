<sis-plan-structure-course-unit-count-rule *ngIf="_rule.type === 'CourseUnitCountRule'"
                                           [parentModule]="parentModule"
                                           [headingLevel]="headingLevel"
                                           [rule]="_rule"
                                           [planData]="planData"
                                           [planRuleData]="planRuleData"
                                           [planStateObject]="planStateObject"
                                           [planValidationResult]="planValidationResult"
                                           [validatablePlan]="validatablePlan"
                                           [groupPrefix]="groupPrefix"
                                           [selectionsDisabled]="selectionDisabled"
                                           [ruleDepthLevel]="ruleDepthLevel"/>
<sis-plan-structure-credits-rule *ngIf="_rule.type === 'CreditsRule'"
                                 [parentModule]="parentModule"
                                 [headingLevel]="headingLevel"
                                 [rule]="_rule"
                                 [planData]="planData"
                                 [planRuleData]="planRuleData"
                                 [planStateObject]="planStateObject"
                                 [planValidationResult]="planValidationResult"
                                 [validatablePlan]="validatablePlan"
                                 [groupPrefix]="groupPrefix"
                                 [selectionsDisabled]="selectionDisabled"
                                 [ruleDepthLevel]="ruleDepthLevel"/>
<sis-plan-structure-composite-rule *ngIf="_rule.type === 'CompositeRule'"
                                   [parentModule]="parentModule"
                                   [headingLevel]="headingLevel"
                                   [rule]="_rule"
                                   [planData]="planData"
                                   [planRuleData]="planRuleData"
                                   [planStateObject]="planStateObject"
                                   [planValidationResult]="planValidationResult"
                                   [validatablePlan]="validatablePlan"
                                   [groupPrefix]="groupPrefix"
                                   [selectionsDisabled]="selectionDisabled"
                                   [ruleDepthLevel]="ruleDepthLevel"/>
<sis-plan-structure-course-unit-rule *ngIf="_rule.type === 'CourseUnitRule'"
                                     [parentModule]="parentModule"
                                     [headingLevel]="headingLevel"
                                     [rule]="_rule"
                                     [planData]="planData"
                                     [planRuleData]="planRuleData"
                                     [planStateObject]="planStateObject"
                                     [planValidationResult]="planValidationResult"
                                     [validatablePlan]="validatablePlan"
                                     [ruleDepthLevel]="ruleDepthLevel"/>
<sis-plan-structure-module-rule *ngIf="_rule.type === 'ModuleRule'"
                                [parentModule]="parentModule"
                                [headingLevel]="headingLevel"
                                [rule]="_rule"
                                [planData]="planData"
                                [planRuleData]="planRuleData"
                                [planStateObject]="planStateObject"
                                [planValidationResult]="planValidationResult"
                                [validatablePlan]="validatablePlan"
                                [ruleDepthLevel]="ruleDepthLevel"/>
<sis-plan-structure-any-course-unit-rule *ngIf="_rule.type === 'AnyCourseUnitRule'"
                                         [parentModule]="parentModule"
                                         [headingLevel]="headingLevel"
                                         [rule]="_rule"
                                         [planData]="planData"
                                         [planStateObject]="planStateObject"
                                         [planValidationResult]="planValidationResult"
                                         [validatablePlan]="validatablePlan"
                                         [ruleDepthLevel]="ruleDepthLevel"/>
<sis-plan-structure-any-module-rule *ngIf="_rule.type === 'AnyModuleRule'"
                                    [parentModule]="parentModule"
                                    [headingLevel]="headingLevel"
                                    [rule]="_rule"
                                    [planData]="planData"
                                    [planStateObject]="planStateObject"
                                    [planValidationResult]="planValidationResult"
                                    [validatablePlan]="validatablePlan"
                                    [ruleDepthLevel]="ruleDepthLevel"/>
