import angular from 'angular';
import _ from 'lodash';
export const planStudyRightServiceModule = 'sis-components.service.planStudyRightService';
(function () {
  angular.module(planStudyRightServiceModule, []).factory('planStudyRightService', planStudyRightService);

  /**
   * @ngInject
   */
  function planStudyRightService() {
    const propertyNames = ['educationPhase1GroupId', 'educationPhase1ChildGroupId', 'educationPhase2GroupId', 'educationPhase2ChildGroupId'];
    const isPhase1Personalized = studyRight => !_.isNil(_.get(studyRight, ['personalizedSelectionPath', 'phase1']));
    const isPhase2Personalized = studyRight => !_.isNil(_.get(studyRight, ['personalizedSelectionPath', 'phase2']));
    const hasPersonalizedPhase1ModuleGroupId = studyRight => !_.isNil(_.get(studyRight, ['personalizedSelectionPath', 'phase1', 'moduleGroupId']));
    const hasPersonalizedPhase1ChildModuleGroupId = studyRight => !_.isNil(_.get(studyRight, ['personalizedSelectionPath', 'phase1', 'childModuleGroupId']));
    const hasPersonalizedPhase2ModuleGroupId = studyRight => !_.isNil(_.get(studyRight, ['personalizedSelectionPath', 'phase2', 'moduleGroupId']));
    const hasPersonalizedPhase2ChildModuleGroupId = studyRight => !_.isNil(_.get(studyRight, ['personalizedSelectionPath', 'phase2', 'childModuleGroupId']));
    const createPersonalizedAllowedPath = (allowedPath, studyRight) => {
      const result = _.merge({}, allowedPath, {
        educationPhase1GroupId: _.get(studyRight, ['personalizedSelectionPath', 'phase1', 'moduleGroupId']),
        educationPhase1ChildGroupId: _.get(studyRight, ['personalizedSelectionPath', 'phase1', 'childModuleGroupId']),
        educationPhase2GroupId: _.get(studyRight, ['personalizedSelectionPath', 'phase2', 'moduleGroupId']),
        educationPhase2ChildGroupId: _.get(studyRight, ['personalizedSelectionPath', 'phase2', 'childModuleGroupId'])
      });
      if (hasPersonalizedPhase1ModuleGroupId(studyRight) && !hasPersonalizedPhase1ChildModuleGroupId(studyRight)) {
        delete result.educationPhase1ChildGroupId;
      }
      if (hasPersonalizedPhase2ModuleGroupId(studyRight) && !hasPersonalizedPhase2ChildModuleGroupId(studyRight)) {
        delete result.educationPhase2ChildGroupId;
      }
      return result;
    };
    const addChildOptions = (api, educationOption, educationOptionModule, educationPathKey, validatablePlan, educationOptions) => {
      _.forEach(educationOption.childOptions, educationChildOption => {
        const childObject = api.getEducationChildOptionObject(educationChildOption, validatablePlan, educationOption, educationOptionModule, educationPathKey);
        educationOptions.push(childObject);
      });
    };
    const createPersonalizedChildOption = (api, education, validatablePlan, phase, parentOption, educationPathKey) => {
      const childObject = {
        moduleGroupId: phase.childModuleGroupId,
        parentModuleGroupId: phase.moduleGroupId,
        educationPathKey,
        acceptanceType: 'ACCEPTED_BY_TEACHER',
        namingType: phase.childNamingUrn
      };
      const educationChildOptionModule = _.find(validatablePlan.modulesById, {
        groupId: phase.childModuleGroupId
      });
      if (educationChildOptionModule && validatablePlan.isModuleInPlan(educationChildOptionModule.id) && validatablePlan.isModuleInPlanUnderModule(educationChildOptionModule.id, _.get(parentOption, 'moduleId'))) {
        childObject.moduleId = educationChildOptionModule.id;
        childObject.isInPlan = true;
        childObject.isInPlanAsMinor = api.isModuleSelectedAsMinor(educationChildOptionModule, validatablePlan);
      } else {
        childObject.isInPlan = false;
        childObject.isInPlanAsMinor = false;
      }
      return childObject;
    };
    const createEducationPathKey = phase => phase === 'phase1' ? 'educationPhase1GroupId' : 'educationPhase2GroupId';
    const createChildOptionPathKey = phase => phase === 'phase1' ? 'educationPhase1ChildGroupId' : 'educationPhase2ChildGroupId';
    const createObjectFromEducationOption = (api, educationOption, educationOptionModule, validatablePlan, phaseName) => {
      const object = {
        moduleGroupId: educationOption.moduleGroupId,
        educationPathKey: createEducationPathKey(phaseName),
        phaseName,
        acceptanceType: educationOption.acceptanceType
      };
      if (!_.isEmpty(educationOption.childOptions)) {
        object.childOptionPathKey = createChildOptionPathKey(phaseName);
      }
      if (educationOptionModule && validatablePlan.isModuleInPlan(educationOptionModule.id)) {
        object.moduleId = educationOptionModule.id;
        object.isInPlan = true;
        object.isInPlanAsMinor = api.isModuleSelectedAsMinor(educationOptionModule, validatablePlan);
      } else {
        object.isInPlan = false;
        object.isInPlanAsMinor = false;
      }
      return object;
    };
    const createPersonalizedOption = (api, education, educationOptionModule, validatablePlan, phase, phaseName) => {
      const object = {
        moduleGroupId: phase.moduleGroupId,
        educationPathKey: createEducationPathKey(phaseName),
        phaseName,
        acceptanceType: 'ACCEPTED_BY_TEACHER'
      };
      if (educationOptionModule && validatablePlan.isModuleInPlan(educationOptionModule.id)) {
        object.moduleId = educationOptionModule.id;
        object.isInPlan = true;
        object.isInPlanAsMinor = api.isModuleSelectedAsMinor(educationOptionModule, validatablePlan);
      } else {
        object.isInPlan = false;
        object.isInPlanAsMinor = false;
      }
      return object;
    };
    const createPhase1ObjectFromEducationOption = (api, educationOption, educationOptionModule, validatablePlan) => createObjectFromEducationOption(api, educationOption, educationOptionModule, validatablePlan, 'phase1');
    const createPhase2ObjectFromEducationOption = (api, educationOption, educationOptionModule, validatablePlan) => createObjectFromEducationOption(api, educationOption, educationOptionModule, validatablePlan, 'phase2');
    const addPhase1Options = (api, education, validatablePlan, educationOptions) => {
      _.forEach(education.structure.phase1.options, educationOption => {
        const educationOptionModule = _.find(validatablePlan.modulesById, {
          groupId: educationOption.moduleGroupId
        });
        addChildOptions(api, educationOption, educationOptionModule, 'educationPhase1ChildGroupId', validatablePlan, educationOptions);
        educationOptions.push(createPhase1ObjectFromEducationOption(api, educationOption, educationOptionModule, validatablePlan));
      });
    };
    const addPhase2Options = (api, education, validatablePlan, educationOptions) => {
      _.forEach(education.structure.phase2.options, educationOption => {
        const educationOptionModule = _.find(validatablePlan.modulesById, {
          groupId: educationOption.moduleGroupId
        });
        addChildOptions(api, educationOption, educationOptionModule, 'educationPhase2ChildGroupId', validatablePlan, educationOptions);
        educationOptions.push(createPhase2ObjectFromEducationOption(api, educationOption, educationOptionModule, validatablePlan));
      });
    };
    const createPersonalizedPhase1Option = (api, education, validatablePlan) => createPersonalizedOption(api, education, _.find(validatablePlan.modulesById, {
      groupId: _.get(validatablePlan, ['studyRight', 'personalizedSelectionPath', 'phase1', 'moduleGroupId'])
    }), validatablePlan, _.get(validatablePlan, ['studyRight', 'personalizedSelectionPath', 'phase1']), 'phase1');
    const createPersonalizedPhase2Option = (api, education, validatablePlan) => createPersonalizedOption(api, education, _.find(validatablePlan.modulesById, {
      groupId: _.get(validatablePlan, ['studyRight', 'personalizedSelectionPath', 'phase2', 'moduleGroupId'])
    }), validatablePlan, _.get(validatablePlan, ['studyRight', 'personalizedSelectionPath', 'phase2']), 'phase2');
    const addPersonalizedPhase1Options = (api, education, validatablePlan, educationOptions) => {
      const option = createPersonalizedPhase1Option(api, education, validatablePlan);
      if (hasPersonalizedPhase1ChildModuleGroupId(validatablePlan.studyRight)) {
        educationOptions.push(createPersonalizedChildOption(api, education, validatablePlan, _.get(validatablePlan, ['studyRight', 'personalizedSelectionPath', 'phase1']), option, createChildOptionPathKey('phase1')));
      }
      educationOptions.push(option);
    };
    const addPersonalizedPhase2Options = (api, education, validatablePlan, educationOptions) => {
      const option = createPersonalizedPhase2Option(api, education, validatablePlan);
      if (hasPersonalizedPhase2ChildModuleGroupId(validatablePlan.studyRight)) {
        educationOptions.push(createPersonalizedChildOption(api, education, validatablePlan, _.get(validatablePlan, ['studyRight', 'personalizedSelectionPath', 'phase2']), option, createChildOptionPathKey('phase2')));
      }
      educationOptions.push(option);
    };
    const setSelectionPathInPlanForPersonalizedPhase = (api, selectionPathInPlan, phaseKey, personalizedPhase, validatablePlan) => {
      const optionModule = _.find(validatablePlan.modulesById, {
        groupId: personalizedPhase.moduleGroupId
      });
      if (optionModule && validatablePlan.isModuleInPlan(optionModule.id) && !api.isModuleSelectedAsMinor(optionModule, validatablePlan)) {
        selectionPathInPlan[`education${phaseKey}GroupId`] = personalizedPhase.moduleGroupId;
        const childOptionModule = _.find(validatablePlan.modulesById, {
          groupId: personalizedPhase.childModuleGroupId
        });
        const childOptionModuleId = _.get(childOptionModule, 'id');
        if (validatablePlan.isModulePlannedOrAttainedUnderModule(childOptionModuleId, personalizedPhase.childModuleGroupId, optionModule.id) && !api.isModuleSelectedAsMinor(childOptionModule, validatablePlan)) {
          selectionPathInPlan[`education${phaseKey}ChildGroupId`] = personalizedPhase.childModuleGroupId;
        }
      }
    };
    const api = {
      createPersonalizedAllowedPaths: function (allowedPaths, studyRight) {
        return _.uniqWith(_.map(allowedPaths, allowedPath => createPersonalizedAllowedPath(allowedPath, studyRight)), _.isEqual);
      },
      getSelectionPathInPlan: function (validatablePlan, education, studyRight) {
        const selectionPathInPlan = {};
        let noDuplicates = true;
        const conflictingSelections = {};
        const personalizedPhase1 = _.get(studyRight, 'personalizedSelectionPath.phase1');
        const personalizedPhase2 = _.get(studyRight, 'personalizedSelectionPath.phase2');
        if (!_.isNil(personalizedPhase1)) {
          setSelectionPathInPlanForPersonalizedPhase(this, selectionPathInPlan, 'Phase1', personalizedPhase1, validatablePlan);
        } else {
          _.forEach(education.structure.phase1.options, educationOption => {
            const optionModule = _.find(validatablePlan.modulesById, {
              groupId: educationOption.moduleGroupId
            });
            if (optionModule && validatablePlan.isModuleInPlan(optionModule.id) && !api.isModuleSelectedAsMinor(optionModule, validatablePlan)) {
              selectionPathInPlan.educationPhase1GroupId = educationOption.moduleGroupId;
              _.forEach(educationOption.childOptions, childOption => {
                const childOptionModule = _.find(validatablePlan.modulesById, {
                  groupId: childOption.moduleGroupId
                });
                const childOptionModuleId = _.get(childOptionModule, 'id');
                if (validatablePlan.isModulePlannedOrAttainedUnderModule(childOptionModuleId, childOption.moduleGroupId, optionModule.id) && !api.isModuleSelectedAsMinor(childOptionModule, validatablePlan)) {
                  if (_.has(selectionPathInPlan, 'educationPhase1ChildGroupId')) {
                    noDuplicates = false;
                    if (!_.has(conflictingSelections, 'educationPhase1ChildGroupId')) {
                      _.set(conflictingSelections, 'educationPhase1ChildGroupId', []);
                      conflictingSelections.educationPhase1ChildGroupId.push(selectionPathInPlan.educationPhase1ChildGroupId);
                    }
                    conflictingSelections.educationPhase1ChildGroupId.push(childOption.moduleGroupId);
                  } else {
                    selectionPathInPlan.educationPhase1ChildGroupId = childOption.moduleGroupId;
                  }
                }
              });
            }
          });
        }
        if (education.structure.phase2) {
          if (!_.isNil(personalizedPhase2)) {
            setSelectionPathInPlanForPersonalizedPhase(this, selectionPathInPlan, 'Phase2', personalizedPhase2, validatablePlan);
          } else {
            _.forEach(education.structure.phase2.options, educationOption => {
              const optionModule = _.find(validatablePlan.modulesById, {
                groupId: educationOption.moduleGroupId
              });
              if (optionModule && validatablePlan.isModuleInPlan(optionModule.id) && !api.isModuleSelectedAsMinor(optionModule, validatablePlan)) {
                selectionPathInPlan.educationPhase2GroupId = educationOption.moduleGroupId;
                _.forEach(educationOption.childOptions, childOption => {
                  const childOptionModule = _.find(validatablePlan.modulesById, {
                    groupId: childOption.moduleGroupId
                  });
                  const childOptionModuleId = _.get(childOptionModule, 'id');
                  if (validatablePlan.isModulePlannedOrAttainedUnderModule(childOptionModuleId, childOption.moduleGroupId, optionModule.id) && !api.isModuleSelectedAsMinor(childOptionModule, validatablePlan)) {
                    if (_.has(selectionPathInPlan, 'educationPhase2ChildGroupId')) {
                      noDuplicates = false;
                      if (!_.has(conflictingSelections, 'educationPhase2ChildGroupId')) {
                        _.set(conflictingSelections, 'educationPhase2ChildGroupId', []);
                        conflictingSelections.educationPhase2ChildGroupId.push(selectionPathInPlan.educationPhase2ChildGroupId);
                      }
                      conflictingSelections.educationPhase2ChildGroupId.push(childOption.moduleGroupId);
                    } else {
                      selectionPathInPlan.educationPhase2ChildGroupId = childOption.moduleGroupId;
                    }
                  }
                });
              }
            });
          }
        }
        const result = {
          selectionPath: selectionPathInPlan,
          conflictingSelections,
          noDuplicates
        };
        if (studyRight) {
          api.resolveConflictingSelections(result, studyRight);
        }
        return result;
      },
      resolveConflictingSelections: function (selectionPathInPlan, studyRight) {
        _.forEach(_.keys(selectionPathInPlan.conflictingSelections), educationPathKey => {
          const acceptedPathSelection = _.get(studyRight.acceptedSelectionPath, educationPathKey);
          if (acceptedPathSelection) {
            const matchingModuleGroupId = _.find(_.get(selectionPathInPlan.conflictingSelections, educationPathKey), moduleGroupId => moduleGroupId === acceptedPathSelection);
            if (matchingModuleGroupId) {
              _.set(selectionPathInPlan.selectionPath, educationPathKey, matchingModuleGroupId);
            }
          }
        });
      },
      getStudyRightSelectionPaths: function (education, studyRight) {
        const studyRightLearningOpportunity = _.find(education.structure.learningOpportunities, {
          localId: studyRight.learningOpportunityId
        });
        if (!_.isNil(_.get(studyRight, 'personalizedSelectionPath'))) {
          return api.createPersonalizedAllowedPaths(_.get(studyRightLearningOpportunity, 'allowedPaths'), studyRight);
        }
        if (!studyRightLearningOpportunity) {
          return [];
        }
        return _.get(studyRightLearningOpportunity, 'allowedPaths');
      },
      selectionPathIsPartialMatch: function (partialPath, completePath) {
        const allKeysMatch = _.every(_.keys(partialPath), key => partialPath[key] === _.get(completePath, key));
        if (!allKeysMatch) {
          return false;
        }
        const partialPathIndexes = _.map(_.keys(partialPath), key => _.indexOf(propertyNames, key));
        const completePathIndexes = _.map(_.keys(completePath), key => _.indexOf(propertyNames, key));
        if (_.min(_.xor(partialPathIndexes, completePathIndexes)) < _.max(partialPathIndexes)) {
          return false;
        }
        return true;
      },
      getSelectionPathMaxIndex: function (selectionPath) {
        if (_.keys(selectionPath).length === 0) {
          return -1;
        }
        return _.max(_.map(_.keys(selectionPath), key => _.indexOf(propertyNames, key)));
      },
      isChildOption: function (educationOption) {
        return _.indexOf(propertyNames, educationOption.educationPathKey) % 2 !== 0;
      },
      matchesCorrespondingPropertyInSomeAllowedPath: function (educationOption, studyRight, education) {
        const studyRightSelectionPaths = api.getStudyRightSelectionPaths(education, studyRight);
        if (studyRightSelectionPaths.length === 0) {
          return false;
        }
        const possiblePaths = _.filter(studyRightSelectionPaths, allowedPath => api.selectionPathIsPartialMatch(studyRight.acceptedSelectionPath, allowedPath));
        return _.some(possiblePaths, allowedPath => api.matchesCorrespondingPropertyInSelectionPath(educationOption, allowedPath));
      },
      matchesCorrespondingPropertyInSelectionPath: function (educationOption, selectionPath) {
        if (!api.isChildOption(educationOption)) {
          return _.get(selectionPath, educationOption.educationPathKey) === educationOption.moduleGroupId;
        }
        const parentPathKey = api.getPreviousEducationPathKey(educationOption.educationPathKey);
        const parentMatches = _.get(selectionPath, parentPathKey) === educationOption.parentModuleGroupId;
        const childMatches = _.get(selectionPath, educationOption.educationPathKey) === educationOption.moduleGroupId;
        return parentMatches && childMatches;
      },
      hasSelectionPathAGap: function (selectionPath, educationOptionKey) {
        return !_.has(selectionPath, educationOptionKey) && api.getSelectionPathMaxIndex(selectionPath) > _.indexOf(propertyNames, educationOptionKey);
      },
      getStudyRightStateForEducationOption: function (educationOption, education, selectionPathInPlan, studyRight) {
        if (educationOption.isInPlanAsMinor === true) {
          return 'SELECTED_AS_MINOR';
        }
        if (_.has(studyRight.acceptedSelectionPath, educationOption.educationPathKey)) {
          return api.matchesCorrespondingPropertyInSelectionPath(educationOption, studyRight.acceptedSelectionPath) ? 'CONFIRMED' : 'CONFLICTS_WITH_STUDY_RIGHT';
        }
        if (api.hasSelectionPathAGap(studyRight.acceptedSelectionPath, educationOption.educationPathKey)) {
          return 'CONFLICTS_WITH_STUDY_RIGHT';
        }
        if (_.has(selectionPathInPlan.conflictingSelections, educationOption.educationPathKey)) {
          return 'DUPLICATE_SELECTION';
        }
        if (!api.matchesCorrespondingPropertyInSomeAllowedPath(educationOption, studyRight, education)) {
          return 'CONFLICTS_WITH_STUDY_RIGHT';
        }
        if (educationOption.acceptanceType === 'AUTOMATIC') {
          return 'NOT_CONFIRMED';
        }
        return 'NO_STUDY_RIGHT';
      },
      getValidatedEducationOptions: function (validatablePlan, education, studyRight) {
        if (!studyRight) {
          return null;
        }
        const educationOptions = api.getEducationOptions(validatablePlan, education);
        const selectionPathInPlan = api.getSelectionPathInPlan(validatablePlan, education, studyRight);
        _.forEach(educationOptions, educationOption => {
          educationOption.studyRightState = api.getStudyRightStateForEducationOption(educationOption, education, selectionPathInPlan, studyRight);
        });
        return educationOptions;
      },
      populateNamingData: function (educationOptions, education, educationOptionNamingTypes) {
        _.forEach(educationOptions, educationOption => {
          if (_.has(educationOption, 'parentModuleGroupId')) {
            _.set(educationOption, 'naming', _.get(educationOptionNamingTypes, educationOption.namingType).name);
          } else {
            const phase = _.get(education.structure, _.get(educationOption, 'phaseName'));
            _.set(educationOption, 'naming', _.get(phase, 'name'));
          }
        });
        return educationOptions;
      },
      getEducationChildOptionObject: function (educationChildOption, validatablePlan, parentOption, parentModule, educationPathKey) {
        const childObject = {
          moduleGroupId: educationChildOption.moduleGroupId,
          parentModuleGroupId: parentOption.moduleGroupId,
          educationPathKey,
          acceptanceType: educationChildOption.acceptanceType,
          namingType: parentOption.childNaming
        };
        const educationChildOptionModule = _.find(validatablePlan.modulesById, {
          groupId: educationChildOption.moduleGroupId
        });
        if (educationChildOptionModule && validatablePlan.isModuleInPlan(educationChildOptionModule.id) && validatablePlan.isModuleInPlanUnderModule(educationChildOptionModule.id, _.get(parentModule, 'id'))) {
          childObject.moduleId = educationChildOptionModule.id;
          childObject.isInPlan = true;
          childObject.isInPlanAsMinor = api.isModuleSelectedAsMinor(educationChildOptionModule, validatablePlan);
        } else {
          childObject.isInPlan = false;
          childObject.isInPlanAsMinor = false;
        }
        return childObject;
      },
      getEducationOptions: function (validatablePlan, education) {
        const educationOptions = [];
        if (isPhase1Personalized(validatablePlan.studyRight)) {
          addPersonalizedPhase1Options(this, education, validatablePlan, educationOptions);
        } else {
          addPhase1Options(this, education, validatablePlan, educationOptions);
        }
        if (education.structure.phase2) {
          if (isPhase2Personalized(validatablePlan.studyRight)) {
            addPersonalizedPhase2Options(this, education, validatablePlan, educationOptions);
          } else {
            addPhase2Options(this, education, validatablePlan, educationOptions);
          }
        }
        return educationOptions;
      },
      getMatchingEducationOption: function (moduleGroupId, educationOptions, validatablePlan) {
        const matchingEducationOption = _.find(educationOptions, educationOption => {
          if (moduleGroupId !== educationOption.moduleGroupId) {
            return false;
          }
          if (_.has(educationOption, 'parentModuleGroupId')) {
            const module = validatablePlan.getModuleInPlanByGroupId(moduleGroupId);
            const parentModule = validatablePlan.getModuleInPlanByGroupId(_.get(educationOption, 'parentModuleGroupId'));
            if (api.doesModuleContainOnlyMinors(parentModule)) {
              return false;
            }
            if (module && parentModule) {
              return validatablePlan.isModuleInPlanUnderModule(module.id, parentModule.id);
            }
            return false;
          }
          return true;
        });
        return matchingEducationOption;
      },
      /*
          getPotentialEducationOption returns an educationOption that would match the module referred to by
          moduleGroupId if it were in plan under the module referred to by parentModuleGroupId
       */

      getPotentialEducationOption: function (moduleGroupId, parentModuleGroupId, educationOptions, validatablePlan) {
        const potentialEducationOption = _.find(educationOptions, educationOption => {
          if (moduleGroupId !== educationOption.moduleGroupId) {
            return false;
          }
          if (_.has(educationOption, 'parentModuleGroupId')) {
            const module = validatablePlan.getModuleInPlanByGroupId(parentModuleGroupId);
            const parentModule = validatablePlan.getModuleInPlanByGroupId(_.get(educationOption, 'parentModuleGroupId'));
            if (api.doesModuleContainOnlyMinors(parentModule)) {
              return false;
            }
            if (module && parentModule) {
              return validatablePlan.isModuleInPlanUnderModule(module.id, parentModule.id);
            }
            return false;
          }
          return true;
        });
        return potentialEducationOption;
      },
      planConflictsWithStudyRight: function (educationOptions) {
        const conflictingOption = _.find(educationOptions, option => option.isInPlan === true && option.studyRightState === 'CONFLICTS_WITH_STUDY_RIGHT');
        return !!conflictingOption;
      },
      getNextSelectableLevel: function (studyRight, education) {
        let increment;
        const selectionPathMaxIndex = api.getSelectionPathMaxIndex(studyRight.acceptedSelectionPath);
        if (selectionPathMaxIndex === -1) {
          return 0;
        }
        const selectionPathLastKey = _.nth(propertyNames, selectionPathMaxIndex);
        const phase = selectionPathMaxIndex < 2 ? education.structure.phase1 : education.structure.phase2;
        if (selectionPathMaxIndex % 2 === 0) {
          const educationOption = _.find(phase.options, option => option.moduleGroupId === _.get(studyRight.acceptedSelectionPath, selectionPathLastKey));
          increment = _.isNil(educationOption) || _.isEmpty(educationOption.childOptions) ? 2 : 1;
        } else {
          increment = 1;
        }
        const result = selectionPathMaxIndex + increment;
        return result < 4 ? result : null;
      },
      pickPhase2OfSelectionPath: function (selectionPath) {
        return _.pick(selectionPath, ['educationPhase2GroupId', 'educationPhase2ChildGroupId']);
      },
      canEducationOptionBeSelected: function (educationOption, studyRight, education) {
        const educationOptionIndex = _.indexOf(propertyNames, educationOption.educationPathKey);
        const hasPersonalizedPhase1 = !_.isNil(_.get(studyRight, 'personalizedSelectionPath.phase1'));
        const hasPersonalizedPhase2 = !_.isNil(_.get(studyRight, 'personalizedSelectionPath.phase2'));
        if (hasPersonalizedPhase1 && educationOptionIndex < 2 || hasPersonalizedPhase2) {
          return false;
        }
        const selectionPathMaxIndex = api.getSelectionPathMaxIndex(studyRight.acceptedSelectionPath);
        const selectionPathToBeSelected = _.clone(studyRight.acceptedSelectionPath);
        if (educationOptionIndex === selectionPathMaxIndex || educationOptionIndex === api.getNextSelectableLevel(studyRight, education)) {
          _.set(selectionPathToBeSelected, educationOption.educationPathKey, educationOption.moduleGroupId);
          if (educationOptionIndex % 2 !== 0) {
            const parentPathKey = api.getPreviousEducationPathKey(educationOption.educationPathKey);
            if (_.get(studyRight, ['acceptedSelectionPath', parentPathKey]) !== educationOption.parentModuleGroupId) {
              return false;
            }
          }
          return _.some(api.getStudyRightSelectionPaths(education, studyRight), studyRightSelectionPath => {
            if (hasPersonalizedPhase1) {
              return api.selectionPathIsPartialMatch(api.pickPhase2OfSelectionPath(selectionPathToBeSelected), api.pickPhase2OfSelectionPath(studyRightSelectionPath));
            }
            return api.selectionPathIsPartialMatch(selectionPathToBeSelected, studyRightSelectionPath);
          });
        }
        return false;
      },
      isEducationOptionSelected: function (educationOption, studyRight) {
        const educationOptionPathIndex = _.indexOf(propertyNames, educationOption.educationPathKey);
        const optionMatchesSelectionPath = api.matchesCorrespondingPropertyInSelectionPath(educationOption, studyRight.acceptedSelectionPath);
        if (educationOptionPathIndex % 2 === 0) {
          // educationOption is not childOption
          return optionMatchesSelectionPath;
        }
        // from this point on we know that educationOption is childOption
        const parentEducationOption = {
          educationPathKey: api.getPreviousEducationPathKey(educationOption.educationPathKey),
          moduleGroupId: educationOption.parentModuleGroupId
        };
        const parentOptionMatchesSelectionPath = api.matchesCorrespondingPropertyInSelectionPath(parentEducationOption, studyRight.acceptedSelectionPath);
        return optionMatchesSelectionPath && parentOptionMatchesSelectionPath;
      },
      getPreviousEducationPathKey: function (educationPathKey) {
        return _.nth(propertyNames, _.indexOf(propertyNames, educationPathKey) - 1);
      },
      getNextEducationPathKey: function (educationPathKey) {
        return _.nth(propertyNames, _.indexOf(propertyNames, educationPathKey) + 1);
      },
      getFirstNotConfirmedSelectionPathKeyInPlan: function (selectionPathInPlan, studyRight, education) {
        return _.find(propertyNames, educationPathKey => {
          if (educationPathKey === 'educationPhase1ChildGroupId' && !api.isPhase1ChildGroupIdRequired(selectionPathInPlan, education)) {
            return false;
          }
          const planModuleGroupId = _.get(selectionPathInPlan, educationPathKey);
          const studyRightModuleGroupId = _.get(studyRight.acceptedSelectionPath, educationPathKey);
          if (!studyRightModuleGroupId || !planModuleGroupId || studyRightModuleGroupId !== planModuleGroupId) {
            return true;
          }
          return false;
        });
      },
      isPhase1ChildGroupIdRequired: function (selectionPath, education) {
        const parentModuleGroupId = _.get(selectionPath, 'educationPhase1GroupId');
        if (!parentModuleGroupId) {
          return false;
        }
        const parentEducationOption = _.find(education.structure.phase1.options, {
          moduleGroupId: parentModuleGroupId
        });
        return parentEducationOption ? !_.isEmpty(parentEducationOption.childOptions) : false;
      },
      getFirstNotConfirmedEducationOption: function (educationOptions, selectionPathInPlan, studyRight, education) {
        const confirmationTargetKey = api.getFirstNotConfirmedSelectionPathKeyInPlan(selectionPathInPlan, studyRight, education);
        if (!confirmationTargetKey) {
          return null;
        }
        if (_.indexOf(propertyNames, confirmationTargetKey) % 2 === 0) {
          return _.find(educationOptions, educationOption => _.get(educationOption, 'educationPathKey') === confirmationTargetKey && _.get(educationOption, 'moduleGroupId') === _.get(selectionPathInPlan, confirmationTargetKey) && _.get(educationOption, 'isInPlanAsMinor') === false);
        }
        return _.find(educationOptions, educationOption => _.get(educationOption, 'educationPathKey') === confirmationTargetKey && _.get(educationOption, 'parentModuleGroupId') === _.get(selectionPathInPlan, api.getPreviousEducationPathKey(confirmationTargetKey)) && _.get(educationOption, 'moduleGroupId') === _.get(selectionPathInPlan, confirmationTargetKey) && _.get(educationOption, 'isInPlanAsMinor') === false);
      },
      isEducationOptionSelectableBasedOnLearningOpportunity: function (educationOption, education, learningOpportunityId) {
        const learningOpportunity = _.find(education.structure.learningOpportunities, {
          localId: learningOpportunityId
        });
        if (!learningOpportunity) {
          return true;
        }
        return _.some(learningOpportunity.allowedPaths, allowedPath => _.get(allowedPath, educationOption.educationPathKey) === educationOption.moduleGroupId);
      },
      isEducationOptionIncludedInStudyRightAcceptedSelectionPath: function (educationOption, studyRight) {
        return _.get(studyRight.acceptedSelectionPath, educationOption.educationPathKey) === educationOption.moduleGroupId;
      },
      isEducationOptionSelectableBasedOnStudyRightAllowedSelectionPaths: function (educationOption, studyRight, education) {
        const selectionPathToBeSelected = api.getSelectionPathToBeSelected(educationOption, studyRight);
        return _.some(api.getStudyRightSelectionPaths(education, studyRight), studyRightSelectionPath => api.selectionPathIsPartialMatch(selectionPathToBeSelected, studyRightSelectionPath));
      },
      getSelectionPathToBeSelected: function (educationOption, studyRight) {
        const selectionPath = _.clone(studyRight.acceptedSelectionPath);
        const indexOfLastProperty = _.indexOf(propertyNames, educationOption.educationPathKey);
        _.forEach(propertyNames, propertyName => {
          if (educationOption.educationPathKey === propertyName) {
            _.set(selectionPath, propertyName, educationOption.moduleGroupId);
          }
          if (_.indexOf(propertyNames, propertyName) > indexOfLastProperty) {
            _.unset(selectionPath, propertyName);
          }
        });
        return selectionPath;
      },
      getEducationOptionsByPathKeyForSelectionPath: function (educationOptions, selectionPath) {
        const result = {};
        _.forEach(propertyNames, propertyName => {
          const moduleGroupId = _.get(selectionPath, propertyName);
          if (moduleGroupId) {
            let educationOption;
            if (_.indexOf(propertyNames, propertyName) % 2 === 0) {
              educationOption = _.find(educationOptions, eo => eo.moduleGroupId === _.get(selectionPath, propertyName));
            } else {
              const parentPathKey = api.getPreviousEducationPathKey(propertyName);
              educationOption = _.find(educationOptions, eo => {
                const moduleGroupIdMatches = eo.moduleGroupId === _.get(selectionPath, propertyName);
                const parentModuleGroupIdMatches = eo.parentModuleGroupId === _.get(selectionPath, parentPathKey);
                return moduleGroupIdMatches && parentModuleGroupIdMatches;
              });
            }
            if (educationOption) {
              _.set(result, propertyName, educationOption);
            }
          }
        });
        return result;
      },
      getEducationOptionsToBeChanged: function (oldOptions, newOptions) {
        const result = [];
        _.forEach(_.keys(oldOptions), oldOptionKey => {
          const oldOption = _.get(oldOptions, oldOptionKey);
          if (!_.isEqual(oldOption, _.get(newOptions, oldOptionKey))) {
            result.push(oldOption);
          }
        });
        return result;
      },
      getBlockingEducationOptions: function (educationOptionToBeSelected, educationOptions, studyRight) {
        const oldOptions = api.getEducationOptionsByPathKeyForSelectionPath(educationOptions, studyRight.acceptedSelectionPath);
        const newSelectionPath = api.getSelectionPathToBeSelected(educationOptionToBeSelected, studyRight);
        const newOptions = api.getEducationOptionsByPathKeyForSelectionPath(educationOptions, newSelectionPath);
        const educationOptionsToBeChanged = api.getEducationOptionsToBeChanged(oldOptions, newOptions);
        return _.filter(educationOptionsToBeChanged, option => option.acceptanceType !== 'AUTOMATIC');
      },
      // isModuleSelectedAsMinor regards minors all modules that are selected under a module with
      // contentFilter minor-study-right. module's studyRightSelectionType property is ignored here on purpose

      isModuleSelectedAsMinor: function (module, validatablePlan) {
        const parentModule = validatablePlan.getParentModuleOrCustomModuleAttainmentForModule(module);
        if (!parentModule) {
          return false;
        }
        return api.doesModuleContainOnlyMinors(parentModule);
      },
      doesModuleContainOnlyMinors: function (module) {
        const studyRightSelectionType = _.get(module, 'contentFilter.studyRightSelectionType');
        return studyRightSelectionType === 'urn:code:study-right-selection-type:minor-study-right';
      }
    };
    return api;
  }
})();