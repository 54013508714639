import { inject, Injectable } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { PlanValidationTs, ValidatablePlan } from 'common-typescript';
import { PlanValidationResult } from 'common-typescript/src/plan/validation/planValidationResult';
import { Education, GradeScale, OtmId, Plan, StudyRight, UniversitySettings } from 'common-typescript/types';
import _ from 'lodash';
import {
    combineLatest,
    distinctUntilChanged,
    filter,
    from,
    map,
    Observable,
    OperatorFunction,
    shareReplay,
    switchMap,
    take,
    tap,
} from 'rxjs';
import { COMMON_PLAN_SERVICE, PLAN_STUDY_RIGHT_SERVICE } from 'sis-components/ajs-upgraded-modules';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { SelectOption } from 'sis-components/select/select-combobox/select-combobox.component';
import { GradeScaleEntityService } from 'sis-components/service/grade-scale-entity.service';
import { PlanEntityService } from 'sis-components/service/plan-entity.service';
import { PlanStateAndData, PlanStateService } from 'sis-components/service/plan-state.service';
import { StudyRightEntityService } from 'sis-components/service/study-right-entity.service';
import { UniversityService } from 'sis-components/service/university.service';
import { convertAJSPromiseToNative } from 'sis-components/util/utils';

import { PlanRedirectionService } from './plan-redirection.service';

export interface PlanStructureData {
    planSelectionOptions: SelectOption[];
    plan: Plan;
    allStudentStudyRights: StudyRight[];
    planStateAndData: PlanStateAndData;
    validatablePlan: ValidatablePlan;
    validatablePlanEducation: Education;
    planValidationResult: PlanValidationResult;
    matchingStudyRight: StudyRight;
    universitySettings: UniversitySettings;
}

@Injectable({
    providedIn: 'root',
})
export class PlanDataService {

    planEntityService = inject(PlanEntityService);
    appErrorHandler = inject(AppErrorHandler);
    studyRightEntityService = inject(StudyRightEntityService);
    planStateService = inject(PlanStateService);
    gradeScaleEntityService = inject(GradeScaleEntityService);
    universityService = inject(UniversityService);
    stateService = inject(StateService);
    planRedirectionService = inject(PlanRedirectionService);
    commonPlanService = inject(COMMON_PLAN_SERVICE);
    planStudyRightService = inject(PLAN_STUDY_RIGHT_SERVICE);

    createDataObservable(planIdInput$: Observable<OtmId>): Observable<PlanStructureData> {
        const allStudentPlans$ = this.createGetMyPlansObservable();
        const planSelectionOptions$ = this.createPlanSelectionsObservable(allStudentPlans$);

        const studyRights$ = this.createStudyRightsObservable();
        const selectedPlan$ = this.createPlanObservable(planIdInput$, allStudentPlans$, studyRights$);
        const matchingStudyRight$ = this.createMatchingStudyRightObservable(selectedPlan$, studyRights$);
        const validatablePlan$ = this.createValidatablePlanObservable(selectedPlan$);
        const planValidationResult$ = this.createPlanValidationResultObservable(validatablePlan$);

        const education$ = this.createEducationObservable(validatablePlan$);
        const educationOptions$ = this.createEducationOptionsObservable(validatablePlan$, education$, matchingStudyRight$);
        const gradeScalesById$ = this.createGradeScalesByIdObservable(validatablePlan$);
        const planStateAndData$ = this.createPlanAndStateDataObservable(
            education$,
            validatablePlan$,
            planValidationResult$,
            educationOptions$,
            gradeScalesById$,
            matchingStudyRight$);
        const universitySettings$ = this.createUniversitySettingsObservable();

        return combineLatest({
            planSelectionOptions: planSelectionOptions$,
            plan: selectedPlan$,
            allStudentStudyRights: studyRights$,
            planValidationResult: planValidationResult$,
            validatablePlan: validatablePlan$,
            validatablePlanEducation: education$,
            matchingStudyRight: matchingStudyRight$,
            gradeScales: gradeScalesById$,
            planStateAndData: planStateAndData$,
            universitySettings: universitySettings$,
        });
    }

    createGetMyPlansObservable(): Observable<Plan[]> {
        return this.planEntityService.getMyPlans().pipe(
            this.appErrorHandler.defaultErrorHandler(),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    createPlanSelectionsObservable(allStudentPlans$: Observable<Plan[]>): Observable<SelectOption[]> {
        return allStudentPlans$.pipe(
            map((myPlans) => myPlans.map((plan) => (<SelectOption>{
                value: plan.id,
                label: plan.name,
            }))),
        );
    }

    createPlanObservable(planId$: Observable<OtmId>, allStudentPlans$: Observable<Plan[]>, studyRights$: Observable<StudyRight[]>): Observable<Plan> {
        return combineLatest([planId$, allStudentPlans$, studyRights$]).pipe(
            distinctUntilChanged((current, previous) => {
                const [curPlanId, curPlans, curStudyRights] = current;
                const [prevPlanId, prevPlans, prevStudyRights] = previous;
                return curPlanId === prevPlanId && _.isEqual(curPlans, prevPlans) && _.isEqual(curStudyRights, prevStudyRights);
            }),
            this.resolveSelectedPlan(),
            filter((plan) => !!plan),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    resolveSelectedPlan(): OperatorFunction<[OtmId, Plan[], StudyRight[]], Plan | null> {
        return map((([planId, allStudentPlans, studyRights]) => {
            const foundPlan = allStudentPlans.find(plan => plan.id === planId);
            // If a plan with the current id does not exist redirect using the redirection service
            if (!foundPlan) {
                const resolvedState = this.planRedirectionService.resolvePlanRedirectionState(allStudentPlans, studyRights);
                this.stateService.go(resolvedState.state, resolvedState.params, { supercede: false });
            }
            return foundPlan;
        }));
    }

    createValidatablePlanObservable(plan$: Observable<Plan>): Observable<ValidatablePlan> {
        return plan$.pipe(
            filter((plan) => !!plan),
            switchMap((plan) => from(convertAJSPromiseToNative<ValidatablePlan>(
                this.commonPlanService.getValidatablePlan(_.cloneDeep(plan), false, false)))),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    createPlanValidationResultObservable(validatablePlan$: Observable<ValidatablePlan>): Observable<PlanValidationResult> {
        return validatablePlan$.pipe(
            filter((validatablePlan) => !!validatablePlan),
            map((validatablePlan) => PlanValidationTs.validatePlan(validatablePlan)),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    createStudyRightsObservable(): Observable<StudyRight[]> {
        return this.studyRightEntityService.getStudyRightsForCurrentUser()
            .pipe(
                this.appErrorHandler.defaultErrorHandler(),
                shareReplay({ bufferSize: 1, refCount: true }),
            );
    }

    createMatchingStudyRightObservable(plan$: Observable<Plan>, studyRights$: Observable<StudyRight[]>): Observable<StudyRight> {
        return combineLatest([
            plan$,
            studyRights$,
        ]).pipe(
            filter(([plan, studyRights]) => !!plan && !!studyRights),
            map(([plan, studyRights]) => this.planStateService.getMatchingStudyRight(plan, studyRights)),
        );
    }

    createEducationObservable(validatablePlan$: Observable<ValidatablePlan>): Observable<Education> {
        return validatablePlan$.pipe(
            filter((validatablePlan) => !!validatablePlan),
            map((validatablePlan) => validatablePlan.rootModule),
        );
    }

    createEducationOptionsObservable(validatablePlan$: Observable<ValidatablePlan>,
                                     education$: Observable<Education>,
                                     matchingStudyRight$: Observable<StudyRight>): Observable<any> {
        return combineLatest([
            validatablePlan$,
            education$,
            matchingStudyRight$,
        ]).pipe(
            filter(([validatablePlan, education, studyRight]) => !!validatablePlan && !!education),
            map(([validatablePlan, education, studyRight]) =>
                this.planStudyRightService.getValidatedEducationOptions(validatablePlan, education, studyRight)));
    }

    createGradeScalesByIdObservable(validatablePlan$: Observable<ValidatablePlan>): Observable<_.Dictionary<GradeScale>> {
        return validatablePlan$.pipe(
            filter((validatablePlan) => !!validatablePlan),
            map(validatablePlan => _.chain(_.values(validatablePlan.getAllAttainments()))
                .map('gradeScaleId')
                .concat('sis-0-5')
                .compact()
                .uniq()
                .value()),
            switchMap((gradeScaleIds) => this.gradeScaleEntityService.getByIds(gradeScaleIds)),
            this.appErrorHandler.defaultErrorHandler(),
            map((gradeScales) => _.keyBy(gradeScales, 'id')),
        );
    }

    createPlanAndStateDataObservable(education$: Observable<Education>,
                                     validatablePlan$: Observable<ValidatablePlan>,
                                     planValidationResult$: Observable<PlanValidationResult>,
                                     educationOptions$: Observable<any>,
                                     gradeScalesById$: Observable<_.Dictionary<GradeScale>>,
                                     matchingStudyRight$: Observable<StudyRight>): Observable<PlanStateAndData> {
        return combineLatest([
            education$,
            validatablePlan$,
            planValidationResult$,
            educationOptions$,
            gradeScalesById$,
            matchingStudyRight$,
        ]).pipe(
            filter(([education, validatablePlan, planValidationResult, educationOptions, gradeScalesById]) =>
                !!education && !!validatablePlan && !!planValidationResult && !!gradeScalesById),
            map(([
                education,
                validatablePlan,
                planValidationResult,
                educationOptions,
                gradeScalesById,
                matchingStudyRight]) => this.planStateService.getPlanStateAndData(
                education,
                validatablePlan,
                planValidationResult,
                educationOptions,
                gradeScalesById,
                matchingStudyRight),
            ));
    }

    createUniversitySettingsObservable(): Observable<UniversitySettings> {
        return this.universityService.getCurrentUniversitySettings()
            .pipe(take(1), this.appErrorHandler.defaultErrorHandler());
    }
}
