<sis-description-list *ngIf="product" [columnCount]="1">
  <ng-template #listItem>
    <dt>{{'OPEN_UNIVERSITY.STUDY_OFFERING.CREDITS_LABEL' | translate}}</dt>
    <dd>{{product.credits | decimalNumber}} {{'CREDITS' | translate}}</dd>
  </ng-template>
  <ng-template #listItem>
    <dt>{{'OPEN_UNIVERSITY.STUDY_OFFERING.ASSESSMENT_ITEMS' | translate}}</dt>
    <dd *ngFor="let assessmentItem of assessmentItems$ | async; trackBy: entityId">
      {{assessmentItem.name | localizedString}}
    </dd>
  </ng-template>
  <ng-template #listItem>
    <dt>{{'OPEN_UNIVERSITY.STUDY_OFFERING.PRICE' | translate}}</dt>
    <dd>{{product.price | money}}</dd>
  </ng-template>
</sis-description-list>
