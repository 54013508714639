<sis-study-box [id]="courseUnit.id"
               [name]="courseUnit.name"
               [code]="courseUnit.code"
               [credits]="courseUnit.credits"
               [level]="headingLevel"
               [studyState]="studyState"
               [attainedCredits]="attainment?.credits"
               [gradeId]="attainment?.gradeId"
               [gradeScaleId]="attainment?.gradeScaleId"
               [isSubstituted]="isSubstituted"
               [isCrossStudyCourseUnit]="isCrossStudyCourseUnit"
               [isInvalidSelection]="invalidSelection"
               (nameClickAction)="openCourseUnitInfoModal()">
</sis-study-box>
