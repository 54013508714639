function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import angular from 'angular';
import _ from 'lodash';
import { PaginationComponent } from 'sis-components/pagination/pagination.component.ts';
import { searchAndResultsModule } from 'sis-components/search/searchAndResults.dir';
import { searchFilterOptionsMenuModule } from 'sis-components/search/searchFilterOptionsMenu.component';
import { searchFilterModule } from 'sis-components/search/searchFilters/searchFilter.component';
import { searchParameterServiceModule } from 'sis-components/search/searchParameters';
import { searchParametersDirectiveModule } from 'sis-components/search/searchParameters.dir';
import { localeServiceModule } from 'sis-common/l10n/localeService';
import { plainTextFilterModule } from 'sis-components/string/plain-text.filter';
import { commonModuleServiceModule } from 'sis-components/service/module.service';
import { commonCourseUnitServiceModule } from 'sis-components/service/courseUnit.service';
import messagesTpl from './messages.tpl.html';
(function () {
  messagesController.$inject = ["$scope", "$state", "$injector", "commonMessageService", "commonModuleService", "commonCourseUnitService", "AuthService", "SearchParameters", "SearchFilterTypes", "MessageType", "defaultPromiseHandler", "messageJSDataModel", "localeService"];
  angular.module('sis-components.messenger.messages', [searchFilterModule, searchAndResultsModule, searchParametersDirectiveModule, searchFilterOptionsMenuModule, searchParameterServiceModule, localeServiceModule, commonModuleServiceModule, commonCourseUnitServiceModule, 'sis-components.date.localDateFormatFilter', 'sis-components.messenger.message', 'sis-components.service.commonMessageService', 'sis-common.errorhandler.errorService', plainTextFilterModule, 'sis-components.model.message', PaginationComponent.downgrade.moduleName]).component('messages', {
    template: messagesTpl,
    controller: messagesController,
    bindings: {
      messageSearchMethod: '<',
      messageFilterType: '<',
      messageDestinationRoute: '<',
      confinedContainer: '<',
      removeSenderStudentNumber: '<'
    }
  });
  function messagesController($scope, $state, $injector,
  // NOSONAR
  commonMessageService, commonModuleService, commonCourseUnitService, AuthService, SearchParameters, SearchFilterTypes, MessageType, defaultPromiseHandler, messageJSDataModel, localeService) {
    const $ctrl = this;
    $ctrl.messagesPerPage = 10;
    $ctrl.maxSize = 5;
    $ctrl.snippetLength = _.get($state, 'current.data.messageSnippetLength', 100);
    $ctrl.showMessagesHeading = !!_.get($state, 'current.data.showMessagesHeading');
    $ctrl.showMessageFilters = !!_.get($state, 'current.data.showMessageFilters');
    $ctrl.$onInit = () => {
      const customMethod = _.get($ctrl, 'messageSearchMethod');
      $ctrl.messageFilterType = _.get($ctrl, 'messageFilterType');
      $ctrl.messageSearchMethod = _.isFunction(customMethod) ? customMethod : commonMessageService.findAllReceivedMessages;
      $ctrl.currentPage = 1;
      $ctrl.searchParams = new SearchParameters({}, {
        allowEmptySearchString: true
      });
      $ctrl.messageTargetNamesById = new Map();
      const messageTypeOptions = _.map(_.keys(MessageType), messageTypeKey => ({
        name: `SIS_COMPONENTS.MESSENGER.MESSAGE.TYPE.${_.get(MessageType, messageTypeKey)}.NAME`,
        id: messageTypeKey
      }));
      $ctrl.searchParameterOptions = {
        searchString: {
          name: 'SEARCHSTRING',
          type: SearchFilterTypes.NO_POPOVER,
          hide: true
        },
        messageType: {
          name: 'MESSAGETYPE',
          options: messageTypeOptions,
          type: SearchFilterTypes.MULTI
        }
      };
      $ctrl.loadMessages($ctrl.removeSenderStudentNumber);
    };
    $ctrl.loadMessages = removeSenderStudentNumber => {
      const start = ($ctrl.currentPage - 1) * $ctrl.messagesPerPage;
      const searchMessageTypes = _.map(_.get($ctrl.searchParams, 'messageType.value'), 'id');
      const searchString = _.get($ctrl.searchParams, 'searchString.value');
      return $ctrl.messageSearchMethod(AuthService.personId(), null, start, $ctrl.messagesPerPage, $ctrl.messageFilterType, _.get(searchString, 'length') > 0 ? searchString : null, searchMessageTypes).then(result => {
        $ctrl.totalItems = result.total;
        $ctrl.messages = removeSenderStudentNumber ? result.messages.map(message => _objectSpread(_objectSpread({}, message), {}, {
          senderStudentNumber: null
        })) : result.messages;
        $ctrl.loadRelationsForCourseUnitRealisationMessages($ctrl.messages);
        $ctrl.resolveMessageTargetNames($ctrl.messages);
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
    $ctrl.onChange = () => $ctrl.loadMessages($ctrl.removeSenderStudentNumber);
    $ctrl.getSnippet = message => {
      const comment = _.get(message, 'comment', '') || '';
      return comment.substring(0, $ctrl.snippetLength).concat('...');
    };
    $ctrl.getLocalizedSnippet = message => {
      const comment = localeService.getLocalizedValue(_.get(message, 'localizedComment', '')) || '';
      return comment.substring(0, $ctrl.snippetLength).concat('...');
    };
    $ctrl.open = messageId => {
      $state.go(_.get($ctrl, 'messageDestinationRoute', '^.message'), {
        messageId,
        returnRoute: $state.current.name
      }, {
        notify: true,
        reload: false
      });
    };
    $ctrl.getMessageTypeTranslationKey = message => commonMessageService.getMessageTypeTranslationKey(message);
    $ctrl.getMessageTargetName = message => $ctrl.messageTargetNamesById.get(message.id);
    $ctrl.resolveMessageTargetNames = allMessages => _.forEach(allMessages, message => $ctrl.resolveMessageTargetName(message));
    $ctrl.setMessageTargetName = (id, targetName) => {
      $ctrl.messageTargetNamesById.set(id, targetName);
    };
    $ctrl.resolveMessageTargetName = message => {
      if (message.moduleId) {
        commonModuleService.findById(message.moduleId).then(module => localeService.getLocalizedValue(module.name)).then(targetName => $ctrl.setMessageTargetName(message.id, targetName));
      }
      if (message.courseUnitId) {
        commonCourseUnitService.findById(message.courseUnitId).then(courseUnit => localeService.getLocalizedValue(courseUnit.name)).then(targetName => $ctrl.setMessageTargetName(message.id, targetName));
      }
      if (message.customStudyDraft) {
        $ctrl.setMessageTargetName(message.id, message.customStudyDraft.name);
      }
    };
    $ctrl.searchService = {
      search() {
        return $ctrl.loadMessages($ctrl.removeSenderStudentNumber);
      }
    };
    $ctrl.hasValidSearchParameters = () => $ctrl.searchParams.searchString.isValidForSearch() || $ctrl.searchParams.messageType.isValidForSearch();
    $ctrl.loadRelationsForCourseUnitRealisationMessages = allMessages => {
      _.forEach(_.filter(allMessages, filterCourseUnitRealisationMessages), message => messageJSDataModel.loadRelations(message, ['courseUnitRealisation']));
    };
    $ctrl.loadRelationsForCourseUnitMessages = allMessages => {
      _.forEach(_.filter(allMessages, message => _.has(message, 'courseUnitId')), message => messageJSDataModel.loadRelations(message, ['courseUnit']));
    };
    $ctrl.showSenderFullName = message => !message.isAutomaticCourseUnitRealisationMessage && !message.isCourseUnitManualEvaluationRequiredMessage;
    function filterCourseUnitRealisationMessages(message) {
      return message.isManualCourseUnitRealisationMessage || message.isAutomaticCourseUnitRealisationMessage;
    }
  }
})();