<div *ngIf="(responsibilityInfo?.personId || (responsibilityInfo?.text | localizedStringExistingLocales)?.length) && (showInactiveResponsibilities || !responsibilityInfo?.validityPeriod || (responsibilityInfo.validityPeriod | dateRangeOngoing))"
  class="person">
  <span class="person-name" *ngIf="responsibilityInfo?.personId|publicPerson|async as person">
    <span>{{(person|personFullName)}}</span>
    <span *ngIf="person.emailAddress?.length"> ({{person.emailAddress}})</span>
  </span>
  <span [ngClass]="{'person-name': !responsibilityInfo?.personId}" *ngIf="responsibilityInfo?.text">{{ responsibilityInfo?.personId ? ',' : '' }} {{ responsibilityInfo.text | localizedString}}</span>
  <span *ngIf="responsibilityInfo?.roleUrn|codeLocalizedName|async as roleName">{{', ' + roleName}}</span>
  <span *ngIf="showResponsibilityValidityPeriod && responsibilityInfo?.validityPeriod && (responsibilityInfo.validityPeriod.endDate || responsibilityInfo.validityPeriod.startDate)" class="dates">,
    {{ responsibilityInfo.validityPeriod | localDateRange }}
  </span>
</div>
