<nav [attr.aria-label]="'ARIA_LABEL.LANGUAGE_MENU' | translate">
  <div ngbDropdown display="static" class="nav-menu" #dropdown=ngbDropdown>
    <button type="button" ngbDropdownToggle class="non-button" [attr.aria-label]="currentLanguageName">
      <span class="lang-desktop">{{currentLanguageName}}</span><span class="lang-mobile">{{currentLanguage}}</span>
      <sis-icon icon="chevron" color="white" class="user-arrow" [iconClass]="'cw-90'"></sis-icon>
    </button>
    <div ngbDropdownMenu class="dropdown-menu-right">
      <div *ngIf="languageSelections?.length > 0" class="language-selections" aria-labelledby="change-language" role="group">
        <div class="dropdown-header" aria-hidden="true" id="change-language">{{'AUTH.CHANGE_LANGUAGE' | translate}}</div>
        <button type="button"
                ngbDropdownItem
                *ngFor="let selection of languageSelections"
                (click)="changeLanguage(selection.language); dropdown.close()"
                (keydown.enter)="changeLanguage(selection.language); dropdown.close()"
                [class.active]="selection.language === currentLanguage"
                [attr.aria-current]="selection.language === currentLanguage">
          {{selection.name}}
          <sis-icon icon="check" *ngIf="selection.language === currentLanguage"></sis-icon>
        </button>
      </div>
    </div>
  </div>
</nav>
