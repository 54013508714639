import { Transition } from '@uirouter/angular';
import { IInjectable } from '@uirouter/angularjs';

import { ConfirmDialogService } from '../confirm/confirm-dialog.service';

/**
 * A conditional type used to determine the type of a value returned by the injector based on the type of the
 * injection token used to get that value:
 * - If the token is a class (represented as a constructor function), resolves to an instance of that class.
 * - If the token is a string, resolves to `any`.
 * - Otherwise, resolves to `never`.
 */
export type Injectable<T> = T extends new (...args: any[]) => infer R ? R : T extends string ? any : never;

/**
 * A helper function for getting several values from the `UIInjector` of the given transition in a type-safe way.
 * Helps to reduce boilerplate e.g. in UI-Router transition hooks or the custom `displayNameFunction` headerParam.
 *
 * The input tokens can be either strings or classes (e.g. Angular services). The return type for a string token is
 * `any`, while the return type for a class token is an instance of that class. E.g.:
 * ```
 * const [localeService, translateService] = getInjectables(transition, 'localeService', TranslateService);
 * ```
 * Above, the type of `localeService` is `any`, while the type of `translateService` is `TranslateService`.
 */
export function getInjectables<T1, T2, T3, T4>(
    transition: Transition,
    token1?: T1,
    token2?: T2,
    token3?: T3,
    token4?: T4,
): [Injectable<T1>, Injectable<T2>, Injectable<T3>, Injectable<T4>];
export function getInjectables(transition: Transition, ...tokens: any[]): any[] {
    return tokens.map(token => transition.injector().get(token));
}

/**
 * Returns a resolver function that can be used in the `resolve` object of a UIRouter state definition. Returns a
 * function which extracts the value of the transition parameter with the given name, or `undefined` if no parameter
 * with that name exists. Useful e.g. when a query/path parameter from the URL needs to be provided to the component
 * in the state definition as an input value.
 *
 * @param name The name of the transition parameter to resolve
 */
export function resolveTransitionParameter(name: string): IInjectable {
    const resolver = (transition: Transition) => transition?.params?.()?.[name];
    resolver.$inject = ['$transition$'];
    return resolver;
}

export function confirmOnExit($transition$: Transition) {
    const confirmDialogService = $transition$.injector().get(ConfirmDialogService);
    if ($transition$.$to().name === $transition$.$from().name || $transition$.options().custom.skipConfirmationDialog) {
        return;
    }
    return confirmDialogService.confirm({
        title: 'CONFIRM_ON_EXIT_MODAL.TITLE',
        description: 'CONFIRM_ON_EXIT_MODAL.DESCRIPTION',
    });
}

confirmOnExit.$inject = ['$transition$'];
