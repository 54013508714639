import angular from 'angular';
import _ from 'lodash';
import { ModalService } from 'sis-common/modal/modal.service.ts';
import { commonEducationServiceModule } from 'sis-components/service/education.service';
import { StudyRightExtensionRequirementsModalComponent } from './studyRightExtensionRequirementsModal.component.ts';
import studyRightExtensionRequirementsModalTpl from './studyRightExtensionRequirementsModal.tpl.html';
(function () {
  studyRightExtensionRequirementsModalController.$inject = ["commonEducationService"];
  studyRightExtensionRequirementsModalService.$inject = ["modalService"];
  angular.module('student.studyRightExtensionRequirementsModal', [ModalService.downgrade.moduleName, commonEducationServiceModule]).factory('studyRightExtensionRequirementsModal', studyRightExtensionRequirementsModalService).controller('studyRightExtensionRequirementsModalController', studyRightExtensionRequirementsModalController).component('studyRightExtensionRequirementsModal', {
    bindings: {
      close: '&',
      resolve: '<'
    },
    controller: studyRightExtensionRequirementsModalController,
    template: studyRightExtensionRequirementsModalTpl
  });

  /**
   * @ngInject
   */
  function studyRightExtensionRequirementsModalService(modalService) {
    return {
      open: studyRightExtensionRequirements => modalService.open(StudyRightExtensionRequirementsModalComponent, {
        studyRightExtensionRequirements
      }, {
        size: 'sm'
      }).result
    };
  }

  /**
   * @ngInject
   */
  function studyRightExtensionRequirementsModalController(commonEducationService) {
    const $ctrl = this;
    $ctrl.$onInit = function () {
      $ctrl.studyRightExtensionRequirements = $ctrl.resolve.studyRightExtensionRequirements;
      commonEducationService.findById($ctrl.studyRightExtensionRequirements.studyRight.educationId).then(education => {
        $ctrl.education = education;
      });
      $ctrl.untimedCourseUnits = !_.isEmpty(_.get($ctrl.studyRightExtensionRequirements, 'courseUnitIdsWithNoTiming'));
      $ctrl.untimedCustomStudyDrafts = !_.isEmpty(_.get($ctrl.studyRightExtensionRequirements, 'customStudyDraftIdsWithNoTiming'));
      $ctrl.courseUnitsTimedInThePast = !_.isEmpty(_.get($ctrl.studyRightExtensionRequirements, 'courseUnitIdsWithTimingInThePast'));
      $ctrl.customStudyDraftsTimedInThePast = !_.isEmpty(_.get($ctrl.studyRightExtensionRequirements, 'customStudyDraftIdsWithTimingInThePast'));
      $ctrl.untimedStudies = $ctrl.untimedCourseUnits || $ctrl.untimedCustomStudyDrafts;
      $ctrl.studiesTimedInThePast = $ctrl.courseUnitsTimedInThePast || $ctrl.customStudyDraftsTimedInThePast;
    };
    $ctrl.proceedToPlanOrTiming = function () {
      $ctrl.close({
        $value: _.get($ctrl.studyRightExtensionRequirements, 'primaryPlan.id')
      });
    };
  }
})();