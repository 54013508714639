import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import { locationModelModule } from 'sis-components/model/location.model';
import { commonStudyEventServiceModule } from 'sis-components/service/studyEvent.service';
import { jsDataCacheHelperModule } from 'sis-components/service/jsDataCacheHelper.service';
import { ShowMoreComponent } from 'sis-components/show-more/show-more.component.ts';
import { studyEventSummaryModule } from './studyEventSummary.component';
import studySubGroupEventInfoTpl from './studySubGroupEventInfo.tpl.html';
export const studySubGroupEventInfoModule = 'student.calendar.component.studySubGroupEventInfo';
(function () {
  studySubGroupEventInfoController.$inject = ["commonStudyEventService", "locationJSDataModel", "jsDataCacheHelper"];
  angular.module(studySubGroupEventInfoModule, ['pascalprecht.translate', studyEventSummaryModule, commonStudyEventServiceModule, locationModelModule, jsDataCacheHelperModule, commonStudyEventServiceModule, ShowMoreComponent.downgrade.moduleName]).component('studySubGroupEventInfo', {
    template: studySubGroupEventInfoTpl,
    bindings: {
      studySubGroup: '<',
      onViewInCalendar: '<?',
      exceptionsVisible: '<?'
    },
    controller: studySubGroupEventInfoController
  });

  /**
   * @ngInject
   */

  function studySubGroupEventInfoController(commonStudyEventService, locationJSDataModel, jsDataCacheHelper) {
    const $ctrl = this;
    $ctrl.$onInit = () => {
      $ctrl.irregularEvents = $ctrl.getIrregularEvents();
      if ($ctrl.exceptionsVisible) {
        $ctrl.getIrregularLocations();
      }
      $ctrl.showEventsWithExceptions = !!$ctrl.exceptionsVisible;
    };
    $ctrl.toggleExceptions = () => {
      if ($ctrl.showEventsWithExceptions === false) {
        $ctrl.getIrregularLocations();
      }
      $ctrl.showEventsWithExceptions = !$ctrl.showEventsWithExceptions;
    };
    $ctrl.getIrregularLocations = () => {
      const irregularLocationIds = _.compact(_.flatMap($ctrl.irregularEvents, 'irregularLocationIds'));
      jsDataCacheHelper.findByIds(locationJSDataModel, irregularLocationIds).then(result => {
        $ctrl.locationsById = _.keyBy(result, 'id');
      });
    };
    $ctrl.getOrderedStudyEvents = () => commonStudyEventService.orderStudyEventsByTime($ctrl.studySubGroup.studyEvents);
    $ctrl.getEventWeekDayAndDate = dateTime => moment(dateTime).format('dd D.M.');
    $ctrl.getEventTimeRange = event => {
      return _.join([moment(event.start).format('H.mm'), moment(event.end).format('H.mm')], '–');
    };
    $ctrl.getLocation = locationId => _.get($ctrl.locationsById, locationId);
    $ctrl.hasStudyEvents = studySubGroup => {
      if (studySubGroup) {
        return !_.isEmpty(studySubGroup.studyEvents);
      }
      return false;
    };
    $ctrl.getIrregularEvents = () => commonStudyEventService.getIrregularEventsForStudent($ctrl.studySubGroup.studyEvents);
  }
})();