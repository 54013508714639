<sis-description-list [columnCount]="2">
  <dl>
    <ng-template #listItem>
      <dt>{{'STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_ORGANISATION' | translate}}</dt>
      <dd>{{this.priorStudies.organisation}}</dd>
    </ng-template>
    <ng-template #listItem>
      <dt>{{'STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_ATTAINMENT_DATE' | translate}}</dt>
      <dd>{{this.priorStudies.attainmentDate | localDateFormat}}</dd>
    </ng-template>
    <ng-template #listItem>
      <dt>{{'STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_CREDITS' | translate}}</dt>
      <dd>{{this.priorStudies.credits}}</dd>
    </ng-template>
    <ng-template #listItem>
      <dt>{{'STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_ATTAINMENT_LANGUAGE' | translate}}</dt>
      <dd>{{this.priorStudies.attainmentLanguage | codeLocalizedName | async}}</dd>
    </ng-template>
    <ng-template #listItem>
      <dt>{{'STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_GRADE' | translate}}</dt>
      <dd>{{this.priorStudies.grade}}</dd>
    </ng-template>
    <ng-template #listItem>
      <dt>{{'STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_GRADE_SCALE' | translate}}</dt>
      <dd>{{this.priorStudies.gradeScale}}</dd>
    </ng-template>
    <ng-template #listItem>
      <dt>{{'STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_DESCRIPTION' | translate}}</dt>
      <dd>{{priorStudies.description}}</dd>
    </ng-template>
  </dl>
</sis-description-list>



