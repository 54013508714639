<ng-container *transloco="let t">
  <nav *ngIf="showBreadcrumbs" [attr.aria-label]="t('ARIA_LABEL.BREADCRUMBS')" class="sis-mb-md">
    <ol class="breadcrumb">
      <li *ngIf="breadcrumbs.length > 1 || showHomeNode === true" class="home">
        <fudis-link [href]="homeUrl" [title]="t('SIS_COMPONENTS.BREADCRUMB.HOME')"></fudis-link>
        <sis-icon icon="chevron"></sis-icon>
      </li>
      <ng-container *ngFor="let item of breadcrumbs; last as isLast">
        <li *ngIf="!isLast">
          <a fudisLink *ngIf="item.isLink" [uiSref]="item.route" [color]="'gray-dark'">{{item.displayName}}</a>
          <span *ngIf="!item.isLink">{{item.displayName}}</span>
          <sis-icon icon="chevron"></sis-icon>
        </li>
        <span *ngIf="isLast && showLastNode === true" aria-current="page">{{item.displayName}}</span>
        <span *ngIf="isLast && showLastNode === false" class="visually-hidden" aria-current="page">{{item.displayName}}</span>
      </ng-container>
    </ol>
  </nav>
</ng-container>
