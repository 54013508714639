import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import { PlanValidationTs } from 'common-typescript';
(function () {
  studyRightExtensionService.$inject = ["$q", "commonPlanService", "commonStudyPeriodService", "universityService", "commonStudentAttainmentService"];
  angular.module('sis-components.service.studyRightExtensionService', ['sis-components.service.planService', 'sis-components.service.studyPeriodService', 'sis-common.university', 'sis-components.service.studentAttainmentService']).factory('studyRightExtensionService', studyRightExtensionService);

  /**
   * @ngInject
   */
  function studyRightExtensionService($q, commonPlanService, commonStudyPeriodService, universityService, commonStudentAttainmentService) {
    const api = {
      getPlanState: function (plan) {
        return commonPlanService.getValidatablePlan(plan, false, false).then(validatablePlan => {
          const planValidationResult = PlanValidationTs.validatePlan(validatablePlan);
          return _.get(planValidationResult, 'rootValidationResult.state');
        });
      },
      getTimingRequiredInfo: plan => api.getCurrentStudyPeriod().then(currentStudyPeriod => {
        if (!currentStudyPeriod) {
          return null;
        }
        return commonStudentAttainmentService.findValidAttainmentsByPersonId(plan.userId, false).then(attainments => api.getStudiesRequiringTiming(plan, attainments, currentStudyPeriod.locator));
      }),
      getStudiesRequiringTiming: (plan, allAttainments, currentStudyPeriodLocator) => {
        const attainedCourseUnitIds = _.map(_.filter(allAttainments, {
          type: 'CourseUnitAttainment'
        }), 'courseUnitId');
        const courseUnitSelections = _.get(plan, 'courseUnitSelections') || [];
        const courseUnitSelectionsRequiringTiming = _.filter(courseUnitSelections, courseUnitSelection => {
          if (_.has(courseUnitSelection, 'substitutedBy') && courseUnitSelection.substitutedBy.length > 0) {
            return false;
          }
          if (_.includes(attainedCourseUnitIds, courseUnitSelection.courseUnitId)) {
            return false;
          }
          return true;
        });
        const courseUnitIdsWithNoTiming = [];
        const courseUnitIdsWithTimingInThePast = [];
        _.forEach(courseUnitSelectionsRequiringTiming, courseUnitSelection => {
          if (_.isEmpty(courseUnitSelection.plannedPeriods)) {
            courseUnitIdsWithNoTiming.push(courseUnitSelection.courseUnitId);
          } else if (commonStudyPeriodService.getOrderOfStudyPeriodLocators(currentStudyPeriodLocator, _.last(courseUnitSelection.plannedPeriods)) === 1) {
            courseUnitIdsWithTimingInThePast.push(courseUnitSelection.courseUnitId);
          }
        });
        const customStudyDraftIdsWithNoTiming = [];
        const customStudyDraftIdsWithTimingInThePast = [];
        const customStudyDrafts = _.get(plan, 'customStudyDrafts');
        _.forEach(customStudyDrafts, customStudyDraft => {
          if (_.isEmpty(customStudyDraft.plannedPeriods)) {
            customStudyDraftIdsWithNoTiming.push(customStudyDraft.id);
          } else if (commonStudyPeriodService.getOrderOfStudyPeriodLocators(currentStudyPeriodLocator, _.last(customStudyDraft.plannedPeriods)) === 1) {
            customStudyDraftIdsWithTimingInThePast.push(customStudyDraft.id);
          }
        });
        return {
          courseUnitIdsWithNoTiming,
          courseUnitIdsWithTimingInThePast,
          customStudyDraftIdsWithNoTiming,
          customStudyDraftIdsWithTimingInThePast
        };
      },
      getCurrentStudyPeriod: () => {
        const today = moment();
        const startYear = today.year() - 1;
        const currentUniversityOrgId = universityService.getCurrentUniversityOrgId();
        return commonStudyPeriodService.getStudyPeriods(currentUniversityOrgId, startYear, 2).then(studyPeriods => {
          return _.find(studyPeriods, studyPeriod => {
            return today.isBetween(studyPeriod.valid.startDate, studyPeriod.valid.endDate, 'day', '[)');
          });
        });
      },
      checkStudyRightExtensionRequirements: function (userId, expirationInfo) {
        const studyRight = _.get(expirationInfo, 'studyRight');
        let planState;
        let timingRequirements;
        return commonPlanService.findAllByUserId(userId, true, false).then(plans => {
          const primaryPlan = _.find(plans, plan => plan.educationId === studyRight.educationId && plan.learningOpportunityId === studyRight.learningOpportunityId && plan.primary === true);
          let planStatePromise;
          let timingPromise;
          if (!primaryPlan) {
            planStatePromise = $q.when();
            timingPromise = $q.when();
          } else {
            planStatePromise = api.getPlanState(primaryPlan).then(result => {
              planState = result;
            });
            timingPromise = api.getTimingRequiredInfo(primaryPlan).then(result => {
              timingRequirements = result;
            });
          }
          return $q.all([planStatePromise, timingPromise]).then(() => {
            const studyRightExtensionRequirements = {
              planState,
              primaryPlan,
              studyRight,
              courseUnitIdsWithNoTiming: _.get(timingRequirements, 'courseUnitIdsWithNoTiming') || [],
              courseUnitIdsWithTimingInThePast: _.get(timingRequirements, 'courseUnitIdsWithTimingInThePast') || [],
              customStudyDraftIdsWithNoTiming: _.get(timingRequirements, 'customStudyDraftIdsWithNoTiming') || [],
              customStudyDraftIdsWithTimingInThePast: _.get(timingRequirements, 'customStudyDraftIdsWithTimingInThePast') || []
            };
            return studyRightExtensionRequirements;
          });
        });
      }
    };
    return api;
  }
})();