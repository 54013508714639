import { ContextualNotificationComponent } from 'sis-components/contextual-notification/contextual-notification.component';
import { IconComponent } from 'sis-components/icon/icon.component';
import attainmentTpl from './attainment.tpl.html';
import { PrintTranscriptModalComponent } from './print-transcript-modal/print-transcript-modal.component';
import { AttainmentDetailsModalComponent } from './attainment-details/attainment-details-modal/attainment-details-modal.component';
angular.module('student.profile.studentAttainments', ['pascalprecht.translate', 'sis-common.auth', 'sis-components.attainment.tree', 'sis-components.service.studentAttainmentService', 'sis-components.service.validAttainmentFilterService', 'sis-components.menuButton', 'sis-common.university', ContextualNotificationComponent.downgrade.moduleName, IconComponent.downgrade.moduleName, AttainmentDetailsModalComponent.downgrade.moduleName]).component('studentAttainments', {
  template: attainmentTpl,
  bindings: {
    modules: '<',
    courseUnits: '<',
    assessmentItems: '<',
    attainmentsDisplayed: '<',
    allAttainments: '<'
  },
  controller: ["$state", "$http", "$translate", "commonStudentAttainmentService", "modalService", "defaultPromiseHandler", "universityService", function ($state, $http, $translate, commonStudentAttainmentService, modalService, defaultPromiseHandler, universityService) {
    const $ctrl = this;
    $ctrl.$onInit = () => {
      $ctrl.transcriptPrintingEnabledByUniversity = false;
      universityService.getCurrentUniversitySettings().then(universitySettings => {
        if (_.get(universitySettings, 'frontendFeatureToggles.studentTranscriptPrintable') !== false) {
          $ctrl.transcriptPrintingEnabledByUniversity = true;
        }
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
    $ctrl.onAttainmentClick = attainmentRow => {
      $ctrl.attainmentRow = attainmentRow;
      const allMatchingSecondaryAttainments = commonStudentAttainmentService.getSecondaryAttainments(attainmentRow.attainment, $ctrl.allAttainments);
      const secondaryAttainments = _.filter(allMatchingSecondaryAttainments, {
        misregistration: false
      });
      $ctrl.secondaryAttainments = secondaryAttainments;
      if (!$ctrl.modalOpen) {
        // clicking a DegreeProgrammeAttainment row triggers onAttainmentClick twice
        $ctrl.modalOpen = true;
        openAttainmentDetailsModal(attainmentRow, secondaryAttainments);
      }
    };
    $ctrl.printAttainments = () => {
      modalService.open(PrintTranscriptModalComponent, {});
    };
    $ctrl.tabs = [{
      path: 'student.logged-in.profile.attainment.valid-attainments',
      title: 'PROFILE.ATTAINMENT.VALID_ATTAINMENTS',
      controls: 'tab-panel-valid-attainments'
    }, {
      path: 'student.logged-in.profile.attainment.failed-and-expired-attainments',
      title: 'PROFILE.ATTAINMENT.FAILED_AND_EXPIRED_ATTAINMENTS',
      controls: 'tab-panel-failed-and-expired-attainments'
    }];
    function openAttainmentDetailsModal(attainmentRow, secondaryAttainments) {
      const {
        attainment,
        hasValidParentAttainment,
        isAboutToExpire
      } = attainmentRow;
      modalService.open(AttainmentDetailsModalComponent, {
        attainment,
        secondaryAttainments,
        hasValidParentAttainment,
        isAboutToExpire
      }, {
        size: 'md'
      }).result.finally(() => {
        $ctrl.modalOpen = false;
      });
    }
  }]
});