import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { PrivatePerson, SearchResult, StudentWorkflow, Workflow } from 'common-typescript/types';
import { take, tap } from 'rxjs/operators';
import {
    SortAndPaginationEvent,
} from 'sis-components/applications/student-workflow-list/student-workflow-list.component';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { WorkflowEntityService } from 'sis-components/service/workflow-entity.service';

import { StudentApplicationRoutingService } from './student-application-routing.service';

@Component({
    selector: 'app-student-applications',
    templateUrl: './student-applications.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class StudentApplicationsComponent implements OnInit {

    readonly defaultSortAndPaginationEvent: SortAndPaginationEvent = {
        sortColumn: 'creationTime',
        reverse: true,
        start: 0,
        itemsPerPage: 20,
    };

    constructor(
        private workflowEntityService: WorkflowEntityService,
        private routingService: StudentApplicationRoutingService,
        protected appErrorHandler: AppErrorHandler,
    ) { }

    @Input() profile: PrivatePerson;

    searchResult: SearchResult<StudentWorkflow>;
    sortAndPaginationEvent: SortAndPaginationEvent;

    ngOnInit(): void {
        this.loadApplications(this.defaultSortAndPaginationEvent);
    }

    loadApplications(event: SortAndPaginationEvent): void {
        const reverse = !!event.reverse;
        const sort = (reverse ? '-' : '') + event.sortColumn;

        const searchParams = {
            limit: event.itemsPerPage,
            studentId: this.profile.id,
            start: event.start,
            sort,
        };

        this.sortAndPaginationEvent = event;

        this.workflowEntityService.search(searchParams)?.pipe(
            take(1),
            tap((result: SearchResult<StudentWorkflow>) => {
                this.searchResult = result;
            }),
            this.appErrorHandler.defaultErrorHandler(),
        ).subscribe();
    }

    openWorkflow(workflow: Workflow): void {
        this.routingService.goToApplication(workflow);
    }

}
