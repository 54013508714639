import _ from 'lodash';
import angular from 'angular';
import { configModule } from 'sis-common/config/config.module.ts';
import { localeServiceModule } from 'sis-common/l10n/localeService';
import { commonUniversityServiceModule } from 'sis-common/university/university.service.ts';
import { IconComponent } from 'sis-components/icon/icon.component.ts';
import universityIFrameTpl from './universityIFrame.tpl.html';
(function () {
  universityIFrameController.$inject = ["$element", "$scope", "$log", "$location", "$window", "universityService", "localeService"];
  angular.module('sis-components.universityIFrame', [configModule, localeServiceModule, commonUniversityServiceModule, IconComponent.downgrade.moduleName]).directive('universityIframe', universityIFrameDirective).controller('universityIFrameController', universityIFrameController);

  /**
   * @ngInject
   */
  function universityIFrameDirective() {
    return {
      scope: {},
      controller: 'universityIFrameController',
      controllerAs: 'ctrl',
      bindToController: true,
      template: universityIFrameTpl
    };
  }

  /**
   * @ngInject
   */
  function universityIFrameController($element, $scope, $log, $location, $window, universityService, localeService) {
    const ctrl = this;

    // The close button exists in order to provide a fool proof method of closing the university nav bar in case the
    // university managed nav bar contents are broken in such a way that it does not support closing.
    ctrl.toggle = toggle;
    ctrl.isOpen = false;
    ctrl.getUniversityName = getUniversityName;
    ctrl.moveFocusToElement = moveFocusToElement;
    let iFrameEl;
    let angIFrameEl;
    const closingButton = angular.element(document.getElementById('close-university-iframe'));
    const backshroud = angular.element(document.getElementById('university-iframe-backshroud'));
    let childOrigin = '*';
    init();
    function init() {
      getElements();
      updateContentUrl();
      $window.addEventListener('message', receiveMessage, false);
      $scope.$on('$localeChangeSuccess', updateContentUrl);
    }
    function updateContentUrl() {
      universityService.getCurrentUniversitySettings().then(settings => {
        const lang = localeService.getCurrentLocale();
        ctrl.contentUrl = _.get(settings, `universityNaviContentUrl.${lang}`, '');

        // onload reset needed, otherwise locale change would open iframe.
        iFrameEl.onload = function () {};
        iFrameEl.src = ctrl.contentUrl;
        childOrigin = getOrigin();
      });
    }

    // Need to get the iframe element to exchange messages with it
    function getElements() {
      iFrameEl = getElement('#universityNavIFrame');
      angIFrameEl = angular.element(iFrameEl);
    }
    function getOrigin() {
      const re = /^http[s]?[:]?\/\/[^\/]*/;
      const fromContentUrl = re.exec(ctrl.contentUrl);
      if (fromContentUrl && fromContentUrl.length === 1) {
        return fromContentUrl[0];
      }
      // For local dev env we have a relative url for iframe src, so we use the current location for origin.
      return `${$location.protocol()}://${$location.host()}:${$location.port()}`;
    }
    function getElement(selector) {
      const matching = $element.find(selector);
      if (matching.length > 0) {
        return matching[0];
      }
      $log.error(`Could not find element ${selector} for university specific navigation. `);
    }
    function toggle() {
      if (ctrl.isOpen) {
        closeIFrame();
      } else {
        openIFrame();
      }
    }
    function getUniversityName() {
      return universityService.getCurrentUniversity().name;
    }
    function moveFocusToElement(location) {
      const targetElement = document.getElementById(location);
      if (targetElement) {
        targetElement.focus();
      }
    }
    function openIFrame() {
      ctrl.isOpen = true;

      // Reset the src in case user has previously navigated to some other location in the iframe
      iFrameEl.src = ctrl.contentUrl;

      // add this back to get esc closing functionality at a later point:
      // window.addEventListener("keyup", escHandler);
      iFrameEl.onload = function () {
        iFrameEl.contentWindow.postMessage('open', '*');
      };
    }
    function closeIFrame() {
      ctrl.isOpen = false;
      angIFrameEl.removeClass('opened').addClass('closed');
      window.removeEventListener('keyup', escHandler);
      moveFocusToElement('open-university-iframe-button');
    }
    function visualizeIFrameOpening() {
      angIFrameEl.removeClass('closed').addClass('opened');
      moveFocusToElement('close-university-iframe-button');
    }
    function escHandler(event) {
      if (event.keyCode === 27) {
        closeIFrame();
        $scope.$digest();
      }
    }
    function extractDomain(url) {
      let domain;
      // find & remove protocol (http, ftp, etc.) and get domain
      if (url.indexOf('://') > -1) {
        domain = url.split('/')[2];
      } else {
        domain = url.split('/')[0];
      }

      // find & remove port number
      domain = domain.split(':')[0];
      return domain;
    }
    function receiveMessage(message) {
      const origin = message.origin || message.originalEvent.origin;
      if (extractDomain(origin) !== extractDomain(childOrigin)) {
        return;
      }
      if (message.data === 'close') {
        closeIFrame();
        $scope.$digest();
      }
      if (message.data === 'opened') {
        // iframe content has loaded
        visualizeIFrameOpening();
      }

      // if height message received, set height
      if (typeof message.data === 'string' && message.data.indexOf('height-', 0) === 0) {
        const newHeight = message.data.substr(message.data.indexOf('-') + 1);
        // Leave this out, this should be set in the iframe target already
        // document.getElementById('universityNavIFrame').contentWindow.document.body.style.overflow = 'hidden';
        iFrameEl.style.height = `${newHeight}px`;
        closingButton.css('top', `${Number(newHeight) + 50}px`);
      }
    }
  }
})();