<ng-container *transloco="let t">
  <div class="sis-plan-structure-education">
    <div class="sis-plan-structure-education__header">
      <div class="row">
        <div class="col-12">
          <h2>{{ education?.name | localizedString }}
            <sis-plan-structure-module-state-badges [documentState]="education.documentState">
            </sis-plan-structure-module-state-badges>
          </h2>
        </div>
      </div>
      <div class="row sis-plan-structure-education__header__additional-info">
        <div class="col-md col-sm-12"><p class="sis-mb-zero">{{education?.code}}</p></div>
        <div class="col-md col-sm-12 mt-3 mt-sm-0">
          <sis-plan-structure-module-selection-state class="float-sm-start float-md-end"
                                                     [stateObject]="planStateObject.education">
          </sis-plan-structure-module-selection-state>
        </div>
        <div class="col-12">
          <hr class="sis-hr">
        </div>
      </div>
    </div>
    <sis-plan-structure-module-content [moduleOrEducation]="education"
                                       [planValidationResult]="planValidationResult"
                                       [planData]="planData"
                                       [planStateObject]="planStateObject"
                                       [moduleOrEducationStateObject]="planStateObject?.education"
                                       [headingLevel]="headingLevel + 1">
    </sis-plan-structure-module-content>
  </div>
</ng-container>



