import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OtmId, StudyRight, TranscriptRequest } from 'common-typescript/types';
import _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AUTH_SERVICE } from 'sis-common/ajs-upgraded-modules';
import { AlertsService } from 'sis-components/alerts/alerts-ng.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { oneChildValueSelected, oneChildValueSelectedIf } from 'sis-components/form/form-validators';
import { getLabelState } from 'sis-components/form/formUtils';
import { SisFormBuilder } from 'sis-components/form/sis-form-builder.service';
import { PainoRequestService } from 'sis-components/service/paino-request.service';
import { StudyRightEntityService } from 'sis-components/service/study-right-entity.service';

interface LanguageOption {
    formKey: string;
    translationKey: string;
}

@Component({
    selector: 'app-print-transcript-modal',
    templateUrl: './print-transcript-modal.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class PrintTranscriptModalComponent implements OnInit, OnDestroy {

    constructor(private formBuilder: SisFormBuilder,
                private alertsService: AlertsService,
                private modal: NgbActiveModal,
                private studyRightEntityService: StudyRightEntityService,
                private ref: ChangeDetectorRef,
                private appErrorHandler: AppErrorHandler,
                @Inject(AUTH_SERVICE) private authService: any,
                private painoRequestService: PainoRequestService) { }

    languageOptions: LanguageOption[] = [
        {
            formKey: 'fi',
            translationKey: 'PROFILE.PRINT_TRANSCRIPT_MODAL.FINNISH',
        },
        {
            formKey: 'sv',
            translationKey: 'PROFILE.PRINT_TRANSCRIPT_MODAL.SWEDISH',
        },
        {
            formKey: 'en',
            translationKey: 'PROFILE.PRINT_TRANSCRIPT_MODAL.ENGLISH',
        },
    ];

    subscriptions: Subscription[] = [];

    form: FormGroup;
    studentStudyRights$: Observable<StudyRight[]>;
    studentId: OtmId;

    ngOnInit(): void {
        this.studentId = this.authService.personId();
        this.form = this.buildForm();
        this.studentStudyRights$ = this.loadStudentStudyRights();
        this.initSubscriptions();
    }

    ngOnDestroy() {
        this.destroySubscriptions();
    }

    initSubscriptions(): void {
        this.subscriptions.push(this.fullTranscriptSelected.valueChanges.subscribe(
            (isFullTranscript: boolean) => {
                if (!isFullTranscript) {
                    this.selectStudyRightIfThereIsOnlyOne();
                }
                this.selectedStudyRights.updateValueAndValidity();
                this.ref.detectChanges();
            }));
    }

    destroySubscriptions(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

    loadStudentStudyRights(): Observable<StudyRight[]> {
        return this.studyRightEntityService.getStudyRightsByStudentId(this.studentId)
            .pipe(
                this.appErrorHandler.defaultErrorHandler(),
                map(studyRights => _.sortBy(studyRights, 'valid.startDate')),
                tap(studyRights => this.form.addControl('selectedStudyRights', this.buildStudyRightSelectionForm(studyRights))),
            );
    }

    buildForm(): FormGroup {
        return this.formBuilder.group({
            fullTranscriptSelected: this.formBuilder.control(true),
            selectedLanguages: this.buildLanguageSelectionsForm(),
            includeAssessmentItems: this.formBuilder.control(false),
        });
    }

    buildLanguageSelectionsForm(): FormGroup {
        const languageControls: any = {};
        _.forEach(this.languageOptions, (option: LanguageOption) => {
            languageControls[option.formKey] = this.formBuilder.control(false);
        });
        return this.formBuilder.group(languageControls, { validators: [
            oneChildValueSelected('PROFILE.PRINT_TRANSCRIPT_MODAL.LANGUAGE_VALIDATION_ERROR'),
        ] });
    }

    buildStudyRightSelectionForm(studyRights: StudyRight[]): FormGroup {
        const studyRightSelectionControls: any = {};
        _.forEach(studyRights, (studyRight: StudyRight) => {
            studyRightSelectionControls[studyRight.id] = this.formBuilder.control(false);
        });
        return this.formBuilder.group(studyRightSelectionControls, { validators: [
            oneChildValueSelectedIf(
                () => !this.fullTranscriptSelected.value,
                'PROFILE.PRINT_TRANSCRIPT_MODAL.TRANSCRIPT_SELECTION.STUDY_RIGHT_TRANSCRIPTS_VALIDATION_ERROR',
            ),
        ] });
    }

    get fullTranscriptSelected(): FormControl {
        return this.form.get('fullTranscriptSelected') as FormControl;
    }

    get fullTranscriptSelectedLabelState(): string {
        return this.fullTranscriptSelected.value ? getLabelState(this.fullTranscriptSelected) : this.selectedStudyRightsLabelState;
    }

    get selectedStudyRights(): FormGroup {
        return this.form.get('selectedStudyRights') as FormGroup;
    }

    getSelectedStudyRightSelectionControl(studyRightId: OtmId): FormControl {
        return this.selectedStudyRights.get(studyRightId) as FormControl;
    }

    get selectedStudyRightsLabelState(): string {
        return getLabelState(this.selectedStudyRights);
    }

    get selectedLanguages(): FormGroup {
        return this.form.get('selectedLanguages') as FormGroup;
    }

    selectedLanguagesLabelState(): string {
        return getLabelState(this.selectedLanguages);
    }

    getLanguageSelectionControl(language: string): FormControl {
        return this.selectedLanguages.get(language) as FormControl;
    }

    get includeAssessmentItems(): FormControl {
        return this.form.get('includeAssessmentItems') as FormControl;
    }

    cancel(): void {
        this.modal.dismiss();
    }

    sendRequest(): void {
        this.form.markAllAsTouched();
        this.selectedLanguages.updateValueAndValidity();
        if (this.form.valid) {
            const languages = this.selectedLanguages.value;
            const requestBase: Partial<TranscriptRequest> = {
                subjectUserId: this.studentId,
                useDateOfBirth: true,
                withSignature: true,
                includeAssessmentItems: this.includeAssessmentItems.value,
            };

            if (this.fullTranscriptSelected.value) {
                this.sendFullTranscriptRequest(requestBase, languages);
            } else {
                const studyRightIds = this.getSelectedStudyRightsForRequest();
                this.sendStudyRightTranscriptRequest(requestBase, languages, studyRightIds);
            }
            this.modal.close();
        } else {
            this.alertsService.addFormSubmissionFailureAlert();
        }
    }

    getSelectedStudyRightsForRequest(): OtmId[] {
        return _.filter(Object.keys(this.selectedStudyRights.value), key => this.selectedStudyRights.value[key]) as OtmId[];
    }

    sendFullTranscriptRequest(requestBase: Partial<TranscriptRequest>,
                              languages: { [key: string]: boolean }): void {
        this.painoRequestService.sendTranscriptRequest(
            requestBase, languages, 'TRANSCRIPT_STUDENT',
        );
    }

    sendStudyRightTranscriptRequest(requestBase: Partial<TranscriptRequest>,
                                    languages: { [key: string]: boolean },
                                    studyRightIds: OtmId[]): void {
        this.painoRequestService.sendTranscriptRequest(
            requestBase, languages, 'STUDY_RIGHT_RECORD_TRANSCRIPT_STUDENT', studyRightIds,
        );
    }

    private selectStudyRightIfThereIsOnlyOne(): void {
        const studyRightSelectionControls = Object.values(this.selectedStudyRights?.controls ?? {});
        if (studyRightSelectionControls.length === 1) {
            studyRightSelectionControls[0].setValue(true);
            studyRightSelectionControls[0].markAsDirty();
        }
    }
}
