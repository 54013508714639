import { Component, Inject, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Attainment, DegreeProgrammeAttainment, Education, Module, OtmId, StudyModule, StudyRight } from 'common-typescript/types';
import _ from 'lodash';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { COMMON_EDUCATION_SERVICE, PLAN_STUDY_RIGHT_SERVICE } from '../../ajs-upgraded-modules';
import { isDegreeProgrammeAttainment } from '../../attainment/AttainmentUtil';
import {
    ConnectionModel,
    PhaseId,
    PreviewModel,
    PreviewModelObject,
    StudyRightEntityService,
} from '../../service/study-right-entity.service';

interface EducationOptionWrapper {
    option: PreviewModelObject;
    isActivePhase: boolean;
    isInLearningOpportunityAllowedPaths: boolean;
}

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-study-right-education-structure-view',
    templateUrl: './study-right-education-structure-view.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class StudyRightEducationStructureViewComponent implements OnChanges {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.studyRight.sis-study-right-education-structure-view',
        directiveName: 'sisStudyRightEducationStructureView',
    };

    constructor(
        @Inject(COMMON_EDUCATION_SERVICE) private commonEducationService: any,
        @Inject(PLAN_STUDY_RIGHT_SERVICE) private planStudyRightService: any,
        private studyRightEntityService: StudyRightEntityService,
    ) { }

    /**
     * Determines expandable heading's semantic aria-level for screen readers, default is equivalent for h6
     */
    @Input() level?: number = 6;
    @Input() set attainments(attainments: Attainment[]) {
        this.dpAttainmentsByGroupId = {};
        attainments?.filter(att => isDegreeProgrammeAttainment(att))
            .forEach((att: DegreeProgrammeAttainment) => this.dpAttainmentsByGroupId[att.moduleGroupId] = att);
    }

    @Input() studyRight: StudyRight;
    @Input() education: Education;
    dpAttainmentsByGroupId: { [index: string]: Attainment } = {};
    previewModel: PreviewModel;
    connectionModel: ConnectionModel;
    leftOptions: EducationOptionWrapper[];
    rightOptions: EducationOptionWrapper[];

    ngOnChanges(changes: SimpleChanges): void {
        if ((changes.studyRight || changes.education) && this.studyRight && this.education) {
            const previewModel = this.commonEducationService.getPreviewModelForLearningOpportunity(
                this.education, this.studyRight, this.studyRight.learningOpportunityId,
            );
            this.previewModel = previewModel;
            this.connectionModel = this.commonEducationService.getConnectionModel(previewModel, this.education, this.studyRight);
            const leftOptions = _.map(_.values(this.previewModel.left), option => this.getEducationOptionWrapper(option, 'phase1'));
            this.leftOptions = _.sortBy(leftOptions, 'option.index');
            const rightOptions = _.map(_.values(this.previewModel.right), option => this.getEducationOptionWrapper(option, 'phase2'));
            this.rightOptions = _.sortBy(rightOptions, 'option.index');
        }
    }

    toStudyModule(module: Module): StudyModule {
        return module as StudyModule;
    }

    isActivePhase(phaseOption: PreviewModelObject, phaseId: PhaseId): boolean {
        return this.studyRightEntityService.isActivePhase(this.studyRight, phaseOption, phaseId);
    }

    isModuleInLearningOpportunityAllowedPaths(moduleGroupId: OtmId, educationPathKey: string) {
        return this.commonEducationService.isModuleInLearningOpportunityAllowedPaths(
            this.planStudyRightService.getStudyRightSelectionPaths(this.education, this.studyRight),
            moduleGroupId,
            educationPathKey,
        );
    }

    getEducationOptionWrapper(phaseOption: PreviewModelObject, phaseId: PhaseId): EducationOptionWrapper {
        return {
            option: phaseOption,
            isActivePhase: this.isActivePhase(phaseOption, phaseId),
            isInLearningOpportunityAllowedPaths: this.isModuleInLearningOpportunityAllowedPaths(phaseOption.moduleGroupId, phaseOption.educationPathKey),
        };
    }

}
