import { ChangeDetectionStrategy, Component, inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FudisDialogService } from '@funidata/ngx-fudis';

export function createPlanModalOpener(): () => MatDialogRef<CreatePlanModalComponent> {
    const fudisDialogService = inject(FudisDialogService);
    return () => fudisDialogService.open(CreatePlanModalComponent);
}
@Component({
    selector: 'app-create-plan-modal',
    templateUrl: './create-plan-modal.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreatePlanModalComponent {
    constructor(
        private dialogService: FudisDialogService,
    ) {}

    onSubmit() {
        this.dialogService.close();
    }

}
