import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';
import { distinctUntilChanged, startWith } from 'rxjs';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { LEGACY_TRANSLATE_SERVICE } from '../ajs-upgraded-modules';

@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
export class LanguageChangeService {
    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'sis-components.downgraded.languageChangeService',
        serviceName: 'languageChangeService',
    };

    readonly currentLanguage$ = this.translocoService.langChanges$
        .pipe(startWith(this.translocoService.getActiveLang()), distinctUntilChanged());

    constructor(
        private state: StateService,
        private translateService: TranslateService,
        private translocoService: TranslocoService,
        @Inject(DOCUMENT) private document: Document,
        @Optional() @Inject(LEGACY_TRANSLATE_SERVICE) private legacyTranslateService: any,
    ) {
        this.document.documentElement.setAttribute('lang', this.translocoService.getActiveLang());
    }

    use(newLanguage: string): void {
        this.translocoService.setActiveLang(newLanguage);
        this.translateService.use(newLanguage);
        this.document.documentElement.setAttribute('lang', newLanguage);
        if (this.legacyTranslateService) {
            return this.legacyTranslateService.use(newLanguage).then(() => this.state.reload());
        }
        this.state.reload();
    }
}
