<sis-expandable [closed]="false" [title]="title">
  <ng-template sisExpandableHeaderButtons>
    <sis-button hollow="true"
                [ariaLabel]="('ARIA_LABEL.DELETE' | translate) + ' ' + title"
                (clicked)="delete.emit()">
      <sis-icon icon="delete"></sis-icon>
    </sis-button>
  </ng-template>
  <ng-template sisExpandableContent>
    <div class="row">
      <div class="col-12">
        <sis-required-field-legend class="float-end"></sis-required-field-legend>
      </div>
    </div>
    <div class="row">
      <div *ngIf="name" class="col-9 col-sm-8">
        <div class="form-group">
          <sis-text-field [control]="name"
                          [label]="'STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_NAME' | translate"
                          id="course-unit-name"
                          name="name">
          </sis-text-field>
        </div>
      </div>
      <div *ngIf="code" class="col-9 col-sm-3 offset-sm-1">
        <div class="form-group">
          <sis-text-field [control]="code"
                          [label]="'STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_CODE' | translate"
                          id="course-unit-code"
                          name="code">
          </sis-text-field>
        </div>
      </div>
    </div>
    <div class="row">
      <div *ngIf="organisation" class="col-9 col-sm-8">
        <div class="form-group">
          <sis-text-field [control]="organisation"
                          [label]="'STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_ORGANISATION' | translate"
                          id="course-unit-organisation"
                          name="organisation">
          </sis-text-field>
        </div>
      </div>
      <div *ngIf="attainmentDate" class="col-9 col-sm-3 offset-sm-1">
        <div class="form-group">
          <sis-local-date-editor [control]="attainmentDate"
                                 [label]="'STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_ATTAINMENT_DATE' | translate"
                                 [id]="'studies-attainment-date'"
                                 [name]="'attainment-date-input'">
          </sis-local-date-editor>
        </div>
      </div>
    </div>
    <div class="row">
      <div *ngIf="credits" class="col-9 col-sm-5">
        <div class="form-group">
          <sis-text-field [control]="credits"
                          [label]="'STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_CREDITS' | translate"
                          id="course-unit-credits"
                          name="credits">
          </sis-text-field>
        </div>
      </div>
      <div *ngIf="attainmentLanguage" class="col-9 col-sm-6 offset-sm-1">
        <div class="form-group">
          <label [for]="'language-code-select'" [class]="getLabelState(attainmentLanguage)">{{'STUDENT_APPLICATIONS.ATTAINMENT_LANGUAGE' | translate}}</label>
          <sis-code-select-editor [id]="'language-code-select'"
                                  [codebookUrn]="languageCodeBookUrn"
                                  [selectedUrn]="attainmentLanguage.value"
                                  [required]="true"
                                  [attr.data-cy]="'prior-studies-attainment-language-editor'"
                                  (onSelectCode)="onAttainmentLanguageChange($event.selectedUrn)">
          </sis-code-select-editor>
          <sis-validation-errors [control]="attainmentLanguage" [formFieldId]="'language-code-select'" [ariaLive]="false" />
        </div>
      </div>
    </div>
    <div class="row">
      <div *ngIf="grade" class="col-9 col-sm-5">
        <div class="form-group">
          <sis-text-field [control]="grade"
                          [label]="'STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_GRADE' | translate"
                          id="course-unit-grade"
                          name="grade">
          </sis-text-field>
        </div>
      </div>
      <div *ngIf="gradeScale" class="col-9 col-sm-6 offset-sm-1">
        <div class="form-group">
          <sis-text-field [control]="gradeScale"
                          [label]="'STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_GRADE_SCALE' | translate"
                          id="course-unit-grade-scale"
                          name="gradeScale">
          </sis-text-field>
        </div>
      </div>
    </div>
    <div *ngIf="description" class="row">
      <div class="col-12">
        <div class="form-group">
          <sis-text-field [control]="description"
                          [label]="'STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_DESCRIPTION' | translate"
                          [textarea]="true"
                          helpBlock="STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_DESCRIPTION_INFO"
                          id="course-unit-description"
                          name="description">
          </sis-text-field>
        </div>
      </div>
    </div>
  </ng-template>
</sis-expandable>
