import {
    isDevMode,
    ModuleWithProviders,
    NgModule,
    Type,
} from '@angular/core';
import {
    provideTransloco,
    TRANSLOCO_LOADER,
    TranslocoLoader,
} from '@ngneat/transloco';

import { provideAllTranspilers } from './transpilers/provide-all-transpilers';

interface TranslocoOptions {
    loader: Type<TranslocoLoader>;
}

/**
 * Common module for importing Transloco configuration in frontends with minimal boilerplate.
 *
 * Uses the `forRoot()` paradigm to inject `environment.production` dynamically from a frontend along with
 * a frontend-specific translation loader. The rest of `TranslocoConfig` options are global and provided by
 * `TranslocoCommonConfigModule`.
 */
@NgModule()
export class TranslocoCommonModule {
    static forRoot(options: TranslocoOptions): ModuleWithProviders<TranslocoCommonModule> {
        return {
            ngModule: TranslocoCommonModule,
            providers: [
                provideTransloco({ config: { prodMode: !isDevMode() } }),
                {
                    provide: TRANSLOCO_LOADER,
                    useClass: options.loader,
                },
                ...provideAllTranspilers(),
            ],
        };
    }
}
