import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'sis-common/modal/modal.service';

export interface ReadMoreValues {
    options: {
        content: string;
        title: string;
    };
}

@Component({
    selector: 'sis-read-more-modal',
    template: '<read-more-modal (close)="activeModal.close()" [resolve]="values"></read-more-modal>',
    encapsulation: ViewEncapsulation.None,
})
export class ReadMoreModalComponent {
    constructor(@Inject(ModalService.injectionToken) public values: ReadMoreValues,
                public activeModal: NgbActiveModal) {}
}
