import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { PriorLearningWorkflow } from 'common-typescript/types';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';
import { STUDENT_WORKFLOW_STATE, STUDENT_WORKFLOW_TYPE } from 'sis-components/model/student-workflow-constants';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'app-prior-learning-workflow-content',
    templateUrl: './prior-learning-workflow-content.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class PriorLearningWorkflowContentComponent {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.profile.application.prior-learning-workflow-content',
        directiveName: 'appPriorLearningWorkflowContent',
    };

    readonly studentWorkflowState = STUDENT_WORKFLOW_STATE;
    readonly studentWorkflowType = STUDENT_WORKFLOW_TYPE;

    @Input() priorLearningWorkflow: PriorLearningWorkflow;
    @Output() openCourseUnitInfoModal = new EventEmitter();

}
