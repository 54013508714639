import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component, ElementRef,
    inject,
    Inject,
    OnDestroy,
    OnInit, ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { dateUtils } from 'common-typescript/src';
import { MaxLength } from 'common-typescript/src/validationConstants';
import {
    CourseUnit, CourseUnitRealisation,
    Enrolment,
    EnrolmentCalculationConfig,
    EnrolmentCalculationConfigForPerson,
    EnrolmentQuestion,
    EnrolmentQuestionAnswer,
    EnrolmentQuestionnaire,
    EnrolmentQuestionnaireAnswers,
    EnrolmentStudyGroupSet,
    EnrolmentStudySubGroup,
    EnrolRequest,
    OtmId,
    SisValidatorFn, StudyRight,
} from 'common-typescript/types';
import _ from 'lodash';
import moment from 'moment';
import {
    BehaviorSubject,
    catchError,
    combineLatest,
    combineLatestWith,
    defaultIfEmpty,
    distinctUntilChanged, EMPTY, forkJoin,
    Observable,
    of, Subject,
    Subscription, switchMap,
} from 'rxjs';
import { exhaustMap, map, take, takeUntil, tap } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { ModalService } from 'sis-common/modal/modal.service';
import { ComponentDowngradeMappings } from 'sis-common/types/angular-hybrid';
import { AlertsService, AlertType } from 'sis-components/alerts/alerts-ng.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { maxLength, not, required } from 'sis-components/form/form-validators';
import { getLabelState } from 'sis-components/form/formUtils';
import { SisFormBuilder } from 'sis-components/form/sis-form-builder.service';
import { SisFormControl } from 'sis-components/form/sis-form-control';
import {
    getValidationErrorSummaryDetail, ValidationErrorSummaryDetail,
} from 'sis-components/form/validation-error-summary/validation-error-summary.component';
import { Breakpoint, BreakpointService } from 'sis-components/service/breakpoint.service';
import { CourseUnitEntityService } from 'sis-components/service/course-unit-entity.service';
import { CourseUnitRealisationEntityService } from 'sis-components/service/course-unit-realisation-entity.service';
import {
    EnrolmentCalculationConfigEntityService,
} from 'sis-components/service/enrolment-calculation-config-entity.service';
import {
    EnrolmentQuestionnaireAnswersEntityService,
} from 'sis-components/service/enrolment-questionnaire-answers-entity.service';
import { EnrolmentQuestionnaireEntityService } from 'sis-components/service/enrolment-questionnaire-entity.service';
import { StudyRightEntityService } from 'sis-components/service/study-right-entity.service';
import { UniversityService } from 'sis-components/service/university.service';

import { EnrolmentStudentService } from '../../common/service/enrolment-student.service';

import {
    createStudyGroupSetsFormGroup,
    getLanguageOptions,
    getStudyGroupSetInfo,
    isLateEnrolment,
    StudyGroupSetInfo,
} from './enrolmentWizardUtils';

export interface EnrolmentWizardValues {
    enrolment: Enrolment;
    isUpdate: boolean;
    isConfirmedSsgEdit: boolean;
    isEnrolmentFromOpenUniversityModal?: boolean;
}

export interface StudySubGroupFormGroup {
    [value: OtmId]: FormGroup<{
        isPrimary: FormControl<boolean>;
        isSelected: FormControl<boolean>;
    }>;
}

export interface StudyGroupSetsFormGroup {
    [value: OtmId]: FormGroup<StudyGroupSetFormGroup>;
}

export interface StudyGroupSetFormGroup {
    studySubGroups: FormGroup<StudySubGroupFormGroup>;
    targetStudySubGroupAmount: FormControl<number>;
}

export interface EnrolmentQuestions {
    [value: OtmId]: FormControl<string>;
}

export interface EnrolmentWizardForm {
    studyRightId?: FormControl<OtmId>;
    enrolmentRightId?: FormControl<OtmId>;
    studyGroupSets: FormGroup<StudyGroupSetsFormGroup>;
    enrolmentQuestions?: FormGroup<EnrolmentQuestions>;
    studentConsentForOutboundDataTransfer?: FormControl<boolean>;
}

export function enrolmentWizardModalOpener(): (values: EnrolmentWizardValues) => NgbModalRef {
    const modalService = inject(ModalService);
    return values => modalService.open(EnrolmentWizardComponent, {
        ...values,
        enrolment: values.enrolment,
    }, { size: 'lg' });
}

@Component({
    selector: 'app-enrolment-wizard',
    templateUrl: './enrolment-wizard.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnrolmentWizardComponent implements OnInit, OnDestroy {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.enrolments.enrolmentWizard.downgraded',
        directiveName: 'appEnrolmentWizard',
    };

    enrolment: Enrolment;

    data$: Observable<[CourseUnitRealisation, EnrolmentCalculationConfigForPerson, EnrolmentCalculationConfig, CourseUnit, EnrolmentQuestionnaire, Enrolment]>;

    studyRightChangeListener: Subscription;

    courseUnitRealisation: CourseUnitRealisation;
    enrolmentStudyGroupSets: EnrolmentStudyGroupSet[] = [];
    enrolmentStudyGroups: EnrolmentStudySubGroup[] = [];

    questions: EnrolmentQuestion[] = [];
    enrolmentQuestionnaireAnswers: EnrolmentQuestionnaireAnswers;
    languageOptions: any[] = [];

    isMobileView = false;

    destroyed$ = new Subject<void>();
    form: FormGroup<EnrolmentWizardForm>;
    studyGroupSetsInfo: Map<OtmId, StudyGroupSetInfo> = new Map<OtmId, StudyGroupSetInfo>();
    validationDetails: ValidationErrorSummaryDetail[] = [];
    requiredStudySubGroupsFull = false;
    invalidCrossStudyRight = false;
    realisationFull = false;
    showErrors = false;
    @ViewChild('realisationFullNotificationContainer') realisationFullNotificationContainer: ElementRef;

    protected readonly getLabelState = getLabelState;
    private confirmButtonClicked = new Subject<void>();

    constructor(
        private modal: NgbActiveModal,
        private appErrorHandler: AppErrorHandler,
        private breakpointService: BreakpointService,
        private fb: SisFormBuilder,
        private alertsService: AlertsService,
        private courseUnitEntityService: CourseUnitEntityService,
        private enrolmentCalculationConfigEntityService: EnrolmentCalculationConfigEntityService,
        private enrolmentQuestionnaireEntityService: EnrolmentQuestionnaireEntityService,
        private enrolmentQuestionnaireAnswersEntityService: EnrolmentQuestionnaireAnswersEntityService,
        private courseUnitRealisationEntityService: CourseUnitRealisationEntityService,
        private translateService: TranslateService,
        private localeService: LocaleService,
        @Inject(ModalService.injectionToken) private values: EnrolmentWizardValues,
        private enrolmentStudentService: EnrolmentStudentService,
        private studyRightService: StudyRightEntityService,
        private universityService: UniversityService,
        private ref: ChangeDetectorRef,
    ) {
    }

    get isUpdate(): boolean {
        return this.values?.isUpdate;
    }

    get isConfirmedSsgEdit(): boolean {
        return this.values?.isConfirmedSsgEdit;
    }

    get studyRightId(): SisFormControl<OtmId> {
        return this.form?.get('studyRightId') as SisFormControl<OtmId>;
    }

    get enrolmentRightId(): SisFormControl<OtmId> {
        return this.form?.get('enrolmentRightId') as SisFormControl<OtmId>;
    }

    get enrolmentQuestions(): FormGroup<EnrolmentQuestions> {
        return this.form?.get('enrolmentQuestions') as FormGroup<EnrolmentQuestions>;
    }

    get studyGroupSets(): FormGroup<StudyGroupSetsFormGroup> {
        return this.form?.get('studyGroupSets') as FormGroup<StudyGroupSetsFormGroup>;
    }

    get studentConsentForOutboundDataTransfer(): FormControl<boolean> {
        return this.form?.get('studentConsentForOutboundDataTransfer') as FormControl<boolean>;
    }

    getStudySubGroups(id: OtmId): FormGroup<StudySubGroupFormGroup> {
        return this.studyGroupSets.get(id).get('studySubGroups') as FormGroup<StudySubGroupFormGroup>;
    }

    getStudyGroupSet(id: OtmId): FormGroup<StudyGroupSetFormGroup> {
        return this.studyGroupSets.get(id) as FormGroup<StudyGroupSetFormGroup>;
    }

    getEnrolmentQuestion(id: OtmId): FormControl {
        return this.enrolmentQuestions?.controls[id];
    }

    ngOnInit(): void {
        const breakpoint = this.breakpointService.getCurrentBreakpoint();
        this.isMobileView = breakpoint < Breakpoint.SM;
        this.data$ = this.init();
        this.confirmButtonClicked
            .pipe(
                takeUntil(this.destroyed$),
                exhaustMap(() => this.handleConfirm()),
                take(1),
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.studyRightChangeListener?.unsubscribe();
        this.destroyed$.next();
    }

    getCourseUnit(): Observable<CourseUnit> {
        return this.courseUnitEntityService.getById(this.values.enrolment.courseUnitId)
            .pipe(take(1), tap(cu => this.languageOptions = getLanguageOptions(cu)));
    }

    getCourseUnitRealisation(): Observable<CourseUnitRealisation> {
        return this.courseUnitRealisationEntityService.getById(this.values.enrolment.courseUnitRealisationId)
            .pipe(take(1), this.appErrorHandler.defaultErrorHandler());
    }

    getEnrolment(): Observable<Enrolment> {
        return this.enrolmentStudentService.getById(this.values.enrolment.id, true)
            .pipe(take(1), this.appErrorHandler.defaultErrorHandler());
    }

    init(): Observable<[CourseUnitRealisation, EnrolmentCalculationConfigForPerson, EnrolmentCalculationConfig, CourseUnit, EnrolmentQuestionnaire, Enrolment]> {
        return forkJoin([
            this.getCourseUnitRealisation(),
            this.getEnrolmentCalculationResult(),
            this.getEnrolmentCalculationConfig(),
            this.getCourseUnit(),
            this.getEnrolmentQuestionnaire().pipe(take(1)),
            this.getEnrolment(),
        ]).pipe(
            this.appErrorHandler.defaultErrorHandler(),
            tap(data => {
                const enrolledCount = new Map<OtmId, number>();
                this.courseUnitRealisation = data[0];
                const enrolmentCalculationResult = data[1];
                this.realisationFull = enrolmentCalculationResult ? this.getRealisationFullFromCalculationResult(enrolmentCalculationResult) : false;
                this.enrolment = _.cloneDeep(data[5]);
                enrolmentCalculationResult?.enrolmentAllocationCounts?.studySubGroupAllocationCounts?.forEach((allocationCount) => {
                    enrolledCount.set(allocationCount.studySubGroupId, allocationCount.enrolledCount);
                });
                this.studyGroupSetsInfo = getStudyGroupSetInfo(this.isConfirmedSsgEdit, this.enrolment, this.courseUnitRealisation, enrolledCount, enrolmentCalculationResult);
                this.requiredStudySubGroupsFull = this.isRequiredStudySubGroupsFull();
                const studyGroupSetsFormGroup = createStudyGroupSetsFormGroup(this.enrolment, this.isConfirmedSsgEdit, this.courseUnitRealisation, this.studyGroupSetsInfo, this.destroyed$, this.localeService.getCurrentLanguage());
                this.form = this.fb.group<EnrolmentWizardForm>({ studyGroupSets: this.fb.group(studyGroupSetsFormGroup) });
                this.addStudyRightControl();
                this.addStudentConsentForOutboundDataTransferControl(this.courseUnitRealisation);
                this.setEnrolmentQuestionnaireControl();
            }));
    }

    isRequiredStudySubGroupsFull = (): boolean =>
        Array.from(this.studyGroupSetsInfo.values()).some((studyGroupSetInfo) => {
            const allocations = Array.from(studyGroupSetInfo.studySubGroupAllocations.values()).filter(allocation => !allocation.cancelled);
            if (studyGroupSetInfo.selectionType === 'MANDATORY') {
                return allocations.some(allocation => !allocation.hasSpace);
            }
            if (studyGroupSetInfo.selectionType === 'RANGE' || studyGroupSetInfo.selectionType === 'MIN') {
                const fullGroupsCount = allocations.filter(allocation => !allocation.hasSpace).length;
                return allocations.length - fullGroupsCount < studyGroupSetInfo.studyGroupSet.subGroupRange.min;
            }
            return false;
        });

    isCrossStudy() {
        return !this.courseUnitRealisation.universityOrgIds?.includes(this.universityService.getCurrentUniversityOrgId());
    }

    checkValidityForCrossStudy(studyRight: StudyRight) {
        const ongoingStudyRightNetworks = studyRight?.cooperationNetworkRights?.filter(network => dateUtils.rangeContains(moment(), network.validityPeriod));
        const ongoingCurNetworks = this.courseUnitRealisation.cooperationNetworkDetails?.networks?.filter(network => dateUtils.rangeContains(moment(), network.validityPeriod));

        // Check if study right and course unit realisation have the same valid network right
        if (!ongoingStudyRightNetworks?.some(studyRightNetwork => ongoingCurNetworks?.some(network => network.cooperationNetworkId === studyRightNetwork.cooperationNetworkId))) {
            this.invalidCrossStudyRight = true;
            this.studyRightId.setErrors({});
        } else {
            this.invalidCrossStudyRight = false;
            this.studyRightId.setErrors(null);
        }
    }

    getEnrolmentQuestionnaire(): Observable<EnrolmentQuestionnaire> {
        if (this.isConfirmedSsgEdit) return new BehaviorSubject(null);
        return this.enrolmentQuestionnaireEntityService.get<EnrolmentQuestionnaire>(this.values.enrolment.courseUnitRealisationId)
            .pipe(
                this.appErrorHandler.defaultErrorHandler(),
                combineLatestWith(this.getEnrolmentQuestionnaireAnswers()),
                tap(([enrolmentQuestionnaire]) => this.questions = enrolmentQuestionnaire.enrolmentQuestions),
                map(([enrolmentQuestionnaire]) => enrolmentQuestionnaire as EnrolmentQuestionnaire),
                defaultIfEmpty(null),
            );
    }

    getEnrolmentQuestionnaireAnswers(): Observable<EnrolmentQuestionnaireAnswers> {
        return this.enrolmentQuestionnaireAnswersEntityService.get(this.values.enrolment.id)
            .pipe(
                take(1),
                this.appErrorHandler.defaultErrorHandler(),
                defaultIfEmpty(undefined),
                tap(result => this.enrolmentQuestionnaireAnswers = result),
            ) as Observable<EnrolmentQuestionnaireAnswers>;
    }

    getEnrolmentCalculationConfig() {
        return this.enrolmentCalculationConfigEntityService
            .getEnrolmentCalculationConfigOrNull(this.values.enrolment.courseUnitRealisationId).pipe(take(1));
    }

    getEnrolmentCalculationResult(): Observable<EnrolmentCalculationConfigForPerson> {
        return this.enrolmentStudentService.getCalculationConfig(this.values.enrolment.courseUnitRealisationId)
            .pipe(take(1), this.appErrorHandler.defaultErrorHandler(), defaultIfEmpty(null));
    }

    confirm(): void {
        this.confirmButtonClicked.next();
    }

    private handleConfirm(): Observable<Enrolment> {
        this.validationDetails = [];
        if (this.isCrossStudy() && this.invalidCrossStudyRight) this.studyRightId.setErrors({});
        if (this.form.invalid) {
            this.validationDetails = this.createErrorSummary();
            return EMPTY;
        }
        return this.getEnrolmentCalculationResult().pipe(
            switchMap((enrolmentCalculationResult) => {
                if (enrolmentCalculationResult && this.getRealisationFullFromCalculationResult(enrolmentCalculationResult)) {
                    this.realisationFull = true;
                }
                if (this.shouldDisplayRealisationFullWarning()) {
                    this.ref.detectChanges();
                    const notification = this.realisationFullNotificationContainer.nativeElement.querySelector('sis-contextual-notification');
                    notification.focus();
                    notification.scrollIntoView();
                    return EMPTY;
                }
                this.mapStudyGroupSetSelections();
                if (this.isUpdate) {
                    return this.isConfirmedSsgEdit ? this.updateEnrolmentStudySubGroups() : this.update();
                }
                if (!this.isUpdate) {
                    return this.enrolToCur();
                }
            }),
        );

    }

    getRealisationFullFromCalculationResult(calculationResult: EnrolmentCalculationConfigForPerson): boolean {
        const maxSelected = calculationResult.enrolmentAllocationCounts?.maxSelected ?? 0;
        const enrolledCount = (calculationResult.enrolmentAllocationCounts?.enrolledCount ?? 0) +
            (calculationResult.enrolmentAllocationCounts?.reservedCount ?? 0);
        return !!maxSelected && maxSelected <= enrolledCount;
    }

    shouldDisplayRealisationFullWarning() {
        return !this.isUpdate &&
            (this.courseUnitRealisation.continuousEnrolment || isLateEnrolment(this.courseUnitRealisation)) &&
            this.realisationFull;
    }

    update() {
        const updatedEnrolment = {
            ...this.enrolment,
            studySubGroups: this.enrolmentStudyGroups,
            studyGroupSets: this.enrolmentStudyGroupSets,
            studyRightId: this.studyRightId.value,
        };
        return combineLatest([
            this.enrolmentStudentService.updateOwnEnrolment(updatedEnrolment),
            !_.isEmpty(this.questions) ? this.enrolmentQuestionnaireAnswersEntityService.update(this.enrolment.id, this.mapEnrolmentQuestionnaireAnswers()) : of(null),
        ]).pipe(
            take(1),
            this.appErrorHandler.defaultErrorHandler(),
            map(([enrolment]) => {
                this.alertsService.addTemporaryAlert({
                    message: this.translateService.instant('ENROLMENT.ENROLMENT_UPDATED'),
                    type: AlertType.INFO,
                });
                this.modal.close(enrolment);
                return enrolment;
            }),
        );
    }

    enrolToCur() {
        const enrolRequest: EnrolRequest = {
            answers: this.mapEnrolmentQuestionnaireAnswers(),
            enrolmentId: this.enrolment.id,
            studyRightId: this.studyRightId.value,
            enrolmentRightId: this.enrolmentRightId?.enabled ? this.enrolmentRightId.value : null,
            studySubGroups: this.enrolmentStudyGroups,
            studyGroupSets: this.enrolmentStudyGroupSets,
            studentConsentForOutboundDataTransfer: this.studentConsentForOutboundDataTransfer ? {
                consentTargetType: 'CROSS_STUDY_DATA_TRANSFER_ON_OUTBOUND_ENROLMENT',
                clause: this.translateService.instant('ENROLMENTS.ENROLMENT_MODAL.CROSS_STUDY.CONSENT_CLAUSE'),
                consentApprovalType: this.studentConsentForOutboundDataTransfer.value ? 'APPROVED' : null } : null,
        };
        return this.enrolmentStudentService.enrol(enrolRequest)
            .pipe(
                take(1),
                this.appErrorHandler.defaultErrorHandler(),
                tap((enrolment) => {
                    this.alertsService.addTemporaryAlert({
                        message: this.translateService.instant('ENROLMENT.ENROLMENT_SUCCEEDED'),
                        type: AlertType.INFO,
                    });
                    this.modal.close(enrolment);
                }),
            );
    }

    mapStudyGroupSetSelections() {
        this.enrolmentStudyGroups = [];
        this.enrolmentStudyGroupSets = [];
        Object.keys(this.studyGroupSets.controls).forEach(sgsKey => {
            const studyGroupSetFormGroup = this.studyGroupSets.controls[sgsKey].controls;
            const targetStudySubGroupAmount = studyGroupSetFormGroup.targetStudySubGroupAmount?.value !== null ? studyGroupSetFormGroup.targetStudySubGroupAmount.value : undefined;
            this.enrolmentStudyGroupSets.push({ studyGroupSetId: sgsKey, targetStudySubGroupAmount });
            Object.keys(studyGroupSetFormGroup.studySubGroups.controls).forEach(ssgKey => {
                const studySubGroupFormGroup = studyGroupSetFormGroup.studySubGroups.controls[ssgKey].controls;
                const currentEnrolmentSsg = _.find(this.enrolment.studySubGroups, { studySubGroupId: ssgKey });
                currentEnrolmentSsg.enrolmentStudySubGroupPriority = !studySubGroupFormGroup.isSelected.value ? 'NOT_SUITABLE' : (studySubGroupFormGroup.isPrimary.value ? 'PRIMARY' : 'SUITABLE');
                currentEnrolmentSsg.isInCalendar = studySubGroupFormGroup.isSelected.value;
                this.enrolmentStudyGroups.push(currentEnrolmentSsg);
            });
        });
    }

    updateEnrolmentStudySubGroups() {
        const confirmedStudySubGroups = this.enrolmentStudyGroups.filter(ssg => ssg.enrolmentStudySubGroupPriority !== 'NOT_SUITABLE');
        const confirmedIds = confirmedStudySubGroups.map(ssg => ssg.studySubGroupId);

        return this.enrolmentStudentService
            .updateEnrolment({ studySubGroupIds: confirmedIds, enrolmentId: this.enrolment.id })
            .pipe(
                this.appErrorHandler.defaultErrorHandler(),
                take(1),
                tap((enrolment) => {
                    this.alertsService.addTemporaryAlert({
                        message: this.translateService.instant('ENROLMENT.MODIFY_GROUPS_MODAL.SUCCESS_ALERT'),
                        type: AlertType.INFO,
                    });
                    this.modal.close(enrolment);
                }),
            );
    }

    mapEnrolmentQuestionnaireAnswers(): EnrolmentQuestionnaireAnswers {
        if (this.form && this.enrolmentQuestions) {
            const questionnaireAnswer = this.enrolmentQuestionnaireAnswers ? {
                ...this.enrolmentQuestionnaireAnswers,
                answers: [] as EnrolmentQuestionAnswer[],
            } : {
                enrolmentId: this.enrolment.id,
                courseUnitRealisationId: this.enrolment.courseUnitRealisationId,
                studentId: this.enrolment.personId,
                answers: [] as EnrolmentQuestionAnswer[],
                metadata: null,
                documentState: null,
            };

            this.questions.forEach(question => {
                const answer = this.enrolmentQuestions.get(question.localId)?.value;
                if (answer && question.questionType === 'FREE_TEXT') {
                    questionnaireAnswer.answers.push({
                        questionId: question.localId,
                        answerText: answer,
                        selections: [],
                    });
                }
                if (answer && question.questionType === 'LANGUAGE_SELECT') {
                    questionnaireAnswer.answers.push({
                        questionId: question.localId,
                        selections: [answer],
                        answerText: undefined,
                    });
                }
            });
            return questionnaireAnswer;
        }
        return undefined;
    }

    close(): void {
        this.modal.dismiss();
    }

    addStudyRightControl() {
        if (!this.isConfirmedSsgEdit) {
            this.form.addControl('studyRightId', this.fb.sisFormControl(this.values.isEnrolmentFromOpenUniversityModal ? this.values.enrolment.studyRightId : this.enrolment.studyRightId, required()));
            this.form.addControl('enrolmentRightId', this.fb.sisFormControl(this.values.isEnrolmentFromOpenUniversityModal ? this.values.enrolment.enrolmentRightId : this.enrolment?.enrolmentRightId, required()));

            this.updateStudyRightAndEnrolmentRightControlState(this.studyRightId.value);
            this.studyRightChangeListener = this.studyRightId.valueChanges
                .pipe(distinctUntilChanged())
                .subscribe(studyRightId => {
                    this.enrolmentRightId.setValue(null);
                    this.updateStudyRightAndEnrolmentRightControlState(studyRightId);
                });
        }
    }

    setEnrolmentQuestionnaireControl() {
        if (!_.isEmpty(this.questions)) {
            const enrolmentQuestions: FormGroup<EnrolmentQuestions> = this.fb.group({});

            _.forEach(this.questions, question => {
                if (question.questionType === 'FREE_TEXT') {
                    const validators: SisValidatorFn[] = [];
                    validators.push(maxLength(MaxLength.MAX_LONG_STRING_LENGTH));
                    const answer = this.getEnrolmentQuestionnaireAnswer(question.localId, false);
                    if (question.required) validators.push(required());
                    enrolmentQuestions.addControl(question.localId, this.fb.control(answer, validators));
                } else {
                    const answer = this.getEnrolmentQuestionnaireAnswer(question.localId, true);
                    enrolmentQuestions.addControl(question.localId, this.fb.control(answer, question.required ? required() : undefined));
                }
            });
            this.form.addControl('enrolmentQuestions', enrolmentQuestions);
        }
    }

    getEnrolmentQuestionnaireAnswer(questionId: string, isSelection: boolean) {
        if (!this.enrolmentQuestionnaireAnswers || _.isNil(this.enrolmentQuestionnaireAnswers.answers)) return null;
        const answer = _.find(this.enrolmentQuestionnaireAnswers.answers, { questionId });
        if (_.isNil(answer)) return null;
        return isSelection ? (answer.selections.length > 0 ? answer.selections[0] : null) : answer.answerText;
    }

    addStudentConsentForOutboundDataTransferControl(courseUnitRealisation: CourseUnitRealisation) {
        if (!this.isUpdate) {
            if (this.isCrossStudy()) this.form.addControl('studentConsentForOutboundDataTransfer', this.fb.control<boolean>(false, not(false, 'SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.REQUIRED')));
        }
    }

    private updateStudyRightAndEnrolmentRightControlState(studyRightId: OtmId): void {
        (!!studyRightId ? this.studyRightService.getById(studyRightId) : of(null))
            .pipe(
                take(1),
                catchError(() => of(null)),
            )
            .subscribe(studyRight => {
                if (studyRight?.basedOnEnrolmentRights) {
                    this.enrolmentRightId.enable();
                } else {
                    this.enrolmentRightId.disable();
                }
                if (this.studyRightId.value && this.isCrossStudy()) {
                    this.checkValidityForCrossStudy(studyRight);
                }
            });
    }

    private createErrorSummary = (): ValidationErrorSummaryDetail[] => {
        this.form.markAllAsTouched();
        this.showErrors = true;

        return _.compact([
            getValidationErrorSummaryDetail(this.studyRightId, this.translateService.instant('ENROLMENTS.ENROLMENT_MODAL.ERROR.SUMMARY_STUDY_RIGHT'), 'studyRightId'),
            getValidationErrorSummaryDetail(this.enrolmentRightId, this.translateService.instant('ENROLMENTS.ENROLMENT_MODAL.ERROR.SUMMARY_ENROLMENT_RIGHT'), 'enrolmentRightId'),
            ...(Object.entries(this.studyGroupSets.controls).map(([key, value]) => value.errors?.errorSummary ? getValidationErrorSummaryDetail(
                value,
                this.translateService.instant(value.errors.errorSummary.translationKey, value.errors.errorSummary.translationParams),
                value.controls.studySubGroups.errors ? this.getFocusableSsgElementId(key) : `${key}-targetStudySubGroupAmount`) : null)),
            ...(!!this.enrolmentQuestions ? Object.entries(this.enrolmentQuestions.controls).map(([key, value]) => {
                const question = _.find(this.questions, { localId: key });
                const translation = question.questionType === 'LANGUAGE_SELECT' ?
                    this.translateService.instant('ENROLMENTS.ENROLMENT_MODAL.ERROR.SUMMARY_ENROLMENT_QUESTION_LANGUAGE') :
                    this.translateService.instant('ENROLMENTS.ENROLMENT_MODAL.ERROR.SUMMARY_ENROLMENT_QUESTION_FREE_TEXT', { content: this.localeService.localize(question.question) });
                return (getValidationErrorSummaryDetail(value, translation, key));
            }) : []),
            getValidationErrorSummaryDetail(this.studentConsentForOutboundDataTransfer, this.translateService.instant('ENROLMENTS.ENROLMENT_MODAL.CROSS_STUDY.CONSENT_LABEL'), 'consent-clause-input'),
        ]) as ValidationErrorSummaryDetail[];
    };

    private getFocusableSsgElementId = (studyGroupSetId: OtmId) => {
        const focusableSsgs: OtmId[] = [];
        const elementIds: string[] = [];
        Object.entries(this.getStudySubGroups(studyGroupSetId).controls).forEach(([key, value]) => {
            if (value.enabled) focusableSsgs.push(key);
        });
        const children = document.getElementById(studyGroupSetId)?.getElementsByTagName('label');
        if (!children) return null;
        Array.from(children).forEach(child => {
            if (focusableSsgs.includes(child.id)) elementIds.push(child.id);
        });
        return children?.length > 0 ? elementIds[0] : null;
    };

    crossStudySubmitButtonDisabled() {
        // When only one study right is available, studyRightControl element is not displayed. If the single study right is invalid for cross study, submit button should be disabled.
        const studyRightControl = document.getElementById('studyRightId');
        return this.isCrossStudy() && this.invalidCrossStudyRight && !studyRightControl;
    }
}
