<button [attr.id]="id"
        [type]="type"
        [title]="customTitle || ariaLabel || ''"
        [class]="classes"
        [disabled]="disabled"
        [attr.data-cy]="dataCy"
        [attr.aria-label]="ariaLabel | translate"
        [attr.aria-expanded]="ariaExpanded"
        (click)="clicked.emit(); $event.stopPropagation()">
  {{label}}
  <ng-content></ng-content>
</button>
