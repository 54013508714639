import { SisStateDeclaration } from 'sis-components/router/types';

import {
    RipaEnrolmentInformationComponent,
} from '../common/components/ripa-enrolment-information/ripa-enrolment-information.component';

import { EnrolmentsMainContainerComponent } from './enrolments-main-container/enrolments-main-container.component';

export const enrolmentStates: SisStateDeclaration[] = [
    {
        name: 'student.logged-in.enrolments',
        url: '/enrolments',
        data: {
            browserTabTitle: 'APPLICATION_ENROLMENTS',
            hideLastNode: false,
            showHome: true,
        },
        headerParams: {
            displayNameKey: 'APPLICATION_ENROLMENTS',
        },
        component: EnrolmentsMainContainerComponent,
    },
    {
        name: 'student.logged-in.ripa-enrolment-information',
        url: '/ripa-enrolment-information',
        views: {
            '': { component: RipaEnrolmentInformationComponent },
        },
        params: {
            focusedNav: {
                active: true,
                title: 'RIPA_ENROLMENT_INFORMATION.TITLE',
                from: 'student.logged-in.frontpage',
            },
        },
    },
];
