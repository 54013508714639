<div role="group"
     [attr.aria-labelledby]="ruleLegendId()">
  <sis-plan-structure-credit-rule-header [parentModule]="_parentModule()"
                                         [ruleDepthLevel]="ruleDepthLevel"
                                         [ruleValidationResults]="ruleValidationResults()"
                                         [ruleLegendId]="ruleLegendId()"
                                         [rule]="_rule()">
  </sis-plan-structure-credit-rule-header>
  <sis-plan-structure-select-rule [rule]="_rule().rule"
                                  [headingLevel]="headingLevel"
                                  [parentModule]="_parentModule()"
                                  [planData]="planData"
                                  [planRuleData]="planRuleData"
                                  [planStateObject]="planStateObject"
                                  [planValidationResult]="_planValidationResult()"
                                  [validatablePlan]="validatablePlan"
                                  [selectionDisabled]="selectionsDisabled"
                                  [groupPrefix]="groupPrefix"
                                  [ruleDepthLevel]="ruleDepthLevel + 1">
  </sis-plan-structure-select-rule>
</div>



