import { Component, ElementRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { StateService } from '@uirouter/core';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { addFocusToTabItem, commonKeyDown, commonKeyUp, removeFocus } from '../tabHelpers';

/**
 * Path: Contains the uiSref address of the tab view
 * Params: Possible navigation parameters for the uiSref address
 * Tail: Contains possible extra information next to the tab title. This information can be a string or a number.
 * Id: Can be used as a data attribute for cypress tests etc.
 */
export interface Tab {
    path: string;
    title: string;
    params?: Object;
    tail?: string | number;
    id?: string;
    controls?: string;
}

/**
 * Type defines the appearance of the navigation tab list.
 * Tabs is usually for first level and pills for second level navigation.
 * Wide is stacked mobile option.
 */
export type NavType = 'tabs' | 'pills' | 'wide';

/**
 * Size defines the breakpoint size up to which the navigation tab list is shown as a wide tab pile.
 */
export type NavSize = 'xxs' | 'xs' | 'sm' | 'md';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-tab-navigation',
    templateUrl: './tab-navigation.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class TabNavigationComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sisComponents.tabNavigation',
        directiveName: 'sisTabNavigation',
    };

    indexOfFocusedTab: number;

    /** Array of tabs */
    @Input() tabs: Tab[];
    /** Appearance of the tabs */
    @Input() type?: NavType = 'tabs';
    /** Breakpoint up to which tabs are shown in wide appearance i.e. stacked, works with type 'tabs' */
    @Input() wide?: NavSize;
    /** Inverted tab colors where active tab is black and others are blue, works with type 'tabs' */
    @Input() invert?: boolean;
    /** Component's wrapper ul element */
    @ViewChild('tabList') ul: ElementRef;

    constructor(private state: StateService) {}

    /** Check if additional information exists as tail */
    hasTail(tab: Tab): boolean {
        return (typeof tab?.tail === 'string' && tab.tail.length > 0) || typeof tab?.tail === 'number';
    }

    /** Active tab handling */
    isSelected(path: string): boolean {
        return this.state.includes(path);
    }

    get selected(): Tab | null {
        return this.tabs?.find(tab => this.isSelected(tab.path)) ?? null;
    }

    /**
     * Enable left, right and selection arrow key navigation.
     * Up and down arrow navigation is enabled since tabs collapse in smaller screens
     */
    protected handleKeyUp(event: KeyboardEvent, index: number) {
        const direction = commonKeyUp(event);

        if (direction && direction !== 'click') {
            this.handleFocusChange(direction);
        } else if (direction === 'click') {
            this.handleFocusChange(direction, index);
        }
    }

    /** Enable home (go to the first tab element) and end (go to the last tab element) key actions */
    protected handleKeyDown(event: KeyboardEvent) {
        const direction = commonKeyDown(event);

        if (direction) {
            this.handleFocusChange(direction);
        }
    }

    /** Update active tab index on mouse click */
    protected handleClick(index: number) {
        this.handleFocusChange('click', index);
    }

    /** Remove focus when blurring out of the tab */
    protected handleBlur(event: Event) {
        removeFocus(event);
    }

    /** Updated index of focused tab */
    private handleFocusChange(direction: string, index?: number) {
        this.indexOfFocusedTab = addFocusToTabItem(this.ul.nativeElement, this.tabs, this.indexOfFocusedTab, direction, index);
    }
}
