<div class="form-group">
    <h4 translate="PLAN_EDIT.INLINE_SEARCH_TITLE_COURSE_UNIT"></h4>
    <p class="guidance guidance__inverted" translate="PLAN_EDIT.INLINE_SEARCH_GUIDANCE"></p>
    <div>
        <sis-inline-search
          [searchTarget]="searchTarget"
          [id]="rule.localId"
          [parentModule]="parentModule"
          [validatablePlan]="validatablePlan"
          [curriculumPeriodIds]="curriculumPeriodIds"
          [cooperationNetworkIds]="effectiveCooperationNetworkIdsForSearch"
          (selectResult)="onSelectCourseUnitResultItem($event)"></sis-inline-search>
      <div class="ripa-checkbox-wrapper" *ngIf="this.crossStudyFeaturesEnabled">
        <sis-checkbox
          [label]="'PLAN_EDIT.RIPA_CHECKBOX_LABEL' | translate"
          [checked]="this.ripaStudiesIncluded"
          (checkedChange)="this.toggleRipaStudiesForSearch($event)"></sis-checkbox>
        <sis-tooltip [tooltipText]="'PLAN_EDIT.RIPA_FILTER_GUIDANCE' | translate" [tooltipBlockElement]="true" [appendToBody]="true">
          <div class="sis-tooltip-trigger-content">
            <sis-icon [icon]="'info-circle'" [color]="'white'"></sis-icon>
          </div>
        </sis-tooltip>
      </div>

    </div>

</div>
<div *ngFor="let courseUnit of courseUnits">
    <sis-plan-course-unit
                          [courseUnit]="courseUnit"
                          [parentModule]="parentModule"
                          [planValidationResult]="planValidationResult"
                          [planStateObject]="planStateObject"
                          [planData]="planData">
        <div course-unit-action class="additional-box">
            <sis-button class="button-box non-button"
                        link="true"
                        (clicked)="unselectCourseUnit(courseUnit)"
                        [ariaLabel]="'ARIA_LABEL.DELETE' |translate">
                <sis-icon icon="delete"></sis-icon>
            </sis-button>
        </div>
    </sis-plan-course-unit>
</div>
<div *ngFor="let customCourseUnitAttainment of customCourseUnitAttainments">
    <sis-plan-custom-course-unit-attainment
            [customCourseUnitAttainment]="customCourseUnitAttainment"
            [planStateObject]="planStateObject">
        <div custom-course-unit-attainment-action class="additional-box">
            <sis-button class="button-box non-button"
                        link="true"
                        (clicked)="unselectCustomCourseUnitAttainment(customCourseUnitAttainment)"
                        [ariaLabel]="'ARIA_LABEL.DELETE' |translate">
                <sis-icon icon="delete"></sis-icon>
            </sis-button>
        </div>
    </sis-plan-custom-course-unit-attainment>
</div>
<div *ngFor="let customStudyDraft of customStudyDrafts">
    <sis-plan-custom-study-draft
            [customStudyDraft]="customStudyDraft"
            [planStateObject]="planStateObject">
        <div custom-study-draft-action class="additional-box">
            <sis-button class="button-box non-button"
                        link="true"
                        (clicked)="unselectCustomStudyDraft(customStudyDraft)"
                        [ariaLabel]="'ARIA_LABEL.DELETE' |translate">
                <sis-icon icon="delete"></sis-icon>
            </sis-button>
        </div>
    </sis-plan-custom-study-draft>
</div>
