import { Injectable } from '@angular/core';
import {
    CompositeRule,
    CourseUnit,
    CustomCourseUnitAttainment,
    CustomStudyDraft,
    EntityWithRule,
    Module,
} from 'common-typescript/types';
import { Subject } from 'rxjs';
import {
    PlanActionsService,
    UiOperation,
    UiOperationType,
} from 'sis-components/plan/plan-actions-service/plan-actions.service';
import { PlanOperation, PlanOperationType } from 'sis-components/plan/plan-manager/plan-manager.service';

@Injectable()
export class StudentPlanActionsService implements PlanActionsService {

    planOperationSubject: Subject<PlanOperation>;
    uiOperationSubject: Subject<UiOperation>;

    constructor() {
        this.planOperationSubject = new Subject<PlanOperation>();
        this.uiOperationSubject = new Subject<UiOperation>();
    }

    /*
     * UI-operations
     */

    openCourseUnitInfoModal(courseUnit: CourseUnit): void {
        this.uiOperationSubject.next(<UiOperation>{
            uiOperationType: UiOperationType.OPEN_COURSE_UNIT_INFO_MODAL,
            target: courseUnit,
        });
    }

    openCustomCourseUnitAttainmentInfoModal(customCourseUnitAttainment: CustomCourseUnitAttainment): void {
        this.uiOperationSubject.next(<UiOperation>{
            uiOperationType: UiOperationType.OPEN_CUSTOM_ATTAINMENT_INFO_MODAL,
            target: customCourseUnitAttainment,
        });
    }

    openCustomStudyDraftCreationModal(parentModule: EntityWithRule): void {
        this.uiOperationSubject.next(<UiOperation>{
            uiOperationType: UiOperationType.OPEN_CUSTOM_STUDY_DRAFT_CREATION_MODAL,
            target: parentModule,
        });
    }

    openCustomStudyDraftInfoModal(customStudyDraft: CustomStudyDraft): void {
        this.uiOperationSubject.next(<UiOperation>{
            uiOperationType: UiOperationType.OPEN_CUSTOM_STUDY_DRAFT_INFO_MODAL,
            target: customStudyDraft,
        });
    }

    openModule(module: EntityWithRule): void {
        this.uiOperationSubject.next(<UiOperation>{
            uiOperationType: UiOperationType.OPEN_MODULE,
            target: module,
        });
    }

    openModuleInfoModal(module: EntityWithRule): void {
        this.uiOperationSubject.next(<UiOperation>{
            uiOperationType: UiOperationType.OPEN_MODULE_INFO_MODAL,
            target: module,
        });
    }

    /*
     *  Plan-operations
     */

    selectCourseUnit(courseUnit: CourseUnit, parentModule: EntityWithRule): void {
        this.planOperationSubject.next(<PlanOperation>{
            planOperationType: PlanOperationType.SELECT_COURSE_UNIT,
            target: courseUnit,
            parentModule,
        });
    }

    removeCourseUnit(courseUnit: CourseUnit, parentModule: EntityWithRule): void {
        this.planOperationSubject.next(<PlanOperation>{
            planOperationType: PlanOperationType.UNSELECT_COURSE_UNIT,
            target: courseUnit,
            parentModule,
        });
    }

    moveCourseUnit(courseUnit: CourseUnit, parentModule: EntityWithRule): void {
        this.planOperationSubject.next(<PlanOperation>{
            planOperationType: PlanOperationType.MOVE_COURSE_UNIT,
            target: courseUnit,
            parentModule,
            parentCourseUnit: null,
        });
    }

    selectModule(module: Module, parentModule: EntityWithRule): void {
        this.planOperationSubject.next(<PlanOperation>{
            planOperationType: PlanOperationType.SELECT_MODULE,
            target: module,
            parentModule,
            parentCourseUnit: null,
        });
    }

    removeModule(module: Module, parentModule: EntityWithRule): void {
        this.planOperationSubject.next(<PlanOperation>{
            planOperationType: PlanOperationType.UNSELECT_MODULE,
            target: module,
            parentModule,
            parentCourseUnit: null,
        });
    }

    activateRuleGroup(rule: CompositeRule, parentModule: EntityWithRule): void {
        this.planOperationSubject.next(<PlanOperation>{
            planOperationType: PlanOperationType.ACTIVATE_RULE_GROUP,
            target: rule,
            parentModule,
        });
    }

    deactivateRuleGroup(rule: CompositeRule, parentModule: EntityWithRule): void {
        this.planOperationSubject.next(<PlanOperation>{
            planOperationType: PlanOperationType.DEACTIVATE_RULE_GROUP,
            target: rule,
            parentModule,
        });
    }
}
