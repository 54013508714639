<sis-study-box [name]="module.name"
               [level]="headingLevel"
               [credits]="module.targetCredits"
               [code]="module.code"
               [attainedCredits]="attainment?.credits"
               [gradeId]="attainment?.gradeId"
               [gradeScaleId]="attainment?.gradeScaleId"
               [type]="boxType"
               [id]="module.id"
               [studyState]="studyState"
               [isInvalidSelection]="invalidSelection"
               (nameClickAction)="openDegreeProgrammeInfoModal()">
</sis-study-box>
