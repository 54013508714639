import { Component, ViewEncapsulation } from '@angular/core';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';
import { Tab } from 'sis-components/tabs/tab-navigation/tab-navigation.component';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'app-profile-secondary-navigation',
    templateUrl: './profile-secondary-navigation.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ProfileSecondaryNavigationComponent {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.profile.profileSecondaryNavigation',
        directiveName: 'appProfileSecondaryNavigation',
    };

    readonly tabs: Tab[] = [
        { path: 'student.logged-in.profile.study-right', id: 'student-profile-study-rights-tab', title: 'PROFILE.STUDY-RIGHTS', controls: 'study-rights' },
        { path: 'student.logged-in.profile.enrolments', id: 'student-profile-enrolments-tab', title: 'PROFILE.ENROLMENTS', controls: 'enrolments' },
        { path: 'student.logged-in.profile.attainment', id: 'student-profile-attainments-tab', title: 'PROFILE.ATTAINMENTS', controls: 'attainments' },
        { path: 'student.logged-in.profile.applications', id: 'student-profile-applications-tab', title: 'PROFILE.TAB_APPLICATIONS', controls: 'applications' },
        { path: 'student.logged-in.profile.person-groups', id: 'student-profile-person-groups-tab', title: 'PROFILE.PERSON_GROUPS.TITLE', controls: 'person-groups' },
        { path: 'student.logged-in.profile.payments-and-grants', id: 'student-profile-payments-tab', title: 'PROFILE.PAYMENTS_AND_GRANTS.TAB_TITLE', controls: 'payments-and-grants' },
        { path: 'student.logged-in.profile.basic-info', id: 'student-profile-basic-info-tab', title: 'PROFILE.PERSONAL_INFO', controls: 'basic-info' },
    ];
}
