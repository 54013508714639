<ng-container *ngIf="studyEvents$ | async as studyEvents">
  <ng-container *ngIf="visibleStudyEvents$ | async as visibleStudyEvents">
    <span class="control-label form-control-static tiny-static-form-group ng-scope">
      {{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.GROUP_SIZE' | transloco }}
      <span *ngIf="ssgSize; else ssgSizeNotDefined" class="value">{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.NUMBER_OF_STUDENTS' | transloco:{number: ssgSize} }}</span>
    </span>
    <div class="table-responsive top-spacing">
      <table *ngIf="visibleStudyEvents$ | async as visibleStudyEvents"
           [attr.aria-label]="'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.EVENTS_ARIA_LABEL' | translate"
           class="table styled-table">
      <caption>
        {{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.TIME_AND_PLACE_INFO' | translate  }}
      </caption>
      <thead>
      <tr>
        <th class="col-4">{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.EVENTS_TIME' | translate }}</th>
        <th class="col-4">{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.EVENTS_PLACE' | translate }}</th>
        <th class="col-4">{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.EVENTS_TEACHER' | translate }}</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngIf="studyEvents.length === 0">
        <tr>
          <td>{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.EVENTS_NO_TIME' | translate }}</td>
          <td>{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.EVENTS_NO_LOCATION' | translate }}</td>
          <ng-container [ngTemplateOutlet]="teachers"></ng-container>
        </tr>
      </ng-container>
      <ng-container *ngIf="studyEvents.length > 0">
        <tr *ngFor="let studyEvent of visibleStudyEvents">
          <td>
            <span
              class="day">{{ studyEvent | studyEventWeekdayOrInterval }} {{ studyEvent | studyEventDateRange }} {{ studyEvent | studyEventTimeRange }}</span>
          </td>
          <td>
            <span
              *ngIf="studyEvent.locationIds?.length === 0">{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.EVENTS_NO_LOCATION' | translate }}</span>
            <div *ngIf="studyEvent.locationIds?.length > 0" class="study-sub-group-container__body__table__row">
              <ng-container [ngTemplateOutlet]="personAndLocationList"
                            [ngTemplateOutletContext]="{isPersonPipe: false, values: studyEvent.locationIds}"></ng-container>
              <ng-container [ngTemplateOutlet]="popover"
                            [ngTemplateOutletContext]="{showPopover: hasIrregularLocations(studyEvent.events), popoverText: 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.EVENTS_LOCATION_CHANGES'}">
              </ng-container>
            </div>
          </td>
          <ng-container [ngTemplateOutlet]="teachers"></ng-container>
        </tr>
      </ng-container>
      </tbody>
    </table>
    </div>
    <ng-template [ngTemplateOutlet]="showMore"
                 [ngTemplateOutletContext]="{showMore: visibleStudyEvents.length !== studyEvents.length, studyEvents: studyEvents}"></ng-template>
  </ng-container>
  <sis-expandable *ngIf="irregularEvents.length > 0"
                  [variant]="'link'"
                  [title]="'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.EVENTS_SHOW_EXCEPTIONS' | translate">
    <ng-template sisExpandableContent>
      <div *ngFor="let irregularEvent of irregularEvents" class="study-sub-group-container__body__events">
        <div
          class="study-sub-group-container__body__events__header">{{ getWeekDayAndDate(irregularEvent.start) }} {{irregularEvent.end ? (getTime(irregularEvent.start) + '-' + getTime(irregularEvent.end)) : getTime(irregularEvent.start) }}</div>
        <ng-container *ngIf="irregularEvent.cancelled">
          <sis-tiny-badge
            variant="danger">{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.EVENTS_CANCELLED' | translate }}</sis-tiny-badge>
        </ng-container>
        <ng-container *ngIf="irregularEvent.excluded">
          <sis-tiny-badge
            variant="danger">{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.EVENTS_EXCLUDED' | translate }}</sis-tiny-badge>
        </ng-container>
        <ng-container *ngIf="!irregularEvent.excluded && !irregularEvent.cancelled">
          <div *ngIf="irregularEvent.irregularLocationIds">
            <span>{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.EVENTS_LOCATION_CHANGE' | translate}}
              <ng-container
                *ngIf="irregularEvent.irregularLocationIds.length === 0">{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.EVENTS_LOCATION_REMOVED' | translate }}</ng-container>
              <ng-container [ngTemplateOutlet]="personAndLocationList"
                            [ngTemplateOutletContext]="{isPersonPipe: false, values: irregularEvent.irregularLocationIds}"></ng-container>
            </span>
          </div>
        </ng-container>
        <div *ngIf="irregularEvent.notice">
          <span>{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.EVENTS_ADDITIONAL_INFO' | translate }} {{ irregularEvent.notice | localizedString }}</span>
        </div>
      </div>
    </ng-template>
  </sis-expandable>
</ng-container>

<ng-template #teachers>
  <td>
    <ng-container *ngIf="!teacherIds || teacherIds.length === 0">
      <span>{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.EVENTS_NO_TEACHER' | translate }}</span>
    </ng-container>
    <div *ngIf="teacherIds && teacherIds.length > 0" class="study-sub-group-container__body__table__row">
      <ng-container [ngTemplateOutlet]="personAndLocationList"
                    [ngTemplateOutletContext]="{isPersonPipe: true, values: teacherIds}"></ng-container>
    </div>
  </td>
</ng-template>

<ng-template #showMore let-studyEvents="studyEvents" let-showMore="showMore">
  <sis-show-more *ngIf="studyEvents.length > 3"
                 [text]="showMore ? ('ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.EVENTS_SHOW_REST' | translate) : ('ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.EVENTS_HIDE_REST' | translate)"
                 [direction]="showMore ? 'cw-90' : 'ccw-90'"
                 [placement]="showMore ? 'bottom' : 'top'"
                 (click)="setVisibleEnrolments(showMore, studyEvents)">
  </sis-show-more>
</ng-template>

<ng-template #popover let-showPopover="showPopover" let-popoverText="popoverText">
  <sis-popover [popoverText]="popoverText | translate"
               *ngIf="showPopover">
    <button type="button" class="non-button sis-popover-trigger-content"
            [attr.aria-label]="'ARIA_LABEL.SHOW_DETAILS' | translate">
      <sis-icon [icon]="'info-circle-fill'" aria-hidden="true"></sis-icon>
    </button>
  </sis-popover>
</ng-template>

<ng-template #personAndLocationList let-values="values" let-isPersonPipe="isPersonPipe">
  <ng-container *ngFor="let value of values; let last = last">
    <ng-container *ngIf="isPersonPipe">
      <span class="study-sub-group-container__body__table__row__list-item">{{ value | publicPerson | async | personFullName }}</span>
    </ng-container>
    <ng-container *ngIf="!isPersonPipe && value | location | async as location">
      <span class="study-sub-group-container__body__table__row__list-item">{{ location.building.name | localizedString }} {{ location.name | localizedString }}</span>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #ssgSizeNotDefined>
  <span class="value">{{'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.SIZE_NOT_DEFINED' | transloco}}</span>
</ng-template>
