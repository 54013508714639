import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { LocalizedString, OtmId, UniversityOrganisation, UniversitySettings } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'sis-common/auth/auth-service';
import { ConfigService } from 'sis-common/config/config.service';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { LanguageChangeService } from '../../l10n/language-change.service';
import { UniversityService } from '../../service/university.service';
import { navUrls, NavUtilsService } from '../nav-utils/nav-utils.service';

interface LanguageSelection {
    language: string;
    name: LocalizedString | string;
}

interface UserRoleSelection {
    active: boolean;
    nameKey: string;
    url: string;
}

interface ExternalLink {
    name: LocalizedString;
    url: string;
}

interface NavigationLink {
    class: string;
    nameKey: string;
    beforeNavigation?: (event: MouseEvent) => void;
    url: string;
    badgeCount?: number;
}

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-user-settings-menu',
    templateUrl: './user-settings-menu.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSettingsMenuComponent implements OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.topNav.userSettingsMenu.downgraded',
        directiveName: 'sisUserSettingsMenu',
    };

    /** The amount of unread messages the user has */
    @Input() messageCount = 0;

    universityLinks$: Observable<ExternalLink[]>;

    languageSelections: LanguageSelection[] = [];
    universitySelections: UniversityOrganisation[] = [];
    userRoleSelections: UserRoleSelection[] = [];
    navigationLinks: NavigationLink[] = [];

    activeRoleNameKey: string;
    currentUniversityOrgId: OtmId;

    constructor(private configService: ConfigService,
                private universityService: UniversityService,
                private navUtilsService: NavUtilsService,
                private localeService: LocaleService,
                private languageChangeService: LanguageChangeService,
                private authService: AuthService) {}

    ngOnInit() {
        this.initLanguageSelections();
        this.universityLinks$ = this.initUniversityLinks();
        this.universitySelections = this.getUniversitySelections();
        this.userRoleSelections = this.getUserRoleSelections(this.navUtilsService.getSelectedContextPath());
        this.navigationLinks = this.getNavigationLinks();
        this.activeRoleNameKey = this.getActiveRoleNameKey(this.navUtilsService.getSelectedContextPath());
        this.currentUniversityOrgId = this.universityService.getCurrentUniversityOrgId();
    }

    get currentLanguage(): string {
        return this.localeService.getCurrentLanguage();
    }

    get displayName(): string {
        return this.authService.displayname();
    }

    get firstNameInitialLetter(): string {
        return this.authService.firstNameInitialLetter();
    }

    get lastNameInitialLetter(): string {
        return this.authService.lastNameInitialLetter();
    }

    changeLanguage(language: string): void {
        this.languageChangeService.use(language);
    }

    changeUniversity(universityOrgId: OtmId): void {
        this.universityService.setCurrentUniversityOrgId(universityOrgId);
        window.location.reload();
    }

    updateActiveRole(role: UserRoleSelection): void {
        // If role is changed but context isn't, update active role to match context
        if (role.url === this.navUtilsService.getContextPath()) {
            this.activeRoleNameKey = this.getActiveRoleNameKey(this.navUtilsService.getContextPath());
            this.userRoleSelections = this.getUserRoleSelections(this.navUtilsService.getContextPath());
            // Special case for staff url, since navUrls.STAFF_URL contains full path of the front page and will
            // therefore not match this.navUtilsService.getContextPath() which returns only /staff
        } else if (role.url === navUrls.STAFF_URL) {
            this.activeRoleNameKey = this.getActiveRoleNameKey(navUrls.STAFF_URL);
            this.userRoleSelections = this.getUserRoleSelections(navUrls.STAFF_URL);
        }
    }

    private initLanguageSelections(): void {
        this.localeService.getLocalizedLanguageNames()
            .subscribe((languageNames) => {
                this.languageSelections = this.localeService.getOfficialLanguages()
                    .map(language => ({ language, name: languageNames[language] }));

                // Languages are hardcoded in user settings menu since language options should not be translated
                this.languageSelections.forEach((value) => {
                    if (value.language === 'fi') value.name = 'Suomi';
                    if (value.language === 'sv') value.name = 'Svenska';
                    if (value.language === 'en') value.name = 'English';
                });
            });
    }

    private getUniversitySelections(): UniversityOrganisation[] {
        return this.configService.get().homeOrganisations || [];
    }

    private getUserRoleSelections(context: string): UserRoleSelection[] {
        const roles: UserRoleSelection[] = [];
        const universityConfig = this.configService.get();
        if (this.authService.hasScope('student') || this.authService.hasScope('staff')) {
            roles.push({
                active: context === navUrls.STUDENT_URL,
                nameKey: 'AUTH.APPROLE.STUDENT',
                url: !universityConfig.studentFrontpageRedirectUrl ?
                    navUrls.STUDENT_URL : navUrls.STUDENT_PLAN_URL,
            });
        }
        if (this.authService.hasScope('staff')) {
            roles.push({
                active: context === navUrls.TEACHER_URL,
                nameKey: 'AUTH.APPROLE.TEACHER',
                url: navUrls.TEACHER_URL,
            });
            roles.push({
                active: (context === navUrls.STAFF_URL || context === '/staff'),
                nameKey: 'AUTH.APPROLE.STAFF',
                url: navUrls.STAFF_URL,
            });
        }
        if (this.authService.hasScope(['admin', 'admin-ui'])) {
            roles.push({
                active: context === navUrls.ADMIN_URL,
                nameKey: 'AUTH.APPROLE.ADMIN',
                url: navUrls.ADMIN_URL,
            });
        }

        return roles;
    }

    private initUniversityLinks(): Observable<ExternalLink[]> {
        return this.universityService.getCurrentUniversitySettings()
            .pipe(
                map((universitySettings: UniversitySettings) => {
                    const universityLinks: ExternalLink[] = [];
                    if (universitySettings.feedbackLink) {
                        universityLinks.push({
                            name: universitySettings.feedbackLink.title,
                            url: universitySettings.feedbackLink.url,
                        });
                    }
                    if (universitySettings.helpdeskLink) {
                        universityLinks.push({
                            name: universitySettings.helpdeskLink.title,
                            url: universitySettings.helpdeskLink.url,
                        });
                    }
                    return universityLinks;
                }),
            );
    }

    private getNavigationLinks(): NavigationLink[] {
        const links: NavigationLink[] = [];
        const universityConfig = this.configService.get();
        if (universityConfig.logoutUrl) {
            const logoutUrlPrefix = (universityConfig.logoutUrl || '').startsWith('http') ? '' : window.location.origin;
            const targetUrl = `${window.location.origin}${this.navUtilsService.getContextPath()}/login?loggedOut=true`;
            links.push({
                class: 'logout-selection anchor-button',
                nameKey: 'AUTH.LOGOUT',
                beforeNavigation: () => {
                    this.authService.clear();
                },
                url: `${logoutUrlPrefix}${universityConfig.logoutUrl}${targetUrl}`,
            });
        }

        return links;
    }

    private getActiveRoleNameKey(context: string): string {
        switch (context) {
            case (navUrls.ADMIN_URL):
                return 'AUTH.APPROLE.ADMIN';
            case (navUrls.STUDENT_URL):
                return 'AUTH.APPROLE.STUDENT';
            case (navUrls.STAFF_URL):
                return 'AUTH.APPROLE.STAFF';
            case (navUrls.TEACHER_URL):
                return 'AUTH.APPROLE.TEACHER';
            case ('/staff'):
                return 'AUTH.APPROLE.STAFF';
        }
        return null;
    }
}
