<div *ngIf="application.questionnaireAnswers && application.questionnaireAnswers.length > 0">
  <div class="grouping-panel">
    <h4>{{ 'STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.OTHER_INFO.APPLICATION_QUESTIONS' | translate }}</h4>
    <div class="grouping-panel-content">
      <div *ngFor="let answer of application.questionnaireAnswers; first as isFirst;"
           [class.answer-margin]="!isFirst">
        <sis-degree-programme-attainment-application-answer
            [answer]="answer">
        </sis-degree-programme-attainment-application-answer>
      </div>
    </div>
  </div>
</div>
