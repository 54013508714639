<sis-expandable [level]="level" [title]="'STUDIES.COURSE_UNIT_INFO_MODAL.COURSE_UNIT_SUBSTITUTIONS' | transloco: {currentUniversity: universityName}" [closed]="false">
  <ng-template sisExpandableContent>
    <ng-container *ngIf="courseUnit.substitutions?.length > 0 || courseUnit.equivalentCoursesInfo">
      <div *ngIf="!hasValidatablePlan" role="heading" [attr.aria-level]="level" class="fudis-heading__lg">
        {{'STUDIES.COURSE_UNITS_BASICINFO.SUBSTITUTIONS' | transloco}}
      </div>
      <p *ngIf="hasValidatablePlan" ><transloco key="{{'STUDIES.COURSE_UNIT_INFO_MODAL.SUBSTITUTE_INFORMATION'}}" [params]="{currentUniversity: universityName}"></transloco></p>
    </ng-container>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group-mimic" *ngIf="courseUnit.substitutions?.length > 0 || courseUnit.equivalentCoursesInfo">
          <div role="heading" [attr.aria-level]="level+1" class="fudis-heading__md">{{'STUDIES.COURSE_UNIT_INFO_MODAL.SUBSTITUTE_SUBTITLE' | transloco}}</div>
          <div *ngFor="let substitution of courseUnit.substitutions; index as substitutionIndex" class="substitution">
            <ng-container *ngIf="hasValidatablePlan">
              <legend class="sis-label">
                {{ ('STUDIES.COURSE_UNIT_INFO_MODAL.SUBSTITUTIONS_OPTION' | translate) + ' ' + (substitutionIndex + 1)}}
              </legend>
              <sis-course-unit-substitution [substitution]="substitution"
                                            [colorIndex]="substitutionIndex"
                                            [language]="contentLanguage"
                                            (openCourseUnitModalEvent)="openCourseUnitModalEvent.emit($event)">
              </sis-course-unit-substitution>
            </ng-container>
          </div>
          <div class="equivalent-courses-info">
            <sis-localized-markup-string-view [content]="courseUnit.equivalentCoursesInfo"></sis-localized-markup-string-view>
          </div>
        </div>
        <div class="form-group-mimic" *ngIf="courseUnit.substitutions?.length === 0 && !courseUnit.equivalentCoursesInfo">
          <p class="sis-guidance"> {{'STUDIES.COURSE_UNIT_INFO_MODAL.NO_SUBSTITUTIONS' | transloco}}</p>
        </div>
      </div>
    </div>
  </ng-template>
</sis-expandable>
