<div class="modal-header">
  <h2 class="modal-title">{{this.title}}</h2>
  <sis-modal-close-button/>
</div>
<div class="modal-body">
  <sis-localized-markup-string-view *ngIf="contentHtml" [content]="contentHtml" />
  <ng-container *ngIf="contentTranslateKey">
    <transloco [key]="contentTranslateKey" [params]="contentTranslateParameters"></transloco>
  </ng-container>
</div>
