<div *ngIf="courseUnitSelection" class="course-unit-selection-info">
  <ng-container *ngIf="!hasCompletionMethods()">
    <div class="sis-px-xxs">
      <span><sis-icon icon="info-circle-fill"></sis-icon>{{ 'PLAN.NO_COMPLETION_METHODS' | transloco }}</span>
    </div>
  </ng-container>

  <ng-container *ngIf="hasCompletionMethods()">
    <div *ngIf="isCompletionMethodSelectionMissing(); else completionMethodChosen" class="sis-px-xxs">
      <span><sis-icon icon="info-circle-fill"></sis-icon>{{ 'PLAN.MISSING_COMPLETION_METHOD' | transloco }}</span>
      <sis-button (clicked)="openDialog()" [naked]="true" [size]="'sm'">{{ 'CONFIRM_AND_SELECT' | transloco }}</sis-button>
    </div>
  </ng-container>

  <ng-template #completionMethodChosen>
    <div class="sis-px-xxs">
      <span><sis-icon icon="check" color="success"></sis-icon>{{ 'PLAN.COMPLETION_METHOD_CHOSEN' | transloco: { completion_method_index: getCompletionMethodIndex() } }}</span>
      <sis-button (clicked)="openDialog()" [naked]="true" [size]="'sm'">{{ 'PLAN.CHANGE_COMPLETION_METHOD' | transloco }}</sis-button>
    </div>
  </ng-template>
</div>
