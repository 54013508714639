import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'sis-common/modal/modal.service';

import { FileItem } from '../file-upload.component';

export interface PreviouslyAddedFilesValues {
    previouslyAddedFiles: FileItem[];
}

@Component({
    selector: 'sis-edit-application-attachments-modal',
    templateUrl: './edit-application-attachments-modal.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class EditApplicationAttachmentsModalComponent {

    addedFiles: FileItem[];
    acceptedFileTypes = ['application/pdf', 'text/plain', 'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/png', 'image/jpeg'];

    constructor(
        @Inject(ModalService.injectionToken) public values: PreviouslyAddedFilesValues,
        private modal: NgbActiveModal) {}

    droppedFiles(files: FileItem[]) {
        this.addedFiles = files;
    }

    close() {
        this.modal.dismiss();
    }

    save() {
        this.modal.close(this.addedFiles);
    }

}
