<ng-container *transloco="let t">
  <app-widget-container [title]="t('FRONTPAGE.STUDY_PROGRESS.TITLE')" [subtitle]="t('FRONTPAGE.STUDY_PROGRESS.SUBTITLE')">
    <ng-container *ngIf="ready(); else loading">

      <p *ngIf="studyRights?.length === 0" class="app-widget-container__content__info-text sis-mb-sm">
        {{t('FRONTPAGE.STUDY_PROGRESS.NO_STUDY_RIGHTS')}}
      </p>
      <p *ngIf="studyRights?.length > 0 && studyRightIdOptions?.length === 0" class="app-widget-container__content__info-text sis-mb-sm">
        {{t('FRONTPAGE.STUDY_PROGRESS.PLAN_MISSING')}}
      </p>
      <p *ngIf="searchFailed()" class="app-widget-container__content__info-text sis-mb-sm">
        {{t('FRONTPAGE.STUDY_PROGRESS.FETCHING_PLANS_FAILED')}}
      </p>

      <label *ngIf="studyRightIdOptions?.length > 0" class="mb-2">{{ t('FRONTPAGE.STUDY_PROGRESS.EDUCATION') }}</label>
      <!-- This is two because header is count as one -->
      <p *ngIf="studyRightIdOptions?.length === 2" class="app-widget-container__content__info-text sis-mb-sm">
        {{ studyRightIdOptions[1].label }}
      </p>
      <ng-container *ngIf="studyRightIdOptions?.length > 2">
        <sis-dropdown-select
                (selectionChange)="onStudyRightChange($event)"
                [options]="studyRightIdOptions"
                [selection]="selectedStudyRightIdAndModuleId"/>
      </ng-container>

      <ng-container *ngIf="selectedPlan">
        <ng-container *ngIf="readyFetchingStudyProgress(); else loading">
          <div *ngIf="studyProgressGraphResults" class="col-12 sis-mt-xl sis-mb-lg plan-container">
            <section class="col-6 plan-section separator">
              <p class="sis-mb-zero">{{t('FRONTPAGE.STUDY_PROGRESS.PLANNED')}}</p>
              <p class="big-font sis-mt-sm sis-mb-sm">{{ studyProgressGraphResults.planned.percentage }}%</p>
              <p class="sis-mb-zero">{{ studyProgressGraphResults.planned.from }}/{{ studyProgressGraphResults.planned.to }} {{t('CREDITS')}}</p>
            </section>
            <section class="col-6 plan-section">
              <p class="sis-mb-zero">{{t('FRONTPAGE.STUDY_PROGRESS.PASSED')}}</p>
              <p class="big-font sis-mt-sm sis-mb-sm">{{ studyProgressGraphResults.attained.percentage }}%</p>
              <p class="sis-mb-zero">{{ studyProgressGraphResults.attained.from }}/{{ studyProgressGraphResults.attained.to }} {{t('CREDITS')}}</p>
            </section>
          </div>
          <div class="sis-pl-md sis-pr-md sis-mb-sm">
            <sis-contextual-notification *ngIf="!studyProgressGraphResults" variant="warning">
              <div> {{t('FRONTPAGE.STUDY_PROGRESS.FETCHING_PLANS_FAILED')}}</div>
            </sis-contextual-notification>
            <!-- state: PlanValidationState -->
            <sis-contextual-notification *ngIf="moduleValidationResults?.state === 'INVALID'" variant="warning">
              <div> {{ t('FRONTPAGE.STUDY_PROGRESS.PLAN_IS_INVALID') }}</div>
            </sis-contextual-notification>

            <sis-contextual-notification *ngIf="moduleValidationResults?.state === 'INCOMPLETE' && studyProgressGraphResults?.planned.percentage >= 100" variant="warning">
              <div> {{ t('FRONTPAGE.STUDY_PROGRESS.PLAN_IS_DEFICIENT') }}</div>
            </sis-contextual-notification>
          </div>
        </ng-container>
      </ng-container>

    </ng-container>
  </app-widget-container>
</ng-container>

<ng-template #loading>
  <div aria-busy="true" class="d-flex justify-content-center pt-3">
    <sis-small-spinner />
  </div>
</ng-template>
