import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { SisCommonModule } from 'sis-common/sis-common.module';
import { SisComponentsModule } from 'sis-components/sis-components.module';

import { StudentCommonModule } from '../common/common.module';

import { ApplicationsModule } from './applications/applications.module';
import { CancelApplicationModalComponent } from './applications/cancelApplicationModal.component';
import { CancelApplicationModalDirective } from './applications/cancelApplicationModal.dir.upgraded';
import { ShowModuleContentApplicationModalComponent } from './applications/showModuleContentApplicationModal.component';
import { ShowModuleContentApplicationModalDirective } from './applications/showModuleContentApplicationModal.dir.upgraded';
import { AttainmentModule } from './attainment/attainment.module';
import { ProfileEnrolmentsComponent } from './enrolments/profile-enrolments.component';
import { GrantsPageComponent } from './payments-and-grants/grants/grants-page/grants-page.component';
import { GrantsTableComponent } from './payments-and-grants/grants/grants-table/grants-table.component';
import { StudyYearGrantsComponent } from './payments-and-grants/grants/study-year-grants/study-year-grants.component';
import { PaymentAndGrantTabsComponent } from './payments-and-grants/payment-and-grant-tabs/payment-and-grant-tabs.component';
import { OiliPaymentsTableComponent } from './payments-and-grants/payments/oili-payments-table/oili-payments-table.component';
import { PaymentsComponent } from './payments-and-grants/payments/payments.component';
import { StudyYearPaymentsComponent } from './payments-and-grants/payments/study-year-payments/study-year-payments.component';
import { TuitionFeePaymentsTableComponent } from './payments-and-grants/payments/tuition-fee-payments-table/tuition-fee-payments-table.component';
import { StudentPersonGroupsComponent } from './personGroups/student-person-groups/student-person-groups.component';
import { ProfileSecondaryNavigationComponent } from './profile-secondary-navigation/profile-secondary-navigation.component';
import { profileStates } from './profile.route-ng';
import { StudyRightsModule } from './studyRights/study-rights.module';

@NgModule({
    declarations: [
        CancelApplicationModalComponent,
        CancelApplicationModalDirective,
        ProfileSecondaryNavigationComponent,
        GrantsPageComponent,
        GrantsTableComponent,
        PaymentAndGrantTabsComponent,
        PaymentsComponent,
        OiliPaymentsTableComponent,
        ShowModuleContentApplicationModalComponent,
        ShowModuleContentApplicationModalDirective,
        StudyYearGrantsComponent,
        StudyYearPaymentsComponent,
        TuitionFeePaymentsTableComponent,
        StudentPersonGroupsComponent,
        ProfileEnrolmentsComponent,
    ],
    imports: [
        BrowserModule,
        SisCommonModule,
        SisComponentsModule,
        StudentCommonModule,
        TranslateModule.forChild(),
        UIRouterModule,
        UIRouterUpgradeModule.forChild({ states: profileStates }),
        ApplicationsModule,
        StudyRightsModule,
        AttainmentModule,
    ],
})
export class ProfileModule {}
