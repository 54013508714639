import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { loginStates } from './login.route';
import { StudentAuthRouteService } from './student-auth-route.service';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        UIRouterUpgradeModule.forChild({ states: loginStates }),
    ],
})
export class StudentAuthRouteModule {
    constructor(private studentAuthRouteService: StudentAuthRouteService) {
        this.studentAuthRouteService.initialize();
    }
}
