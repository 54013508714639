angular.module('student.profile').filter('translateAttainmentState', ["$translate", function ($translate) {
  return function (state) {
    var stateTranslations = {
      ATTAINED: 'ATTAINMENT_STATE.ATTAINED',
      INCLUDED: 'ATTAINMENT_STATE.INCLUDED',
      SUBSTITUTED: 'ATTAINMENT_STATE.SUBSTITUTED',
      FAILED: 'ATTAINMENT_STATE.FAILED'
    };
    return $translate.instant(stateTranslations[state]);
  };
}]);