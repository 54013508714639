<article [ngClass]="{'study-wrapper-max-width': !disableBoxMaxWidth }" class="study-wrapper">
  <div class="study-header" role="heading" [attr.aria-level]="level">
    <ng-container *ngTemplateOutlet="nameClickAction.observed ? linkTitle : title"></ng-container>
  </div>
  <div class="study-content">
    <div *ngIf="showCancelledBadge" class="row">
      <sis-tiny-badge variant="danger">
        {{ 'FLOW_STATE.CANCELLED' | translate }}
      </sis-tiny-badge>
    </div>
    <div class="d-flex">
      <div><sis-icon icon="calendar"></sis-icon></div>
      <div>
        <span class="visually-hidden">{{'ARIA_LABEL.TIMING_PERIOD' | translate}}</span>
        {{ activityPeriod | localDateRange }}
      </div>
    </div>
    <div *ngIf="attainedCredits" class="row">
      <div class="col-2 col-sm-1"><sis-icon icon="achievement" color="success"></sis-icon></div>
      <div class="col-10 col-sm-11 attainment-info">
        <div>{{'SIS_COMPONENTS.STUDY.ATTAINED' | translate}} ({{attainedCredits}} {{"CREDITS" | translate}})</div>
        <div>{{'SIS_COMPONENTS.STUDY.ATTAINMENT_GRADE' | translate}}: {{gradeId | grade: gradeScaleId | async }}</div>
      </div>
    </div>
  </div>
  <ng-content select="[study-box=notifications]"></ng-content>
  <sis-study-action-box *ngIf="action" [action]="action" (actionChange)="actionChange.emit(id)"
                        [studyInfo]="(name | localizedString) + ' ' + (credits | creditRange)"></sis-study-action-box>
</article>

<ng-template #linkTitle>
  <sis-button link="true" (clicked)="nameClickAction.emit(id)">
    <ng-container *ngTemplateOutlet="title"></ng-container>
  </sis-button>
</ng-template>

<ng-template #title>
  {{name | localizedString}}, {{code}}, {{realisationName | localizedString}}
  <span class="visually-hidden">{{activityPeriod | localDateRange}}</span>
  ({{credits | creditRange}})
</ng-template>
