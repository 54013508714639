import angular from 'angular';
import _ from 'lodash';
import { ModalService } from 'sis-common/modal/modal.service.ts';
import { commonStudyRightServiceModule } from 'sis-components/service/studyRight.service';
import { EnrolmentQuestionnaireModalComponent } from 'sis-components/enrolmentQuestionnaire/enrolmentQuestionnaireModal-ng.component.ts';
import { enrolmentQuestionnaireModalModule } from 'sis-components/enrolmentQuestionnaire/enrolmentQuestionnaireModal.component';
import { enrolmentCalculationModule } from 'sis-components/service/enrolmentCalculation.service';
import { ModifyGroupsModalComponent } from '../../enrolment/modify-groups-modal/modify-groups-modal.component.ts';
import { educationServiceModule } from '../../../service/educationService';
import { planServiceModule } from '../../../service/planService';
import { CancelOrAbortEnrolmentModalComponent } from './cancelOrAbortEnrolmentModal-ng.component.ts';
import { cancelOrAbortEnrolmentModalModule } from './cancelOrAbortEnrolmentModal.component';
import { EnrolmentModalComponent } from './enrolmentModal-ng.component.ts';
import { enrolmentModalModule } from './enrolmentModal.component';
export const enrolmentModalServiceModule = 'student.common.components.course-unit-realisation.enrolmentModalService';
(function () {
  enrolmentModalService.$inject = ["$q", "modalService", "commonStudyRightService", "educationService", "planService", "enrolmentCalculationService"];
  angular.module(enrolmentModalServiceModule, [commonStudyRightServiceModule, educationServiceModule, planServiceModule, cancelOrAbortEnrolmentModalModule, enrolmentModalModule, enrolmentQuestionnaireModalModule, ModalService.downgrade.moduleName, enrolmentCalculationModule]).service('enrolmentModalService', enrolmentModalService);
  function enrolmentModalService($q, modalService, commonStudyRightService, educationService, planService, enrolmentCalculationService) {
    return {
      /**
       * Returns promise that resolves to object containing updated enrolment and possibly created answers if operation was success.
       * Returns a rejected promise if user clicks on cancel.
       */
      openEnrolmentModal(enrolment, courseUnitRealisation, courseUnit, enrolmentQuestionnaireAnswers, enrolmentQuestionnaire) {
        return $q.all([planService.findMyPlans(), educationService.getMyStudyRightEducations(true), commonStudyRightService.studyRights(), enrolmentCalculationService.getEnrolmentCalculationConfigOrEmpty(_.get(courseUnitRealisation, 'id'))]).then(([myPlans, myStudyRightEducations, myStudyRights, calculationConfig]) => modalService.open(EnrolmentModalComponent, {
          calculationConfig,
          courseUnit,
          courseUnitRealisation,
          enrolment,
          enrolmentQuestionnaire,
          enrolmentQuestionnaireAnswers,
          myPlans,
          myStudyRightEducations,
          myStudyRights,
          update: false
        }, {
          ariaLabelledBy: 'modal-title',
          size: 'sm'
        }).result);
      },
      /**
       * Returns promise that resolves to object containing updated enrolment and possibly created or updated answers if operation was
       * success.
       * Returns a rejected promise if user clicks on cancel.
       */
      openUpdateEnrolmentModal(enrolment, courseUnitRealisation, courseUnit, enrolmentQuestionnaireAnswers, enrolmentQuestionnaire) {
        return $q.all([educationService.getMyStudyRightEducations(true), commonStudyRightService.studyRights()]).then(([myStudyRightEducations, myStudyRights]) => modalService.open(EnrolmentModalComponent, {
          courseUnit: enrolment.courseUnit,
          courseUnitRealisation: enrolment.courseUnitRealisation,
          enrolment,
          enrolmentQuestionnaire,
          enrolmentQuestionnaireAnswers,
          myStudyRightEducations,
          myStudyRights,
          update: true
        }, {
          ariaLabelledBy: 'modal-title',
          size: 'sm'
        }).result);
      },
      /**
       * Returns promise that resolves to object containing updated enrolment if operation was success.
       * Returns a rejected promise if user clicks on cancel.
       */
      openCancelEnrolmentModal(enrolment, courseUnitRealisation, courseUnit) {
        return $q.all([educationService.getMyStudyRightEducations(true), commonStudyRightService.studyRights()]).then(([myStudyRightEducations, myStudyRights]) => modalService.open(CancelOrAbortEnrolmentModalComponent, {
          abort: false,
          courseUnit,
          courseUnitRealisation,
          enrolment,
          myStudyRightEducations,
          myStudyRights
        }, {
          ariaLabelledBy: 'modal-title',
          size: 'sm'
        }).result);
      },
      /**
       * Returns promise that resolves to object containing updated enrolment if operation was success.
       * Returns a rejected promise if user clicks on cancel.
       */
      openAbortEnrolmentModal(enrolment, courseUnitRealisation, courseUnit) {
        return $q.all([educationService.getMyStudyRightEducations(true), commonStudyRightService.studyRights()]).then(([myStudyRightEducations, myStudyRights]) => modalService.open(CancelOrAbortEnrolmentModalComponent, {
          abort: true,
          courseUnit,
          courseUnitRealisation,
          enrolment,
          myStudyRightEducations,
          myStudyRights
        }, {
          ariaLabelledBy: 'modal-title',
          size: 'sm'
        }).result);
      },
      openEnrolmentQuestionnaireModal(enrolmentQuestionnaire, enrolmentQuestionnaireAnswers, courseUnitRealisation) {
        return modalService.open(EnrolmentQuestionnaireModalComponent, {
          enrolmentQuestionnaire,
          enrolmentQuestionnaireAnswers,
          courseUnitRealisation
        }, {
          size: 'sm',
          closeWithOutsideClick: true
        }).result;
      },
      openModifyGroupsModal(enrolment) {
        return modalService.open(ModifyGroupsModalComponent, {
          enrolment
        }, {
          ariaLabelledBy: 'modal-title',
          size: 'md'
        }).result;
      }
    };
  }
})();