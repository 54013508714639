import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Attainment, CourseUnit, CourseUnitAttainment, RdiCreditsEnabled } from 'common-typescript/types';
import { take } from 'rxjs';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { CourseUnitEntityService } from 'sis-components/service/course-unit-entity.service';

@Component({
    selector: 'app-course-unit-attainment-details',
    templateUrl: './course-unit-attainment-details.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CourseUnitAttainmentDetailsComponent implements OnInit {
    rdiCreditsEnabled: RdiCreditsEnabled;

    @Input() attainment: CourseUnitAttainment;
    @Input() secondaryAttainments: Attainment[];
    @Input() expirationImminent: boolean;

    constructor(
        private courseUnitEntityService: CourseUnitEntityService,
        private appErrorHandler: AppErrorHandler,
    ) { }

    ngOnInit() {
        this.courseUnitEntityService.getById(this.attainment.courseUnitId)
            .pipe(take(1), this.appErrorHandler.defaultErrorHandler())
            .subscribe((courseUnit: CourseUnit) => {
                this.rdiCreditsEnabled = courseUnit.rdiCreditsEnabled;
            });
    }
}
