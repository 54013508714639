<ng-container *transloco="let t">
  <div class="modal-header">
    <h2 class="modal-title" tabindex="0">
      {{ t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.TITLE') }}
    </h2>
    <button class="modal-close"
                (click)="cancel()"
                [attr.aria-label]="t('ARIA_LABEL.CLOSE')">
      <sis-icon icon="ring-close"></sis-icon>
    </button>
  </div>
  <div class="modal-body">
    <p class="guidance">{{ t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.EDIT_MODE_GUIDANCE') }}</p>
    <form [formGroup]="customStudyDraftForm">
      <div class="form-group">
        <sis-text-field
          [id]="'name'"
          [control]="name"
          [type]="'text'"
          [label]="t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.NAME')"
          [guidanceText]="t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.NAME_GUIDANCE')">
        </sis-text-field>
      </div>
      <div class="form-group">
        <label [class]="getLabelState(credits)"
               for="credits">{{ t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.CREDITS') + ' (' + t('CREDITS') + ')' }}</label>
        <sis-popover popoverClass="dark"
                     [popoverPlacement]="'top'"
                     (shown)="popoverShown = true"
                     (hidden)="popoverShown = false">
          <button type="button"
                  class="non-button sis-popover-trigger-content"
                  [attr.aria-label]="'ARIA_LABEL.SHOW_DETAILS' | translate"
                  [attr.aria-expanded]="popoverShown">
            <sis-icon icon="info-circle-fill" aria-hidden="true"></sis-icon>
          </button>
          <div class="sis-popover-popover-content">
            <p>{{t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.CREDITS_GUIDANCE')}}</p>
          </div>
        </sis-popover>
        <div class="row">
          <sis-text-field [control]="credits"
                          [showLabelIcon]="false"
                          id="credits"
                          type="number"
                          class="col-4">
          </sis-text-field>
        </div>
      </div>
      <div class="form-group">
        <sis-text-field
          [id]="'location'"
          [control]="location"
          [type]="'text'"
          [label]="t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.LOCATION')"
          [guidanceText]="t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.LOCATION_GUIDANCE')">
        </sis-text-field>
      </div>
      <div class="form-group">
        <sis-text-field
          [id]="'description'"
          [textarea]="true"
          [control]="description"
          [type]="'text'"
          [label]="t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.DESCRIPTION')"
          [guidanceText]="t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.DESCRIPTION_GUIDANCE')">
        </sis-text-field>
      </div>
    </form>
    <p class="guidance">{{ t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.VIEW_MODE_HELP') }}</p>
  </div>
  <div class="modal-footer">
    <sis-button [hollow]="true" (clicked)="cancel()" [label]="t('BUTTON.CANCEL')"></sis-button>
    <sis-button (clicked)="addStudyDraft()"
                [label]="t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.BUTTON_ADD')">
    </sis-button>
  </div>
</ng-container>
