import * as _ from 'lodash';

import {
    AnyCourseUnitRule,
    AnyModuleRule,
    CompositeRule,
    CourseUnit,
    CourseUnitCountRule,
    CourseUnitRule,
    CreditsRule,
    CustomCourseUnitAttainment,
    CustomModuleAttainment,
    CustomStudyDraft,
    EntityWithRule,
    ModuleRule,
    PlanValidationState,
    RangeValidationResultState,
    Rule,
} from '../../../types';
import { Range } from '../../model/range';
import { PlanValidationStateService } from '../../service/planValidationState.service';

import { AttainmentValidation } from './attainmentValidation';
import { ModuleContext } from './context/moduleContext';
import { RuleContext } from './context/ruleContext';
import { CourseUnitValidation } from './courseUnitValidation';
import { ModuleValidation } from './moduleValidation';
import { PlanValidationResult } from './planValidationResult';
import { RangeValidation } from './rangeValidation';
import { ValidatablePlan } from './validatablePlan';

export class RuleValidation {

    static validateRule(rule: Rule,
                        validatablePlan: ValidatablePlan,
                        moduleContext: ModuleContext,
                        planValidationResult: PlanValidationResult,
    ): RuleContext {

        switch (rule.type) {
            case 'CreditsRule':
                return RuleValidation.validateCreditsRule(<CreditsRule> rule, validatablePlan, moduleContext, planValidationResult);
            case 'CourseUnitCountRule':
                return RuleValidation.validateCourseUnitCountRule(<CourseUnitCountRule> rule, validatablePlan, moduleContext, planValidationResult);
            case 'CompositeRule':
                return RuleValidation.validateCompositeRule(<CompositeRule> rule, validatablePlan, moduleContext, planValidationResult);
            case 'ModuleRule':
                return RuleValidation.validateModuleRule(<ModuleRule> rule, validatablePlan, moduleContext, planValidationResult);
            case 'AnyModuleRule':
                return RuleValidation.validateAnyModuleRule(<AnyModuleRule> rule, validatablePlan, moduleContext, planValidationResult);
            case 'CourseUnitRule':
                return RuleValidation.validateCourseUnitRule(<CourseUnitRule> rule, validatablePlan, moduleContext, planValidationResult);
            case 'AnyCourseUnitRule':
                return RuleValidation.validateAnyCourseUnitRule(<AnyCourseUnitRule> rule, validatablePlan, moduleContext, planValidationResult);
            default:
                return new RuleContext();
        }
    }

    static validateCreditsRule(
        creditsRule: CreditsRule,
        validatablePlan: ValidatablePlan,
        moduleContext: ModuleContext,
        planValidationResult: PlanValidationResult,
    ): RuleContext {

        const ruleContext = new RuleContext();
        ruleContext.mergeContext(RuleValidation.validateRule(creditsRule.rule, validatablePlan, moduleContext, planValidationResult));

        const result = RangeValidation.validateRange(new Range(creditsRule.credits), ruleContext.getActualCredits());
        ruleContext.mergePlanValidationState(PlanValidationStateService.fromRangeValidationResult(result, ruleContext.state));
        ruleContext.mergeContextualState(PlanValidationStateService.fromRangeValidationResult(result, ruleContext.contextualState));

        const ruleValidationResults = ruleContext.getResults({
            result: result.result,
            minRequired: result.minRequired,
            maxAllowed: result.maxAllowed,
        });
        RuleValidation.updateRuleValidationResult(
            moduleContext.module.id,
            creditsRule,
            ruleValidationResults,
            planValidationResult,
        );
        return ruleContext;
    }

    static validateCourseUnitCountRule(
        courseUnitCountRule: CourseUnitCountRule,
        validatablePlan: ValidatablePlan,
        moduleContext: ModuleContext,
        planValidationResult: PlanValidationResult,
    ): RuleContext {

        const ruleContext = new RuleContext();
        ruleContext.mergeContext(RuleValidation.validateRule(courseUnitCountRule.rule, validatablePlan, moduleContext, planValidationResult));
        const directCount = _.size(ruleContext.matchingCourseUnitsByGroupId) + _.size(ruleContext.matchingCustomCourseUnitAttainmentsById);
        const result = RangeValidation.validateRange(
            new Range(courseUnitCountRule.count),
            new Range(directCount));
        ruleContext.mergePlanValidationState(PlanValidationStateService.fromRangeValidationResult(result, ruleContext.state));
        ruleContext.mergeContextualState(PlanValidationStateService.fromRangeValidationResult(result, ruleContext.contextualState));

        const ruleValidationResults = ruleContext.getResults({
            directCount,
            result: result.result,
            minRequired: result.minRequired,
            maxAllowed: result.maxAllowed,
        });
        RuleValidation.updateRuleValidationResult(
            moduleContext.module.id,
            courseUnitCountRule,
            ruleValidationResults,
            planValidationResult,
        );
        return ruleContext;
    }

    static validateCompositeRule(
        compositeRule: CompositeRule,
        validatablePlan: ValidatablePlan,
        moduleContext: ModuleContext,
        planValidationResult: PlanValidationResult,
    ): RuleContext {

        const ruleContext = new RuleContext();
        let directCount = 0;
        let implicitCount = 0;
        let implicitCourseUnitIds: string[] = [];
        let implicitModuleIds: string[] = [];
        const anyRules = ['AnyCourseUnitRule', 'AnyModuleRule'];

        const sortedRules = _.sortBy(compositeRule.rules, (rule) => _.intersection(anyRules, [rule.type]).length ? 99 : 1);

        _.forEach(sortedRules, (rule) => {

            const childCtx = RuleValidation.validateRule(rule, validatablePlan, moduleContext, planValidationResult);
            if (childCtx.isActive()) {
                directCount += 1;
                ruleContext.mergeContext(childCtx);
            } else if (childCtx.state === PlanValidationState.IMPLICIT) {
                implicitCount += 1;
                implicitCourseUnitIds = _.concat(implicitCourseUnitIds, childCtx.implicitCourseUnitIds);
                implicitModuleIds = _.concat(implicitModuleIds, childCtx.implicitModuleIds);
                ruleContext.mergeStatesFromOtherContext(childCtx);
            }
        });
        const require = _.defaultTo(compositeRule.require, {
            min: _.size(compositeRule.rules),
            max: _.size(compositeRule.rules),
        });
        const result = RangeValidation.validateRange(new Range(require), new Range(directCount, directCount + implicitCount));
        if (result.result === RangeValidationResultState.IMPLICIT || result.result === RangeValidationResultState.IMPLICIT_OK) {
            ruleContext.mergeImplicitSelections(implicitCourseUnitIds, implicitModuleIds);
        }
        ruleContext.mergePlanValidationState(PlanValidationStateService.fromRangeValidationResult(result, ruleContext.state));
        ruleContext.mergeContextualState(PlanValidationStateService.fromRangeValidationResult(result, ruleContext.contextualState));
        const ruleValidationResults = ruleContext.getResults({
            directCount,
            implicitCount,
            result: result.result,
            minRequired: result.minRequired,
            maxAllowed: result.maxAllowed,
            matchingModulesByGroupId: ruleContext.matchingModulesByGroupId,
            matchingCourseUnitsByGroupId: ruleContext.matchingCourseUnitsByGroupId,
            matchingCustomModuleAttainmentsById: ruleContext.matchingCustomModuleAttainmentsById,
            matchingCustomCourseUnitAttainmentsById: ruleContext.matchingCustomCourseUnitAttainmentsById,
        });
        RuleValidation.updateRuleValidationResult(
            moduleContext.module.id,
            compositeRule,
            ruleValidationResults,
            planValidationResult,
        );
        return ruleContext;
    }

    static validateModuleRule(
        moduleRule: ModuleRule,
        validatablePlan: ValidatablePlan,
        moduleContext: ModuleContext,
        planValidationResult: PlanValidationResult,
    ): RuleContext {

        const ruleContext = new RuleContext();
        const module = validatablePlan.getModuleInPlanByGroupId(moduleRule.moduleGroupId);
        if (module) {
            if (moduleContext.consumeModule(module, planValidationResult)) {
                const result = ModuleValidation.validateModule(module, validatablePlan, planValidationResult);
                ruleContext.mergePartialRuleContextForModule(result);
            } else {
                ruleContext.addImplicitModule(module);
                ruleContext.mergeState(PlanValidationState.IMPLICIT);
            }
            RuleValidation.validateContentFilterForModule(module, validatablePlan, ruleContext);
        }
        const ruleValidationResults = ruleContext.getResults({
            selectedModulesById: _.keyBy([module], 'id'),
        });

        RuleValidation.updateRuleValidationResult(
            moduleContext.module.id,
            moduleRule,
            ruleValidationResults,
            planValidationResult,
        );

        return ruleContext;
    }

    static validateAnyModuleRule(
        anyModuleRule: AnyModuleRule,
        validatablePlan: ValidatablePlan,
        moduleContext: ModuleContext,
        planValidationResult: PlanValidationResult,
    ): RuleContext {

        const ruleContext = new RuleContext();
        _.forEach(moduleContext.unmatchedModulesById, (module) => {
            moduleContext.consumeModule(module, planValidationResult);
            const result = ModuleValidation.validateModule(module, validatablePlan, planValidationResult);
            ruleContext.mergePartialRuleContextForModule(result);
            RuleValidation.validateContentFilterForModule(module, validatablePlan, ruleContext);
        });

        _.forEach(moduleContext.unmatchedCustomModuleAttainmentsById, (customModuleAttainment) => {
            moduleContext.consumeCustomModuleAttainment(customModuleAttainment, planValidationResult);
            ruleContext.addCustomModuleAttainment(customModuleAttainment);
            AttainmentValidation.validateCustomModuleAttainment(customModuleAttainment, ruleContext);
            RuleValidation.validateContentFilterForCustomAttainment(customModuleAttainment, validatablePlan, ruleContext);
        });
        const ruleValidationResults = ruleContext.getResults({
            selectedModulesById: _.keyBy(ruleContext.matchingModulesByGroupId, 'id'),
            selectedCustomModuleAttainmentsById: _.keyBy(ruleContext.matchingCustomModuleAttainmentsById, 'id'),
        });

        RuleValidation.updateRuleValidationResult(
            moduleContext.module.id,
            anyModuleRule,
            ruleValidationResults,
            planValidationResult,
        );
        return ruleContext;
    }

    static validateCourseUnitRule(
        courseUnitRule: CourseUnitRule,
        validatablePlan: ValidatablePlan,
        moduleContext: ModuleContext,
        planValidationResult: PlanValidationResult,
    ): RuleContext {

        const ruleContext = new RuleContext();
        const courseUnit = validatablePlan.getCourseUnitInPlanByGroupId(courseUnitRule.courseUnitGroupId);
        if (courseUnit) {
            if (moduleContext.consumeCourseUnit(courseUnit, planValidationResult)) {
                ruleContext.mergeContext(CourseUnitValidation.validateCourseUnit(courseUnit, validatablePlan, planValidationResult));
            } else {
                ruleContext.addImplicitCourseUnit(courseUnit);
                ruleContext.mergeState(PlanValidationState.IMPLICIT);
            }
            RuleValidation.validateContentFilterForCourseUnit(courseUnit, validatablePlan, ruleContext);
        }
        const ruleValidationResults = ruleContext.getResults({
            selectedCourseUnitsById: _.keyBy([courseUnit], 'id'),
        });

        RuleValidation.updateRuleValidationResult(
            moduleContext.module.id,
            courseUnitRule,
            ruleValidationResults,
            planValidationResult,
        );

        return ruleContext;
    }

    static validateAnyCourseUnitRule(
        anyCourseUnitRule: AnyCourseUnitRule,
        validatablePlan: ValidatablePlan,
        moduleContext: ModuleContext,
        planValidationResult: PlanValidationResult,
    ): RuleContext {

        const ruleContext = new RuleContext();
        _.forEach(moduleContext.unmatchedCourseUnitsById, (courseUnit) => {
            moduleContext.consumeCourseUnit(courseUnit, planValidationResult);
            ruleContext.mergeContext(CourseUnitValidation.validateCourseUnit(courseUnit, validatablePlan, planValidationResult));

            RuleValidation.validateContentFilterForCourseUnit(courseUnit, validatablePlan, ruleContext);
        });

        _.forEach(moduleContext.unmatchedCustomCourseUnitAttainmentsById, (customCourseUnitAttainment) => {
            moduleContext.consumeCustomCourseUnitAttainment(customCourseUnitAttainment, planValidationResult);
            ruleContext.addCustomCourseUnitAttainment(customCourseUnitAttainment);
            AttainmentValidation.validateCustomCourseUnitAttainment(customCourseUnitAttainment, ruleContext);
            RuleValidation.validateContentFilterForCustomAttainment(customCourseUnitAttainment, validatablePlan, ruleContext);
        });

        _.forEach(moduleContext.unmatchedCustomStudyDraftsById, (customStudyDraft) => {
            moduleContext.consumeCustomStudyDraft(customStudyDraft);
            ruleContext.addCustomStudyDraft(customStudyDraft);
            ruleContext.addPlannedCredits(new Range(customStudyDraft.credits));
            RuleValidation.validateContentFilterForCustomStudyDraft(customStudyDraft, validatablePlan, ruleContext);
        });

        const ruleValidationResults = ruleContext.getResults({
            selectedCourseUnitsById: _.keyBy(ruleContext.matchingCourseUnitsByGroupId, 'id'),
            selectedCustomCourseUnitAttainmentsById: _.keyBy(ruleContext.matchingCustomCourseUnitAttainmentsById, 'id'),
            selectedCustomStudyDraftsById: _.keyBy(ruleContext.matchingCustomStudyDraftsById, 'id'),
        });

        RuleValidation.updateRuleValidationResult(
            moduleContext.module.id,
            anyCourseUnitRule,
            ruleValidationResults,
            planValidationResult,
        );

        return ruleContext;
    }

    private static validateContentFilterForModule(
        module: EntityWithRule,
        validatablePlan: ValidatablePlan,
        ruleContext: RuleContext,
    ): void {

        const parentModule = validatablePlan.getParentModuleOrCustomModuleAttainmentForModule(module);
        const studyRightSelectionType = _.get(parentModule, 'contentFilter.studyRightSelectionType');
        if (studyRightSelectionType && !_.isEqual(studyRightSelectionType, _.get(module, 'studyRightSelectionType'))) {
            ruleContext.mergeState(PlanValidationState.INVALID);
        }
    }

    private static validateContentFilterForCourseUnit(
        courseUnit: CourseUnit,
        validatablePlan: ValidatablePlan,
        ruleContext: RuleContext,
    ): void {

        const parentModule = validatablePlan.getParentModuleOrCustomModuleAttainmentForCourseUnit(courseUnit);
        const studyRightSelectionType = _.get(parentModule, 'contentFilter.studyRightSelectionType');
        if (studyRightSelectionType) {
            ruleContext.mergeState(PlanValidationState.INVALID);
        }
    }

    static validateContentFilterForCustomAttainment(
        attainment: CustomCourseUnitAttainment | CustomModuleAttainment,
        validatablePlan: ValidatablePlan,
        ruleContext: RuleContext,
    ): void {

        const parentModule = validatablePlan.getParentModuleOrCustomModuleAttainmentForCustomAttainment(attainment);
        const studyRightSelectionType = _.get(parentModule, 'contentFilter.studyRightSelectionType');
        if (studyRightSelectionType && attainment.type !== 'CustomModuleAttainment') {
            ruleContext.mergeState(PlanValidationState.INVALID);
        }
    }

    static validateContentFilterForCustomStudyDraft(
        customStudyDraft: CustomStudyDraft,
        validatablePlan: ValidatablePlan,
        ruleContext: RuleContext,
    ): void {

        const parentModule = validatablePlan.getModule(customStudyDraft.parentModuleId);
        const studyRightSelectionType = _.get(parentModule, 'contentFilter.studyRightSelectionType');
        if (studyRightSelectionType) {
            ruleContext.mergeState(PlanValidationState.INVALID);
        }
    }

    private static updateRuleValidationResult(
        parentModuleId: string,
        rule: Rule,
        ruleValidationResult: any,
        planValidationResult: PlanValidationResult,
    ): void {

        if (!_.has(planValidationResult.ruleValidationResults, parentModuleId)) {
            planValidationResult.ruleValidationResults[parentModuleId] = {};
        }
        _.set(_.get(planValidationResult.ruleValidationResults, parentModuleId), <string> rule.localId, ruleValidationResult);
    }

}
