import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { numberUtils } from 'common-typescript';
import { MaxLength } from 'common-typescript/constants';
import * as _ from 'lodash';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { AlertsService, AlertType } from '../alerts/alerts-ng.service';

import { FileUploadInfoModalService } from './file-upload-info-modal.service';

export interface FileItem {
    file: File;
    explanation: string;
    preSignedGetUrl?: string;
    localId?: string;
    name: string;
}

/**
 * A simple file upload drag and drop control component.
 */
@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-file-upload',
    templateUrl: './file-upload.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class FileUploadComponent implements OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.fileUpload.downgraded',
        directiveName: 'sisFileUpload',
    };

    // array for valid files
    files: File[] = [];
    fileItems: FileItem[] = [];
    selectedFileItems: FileItem[] = [];

    @Input() description = false;
    @Input() isDisabled: boolean;
    @Input() isRemovable = true;
    @Input() modalMode = false;
    @Input() staffEditMode = false;
    // set accepted file types e.g. 'image/png, image/jpeg, application/pdf'. Defaults to application/pdf.
    @Input() acceptedFilesTypes = ['application/pdf'];
    // Set the maximum size a single file may have, in bytes. Defaults to 10Mb.
    @Input() maxFileSize = 10000000;
    // Initialised max files 20
    @Input() maxFiles = 20;
    @Input() previousFilesEditable = false;
    @Input() showPreviousFilesListButton = false;
    @Input() previouslyAddedFiles: FileItem[] = [];
    @Output() droppedFiles = new EventEmitter<FileItem[]>();

    filesWithInvalidFileFormat: File[] = [];
    filesWithInvalidFileSize: File[] = [];
    filesWithSameFileName: File[] = [];
    filesWithInvalidFileName: File[] = [];

    explanationMaxLength = MaxLength.MAX_MEDIUM_STRING_LENGTH;
    mimeTypesWithExtensions: Map<string, string[]> = new Map([
        ['application/pdf', ['.pdf']],
        ['text/plain', ['.txt']],
        ['application/msword', ['.doc']],
        ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', ['.docx']],
        ['image/png', ['.png']],
        ['image/jpeg', ['.jpeg', '.jpg']],
    ]);

    maxFileSizeString: string;
    acceptedFileTypesText: string;
    editableFiles: FileItem[];

    constructor(private fileUploadInfoModalService: FileUploadInfoModalService,
                private translateService: TranslateService,
                private alertsService: AlertsService,
                public modal: NgbActiveModal) { }

    @ViewChild('fileInput') fileInput: any;

    ngOnInit(): void {
        // bring previously added files
        this.fileItems = [...this.fileItems, ...this.previouslyAddedFiles];
        this.editableFiles = this.previousFilesEditable ? this.fileItems : this.selectedFileItems;
        // creates text representation for max file size
        this.maxFileSizeString = numberUtils.readableFileSizeString(this.maxFileSize); // in MB
        // creates text representation for accepted file types
        this.acceptedFileTypesText = this.readableFileTypesText();
        this.droppedFiles.emit(this.fileItems);
    }

    readableFileSizeString(number: any) {
        return numberUtils.readableFileSizeString(number);
    }

    readableFileTypesText(): string {
        return this.acceptedFilesTypes.map(acceptedFileType => this.mimeTypesWithExtensions.get(acceptedFileType))
            .flat()
            .join(', ');
    }

    onSelect(files: any) {
        if (this.invalidNumberOfFiles(files)) {
            this.alertsService.addAlert({
                message: this.translateService.instant('FILE_UPLOAD.MAX_FILES_WARNING', { x: this.maxFiles }),
                type: AlertType.DANGER,
            });
            return false;
        }

        _.forEach(files, (file) => {
            const fileItem = {
                file,
                explanation: <string> undefined,
                name: file.name.normalize(),
            };
            if (this.validateFormat(file) && this.validateSize(file) && this.validateSameFileName(fileItem) && this.validateCharInFileName(file)) {
                // add valid file
                this.files.push(file);
                this.fileItems.push(fileItem);
                this.selectedFileItems.push(fileItem);
            }
        });

        if (this.filesWithInvalidFileFormat.length > 0 || this.filesWithInvalidFileSize.length > 0 || this.filesWithSameFileName.length > 0 || this.filesWithInvalidFileName.length > 0) {
            this.invalidFilesConfirm(files);
        }

        // emit valid added file items
        this.droppedFiles.emit(this.fileItems);
    }

    onRemove(item: FileItem) {
        this.files = _.filter(this.files, file => item.file !== file);
        this.fileItems = _.filter(this.fileItems, fileItem => item.file !== fileItem.file);
        this.clearFileInput();
        this.selectedFileItems = _.filter(this.selectedFileItems, selectedFileItem => item !== selectedFileItem);
        this.editableFiles = this.previousFilesEditable ? this.fileItems : this.selectedFileItems;
        this.droppedFiles.emit(this.fileItems);
    }

    clearFileInput() {
        this.fileInput.nativeElement.value = '';
    }

    validateSize(file: File) {
        if (file.size > this.maxFileSize) {
            this.filesWithInvalidFileSize.push(file);
            return false;
        }
        return true;
    }

    validateSameFileName(fileItem: FileItem) {
        if (_.some(this.fileItems, f => fileItem.name === f.name)) {
            this.filesWithSameFileName.push(fileItem.file);
            return false;
        }
        return true;
    }

    validateFormat(file: File) {
        const filePath = `.${_.toLower(_.last(_.split(file.name, '.')))}`;
        const acceptedExtensions = this.acceptedFilesTypes.map(acceptedFileType => this.mimeTypesWithExtensions.get(acceptedFileType)).flat();
        if (filePath && _.includes(acceptedExtensions, filePath)) {
            return true;
        }
        this.filesWithInvalidFileFormat.push(file);
        return false;
    }

    validateCharInFileName(file: File) {
        if (/^[-_.()'! A-Za-zÖÄÅöäå0-9]+$/.test(file.name.normalize('NFC'))) { // works similar as Taskus attachment.name.matches("[-_.()'! A-Za-zÖÄÅöäå0-9]+")
            return true;
        }
        this.filesWithInvalidFileName.push(file);
        return false;
    }

    invalidNumberOfFiles(files: File[]) {
        return this.fileItems.length + files.length > this.maxFiles;
    }

    addExplanation(value: string, item: FileItem): void {
        this.fileItems[_.findIndex(this.fileItems, i => i.file === item.file)].explanation = value;
        this.droppedFiles.emit(this.fileItems);
    }

    invalidFilesConfirm(files: File[]) {
        this.fileUploadInfoModalService.confirm({
            title: this.translateService.instant('FILE_UPLOAD.UNSUCCESSFUL'),
            confirmText: this.translateService.instant('BUTTON.OK'),
            invalidFileSize: this.filesWithInvalidFileSize,
            invalidFileFormat: this.filesWithInvalidFileFormat,
            filesWithSameFileName: this.filesWithSameFileName,
            filesWithInvalidFileName: this.filesWithInvalidFileName,
            acceptedFiles: this.files,
            selectedFiles: files,
            maxFileSizeString: this.maxFileSizeString,
            hideCancel: true,
            editPossible: !this.modalMode,
        }).then(() => {
            // reset invalid file arrays
            this.filesWithInvalidFileFormat = [];
            this.filesWithInvalidFileSize = [];
            this.filesWithSameFileName = [];
            this.filesWithInvalidFileName = [];
            return true;
        }).catch(() => {});
    }

    invalidExplanationTextLength(item: FileItem) {
        return item.explanation && item.explanation.length >= this.explanationMaxLength;
    }

    confirm() {
        this.modal.close(this.fileItems);
    }

    cancel(): void {
        this.modal.dismiss();
    }
}
