import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({ selector: '[sisAutofocus]' })
export class AutofocusDirective implements AfterViewInit {

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

    ngAfterViewInit() {
        // in case of sis-element, focus to element's first child, otherwise focus to element itself
        const isSisElement = this.elementRef.nativeElement?.tagName?.startsWith('SIS-');
        const focusableElement = isSisElement ? this.elementRef.nativeElement.firstChild : this.elementRef.nativeElement;
        if (!focusableElement?.attributes['tabindex']) {
            this.renderer.setAttribute(focusableElement, 'tabindex', '-1');
        }
        focusableElement.focus();
    }
}
