import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { ValidatablePlan } from 'common-typescript';
import { PlanValidationResult } from 'common-typescript/src/plan/validation/planValidationResult';
import {
    AnyCourseUnitRule, AnyModuleRule,
    CompositeRule,
    CourseUnitCountRule, CourseUnitRule,
    CreditsRule,
    EntityWithRule, LocalId,
    ModuleRule,
    Rule,
} from 'common-typescript/types';

import { PlanRuleData } from '../../../service/plan-rule-data.service';
import { PlanData, PlanStateObject } from '../../../service/plan-state.service';

type CombinedRules = CourseUnitCountRule | CompositeRule | CreditsRule
| CourseUnitRule | ModuleRule | AnyCourseUnitRule | AnyModuleRule;
@Component({
    selector: 'sis-plan-structure-select-rule',
    templateUrl: './plan-structure-select-rule.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStructureSelectRuleComponent {
    @Input({ required: true }) parentModule: EntityWithRule;
    @Input({ required: true }) planValidationResult: PlanValidationResult;
    @Input({ required: true }) planStateObject: PlanStateObject;
    @Input({ required: true }) planData: PlanData;
    @Input({ required: true }) planRuleData: PlanRuleData;
    @Input({ required: true }) validatablePlan: ValidatablePlan;
    @Input({ required: true }) headingLevel: number;
    @Input({ required: true }) ruleDepthLevel: number;
    @Input({ transform: (value: number | string): string => value as string, required: true }) groupPrefix: string;
    @Input() selectionDisabled: boolean;
    @Input({ required: true }) set rule(rule: Rule) {
        this._rule = <CombinedRules>rule;
    }

    _rule: CombinedRules;

}
