import { Injectable } from '@angular/core';
import { ModalService } from 'sis-common/modal/modal.service';

import { ReadMoreNgModalComponent, ReadMoreValues } from './read-more-ng-modal/read-more-ng-modal.component';

@Injectable({
    providedIn: 'root',
})
export class ReadMoreModalService {

    constructor(private modalService: ModalService) { }

    open(options: ReadMoreValues) {
        return this.modalService.open(ReadMoreNgModalComponent, options, { closeWithOutsideClick: true, size: 'sm' }).result;
    }
}
