studyRightExtensionRequirementsService.$inject = ["studyRightExtensionService", "studyRightExtensionRequirementsModal"];
import angular from 'angular';
import _ from 'lodash';
angular.module('student.common.service.studyRightExtensionRequirementsService', ['sis-components.service.studyRightExtensionService', 'student.studyRightExtensionRequirementsModal']).factory('studyRightExtensionRequirementsService', studyRightExtensionRequirementsService);

/**
 * @ngInject
 */
function studyRightExtensionRequirementsService(studyRightExtensionService, studyRightExtensionRequirementsModal) {
  const api = {
    checkRequirementsAndProceedToApplication: (userId, studyRightExpirationInfo) => {
      return studyRightExtensionService.checkStudyRightExtensionRequirements(userId, studyRightExpirationInfo).then(studyRightExtensionRequirements => {
        if (!!studyRightExtensionRequirements.primaryPlan && studyRightExtensionRequirements.planState === 'PLANNED' && allStudiesTimedCorrectly(studyRightExtensionRequirements)) {
          return {
            target: 'CREATE_APPLICATION',
            planId: studyRightExtensionRequirements.primaryPlan.id,
            studyRightId: studyRightExpirationInfo.studyRightId
          };
        }
        return studyRightExtensionRequirementsModal.open(studyRightExtensionRequirements).then(planId => {
          if (!planId) {
            return {
              target: 'NONE'
            };
          }
          if (studyRightExtensionRequirements.planState !== 'PLANNED') {
            return {
              target: 'PLAN',
              planId
            };
          }
          if (!allStudiesTimedCorrectly(studyRightExtensionRequirements)) {
            return {
              target: 'TIMING',
              planId
            };
          }
          return {
            target: 'NONE'
          };
        });
      });
    }
  };
  function allStudiesTimedCorrectly(studyRightExtensionRequirements) {
    return allStudiesTimed(studyRightExtensionRequirements) && noStudiesTimedInPast(studyRightExtensionRequirements);
  }
  function allStudiesTimed(studyRightExtensionRequirements) {
    return _.isEmpty(studyRightExtensionRequirements.courseUnitIdsWithNoTiming) && _.isEmpty(studyRightExtensionRequirements.customStudyDraftIdsWithNoTiming);
  }
  function noStudiesTimedInPast(studyRightExtensionRequirements) {
    return _.isEmpty(studyRightExtensionRequirements.courseUnitIdsWithTimingInThePast) && _.isEmpty(studyRightExtensionRequirements.customStudyDraftIdsWithTimingInThePast);
  }
  return api;
}