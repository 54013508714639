import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import {
    BatchOperationRequest,
    BatchOperationRequestWithSalesPeriod,
    BatchOperationResult,
    DocumentState,
    FlowState,
    LocalDateTimeRange,
    OpenUniversityProduct,
    OpenUniversityProductResultItem,
    OpenUniversityProductSearch,
    OtmId,
    SearchResult,
    SearchValidity,
} from 'common-typescript/types';
import * as _ from 'lodash';
import { Observable, switchMap, tap } from 'rxjs';

import {
    dateRangeAllowBlankDateToQueryParam,
    dateTimeRangeToQueryParam,
    searchRequestToQueryParams, simpleObjectToQueryParams,
} from '../search-ng/search-utils';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/kori/api',
        getByCourseUnitId(courseUnitId: string) {
            return `${this.backend}/open-university-products/find-by-course-unit-id/${courseUnitId}`;
        },
        createOpenUniversityProduct() {
            return `${this.backend}/open-university-products/`;
        },
        massSalesPeriodUpdate() {
            return `${this.backend}/open-university-products/batch/sales-period`;
        },
        massCopy() {
            return `${this.backend}/open-university-products/copy`;
        },
        massPublish() {
            return `${this.backend}/open-university-products/publish`;
        },
        massCancel() {
            return `${this.backend}/open-university-products/cancel`;
        },
        massUnpublish() {
            return `${this.backend}/open-university-products/unpublish`;
        },
        massDelete() {
            return `${this.backend}/open-university-products/delete`;
        },
        search() {
            return `${this.backend}/open-university-products/search`;
        },
        searchAuthenticated() {
            return `${this.backend}/authenticated/open-university-products/search`;
        },
    },
};

@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
})
export class OpenUniversityProductEntityService extends EntityService<OpenUniversityProductState> {

    constructor() {
        super(OpenUniversityProductStore, OpenUniversityProductQuery);
    }

    /**
     * Find products by course unit id. Returns all non-deleted products by default.
     *
     * This query will always fetch the products from the backend, and the returned `Observable` will remain open and listen
     * to changes in the store for exactly those products the backend query returns. I.e. if new products matching the filters
     * are added to the store afterwards, the `Observable` returned from this function will not emit those products.
     *
     * @param courseUnitId The id of the course unit whose products to fetch.
     * @param documentState Filter products based on document state. Note that this might require certain privileges.
     * @param flowState Filter products based on flow state.
     * @param salesPeriodValidity Filter products based on their sales period (e.g. only products currently on sale).
     */
    getByCourseUnitId(
        courseUnitId: OtmId,
        documentState: DocumentState | DocumentState[] = ['ACTIVE', 'DRAFT'],
        flowState?: FlowState | FlowState[],
        salesPeriodValidity?: SearchValidity,
    ): Observable<OpenUniversityProduct[]> {
        const params = simpleObjectToQueryParams({ documentState, flowState, salesPeriodValidity });
        return this.getHttp().get<OpenUniversityProduct[]>(CONFIG.ENDPOINTS.getByCourseUnitId(courseUnitId), { params })
            .pipe(
                tap(products => this.store.upsertMany(products)),
                switchMap(products => this.query.selectMany(products.map(product => product.id))),
            );
    }

    createOpenUniversityProduct(product: OpenUniversityProduct): Observable<OpenUniversityProduct> {
        return this.getHttp().post<OpenUniversityProduct>(CONFIG.ENDPOINTS.createOpenUniversityProduct(), product);
    }

    /**
     * @deprecated Use `getByCourseUnitId()` instead
     */
    getActiveByCourseUnitId(courseUnitId: string): Observable<OpenUniversityProduct[]> {
        return this.getByCourseUnitId(courseUnitId, 'ACTIVE');
    }

    massSalesPeriodUpdate(productIds: OtmId[], salesPeriod: LocalDateTimeRange, isDryRun = false): Observable<{ [productId: OtmId]: BatchOperationResult }> {
        const body: BatchOperationRequestWithSalesPeriod = { ids: productIds, salesPeriod, dryRun: isDryRun };
        return this.getHttp().put<{ [productId: OtmId]: BatchOperationResult }>(CONFIG.ENDPOINTS.massSalesPeriodUpdate(), body);
    }

    massCopy(productIds: OtmId[], isDryRun = false): Observable<{ [productId: OtmId]: BatchOperationResult }> {
        const body: BatchOperationRequest = { ids: productIds, dryRun: isDryRun };
        return this.getHttp().post<{ [productId: OtmId]: BatchOperationResult }>(CONFIG.ENDPOINTS.massCopy(), body);
    }

    massPublish(productIds: OtmId[], isDryRun: boolean = false): Observable<{ [key: string]: BatchOperationResult }> {
        const body: BatchOperationRequest = { ids: productIds, dryRun: isDryRun };
        return this.getHttp().put<{ [key: string]: BatchOperationResult }>(CONFIG.ENDPOINTS.massPublish(), body);
    }

    massCancel(productIds: OtmId[], isDryRun: boolean = false): Observable<{ [key: string]: BatchOperationResult }> {
        const body: BatchOperationRequest = { ids: productIds, dryRun: isDryRun };
        return this.getHttp().post<{ [key: string]: BatchOperationResult }>(CONFIG.ENDPOINTS.massCancel(), body);
    }

    massUnpublish(productIds: OtmId[], isDryRun: boolean = false): Observable<{ [key: string]: BatchOperationResult }> {
        const body: BatchOperationRequest = { ids: productIds, dryRun: isDryRun };
        return this.getHttp().put<{ [key: string]: BatchOperationResult }>(CONFIG.ENDPOINTS.massUnpublish(), body);
    }

    massDelete(productIds: OtmId[], isDryRun: boolean = false): Observable<{ [key: string]: BatchOperationResult }> {
        const body: BatchOperationRequest = { ids: productIds, dryRun: isDryRun };
        return this.getHttp().post<{ [key: string]: BatchOperationResult }>(CONFIG.ENDPOINTS.massDelete(), body);
    }

    search(searchParams: Partial<OpenUniversityProductSearch>): Observable<SearchResult<OpenUniversityProductResultItem>> {
        return this.getHttp().get<SearchResult<OpenUniversityProductResultItem>>(CONFIG.ENDPOINTS.search(), { params: this.toQueryParams(searchParams) });
    }

    searchAuthenticated(searchParams: Partial<OpenUniversityProductSearch>): Observable<SearchResult<OpenUniversityProductResultItem>> {
        return this.getHttp().get<SearchResult<OpenUniversityProductResultItem>>(CONFIG.ENDPOINTS.searchAuthenticated(), { params: this.toQueryParams(searchParams) });
    }

    private toQueryParams(searchRequest: Partial<OpenUniversityProductSearch>): { [key: string]: string | string[] } {
        if (_.isEmpty(searchRequest)) {
            return {};
        }

        return _.omitBy(
            {
                ...searchRequestToQueryParams(searchRequest),
                courseUnitId: searchRequest.courseUnitIds,
                completionMethodId: searchRequest.completionMethodIds,
                educationId: searchRequest.educationIds,
                flowState: searchRequest.flowStates,
                salesPeriod: dateTimeRangeToQueryParam(searchRequest.salesPeriod),
                validityPeriod: dateRangeAllowBlankDateToQueryParam(searchRequest.validityPeriod),
                productState: searchRequest.productStates,
                universityOrgId: searchRequest.universityOrgIds,
                orgRootId: searchRequest.organisationRootIds,
                orgId: searchRequest.organisationIds,
            },
            _.isEmpty,
        );
    }
}

type OpenUniversityProductState = EntityState<OpenUniversityProduct>;

@StoreConfig({ name: 'open-university-products' })
class OpenUniversityProductStore extends EntityStore<OpenUniversityProductState> {}

class OpenUniversityProductQuery extends QueryEntity<OpenUniversityProductState> {
    constructor(protected store: OpenUniversityProductStore) {
        super(store);
    }
}
