import angular from 'angular';
import angularTranslate from 'angular-translate';
import _ from 'lodash';
import parse from 'url-parse';
import { authModule } from 'sis-common/auth/auth.module';
import { errorServiceModule } from 'sis-components/error-handler/legacy/errorService';
import { localeServiceModule } from 'sis-common/l10n/localeService';
import { NotificationsService } from '../service/notifications/notifications.service.ts';
import { alertsServiceModule } from '../alerts/alerts.service';
import { IconComponent } from '../icon/icon.component.ts';
import { NavUtilsService } from './nav-utils/nav-utils.service.ts';
import topNavTpl from './topNav.tpl.html';
import { UserSettingsMenuComponent } from './user-settings-menu/user-settings-menu.component.ts';
import { LanguageSelectionMenuComponent } from './language-selection-menu/language-selection-menu.component.ts';
import { LoginProviderMenuComponent } from './login-provider-menu/login-provider-menu.component.ts';
(function () {
  TopNavController.$inject = ["$scope", "$location", "$timeout", "$injector", "AuthService", "universityService", "defaultPromiseHandler", "errorService", "alertsService", "localeService", "$translate", "$http", "$window", "$rootScope", "$state"];
  angular.module('sis-components.topNav', ['ui.router', 'ui.router.upgrade', 'sis-components.universityIFrame', 'sis-components.navMenuIcons', LanguageSelectionMenuComponent.downgrade.moduleName, LoginProviderMenuComponent.downgrade.moduleName, UserSettingsMenuComponent.downgrade.moduleName, alertsServiceModule, angularTranslate, authModule, errorServiceModule, localeServiceModule, IconComponent.downgrade.moduleName, NavUtilsService.downgrade.moduleName, NotificationsService.downgrade.moduleName]).component('topNav', {
    bindings: {
      links: '<',
      currentApp: '@'
    },
    template: topNavTpl,
    controller: TopNavController
  });
  function TopNavController(
  // NOSONAR
  $scope, $location, $timeout, $injector, AuthService, universityService,
  // NOSONAR
  defaultPromiseHandler, errorService, alertsService, localeService, $translate, $http, $window, $rootScope, $state) {
    const $ctrl = this;
    $ctrl.frontPageUrl = false;
    $ctrl.activeLinkIndex = null;
    $ctrl.messageNotifications = 0;

    // Create eventCallbacks object with event names as keys, connect to the SSE-notification service for
    // message counts. This does nothing if SSE is disabled in universityConfig.
    $ctrl.$onInit = function () {
      AuthService.initializeAppAuth().then(authenticated => {
        $ctrl.authenticated = authenticated;
        $ctrl.showMessageIcon = authenticated && _.includes(['staff', 'student', 'teacher', 'tutor'], $ctrl.currentApp);
        if (authenticated && $ctrl.currentApp !== 'admin') {
          $ctrl.messageCountObservable = $injector.get('notificationsService').getEventsObservable('messageCount').subscribe(value => updateMessageCount(value));
          $ctrl.pdfResponseEventObservable = $injector.get('notificationsService').getEventsObservable('pdfResponseEvent').subscribe(value => pdfResponseNotification(value));
          $ctrl.pdfResponseErrorEventObservable = $injector.get('notificationsService').getEventsObservable('pdfResponseErrorEvent').subscribe(value => pdfResponseErrorNotification(value));
        }
        $ctrl.homeUrl = $injector.get('navUtilsService').getHomeUrl();
      });
      $rootScope.scrolledDown = false;
      angular.element($window).bind('scroll', function () {
        let scrollStatusChanged = false;
        if (this.pageYOffset >= 147) {
          if ($rootScope.scrolledDown !== true) {
            scrollStatusChanged = true;
          }
          $rootScope.scrolledDown = true;
        } else if (this.pageYOffset < 48) {
          if ($rootScope.scrolledDown !== false) {
            scrollStatusChanged = true;
          }
          $rootScope.scrolledDown = false;
        }
        if (scrollStatusChanged) {
          $rootScope.$apply();
        }
      });
    };
    $ctrl.$onChanges = function () {
      $ctrl.frontPageUrl = false;
      if ($state.current.url === '/frontpage') {
        $ctrl.frontPageUrl = true;
        $ctrl.activeLinkIndex = undefined;
      } else if ($state.current.url.includes('/structure')) {
        $ctrl.activeLinkIndex = 0;
      }
      /* Loop through navigation links and check if they match with current page */
      if ($ctrl.links && $state.$current.name) {
        $ctrl.links.forEach((item, index) => {
          if ($state.$current.name.includes(item.toState)) {
            $ctrl.activeLinkIndex = index;
          }
        });
      }
    };
    $ctrl.$onDestroy = function () {
      if ($ctrl.messageCountObservable) $ctrl.messageCountObservable.unsubscribe();
      if ($ctrl.pdfResponseEventObservable) $ctrl.messageCountObservable.unsubscribe();
      if ($ctrl.pdfResponseErrorEventObservable) $ctrl.messageCountObservable.unsubscribe();
    };
    $ctrl.handleRightMenuIconClick = function () {
      $ctrl.closeHamburgerMenu();
      $ctrl.activeLinkIndex = null;
      $ctrl.frontPageUrl = false;
    };
    function updateMessageCount(messageCount) {
      $timeout(() => {
        $ctrl.messageNotifications = messageCount;
      });
    }
    function pdfResponseNotification(painoResponseEvent) {
      const translationId = painoResponseEvent.eventType === 'CSV_RESPONSE' ? 'CSV_EVENTS.READY.ALERT' : 'PDF_EVENTS.READY.ALERT';
      alertsService.success($translate.instant(translationId), undefined, _.get(painoResponseEvent, 'ticketId'), $ctrl.openFromTasku.bind($ctrl, _.get(painoResponseEvent, 'groupId')));
    }
    $ctrl.openFromTasku = function (groupId) {
      $http.get(`/tasku/api/stored-document-groups/${groupId}/presigned-download`).then(response => {
        const taskuUrl = _.get(response.data, 'urlPath', null);
        $window.location = `/tasku${taskuUrl}`;
      });
    };
    function pdfResponseErrorNotification(painoResponseErrorEvent) {
      const translationId = painoResponseErrorEvent.eventType === 'CSV_ERROR' ? 'CSV_EVENTS.ERROR.STATUSTEXT' : 'PDF_EVENTS.ERROR.STATUSTEXT';
      alertsService.dismissAlertIfExists(_.get(painoResponseErrorEvent, 'ticketId'));
      $timeout(() => {
        errorService.showSystemError({
          message: _.get(painoResponseErrorEvent, 'errorMessage'),
          statusText: $translate.instant(translationId)
        });
      });
    }
    $ctrl.overrideNavigation = function (event) {
      event.preventDefault();
      let path = '';
      switch ($injector.get('navUtilsService').getRouteRoleContextPath()) {
        case '/teacher/role/teacher':
        case '/tutor/role/teacher':
        case '/staff/studies/teacher':
          path = '/teacher/role/teacher/frontpage';
          break;
        case '/tutor/role/staff':
        case '/staff/studies/staff':
        case '/teacher/role/staff':
          path = '/staff/studies/staff/frontpage';
          break;
        default:
          path = $ctrl.homeUrl;
          break;
      }
      $injector.get('navUtilsService').navigateToHref(path);
    };
    $ctrl.toggleHamburgerMenu = () => $scope.menuOpen = !$scope.menuOpen;
    $ctrl.closeHamburgerMenu = () => $scope.menuOpen = false;
    $rootScope.$on('$locationChangeStart', () => {
      $ctrl.closeHamburgerMenu();
    });
    $ctrl.makeRelativePath = toUrl => toUrl ? parse(toUrl).pathname : undefined;
    $ctrl.isLinkToAnotherApplication = function (navigationLink) {
      return !$state.get(navigationLink.toState);
    };
  }
})();