import { InjectionToken, Provider } from '@angular/core';
import * as angular from 'angular';

import { SelectedUniversityStorage, UniversityService } from './university/university.service';

/* **************************************************************************************
 * NOTE: Don't add any new upgrade configs here without a very good reason. If you need *
 * functionality from a legacy AngularJS service, your first choice should always be to *
 * move/reimplement that functionality to a new/existing Angular service.               *
 ************************************************************************************** */

const angularJSInjectorName = '$injector';

/**
 * @deprecated Use `AuthService` instead
 */
export const AUTH_SERVICE = new InjectionToken<any>('AuthService');
export const getAuthService = (injector: angular.auto.IInjectorService) => injector.get('AuthService');
export const authServiceProvider: Provider = {
    provide: AUTH_SERVICE,
    useFactory: getAuthService,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use `CourseUnitEntityService` instead
 */
export const COURSE_UNIT_SERVICE = new InjectionToken<any>('commonCourseUnitService');
export const courseUnitServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('commonCourseUnitService');
export const courseUnitServiceProvider: Provider = {
    provide: COURSE_UNIT_SERVICE,
    useFactory: courseUnitServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const LOG_SERVICE = new InjectionToken<any>('logService');
export const logServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('logService');
export const logServiceProvider: Provider = {
    provide: LOG_SERVICE,
    useFactory: logServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use `TokenStorageService` instead
 */
export const TOKEN_STORAGE = new InjectionToken<any>('TokenStorage');
export const getTokenStorage = (injector: angular.auto.IInjectorService) => injector.get('TokenStorage');
export const tokenStorageProvider: Provider = {
    provide: TOKEN_STORAGE,
    useFactory: getTokenStorage,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const CONSTRAINT_SERVICE = new InjectionToken<any>('constraintService');
export const constraintServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('constraintService');
export const constraintServiceProvider: Provider = {
    provide: CONSTRAINT_SERVICE,
    useFactory: constraintServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use AppErrorHandler instead
 */
export const DEFAULT_PROMISE_HANDLER = new InjectionToken<any>('defaultPromiseHandler');
export const defaultPromiseHandlerFactory = (injector: angular.auto.IInjectorService) => injector.get('defaultPromiseHandler');
export const defaultPromiseHandlerProvider: Provider = {
    provide: DEFAULT_PROMISE_HANDLER,
    useFactory: defaultPromiseHandlerFactory,
    deps: [angularJSInjectorName],
};

export const selectedUniversityStorageFactory = (injector: angular.auto.IInjectorService) => injector.get('selectedUniversityStorage');
export const selectedUniversityStorageProvider: Provider = {
    provide: SelectedUniversityStorage,
    useFactory: selectedUniversityStorageFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const TRANSLATION_LOADER = new InjectionToken<Function>('appTranslationsAsync');
export const translationLoaderFactory = (injector: angular.auto.IInjectorService) => injector.get('appTranslationsAsync');
export const translationLoaderProvider: Provider = {
    provide: TRANSLATION_LOADER,
    useFactory: translationLoaderFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const universityServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('universityService');
export const universityServiceProvider: Provider = {
    provide: UniversityService,
    useFactory: universityServiceFactory,
    deps: [angularJSInjectorName],
};
