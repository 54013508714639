import angular from 'angular';
import _ from 'lodash';
import { localizedUrnNameDisplayModule } from 'sis-components/codes/localizedUrnNameDisplay.component';
import { ContactInfoForGraduationComponent } from 'sis-components/person/contact-info-for-graduation/contact-info-for-graduation.component.ts';
import { PersonInfoForGraduationComponent } from 'sis-components/person/person-info-for-graduation/person-info-for-graduation.component.ts';
import { DegreeProgrammeAttainmentApplicationAnswersComponent } from 'sis-components/applications/degree-programme-attainment-application/degree-programme-attainment-application-answers/degree-programme-attainment-application-answers.component.ts';
import degreeProgrammeAttainmentApplicationTpl from './degreeProgrammeAttainmentApplication.tpl.html';
(function () {
  degreeProgrammeAttainmentApplicationController.$inject = ["$state", "$translate", "alertsService", "cancelApplicationModalService", "defaultPromiseHandler", "studentProfileService", "STUDENT_APPLICATION_STATE", "attainmentService"];
  angular.module('student.profile.applications.degreeProgrammeAttainmentApplication', ['ui.router', 'ui.router.upgrade', 'sis-common.errorhandler.errorService', 'sis-components.alertsService', ContactInfoForGraduationComponent.downgrade.moduleName, DegreeProgrammeAttainmentApplicationAnswersComponent.downgrade.moduleName, PersonInfoForGraduationComponent.downgrade.moduleName, 'sis-components.model.studentApplication', 'student.profile', 'student.profile.applications.cancelApplicationModal', 'student.common.service.attainmentService', localizedUrnNameDisplayModule]).component('degreeProgrammeAttainmentApplication', {
    template: degreeProgrammeAttainmentApplicationTpl,
    bindings: {
      application: '<',
      degreeProgramme: '<'
    },
    controller: degreeProgrammeAttainmentApplicationController
  });
  function degreeProgrammeAttainmentApplicationController($state, $translate, alertsService, cancelApplicationModalService, defaultPromiseHandler, studentProfileService, STUDENT_APPLICATION_STATE, attainmentService) {
    const $ctrl = this;
    $ctrl.$onInit = function () {
      if ($ctrl.application.state === STUDENT_APPLICATION_STATE.ACCEPTANCE_REVOKED) {
        attainmentService.getAttainmentsForStudent().then(attainments => {
          $ctrl.attainment = _.find(attainments, {
            studentApplicationId: $ctrl.application.id,
            misregistration: true
          });
        }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
      }
      studentProfileService.profile().then(profile => {
        $ctrl.profile = profile;
      });
      $ctrl.showAlumniAssociationInfo = !_.isNil($ctrl.application.joinsAlumniAssociation);
    };
    $ctrl.deliveryByPickUp = function () {
      return $ctrl.application.degreeDeliveryMethod === 'PICK_UP';
    };
    $ctrl.deliveryByMail = function () {
      return $ctrl.application.degreeDeliveryMethod === 'MAIL';
    };
    $ctrl.digitalDegreeCertificatePreferred = function () {
      return $ctrl.application.degreeDeliveryMethod === 'DIGITAL_CERTIFICATE';
    };
    $ctrl.isDeliveryAddressInFinland = function () {
      return _.get($ctrl.application, 'deliveryAddress.type') === 'FinnishAddress';
    };
    $ctrl.applicationRequestedOrInHandling = function () {
      return _.includes([STUDENT_APPLICATION_STATE.REQUESTED, STUDENT_APPLICATION_STATE.IN_HANDLING], _.get($ctrl.application, 'state'));
    };
    $ctrl.applicationCancelled = function () {
      return _.get($ctrl.application, 'state') === STUDENT_APPLICATION_STATE.CANCELLED;
    };
    $ctrl.applicationRejected = function () {
      return _.get($ctrl.application, 'state') === STUDENT_APPLICATION_STATE.REJECTED;
    };
    $ctrl.applicationAcceptanceRevoked = function () {
      return _.get($ctrl.application, 'state') === STUDENT_APPLICATION_STATE.ACCEPTANCE_REVOKED;
    };
    $ctrl.getResolutionRationale = function () {
      return _.get($ctrl.application, 'decision.resolutionRationale');
    };
    $ctrl.getAttainmentMisregistrationRationale = function () {
      return _.get($ctrl.attainment, 'misregistrationRationale');
    };
    $ctrl.cancel = function () {
      cancelApplicationModalService.open($ctrl.application).then(() => {
        alertsService.success($translate.instant('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CANCELLED'));
        $state.go($state.current, {}, {
          reload: true
        });
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
  }
})();