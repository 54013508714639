import { timelineCustomCourseUnitAttainmentModule } from './customCourseUnitAttainment/timelineCustomCourseUnitAttainment.component';
import { timelineCustomStudyDraftModule } from './customStudyDraft/timelineCustomStudyDraft.component';
import { TimelineNoteComponent } from './note/timeline-note/timeline-note.component.ts';
import timelineTpl from './timeline.tpl.html';
'use strict';
(function () {
  TimeLineController.$inject = ["$log", "$element", "$timeout", "$window", "$scope", "timingViewStateService", "uiStateStore", "timingService", "timelineConfig", "studyRightIndicatorBuilder"];
  angular.module('student.timing.timeline', ['student.common.utils.uiStateStore', 'student.timing.attainedCredits', 'student.timing.timelineCourseUnit', 'pascalprecht.translate', 'sis-common.l10n', 'student.timing.timingService', 'student.timing.plannedTeachingPeriod', 'student.common.model.studyPeriodLocator', 'student.common.service.studyPeriodService', 'student.timing.studyRightIndicator.studyRightIndicatorBuilder', 'student.timing.studyRightIndicator.studyRightIndicator', 'student.timing.constant', TimelineNoteComponent.downgrade.moduleName, 'student.timing.timingViewStateService', timelineCustomCourseUnitAttainmentModule, timelineCustomStudyDraftModule]).component('timeline', {
    template: timelineTpl,
    bindings: {
      validatablePlan: '<',
      validatablePlanConstructor: '<',
      selectedPlan: '<',
      state: '<',
      studyRights: '<'
    },
    controller: TimeLineController
  });
  function TimeLineController(
  // NOSONAR
  $log, $element, $timeout, $window, $scope, timingViewStateService, uiStateStore, timingService, timelineConfig, studyRightIndicatorBuilder) {
    var $ctrl = this;
    $ctrl.$scope = $scope;
    var timeLineDiv;
    var scrollPositions = {};
    function cutPeriodsByStudyRight(studyYears) {
      if (_.isEmpty($ctrl.studyRights)) {
        $log.debug('cutPeriodsByStudyRight, no study rights found');
        return studyYears;
      }
      var lastStudyRightDate = _.max(_.map($ctrl.studyRights, function (studyRight) {
        var endDate = _.get(studyRight, 'valid.endDate', null);
        return _.isNil(endDate) ? -1 : new Date(endDate);
      }));
      if (lastStudyRightDate !== -1) {
        var lastDate = moment(lastStudyRightDate).add(timelineConfig.studyYearsVisibleAfterStudyRight, 'year');
        studyYears.studyYears = _.filter(studyYears.studyYears, function (year) {
          return moment(year.valid.startDate).isBefore(lastDate, 'day');
        });
        studyYears.terms = _.filter(studyYears.terms, function (term) {
          return moment(term.valid.startDate).isBefore(lastDate, 'day');
        });
        studyYears.periods = _.filter(studyYears.periods, function (p) {
          return moment(p.valid.startDate).isBefore(lastDate, 'day');
        });
      }
      return studyYears;
    }
    function calculatePeriodSizesAndVisibility() {
      return timingService.init($ctrl.selectedPlan, $ctrl.validatablePlan, $ctrl.studyRights).then(() => {
        startTimingCourseUnit();
        const years = [];
        const terms = [];
        $ctrl.periods = [];
        _.forEach(timingService.getStudyYears(), studyYear => {
          let firstTerm = true;
          let firstPeriod = true;
          _.forEach(studyYear.$studyTerms, studyTerm => {
            studyTerm.firstOfYear = firstTerm;
            firstTerm = false;
            let firstPeriodOfTerm = true;
            _.forEach(studyTerm.$studyPeriods, studyPeriod => {
              studyPeriod.hidden = !$ctrl.state.showAllPeriods && !showStudyPeriod(studyPeriod);
              studyPeriod.firstOfYear = !studyPeriod.hidden && firstPeriod;
              studyPeriod.firstOfTerm = !studyPeriod.hidden && firstPeriodOfTerm;
              if (!studyPeriod.hidden) {
                $ctrl.periods.push(studyPeriod);
                firstPeriod = false;
                firstPeriodOfTerm = false;
              }
            });
            studyTerm.size = termSize(studyTerm);
            terms.push(studyTerm);
          });
          studyYear.size = yearSize(studyYear);
          years.push(studyYear);
        });
        calculatePeriodWidths($ctrl.periods);
        const calculatedTimeLineData = cutPeriodsByStudyRight({
          studyYears: years,
          terms,
          periods: $ctrl.periods
        });
        $ctrl.studyYears = calculatedTimeLineData.studyYears;
        $ctrl.terms = calculatedTimeLineData.terms;
        $ctrl.periods = calculatedTimeLineData.periods;
        studyRightIndicatorBuilder($ctrl.periods, $ctrl.studyRights);
      });
    }
    function startTimingCourseUnit() {
      if ($ctrl.state.activeCourseUnit) {
        timingService.startTimingCourseUnit($ctrl.state.activeCourseUnit, undefined, $ctrl.selectedPlan, $ctrl.validatablePlan);
      }
    }
    function showStudyPeriod(studyPeriod) {
      var nonGapRows = _.filter(studyPeriod.rows, function (row) {
        return row.type.indexOf('-gap') < 0;
      });
      return studyPeriod.visibleByDefault || nonGapRows.length > 0;
    }
    function termSize(term) {
      return _.reduce(_.reject(term.$studyPeriods, 'hidden'), function (memo, period) {
        return memo + period.size;
      }, 0);
    }
    function yearSize(year) {
      return _.reduce(year.$studyTerms, function (memo, term) {
        return memo + term.size;
      }, 0);
    }
    function handleResize(periods) {
      calculatePeriodWidths(periods);
      $scope.$digest();
    }
    function calculatePeriodWidths(periods) {
      _.forEach(periods, function (period) {
        period.width = period.size * scaleWidth() / period.$year.size;
      });
    }
    function scaleWidth() {
      // scaleWidth is how many pixels one year is wide
      return $window.innerWidth / timelineConfig.SCALES[$ctrl.state.zoomLevel];
    }

    // Scrolls the timeline to the given anchor and returns true, or if anchor not found, returns false.
    function scrollTo(anchor) {
      var element = document.getElementById(anchor);
      if (element) {
        timeLineDiv.scrollLeft(element.offsetLeft);
        return true;
      }
      return false;
    }
    function findCurrentStudyYearFirstPeriodLocator() {
      var now = moment();
      var yearAgo = moment().subtract(1, 'years');
      var studyYear = _.find($ctrl.studyYears, function (sy) {
        var sd = moment(sy.startDate);
        return sd < now && sd > yearAgo;
      });
      if (studyYear) {
        return _.reject(studyYear.$studyTerms[0].$studyPeriods, 'hidden')[0].locator;
      }
    }
    function scrollToCurrentStudyYearStart() {
      var locator = findCurrentStudyYearFirstPeriodLocator();
      $timeout(function () {
        scrollTo(locator);
      }, 0);
    }
    function scrollToPosition(planId) {
      if (_.has(scrollPositions, planId) && !_.isNil(timeLineDiv)) {
        $timeout(function () {
          timeLineDiv.scrollLeft(scrollPositions[planId] * timeLineDiv[0].scrollWidth);
        }, 0);
      } else {
        scrollToCurrentStudyYearStart();
      }
    }
    function saveScrollPosition(e) {
      var positions = uiStateStore.readField(timelineConfig.store.PREFIX, timelineConfig.store.SCROLL_KEY, {});
      positions[$ctrl.selectedPlan.id] = e.currentTarget.scrollLeft / e.currentTarget.scrollWidth;
      uiStateStore.storeField(timelineConfig.store.PREFIX, timelineConfig.store.SCROLL_KEY, positions);
    }
    function findCourseUnitFromPeriods(courseUnit, periods) {
      function findFromRow(rows) {
        return _.some(rows, function (row) {
          return row.item.id === courseUnit.id;
        });
      }
      const foundCourseUnit = _.some(periods, period => findFromRow(_.filter(period.rows, {
        type: timelineConfig.rowType.COURSE_UNIT
      })));
      return foundCourseUnit === false;
    }
    function findCustomStudyDraftFromPeriods(customStudyDraft, periods) {
      function findFromRow(rows) {
        return _.some(rows, row => row.item.id === customStudyDraft.id);
      }
      const foundCustomStudyDraft = _.some(periods, period => findFromRow(_.filter(period.rows, {
        type: timelineConfig.rowType.CUSTOM_STUDY_DRAFT
      })));
      return foundCustomStudyDraft === false;
    }
    function updateZoom() {
      calculatePeriodWidths($ctrl.periods);
      scrollToPosition($ctrl.selectedPlan.id);
    }
    function showAllPeriodsChanged() {
      calculatePeriodSizesAndVisibility();
    }

    // We could support adding the course unit to another period instead of moving it.
    function finishTimingCourseUnit(courseUnit, periodLocators) {
      return timingService.timeCourseUnitToPeriods(courseUnit, periodLocators, $ctrl.selectedPlan, $ctrl.validatablePlanConstructor).then(function () {
        timingViewStateService.onEndTiming($ctrl.state);
      });
    }
    function finishTimingCustomStudyDraft(customStudyDraft, periodLocators) {
      return timingService.timeCustomStudyDraftToPeriods(customStudyDraft, periodLocators, $ctrl.selectedPlan, $ctrl.validatablePlanConstructor).then(() => {
        timingViewStateService.onEndTiming($ctrl.state);
      });
    }
    function finishMovingCourseUnitTiming(courseUnit, periodLocators) {
      return timingService.moveCourseUnitTimingToPeriods(courseUnit, periodLocators, $ctrl.selectedPlan, $ctrl.validatablePlanConstructor).then(function () {
        timingViewStateService.onEndTiming($ctrl.state);
      });
    }
    function finishMovingCustomStudyDraftTiming(customStudyDraft, periodLocators) {
      return timingService.moveCustomStudyDraftTimingToPeriods(customStudyDraft, periodLocators, $ctrl.selectedPlan, $ctrl.validatablePlanConstructor).then(function () {
        timingViewStateService.onEndTiming($ctrl.state);
      });
    }
    function moveNoteToPeriod(period) {
      var note = $ctrl.state.activeNote;
      return timingService.moveNoteToPeriod(note, period, $ctrl.selectedPlan, $ctrl.validatablePlanConstructor).then(function () {
        timingViewStateService.onEndTiming($ctrl.state);
      });
    }
    function toggleNotePeriod(period) {
      var note = $ctrl.state.activeNote;
      var update;
      if (_.includes(note.notePeriods, period.locator)) {
        update = timingService.removeNoteFromPeriod(note, period, $ctrl.selectedPlan, $ctrl.validatablePlanConstructor);
      } else {
        update = timingService.addNoteToPeriod(note, period, $ctrl.selectedPlan, $ctrl.validatablePlanConstructor);
      }
      return update.then(function () {
        timingViewStateService.onEndTiming($ctrl.state);
      });
    }
    $ctrl.periods = [];
    $ctrl.ignoreClickPeriod = false;
    $ctrl.$onInit = function () {
      calculatePeriodSizesAndVisibility().then(() => {
        timeLineDiv = $element.find('.scroll-horizontal');
        scrollPositions = uiStateStore.readField(timelineConfig.store.PREFIX, timelineConfig.store.SCROLL_KEY);
        angular.element($window).bind('resize', _.debounce(() => handleResize($ctrl.periods), 250));
        $timeout(() => {
          timeLineDiv.bind('scroll', _.debounce(saveScrollPosition, 250));
          scrollToPosition($ctrl.selectedPlan.id);
        });
      });
    };
    $scope.$watch('$ctrl.state.zoomLevel', updateZoom);
    $scope.$watch('$ctrl.state.showAllPeriods', showAllPeriodsChanged);
    $scope.$watch('$ctrl.state.type', function (newType, oldType) {
      if (newType === timelineConfig.viewStates.VIEW && (oldType === timelineConfig.viewStates.TIME_COURSE_UNIT || oldType === timelineConfig.viewStates.MOVE_COURSE_UNIT_TIMING)) {
        timingService.cancelTimingOfCourseUnit($ctrl.selectedPlan, $ctrl.validatablePlan);
      }
      if (newType === timelineConfig.viewStates.VIEW && (oldType === timelineConfig.viewStates.TIME_CUSTOM_STUDY_DRAFT || oldType === timelineConfig.viewStates.MOVE_CUSTOM_STUDY_DRAFT_TIMING)) {
        timingService.cancelTimingOfCourseUnit($ctrl.selectedPlan, $ctrl.validatablePlan);
      }
      if (newType !== oldType) {
        calculatePeriodSizesAndVisibility();
      }
    });
    $scope.$watch('$ctrl.state.zoomLevel', function () {
      $ctrl.zoomIsTiny = $ctrl.state.zoomLevel === 0 || $ctrl.state.zoomLevel === 1;
      $ctrl.zoomIsSmall = $ctrl.state.zoomLevel === 2 || $ctrl.state.zoomLevel === 3;
    });
    $ctrl.$onDestroy = function () {
      angular.element($window).unbind('resize');
      timeLineDiv.unbind('scroll');
    };
    $ctrl.hasTimingOfNote = function () {
      return $ctrl.state.type === timelineConfig.viewStates.ADD_NOTE || $ctrl.state.type === timelineConfig.viewStates.EDIT_NOTE || $ctrl.state.type === timelineConfig.viewStates.MOVE_NOTE_TIMING;
    };
    $ctrl.hasTimingOfCourseUnit = function () {
      return $ctrl.state.type === timelineConfig.viewStates.TIME_COURSE_UNIT || $ctrl.state.type === timelineConfig.viewStates.MOVE_COURSE_UNIT_TIMING;
    };
    $ctrl.hasTimingOfCustomStudyDraft = () => {
      return $ctrl.state.type === timelineConfig.viewStates.TIME_CUSTOM_STUDY_DRAFT || $ctrl.state.type === timelineConfig.viewStates.MOVE_CUSTOM_STUDY_DRAFT_TIMING;
    };
    $ctrl.clickPeriod = function (period) {
      if ($ctrl.ignoreClickPeriod) {
        return;
      }
      if ($ctrl.state.type === timelineConfig.viewStates.TIME_COURSE_UNIT) {
        finishTimingCourseUnit($ctrl.state.activeCourseUnit, [period.locator]);
      } else if ($ctrl.state.type === timelineConfig.viewStates.MOVE_COURSE_UNIT_TIMING) {
        finishMovingCourseUnitTiming($ctrl.state.activeCourseUnit, [period.locator]);
      } else if ($ctrl.state.type === timelineConfig.viewStates.TIME_CUSTOM_STUDY_DRAFT) {
        finishTimingCustomStudyDraft($ctrl.state.activeCustomStudyDraft, [period.locator]);
      } else if ($ctrl.state.type === timelineConfig.viewStates.MOVE_CUSTOM_STUDY_DRAFT_TIMING) {
        finishMovingCustomStudyDraftTiming($ctrl.state.activeCustomStudyDraft, [period.locator]);
      } else if ($ctrl.state.type === timelineConfig.viewStates.ADD_NOTE) {
        $ctrl.createTimeLineNote(period);
      } else if ($ctrl.state.type === timelineConfig.viewStates.MOVE_NOTE_TIMING) {
        moveNoteToPeriod(period);
      } else if ($ctrl.state.type === timelineConfig.viewStates.EDIT_NOTE) {
        toggleNotePeriod(period);
      }
    };
    $ctrl.clickPlannedTeaching = function (periodLocators) {
      if ($ctrl.state.type === timelineConfig.viewStates.TIME_COURSE_UNIT) {
        finishTimingCourseUnit($ctrl.state.activeCourseUnit, periodLocators);
      } else if ($ctrl.state.type === timelineConfig.viewStates.MOVE_COURSE_UNIT_TIMING) {
        finishMovingCourseUnitTiming($ctrl.state.activeCourseUnit, periodLocators);
      }
    };

    /**
     * Find if courseUnit is last ACTIVE occurrence in periods table
     */
    $ctrl.isLastActive = function (periodIndex, courseUnit) {
      if (!$ctrl.isActive(timelineConfig.rowType.COURSE_UNIT, courseUnit)) {
        return false;
      }
      return $ctrl.isLast(periodIndex, courseUnit);
    };

    /**
     * Find if customStudyDraft is last ACTIVE occurrence in periods table
     */
    $ctrl.isLastActiveCustomStudyDraft = (periodIndex, customStudyDraft) => {
      if (!$ctrl.isActive(timelineConfig.rowType.CUSTOM_STUDY_DRAFT, customStudyDraft)) {
        return false;
      }
      return $ctrl.isLastCustomStudyDraftPeriod(periodIndex, customStudyDraft);
    };

    /**
     * Find if courseUnit is first occurrence in periods table
     */
    $ctrl.isFirst = function (periodIndex, courseUnit) {
      var periods = $ctrl.periods.slice(0, periodIndex);
      return findCourseUnitFromPeriods(courseUnit, periods);
    };

    /**
     * Find if customStudyDraft is first occurrence in periods table
     */
    $ctrl.isFirstCustomStudyDraftPeriod = function (periodIndex, customStudyDraft) {
      var periods = $ctrl.periods.slice(0, periodIndex);
      return findCustomStudyDraftFromPeriods(customStudyDraft, periods);
    };

    /**
     * Find if courseUnit is last occurrence in periods table
     */
    $ctrl.isLast = function (periodIndex, courseUnit) {
      var periods = $ctrl.periods.slice(periodIndex + 1);
      return findCourseUnitFromPeriods(courseUnit, periods);
    };

    /**
     * Find if customStudyDraft is last occurrence in periods table
     */
    $ctrl.isLastCustomStudyDraftPeriod = function (periodIndex, customStudyDraft) {
      var periods = $ctrl.periods.slice(periodIndex + 1);
      return findCustomStudyDraftFromPeriods(customStudyDraft, periods);
    };

    /**
     * Check if plannedTeaching is first element in timeline
     */
    $ctrl.isFirstPlanned = function (periodIndex, id) {
      var periods = $ctrl.periods.slice(0, periodIndex);
      var foundPlanning = _.some(periods, function (period) {
        return findPlanningIdFromRow(_.filter(period.rows, {
          type: timelineConfig.rowType.PLANNED_TEACHING
        }));
      });
      return foundPlanning === false;
      function findPlanningIdFromRow(rows) {
        return _.some(rows, function (row) {
          return row.id === id;
        });
      }
    };
    $ctrl.isAttained = function (courseUnit) {
      return $ctrl.validatablePlan.isCourseUnitAttained(courseUnit.id);
    };

    /**
     * Check if plannedTeaching is last element in timeline
     */
    $ctrl.isLastPlanned = function (periodIndex, id) {
      var periods = $ctrl.periods.slice(periodIndex + 1);
      var foundPlanning = _.some(periods, function (period) {
        return findPlanningIdFromRow(_.filter(period.rows, {
          type: timelineConfig.rowType.PLANNED_TEACHING
        }));
      });
      return foundPlanning === false;
      function findPlanningIdFromRow(rows) {
        return _.some(rows, function (row) {
          return row.id === id;
        });
      }
    };
    $ctrl.isAlreadyPlanned = function (period) {
      return _.some(_.filter(period.rows, {
        type: timelineConfig.rowType.COURSE_UNIT
      }), function (row) {
        return _.get(row, 'item.id', false) === _.get(timingService, 'untimedCourseUnit.id', null);
      });
    };
    $ctrl.isActive = function (type, item) {
      if (type === timelineConfig.rowType.COURSE_UNIT) {
        return _.get($ctrl.state, 'activeCourseUnit.id', -1) === item.id;
      } else if (type === timelineConfig.rowType.NOTE) {
        return _.get($ctrl, 'state.activeNote.localId', -1) === item.localId;
      } else if (type === timelineConfig.rowType.CUSTOM_STUDY_DRAFT) {
        return _.get($ctrl, 'state.activeCustomStudyDraft.id', -1) === item.id;
      }
    };
    $ctrl.someOtherIsActive = function (type, item) {
      if (type === timelineConfig.rowType.NOTE) {
        return !_.isUndefined($ctrl.state.activeCourseUnit) || !_.isUndefined($ctrl.state.activeCustomStudyDraft) || _.get($ctrl.state, 'activeNote.localId', item.localId) !== item.localId;
      } else if (type === timelineConfig.rowType.COURSE_UNIT) {
        return !_.isUndefined($ctrl.state.activeNote) || !_.isUndefined($ctrl.state.activeCustomStudydraft) || _.get($ctrl.state, 'activeCourseUnit.id', item.id) !== item.id;
      } else if (type === timelineConfig.rowType.CUSTOM_STUDY_DRAFT) {
        return !_.isUndefined($ctrl.state.activeCourseUnit) || !_.isUndefined($ctrl.state.activeNote) || _.get($ctrl.state, 'activeCustomStudyDraft.id', item.id) !== item.id;
      }
    };
    $ctrl.startTimingCourseUnit = function (courseUnit, periodLocator) {
      timingService.cancelTimingOfCourseUnit($ctrl.selectedPlan, $ctrl.validatablePlan);
      timingService.startTimingCourseUnit(courseUnit, periodLocator, $ctrl.selectedPlan, $ctrl.validatablePlan);
      timingViewStateService.startTimingForCourseUnit($ctrl.state, courseUnit);
    };
    $ctrl.startTimingCustomStudyDraft = function (customStudyDraft, periodLocator) {
      timingService.cancelTimingOfCourseUnit($ctrl.selectedPlan, $ctrl.validatablePlan);
      timingService.startTimingCustomStudyDraft(customStudyDraft, periodLocator, $ctrl.selectedPlan, $ctrl.validatablePlan);
      timingViewStateService.startTimingForCustomStudyDraft($ctrl.state, customStudyDraft);
    };
    $ctrl.startMovingCourseUnitTiming = function (courseUnit, periodLocator) {
      timingService.cancelTimingOfCourseUnit($ctrl.selectedPlan, $ctrl.validatablePlan);
      timingService.startTimingCourseUnit(courseUnit, periodLocator, $ctrl.selectedPlan, $ctrl.validatablePlan);
      timingViewStateService.startMoveCourseUnitTiming($ctrl.state, courseUnit);
    };
    $ctrl.startMovingCustomStudyDraftTiming = (customStudyDraft, periodLocator) => {
      timingService.cancelTimingOfCourseUnit($ctrl.selectedPlan, $ctrl.validatablePlan);
      timingService.startTimingCustomStudyDraft(customStudyDraft, periodLocator, $ctrl.selectedPlan, $ctrl.validatablePlan);
      timingViewStateService.startMoveCustomStudyDraftTiming($ctrl.state, customStudyDraft);
    };
    $ctrl.deActivate = function () {
      timingService.cancelTimingOfCourseUnit($ctrl.selectedPlan, $ctrl.validatablePlan);
      timingViewStateService.onEndTiming($ctrl.state);
    };
    $ctrl.getClasses = function (courseUnit) {
      return $ctrl.isActive(timelineConfig.rowType.COURSE_UNIT, courseUnit) ? "active" : $ctrl.someOtherIsActive(timelineConfig.rowType.COURSE_UNIT, courseUnit) ? "passive" : "";
    };
    $ctrl.unTimeCourseUnit = function (courseUnit, locator) {
      $ctrl.ignoreClickPeriod = true;
      timingViewStateService.startUnTimingFromTimeline($ctrl.state);
      var update;
      if (locator) {
        update = timingService.unTimeCourseUnitFromPeriod(courseUnit, locator, $ctrl.selectedPlan, $ctrl.validatablePlanConstructor);
      } else {
        update = timingService.unTimeCourseUnitFromTimeLine(courseUnit, $ctrl.selectedPlan, $ctrl.validatablePlanConstructor);
      }
      return update.then(function () {
        timingViewStateService.onEndTiming($ctrl.state);
        $ctrl.ignoreClickPeriod = false;
      });
    };
    $ctrl.unTimeCustomStudyDraft = function (customStudyDraft, locator) {
      $ctrl.ignoreClickPeriod = true;
      timingViewStateService.startUnTimingFromTimeline($ctrl.state);
      let update;
      if (locator) {
        update = timingService.unTimeCustomStudyDraftFromPeriod(customStudyDraft, locator, $ctrl.selectedPlan, $ctrl.validatablePlanConstructor);
      } else {
        update = timingService.unTimeCustomStudyDraftFromTimeLine(customStudyDraft, $ctrl.selectedPlan, $ctrl.validatablePlanConstructor);
      }
      return update.then(function () {
        timingViewStateService.onEndTiming($ctrl.state);
        $ctrl.ignoreClickPeriod = false;
      });
    };
    $ctrl.createTimeLineNote = function (period) {
      return timingService.createTimeLineNote(period, $ctrl.selectedPlan, $ctrl.validatablePlanConstructor).then(function () {
        $scope.$apply(() => {
          timingViewStateService.onEndTiming($ctrl.state);
        });
      });
    };
    $ctrl.noteEditTitle = function (note) {
      return timingService.editTimeLineNote(note, $ctrl.selectedPlan, $ctrl.validatablePlanConstructor).then(function () {
        timingViewStateService.onEndTiming($ctrl.state);
      });
    };
    $ctrl.startMovingNoteTiming = function (note) {
      timingViewStateService.startMovingNoteTiming($ctrl.state, note);
    };
    $ctrl.noteEditTiming = function (note) {
      timingViewStateService.startTimingForNote($ctrl.state, note);
    };
    $ctrl.deleteNote = function (note) {
      return timingService.deleteNote(note, $ctrl.selectedPlan, $ctrl.validatablePlanConstructor).then(function () {
        timingViewStateService.onEndTiming($ctrl.state);
      });
    };
    $ctrl.addHoverToRepeatSet = function (item) {
      var itemRepeats = document.getElementsByClassName('repeatSetId' + item.id);
      _.forEach(itemRepeats, function (value) {
        value.classList.add('hover');
      });
    };
    $ctrl.removeHoverFromRepeatSet = function (item) {
      var itemRepeats = document.getElementsByClassName('repeatSetId' + item.id);
      _.forEach(itemRepeats, function (value) {
        value.classList.remove('hover');
      });
    };
  }
})();