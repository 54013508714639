import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { TranslateModule } from '@ngx-translate/core';
import { SisCommonModule } from 'sis-common/sis-common.module';

import { SisComponentsModule } from '../../sis-components.module';

import { PlanStructureAttainmentGroupNodeComponent } from './plan-structure-attainment-group-node/plan-structure-attainment-group-node.component';
import { PlanStructureAttainmentNodeStructureComponent } from './plan-structure-attainment-node-structure/plan-structure-attainment-node-structure.component';
import { PlanStructureCourseUnitSubstitutionsComponent } from './plan-structure-course-unit-substitutions/plan-structure-course-unit-substitutions.component';
import { PlanStructureCourseUnitComponent } from './plan-structure-course-unit/plan-structure-course-unit.component';
import { PlanStructureCustomCourseUnitAttainmentComponent } from './plan-structure-custom-course-unit-attainment/plan-structure-custom-course-unit-attainment.component';
import { PlanStructureCustomModuleAttainmentComponent } from './plan-structure-custom-module-attainment/plan-structure-custom-module-attainment.component';
import { PlanStructureCustomStudyDraftComponent } from './plan-structure-custom-study-draft/plan-structure-custom-study-draft.component';
import { PlanStructureDegreeProgrammeBoxComponent } from './plan-structure-degree-programme-box/plan-structure-degree-programme-box.component';
import { PlanStructureEducationComponent } from './plan-structure-education/plan-structure-education.component';
import { PlanStructureModuleContentComponent } from './plan-structure-module-content/plan-structure-module-content.component';
import { PlanStructureModuleSelectionStateComponent } from './plan-structure-module-selection-state/plan-structure-module-selection-state.component';
import { PlanStructureModuleStateBadgesComponent } from './plan-structure-module-state-badges/plan-structure-module-state-badges.component';
import { PlanStructureModuleComponent } from './plan-structure-module/plan-structure-module.component';
import { PlanStructureStudyModuleBoxComponent } from './plan-structure-study-module-box/plan-structure-study-module-box.component';
import { PlanStructureAnyCourseUnitRuleComponent } from './rules/plan-structure-any-course-unit-rule/plan-structure-any-course-unit-rule.component';
import { PlanStructureAnyModuleRuleComponent } from './rules/plan-structure-any-module-rule/plan-structure-any-module-rule.component';
import { PlanStructureCompositeRuleComponent } from './rules/plan-structure-composite-rule/plan-structure-composite-rule.component';
import { PlanStructureCourseUnitCountRuleComponent } from './rules/plan-structure-course-unit-count-rule/plan-structure-course-unit-count-rule.component';
import { PlanStructureCourseUnitRuleComponent } from './rules/plan-structure-course-unit-rule/plan-structure-course-unit-rule.component';
import { PlanStructureCreditsRuleComponent } from './rules/plan-structure-credits-rule/plan-structure-credits-rule.component';
import { PlanStructureModuleRuleComponent } from './rules/plan-structure-module-rule/plan-structure-module-rule.component';
import { PlanStructureRuleErrorListComponent } from './rules/plan-structure-rule-error-list/plan-structure-rule-error-list.component';
import { PlanStructureRuleErrorSummaryComponent } from './rules/plan-structure-rule-error-summary/plan-structure-rule-error-summary.component';
import { PlanStructureCountRuleHeaderComponent } from './rules/plan-structure-rule-headers/plan-structure-count-rule-header/plan-structure-count-rule-header.component';
import { PlanStructureCreditRuleHeaderComponent } from './rules/plan-structure-rule-headers/plan-structure-credit-rule-header/plan-structure-credit-rule-header.component';
import {
    PlanStructureSelectRuleComponent,
} from './rules/plan-structure-select-rule/plan-structure-select-rule.component';

@NgModule({
    declarations: [
        PlanStructureEducationComponent,
        PlanStructureModuleContentComponent,
        PlanStructureModuleSelectionStateComponent,
        PlanStructureModuleComponent,
        PlanStructureAttainmentNodeStructureComponent,
        PlanStructureCustomModuleAttainmentComponent,
        PlanStructureAttainmentGroupNodeComponent,
        PlanStructureModuleStateBadgesComponent,
        PlanStructureCustomStudyDraftComponent,
        PlanStructureCustomCourseUnitAttainmentComponent,
        PlanStructureCourseUnitComponent,
        PlanStructureCourseUnitSubstitutionsComponent,
        PlanStructureSelectRuleComponent,
        PlanStructureCourseUnitCountRuleComponent,
        PlanStructureCreditsRuleComponent,
        PlanStructureCompositeRuleComponent,
        PlanStructureCourseUnitRuleComponent,
        PlanStructureModuleRuleComponent,
        PlanStructureAnyCourseUnitRuleComponent,
        PlanStructureAnyModuleRuleComponent,
        PlanStructureCountRuleHeaderComponent,
        PlanStructureCreditRuleHeaderComponent,
        PlanStructureRuleErrorListComponent,
        PlanStructureRuleErrorSummaryComponent,
        PlanStructureStudyModuleBoxComponent,
        PlanStructureDegreeProgrammeBoxComponent,
    ],
    imports: [
        TranslocoModule,
        CommonModule,
        SisCommonModule,
        SisComponentsModule,
        TranslateModule,
    ],
    exports: [
        PlanStructureEducationComponent,
        PlanStructureSelectRuleComponent,
        PlanStructureRuleErrorSummaryComponent,
    ],
})
export class PlanStructureModule { }
