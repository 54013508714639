import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import {
    Message,
    MessageConversation,
    OtmId,
    SearchResult,
} from 'common-typescript/types';
import _ from 'lodash';
import { map } from 'rxjs/operators';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { MessageConversationService } from 'sis-components/service/message-conversation.service';
import {
    MessageEntityService,
    MessageRelation,
    MessageWithResolvedData,
} from 'sis-components/service/message-entity.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'app-student-frontpage-message-conversations',
    templateUrl: './student-frontpage-message-conversations.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class StudentFrontpageMessageConversationsComponent implements OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.frontpage.studentFrontpageMessageConversations',
        directiveName: 'appStudentFrontpageMessageConversations',
    };

    @Input() studentId: string;
    @Output() goToMessages = new EventEmitter<void>();

    constructor(private appErrorHandler: AppErrorHandler,
                private messageConversationService: MessageConversationService,
                private stateService: StateService,
                private uiRouterGlobals: UIRouterGlobals,
                private messageEntityService: MessageEntityService,
    ) {
    }

    result: SearchResult<MessageConversation>;
    selectedMessageTargetNamesByMessageId: Map<OtmId, string> = new Map<OtmId, string>();

    ngOnInit() {
        this.messageConversationService.findReceivedMessageConversationsForStudent(this.studentId, 10)
            .pipe(
                map((response) => {
                    this.result = response;
                    this.resolveSelectedMessageTargetNames(response);
                }),
                this.appErrorHandler.defaultErrorHandler(),
            ).subscribe();
    }

    resolveSelectedMessageTargetNames = (response: SearchResult<MessageConversation>) => {
        _.forEach(response.searchResults, (conversation: MessageConversation) => {
            this.messageEntityService.loadRelations(conversation.selectedMessage, [MessageRelation.MODULE, MessageRelation.COURSEUNIT])
                .subscribe((data: MessageWithResolvedData) => {
                    this.selectedMessageTargetNamesByMessageId.set(_.get(data, 'message.id'),
                                                                   this.messageEntityService.getMessageTargetNameFromResolvedData(data));
                });
        });
    };

    getMessageTargetName = (message: Message): string => this.selectedMessageTargetNamesByMessageId.get(message.id);

    getMessageTypeTranslationKey(message: Message): string {
        return this.messageEntityService.getMessageTypeTranslationKey(message);
    }

    getSenderFullName(message: Message): string {
        return _.join(_.compact([message.senderFirstNames, message.senderLastName]), ' ');
    }

    moveToReceivedMessages(): void {
        this.goToMessages.emit();
    }

    goToMessageConversation(messageConversationId: string) {
        this.stateService.go('student.logged-in.message-conversations.message-conversation', {
            messageConversationId,
            returnRoute: this.uiRouterGlobals.current.name,
        });
    }

}
