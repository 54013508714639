<sis-create-custom-module-content-workflow
  [parentModule]="parentModule"
  [validatablePlan]="validatablePlan"
  [planValidationResult]="planValidationResult"
  [planStateObject]="planStateObject"
  [planData]="planData"
  [matchingStudyRight]="matchingStudyRight">
</sis-create-custom-module-content-workflow>
<hr/>
<sis-expandable [closed]="false"
                [title]="'SIS_COMPONENTS.PLAN.FREE_EDIT.COURSE_UNITS_TITLE' | translate:{count: selectedCourseUnits.length + selectedCustomCourseUnitAttainments.length}"
                [variant]="'naked-dotted-transparent'">
  <ng-template sisExpandableContent>
    <div class="content-row">
      <div class="content-text">
        <div class="form-group">
          <h4 translate>SIS_COMPONENTS.PLAN.FREE_EDIT.ADD_TO_PLAN</h4>
          <sis-inline-search [searchTarget]="SEARCH_TARGET.COURSE_UNIT"
                             [id]="'course-unit-inline-search'"
                             [parentModule]="parentModule"
                             [validatablePlan]="validatablePlan"
                             [curriculumPeriodIds]="curriculumPeriodIdsForSearch"
                             (selectResult)="onSelectCourseUnitResultItem($event)">
          </sis-inline-search>
        </div>
        <div class="free-edit-selectable-studies">
          <div class="selectable-header clickable">
            <button class="link-button functional-badge"
                    (click)="courseUnitAttainmentsExpanded = !courseUnitAttainmentsExpanded"
                    type="button"
                    [attr.aria-expanded]="courseUnitAttainmentsExpanded">
              <span translate>SIS_COMPONENTS.PLAN.FREE_EDIT.ADD_ATTAINED_COURSE_UNIT</span>
              <sis-icon *ngIf="!courseUnitAttainmentsExpanded" icon="plus"></sis-icon>
              <sis-icon *ngIf="courseUnitAttainmentsExpanded" icon="close"></sis-icon>
            </button>
          </div>
          <div class="selectable-body" *ngIf="courseUnitAttainmentsExpanded">
            <div *ngFor="let courseUnit of selectableAttainedCourseUnits" class="indented-box">
              <sis-plan-course-unit [courseUnit]="courseUnit"
                                    [parentModule]="parentModule"
                                    [planValidationResult]="planValidationResult"
                                    [planStateObject]="planStateObject"
                                    [planData]="planData">
                <div course-unit-action class="additional-box button-box">
                  <button type="button" class="btn btn-sm btn-primary" (click)="selectCourseUnit(courseUnit)">
                    <span translate>SIS_COMPONENTS.PLAN.FREE_EDIT.ADD</span>
                  </button>
                </div>
              </sis-plan-course-unit>
            </div>
            <div *ngFor="let customCourseUnitAttainment of selectableCustomCourseUnitAttainments" class="custom-course-unit-attainment-container">
              <sis-plan-custom-course-unit-attainment [customCourseUnitAttainment]="customCourseUnitAttainment"
                                                      [planStateObject]="planStateObject">
                <div custom-course-unit-attainment-action class="additional-box button-box">
                  <button type="button" class="btn btn-sm btn-primary" (click)="selectCustomCourseUnitAttainment(customCourseUnitAttainment)">
                    <span translate>SIS_COMPONENTS.PLAN.FREE_EDIT.ADD</span>
                  </button>
                </div>
              </sis-plan-custom-course-unit-attainment>
            </div>
          </div>
        </div>
        <div *ngFor="let courseUnit of selectedCourseUnits">
          <sis-plan-course-unit [courseUnit]="courseUnit"
                                [parentModule]="parentModule"
                                [planValidationResult]="planValidationResult"
                                [planStateObject]="planStateObject"
                                [planData]="planData">
            <div course-unit-action class="additional-box">
              <sis-button class="button-box non-button"
                          link="true"
                          (clicked)="unselectCourseUnit(courseUnit)"
                          [ariaLabel]="'ARIA_LABEL.DELETE' |translate">
                <sis-icon icon="delete"></sis-icon>
              </sis-button>
            </div>
          </sis-plan-course-unit>
        </div>
        <div *ngFor="let customCourseUnitAttainment of selectedCustomCourseUnitAttainments" class="custom-course-unit-attainment-container">
          <sis-plan-custom-course-unit-attainment [customCourseUnitAttainment]="customCourseUnitAttainment"
                                                  [planStateObject]="planStateObject">
            <div custom-course-unit-attainment-action class="additional-box">
              <sis-button class="button-box non-button"
                          link="true"
                          (clicked)="unselectCustomCourseUnitAttainment(customCourseUnitAttainment)"
                          [ariaLabel]="'ARIA_LABEL.DELETE' |translate">
                <sis-icon icon="delete"></sis-icon>
              </sis-button>
            </div>
          </sis-plan-custom-course-unit-attainment>
        </div>
      </div>
    </div>
  </ng-template>
</sis-expandable>
<hr>
<sis-expandable [closed]="false"
                [title]="'SIS_COMPONENTS.PLAN.FREE_EDIT.MODULES_TITLE' | translate:{count: selectedModules.length + selectedCustomModuleAttainments.length}"
                [variant]="'naked-dotted-transparent'">
  <ng-template sisExpandableContent>
    <div class="content-row">
      <div class="content-text">
        <div class="form-group">
          <h4 translate>SIS_COMPONENTS.PLAN.FREE_EDIT.ADD_TO_PLAN</h4>
          <sis-inline-search [searchTarget]="SEARCH_TARGET.MODULE"
                             [id]="'module-inline-search'"
                             [parentModule]="parentModule"
                             [validatablePlan]="validatablePlan"
                             [curriculumPeriodIds]="curriculumPeriodIdsForSearch"
                             (selectResult)="onSelectModuleResultItem($event)"></sis-inline-search>
        </div>
        <div>
          <div class="free-edit-selectable-studies">
            <div class="selectable-header clickable">
              <button class="link-button functional-badge"
                      (click)="moduleAttainmentsExpanded = !moduleAttainmentsExpanded"
                      type="button"
                      [attr.aria-expanded]="moduleAttainmentsExpanded">
                <span translate>SIS_COMPONENTS.PLAN.FREE_EDIT.ADD_ATTAINED_MODULE</span>
                <sis-icon *ngIf="!moduleAttainmentsExpanded" icon="plus"></sis-icon>
                <sis-icon *ngIf="moduleAttainmentsExpanded" icon="close"></sis-icon>
              </button>
            </div>
            <div class="selectable-body" *ngIf="moduleAttainmentsExpanded">
              <div *ngFor="let module of selectableAttainedModules" class="indented-box">
                <sis-rule-module-box [module]="module"
                                     [planValidationResult]="planValidationResult"
                                     [planStateObject]="planStateObject"
                                     [planData]="planData">
                  <div module-action class="additional-box button-box">
                    <button type="button" class="btn btn-sm btn-primary" (click)="selectModule(module)">
                      <span translate>SIS_COMPONENTS.PLAN.FREE_EDIT.ADD</span>
                    </button>
                  </div>
                </sis-rule-module-box>
              </div>
              <div *ngFor="let customModuleAttainment of selectableCustomModuleAttainments" class="custom-course-unit-attainment-container">
                <sis-rule-custom-module-attainment-box [customModuleAttainment]="customModuleAttainment"
                                                       [planValidationResult]="planValidationResult"
                                                       [planStateObject]="planStateObject"
                                                       [planData]="planData">
                  <div custom-module-attainment-action class="additional-box button-box">
                    <button type="button" class="btn btn-sm btn-primary" (click)="selectCustomModuleAttainment(customModuleAttainment)">
                      <span translate>SIS_COMPONENTS.PLAN.FREE_EDIT.ADD</span>
                    </button>
                  </div>
                </sis-rule-custom-module-attainment-box>
              </div>
            </div>
          </div>
        </div>
        <div *ngFor="let module of selectedModules">
          <sis-rule-module-box [module]="module"
                               [planValidationResult]="planValidationResult"
                               [planStateObject]="planStateObject"
                               [planData]="planData">
            <div module-action class="additional-box">
              <sis-button class="button-box non-button"
                          link="true"
                          (clicked)="unselectModule(module)"
                          [ariaLabel]="'ARIA_LABEL.DELETE' |translate">
                <sis-icon icon="delete"></sis-icon>
              </sis-button>
            </div>
          </sis-rule-module-box>
        </div>
        <div *ngFor="let customModuleAttainment of selectedCustomModuleAttainments" class="custom-course-unit-attainment-container">
          <sis-rule-custom-module-attainment-box [customModuleAttainment]="customModuleAttainment"
                                                 [planValidationResult]="planValidationResult"
                                                 [planStateObject]="planStateObject"
                                                 [planData]="planData">
            <div custom-module-attainment-action class="additional-box">
              <sis-button class="button-box non-button"
                          link="true"
                          (clicked)="unselectCustomModuleAttainment(customModuleAttainment)"
                          [ariaLabel]="'ARIA_LABEL.DELETE' |translate">
                <sis-icon icon="delete"></sis-icon>
              </sis-button>
            </div>
          </sis-rule-custom-module-attainment-box>
        </div>
      </div>
    </div>
  </ng-template>
</sis-expandable>
<hr/>
<sis-expandable [closed]="false"
                [title]="'SIS_COMPONENTS.PLAN.FREE_EDIT.CUSTOM_STUDY_DRAFTS_TITLE' | translate:{count: selectedCustomStudyDrafts.length}"
                [variant]="'naked-dotted-transparent'">
  <ng-template sisExpandableContent>
    <div class="content-row">
      <div class="content-text">
        <div class="float-end">
          <sis-button size="sm" class="add-study-draft" (clicked)="openCustomStudyDraftCreateModal()">
            {{'SIS_COMPONENTS.PLAN.CREATE_CUSTOM_STUDY_DRAFT' | translate}}
          </sis-button>
        </div>
        <sis-plan-custom-study-draft *ngFor="let customStudyDraft of selectedCustomStudyDrafts"
                                     [customStudyDraft]="customStudyDraft"
                                     [planStateObject]="planStateObject">
          <div custom-study-draft-action class="additional-box">
            <sis-button class="button-box non-button"
                        link="true"
                        (clicked)="unselectCustomStudyDraft(customStudyDraft)"
                        [ariaLabel]="'ARIA_LABEL.DELETE' |translate">
              <sis-icon icon="delete"></sis-icon>
            </sis-button>
          </div>
        </sis-plan-custom-study-draft>
      </div>
    </div>
  </ng-template>
</sis-expandable>
