import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import angular from 'angular';
import { LocalizedString } from 'common-typescript/types';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

/**
 * Displays pills for each supported language in the system, and allows switching the language by clicking any of the pills.
 * This will cause the languageChange event emitter to emit a language change event which contains the language code of the
 * selected language.
 */

interface LanguageSelection {
    language: string;
    name: LocalizedString;
}

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-language-pills',
    templateUrl: './language-pills.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class LanguagePillsComponent implements OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sisComponents.languagePills',
        directiveName: 'sisLanguagePills',
    };

    /**
     * The $scope instance of the containing AngularJS component. Only necessary when this component is used in an AngularJS form.
     * Without this the AngularJS change detection will not pick up the changes made in this component.
     */
    @Input() scope?: angular.IScope;
    @Input() gray?: boolean;
    @Input() invalidLocales?: [string];
    @Input() selectedLanguage: string;
    @Output() selectedLanguageChange = new EventEmitter<string>();

    languageSelection: LanguageSelection[] = [];

    constructor(private localeService: LocaleService) {}

    ngOnInit(): void {
        this.getLanguageSelections();
        if (this.selectedLanguage === null || this.selectedLanguage === undefined) {
            this.changeLanguage(this.localeService.getCurrentLanguage());
        }
    }

    changeLanguage(lang: string): void {
        if (!!this.languageSelection && this.languageSelection.map(ls => ls.language).includes(lang)) {
            this.selectedLanguage = lang;

            if (this.scope) {
                // The change is run inside a digest loop, so need to use setTimeout to prevent "$digest already in progress" errors
                setTimeout(() => this.scope.$apply(() => this.selectedLanguageChange.emit(lang)));
            } else {
                this.selectedLanguageChange.emit(lang);
            }
        }
    }

    isInvalid(lang: string): boolean {
        return this.invalidLocales?.includes(lang);
    }

    private getLanguageSelections(): void {
        this.localeService.getLocalizedLanguageNames()
            .subscribe((languageNames) => {
                this.languageSelection = this.localeService.getOfficialLanguages()
                    .map(language => ({ language, name: languageNames[language] }));
            });

    }
}
