<ng-container *transloco="let t">
  <div class="sis-plan-structure-composite-rule" role="group"
       [attr.id]="this.ruleFocusId()"
       [tabIndex]="-1"
       [attr.aria-labelledby]="ruleLegendId()">
    <sis-plan-structure-count-rule-header [rule]="_rule()"
                                          [ruleLegendId]="ruleLegendId()"
                                          [ruleValidationResults]="ruleValidationResults()">
    </sis-plan-structure-count-rule-header>
    <sis-plan-structure-rule-error-list *ngIf="ruleErrors && ruleErrors()?.length > 0"
                                        [ruleErrors]="ruleErrors()">
    </sis-plan-structure-rule-error-list>
    <div class="sis-plan-structure-composite-rule__body">
      <ul class="list-unstyled">
        <li *ngFor="let rule of sortedAndFilteredRules(); index as i; trackBy: trackByFunc"
            class="list-group-item sis-mb-sm">
          <div [class]="{
           'rule-group': true,
           'rule-group--active': selectionsState()[rule.localId],
          }">
            <div class="sis-pl-sm sis-mb-sm sis-pr-sm">
                <input [attr.type]="isSelectOneRule() ? 'radio' : 'checkbox'"
                       [attr.name]="_rule().localId"
                       [disabled]="disabledSelections()[rule.localId]"
                       [checked]="selectionsState()[rule.localId]"
                       (change)="$event.preventDefault(); toggle(!selectionsState()[rule.localId], rule)"
                       [attr.id]="rule.localId">
                <label [for]="rule.localId" class="sis-pl-xs" role="heading" [attr.aria-level]="headingLevel">
                  {{ t('PLAN_EDIT.SELECTION_MODAL.RULE_BODY.SELECTION_GROUP') }} {{ (groupPrefix ? (groupPrefix + '.' + (i + 1)) : (i + 1)) }}
                </label>
              <sis-plan-structure-select-rule [rule]="rule"
                                              [headingLevel]="headingLevel + 1"
                                              [ruleDepthLevel]="ruleDepthLevel + 1"
                                              [groupPrefix]="groupPrefix ?  (groupPrefix + '.' + (i + 1)): (i + 1)"
                                              [parentModule]="_parentModule()"
                                              [planData]="planData"
                                              [planRuleData]="planRuleData"
                                              [planStateObject]="planStateObject"
                                              [planValidationResult]="_planValidationResult()"
                                              [validatablePlan]="_validatablePlan()"
                                              [selectionDisabled]="!selectionsState()[rule.localId] || _selectionsDisabled()">
              </sis-plan-structure-select-rule>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</ng-container>

