CourseUnitRealisationController.$inject = ["$log", "$q", "$scope", "$timeout", "$translate", "$window", "$filter", "courseUnitInfoModal", "uiStateStore", "localeService", "EnrolmentCalculationState", "commonStudyEventService", "courseUnitRealisationNameService", "enrolmentValidationService", "enrolmentPeriodDateService", "commonEnrolmentService", "universityService", "enrolmentModalService", "modalService", "EnrolmentStudySubGroupPriority", "EnrolmentState", "ProcessingState", "defaultPromiseHandler", "commonEnrolmentQuestionnaireService", "languageJSDataModel", "alertsService", "personRuleService", "enrolmentService", "$state", "commonPlanService", "commonStudyRightService", "planService", "selectEducationModal"];
import angular from 'angular';
import _, { cloneDeep } from 'lodash';
import moment from 'moment';
import { commonUniversityServiceModule } from 'sis-common/university/university.service.ts';
import { IconComponent } from 'sis-components/icon/icon.component.ts';
import { localeServiceModule } from 'sis-common/l10n/localeService';
import { localizedStringFilterModule } from 'sis-common/l10n/localizedStringFilter';
import { courseUnitRealisationNameServiceModule } from 'sis-common/model/courseUnitRealisationName.service';
import { commonStudyEventServiceModule } from 'sis-components/service/studyEvent.service';
import { commonEnrolmentQuestionnaireServiceModule } from 'sis-components/service/enrolmentQuestionnaire.service';
import { languageModelModule } from 'sis-components/model/language.model';
import { alertsServiceModule } from 'sis-components/alerts/alerts.service';
import { personRuleModule } from 'sis-components/service/personRule.service';
import { commonEnrolmentPeriodDateServiceModule } from 'sis-components/service/enrolmentPeriodDate.service';
import { commonEnrolmentServiceModule } from 'sis-components/service/enrolment.service';
import { enrolmentValidationServiceModule } from 'sis-components/service/enrolmentValidation.service';
import { localDateRangeFilterModule } from 'sis-components/date/filters/localDateRange/localDateRange.filter';
import { ShowMoreComponent } from 'sis-components/show-more/show-more.component.ts';
import { ExternalLinkComponent } from 'sis-components/external-link/external-link.component.ts';
import { dropdownSelectModule } from 'sis-components/select/dropdownSelect.component';
import { ModalService } from 'sis-common/modal/modal.service.ts';
import { EnrolmentWizardComponent } from '../../../enrolments/enrolment-wizard/enrolment-wizard.component.ts';
import { courseUnitRealisationNameFilterModule } from '../../filters/courseUnitRealisationName.filter';
import { enrolmentServiceModule } from '../../service/enrolment.service';
import { uiStateStoreModule } from '../../utils/uiStateStore';
import { learningEnvironmentUrlModule } from '../learningEnvironmentUrl/learningEnvironmentUrl.component';
import { planServiceModule } from '../../service/planService';
import { EnrolmentStateIndicatorComponent } from '../enrolment/enrolment-state-indicator/enrolment-state-indicator.component.ts';
import { ProcessingStateIndicatorComponent } from '../enrolment/processing-state-indicator/processing-state-indicator.component.ts';
import { enrolmentModalServiceModule } from './enrolment/enrolmentModal.service';
import { studyEventSummaryModule } from './studyEventSummary.component';
import { studySubGroupEventInfoModule } from './studySubGroupEventInfo.component';
import courseUnitRealisationTpl from './courseUnitRealisation.tpl.html';
export const courseUnitRealisationModule = 'student.common.components.course-unit-realisation.courseUnitRealisation';
angular.module(courseUnitRealisationModule, ['ui.router', 'ui.router.upgrade', courseUnitRealisationNameFilterModule, uiStateStoreModule, 'student.common.components.courseUnitInfoModal', learningEnvironmentUrlModule, commonStudyEventServiceModule, localDateRangeFilterModule, dropdownSelectModule, localeServiceModule, commonUniversityServiceModule, studyEventSummaryModule, courseUnitRealisationNameServiceModule, commonEnrolmentPeriodDateServiceModule, commonEnrolmentServiceModule, enrolmentModalServiceModule, localizedStringFilterModule, commonEnrolmentQuestionnaireServiceModule, enrolmentValidationServiceModule, languageModelModule, alertsServiceModule, personRuleModule, studySubGroupEventInfoModule, enrolmentServiceModule, planServiceModule, 'student.plan', IconComponent.downgrade.moduleName, ExternalLinkComponent.downgrade.moduleName, EnrolmentStateIndicatorComponent.downgrade.moduleName, ProcessingStateIndicatorComponent.downgrade.moduleName, ShowMoreComponent.downgrade.moduleName, EnrolmentWizardComponent.downgrade.moduleName, ModalService.downgrade.moduleName]).component('courseUnitRealisation', {
  bindings: {
    courseUnitRealisation: '<',
    courseUnit: '<',
    getValidatablePlan: '<',
    enrolment: '<',
    enrolments: '<',
    enrolmentCalculationResult: '<',
    colorIndex: '<',
    // Not used if frontPageView = true. Mandatory if frontPageView = false.
    onViewInCalendar: '&?',
    // Not used if frontPageView = true. Mandatory if frontPageView = false.
    onStudySubGroupCalendarSelectionToggle: '&?',
    // Not used if frontPageView = true. Mandatory if frontPageView = false.
    onRemoveFromCalendar: '&?',
    // Force evaluation of visibility againts filters in parent component. Binding a function that evaluated visible enrolments to
    // template in parent component seemed to produce too many digest cycles. So we do it manually.
    // Not used if frontPageView = true. Mandatory if frontPageView = false.
    evaluateVisibility: '&?',
    // Used for ENROLLED after modify groups
    onModifyGroups: '&?',
    // Indicates if used from front page. If true all actions are disabled/hidden and expand/collapse toggle is not stored in
    // storage. Actions are hidden with ng-ifs in template.
    frontPageView: '<?',
    plans: '<'
  },
  template: courseUnitRealisationTpl,
  controller: CourseUnitRealisationController
});
function CourseUnitRealisationController(
// NOSONAR
$log, $q, $scope, $timeout, $translate, $window, $filter, courseUnitInfoModal, uiStateStore, localeService, EnrolmentCalculationState, commonStudyEventService, courseUnitRealisationNameService, enrolmentValidationService, enrolmentPeriodDateService, commonEnrolmentService, universityService, enrolmentModalService, modalService, EnrolmentStudySubGroupPriority, EnrolmentState, ProcessingState, defaultPromiseHandler, commonEnrolmentQuestionnaireService, languageJSDataModel, alertsService, personRuleService, enrolmentService, $state, commonPlanService, commonStudyRightService, planService, selectEducationModal) {
  const $ctrl = this;
  let enrolmentEndTimer;
  let cancellationEndTimer;
  $ctrl.collapsed = true;
  $ctrl.expandedOnce = false;
  $ctrl.loaded = false;
  $ctrl.firstEventDateByStudySubGroupId = {};
  $ctrl.notifications = [];
  $ctrl.warnings = [];
  $ctrl.studySubGroupPriorityMap = {};
  $ctrl.isEditableBySsgId = {};
  $ctrl.ssgPriorityOptionsBySsgId = {};
  $ctrl.extendedViewExpanded = {};
  $ctrl.modalOpen = false;

  /**
           * This component uses temporary map for enrolmentStudySubGroupPriorities. Function of the map is to store
           * users priority selections until he/she decides to enrol/updateEnrolment because he/she should be able to
           * simultaneously toggle isInCalendar fields and those requests are PUTted instantly. This leads to a
           * dilemma where we can not save invalid priority selections but we want to save calendar toggles. When
           * the magical moment of crucial button click happens, we merge temporary priority map to real enrolment
           * and send it as request payload. The unfortunate fact is that priority selection do not matter before
           * enrol action is taken so the map is not necessary before that. But we should allow user to update
           * priority selections during enrolment period (and also those isInCalendars). The latter case was used as
           * justification for temporary map solution.
           */
  $ctrl.$onInit = () => {
    $ctrl.courseUnitName = getCourseUnitRealisationName($ctrl.courseUnitRealisation, $ctrl.courseUnit);
    $ctrl.credits = enrolmentService.getEnrolmentCredits($ctrl.enrolment);
    $ctrl.isCreditRange = $ctrl.credits && $ctrl.credits.min !== $ctrl.credits.max;
    $ctrl.setLearningEnvironmentLink();
    $ctrl.curHasExceptionsOrCancellationsWithinOneWeek = $ctrl.resolveCurHasExceptionsOrCancellationsWithinOneWeek();
    $ctrl.initCollapsed();
    $ctrl.enrolmentPeriodDates = enrolmentPeriodDateService.getEnrolmentDates($ctrl.courseUnitRealisation);
    $ctrl.setPriorityMaps();
    $ctrl.updateNotifications();
    $ctrl.updateWarnings();
    $ctrl.setUniversityNames();
    languageJSDataModel.lazyGetAll();
    $ctrl.initTimers();
  };
  $scope.$on('updateDataForExpandedView', () => {
    const expandedCurs = getExpandedCurs();
    const isExpanded = expandedCurs.some(cur => cur === $ctrl.enrolment.courseUnitRealisationId);
    if (isExpanded || $ctrl.expandedOnce) {
      $ctrl.getCalculationResultTexts();
    }
  });
  $ctrl.setPriorityMaps = () => {
    $ctrl.studySubGroupPriorityMap = enrolmentValidationService.createEnrolmentStudySubGroupPriorityMap($ctrl.enrolment, $ctrl.courseUnitRealisation);
    $ctrl.isEditableBySsgId = enrolmentValidationService.createIsEditableBySsgIdMap($ctrl.studySubGroupPriorityMap, $ctrl.courseUnitRealisation);
    _.chain($ctrl.courseUnitRealisation.studyGroupSets).flatMap('studySubGroups').filter('cancelled').forEach(ssg => {
      $ctrl.studySubGroupPriorityMap[ssg.id] = 'NOT_SUITABLE';
      $ctrl.isEditableBySsgId[ssg.id] = false;
    }).value();
    $ctrl.ssgPriorityOptionsBySsgId = enrolmentValidationService.createDropdownOptionsForStudySubGroups($ctrl.courseUnitRealisation, $ctrl.enrolment);
  };

  /**
           * This function performs async loads of data that are needed for expanded view.
           */
  $ctrl.loadDataForExpandedView = () => {
    if ($ctrl.loaded) {
      return;
    }
    enrolmentService.loadDataForCourseUnitRealisationComponent([$ctrl.enrolment]).then(() => $ctrl.getCalculationResultTexts()).catch(defaultPromiseHandler.loggingRejectedPromiseHandler).finally(() => {
      $ctrl.loaded = true;
    });
  };
  const getExpandedCurs = () => {
    const storedUiStates = uiStateStore.readField('otm.student.calendar.courseUnitRealisationItem.', 'expanded');
    return storedUiStates || [];
  };
  $ctrl.initCollapsed = () => {
    $ctrl.collapsed = $ctrl.frontPageView || !_.includes(getExpandedCurs(), $ctrl.courseUnitRealisation.id) && !$ctrl.showUpcomingExceptionNotification();
    $ctrl.expandedOnce = !$ctrl.collapsed;
    if ($ctrl.expandedOnce) {
      $ctrl.loadDataForExpandedView();
    }
  };
  $ctrl.collapse = () => {
    $ctrl.collapsed = !$ctrl.collapsed;
    if (!$ctrl.frontPageView) {
      let states = getExpandedCurs();
      if (!$ctrl.collapsed) {
        states.push($ctrl.courseUnitRealisation.id);
      } else {
        states = _.pull(states, $ctrl.courseUnitRealisation.id);
      }
      uiStateStore.storeField('otm.student.calendar.courseUnitRealisationItem.', 'expanded', states);
    }
    // if this is the collapsing toggle then this has already been expanded once
    // if this is the expanding toggle then this has been expanded once
    $ctrl.expandedOnce = true;
    $ctrl.loadDataForExpandedView();
  };

  // relations needed for texts should be loaded by now
  $ctrl.getCalculationResultTexts = () => {
    function keepFailedRequirementRules(ruleResult) {
      return ruleResult.result === false;
    }
    if (!$ctrl.enrolmentCalculationResult) {
      return $q.when();
    }
    const requirementPromises = _.chain($ctrl.enrolmentCalculationResult.requirementRules).filter(keepFailedRequirementRules).map(ruleResult => personRuleService.fetchRuleInfoText(ruleResult.rule)).value();
    const orderingPromises = _.map($ctrl.enrolmentCalculationResult.orderingRules, ruleResult => personRuleService.fetchRuleInfoText(ruleResult.rule));
    // Load relations first as each rule might have similar data to be loaded.
    return $q.all([$q.all(requirementPromises), $q.all(orderingPromises)]).then(responses => {
      $ctrl.enrolmentCalculationResultText = {
        courseUnitRealisationId: $ctrl.enrolmentCalculationResult.courseUnitRealisationId,
        failedRequirementPersonRulesText: responses[0],
        orderingPersonRulesText: responses[1]
      };
    });
  };
  $ctrl.setUniversityNames = () => {
    universityService.getUniversities().then(universities => {
      $ctrl.externalUniversityNames = _.chain(universities).filter(university => _.includes($ctrl.courseUnitRealisation.universityOrgIds, university.id)).map('name').map(localeService.getLocalizedValue).join(', ').value();
    });
  };
  function getCourseUnitRealisationName(courseUnitRealisation, courseUnit) {
    return courseUnitRealisationNameService.generateFullNameFromCourseUnit(courseUnitRealisation, courseUnit);
  }
  $ctrl.setLearningEnvironmentLink = () => {
    const language = localeService.getCurrentLocale();
    $ctrl.learningEnvironmentLink = _.find($ctrl.courseUnitRealisation.learningEnvironments, {
      language,
      primary: true
    });
    if ($ctrl.learningEnvironmentLink) {
      return;
    }
    $ctrl.learningEnvironmentLink = _.find($ctrl.courseUnitRealisation.learningEnvironments, {
      language
    });
  };
  $ctrl.studySubGroupPriorityChangedCallBack = (studySubGroup, selection) => {
    $ctrl.studySubGroupPriorityMap[studySubGroup.id] = selection;
  };
  $ctrl.showStudySubGroupPriority = studySubGroup => $ctrl.ssgPriorityOptionsBySsgId[studySubGroup.id] && ($ctrl.enrolAction.isButtonVisible() || $ctrl.updateAction.isButtonVisible());
  $ctrl.updateNotifications = () => {
    $ctrl.notifications = [];
    if ($ctrl.isCancelledCourseUnitRealisation()) {
      $ctrl.notifications.push({
        translation: 'ENROLMENTS.CUR_CANCELLED'
      });
      return;
    }
    if (!$ctrl.enrolmentPossibleForUniversity()) {
      return;
    }
    if (!_.includes([EnrolmentState.NOT_ENROLLED, EnrolmentState.REJECTED], $ctrl.enrolment.state)) {
      return;
    }
    const rejected = $ctrl.enrolment.state === EnrolmentState.REJECTED;
    const enrolmentPeriodOngoing = $ctrl.enrolmentPeriodOngoing();
    if ($ctrl.courseUnitRealisation.continuousEnrolment) {
      if (!enrolmentPeriodOngoing) {
        return;
      }
      if (rejected) {
        $ctrl.notifications.push({
          translation: 'COURSE_UNIT_REALISATION.PRIORITIES_NOT_SELECTED_CONTINUOUS_REJECTED'
        });
        return;
      }
      $ctrl.notifications.push({
        translation: 'COURSE_UNIT_REALISATION.PRIORITIES_NOT_SELECTED'
      });
      return;
    }
    if (enrolmentPeriodOngoing) {
      if ($ctrl.enrolment.state === EnrolmentState.NOT_ENROLLED) {
        $ctrl.notifications.push({
          translation: 'COURSE_UNIT_REALISATION.PRIORITIES_NOT_SELECTED'
        });
        return;
      }
      return;
    }
    if (!$ctrl.lateEnrolmentPeriodOngoing()) {
      return;
    }
    if (rejected) {
      $ctrl.notifications.push({
        translation: 'COURSE_UNIT_REALISATION.PRIORITIES_NOT_SELECTED_LATE_REJECTED'
      });
      return;
    }
    $ctrl.notifications.push({
      translation: 'COURSE_UNIT_REALISATION.PRIORITIES_NOT_SELECTED_LATE'
    });
  };
  $ctrl.updateWarnings = () => {
    $ctrl.warnings = [];
    if ($ctrl.enrolmentPeriodOngoing() && $ctrl.enrolment.state === EnrolmentState.NOT_ENROLLED && !$ctrl.enrolmentPossibleForUniversity()) {
      $ctrl.warnings.push({
        translation: 'COURSE_UNIT_REALISATION.ENROLMENT_IN_EXTERNAL_UNI'
      });
    }
    if ($ctrl.showUpcomingExceptionNotification()) {
      $ctrl.warnings.push({
        translation: 'COURSE_UNIT_REALISATION.HAS_CANCELLATIONS_WITHIN_ONE_WEEK'
      });
    }
  };
  $ctrl.enrolmentPossibleForUniversity = function () {
    return $ctrl.enrolmentPossibleForHomeUniversity() || $ctrl.enrolmentPossibleForCooperationNetwork();
  };
  $ctrl.enrolmentPossibleForCooperationNetwork = function () {
    return !$ctrl.enrolmentPossibleForHomeUniversity() && !_.isEmpty(_.get($ctrl.courseUnitRealisation, 'cooperationNetworkDetails.networks'));
  };
  $ctrl.enrolmentPossibleForHomeUniversity = function () {
    return _.includes(_.get($ctrl.courseUnitRealisation, 'universityOrgIds'), universityService.getCurrentUniversityOrgId());
  };
  $ctrl.resolveCurHasExceptionsOrCancellationsWithinOneWeek = () => {
    let exceptionDates = [];
    const studySubGroups = _.flatMap($ctrl.courseUnitRealisation.studyGroupSets, $ctrl.getStudySubGroupsForDefaultView);
    const studyEvents = _.chain(studySubGroups).flatMap('studyEvents').compact().value();
    _.forEach(studyEvents, studyEvent => {
      exceptionDates = _.concat(exceptionDates, studyEvent.exceptions, studyEvent.cancellations);
    });
    if (_.isEmpty(exceptionDates)) {
      return false;
    }
    const currentMoment = moment();
    const weekAfterMoment = moment().add(7, 'days');
    return _.some(exceptionDates, date => moment(date).isBetween(currentMoment, weekAfterMoment, 'day', '[]'));
  };
  $ctrl.showUpcomingExceptionNotification = () => _.get($ctrl.enrolment, 'state') === 'ENROLLED' && $ctrl.curHasExceptionsOrCancellationsWithinOneWeek;
  $ctrl.getStudySubGroupsForDefaultView = function (studyGroupSet) {
    if ($ctrl.enrolment.state !== 'ENROLLED') {
      return studyGroupSet.studySubGroups;
    }
    return _.filter(studyGroupSet.studySubGroups, ssg => isSsgInConfirmedSsgs(ssg.id));
  };
  $ctrl.getStudySubGroupsForExtendedView = function (studyGroupSet) {
    if ($ctrl.enrolment.state !== 'ENROLLED') {
      return [];
    }
    return _.filter(studyGroupSet.studySubGroups, ssg => !isSsgInConfirmedSsgs(ssg.id));
  };
  $ctrl.toggleExpandedExtendedView = studyGroupSet => {
    $ctrl.extendedViewExpanded[studyGroupSet.localId] = !$ctrl.extendedViewExpanded[studyGroupSet.localId];
  };
  $ctrl.isExtendedViewExpanded = studyGroupSet => _.get($ctrl.extendedViewExpanded, studyGroupSet.localId, false);
  const getEnrolmentStudySubGroups = () => _.get($ctrl.enrolment, 'studySubGroups', []);

  /**
           * Get study sub group object in enrolment.
           */
  $ctrl.getEnrolmentStudySubGroup = studySubGroupId => _.find(getEnrolmentStudySubGroups(), {
    studySubGroupId
  });
  const getCurStudySubGroups = () => _.flatMap(_.get($ctrl.courseUnitRealisation, 'studyGroupSets', []), 'studySubGroups');

  /**
           * Get study sub group object in course unit realisation.
           */
  const getCurStudySubGroup = studySubGroupId => _.find(getCurStudySubGroups(), {
    id: studySubGroupId
  });
  $ctrl.getEnrolmentStudySubGroupOrEmptyObject = studySubGroupId => $ctrl.getEnrolmentStudySubGroup(studySubGroupId) || {};
  $ctrl.canToggleSubGroupInCalendar = studySubGroupId => !$ctrl.isStudySubGroupConfirmed(studySubGroupId);
  $ctrl.onToggleSubGroupInCalendar = function (studySubGroupId) {
    const enrolmentStudySubGroup = $ctrl.getEnrolmentStudySubGroup(studySubGroupId);
    if (!enrolmentStudySubGroup) {
      // This is kind of a hack. This means that enrolment did not have ssg object. getEnrolmentStudySubGroupOrEmptyObject
      // method is used on the template and in this case it returns empty object. So before toggle ui indicates that user has
      // not added ssg to calendar. Now that the toggle has been clicked, the empty object got "isInCalendar: true" but that
      // will not be persisted in any way so here we create the enrolment ssg with "isInCalendar: true"
      const newEnrolmentStudySubGroup = {
        studySubGroupId,
        isInCalendar: true,
        enrolmentStudySubGroupPriority: EnrolmentStudySubGroupPriority.NOT_SUITABLE
      };
      $ctrl.enrolment.studySubGroups.push(newEnrolmentStudySubGroup);
    }
    return $ctrl.onStudySubGroupCalendarSelectionToggle({
      enrolment: $ctrl.enrolment
    }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
  };
  $ctrl.isEnrolmentTentativelySelected = () => $ctrl.enrolment.state === 'PROCESSING' && _.includes(['SELECTED', 'CURRENTLY_SELECTED'], $ctrl.enrolment.processingState);
  function isSsgInConfirmedSsgs(studySubGroupId) {
    return _.includes($ctrl.enrolment.confirmedStudySubGroupIds, studySubGroupId);
  }
  $ctrl.isStudySubGroupConfirmed = function (studySubGroupId) {
    return $ctrl.enrolment.state === 'ENROLLED' && isSsgInConfirmedSsgs(studySubGroupId);
  };
  $ctrl.isStudySubGroupTentative = function (studySubGroupId) {
    return $ctrl.isEnrolmentTentativelySelected() && _.includes($ctrl.enrolment.tentativeStudySubGroupIds, studySubGroupId);
  };
  $ctrl.getTentativeGroupsForSgs = sgs => _.filter(sgs.studySubGroups, ssg => $ctrl.isStudySubGroupTentative(ssg.id));
  const getAllocationCountsForSsg = studySubGroupId => _.chain($ctrl.enrolmentCalculationResult).get('enrolmentAllocationCounts.studySubGroupAllocationCounts', []).find({
    studySubGroupId
  }).value();
  $ctrl.getEnrolledCountForSsg = studySubGroupId => _.get(getAllocationCountsForSsg(studySubGroupId), 'enrolledCount', 0);
  $ctrl.getTentativeCountForSsg = studySubGroupId => _.get(getAllocationCountsForSsg(studySubGroupId), 'tentativeCount', 0);
  $ctrl.getEnrolledAmountTranslationKey = () => {
    if (!$ctrl.courseUnitRealisation.continuousEnrolment && (!enrolmentPeriodDateService.enrolmentPeriodStarted($ctrl.courseUnitRealisation) || enrolmentPeriodDateService.enrolmentPeriodOngoing($ctrl.courseUnitRealisation))) {
      return 'COURSE_UNIT_REALISATION.TENTATIVE_AND_TOTAL_GROUP_SIZE';
    }
    return 'COURSE_UNIT_REALISATION.CONFIRMED_AND_TOTAL_GROUP_SIZE';
  };
  const isStudySubGroupFull = studySubGroupId => {
    const ssgSize = _.get(getCurStudySubGroup(studySubGroupId), 'size');
    if (_.isNil(ssgSize)) {
      return false;
    }
    return $ctrl.getEnrolledCountForSsg(studySubGroupId) >= ssgSize;
  };
  $ctrl.getEnrolledAmountIcon = ssgId => {
    if ($ctrl.courseUnitRealisation.continuousEnrolment || $ctrl.lateEnrolmentPeriodOngoing()) {
      if (isStudySubGroupFull(ssgId)) {
        return 'lock';
      }
      return 'seats';
    }
    if (!enrolmentPeriodDateService.enrolmentPeriodStarted($ctrl.courseUnitRealisation) || enrolmentPeriodDateService.enrolmentPeriodOngoing($ctrl.courseUnitRealisation)) {
      return 'hourglass';
    }
    return 'lock';
  };
  $ctrl.getEnrolledAmountCount = ssgId => {
    if ($ctrl.courseUnitRealisation.continuousEnrolment) {
      return $ctrl.getEnrolledCountForSsg(ssgId);
    }
    if (!enrolmentPeriodDateService.enrolmentPeriodStarted($ctrl.courseUnitRealisation) || enrolmentPeriodDateService.enrolmentPeriodOngoing($ctrl.courseUnitRealisation)) {
      return $ctrl.getTentativeCountForSsg(ssgId) + $ctrl.getEnrolledCountForSsg(ssgId);
    }
    return $ctrl.getEnrolledCountForSsg(ssgId);
  };
  $ctrl.hasSpaceInSelectedStudySubGroups = function () {
    return !_.chain(getEnrolmentStudySubGroups()).map('studySubGroupId').filter(ssgId => _.includes([EnrolmentStudySubGroupPriority.PRIMARY, EnrolmentStudySubGroupPriority.SUITABLE], $ctrl.studySubGroupPriorityMap[ssgId])).find(isStudySubGroupFull).value();
  };
  $ctrl.hasSpaceForEnrolledEnrolmentsInCur = function () {
    const maxSelected = _.get($ctrl.enrolmentCalculationResult, 'enrolmentAllocationCounts.maxSelected');
    const enrolledCount = _.get($ctrl.enrolmentCalculationResult, 'enrolmentAllocationCounts.enrolledCount');
    if (!_.isNumber(maxSelected) || !_.isNumber(enrolledCount)) {
      // Some shady stuff is happening here, allow user to enrol and delegate the validation to backend
      return true;
    }
    return enrolledCount < maxSelected;
  };
  $ctrl.hasSpaceInCur = () => $ctrl.hasSpaceForEnrolledEnrolmentsInCur() && $ctrl.hasSpaceInSelectedStudySubGroups();
  $ctrl.openCourseUnitInfo = function () {
    if (!$ctrl.courseUnit) {
      $log.info('courseUnit for enrolment not defined, can not open courseUnitInfoModal');
      return;
    }
    if (!_.isFunction($ctrl.getValidatablePlan)) {
      courseUnitInfoModal.showCourseUnitRealisationForCourseUnit($ctrl.courseUnit.id, $ctrl.courseUnitRealisation, undefined);
      return;
    }
    $ctrl.getValidatablePlan().then(validatablePlan => {
      courseUnitInfoModal.showCourseUnitRealisationForCourseUnit($ctrl.courseUnit.id, $ctrl.courseUnitRealisation, validatablePlan);
    });
  };
  $ctrl.selectedGroupsCount = function (studyGroupSet) {
    if (studyGroupSet.subGroupRange.min === studyGroupSet.subGroupRange.max) {
      return enrolmentValidationService.selectedSuitableAndPrimarySubGroupsCount($ctrl.studySubGroupPriorityMap, studyGroupSet);
    }
    return enrolmentValidationService.selectedPrimarySubGroupsCount($ctrl.studySubGroupPriorityMap, studyGroupSet);
  };
  $ctrl.canUpdateStudySubGroupPriority = function () {
    if ($ctrl.courseUnitRealisation.usesExternalEnrolment) {
      return false;
    }
    return canUpdateSsgPriorityOnEnrolmentPeriod() || canUpdateSsgPriorityOnContinuousEnrolmentPeriod() || canUpdateSsgPriorityOnLateEnrolmentPeriod();
  };
  function canUpdateSsgPriorityOnEnrolmentPeriod() {
    return !$ctrl.courseUnitRealisation.continuousEnrolment && $ctrl.enrolmentPeriodOngoing() && ['NOT_ENROLLED', 'INVALID', 'PROCESSING'].includes($ctrl.enrolment.state);
  }
  function canUpdateSsgPriorityOnContinuousEnrolmentPeriod() {
    return $ctrl.continuousEnrolmentPeriodOngoing() && ['NOT_ENROLLED', 'INVALID', 'REJECTED'].includes($ctrl.enrolment.state);
  }
  function canUpdateSsgPriorityOnLateEnrolmentPeriod() {
    return $ctrl.lateEnrolmentPeriodOngoing() && ['NOT_ENROLLED', 'REJECTED'].includes($ctrl.enrolment.state);
  }
  $ctrl.isThereStudyRightMatchingPlan = function () {
    commonStudyRightService.studyRights().then(myStudyRights => _.find(myStudyRights, {
      id: $ctrl.enrolment.studyRightId
    })).then(myStudyRight => {
      $ctrl.getValidatablePlan().then(currentValidatablePlan => {
        // Checking if current plans study right is same as enrolments plans study right
        // this prevents in this case unnecessary plan revalidation
        if (_.get(currentValidatablePlan, 'plan.rootId') !== _.get(myStudyRight, 'educationId')) {
          planService.findMyPlans().then(plans => {
            const myMatchingPlan = _.find(plans, {
              rootId: _.get(myStudyRight, 'educationId'),
              primary: true
            });
            commonPlanService.getValidatablePlan(myMatchingPlan, false, true).then(validatablePlan => {
              $ctrl.guidanceAlertsForStudent(validatablePlan);
            });
          });
        } else {
          $ctrl.guidanceAlertsForStudent(currentValidatablePlan);
        }
      });
    }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
  };
  $ctrl.guidanceAlertsForStudent = function (validatablePlan) {
    if (!validatablePlan) {
      // there is no primary plan for current enrolment with this study right.
      // Lets guide student to create one and add study module there.
      alertsService.info($translate.instant('ENROLMENT.NO_MATCHING_PLAN', {
        cur: $filter('localizedString')($ctrl.courseUnit.name)
      }), undefined, `guidance-alert-${$ctrl.courseUnitRealisation.id}`, openCreatePlanModal);
    }
    if (validatablePlan) {
      const isSelected = _.filter(validatablePlan.plan.courseUnitSelections, courseUnitSelections => courseUnitSelections.courseUnitId === $ctrl.courseUnit.id);
      const isCourseUnitUnscheduled = commonPlanService.isCourseUnitUnscheduled($ctrl.enrolment.courseUnitId, validatablePlan, $ctrl.enrolments);
      if (!isSelected.length > 0) {
        alertsService.info($translate.instant('ENROLMENT.CUR_IS_NOT_IN_PLAN', {
          cur: $filter('localizedString')($ctrl.courseUnit.name)
        }), undefined, `guidance-alert-${$ctrl.courseUnitRealisation.id}`, goStructure);
      } else if (!isSelected[0].completionMethodId) {
        alertsService.info($translate.instant('ENROLMENT.MISSING_COMPLETION_METHOD_SELECTION', {
          cur: $filter('localizedString')($ctrl.courseUnit.name)
        }), undefined, `guidance-alert-${$ctrl.courseUnitRealisation.id}`, openCourseUnitModal);
      } else if (isCourseUnitUnscheduled) {
        alertsService.info($translate.instant('ENROLMENT.MISSING_PART_OF_COURSE_UNIT', {
          cur: $filter('localizedString')($ctrl.courseUnit.name)
        }), undefined, `guidance-alert-${$ctrl.courseUnitRealisation.id}`, openCourseUnitModal);
      }
    }
    function openCourseUnitModal() {
      alertsService.dismissAlertIfExists(`guidance-alert-${$ctrl.courseUnitRealisation.id}`);
      courseUnitInfoModal.showCompletionMethodsForCourseUnit($ctrl.courseUnit.id, validatablePlan);
    }
    function goStructure() {
      alertsService.dismissAlertIfExists(`guidance-alert-${$ctrl.courseUnitRealisation.id}`);
      $state.go('student.logged-in.structure', {
        planId: validatablePlan.plan.id,
        openUnplanned: true,
        unplannedCourseUnitId: $ctrl.courseUnit.id
      });
    }
    function openCreatePlanModal() {
      alertsService.dismissAlertIfExists(`guidance-alert-${$ctrl.courseUnitRealisation.id}`);
      selectEducationModal.open($ctrl.enrolment.studyRightId).then(result => {
        $state.go('student.logged-in.structure', {
          planId: result.planId,
          openUnplanned: true,
          unplannedCourseUnitId: $ctrl.courseUnit.id
        });
      }, () => {
        $log.debug('selectEducationModal cancelled');
      });
    }
  };
  const isDefinedAndInFuture = dateTime => !!dateTime && moment().isBefore(dateTime);
  $ctrl.enrolAction = {
    buttonClick() {
      $ctrl.modalOpen = true;
      $ctrl.enrolment = enrolmentValidationService.assignNewPrioritiesToEnrolment($ctrl.studySubGroupPriorityMap, $ctrl.enrolment);
      const modalRef = modalService.open(EnrolmentWizardComponent, {
        enrolment: cloneDeep($ctrl.enrolment),
        isUpdate: false,
        isConfirmedSsgEdit: false
      }, {
        size: 'lg'
      });
      modalRef.result.then($ctrl.enrolAction.afterEnrolment).catch(errorResponse => {
        $ctrl.enrolmentModalErrorHandler(errorResponse);
      }).finally(() => {
        $timeout(() => {
          $ctrl.modalOpen = false;
        });
      });
    },
    afterEnrolment(modalResponse) {
      $ctrl.enrolment = _.cloneDeep(modalResponse);
      $ctrl.setPriorityMaps();
      $ctrl.updateNotifications();
      $ctrl.isThereStudyRightMatchingPlan();
      // do not re-evaluate visibility, we don't want to lose the cur-box from the ui just yet
    },

    isButtonVisible() {
      if (!$ctrl.enrolmentPossibleForCur()) {
        return false;
      }
      if ($ctrl.enrolment.state === EnrolmentState.REJECTED) {
        return $ctrl.lateOrContinuousPeriodOngoing();
      }
      if ($ctrl.enrolment.state === EnrolmentState.NOT_ENROLLED) {
        return $ctrl.enrolmentPeriodOngoing() || $ctrl.lateEnrolmentPeriodOngoing();
      }
      if ($ctrl.enrolment.state === EnrolmentState.INVALID) {
        return $ctrl.enrolmentPeriodOngoing() || $ctrl.lateEnrolmentPeriodOngoing();
      }
      return false;
    },
    isButtonEnabled() {
      if (!$ctrl.lateOrContinuousPeriodOngoing()) {
        // normal period does not need extra checks
        return true;
      }
      return $ctrl.lateEnrolmentPeriodOngoing() ? $ctrl.areCalculationResultsConfirmed() : true;
    }
  };
  $ctrl.updateAction = {
    buttonClick() {
      $ctrl.modalOpen = true;
      $ctrl.enrolment = enrolmentValidationService.assignNewPrioritiesToEnrolment($ctrl.studySubGroupPriorityMap, cloneDeep($ctrl.enrolment));
      const modalRef = modalService.open(EnrolmentWizardComponent, {
        enrolment: cloneDeep($ctrl.enrolment),
        isUpdate: true,
        isConfirmedSsgEdit: false
      }, {
        size: 'lg'
      });
      modalRef.result.then($ctrl.updateAction.afterUpdate).catch($ctrl.enrolmentModalErrorHandler).finally(() => {
        $timeout(() => {
          $ctrl.modalOpen = false;
        });
      });
    },
    afterUpdate(modalResponse) {
      $ctrl.enrolment = _.cloneDeep(modalResponse);
      $ctrl.onStudySubGroupCalendarSelectionToggle({
        enrolment: $ctrl.enrolment
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
      $ctrl.setPriorityMaps();
      $ctrl.updateNotifications();
      $ctrl.isThereStudyRightMatchingPlan();
      // do not re-evaluate visibility, we don't want to lose the cur-box from the ui just yet
    },

    isButtonVisible() {
      if (!$ctrl.enrolmentPossibleForCur()) {
        return false;
      }
      return $ctrl.enrolment.state === EnrolmentState.PROCESSING &&
      // Immediately after enrol on late or continuous period, the enrolment is in PROCESSING state. Update should be
      // disabled during this.
      !$ctrl.courseUnitRealisation.continuousEnrolment && $ctrl.enrolmentPeriodOngoing();
    },
    isButtonEnabled() {
      return $ctrl.updateAction.isButtonVisible();
    }
  };
  $ctrl.cancelAction = {
    buttonClick() {
      enrolmentModalService.openCancelEnrolmentModal($ctrl.enrolment, $ctrl.courseUnitRealisation, $ctrl.courseUnit).then(modalResponse => {
        alertsService.dismissAlertIfExists(`guidance-alert-${$ctrl.courseUnitRealisation.id}`);
        $ctrl.cancelAction.afterCancel(modalResponse);
      }).catch($ctrl.enrolmentModalErrorHandler);
    },
    afterCancel(modalResponse) {
      $ctrl.enrolment = modalResponse.enrolment;
      // answers should not exist anymore
      delete $ctrl.enrolmentQuestionnaireAnswers;
      $ctrl.setPriorityMaps();
      $ctrl.updateNotifications();
      // do not re-evaluate visibility, we don't want to lose the cur-box from the ui just yet
    },

    isButtonVisible() {
      if (!$ctrl.enrolmentPossibleForCur()) {
        return false;
      }
      if (!_.includes([EnrolmentState.PROCESSING, EnrolmentState.INVALID, EnrolmentState.ENROLLED], $ctrl.enrolment.state)) {
        return false;
      }
      // on continuous enrolment you can cancel between cur.enrolmentPeriod.start and cur.activityPeriod.start.
      if ($ctrl.courseUnitRealisation.continuousEnrolment) {
        return $ctrl.isEnrolmentPeriodStarted() && $ctrl.curActivityHasNotStarted();
      }
      if ($ctrl.enrolmentPeriodOngoing()) {
        return true;
      }
      // Now must be 'additional cancellation' period if isCancellationPeriodOngoing returns true
      return $ctrl.isCancellationPeriodOngoing();
    },
    isButtonEnabled() {
      if (!$ctrl.cancelAction.isButtonVisible()) {
        return false;
      }
      if ($ctrl.courseUnitRealisation.continuousEnrolment) {
        return true;
      }
      if ($ctrl.enrolmentPeriodOngoing()) {
        return true;
      }
      return $ctrl.areCalculationResultsConfirmed() && $ctrl.isCancellationPeriodOngoing();
    }
  };
  $ctrl.abortAction = {
    buttonClick() {
      enrolmentModalService.openAbortEnrolmentModal($ctrl.enrolment, $ctrl.courseUnitRealisation, $ctrl.courseUnit).then($ctrl.abortAction.afterAbort).catch($ctrl.enrolmentModalErrorHandler);
    },
    afterAbort(modalResponse) {
      $ctrl.enrolment = modalResponse.enrolment;
      // answers should not exist anymore
      $ctrl.setPriorityMaps();
      $ctrl.updateNotifications();
      _.forEach($ctrl.enrolment.studySubGroups, studySubGroup => {
        studySubGroup.isInCalendar = false;
      });
      $ctrl.onStudySubGroupCalendarSelectionToggle({
        enrolment: $ctrl.enrolment
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
      $ctrl.evaluateVisibility();
    },
    isButtonVisible() {
      if (!$ctrl.enrolmentPossibleForCur()) {
        return false;
      }
      if (EnrolmentState.ENROLLED !== $ctrl.enrolment.state || !enrolmentPeriodDateService.activityPeriodOnGoing($ctrl.courseUnitRealisation)) {
        return false;
      }
      return !$ctrl.cancelAction.isButtonVisible() && ($ctrl.areCalculationResultsConfirmed() || $ctrl.courseUnitRealisation.continuousEnrolment);
    }
  };
  $ctrl.modifyGroupsAction = {
    isButtonVisible() {
      if (!$ctrl.enrolmentPossibleForCur()) {
        return false;
      }
      if (!$ctrl.courseUnitRealisation.confirmedStudySubGroupModificationAllowed || $ctrl.enrolment.state !== 'ENROLLED') {
        return false;
      }
      const dateTime = enrolmentValidationService.resolveConfirmedStudySubGroupModificationEnd($ctrl.courseUnitRealisation);
      return isDefinedAndInFuture(dateTime);
    },
    buttonClick() {
      $ctrl.modalOpen = true;
      const modalRef = modalService.open(EnrolmentWizardComponent, {
        enrolment: cloneDeep($ctrl.enrolment),
        isUpdate: true,
        isConfirmedSsgEdit: true
      }, {
        size: 'lg'
      });
      modalRef.result.then(response => {
        // Parent component will inject to js-data and refresh allocation counts
        $ctrl.onModifyGroups({
          newEnrolment: response
        });
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler).finally(() => {
        $timeout(() => {
          $ctrl.modalOpen = false;
        });
      });
    }
  };
  $ctrl.enrolmentPossibleForCur = () => !$ctrl.courseUnitRealisation.usesExternalEnrolment && $ctrl.enrolmentPossibleForUniversity() && !$ctrl.isCancelledCourseUnitRealisation();
  $ctrl.enrolmentPeriodOngoing = () => enrolmentPeriodDateService.enrolmentPeriodOngoing($ctrl.courseUnitRealisation);
  $ctrl.lateEnrolmentPeriodOngoing = () => enrolmentPeriodDateService.lateEnrolmentPeriodOngoing($ctrl.courseUnitRealisation);
  $ctrl.continuousEnrolmentPeriodOngoing = () => !!$ctrl.courseUnitRealisation.continuousEnrolment && $ctrl.enrolmentPeriodOngoing();
  $ctrl.lateOrContinuousPeriodOngoing = () => $ctrl.lateEnrolmentPeriodOngoing() || $ctrl.continuousEnrolmentPeriodOngoing();
  $ctrl.isEnrolmentPeriodStarted = () => enrolmentPeriodDateService.enrolmentPeriodStarted($ctrl.courseUnitRealisation);
  $ctrl.hasStudyEvents = studySubGroup => _.size(_.get(studySubGroup, 'studyEvents', [])) > 0;
  $ctrl.areCalculationResultsConfirmed = () => _.get($ctrl.enrolmentCalculationResult, 'enrolmentCalculationState', '') === EnrolmentCalculationState.CONFIRMED;
  $ctrl.maxSelectedIsNull = function () {
    return _.isNil($ctrl.enrolmentCalculationResult?.enrolmentAllocationCounts?.maxSelected);
  };
  $ctrl.showQuestionnaireLink = function () {
    return !$ctrl.enrolAction.isButtonVisible() && !$ctrl.updateAction.isButtonVisible() && (!$ctrl.cancelAction.isButtonVisible() || $ctrl.cancelAction.isButtonVisible() && $ctrl.cancelAction.isButtonEnabled()) && !$ctrl.enrolmentEndedAndNotEnrolled();
  };
  $ctrl.populateEnrolmentQuestionnaireData = function () {
    function hasNoQuestions() {
      return _.isEmpty(_.get($ctrl.enrolmentQuestionnaire, 'enrolmentQuestions'));
    }
    function populateAnswers() {
      if (hasNoQuestions()) {
        // Don't create answers if there are no questions as some other actions will try to POST/PUT those unnecessarily.
        return;
      }
      if (!$ctrl.enrolmentQuestionnaireAnswers) {
        $ctrl.enrolmentQuestionnaireAnswers = {
          enrolmentId: $ctrl.enrolment.id,
          courseUnitRealisationId: $ctrl.courseUnitRealisation.id,
          studentId: $ctrl.enrolment.personId
        };
      }
      let {
        answers
      } = $ctrl.enrolmentQuestionnaireAnswers;
      if (_.isEmpty($ctrl.enrolmentQuestionnaireAnswers.answers)) {
        answers = [];
      }
      const missingAnswers = _.chain($ctrl.enrolmentQuestionnaire.enrolmentQuestions).reject(question => _.find(answers, {
        questionId: question.localId
      })).map(question => ({
        questionId: question.localId,
        selections: [],
        answerText: ''
      })).value();
      $ctrl.enrolmentQuestionnaireAnswers.answers = answers.concat(missingAnswers);
    }
    return commonEnrolmentQuestionnaireService.findQuestionnaireByCourseUnitRealisationId($ctrl.courseUnitRealisation.id, false).then(result => {
      $ctrl.enrolmentQuestionnaire = result;
      if (hasNoQuestions()) {
        return undefined;
      }
      return commonEnrolmentQuestionnaireService.findQuestionnaireAnswersByEnrolmentId($ctrl.enrolment.id);
    }).then(answers => {
      $ctrl.enrolmentQuestionnaireAnswers = answers;
      populateAnswers();
    }, populateAnswers).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
  };
  $ctrl.canRemoveFromCalendar = function () {
    return !_.includes(['ENROLLED', 'PROCESSING', 'INVALID'], $ctrl.enrolment.state) || $ctrl.isCancelledCourseUnitRealisation();
  };
  $ctrl.getOrderedStudyEvents = studyEvents => commonStudyEventService.orderStudyEventsByTime(studyEvents);
  $ctrl.getSgsRuleTranslationKey = sgs => enrolmentValidationService.getSgsSelectionRuleTranslationKey(sgs);
  $ctrl.getSgsRuleTranslationValues = sgs => enrolmentValidationService.getSgsSelectionRuleTranslationValues(sgs, $ctrl.studySubGroupPriorityMap);
  $ctrl.areAllStudySubGroupsMandatory = function (sgs) {
    const min = _.get(sgs, 'subGroupRange.min');
    if (!_.isNil(min)) {
      return min === _.size(sgs.studySubGroups);
    }
    return false;
  };

  /**
           * $timeout will fire it's function argument in scope.$apply block. This will also fire digest cycle and
           * that causes button disable/visible functions to be evaluated. This will ensure that disable/hide is not
           * late for 10 seconds.
           */
  $ctrl.initTimers = () => {
    if (!enrolmentEndTimer) {
      enrolmentEndTimer = setTimer(_.get($ctrl.enrolmentPeriodDates, 'endDateTime'));
    }
    if (!cancellationEndTimer) {
      cancellationEndTimer = setTimer(_.get($ctrl.enrolmentPeriodDates, 'cancellationEndDateTime'));
    }
  };
  $ctrl.$onDestroy = () => {
    if (enrolmentEndTimer) {
      $timeout.cancel(enrolmentEndTimer);
    }
    if (cancellationEndTimer) {
      $timeout.cancel(cancellationEndTimer);
    }
  };
  function setTimer(dateTime) {
    if (!dateTime) {
      // Can not set timer for no date time
      return undefined;
    }
    const millis = moment.duration(moment(dateTime).diff(moment())).asMilliseconds();
    // Do not set timeout for negative millis
    return millis > 0 ? $timeout($ctrl.initTimers, millis) : undefined;
  }
  $ctrl.showFailedRequirementRules = function () {
    return hasValidStatesForFailedRequirementRules() && hasFailedRequirementPersonRulesText();
  };
  const hasValidStatesForFailedRequirementRules = () => $ctrl.enrolment.state === EnrolmentState.PROCESSING && $ctrl.enrolment.processingState === ProcessingState.REQ_NOT_FULFILLED || $ctrl.enrolment.state === EnrolmentState.REJECTED && $ctrl.enrolment.processingState === ProcessingState.NOT_SELECTED;
  const hasFailedRequirementPersonRulesText = () => _.get($ctrl.enrolmentCalculationResultText, 'failedRequirementPersonRulesText') && !_.isEmpty($ctrl.enrolmentCalculationResultText.failedRequirementPersonRulesText);
  $ctrl.showOrderingRules = function () {
    return _.get($ctrl.enrolmentCalculationResultText, 'orderingPersonRulesText') && !_.isEmpty($ctrl.enrolmentCalculationResultText.orderingPersonRulesText);
  };
  $ctrl.showEnrolmentPeriodStartTimeInfo = () => isDefinedAndInFuture(_.get($ctrl.enrolmentPeriodDates, 'startDateTime'));
  $ctrl.curActivityHasNotStarted = () => isDefinedAndInFuture(_.get($ctrl.courseUnitRealisation, 'activityPeriod.startDate'));
  $ctrl.showSelectionsConfirmedOnInfo = () => isDefinedAndInFuture(_.get($ctrl.enrolmentPeriodDates, 'endDateTime')) && !($ctrl.continuousEnrolmentPeriodOngoing() && $ctrl.enrolment.state === 'ENROLLED');
  $ctrl.showAnswersModifiableUntilInfo = () => isDefinedAndInFuture(_.get($ctrl.enrolmentPeriodDates, 'endDateTime')) && !($ctrl.continuousEnrolmentPeriodOngoing() && $ctrl.enrolment.state === 'ENROLLED');
  $ctrl.showAnswersModifiableUntilInfoNotEnrolled = () => isDefinedAndInFuture(_.get($ctrl.enrolmentPeriodDates, 'endDateTime')) && !$ctrl.continuousEnrolmentPeriodOngoing() && $ctrl.enrolment.state !== EnrolmentState.ENROLLED;
  $ctrl.isCancellationPeriodOngoing = () => isDefinedAndInFuture(_.get($ctrl.enrolmentPeriodDates, 'cancellationEndDateTime'));
  $ctrl.isCancelledCourseUnitRealisation = () => _.get($ctrl.courseUnitRealisation, 'flowState') === 'CANCELLED';
  function hasEnrolmentPeriodEnded() {
    const endDateTime = _.get($ctrl.enrolmentPeriodDates, 'endDateTime');
    return !!endDateTime && moment().isAfter(endDateTime);
  }
  $ctrl.enrolmentEndedAndNotEnrolled = function () {
    return $ctrl.enrolment.state === EnrolmentState.NOT_ENROLLED && hasEnrolmentPeriodEnded();
  };
  $ctrl.enrolmentModalErrorHandler = function (error) {
    // Revert enrolment if error happens. User might have done modifications that did not pass validation or
    // something else.
    commonEnrolmentService.revert($ctrl.enrolment);
    if (_.get($ctrl.enrolmentQuestionnaireAnswers, 'metadata')) {
      commonEnrolmentQuestionnaireService.revertQuestionnaireAnswers($ctrl.enrolmentQuestionnaireAnswers);
    } else {
      // these were created before opening modal so just delete
      delete $ctrl.enrolmentQuestionnaireAnswers;
    }
    defaultPromiseHandler.loggingRejectedPromiseHandler(error);
  };
  $ctrl.isExternalEnrolmentButtonVisible = function () {
    return $ctrl.courseUnitRealisation.usesExternalEnrolment;
  };
  $ctrl.getExternalEnrolmentLink = function () {
    if (_.get($ctrl.courseUnitRealisation, 'externalEnrolmentLink.url')) {
      const url = localeService.getLocalizedValue($ctrl.courseUnitRealisation.externalEnrolmentLink.url);
      return url || '';
    }
    // If no valid url exist, retun empty string.
    return '';
  };
  $ctrl.isExternalEnrolmentButtonDisabled = function () {
    return !$ctrl.courseUnitRealisation.externalEnrolmentLink;
  };
  $ctrl.hasExternalEnrolmentLinkLabel = function () {
    return _.get($ctrl.courseUnitRealisation, 'externalEnrolmentLink.label');
  };
  $ctrl.openQuestionnaire = function () {
    $ctrl.populateEnrolmentQuestionnaireData().then(() => enrolmentModalService.openEnrolmentQuestionnaireModal($ctrl.enrolmentQuestionnaire, $ctrl.enrolmentQuestionnaireAnswers, $ctrl.courseUnitRealisation, $ctrl.courseUnit)).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
  };

  // Redirects to calendar view (from front page) and forces this cur box to be expanded (and correct filters)
  $ctrl.showInCalendarView = () => {
    uiStateStore.storeField('otm.student.calendar.courseUnitRealisationItem.', 'expanded', [$ctrl.courseUnitRealisation.id]);
    const filterKeyForEnrolment = enrolmentService.getFilterKeyForEnrolment($ctrl.enrolment, $ctrl.courseUnitRealisation);
    const activeFilters = ['NOT_ENROLLED', 'ENROLMENT_NOT_STARTED', 'PROCESSING', 'ENROLLED', 'REJECTED'];
    if (!_.includes(activeFilters, filterKeyForEnrolment)) {
      $state.go('student.logged-in.calendar.aborted-and-finished-enrolments');
      return;
    }
    const filters = uiStateStore.readField('student.calendar.calendarEnrolments.', 'filters', enrolmentService.getDefaultCalendarFilterStates());
    filters[filterKeyForEnrolment] = true;
    uiStateStore.storeField('student.calendar.calendarEnrolments.', 'filters', filters);
    $state.go('student.logged-in.calendar.enrolments');
  };
  $ctrl.clickCourseUnitInfoLink = $event => {
    switch ($event.button) {
      // Main button (usually mouse's left button)
      case 0:
        $ctrl.openCourseUnitInfo();
        break;
      // Auxiliary button (usually the wheel or middle button)
      case 1:
      default:
        break;
    }
  };
}