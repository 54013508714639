import {
    ChangeDetectionStrategy,
    Component, computed, DestroyRef, inject,
    Input, OnInit,
    Signal,
    signal,
    ViewEncapsulation,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ValidatablePlan } from 'common-typescript';
import { PlanValidationResult } from 'common-typescript/src/plan/validation/planValidationResult';
import { CourseUnitCountRule, EntityWithRule, OtmId } from 'common-typescript/types';
import _ from 'lodash';

import { PlanRuleData } from '../../../service/plan-rule-data.service';
import { PlanData, PlanStateObject } from '../../../service/plan-state.service';
import { RuleClearSignalService } from '../rule-clear-signal.service';

@Component({
    selector: 'sis-plan-structure-course-unit-count-rule',
    templateUrl: './plan-structure-course-unit-count-rule.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStructureCourseUnitCountRuleComponent implements OnInit {
    ruleClearSignalService: RuleClearSignalService = inject(RuleClearSignalService);
    destroyRef: DestroyRef = inject(DestroyRef);

    _rule: WritableSignal<CourseUnitCountRule> = signal(undefined);
    @Input({ required: true }) set rule(rule: CourseUnitCountRule) {
        this._rule.set(rule);
    }

    _parentModule: WritableSignal<EntityWithRule> = signal(undefined);
    @Input({ required: true }) set parentModule(parentModule: EntityWithRule) {
        this._parentModule.set(parentModule);
    }

    _planValidationResult: WritableSignal<PlanValidationResult> = signal(undefined);
    @Input({ required: true }) set planValidationResult(planValidationResult: PlanValidationResult) {
        this._planValidationResult.set(planValidationResult);
    }

    @Input({ required: true }) planStateObject: PlanStateObject;
    @Input({ required: true }) planData: PlanData;
    @Input({ required: true }) planRuleData: PlanRuleData;
    @Input({ required: true }) validatablePlan: ValidatablePlan;
    @Input({ required: true }) ruleDepthLevel: number;
    @Input({ required: true }) headingLevel: number;
    @Input({ required: true }) groupPrefix: string;
    @Input() selectionsDisabled: boolean;

    ruleLegendId: Signal<string> = computed(this.ruleLegendIdComputation());
    ruleValidationResults: Signal<{ [id: string]: { [id: string]: any } }> = computed(this.ruleValidationResultsComputation());

    ngOnInit(): void {
        this.ruleClearSignalService.getClearSignal()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((localId) => {
                if (this._rule().localId === localId) {
                    this.ruleClearSignalService.sendClearSignal(this._rule().rule.localId);
                }
            });
    }

    ruleLegendIdComputation(): () => string {
        return () => (`rule-legend-${this._rule().localId}`);
    }

    ruleValidationResultsComputation(): () => { [id: string]: { [id: string]: any } } {
        return () => this.getValidationResultsForRule(
            this._planValidationResult()?.ruleValidationResults,
            this._parentModule().id,
            this._rule()?.localId);
    }

    getValidationResultsForRule(ruleValidationResults: any, parentModuleId: OtmId, ruleLocalId: OtmId): any {
        return _.get(ruleValidationResults,
                     [parentModuleId, ruleLocalId]);
    }
}
