import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxFudisModule } from '@funidata/ngx-fudis';
import { TranslateModule } from '@ngx-translate/core';
import { SisCommonModule } from 'sis-common/sis-common.module';

import { SisComponentsModule } from '../../../sis-components.module';

import { PriorLearningWorkflowSummaryComponent } from './prior-learning-workflow-summary/prior-learning-workflow-summary.component';

@NgModule({
    declarations: [
        PriorLearningWorkflowSummaryComponent,
    ],
    imports: [
        CommonModule,
        SisComponentsModule,
        SisCommonModule,
        TranslateModule.forChild(),
        NgxFudisModule,
    ],
    exports: [
        PriorLearningWorkflowSummaryComponent,
    ],
})
export class PriorLearningWorkflowModule { }
