import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { Education, LocalDateString, OtmId, StudyRight, StudyRightExtensionApplication } from 'common-typescript/types';
import _ from 'lodash';
import { forkJoin, from, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AUTH_SERVICE, DEFAULT_PROMISE_HANDLER } from 'sis-common/ajs-upgraded-modules';
import { ModalService } from 'sis-common/modal/modal.service';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';
import { COMMON_STUDY_RIGHT_SERVICE } from 'sis-components/ajs-upgraded-modules';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { EducationEntityService } from 'sis-components/service/education-entity.service';
import { StudyRightEntityService } from 'sis-components/service/study-right-entity.service';
import { UniversityService } from 'sis-components/service/university.service';
import { convertAJSPromiseToNative } from 'sis-components/util/utils';

import { STUDY_RIGHT_EXTENSION_REQUIREMENTS_SERVICE } from '../../../ajs-upgraded-modules';
import { CreateStudyCertificateModalComponent } from '../create-study-certificate-modal/create-study-certificate-modal.component';

export interface ExpirationInfo {
    isExpired: boolean;
    studyRightId: OtmId;
    inclusiveEndDate: LocalDateString;
    pendingExtensionApplication?: StudyRightExtensionApplication;
}

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'app-study-rights',
    templateUrl: './study-rights.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class StudyRightsComponent implements OnInit, OnDestroy {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.profile.studyRights',
        directiveName: 'appStudyRights',
    };

    studyRights: StudyRight[] = [];
    studyRightExpirations: { [index: string]: any } = {};
    educations: { [educationId: string]: Education } = {};
    degreeStudyRights: StudyRight[] = [];
    openUniversityStudyRights: StudyRight[] = [];
    oldOpenUniversityStudyRights: StudyRight[] = [];
    otherStudyRights: StudyRight[] = [];
    ready = false;
    studyRightExtensionApplicationEnabledByUniversity = false;
    destroyed$ = new Subject<void>();
    openUniversityEducationUrn = 'urn:code:education-type:non-degree-education:open-university-studies';
    degreeEducationUrn = 'urn:code:education-type:degree-education';

    constructor(
        private studyRightEntityService: StudyRightEntityService,
        private educationEntityService: EducationEntityService,
        private universityService: UniversityService,
        private stateService: StateService,
        private modalService: ModalService,
        private appErrorHandler: AppErrorHandler,
        @Inject(AUTH_SERVICE) private AuthService: any,
        @Inject(DEFAULT_PROMISE_HANDLER) private defaultPromiseHandler: any,
        @Inject(STUDY_RIGHT_EXTENSION_REQUIREMENTS_SERVICE) private studyRightExtensionRequirementsService: any,
        @Inject(COMMON_STUDY_RIGHT_SERVICE) private commonStudyRightService: any,
    ) { }

    ngOnInit(): void {
        this.universityService.getCurrentUniversitySettings()
            .pipe(
                takeUntil(this.destroyed$),
                this.appErrorHandler.defaultErrorHandler())
            .subscribe((universitySettings) => {
                const extensionEnabled = _.get(universitySettings, 'frontendFeatureToggles.studyRightExtensionApplicationEnabled');
                this.studyRightExtensionApplicationEnabledByUniversity = extensionEnabled !== false;
            });

        this.studyRightEntityService.getStudyRightsForCurrentUser()
            .pipe(
                this.appErrorHandler.defaultErrorHandler(),
                takeUntil(this.destroyed$),
            )
            .subscribe({
                next: (studyRights) => {
                    const educationIds = _.uniq(studyRights.map(sr => sr.educationId));
                    forkJoin({
                        expirationInfos: from(convertAJSPromiseToNative(this.commonStudyRightService.getStudyRightExpirationInfosForStudyRights(studyRights) as Promise<ExpirationInfo[]>)).pipe(take(1)),
                        educations: this.educationEntityService.getByIds(educationIds).pipe(take(1)),
                    })
                        .pipe(this.appErrorHandler.defaultErrorHandler())
                        .subscribe({
                            next: ({ expirationInfos, educations }) => {
                                expirationInfos.forEach(expirationInfo => this.studyRightExpirations[expirationInfo.studyRightId] = expirationInfo);
                                educations.forEach(education => this.educations[education.id] = education);
                                this.filterAndSortStudyRights(studyRights);
                                this.ready = true;
                            },
                        });
                },
            });
    }

    filterAndSortStudyRights(studyRights: StudyRight[]): void {
        this.degreeStudyRights = this.sortStudyRightsByStartDate(this.getDegreeStudyRights(studyRights));
        this.openUniversityStudyRights = this.sortStudyRightsByStartDate(this.getOpenUniversityStudyRights(studyRights, true));
        this.oldOpenUniversityStudyRights = this.sortStudyRightsByStartDate(this.getOpenUniversityStudyRights(studyRights, false));
        this.otherStudyRights = this.sortStudyRightsByStartDate(this.getOtherStudyRights(studyRights));
    }

    getDegreeStudyRights(studyRights: StudyRight[]): StudyRight[] {
        return studyRights.filter(studyRight =>
            this.checkEducationType(studyRight, this.degreeEducationUrn));
    }

    getOpenUniversityStudyRights(studyRights: StudyRight[], basedOnEnrolmentRights: boolean): StudyRight[] {
        return studyRights.filter(studyRight =>
            this.checkEducationType(studyRight, this.openUniversityEducationUrn) &&
            studyRight.basedOnEnrolmentRights === basedOnEnrolmentRights);
    }

    getOtherStudyRights(studyRights: StudyRight[]): StudyRight[] {
        return studyRights.filter(studyRight =>
            !this.checkEducationType(studyRight, this.degreeEducationUrn) &&
                !this.checkEducationType(studyRight, this.openUniversityEducationUrn));
    }

    sortStudyRightsByStartDate(studyRights: StudyRight[]): StudyRight[] {
        return _.sortBy(studyRights, 'valid.startDate');
    }

    checkEducationType(studyRight: StudyRight, educationType: string): boolean {
        return this.educations[studyRight.educationId]?.educationType.includes(educationType) ?? false;
    }

    openCreateStudyCertificateModal() {
        from(this.modalService.open(CreateStudyCertificateModalComponent, null).result)
            .subscribe({ error: this.defaultPromiseHandler.loggingRejectedPromiseHandler });
    }

    initStudyRightExtensionApplication(studyRightId: OtmId) {
        const userId = this.AuthService.personId();
        const studyRightExpirationInfo = this.getStudyRightExpiration(studyRightId);
        this.studyRightExtensionRequirementsService.checkRequirementsAndProceedToApplication(userId, studyRightExpirationInfo)
            .then((result: { target: string; planId: string; studyRightId: string }) => {
                switch (result.target) {
                    case 'CREATE_APPLICATION':
                        this.stateService.go(
                            'student.logged-in.profile.applications.create-study-right-extension-application',
                            { planId: result.planId, studyRightId: result.studyRightId },
                        );
                        break;
                    case 'PLAN':
                        this.stateService.go('student.logged-in.structure', { planId: result.planId });
                        break;
                    case 'TIMING':
                        this.stateService.go('student.logged-in.plan.timing', { planId: result.planId });
                        break;
                    default:
                        break;
                }
            })
            .catch(this.defaultPromiseHandler.loggingRejectedPromiseHandler);
    }

    getStudyRightExpiration(studyRightId: OtmId): ExpirationInfo {
        return this.studyRightExpirations[studyRightId];
    }

    openStudyRightExtensionApplication(studyRightId: OtmId) {
        const applicationId = this.getStudyRightExpiration(studyRightId)?.pendingExtensionApplication?.id;
        if (applicationId) {
            this.stateService.go('student.logged-in.profile.applications.study-right-extension-application', { applicationId });
        }
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
    }
}
