import {
    HttpErrorResponse,
    HttpEvent,
    HttpEventType,
    HttpHandler,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { catchError, concatMap, mergeMap } from 'rxjs/operators';

import { AuthService } from '../auth/auth-service';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from '../types/angular-hybrid';

@StaticMembers<DowngradedService>()
@Injectable({
    providedIn: 'root',
})
export class AuthTokenInterceptorService {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'common.authTokenInterceptorService',
        serviceName: 'authTokenInterceptorService',
    };

    constructor(private authService: AuthService) { }
    handleRequest(req: HttpRequest<any>, next: HttpHandler) {
        if (this.authService.isAnonymousUrl(req.url) || this.authService.isPreAuth(req.url)) {
            return next.handle(req);
        }

        /**
         when making a "presignedUrl" -request to minio (file upload/download), it can't have the "Authorization bearer" -header,
         because it conflicts with minios own request headers(statusCode: 400)
         */
        if (req.headers.get('isPreSignedUrl')) {
            return next.handle(req.clone({
                headers: req.headers.delete('isPreSignedUrl'),
            }));
        }
        return this.authService.refreshAuthToken().pipe(
            mergeMap((authToken: any) => {
                if (!authToken) {
                    return next.handle(req);
                }
                return next.handle(req.clone({ setHeaders: { Authorization: `Bearer ${authToken}` } }));
            }),
            catchError((error) => {
                throw error;
            }),
        );
    }

    /**
     * Handles server HTTP responses with status 419 (Authentication Timeout).
     * It refreshes the authentication token and then re-sends the failed HTTP request once.
     */
    handleResponse(req: HttpRequest<any>, next: HttpHandler) {
        return next.handle(req)
            .pipe(
                concatMap((event: HttpEvent<any>) => {
                    // Handle retries for graphql requests
                    if (req.url === '/api' && this.shouldRetryGraphqlRequest(event)) {
                        return this.retry(req, next);
                    }
                    return of(event);
                }),
                catchError((error) => {
                    // If the token has expired, retry with a new one
                    if ((error instanceof HttpErrorResponse) &&
                        (error as HttpErrorResponse).status === 419) {
                        return this.retry(req, next);
                    }
                    throw error;
                }),
            );
    }

    retry(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.authService.refreshAuthToken(true))
            .pipe(
                mergeMap((authToken) =>
                    next.handle(req.clone({ setHeaders: { Authorization: `Bearer ${authToken}` } }))),
            );
    }

    /**
     * Returns true if graphql request response contains errors with status 419.
     * If there is one error the whole batch will be retried.
     */
    shouldRetryGraphqlRequest(event: HttpEvent<any>): boolean {
        if (event.type === HttpEventType.Response &&
            event.status === 200 &&
            event.body) {
            return this.getErrors(event).some(e => e.extensions?.response?.status === 419);
        }
        return false;
    }

    getErrors(response: HttpResponse<any>): any[] {
        if (Array.isArray(response.body)) {
            return response.body.flatMap(body => body.errors).filter(item => item);
        }
        if (response.body.errors) {
            return response.body.errors;
        }
        return [];
    }
}
