import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
export const attainmentTreeDataMapperModule = 'sis-components.attainment.tree';
(function () {
  angular.module(attainmentTreeDataMapperModule).factory('attainmentTreeDataMapper', ["commonStudentAttainmentService", "localeService", "AttainmentTypes", "WarningLimits", (commonStudentAttainmentService, localeService, AttainmentTypes, WarningLimits) => {
    // default children behaviour
    const childrenHidden = false;
    return {
      /**
       * Creates a tree for each non attached module/degree programme attainment in attainments list.
       * 'Is attainment attached' is checked against union of attainments and validAttainments, a.k.a
       * allAttainments. Furthermore all tree branches are fetched from allAttainments.
       */
      modules(attainments, validAttainments) {
        const allAttainments = _.unionBy(attainments, validAttainments, 'id');
        const modules = _.concat(getAttainmentsByType(attainments, AttainmentTypes.MODULE_ATTAINMENT), getAttainmentsByType(attainments, AttainmentTypes.DEGREE_PROGRAMME_ATTAINMENT));
        const moduleAttainmentsWithoutModule = _.filter(modules, moduleAtt => !isAttached(allAttainments, moduleAtt));
        const tree = [];
        _.each(moduleAttainmentsWithoutModule, moduleAttainment => {
          const treeModule = makeScopeObject(validAttainments, moduleAttainment, {
            name: moduleAttainment.module.name,
            code: moduleAttainment.module.code,
            moduleGroupId: moduleAttainment.moduleGroupId,
            type: 'parent',
            hide: childrenHidden
          });
          treeModule.attainments = getAttainmentsFromNodes(allAttainments, validAttainments, moduleAttainment.nodes);
          tree.push(treeModule);
        });
        return tree;
      },
      /**
      * Creates a tree for each non attached custom module attainment in attainments list.
      * 'Is attainment attached' is checked against union of attainments and validAttainments, a.k.a
      * allAttainments. Furthermore all tree branches are fetched from allAttainments.
      */
      customModules(attainments, validAttainments) {
        const allAttainments = _.unionBy(attainments, validAttainments, 'id');
        const modules = getAttainmentsByType(attainments, AttainmentTypes.CUSTOM_MODULE_ATTAINMENT);
        const moduleAttainmentsWithoutModule = _.filter(modules, moduleAtt => !isAttached(allAttainments, moduleAtt));
        const tree = [];
        _.each(moduleAttainmentsWithoutModule, moduleAttainment => {
          const treeModule = makeScopeObject(validAttainments, moduleAttainment, {
            name: moduleAttainment.name,
            code: moduleAttainment.code,
            type: 'parent',
            hide: childrenHidden
          });
          treeModule.attainments = getAttainmentsFromNodes(allAttainments, validAttainments, moduleAttainment.nodes);
          tree.push(treeModule);
        });
        return tree;
      },
      /**
      * Creates a tree for each non attached course unit attainment in attainments list.
      * 'Is attainment attached' is checked against union of attainments and validAttainments, a.k.a
      * allAttainments. Furthermore all tree branches are fetched from allAttainments.
      */
      courseUnits(attainments, validAttainments) {
        const allAttainments = _.unionBy(attainments, validAttainments, 'id');
        const courseUnitsAtt = getAttainmentsByType(attainments, AttainmentTypes.COURSE_UNIT_ATTAINMENT);
        const courseUnitsAttainmentsWithoutModule = _.filter(courseUnitsAtt, courseUnitAtt => !isAttached(allAttainments, courseUnitAtt));
        const tree = [];
        _.each(courseUnitsAttainmentsWithoutModule, attainment => {
          const scopeObject = makeScopeObject(validAttainments, attainment, {
            name: attainment.courseUnit.name,
            courseUnitGroupId: attainment.courseUnitGroupId,
            code: attainment.courseUnit.code,
            type: 'parent',
            hide: childrenHidden
          });
          scopeObject.attainments = getAssessmentItems(allAttainments, validAttainments, attainment.assessmentItemAttainmentIds);
          tree.push(scopeObject);
        });
        return tree;
      },
      /**
      * Creates a tree (depth of one, because custom course unit attainments do not have children) for each non
      * attached custom course unit attainment.
      * 'Is attainment attached' is checked against union of attainments and validAttainments, a.k.a
      * allAttainments. Furthermore all tree branches are fetched from allAttainments.
      */
      customCourseUnits(attainments, validAttainments) {
        const allAttainments = _.unionBy(attainments, validAttainments, 'id');
        const courseUnitsAtt = getAttainmentsByType(attainments, AttainmentTypes.CUSTOM_COURSE_UNIT_ATTAINMENT);
        const courseUnitsAttainmentsWithoutModule = _.filter(courseUnitsAtt, courseUnitAtt => !isAttached(allAttainments, courseUnitAtt));
        const tree = [];
        _.each(courseUnitsAttainmentsWithoutModule, attainment => {
          const scopeObject = makeScopeObject(validAttainments, attainment, {
            name: attainment.name,
            courseUnitGroupId: attainment.courseUnitGroupId,
            code: attainment.code,
            type: 'parent',
            hide: childrenHidden
          });
          scopeObject.attainments = [];
          tree.push(scopeObject);
        });
        return tree;
      },
      /**
      * Creates a template objects for each non attached assessment item attainment.
      * 'Is attainment attached' is checked against union of attainments and validAttainments, a.k.a
      * allAttainments. Furthermore all tree branches are fetched from allAttainments.
      */
      assessmentItems(attainments, validAttainments) {
        const allAttainments = _.unionBy(attainments, validAttainments, 'id');
        const assessmentItemAttainments = _.filter(getAttainmentsByType(attainments, AttainmentTypes.ASSESSMENT_ITEM_ATTAINMENT), attainment => !isAttached(allAttainments, attainment));
        const attainmentIds = _.map(assessmentItemAttainments, 'id');
        return getAssessmentItems(allAttainments, validAttainments, attainmentIds, true);
      }
    };
    function isAttached(attainments, attainment) {
      return commonStudentAttainmentService.isAttached(attainment, attainments);
    }
    function getAttainmentsByType(list, type) {
      return _.filter(list, {
        type
      });
    }
    function getAttainmentsFromNodes(attainments, validAttainments, nodes) {
      const scopeObjects = [];
      _.each(nodes, node => {
        if (node.type === 'AttainmentGroupNode') {
          const groupObject = {
            name: node.name,
            type: 'parent',
            attainments: getAttainmentsFromNodes(attainments, validAttainments, node.nodes)
          };
          scopeObjects.push(groupObject);
        } else if (node.type === 'AttainmentReferenceNode') {
          const attainment = getAttainment(attainments, node.attainmentId);
          if (!attainment) {
            // Might not be found. Currently in STUDENT, the misregistration = true attainments are not
            // passed to this service.
            return;
          }
          const scopeObject = makeScopeObject(validAttainments, attainment, {});
          let target;
          if (_.includes([AttainmentTypes.MODULE_ATTAINMENT, AttainmentTypes.DEGREE_PROGRAMME_ATTAINMENT], attainment.type)) {
            target = commonStudentAttainmentService.get('module', attainment.moduleId);
            scopeObject.type = 'parent';
            scopeObject.hide = childrenHidden;
            scopeObject.attainments = getAttainmentsFromNodes(attainments, validAttainments, attainment.nodes);
            scopeObject.moduleGroupId = target.moduleGroupId;
          } else if (attainment.type === AttainmentTypes.COURSE_UNIT_ATTAINMENT) {
            target = commonStudentAttainmentService.get('courseUnit', attainment.courseUnitId);
            scopeObject.type = 'child';
            scopeObject.courseUnitGroupId = target.courseUnitGroupId;
            scopeObject.courseUnitId = target.courseUnitId;
          } else if (attainment.type === AttainmentTypes.CUSTOM_MODULE_ATTAINMENT) {
            target = {
              name: attainment.name,
              code: attainment.code
            };
            scopeObject.type = 'parent';
            scopeObject.hide = childrenHidden;
            scopeObject.attainments = getAttainmentsFromNodes(attainments, validAttainments, attainment.nodes);
          } else if (attainment.type === AttainmentTypes.CUSTOM_COURSE_UNIT_ATTAINMENT) {
            target = {
              name: attainment.name,
              code: attainment.code
            };
            scopeObject.type = 'child';
          } else {
            target = commonStudentAttainmentService.getAISnapshotByAttainmentItem(attainment.id);
          }
          scopeObject.name = target.name;
          scopeObject.code = target.code;
          scopeObjects.push(scopeObject);
        }
      });
      return scopeObjects;
    }
    function getAssessmentItems(allAttainments, validAttainments, attainmentIds, withFullName) {
      const scopeObjects = [];
      _.each(attainmentIds, attainmentId => {
        const attainment = _.find(allAttainments, {
          id: attainmentId
        });
        if (attainment) {
          const {
            courseUnit
          } = attainment;
          const assessmentItem = commonStudentAttainmentService.getAISnapshotByAttainmentItem(attainment.id);
          let scopeObject;
          if (courseUnit !== false) {
            scopeObject = makeScopeObject(validAttainments, attainment, {
              code: courseUnit.code,
              type: 'child'
            });
            scopeObject.name = localeService.concatLocalized(courseUnit.name, ', ', assessmentItem.name);
            scopeObjects.push(scopeObject);
          } else if (assessmentItem !== false) {
            scopeObject = makeScopeObject(validAttainments, attainment, {
              code: '',
              type: 'child'
            });
            if (withFullName === true) {
              scopeObject.name = assessmentItem.fullName;
            } else {
              scopeObject.name = assessmentItem.name;
            }
            scopeObjects.push(scopeObject);
          }
        }
      });
      return scopeObjects;
    }
    function getAttainment(attainments, id) {
      return _.find(attainments, {
        id
      });
    }
    function getGrade(attainment) {
      const gradeScale = commonStudentAttainmentService.get('gradeScales', attainment.gradeScaleId);
      return _.find(gradeScale.grades, {
        localId: attainment.gradeId
      });
    }
    function isAboutToExpire(attainment) {
      const expiryWarningPeriodMonths = isModuleAttainment(attainment) ? WarningLimits.MODULE_EXPIRY_WARNING_PERIOD_MONTHS : WarningLimits.COURSE_UNIT_EXPIRY_WARNING_PERIOD_MONTHS;
      return _.get(attainment, 'expiryDate', null) !== null && moment(_.get(attainment, 'expiryDate')).subtract(expiryWarningPeriodMonths, 'months').isBefore(_.now()) && !isAttainmentExpired(attainment);
    }
    function isAttainmentExpired(attainment) {
      if (_.get(attainment, 'expiryDate')) {
        const today = moment();
        return !today.isBefore(attainment.expiryDate, 'days');
      }
      return false;
    }
    function isModuleAttainment(attainment) {
      return attainment.type === AttainmentTypes.MODULE_ATTAINMENT || attainment.type === AttainmentTypes.DEGREE_PROGRAMME_ATTAINMENT || attainment.type === AttainmentTypes.CUSTOM_MODULE_ATTAINMENT;
    }
    function makeScopeObject(validAttainments, attainment, overrides) {
      const scopeObject = {
        id: attainment.id,
        attainment,
        credits: attainment.credits,
        grade: getGrade(attainment),
        attainmentDate: attainment.attainmentDate,
        expiryDate: attainment.expiryDate,
        isAboutToExpire: isAboutToExpire(attainment),
        hasValidParentAttainment: isAttached(validAttainments, attainment),
        isValid: !!_.find(validAttainments, {
          id: attainment.id
        }),
        expired: isAttainmentExpired(attainment),
        primary: attainment.primary,
        misregistration: attainment.misregistration
      };
      return _.assign(scopeObject, overrides);
    }
  }]);
})();