<ng-container *transloco="let t">
  <div class="modal-header">
    <h2 sisAutofocus>{{ this.modalValues.module.name | localizedString }}</h2>
    <sis-modal-close-button/>
  </div>
  <ng-container *ngIf="this.anyRuleErrors$()">
    <div class="sis-mb-sm">
      <sis-plan-structure-rule-error-summary #errorSummary
                                             [tabIndex]="-1"
                                             [ruleErrorStates]="this.ruleErrorStateService.ruleErrorStates$()"
                                             (errorClick)="this.focusRule($event)">
      </sis-plan-structure-rule-error-summary>
    </div>
  </ng-container>
  <ng-container *ngIf="data$ | async as data">
    <div class="modal-body">
      <sis-plan-structure-select-rule [parentModule]="data.selectedModule"
                                      [headingLevel]="3"
                                      [rule]="data.selectedModule.rule"
                                      [planData]="data.planData"
                                      [planRuleData]="data.planRuleData"
                                      [planStateObject]="data.planStateObject"
                                      [planValidationResult]="data.planValidationResult"
                                      [validatablePlan]="data.validatablePlan"
                                      [selectionDisabled]="false"
                                      [groupPrefix]="''"
                                      [ruleDepthLevel]="1">
      </sis-plan-structure-select-rule>
    </div>
  </ng-container>
  <div class="modal-footer">
    <sis-button (clicked)="dismiss()"
                [hollow]="true">
      {{ t('SIS_COMPONENTS.BUTTON.CANCEL') }}
    </sis-button>
    <sis-button (clicked)="submitClick$.next()">
      {{ t('SIS_COMPONENTS.BUTTON.SAVE') }}
    </sis-button>
  </div>
</ng-container>

