import {
    ChangeDetectionStrategy,
    Component,
    Input,
    ViewEncapsulation,
} from '@angular/core';
import { PlanValidationResult } from 'common-typescript/src/plan/validation/planValidationResult';
import { Education } from 'common-typescript/types';

import { PlanData, PlanStateObject } from '../../service/plan-state.service';

@Component({
    selector: 'sis-plan-structure-education',
    templateUrl: './plan-structure-education.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStructureEducationComponent {

    @Input({ required: true }) education: Education;
    @Input({ required: true }) planValidationResult: PlanValidationResult;
    @Input({ required: true }) planStateObject: PlanStateObject;
    @Input({ required: true }) planData: PlanData;

    headingLevel: number = 2;
}
