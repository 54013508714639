LogService.$inject = ["globalErrorHandlerLoggerName"];
import angular from 'angular';
import _ from 'lodash';
export const logServiceModule = 'sis-common.errorhandler.logService';
angular.module(logServiceModule, []).factory('logService', LogService).constant('globalErrorHandlerLoggerName', 'GlobalException');

/** @ngInject */
function LogService(globalErrorHandlerLoggerName) {
  const levels = {
    CRITICAL: 0,
    ERROR: 1,
    LOG: 2,
    INFO: 3,
    DEBUG: 4
  };

  // Use these to configure logging
  let isLogActive = true;
  const logLevel = levels.CRITICAL;
  const matchers = [
  // These can be used to filter messages per level
  ['', levels.DEBUG]];
  // ---

  const levelNames = _.invert(levels);
  let timeBasis = 0;
  const service = {};
  function isActive(id, level) {
    if (!isLogActive || level > logLevel) {
      return false;
    }
    let i = 0;
    while (i < matchers.length) {
      const matcher = matchers[i][0];
      const itemLevel = matchers[i][1];
      const matcherLength = matcher.length;
      if (id.length >= matcherLength && id.substring(0, matcherLength) === matcher && level <= itemLevel) {
        return true;
      }
      i += 1;
    }
    return false;
  }
  function doLog(id, logObject, level) {
    const stamp = new Date().getTime() - timeBasis;
    const prefix = `${stamp} ${levelNames[level]} (${id})`;
    // eslint-disable-next-line no-console
    console.log.apply(console, [prefix, logObject]);
  }
  function maybeLog(id, logObject, level) {
    if (!timeBasis) {
      timeBasis = new Date().getTime();
    }
    if (isActive(id, level)) {
      doLog(id, logObject, level);
    }
  }
  function stringify(object) {
    // Stringify objects with circular references

    let cache = [];
    const result = JSON.stringify(object, (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (cache.indexOf(value) !== -1) {
          // Circular reference found, discard key
          return undefined;
        }
        // Store value in our collection
        cache.push(value);
      }
      return value;
    });
    cache = null; // Enable garbage collection
    return result;
  }
  service.init = function (defaultAjaxUrl) {
    // Set up global JSNLog
    JL.setOptions({
      defaultAjaxUrl,
      maxMessages: 5
    });

    // Set up all exception handlers
    JL().setOptions({
      onceOnly: ['"AbortError"', 'Aborting the connection']
    });

    // Set up global exception handler, at least EventSource errors go there
    JL(globalErrorHandlerLoggerName).setOptions({
      disallow: 'status 419'
    });
  };
  service.critical = function (id, logObject, error) {
    maybeLog(id, logObject, levels.CRITICAL);
    JL(id).fatalException(error ? logObject : stringify(logObject), error);
  };
  service.error = function (id, logObject) {
    maybeLog(id, logObject, levels.ERROR);
    const logObj = stringify(logObject);
    JL(id).error(logObj);
  };
  service.log = function (id, logObject) {
    maybeLog(id, logObject, levels.LOG);
    JL(id).trace();
  };
  service.info = function (id, logObject) {
    maybeLog(id, logObject, levels.INFO);
    JL(id).info(document.href);
  };
  service.debug = function (id, logObject) {
    maybeLog(id, logObject, levels.DEBUG);
    JL(id).debug();
  };
  service.activate = function () {
    isLogActive = true;
  };
  service.deactivate = function () {
    isLogActive = false;
  };
  return service;
}