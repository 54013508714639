import { Injectable } from '@angular/core';
import { Organisation, OtmId, UniversityOrganisation, UniversitySettings } from 'common-typescript/types';
import _ from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { ConfigService } from 'sis-common/config/config.service';
import { LocalStorageService } from 'sis-common/storage/local-storage.service';

import { OrganisationEntityService } from '../service/organisation-entity.service';
import { UniversitySettingsEntityService } from '../service/university-settings-entity.service';

@Injectable({ providedIn: 'root' })
export class UniversityService {

    private university: UniversityOrganisation;
    readonly storageKey = 'selected_university';
    constructor(private configService: ConfigService,
                private localStorageService: LocalStorageService,
                private organisationEntityService: OrganisationEntityService,
                private universitySettingsEntityService: UniversitySettingsEntityService,
                private logger: NGXLogger) {
        this.university = null;
    }

    /**
     * @deprecated If possible, use OrganisationEntityService.getRootOrganisations() directly
     */
    getUniversities(): Observable<Organisation[]> {
        return this.organisationEntityService.getRootOrganisations();
    }

    setCurrentUniversityOrgId(id: OtmId): UniversityOrganisation {
        this.university = _.find(this.configService.get().homeOrganisations, { id });

        if (!this.university) {
            throw Error(`Setting current university to ${id} failed, not any home organisation`);
        }
        this.localStorageService.setItem(this.storageKey, this.university.id);
        return this.university;
    }

    getCurrentUniversity(): UniversityOrganisation {
        if (!this.university) {
            const universityOrgId = this.localStorageService.getItem(this.storageKey);

            this.university = _.find(this.configService.get().homeOrganisations, { id: universityOrgId });

            if (!this.university) {
                const defaultUniversityOrgId = _.get(_.first(this.configService.get().homeOrganisations), 'id');
                this.university = this.setCurrentUniversityOrgId(defaultUniversityOrgId);
                this.logger.warn('selectedUniversity not found returning first', 'defaultUniversityOrgId', defaultUniversityOrgId,
                                 'localStorageValue:', universityOrgId, 'UniversityConfig:', this.configService.get());
            }
        }
        return this.university;
    }

    getCurrentUniversityOrgId(): OtmId {
        return this.getCurrentUniversity().id;
    }

    getCurrentUniversitySettings(): Observable<UniversitySettings> {
        return this.universitySettingsEntityService.getById(this.getCurrentUniversityOrgId());
    }

    updateCurrentUniversitySettings(universitySettings: UniversitySettings): Observable<UniversitySettings> {
        return this.universitySettingsEntityService.updateById(this.getCurrentUniversityOrgId(), universitySettings);
    }

    getLocalStorageCurrentUniversityOrgId(): OtmId {
        return this.localStorageService.getItem(this.storageKey);
    }
}
