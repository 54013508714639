import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Urn } from 'common-typescript/types';
import { getLabelState } from 'sis-components/form/formUtils';

@Component({
    selector: 'app-prior-studies-edit',
    templateUrl: './prior-studies-edit.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class PriorStudiesEditComponent {

    @Input() formGroup: FormGroup;
    @Input() title: string;
    @Output() delete = new EventEmitter<void>();

    getLabelState = getLabelState;
    readonly languageCodeBookUrn = 'urn:code:language';

    onAttainmentLanguageChange(language: Urn): void {
        if (language !== this.attainmentLanguage.value) {
            this.attainmentLanguage.setValue(language);
            this.attainmentLanguage.markAsTouched();
            this.attainmentLanguage.markAsDirty();
        }
    }

    get attainmentDate(): FormControl {
        return this.formGroup?.get('attainmentDate') as FormControl;
    }

    get attainmentLanguage(): FormControl {
        return this.formGroup?.get('attainmentLanguage') as FormControl;
    }

    get code(): FormControl {
        return this.formGroup?.get('code') as FormControl;
    }

    get credits(): FormControl {
        return this.formGroup?.get('credits') as FormControl;
    }

    get description(): FormControl {
        return this.formGroup?.get('description') as FormControl;
    }

    get grade(): FormControl {
        return this.formGroup?.get('grade') as FormControl;
    }

    get gradeScale(): FormControl {
        return this.formGroup?.get('gradeScale') as FormControl;
    }

    get name(): FormControl {
        return this.formGroup?.get('name') as FormControl;
    }

    get organisation(): FormControl {
        return this.formGroup?.get('organisation') as FormControl;
    }
}
