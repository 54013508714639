import { Transition } from '@uirouter/angular';
import { Enrolment } from 'common-typescript/types';
import { firstValueFrom, lastValueFrom, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { AuthService } from 'sis-common/auth/auth-service';
import { getInjectables } from 'sis-components/router/router-utils';
import { SisStateDeclaration } from 'sis-components/router/types';
import { PlanEntityService } from 'sis-components/service/plan-entity.service';
import { UserSettingsEntityService } from 'sis-components/service/user-settings-entity.service';

import { FrontpageBetaComponent } from './frontpage-beta/frontpage-beta.component';
import { FrontpageWrapperComponent } from './frontpage-wrapper.component';

const frontpageRootState = 'student.logged-in.frontpage';

export const frontpageRoutes: SisStateDeclaration[] = [
    {
        name: frontpageRootState,
        redirectTo: resolveFrontpageRoute,
        data: {
            browserTabTitle: 'APPLICATION_FRONTPAGE',
            hasSecondaryBackground: true,
        },
    },
    {
        name: `${frontpageRootState}.default`,
        url: '/frontpage',
        component: FrontpageWrapperComponent,
        resolve: {
            userHasPlans,
            enrolments: userEnrolments,
        },
    },
    {
        name: `${frontpageRootState}.beta`,
        url: '/beta/frontpage',
        component: FrontpageBetaComponent,
    },
];

export async function userHasPlans($transition$: Transition): Promise<boolean> {
    const [planService] = getInjectables($transition$, PlanEntityService);
    return lastValueFrom(planService.getMyPlans()
        .pipe(
            take(1),
            map(plans => plans?.length > 0),
            catchError(() => of(false)),
        ));
}

userHasPlans.$inject = ['$transition$'];

export async function userEnrolments<T extends Enrolment>($transition$: Transition): Promise<T[]> {
    const [authService, enrolmentService] = getInjectables($transition$, AuthService, 'enrolmentService');
    if (!authService.loggedIn()) {
        return Promise.resolve([]);
    }
    return enrolmentService.findMyEnrolments(true, false)
        // return only enrolments that are in calendar and load relations only for those
        .then((enrolments: T[]) => enrolments?.filter(e => e.isInCalendar) ?? [])
        .then((enrolments: T[]) => enrolmentService.loadMinimumDataForCourseUnitRealisationComponent(enrolments));
}

userEnrolments.$inject = ['$transition$'];

export async function resolveFrontpageRoute($transition$: Transition): Promise<string> {
    const [userSettingsService] = getInjectables($transition$, UserSettingsEntityService);
    return firstValueFrom(userSettingsService.getOwnSettings())
        .then(settings => settings?.studentBetaFrontpageEnabled)
        .catch(() => false)
        .then(betaFrontpageEnabled => `${frontpageRootState}.${betaFrontpageEnabled ? 'beta' : 'default'}`);
}

resolveFrontpageRoute.$inject = ['$transition$'];
