<div class="row collapsible-columns-container">
  <div class="main-bar column-transitions col-12" id="plan-main-bar"
       [class.col-md-8]="uiStateObject.selectedModule" [class.right-column-is-open]="uiStateObject.selectedModule">
    <div class="row">
      <div class="col-12">
        <div class="state-credits-header float-end">
          <div class="module-grade-average-header">
            <h4 translate>SIS_COMPONENTS.PLAN.LABEL.GRADE_AVERAGE</h4>
          </div>
          <div class="module-state-header">
            <h4 translate>SIS_COMPONENTS.PLAN.LABEL.STATE</h4>
          </div>
          <div class="module-credits-header">
            <h4 translate>SIS_COMPONENTS.PLAN.LABEL.TARGET_CREDITS</h4>
            <div class="sub-headers">
              <span class="credits-selected" translate>SIS_COMPONENTS.PLAN.LABEL.SELECTED_CREDITS</span>
              <span class="credits-done" translate>SIS_COMPONENTS.PLAN.LABEL.ATTAINED</span>
            </div>
          </div>
          <div class="cell button"></div>
        </div>
        <div class="content-padding study-plan-container clearfix">
          <sis-plan-education *ngIf="ready"
                              [education]="education"
                              [planValidationResult]="planValidationResult"
                              [planStateObject]="planStateObject"
                              [planData]="planData"
                              [uiStateObject]="uiStateObject"
                              [level]="1"></sis-plan-education>
        </div>
      </div>
    </div>
  </div>
  <div class="right-column col-10 col-md-4 column-transitions"
       [class.closed]="!uiStateObject.selectedModule" [class.collapsed]="collapsed">
    <div class="collapsible-column-header">
      <span class="header-text" [class.header-text-free-edit]="uiStateObject.selectedModule && uiStateObject.freeEditMode" data-cy="module-selections-header">
        <ng-container *ngIf="uiStateObject.selectedModule && planStateObject.modules[uiStateObject.selectedModule.id]?.studyRightState">
          <sis-icon icon="junction" color="white"></sis-icon>
          <span class="visually-hidden" translate>ARIA_LABEL.STUDY_RIGHT</span>
        </ng-container>
        {{ uiStateObject.selectedModule?.name | localizedString }}
      </span>
      <sis-menu-button *ngIf="!uiStateObject.freeEditMode && uiStateObject.selectedModule?.type !== 'Education'"
                       [options]="freeEditMenuOptions" [color]="'white'">
      </sis-menu-button>
      <button type="button"
              (click)="openModule(null)"
              class="non-button collapsible-column-dismiss"
              [attr.aria-label]="'ARIA_LABEL.CLOSE' | translate">
        <sis-icon icon="close" color="white"></sis-icon>
      </button>
    </div>
    <div class="collapsible-column-content" *ngIf="uiStateObject.selectedModule && !uiStateObject.freeEditMode">
      <sis-rule-select *ngIf="uiStateObject.selectedModule"
                       [rule]="selectedRule"
                       [parentModule]="uiStateObject.selectedModule"
                       [validatablePlan]="validatablePlan"
                       [planValidationResult]="planValidationResult"
                       [blockLevel]="1"
                       [planStateObject]="planStateObject"
                       [planData]="planData"
                       [uiStateObject]="uiStateObject">
      </sis-rule-select>
      <sis-invalid-selections [parentModule]="uiStateObject.selectedModule"
                              [planValidationResult]="planValidationResult"
                              [validatablePlan]="validatablePlan"
                              [planStateObject]="planStateObject"
                              [planData]="planData">
      </sis-invalid-selections>
      <hr/>
      <h4 translate>SIS_COMPONENTS.PLAN.CUSTOM_STUDY_DRAFTS_TITLE</h4>
      <div class="float-end">
        <sis-button size="sm" class="add-study-draft" (clicked)="openCustomStudyDraftCreateModal(uiStateObject.selectedModule)">
          {{'SIS_COMPONENTS.PLAN.CREATE_CUSTOM_STUDY_DRAFT' | translate}}
        </sis-button>
      </div>
      <sis-plan-custom-study-draft *ngFor="let customStudyDraft of customStudyDraftsNotMatchedByRules"
                                   [customStudyDraft]="customStudyDraft"
                                   [planStateObject]="planStateObject">
        <div custom-study-draft-action class="additional-box">
          <sis-button class="button-box non-button"
                      link="true"
                      (clicked)="this.removeCustomStudyDraft(customStudyDraft, uiStateObject.selectedModule)"
                      [ariaLabel]="'ARIA_LABEL.DELETE' |translate">
            <sis-icon icon="delete"></sis-icon>
          </sis-button>
        </div>
      </sis-plan-custom-study-draft>
    </div>
    <div class="free-edit-mode-banner" *ngIf="uiStateObject.selectedModule && uiStateObject.freeEditMode">
      <sis-icon icon="magic-wand" color="white"></sis-icon>
      <span translate>SIS_COMPONENTS.PLAN.FREE_EDIT.TITLE</span>
      <span class="float-end">
        <button type="button" class="link-button" (click)="closeFreeEdit()">
          <span translate>SIS_COMPONENTS.PLAN.FREE_EDIT.BANNER_TO_NORMAL_MODE_LINK_TITLE</span>
        </button>
      </span>
    </div>
    <div class="collapsible-column-content" *ngIf="uiStateObject.selectedModule && uiStateObject.freeEditMode">
      <sis-free-edit [parentModule]="uiStateObject.selectedModule"
                     [planValidationResult]="planValidationResult"
                     [validatablePlan]="validatablePlan"
                     [planStateObject]="planStateObject"
                     [planData]="planData"
                     [matchingStudyRight]="studyRight">
      </sis-free-edit>
    </div>
  </div>
</div>
