<ng-container *transloco="let t">
  <fudis-dialog [size]="'sm'">
    <fudis-dialog-content>
      <fudis-form
        [title]="t('PROFILE.DISCLOSURE_AUTHORIZATION.AUTHORIZATION_TITLE')"
        [titleLevel]="2"
        [errorSummaryLinkType]="'href'"
        [errorSummaryHelpText]="t('ERROR-SUMMARY.HELP_TEXT')">
        <ng-template fudisContent [type]="'form'">
          <fudis-checkbox-group #checkboxGroup
            [size]="'lg'"
            [label]="!this.staffEdit ? t('PROFILE.DISCLOSURE_AUTHORIZATION.SELECT_GRANT_OR_DENY') : t('PROFILE.DISCLOSURE_AUTHORIZATION.STAFF.SELECT_GRANT_OR_DENY')">
            <fudis-checkbox
              *ngFor="let answer of initialControls?.controls"
              [controlName]="answer.get('id').value"
              [control]="answer['controls']['authorized']"
              [label]="answer.get('description').value|localizedString" />
          </fudis-checkbox-group>
          <ng-container *ngIf="initialControls?.value.length===0">
            <fudis-body-text>{{t('PROFILE.DISCLOSURE_AUTHORIZATION.NO_AUTHORIZATION_CATEGORIES_DEFINED')}}</fudis-body-text>
          </ng-container>
        </ng-template>
        <ng-template fudisActions type="form">
          <fudis-button [label]="t('SIS_COMPONENTS.BUTTON.SAVE')" (handleClick)="save()" />
          <fudis-button fudisDialogClose [label]="t('SIS_COMPONENTS.BUTTON.CANCEL')" (handleClick)="reset.emit()" [variant]="'secondary'" />
        </ng-template>
      </fudis-form>
    </fudis-dialog-content>
  </fudis-dialog>
</ng-container>

