import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Transition } from '@uirouter/angular';
import { EntityWithApprovalState, StudentApplication, StudentWorkflow } from 'common-typescript/types';
import * as _ from 'lodash';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { SisStateObject } from '../router/types';
import { CommonCodeService } from '../service/common-code.service';

export interface BreadcrumbItem {
    displayName: string;
    isLink: boolean;
    route: string;
}

type Variant = 'accent' | 'danger' | 'primary' | 'secondary' | 'success';

/** Defines the contents and visuals of a badge. See TinyBadgeComponent for details on the properties. */
export interface BadgeData {
    variant: Variant;
    content: string;
    customClasses?: string;
}

@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
export class HeaderService {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'sis-components.breadcrumb.headerService',
        serviceName: 'headerService',
    };

    // Subjects which transmit events of various types to subscribers. The concrete type of each subject is a
    // `ReplaySubject` with a buffer size of 1 so that all new subscribers will also get the "current value" of
    // the event they subscribe to. This is necessary, since e.g. during page load some transition hooks might
    // trigger events before the component(s) listening on those events have been initialized.
    private readonly breadcrumbEvents: Subject<BreadcrumbItem[]> = new ReplaySubject(1);
    private readonly pageTitleEvents: Subject<string> = new ReplaySubject(1);
    private readonly pageSubTitleEvents: Subject<string> = new ReplaySubject(1);
    private readonly badgeEvents: Subject<BadgeData[]> = new ReplaySubject(1);

    constructor(private commonCodeService: CommonCodeService,
                private translate: TranslateService,
                private localeService: LocaleService) {
    }

    get breadcrumbsChanged(): Observable<BreadcrumbItem[]> {
        return this.breadcrumbEvents.asObservable();
    }

    get customPageTitleChanged(): Observable<string> {
        return this.pageTitleEvents.asObservable();
    }

    get customPageSubTitleChanged(): Observable<string> {
        return this.pageSubTitleEvents.asObservable();
    }

    get pageTitleBadgesChanged(): Observable<BadgeData[]> {
        return this.badgeEvents.asObservable();
    }

    /**
     * Set a custom page title for the currently active router state (shown in the `PageTitleComponent`). This value will
     * be cleared during the next state transition.
     *
     * When calling this function from a UI-Router transition hook, be sure to use an onSuccess hook (via `TransitionService`).
     * See <a href="https://ui-router.github.io/guide/transitions#transition-lifecycle">Transition lifecycle</a> in the
     * UI-Router docs.
     */
    setCustomPageTitle(title: string): void {
        this.pageTitleEvents.next(title);
    }

    setCustomSubTitle(subTitle: string): void {
        this.pageSubTitleEvents.next(subTitle);
    }

    /**
     * Set the badges to be displayed next to the page title. These badges will be cleared during the next successful
     * state transition.
     *
     * When calling this function from a UI-Router transition hook, be sure to use an onSuccess hook (via `TransitionService`).
     * See <a href="https://ui-router.github.io/guide/transitions#transition-lifecycle">Transition lifecycle</a> in the
     * UI-Router docs.
     */
    setPageTitleBadges(...badges: BadgeData[]): void {
        this.badgeEvents.next(badges);
    }

    /**
     * Sets a page title badge based on the given entity (e.g. a course unit, study module, or degree programme). The badge
     * reflects the approval state of the given entity.
     *
     * The badge will be cleared during the next successful state transition.
     *
     * @see setPageTitleBadges
     */
    setApprovalStateBadge(entity: EntityWithApprovalState): void {
        if (!!entity && !!entity.approvalState && !!entity.documentState) {
            const customClasses = 'approval-state-badge';
            if (entity.documentState === 'DRAFT') {
                this.commonCodeService.getCode(entity.approvalState)
                    .then(code => this.setPageTitleBadges({
                        customClasses,
                        variant: 'accent',
                        content: this.localeService.localize(code.name),
                    }));
            } else {
                this.setPageTitleBadges({
                    customClasses,
                    variant: entity.documentState === 'ACTIVE' ? 'success' : 'secondary',
                    content: this.translate.instant(`VISIBLE_DOCUMENT_STATE.${entity.documentState}`),
                });
            }
        }
    }

    /**
     * Used in student/profile page
     */
    setApplicationStateBadge(application: StudentApplication | StudentWorkflow) {
        let variant: Variant = 'secondary';
        if (application.state === 'ACCEPTED') {
            variant = 'success';
        } else if (application.state === 'REJECTED') {
            variant = 'danger';
        } else if (application.state === 'SUPPLEMENT_REQUESTED') {
            variant = 'accent';
        }
        this.setPageTitleBadges({
            variant,
            content: this.translate.instant(`STUDENT_APPLICATIONS.STATE.${application.state}`),
            customClasses: 'student-application-state-badge',
        });
    }

    onSuccessfulTransition(transition: Transition): void {
        const state: SisStateObject = transition.$to();

        // Clear any custom page title and badges set for the previous state if state has no default custom page title
        if (state.headerParams?.displayPageTitleNameKey) {
            this.setCustomPageTitle(this.translate.instant(state.headerParams?.displayPageTitleNameKey));
        } else {
            this.setCustomPageTitle(undefined);
        }

        // Clear any custom page title and badges set for the previous state if state has no default custom page title
        if (state.headerParams?.displayPageTitleSubHeadingKey) {
            this.setCustomSubTitle(this.translate.instant(state.headerParams?.displayPageTitleSubHeadingKey));
        } else {
            this.setCustomSubTitle(undefined);
        }

        this.setPageTitleBadges();

        // Update the breadcrumbs array for the new state
        this.updateBreadcrumbs(transition);
    }

    private updateBreadcrumbs(transition: Transition): void {
        const breadcrumbs: BreadcrumbItem[] = [];
        let state: SisStateObject = transition.$to();

        while (!!state) {
            if (!_.isEmpty(state.name) && !state.headerParams?.skipBreadcrumb) {
                const item = this.createBreadcrumbItem(state, this.getDisplayName(state, transition));
                if (!breadcrumbs.some(crumb => crumb.route === item.route)) {
                    breadcrumbs.push(item);
                }
            }
            state = state.parent;
        }
        breadcrumbs.reverse();
        this.breadcrumbEvents.next(breadcrumbs);
    }

    private createBreadcrumbItem(state: SisStateObject, displayName: string): BreadcrumbItem {
        const customRoute = state.headerParams?.customBreadcrumbTargetRoute;
        const route = customRoute ?? state.name;
        const isLink = !!customRoute || !state.abstract;

        return { displayName, route, isLink };
    }

    private getDisplayName(state: SisStateObject, transition: Transition): string {
        const displayNameKey = state.headerParams?.displayNameKey;
        if (displayNameKey) {
            return this.translate.instant(displayNameKey);
        }

        const displayNameFunction = state.headerParams?.displayNameFunction;
        if (_.isFunction(displayNameFunction)) {
            return displayNameFunction.apply(null, [transition]);
        }

        return undefined;
    }
}
