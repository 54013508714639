import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    ModuleContentWorkflow,
    OtmId,
    Plan,
} from 'common-typescript/types';
import { BehaviorSubject, combineLatest, Observable, of, switchMap, take, tap } from 'rxjs';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { AlertsService, AlertType } from 'sis-components/alerts/alerts-ng.service';
import { ModuleContentWorkflowService, ModuleContentWorkflowStructureData } from 'sis-components/applications/module-content-workflow.service';
import { ConfirmDialogService } from 'sis-components/confirm/confirm-dialog.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { HeaderService } from 'sis-components/header/header.service';
import { STUDENT_WORKFLOW_STATE } from 'sis-components/model/student-workflow-constants';
import { ReadMoreModalService } from 'sis-components/read-more-ng/read-more-modal.service';
import { ReadMoreValues } from 'sis-components/read-more-ng/read-more-ng-modal/read-more-ng-modal.component';
import { PlanEntityService } from 'sis-components/service/plan-entity.service';
import { WorkflowEntityService } from 'sis-components/service/workflow-entity.service';

import { WorkflowCancellationInfoModalService } from './workflow-cancellation-info-modal/workflow-cancellation-info-modal.service';

@Component({
    selector: 'app-module-content-workflow',
    templateUrl: './module-content-workflow.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModuleContentWorkflowComponent implements OnInit {

    @Input() studentWorkflow: ModuleContentWorkflow;

    private readonly workflowState = STUDENT_WORKFLOW_STATE;

    moduleContentWorkflow: ModuleContentWorkflow;
    plan: Plan;
    moduleContentWorkflowStructureData: ModuleContentWorkflowStructureData;

    isPendingApproval: boolean;
    isApproved: boolean;
    isRejected: boolean;
    isCreatedByStaff: boolean;
    isApplicationCancelledByStaff: boolean;
    isApplicationAcceptanceRevokedByStaff: boolean;
    cancelReason: string;
    cancelTime: string;

    planExists: boolean;
    isInPlan: boolean;

    // Trigger reload using a subject as workflow state operations and workflow fetch don't currently utilize akita store
    reloadTriggerSubject$ = new BehaviorSubject(null);
    data$: Observable<[ModuleContentWorkflow, Plan, ModuleContentWorkflowStructureData]>;

    constructor(
        private moduleContentWorkflowService: ModuleContentWorkflowService,
        private workflowEntityService: WorkflowEntityService,
        private planEntityService: PlanEntityService,
        private headerService: HeaderService,
        private readMoreModalService: ReadMoreModalService,
        private translateService: TranslateService,
        private localeService: LocaleService,
        private confirmDialogService: ConfirmDialogService,
        private alertsService: AlertsService,
        private appErrorHandler: AppErrorHandler,
        private workflowCancellationInfoModalService: WorkflowCancellationInfoModalService,
    ) { }

    ngOnInit(): void {
        this.data$ = this.reloadTriggerSubject$
            .pipe(
                switchMap(() => this.workflowEntityService.getById(this.studentWorkflow.id)
                    .pipe(
                        this.appErrorHandler.defaultErrorHandler(),
                        switchMap(workflow => combineLatest([
                            of(workflow as ModuleContentWorkflow),
                            this.planEntityService.getById(this.studentWorkflow.originalReferredPlanId),
                            this.moduleContentWorkflowService.getModuleContentWorkflowStructureData(workflow as ModuleContentWorkflow),
                        ])),
                        tap(([moduleContentWorkflow, plan, mcwStructureData]) => {
                            this.setWorkflowStateVariables(moduleContentWorkflow);
                            this.setPlanStateVariables(plan, moduleContentWorkflow.approvedModuleId);
                            this.headerService.setApplicationStateBadge(moduleContentWorkflow);
                            this.moduleContentWorkflow = moduleContentWorkflow;
                            this.plan = plan;
                            this.moduleContentWorkflowStructureData = mcwStructureData;
                        }),
                    )),
            );

    }

    showWorkflowApplicationCancelledInfoModal(): void {
        const content = {
            title: this.translateService.instant('PROFILE.APPLICATIONS.APPLICATION_CANCELLED_INFO'),
            description: this.translateService.instant('PROFILE.APPLICATIONS.APPLICATION_CANCELLED_BY_STAFF'),
            cancelTime: this.cancelTime,
            cancelReason: this.cancelReason,
        };
        this.workflowCancellationInfoModalService.open({ ...content });
    }

    showWorkflowApplicationAcceptanceRevokedInfoModal(): void {
        const content = {
            title: this.translateService.instant('PROFILE.APPLICATIONS.APPLICATION_ACCEPTANCE_REVOKED_INFO'),
            description: this.translateService.instant('PROFILE.APPLICATIONS.APPLICATION_ACCEPTANCE_REVOKED_BY_STAFF'),
            cancelTime: this.cancelTime,
            cancelReason: this.cancelReason,
        };
        this.workflowCancellationInfoModalService.open({ ...content });
    }

    cancel(): void {
        if (this.moduleContentWorkflow.cancellingDisabled) {
            this.alertsService.addAlert({
                type: AlertType.DANGER,
                message: this.translateService.instant('PROFILE.APPLICATIONS.APPLICATION_CANCELLATION_DISABLED_ALERT'),
            });
            return;
        }
        const confirmTexts = {
            title: 'SHOW_MODULE_CONTENT_APPROVAL_MODAL.CANCEL.CONFIRM_TITLE',
            description: 'SHOW_MODULE_CONTENT_APPROVAL_MODAL.CANCEL.CONFIRM_TEXT',
            confirmText: 'BUTTON.YES',
            cancelText: 'CANCEL',
        };
        this.confirmDialogService.confirm(confirmTexts).then(
            () => this.confirmCancel(),
            () => {},
        );
    }

    confirmCancel(): void {
        this.workflowEntityService.cancel(this.moduleContentWorkflow.id)
            .pipe(take(1), this.appErrorHandler.defaultErrorHandler())
            .subscribe(() => {
                this.reloadTriggerSubject$.next(null);
                this.alertsService.addAlert({
                    message: this.translateService.instant('PROFILE.APPLICATIONS.APPLICATION_CANCELLED_ALERT'),
                    type: AlertType.SUCCESS,
                });
            });
    }

    revoke(): void {
        if (this.moduleContentWorkflow.cancellingDisabled) {
            this.alertsService.addAlert({
                type: AlertType.DANGER,
                message: this.translateService.instant('PROFILE.APPLICATIONS.APPLICATION_REVOKE_DISABLED_ALERT'),
            });
            return;
        }
        const confirmTexts = {
            title: 'SHOW_MODULE_CONTENT_APPROVAL_MODAL.REVOKE.CONFIRM_TITLE',
            description: 'SHOW_MODULE_CONTENT_APPROVAL_MODAL.REVOKE.CONFIRM_TEXT',
            confirmText: 'BUTTON.YES',
            cancelText: 'CANCEL',
        };
        this.confirmDialogService.confirm(confirmTexts).then(
            () => this.confirmRevoke(),
            () => {},
        );
    }

    confirmRevoke(): void {
        this.workflowEntityService.revoke(this.moduleContentWorkflow.id)
            .pipe(take(1), this.appErrorHandler.defaultErrorHandler())
            .subscribe(() => {
                this.reloadTriggerSubject$.next(null);
                this.alertsService.addAlert({
                    message: this.translateService.instant('PROFILE.APPLICATIONS.APPLICATION_REVOKED_ALERT'),
                    type: AlertType.SUCCESS,
                });
            });
    }

    openPlan(): void {
        if (this.planExists && this.isInPlan) {
            this.openPlanInNewWindow();
        } else if (this.isInPlan) {
            this.readMoreModalService.open(this.getModuleRemovedOptions()).then(() => this.openPlanInNewWindow());
        } else {
            this.readMoreModalService.open(this.getPlanDeletedOptions());
        }
    }

    openPlanInNewWindow(): void {
        window.open(`/student/structure/${this.moduleContentWorkflow.originalReferredPlanId}`, '_blank');
    }

    getModuleRemovedOptions(): ReadMoreValues {
        return {
            options: {
                title: this.translateService.instant('SHOW_MODULE_CONTENT_APPROVAL_MODAL.OPEN_PLAN.TITLE_REMOVED'),
                contentTranslateKey: 'SHOW_MODULE_CONTENT_APPROVAL_MODAL.OPEN_PLAN.CONTENT_REMOVED',
                contentTranslateParameters: {
                    moduleName: this.localeService.localize(this.moduleContentWorkflowStructureData.module.name),
                    planName: this.plan.name,
                },
                contentHtml: null,
            },
        };
    }

    getPlanDeletedOptions(): ReadMoreValues {
        return {
            options: {
                title: this.translateService.instant('SHOW_MODULE_CONTENT_APPROVAL_MODAL.OPEN_PLAN.TITLE_DELETED'),
                contentTranslateKey: 'SHOW_MODULE_CONTENT_APPROVAL_MODAL.OPEN_PLAN.CONTENT_DELETED',
                contentTranslateParameters: {
                    moduleName: this.localeService.localize(this.moduleContentWorkflowStructureData.module.name),
                    planName: this.plan.name,
                },
                contentHtml: null,
            },
        };
    }

    setWorkflowStateVariables(workflow: ModuleContentWorkflow): void {
        this.isPendingApproval = this.getIsPendingApproval(workflow);
        this.isApproved = this.getIsApproved(workflow);
        this.isRejected = this.getIsRejected(workflow);
        this.isCreatedByStaff = this.getIsCreatedByStaff(workflow);
        this.isApplicationCancelledByStaff = this.getIsApplicationCancelledByStaff(workflow);
        this.isApplicationAcceptanceRevokedByStaff = this.getIsApplicationAcceptanceRevokedByStaff(workflow);
        this.cancelReason = this.getCancelReason(workflow);
        this.cancelTime = this.getCancelTime(workflow);
    }

    setPlanStateVariables(plan: Plan, approvedModuleId: OtmId): void {
        this.isInPlan = this.getIsInPlan(plan, approvedModuleId);
        this.planExists = this.getPlanExists(plan);
    }

    getIsPendingApproval(workflow: ModuleContentWorkflow): boolean {
        return workflow.state === this.workflowState.REQUESTED
            || workflow.state === this.workflowState.IN_HANDLING;
    }

    getIsApproved(workflow: ModuleContentWorkflow): boolean {
        return workflow.state === this.workflowState.ACCEPTED ||
            workflow.state === this.workflowState.CONDITIONAL;
    }

    getIsRejected(workflow: ModuleContentWorkflow): boolean {
        return workflow.state === this.workflowState.REJECTED;
    }

    getIsCreatedByStaff(workflow: ModuleContentWorkflow): boolean {
        return workflow.initiatorType === 'STAFF';
    }

    getIsApplicationCancelledByStaff(workflow: ModuleContentWorkflow): boolean {
        return workflow.state === this.workflowState.CANCELLED && workflow.cancelledByType === 'STAFF';
    }

    getIsApplicationAcceptanceRevokedByStaff(workflow: ModuleContentWorkflow): boolean {
        return workflow.state === this.workflowState.ACCEPTANCE_REVOKED && workflow.cancelledByType === 'STAFF';
    }

    getCancelReason(workflow: ModuleContentWorkflow): string {
        return workflow.cancelReason;
    }

    getCancelTime(workflow: ModuleContentWorkflow): string {
        return workflow.cancelTime;
    }

    getPlanExists(plan: Plan): boolean {
        return plan?.documentState === 'ACTIVE';
    }

    getIsInPlan(plan: Plan, approvedModuleId: OtmId): boolean {
        return !!plan?.moduleSelections.find((selection) => selection.moduleId === approvedModuleId);
    }
}
