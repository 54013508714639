import angular from 'angular';
import _ from 'lodash';
import { isAttached } from '../attainment/AttainmentUtil.ts';
export const commonStudentAttainmentServiceModule = 'sis-components.service.studentAttainmentService';
(function () {
  angular.module(commonStudentAttainmentServiceModule, ['sis-components.model.courseUnit', 'sis-components.model.module', 'sis-components.model.gradeScale', 'sis-components.model.assessmentItem', 'sis-components.model.attainment', 'sis-components.service.assessmentItemService', 'sis-components.service.validAttainmentFilterService', 'sis-components.service.jsDataCacheHelper']).service('commonStudentAttainmentService', ["$q", "$log", "attainmentJSDataModel", "gradeScaleJSDataModel", "assessmentItemJSDataModel", "courseUnitJSDataModel", "commonAssessmentItemService", "validAttainmentFilterService", "jsDataCacheHelper", "planModuleJSDataModel", "attainmentEndpoint", function ($q, $log, attainmentJSDataModel, gradeScaleJSDataModel, assessmentItemJSDataModel, courseUnitJSDataModel, commonAssessmentItemService, validAttainmentFilterService, jsDataCacheHelper, planModuleJSDataModel, attainmentEndpoint) {
    const _memoized = {
      assessmentItemByAttainmentItem: []
    };
    const api = {
      /**
       * Makes a get request for 'my-attainments' if personId is null.
       * Makes a get request for all attainments of personId if it is not null.
       * Loads relations for modules, course units and assessment items.
       * @param personId
       * @param bypassCache
       * @returns list of attainments
       */
      load: (personId, bypassCache) => {
        const options = {
          bypassCache: !!bypassCache,
          endpoint: _.isNil(personId) ? attainmentEndpoint.MY_ATTAINMENTS : attainmentEndpoint.DEFAULT
        };
        return attainmentJSDataModel.findAll({
          personId
        }, options).then(loadModCuAndAssItemDataForAttainments).then(loadRelationsForAttainments);

        /**
         * Use jsDataCacheHelper to load course units, modules and assessment items for attainments so that
         * js data will not do requests for single entities.
         * @param attainments
         * @returns attainments
         */
        function loadModCuAndAssItemDataForAttainments(attainments) {
          return $q.all([loadAttainmentDatas(attainments, planModuleJSDataModel, 'moduleId'), loadAttainmentDatas(attainments, courseUnitJSDataModel, 'courseUnitId'), loadAttainmentDatas(attainments, assessmentItemJSDataModel, 'assessmentItemId')]).then(() => attainments);
          function loadAttainmentDatas(attainmentList, model, field) {
            const ids = _.chain(attainmentList).map(field).compact().uniq().value();
            return jsDataCacheHelper.findByIds(model, ids, false);
          }
        }

        /**
         * Load relations for each attainment
         * @param attainments
         * @returns attainments
         */
        function loadRelationsForAttainments(attainments) {
          const relations = ['module', 'courseUnit', 'gradeScale', 'assessmentItem'];
          return $q.all(_.map(attainments, attainment => api.loadRelationsForAttainment(attainment, relations)));
        }
      },
      /**
       * Load snapshot assessment item for each assessment item attainment on the attainment date to cache. Load from cache by
       * calling 'get' method.
       *
       * @param attainments
       * @returns attainments
       */
      loadAssessmentItemSnapshotsToCache(attainments) {
        const assessmentItemAttainmentPromises = _.chain(attainments).filter({
          type: 'AssessmentItemAttainment'
        }).map(attainment => commonAssessmentItemService.findAssessmentItemByIdAndDate(attainment.assessmentItemId, attainment.attainmentDate).then(assessmentItem => {
          _memoized.assessmentItemByAttainmentItem[attainment.id] = assessmentItem;
        }, err => {
          const id = attainment.assessmentItemId;
          const date = attainment.attainmentDate;
          $log.error(`AssessmentItem snapshot not found id: ${id} atDate: ${date}`, err);
          // AssessmentItem for the attainmentDate was not found...
          _memoized.assessmentItemByAttainmentItem[attainment.id] = false;
        })).value();
        return $q.all(assessmentItemAttainmentPromises).then(() => attainments);
      },
      get(type, id) {
        let data;
        let model;
        if (type === 'module') {
          model = _.find(attainmentJSDataModel.getAll(), a => _.result(a, 'moduleId', null) === id);
          if (model) {
            data = {
              code: model.module.code,
              name: model.module.name,
              moduleGroupId: model.moduleGroupId,
              moduleId: model.moduleId
            };
          }
        }
        if (type === 'courseUnit') {
          model = _.find(attainmentJSDataModel.getAll(), a => _.result(a, 'courseUnitId', null) === id);
          if (model) {
            data = {
              code: model.courseUnit.code,
              name: model.courseUnit.name,
              courseUnitGroupId: model.courseUnitGroupId,
              courseUnitId: model.courseUnitId
            };
          }
        }
        if (type === 'gradeScales') {
          model = _.find(attainmentJSDataModel.getAll(), a => _.result(a, 'gradeScaleId', null) === id);
          if (model) {
            data = {
              grades: model.gradeScale.grades
            };
          }
        }
        return data;
      },
      getAISnapshotByAttainmentItem(attainmentId) {
        return _memoized.assessmentItemByAttainmentItem[attainmentId];
      },
      findByPersonId(personId, bypassCache) {
        const options = {
          bypassCache: !!bypassCache
        };
        const params = {
          personId
        };
        return attainmentJSDataModel.findAll(params, options);
      },
      findValidAttainmentsByPersonId(personId, bypassCache) {
        return api.findByPersonId(personId, bypassCache).then(allAttainments => validAttainmentFilterService.getValidAttainments(allAttainments));
      },
      getMyAttainments(bypassCache) {
        const options = {
          bypassCache: !!bypassCache,
          endpoint: attainmentEndpoint.MY_ATTAINMENTS
        };
        return attainmentJSDataModel.findAll({}, options);
      },
      getSecondaryAttainments(attainment, allAttainments) {
        let filter;
        if (attainment.type === 'AssessmentItemAttainment') {
          filter = {
            primary: false,
            type: 'AssessmentItemAttainment',
            assessmentItemId: attainment.assessmentItemId
          };
        }
        if (attainment.type === 'CourseUnitAttainment') {
          filter = {
            primary: false,
            type: 'CourseUnitAttainment',
            courseUnitId: attainment.courseUnitId
          };
        }
        if (attainment.type === 'ModuleAttainment') {
          filter = {
            primary: false,
            type: 'ModuleAttainment',
            moduleId: attainment.moduleId
          };
        }
        if (attainment.type === 'DegreeProgrammeAttainment') {
          filter = {
            primary: false,
            type: 'DegreeProgrammeAttainment',
            moduleId: attainment.moduleId
          };
        }
        if (filter) {
          return _.filter(allAttainments, filter);
        }
        return [];
      },
      loadAttainmentTree(attainmentTreeDataMapper) {
        return api.load().then(api.loadAssessmentItemSnapshotsToCache).then(allAttainments => {
          const attainmentsToReturn = {};
          attainmentsToReturn.allAttainments = allAttainments;
          attainmentsToReturn.attainmentsGroupedByValidity = validAttainmentFilterService.groupAttainmentsByValidity(allAttainments);
          attainmentsToReturn.secondaryAttainments = _.filter(allAttainments, {
            primary: false,
            misregistration: false
          });
          const {
            validAttainments
          } = attainmentsToReturn.attainmentsGroupedByValidity;
          attainmentsToReturn.modules = _.concat(attainmentTreeDataMapper.modules(validAttainments, validAttainments), attainmentTreeDataMapper.customModules(validAttainments, validAttainments));
          attainmentsToReturn.courseUnits = _.concat(attainmentTreeDataMapper.courseUnits(validAttainments, validAttainments), attainmentTreeDataMapper.customCourseUnits(validAttainments, validAttainments));
          attainmentsToReturn.assessmentItems = attainmentTreeDataMapper.assessmentItems(validAttainments, validAttainments);

          // Note that this will mean that misregistered attainments will not be visible under failed
          // or secondary attainments, etc.
          const notValidAttainments = _.filter(attainmentsToReturn.attainmentsGroupedByValidity.notValidAttainments, {
            misregistration: false
          });
          attainmentsToReturn.invalidModules = _.concat(attainmentTreeDataMapper.modules(notValidAttainments, validAttainments), attainmentTreeDataMapper.customModules(notValidAttainments, validAttainments));
          attainmentsToReturn.invalidCourseUnits = _.concat(attainmentTreeDataMapper.courseUnits(notValidAttainments, validAttainments), attainmentTreeDataMapper.customCourseUnits(notValidAttainments, validAttainments));
          attainmentsToReturn.invalidAssessmentItems = attainmentTreeDataMapper.assessmentItems(notValidAttainments, validAttainments);
          return attainmentsToReturn;
        });
      },
      loadRelationsForAttainment(attainment, relations) {
        return attainmentJSDataModel.loadRelations(attainment.id, relations);
      },
      isAttached(attainment, attainments) {
        return isAttached(attainment, attainments);
      }
    };
    return api;
  }]);
})();