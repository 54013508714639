import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef, ElementRef,
    inject,
    OnInit,
    ProviderToken, ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PlanValidationTs, ValidatablePlan } from 'common-typescript';
import { PlanValidationResult } from 'common-typescript/src/plan/validation/planValidationResult';
import { Education, EntityWithRule, Plan, StudyRight } from 'common-typescript/types';
import _ from 'lodash';
import { combineLatestWith, exhaustMap, map, merge, mergeMap, Observable, of, Subject, switchMap, take, tap, withLatestFrom } from 'rxjs';
import { ModalService } from 'sis-common/modal/modal.service';
import { PLAN_STUDY_RIGHT_SERVICE } from 'sis-components/ajs-upgraded-modules';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { RuleClearSignalService } from 'sis-components/plan-structure/rules/rule-clear-signal.service';
import {
    RuleError,
    RuleErrorState,
    RuleErrorStateService,
} from 'sis-components/plan-structure/rules/rule-error-state.service';
import {
    PLAN_ACTIONS_SERVICE_INJECTION_TOKEN,
    PlanActionsService,
} from 'sis-components/plan/plan-actions-service/plan-actions.service';
import { PlanManager } from 'sis-components/plan/plan-manager/plan-manager.service';
import { GradeScaleEntityService } from 'sis-components/service/grade-scale-entity.service';
import { PlanEntityService } from 'sis-components/service/plan-entity.service';
import { PlanRuleData, PlanRuleDataService } from 'sis-components/service/plan-rule-data.service';
import { PlanData, PlanStateObject, PlanStateService } from 'sis-components/service/plan-state.service';

export function planStructureEditModalOpener(): (modalValues: PlanStructureEditModalValues) => NgbModalRef {
    const modalService = inject(ModalService);
    return modalValues => modalService.open(PlanStructureEditModalComponent, modalValues, { size: 'md' });
}
export interface PlanStructureEditModalValues {
    module: EntityWithRule;
    validatablePlan: ValidatablePlan;
    validatablePlanStudyRight: StudyRight;
    education: Education;
}
interface PlanStructureEditModalData {
    planData: PlanData;
    planStateObject: PlanStateObject;
    planValidationResult: PlanValidationResult;
    planRuleData: PlanRuleData;
    validatablePlan: ValidatablePlan;
    selectedModule: EntityWithRule;
}
@Component({
    selector: 'app-plan-structure-edit-modal',
    templateUrl: './plan-structure-edit-modal.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        PlanManager,
        RuleClearSignalService,
        RuleErrorStateService,
    ],
})
export class PlanStructureEditModalComponent implements OnInit {

    protected readonly modalValues: PlanStructureEditModalValues =
        inject(ModalService.injectionToken as unknown as ProviderToken<PlanStructureEditModalValues>);

    private planManager: PlanManager = inject(PlanManager);

    private planActionsService: PlanActionsService = inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN);

    private planStudyRightService: any = inject(PLAN_STUDY_RIGHT_SERVICE);

    private planStateService: PlanStateService = inject(PlanStateService);

    private planRuleDataService: PlanRuleDataService = inject(PlanRuleDataService);

    protected ruleErrorStateService: RuleErrorStateService = inject(RuleErrorStateService);

    private gradeScaleEntityService: GradeScaleEntityService = inject(GradeScaleEntityService);

    private planEntityService: PlanEntityService = inject(PlanEntityService);

    private activeModal = inject(NgbActiveModal);

    private appErrorHandler = inject(AppErrorHandler);

    private destroyRef: DestroyRef = inject(DestroyRef);

    anyRuleErrors$ = this.ruleErrorStateService.hasAnyErrors$;
    @ViewChild('errorSummary', { static: false, read: ElementRef }) errorSummary: ElementRef;

    data$: Observable<PlanStructureEditModalData>;
    submitClick$: Subject<void> = new Subject();

    ngOnInit() {
        // Important to clone the validatable plan to avoid modifying the original plan
        this.planManager.setValidatablePlan(_.cloneDeep(this.modalValues.validatablePlan));
        this.planManager.setStudyRight(this.modalValues.validatablePlanStudyRight);

        this.data$ = this.createDataObservable();
        this.createPlanOperationSubjectSubscription();
        this.createSubmitClickSubscription();
    }

    createDataObservable(): Observable<PlanStructureEditModalData> {
        // First emitted value will be the initial validatable plan, after that,
        // emit values from planManager.validatablePlanSubject
        return merge(of(this.modalValues.validatablePlan),
                     this.planManager.validatablePlanSubject).pipe(
            // Prepare data for plan update, combine gradescales and validatable plan
            switchMap((validatablePlan) =>
                this.createGradeScalesByIdObservable(validatablePlan).pipe(
                    combineLatestWith(of(validatablePlan))),
            ),
            map(([gradeScalesById, newValidatablePlan]) =>
                this.handleValidatablePlanUpdate(gradeScalesById, newValidatablePlan)),
        ).pipe(
            // Resolve shown selectable course units and modules.
            // This is only done once and the initial value is passed on each emit
            combineLatestWith(this.planRuleDataService.resolvePlanRuleData(this.modalValues.validatablePlan, this.modalValues.module)),
            map(([data, planRuleData]) =>
                ({ ...data, planRuleData } as PlanStructureEditModalData)),
        );
    }

    /**
     * Gathers all grade scale ids from plan attainments and returns
     * them in an object with the grade scale id as the key.
     *
     * @param validatablePlan Current validatable plan.
     */
    createGradeScalesByIdObservable(validatablePlan: ValidatablePlan): Observable<{ [id: string]: any }> {
        return this.gradeScaleEntityService.getByIds(_.chain(_.values(validatablePlan.getAllAttainments()))
            .map('gradeScaleId')
            .concat('sis-0-5')
            .compact()
            .uniq()
            .value())
            .pipe(
                map((gradeScales) => _.keyBy(gradeScales, 'id')),
            );
    }

    /**
     * Subscribes to planOperationSubject and processes the operations by passing them directly to planManager.
     */
    createPlanOperationSubjectSubscription(): void {
        this.planActionsService.planOperationSubject.pipe(
            takeUntilDestroyed(this.destroyRef),
            mergeMap((operation) => this.planManager.processPlanOperation(operation)),
        ).subscribe();
    }

    createSubmitClickSubscription() {
        this.submitClick$.pipe(
            withLatestFrom(this.data$),
            exhaustMap(([voidValue, data]) => this.save(data.validatablePlan.plan)
                // On success tap observer next will be called, on error tap is bypassed and the error is consumed by the error handler
                .pipe(tap((notification) => {
                    if (notification === 'SUCCESS') {
                        this.activeModal.close();
                    }
                    if (notification === 'INVALID' && this.errorSummary) {
                        this.errorSummary.nativeElement.focus();
                        this.errorSummary.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                }),
                      this.appErrorHandler.defaultErrorHandler()),
            ),
            takeUntilDestroyed(this.destroyRef),
        ).subscribe();
    }

    handleValidatablePlanUpdate(gradeScalesById: any, newValidatablePlan: ValidatablePlan): Partial<PlanStructureEditModalData> {
        const { validatablePlan,
            validatablePlanStudyRight,
            module,
            education } = this.modalValues;
        const planValidationResult = PlanValidationTs.validatePlan(newValidatablePlan);
        const educationOptions = this.planStudyRightService.getValidatedEducationOptions(validatablePlan, education, validatablePlanStudyRight);
        const selectionPathInPlan = this.planStudyRightService.getSelectionPathInPlan(validatablePlan, education);
        const planStateAndData = this.planStateService.getPlanStateAndData(
            education,
            newValidatablePlan,
            planValidationResult,
            educationOptions,
            gradeScalesById,
            validatablePlanStudyRight,
        );
        return {
            planData: planStateAndData.planData,
            planStateObject: planStateAndData.planStateObject,
            planValidationResult,
            validatablePlan: newValidatablePlan,
            selectedModule: module,
        };
    }

    focusRule([ruleErrorState]: [RuleErrorState, RuleError]) {
        const el = document.getElementById(ruleErrorState.ruleFocusId);
        if (el) {
            el.focus();
            el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }

    dismiss() {
        this.activeModal.dismiss();
    }

    save(newPlan: Plan): Observable<'SUCCESS' | 'INVALID'> {
        if (this.ruleErrorStateService.hasAnyErrors$()) {
            return of('INVALID');
        }
        return this.planEntityService.updateMyPlan(newPlan)
            .pipe(
                take(1),
                map(() => 'SUCCESS'),
            );
    }

}
