import angular from 'angular';
(function () {
  angular.module('sis-components.topNavigationItems', []).factory('TopNavigationItems', topNavigationItems);
  function topNavigationItems() {
    // linkId could be equal between staff and teacher items when toState is equal (teacher.logged-in.role.teaching,
    // staff.logged-in.studies). Can't say if they should be equal so I left them to be distinct.
    const staffItems = [{
      translation: 'NAVIGATION.STUDENTSEARCH',
      toState: 'tutor.logged-in.student.search',
      toParams: {
        role: 'staff'
      },
      toUrl: '/tutor/role/staff/search/students',
      loginRequired: true,
      linkId: 'top-nav-staff-student-search'
    }, {
      translation: 'NAVIGATION.TEACHING',
      toState: 'teacher.logged-in.role.teaching',
      toParams: {
        role: 'staff'
      },
      toUrl: '/teacher/role/staff/teaching',
      loginRequired: true,
      linkId: 'top-nav-staff-teaching'
    }, {
      translation: 'NAVIGATION.STUDIES',
      toState: 'staff.logged-in.studies',
      // fix this view to point parent of children
      toParams: {
        role: 'staff'
      },
      toUrl: '/staff/studies/staff/',
      loginRequired: true,
      linkId: 'top-nav-staff-studies'
    }, {
      translation: 'NAVIGATION.EDUCATIONS',
      toState: 'staff.logged-in.educations',
      toParams: {
        role: 'staff'
      },
      toUrl: '/staff/studies/staff/educations',
      loginRequired: true,
      linkId: 'top-nav-staff-educations'
    }, {
      translation: 'NAVIGATION.TRANSACTIONS',
      toState: 'staff.logged-in.transactions',
      toParams: {
        role: 'staff'
      },
      toUrl: '/staff/studies/staff/transactions',
      loginRequired: true,
      linkId: 'top-nav-staff-transactions'
    }];
    const teacherItems = [{
      translation: 'NAVIGATION.MY_ASSESSMENTS',
      toState: 'teacher.logged-in.role.teaching',
      toParams: {
        role: 'teacher'
      },
      toUrl: '/teacher/role/teacher/teaching',
      linkId: 'top-nav-teacher-teaching'
    }, {
      translation: 'NAVIGATION.TUTEESEARCH',
      toState: 'tutor.logged-in.student.tutees',
      toParams: {
        role: 'teacher'
      },
      toUrl: '/tutor/role/teacher/tutees',
      loginRequired: true,
      linkId: 'top-nav-teacher-tutees'
    }, {
      translation: 'NAVIGATION.CURRICULUM_PLANNING',
      toState: 'staff.logged-in.studies',
      toParams: {
        role: 'teacher'
      },
      toUrl: '/staff/studies/teacher/',
      loginRequired: true,
      linkId: 'top-nav-teacher-studies'
    }, {
      translation: 'NAVIGATION.CALENDAR',
      toState: 'teacher.logged-in.role.calendar',
      toParams: {
        role: 'teacher'
      },
      toUrl: '/teacher/role/teacher/calendar',
      linkId: 'top-nav-teacher-calendar'
    }];
    const studentItems = [{
      translation: 'APPLICATION_STRUCTURE_OF_STUDIES',
      toState: 'student.logged-in.planSelect',
      loginRequired: true,
      linkId: 'top-nav-student-studies-structure'
    }, {
      translation: 'APPLICATION_STRUCTURE_OF_STUDIES_WIP',
      toState: 'student.logged-in.new-plan',
      loginRequired: true,
      linkId: 'top-nav-student-studies-structure-wip'
    }, {
      translation: 'APPLICATION_TIMELINE',
      toState: 'student.logged-in.plan.timing',
      loginRequired: true,
      linkId: 'top-nav-student-timing'
    }, {
      translation: 'APPLICATION_ENROLMENTS',
      toState: 'student.logged-in.enrolments',
      loginRequired: true,
      linkId: 'top-nav-student-enrolments'
    }, {
      translation: 'CALENDAR',
      toState: 'student.logged-in.calendar',
      loginRequired: true,
      linkId: 'top-nav-student-calendar'
    }, {
      translation: 'APPLICATION_STUDENT_PROFILE',
      toState: 'student.logged-in.profile',
      loginRequired: true,
      linkId: 'top-nav-student-profile'
    }, {
      translation: 'APPLICATION_SEARCH',
      toState: 'student.search',
      loginRequired: false,
      linkId: 'top-nav-student-course-unit-search'
    }];
    const adminItems = [{
      translation: 'NAVIGATION.PERMISSIONS',
      toState: 'admin.logged-in.permissions',
      loginRequired: true,
      linkId: 'top-nav-admin-permissions'
    }, {
      translation: 'NAVIGATION.CODES',
      toState: 'admin.logged-in.codes',
      loginRequired: true,
      linkId: 'top-nav-admin-codes'
    }, {
      translation: 'NAVIGATION.DATA_TRANSFER',
      toState: 'admin.logged-in.data-transfer',
      loginRequired: true,
      linkId: 'top-nav-admin-data-transfer'
    }, {
      translation: 'NAVIGATION.ADDITIONAL_SETTINGS',
      toState: 'admin.logged-in.additional-settings',
      loginRequired: true,
      linkId: 'top-nav-admin-additional-setting'
    }];
    return {
      getStaffItems: function () {
        return staffItems;
      },
      getTeacherItems: function () {
        return teacherItems;
      },
      getStudentItems: function (newPlanTabEnabled) {
        if (!newPlanTabEnabled) {
          return studentItems.filter(item => item.linkId !== 'top-nav-student-studies-structure-wip');
        }
        return studentItems;
      },
      getAdminItems: function () {
        return adminItems;
      }
    };
  }
})();