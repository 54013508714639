function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import angular from 'angular';
import { firstValueFrom, lastValueFrom, of, throwError } from 'rxjs';
import { AuthTokenInterceptorService } from '../http-interceptors/auth-token-interceptor.service.ts';
import { AuthService as NgAuthService } from './auth-service.ts';
import { TokenStorageService } from './token-storage.service.ts';
export const authServiceModule = 'sis-common.auth.service';

/**
 * This is a simplified version of the HttpRequest interface from Angular's HttpClient.
 * It is used to make the request object of the request and responseError methods compatible
 * with an implementation {@link AuthTokenInterceptorService} that is shared by the Angular and AngularJS http-clients
 */
export const httpRequestLike = request => _objectSpread(_objectSpread({}, request), {}, {
  body: request.data,
  headers: _objectSpread(_objectSpread({}, request.headers), {}, {
    get: key => request.headers[key],
    delete: key => delete request.headers[key]
  }),
  clone: updated => {
    const newHeaders = _objectSpread(_objectSpread({}, request.headers), updated?.setHeaders);
    return _objectSpread(_objectSpread({}, request), {}, {
      headers: _objectSpread(_objectSpread({}, newHeaders), {}, {
        get: key => newHeaders[key],
        delete: key => delete newHeaders[key]
      })
    });
  }
});

// As opposed to AngularJs interceptors, Angular HttpInterceptor doesn't have a separate method for handling errors.
// Throw an error to handle it as such.
export const asError = {
  handle: response => throwError(response)
};
export const next = {
  handle: request => of(request)
};
(function () {
  configAuth.$inject = ["$httpProvider"];
  AuthService.$inject = ["$injector", "$rootScope", "$q"];
  BootstrappingDeferralInterceptor.$inject = ["$q", "$timeout", "$rootScope"];
  TokenAuthInterceptor.$inject = ["$q", "$injector"];
  TokenStorage.$inject = ["$window", "$log", "$rootScope", "$injector"];
  angular.module(authServiceModule, [NgAuthService.downgrade.moduleName, AuthTokenInterceptorService.downgrade.moduleName, TokenStorageService.downgrade.moduleName]).factory('TokenStorage', TokenStorage).factory('TokenAuthInterceptor', TokenAuthInterceptor).factory('BootstrappingDeferralInterceptor', BootstrappingDeferralInterceptor).factory('AuthService', AuthService).config(configAuth);

  /**
   * @ngInject
   */
  function configAuth($httpProvider) {
    $httpProvider.interceptors.push('BootstrappingDeferralInterceptor');
    $httpProvider.interceptors.push('TokenAuthInterceptor');
  }

  /**
   * @ngInject
   */
  function TokenStorage($window, $log, $rootScope, $injector) {
    return {
      store: function (token, tokenExpiresIn, sessionTimeoutAt) {
        return $injector.get('tokenStorageService').store(token, tokenExpiresIn, sessionTimeoutAt);
      },
      retrieve: function () {
        return $injector.get('tokenStorageService').retrieve();
      },
      clear: function () {
        $injector.get('tokenStorageService').clear();
      },
      logout: function () {
        $injector.get('tokenStorageService').logout();
      }
    };
  }

  /**
   * @ngInject
   */
  function TokenAuthInterceptor($q, $injector) {
    return {
      request: function (config) {
        const interception$ = $injector.get(AuthTokenInterceptorService.downgrade.serviceName).handleRequest(httpRequestLike(config), next);
        return firstValueFrom(interception$).catch(error => $q.reject(error));
      },
      responseError: function (error) {
        const interception$ = $injector.get(AuthTokenInterceptorService.downgrade.serviceName).handleResponse(httpRequestLike(error.config), asError);
        return firstValueFrom(interception$).catch(() => $q.reject(error));
      }
    };
  }

  /**
   * @ngInject
   * Defers any requests originating from AngularJs $http until the Angular app has been initialized
   * If the Angular app has been initialized, the request is passed through immediately
   * This is needed because of requests that are made from AngularJs during the bootstrap phase, such as JSDataModel initializations
   */
  function BootstrappingDeferralInterceptor($q, $timeout, $rootScope) {
    return {
      request: function (config) {
        if ($rootScope.NgAppInitialized) {
          return config;
        }
        const deferred = $q.defer();
        $rootScope.$on('NgAppInitialized', () => deferred.resolve(config));
        return deferred.promise;
      }
    };
  }

  /**
   * @ngInject
   */
  function AuthService($injector, $rootScope, $q) {
    // NOSONAR
    const api = {
      setAnonymousUrls: function (includedUrls, excludedUrls) {
        $injector.get('ngAuthService').setAnonymousUrls(includedUrls, excludedUrls);
      },
      isAnonymousUrl: function (url) {
        $injector.get('ngAuthService').isAnonymousUrl(url);
      },
      logout: function () {
        $injector.get('ngAuthService').logout();
      },
      username: function () {
        return $injector.get('ngAuthService').parseUser()?.username;
      },
      personId: function () {
        return $injector.get('ngAuthService').parseUser()?.id;
      },
      roles: function () {
        return $injector.get('ngAuthService').parseUser()?.roles;
      },
      scope: function () {
        return $injector.get('ngAuthService').parseUser()?.scope;
      },
      hasScope: function (s) {
        return $injector.get('ngAuthService').hasScope(s);
      },
      displayname: function () {
        return $injector.get('ngAuthService').parseUser()?.displayname;
      },
      firstNameInitialLetter: function () {
        return $injector.get('ngAuthService').parseUser()?.firstNameInitialLetter;
      },
      lastNameInitialLetter: function () {
        return $injector.get('ngAuthService').parseUser()?.lastNameInitialLetter;
      },
      loggedIn: function () {
        return !!$injector.get('ngAuthService').parseUser();
      },
      initializeAppAuth: function () {
        const deferred = $q.defer();
        deferred.resolveAppAuthInitialization = resolveAppAuthInitialization;
        if ($rootScope.NgAppInitialized) {
          deferred.resolveAppAuthInitialization();
        }
        $rootScope.$on('NgAppInitialized', () => {
          deferred.resolveAppAuthInitialization();
        });
        return deferred.promise;
      },
      preAuth: function () {
        return lastValueFrom($injector.get('ngAuthService').refreshAuthToken());
      },
      moveToAuthUrl: function (shibbolethPath, loginTargetUrl) {
        $injector.get('ngAuthService').moveToAuthUrl(shibbolethPath, loginTargetUrl);
      }
    };
    function resolveAppAuthInitialization() {
      return $injector.get('ngAuthService').initializeAppAuth().subscribe(res => {
        this.resolve(res);
      });
    }
    return api;
  }
})();