import _ from 'lodash';
import angular from 'angular';
import { ConfirmDialogService } from 'sis-components/confirm/confirm-dialog.service.ts';
import { HeaderService } from 'sis-components/header/header.service.ts';
import { getInjectables } from 'sis-components/router/router-utils.ts';
import './customAttainmentApplication.component';
import './degreeProgrammeAttainmentApplication.component';
import './extensionApplication.component';
import './priorLearningApplication.component';
import 'sis-components/service/studentApplication.service';
import 'sis-components/service/studyRight.service';
import { ModuleAttainmentApplicationComponent } from './module-attainment-application/module-attainment-application.component.ts';
'use strict';
(function () {
  angular.module('student.profile.applications', ['ui.router', 'ui.router.upgrade', 'sis-components.service.studentApplicationService', 'sis-components.service.studyRightService', 'student.profile.applications.customAttainmentApplication', 'student.profile.applications.degreeProgrammeAttainmentApplication', 'student.profile.applications.extensionApplication', 'student.profile.applications.priorLearningApplication', ConfirmDialogService.downgrade.moduleName, ModuleAttainmentApplicationComponent.downgrade.moduleName]).config(["$stateProvider", $stateProvider => {
    findStudyModuleForApplication.$inject = ["moduleService", "studentApplication"];
    resolveApplicationDisplayName.$inject = ["$transition$", "$translate", "studentApplication", "commonCourseUnitService", "commonStudentApplicationService", "localeService", "STUDENT_APPLICATION_TYPE"];
    findStudentApplication.$inject = ["$transition$", "commonStudentApplicationService"];
    function findStudentApplication($transition$, commonStudentApplicationService) {
      return commonStudentApplicationService.findById($transition$.params().applicationId, {
        bypassCache: true
      });
    }
    function resolveApplicationDisplayName($transition$, $translate, studentApplication, commonCourseUnitService, commonStudentApplicationService, localeService, STUDENT_APPLICATION_TYPE) {
      const applicationType = studentApplication ? studentApplication.type : null;
      switch (applicationType) {
        case STUDENT_APPLICATION_TYPE.PRIOR_LEARNING_SUBSTITUTION_APPLICATION:
          return resolvePriorLearningSubstitutionApplicationDisplayName($translate, studentApplication.courseUnitId, commonCourseUnitService, localeService);
        case STUDENT_APPLICATION_TYPE.PRIOR_LEARNING_INCLUSION_APPLICATION:
          return resolvePriorLearningInclusionApplicationDisplayName($translate, studentApplication.name);
        case STUDENT_APPLICATION_TYPE.CUSTOM_ATTAINMENT_APPLICATION:
          return resolveCustomAttainmentApplicationDisplayName($transition$, $translate, commonStudentApplicationService, STUDENT_APPLICATION_TYPE);
        default:
          return null;
      }
    }
    function resolvePriorLearningSubstitutionApplicationDisplayName($translate, courseUnitId, commonCourseUnitService, localeService) {
      const applicationTypeName = $translate.instant('STUDENT_APPLICATIONS.PRIOR_LEARNING.SUBSTITUTION_APPLICATION');
      return commonCourseUnitService.findById(courseUnitId, {
        loadRelations: true
      }).then(courseUnit => courseUnit ? `${applicationTypeName}: ${localeService.getLocalizedValue(courseUnit.name)}` : applicationTypeName);
    }
    function resolvePriorLearningInclusionApplicationDisplayName($translate, studentApplicationName) {
      const applicationTypeName = $translate.instant('STUDENT_APPLICATIONS.PRIOR_LEARNING.INCLUSION_APPLICATION');
      return studentApplicationName ? `${applicationTypeName}: ${studentApplicationName}` : applicationTypeName;
    }
    function resolveCustomAttainmentApplicationDisplayName($transition$, $translate, commonStudentApplicationService, STUDENT_APPLICATION_TYPE) {
      const name = $translate.instant(`STUDENT_APPLICATIONS.TYPE.${STUDENT_APPLICATION_TYPE.CUSTOM_ATTAINMENT_APPLICATION}`);
      if ($transition$.params().applicationId) {
        return commonStudentApplicationService.findById($transition$.params().applicationId, {
          bypassCache: true
        }).then(application => application && application.name ? `${name}: ${application.name}` : name);
      }
      return name;
    }
    function getModuleAttainmentApplicationDisplayName(transition) {
      const [$translate, localeService, studyModule] = getInjectables(transition, '$translate', 'localeService', 'studyModule');
      const applicationTypeName = $translate.instant('STUDENT_APPLICATIONS.TYPE.ModuleAttainmentApplication');
      return `${applicationTypeName}: ${localeService.getLocalizedValue(studyModule.name)}`;
    }
    function getDegreeProgrammeAttainmentApplicationDisplayName(transition) {
      const [$translate, localeService, degreeProgramme] = getInjectables(transition, '$translate', 'localeService', 'degreeProgramme');
      const applicationTypeName = $translate.instant('STUDENT_APPLICATIONS.TYPE.DegreeProgrammeAttainmentApplication');
      return `${applicationTypeName}: ${localeService.getLocalizedValue(degreeProgramme.name)}`;
    }
    function getExtensionApplicationDisplayName(transition) {
      const [$translate, localeService, education] = getInjectables(transition, '$translate', 'localeService', 'education');
      const applicationTypeName = $translate.instant('STUDENT_APPLICATIONS.TYPE.StudyRightExtensionApplication');
      return `${applicationTypeName}: ${localeService.getLocalizedValue(education.name)}`;
    }
    function findStudyModuleForApplication(moduleService, studentApplication) {
      return moduleService.get(studentApplication.moduleId);
    }
    $stateProvider.state('student.logged-in.profile.applications.custom-attainment-application', {
      url: '/custom-attainment/:applicationId',
      views: {
        '': {
          // Need to use $stateParams instead of $transition$ because the latter
          // is 'null' if we come to this state from its child state with '^'
          controller: ["$scope", "$stateParams", function ($scope, $stateParams) {
            $scope.applicationId = $stateParams.applicationId;
          }],
          template: '<custom-attainment-application application-id="applicationId"></custom-attainment-application>'
        },
        'title@student.logged-in.profile': {
          template: ''
        },
        'secondaryNavigation@student.logged-in.profile': {
          template: ''
        },
        'codepanel@': {
          template: '<code-panel code="code"></code-panel>',
          controller: ["$scope", "studentApplication", function ($scope, studentApplication) {
            $scope.code = studentApplication.code;
          }]
        }
      },
      headerParams: {
        displayNameFunction: transition => transition.injector().get('displayName')
      },
      resolve: {
        studentApplication: findStudentApplication,
        displayName: resolveApplicationDisplayName
      },
      data: {
        showHeader: true,
        showApplicationStateBadge: true
      }
    }).state('student.logged-in.profile.applications.custom-attainment-application.print', {
      url: '/print',
      views: {
        '@': {
          // '@' overrides parent <ui-view>
          controller: ["$scope", "$transition$", function ($scope, $transition$) {
            $scope.applicationId = $transition$.params().applicationId;
          }],
          template: '<student-application-print application-id="applicationId" class="container">' + '</student-application-print>'
        }
      },
      data: {
        showHeader: false
      }
    }).state('student.logged-in.profile.applications.prior-learning-application', {
      url: '/prior-learning/:applicationId?showCancellationMessage',
      views: {
        '': {
          // Need to use $stateParams instead of $transition$ because the latter
          // is 'null' if we come to this state from its child state with '^'
          controller: ["$scope", "$stateParams", function ($scope, $stateParams) {
            $scope.applicationId = $stateParams.applicationId;
            $scope.showCancellationMessage = _.includes([true, 'true'], $stateParams.showCancellationMessage);
          }],
          template: '<prior-learning-application application-id="applicationId" ' + 'show-cancellation-message="showCancellationMessage">' + '</prior-learning-application>'
        },
        'title@student.logged-in.profile': {
          template: ''
        },
        'secondaryNavigation@student.logged-in.profile': {
          template: ''
        },
        'codepanel@': {
          template: '<code-panel code="code"></code-panel>',
          controller: ["$scope", "studentApplication", function ($scope, studentApplication) {
            $scope.code = studentApplication.code;
          }]
        }
      },
      headerParams: {
        displayNameFunction: transition => transition.injector().get('displayName')
      },
      resolve: {
        studentApplication: findStudentApplication,
        displayName: resolveApplicationDisplayName
      },
      data: {
        showHeader: true,
        showApplicationStateBadge: true
      }
    }).state('student.logged-in.profile.applications.module-attainment-application', {
      url: '/module-attainment/:applicationId',
      views: {
        '': {
          controller: ["$scope", "studentApplication", function ($scope, studentApplication) {
            $scope.application = studentApplication;
          }],
          template: '<app-module-attainment-application [application]="application"></app-module-attainment-application>'
        },
        'title@student.logged-in.profile': {
          template: ''
        },
        'secondaryNavigation@student.logged-in.profile': {
          template: ''
        },
        'codepanel@': {
          template: '<code-panel code="code"></code-panel>',
          controller: ["$scope", "studentApplication", function ($scope, studentApplication) {
            $scope.code = studentApplication.code;
          }]
        }
      },
      headerParams: {
        displayNameFunction: getModuleAttainmentApplicationDisplayName
      },
      resolve: {
        studentApplication: findStudentApplication,
        studyModule: findStudyModuleForApplication
      },
      data: {
        showHeader: true,
        showApplicationStateBadge: true
      }
    }).state('student.logged-in.profile.applications.degree-programme-attainment-application', {
      url: '/degree-programme/:applicationId',
      views: {
        '@': {
          // '@' overrides parent <ui-view>
          component: 'degreeProgrammeAttainmentApplication'
        },
        'codepanel@': {
          template: '<code-panel code="code"></code-panel>',
          controller: ["$scope", "application", function ($scope, application) {
            $scope.code = application.code;
          }]
        }
      },
      headerParams: {
        displayNameFunction: getDegreeProgrammeAttainmentApplicationDisplayName
      },
      resolve: {
        application: findStudentApplication,
        degreeProgramme: ["moduleService", "application", (moduleService, application) => moduleService.get(_.get(application, 'moduleId'))]
      },
      data: {
        showHeader: true,
        showApplicationStateBadge: true
      }
    }).state('student.logged-in.profile.applications.study-right-extension-application', {
      url: '/study-right-extension/:applicationId?showSubmitSuccess&showCancellationMessage',
      views: {
        '': {
          component: 'extensionApplication'
        },
        'title@student.logged-in.profile': {
          template: ''
        },
        'secondaryNavigation@student.logged-in.profile': {
          template: ''
        },
        'codepanel@': {
          template: '<code-panel code="code"></code-panel>',
          controller: ["$scope", "studentApplication", function ($scope, studentApplication) {
            $scope.code = studentApplication.code;
          }]
        }
      },
      headerParams: {
        displayNameFunction: getExtensionApplicationDisplayName
      },
      resolve: {
        applicationId: ["$stateParams", $stateParams => $stateParams.applicationId],
        showSubmitSuccess: ["$stateParams", $stateParams => _.includes([true, 'true'], $stateParams.showSubmitSuccess)],
        showCancellationMessage: ["$stateParams", $stateParams => _.includes([true, 'true'], $stateParams.showCancellationMessage)],
        studentApplication: findStudentApplication,
        education: ["commonEducationService", "studentApplication", (commonEducationService, studentApplication) => commonEducationService.findById(studentApplication.educationId)]
      },
      data: {
        showHeader: true,
        showApplicationStateBadge: true
      }
    }).state('student.logged-in.profile.applications.prior-learning-application.print', {
      url: '/print',
      views: {
        '@': {
          // '@' overrides parent <ui-view>
          controller: ["$scope", "$transition$", function ($scope, $transition$) {
            $scope.applicationId = $transition$.params().applicationId;
          }],
          template: '<student-application-print application-id="applicationId" ' + 'class="container"></student-application-print>'
        }
      },
      data: {
        showHeader: false
      }
    });
  }]).run(["$window", "$state", "$transitions", "$translate", "$injector", ($window, $state, $transitions, $translate, $injector) => {
    // Hooks for showing a confirmation modal when leaving an unsaved student application form.
    // 2 different mechanisms are needed: confirmDiscardApplication() shows a SISU modal when
    // the user attempts to leave the page via a ui-router transition (e.g. $state.go()); and
    // a native 'beforeunload' listener that shows a browser-specific, unmodifiable confirmation
    // modal for other navigation events, e.g. page refreshes and when using window.location = ...
    $transitions.onBefore({
      from: isApplicationCreateState
    }, confirmDiscardApplication);
    $transitions.onSuccess({
      to: isApplicationCreateState
    }, () => $window.addEventListener('beforeunload', confirmUnloadListener));
    $transitions.onExit({
      from: isApplicationCreateState
    }, () => $window.removeEventListener('beforeunload', confirmUnloadListener));
    function isApplicationCreateState(state) {
      return _.startsWith(state.name, 'student.logged-in.profile.applications.create-');
    }

    /**
     * Listener for the beforeunload event, which shows a native confirmation dialog to make
     * sure the user doesn't leave the page accidentally.
     */
    function confirmUnloadListener(event = $window.event) {
      event.preventDefault();
      event.returnValue = '';
    }

    /**
     * This function shows a confirmation dialog if the user attempts to navigate from an
     * unsaved application without saving to any other state except the application view
     * state (which is shown after the application is submitted).
     */
    function confirmDiscardApplication(transition) {
      // Ignore redirects, otherwise the modal will be shown several times
      if (_.isNil(transition.redirectedFrom()) && !_.get(transition.options(), 'custom.skipConfirmationDialog')) {
        const fromPath = getTransitionPath(transition.$from());
        const toPath = getTransitionPath(transition.$to());

        // The student application URLs are constructed so that the start of the URL is the same
        // for create and view states, with the former being shorter. So by checking if the start
        // of the path is the same, we know if the user is navigating to the application view
        // state or somewhere else.
        if (!fromPath || !toPath || !_.startsWith(toPath, fromPath)) {
          // Inject confirmDialogService manually since downgraded service injectors are not available when the 'run' method
          // is executed
          const confirmDialogService = $injector.get('confirmDialogService');
          return confirmDialogService.confirm({
            title: 'PROFILE.APPLICATIONS.CONFIRM_DISCARD_APPLICATION.TITLE',
            description: 'PROFILE.APPLICATIONS.CONFIRM_DISCARD_APPLICATION.BODY'
          }).then(angular.noop, () => {
            const {
              location
            } = $window;
            if (location.pathname + location.search !== $state.href($state.$current)) {
              // The browser already changed URL; undo it. This "cancels" browser back button
              // clicks and other non-ui-router transitions.
              $window.history.replaceState(null, null, $state.href($state.$current));
            }
            return false;
          });
        }
      }
    }
    function getTransitionPath(transition) {
      return _.get(transition, 'path', []).map(path => path.url.pattern).join('').replace(/\?.*/, '');
    }
    $transitions.onSuccess({
      to: state => !!state.data && state.data.showApplicationStateBadge
    }, transition => {
      // Some states use the 'studentApplication' resolve token for the application, other use just 'application'.
      let applicationToken;
      const tokens = transition.getResolveTokens();
      if (tokens.includes('studentApplication')) {
        applicationToken = 'studentApplication';
      } else if (tokens.includes('application')) {
        applicationToken = 'application';
      }
      if (applicationToken) {
        const [headerService, application] = getInjectables(transition, HeaderService, applicationToken);
        headerService.setApplicationStateBadge(application);
      }
    });
  }]);
})();