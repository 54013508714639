<ng-container *transloco="let t">
  <fudis-dialog [size]="'sm'">
    <fudis-dialog-content>
        <fudis-form [title]="t('PROFILE.CLASSIFIED.TITLE')"
                    [titleLevel]="2"
                    [errorSummaryHelpText]="t('ERROR-SUMMARY.HELP_TEXT')">
          <ng-template fudisActions [type]="'form'">
            <fudis-button [label]="t('SIS_COMPONENTS.BUTTON.SAVE')" (handleClick)="onSubmit()" />
            <fudis-button fudisDialogClose [label]="t('SIS_COMPONENTS.BUTTON.CANCEL')" [variant]="'secondary'" />
          </ng-template>
          <ng-template fudisContent [type]="'form'">
            <fudis-body-text *ngIf="changedByOther()">
              {{ staffEdit
              ? t('PROFILE.CLASSIFIED.DONE_BY_STAFF', {
                changedBy: changedById|publicPerson|async|personFullName,
                changedOn: (changedByDate|localDateFormat) })
              : t('PROFILE.CLASSIFIED.DONE_BY_STUDENT', {changedOn: (changedByDate|localDateFormat) }) }}
            </fudis-body-text>
            <fudis-checkbox-group [label]="t('PROFILE.CLASSIFIED.CHECKBOX_GROUP_LEGEND')" [formGroup]="classifiedPersonInfoToggleForm">
              <fudis-checkbox [label]="t('PROFILE.CLASSIFIED.PHONE_NUMBER')" [controlName]="'isPhoneNumberClassified'" />
              <fudis-checkbox [label]="t('PROFILE.CLASSIFIED.EMAIL2')" [controlName]="'isSecondaryEmailClassified'" />
              <fudis-checkbox [label]="t('PROFILE.CLASSIFIED.HOME_MUNICIPALITY')" [controlName]="'isMunicipalityUrnClassified'" />
              <fudis-checkbox [label]="t('PROFILE.CLASSIFIED.PRIMARY_ADDRESS')" [controlName]="'isPrimaryAddressClassified'" />
              <fudis-checkbox [label]="t('PROFILE.CLASSIFIED.SECONDARY_ADDRESS')" [controlName]="'isSecondaryAddressClassified'" />
            </fudis-checkbox-group>
          </ng-template>
        </fudis-form>
    </fudis-dialog-content>
  </fudis-dialog>
</ng-container>
