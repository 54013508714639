import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Code, Urn } from 'common-typescript/types';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';

import { getLabelState } from '../../form/formUtils';
import { CommonCodeService } from '../../service/common-code.service';

/** Lists all code books in Sisu. If you need to use a code book that's not listed here, simply add it. */
type CodeBookName =
    'admission-type' |
    'approval-state-type' |
    'assessment-item-type' |
    'attainment-acceptor-type' |
    'category-tag' |
    'cefr-level' |
    'classification-scope' |
    'competency' |
    'country' |
    'course-unit-realisation-responsibility-info-type' |
    'course-unit-realisation-type' |
    'course-unit-type' |
    'custom-qualification-type' |
    'decree-on-university-degrees' |
    'degree-program-type' |
    'degree-title' |
    'education-classification' |
    'education-option-naming-type' |
    'education-responsibility-info-type' |
    'education-type' |
    'educational-institution' |
    'funding-source' |
    'gender' |
    'grant-type' |
    'group-responsibility-info-type' |
    'honorary-title' |
    'international-institution' |
    'language' |
    'mobility-program' |
    'mobility-study-right-type' |
    'mobility-type' |
    'module-responsibility-info-type' |
    'municipality' |
    'official-language' |
    'organisation-role' |
    'permission' |
    'preferred-language' |
    'qualification' |
    'school-education-language' |
    'specialisation-studies-classification' |
    'study-field' |
    'study-level' |
    'study-right-acceptance-type' |
    'study-right-classification' |
    'study-right-expiration-rules' |
    'study-right-selection-type' |
    'subject' |
    'thesis-type';

interface Option {
    urn: Urn;
    label: string;
    groupLabel?: string;
    disabled?: boolean;
}

/**
 * A component for selecting a single Code urn value from the given code book. This is effectively a simplified version
 * of the `CodeSelectEditorComponent`/`CodeSelectionEditorComponent` combo. Key differences:
 *
 * 1. This component is designed to work effortlessly in (and ONLY in) Angular reactive forms. Supports displaying
 * an optional label and validation errors. This component won't work in AngularJS.
 * 2. This component is missing some bells and whistles from the `CodeSelect[ion]Editor`, such as displaying the last
 * segment of each code as part of the label (thus making them searchable), or displaying code names in all languages.
 */
@Component({
    selector: 'sis-code-select',
    templateUrl: './code-select.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CodeSelectComponent implements OnInit, OnDestroy {

    @Input() set codeBookName(codeBookName: CodeBookName) {
        this.initOptions(codeBookName ? `urn:code:${codeBookName}` : null);
    }

    @Input() control: FormControl;
    @Input() label?: string;
    @Input() clearable = false;
    /** Deprecated codes are always visible in the selection list. This property controls whether they're disabled or selectable. */
    @Input() disableDeprecated = true;
    @Input() showLabelIcon = true;
    @Input() maxOptions = 20;

    destroyed$ = new Subject<void>();
    allOptions: Option[];
    visibleOptions: Option[];
    selectedOption: Option;

    readonly tooManyResultsOption: Option = {
        label: this.translate.instant('SIS_COMPONENTS.SELECT.TOO_MANY_SEARCH_RESULTS'),
        urn: null,
        disabled: true,
    };

    constructor(
        private codeService: CommonCodeService,
        private localeService: LocaleService,
        private translate: TranslateService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.control?.valueChanges
            .pipe(
                takeUntil(this.destroyed$),
                filter(urn => this.selectedOption?.urn !== urn),
            )
            .subscribe((urn) => {
                this.selectedOption = this.findOption(urn);
                this.cdr.markForCheck();
            });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
    }

    getLabelState(): string {
        return (this.showLabelIcon && this.label) ? getLabelState(this.control) : '';
    }

    clearFiltering(): void {
        this.setVisibleOptions(this.allOptions);
    }

    onBlur(): void {
        this.control?.markAsTouched();
    }

    onChange(option: Option): void {
        this.selectedOption = option;
        this.control?.setValue(option?.urn);
        this.control?.markAsDirty();
        this.clearFiltering();
    }

    onSearch(query: string): void {
        if (!query) {
            this.clearFiltering();
        } else {
            const lower = query.toLowerCase();
            this.setVisibleOptions(this.allOptions.filter(option => option.label.toLowerCase().includes(lower)));
        }
    }

    private async initOptions(codeBookUrn: Urn): Promise<void> {
        if (codeBookUrn) {
            const [codeBook = {}, universityCodeBookUsage = []] = await Promise.all([
                this.codeService.getCodebook(codeBookUrn),
                this.codeService.getCodeBookUniversityUsage(codeBookUrn),
            ]);

            // Sort the options primarily by university usage, secondarily by label. This way the university-specific
            // active values will always be shown first. Note that the sorting by label is based on Unicode code point
            // values, similarly to e.g. Array.prototype.sort(). This will cause all uppercase characters to have priority
            // over all lowercase characters (e.g. ['a', 'b', 'B', 'A'] => ['A', 'B', 'a', 'b']). This is intentional,
            // as this is how items are sorted in CodeSelectionEditorComponent (and its predecessor) as well.
            this.allOptions = _(Object.values(codeBook))
                .map(code => this.codeToOption(code, universityCodeBookUsage.includes(code.urn)))
                .sortBy(option => universityCodeBookUsage.includes(option.urn) ? 0 : 1, 'label')
                .value();

            this.setVisibleOptions(this.allOptions);
            this.selectedOption = this.findOption(this.control?.value);
            this.cdr.markForCheck();
        } else {
            delete this.allOptions;
            delete this.visibleOptions;
            delete this.selectedOption;
        }
    }

    findOption(urn: Urn): Option {
        return urn ? this.allOptions?.find(option => option.urn === urn) : null;
    }

    private setVisibleOptions(options: Option[] = []): void {
        this.visibleOptions = options.length > this.maxOptions ?
            [...options.slice(0, this.maxOptions), this.tooManyResultsOption] : [...options];
    }

    private codeToOption(code: Code, isHomeUniversityCode: boolean): Option {
        return {
            urn: code.urn,
            label: [
                this.localeService.localize(code.name),
                code.universitySpecifier ? `(${code.universitySpecifier})` : null,
                code.deprecated ? this.translate.instant('SIS_COMPONENTS.SELECT.DEPRECATED') : null,
            ].filter(Boolean).join(' '),
            groupLabel: this.translate.instant(`SIS_COMPONENTS.SELECT.${isHomeUniversityCode ? 'MY_UNIVERSITY' : 'OTHER_UNIVERSITIES'}`),
            disabled: this.disableDeprecated && !!code.deprecated,
        };
    }
}
