import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    Inject,
    Input,
    OnChanges,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { ValidatablePlan } from 'common-typescript/src/plan/validation/validatablePlan';
import {
    Education,
    Enrolment,
    OtmId,
    StudyRight,
} from 'common-typescript/types';
import _ from 'lodash';
import moment from 'moment';
import {
    BehaviorSubject, defaultIfEmpty,
    from,
    Observable,
} from 'rxjs';
import { first, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { LocalStorageService } from 'sis-common/storage/local-storage.service';
import { PLAN_LOADER } from 'sis-components/ajs-upgraded-modules';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { Breakpoint, BreakpointService } from 'sis-components/service/breakpoint.service';
import { EducationEntityService } from 'sis-components/service/education-entity.service';
import { PlanEntityService } from 'sis-components/service/plan-entity.service';
import { StudyRightEntityService } from 'sis-components/service/study-right-entity.service';
import { convertAJSPromiseToNative } from 'sis-components/util/utils';

@Component({
    selector: 'app-teaching-not-selected',
    templateUrl: './teaching-not-selected.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeachingNotSelectedComponent implements OnInit, OnChanges {
    @Input() enrolments: Enrolment[];

    localStorageKeyStudyRight = 'enrolments-select-studies.studyright';

    isMobileView: boolean;
    enrolmentsInCalendar: Enrolment[] = [];
    studyRight: StudyRight;
    studyRights$: Observable<StudyRight[]>;

    education$: Observable<Education>;
    validatablePlan$: BehaviorSubject<ValidatablePlan> = new BehaviorSubject(undefined);

    constructor(private planEntityService: PlanEntityService,
                private localStorageService: LocalStorageService,
                private educationEntityService: EducationEntityService,
                private breakpointService: BreakpointService,
                private studyRightEntityService: StudyRightEntityService,
                private errorHandler: AppErrorHandler,
                private changeDetectorRef: ChangeDetectorRef,
                @Inject(PLAN_LOADER) private planLoader: any) {
    }

    ngOnInit() {
        this.studyRights$ = this.getStudyRights();
        const breakpoint = this.breakpointService.getCurrentBreakpoint();
        this.isMobileView = breakpoint < Breakpoint.SM;
        this.enrolmentsInCalendar = this.enrolments.filter(enrolment => enrolment.isInCalendar);
    }

    ngOnChanges() {
        this.enrolmentsInCalendar = this.enrolments.filter(enrolment => enrolment.isInCalendar);
    }

    getStudyRights(): Observable<StudyRight[]> {
        return this.studyRightEntityService.getStudyRightsForCurrentUser()
            .pipe(map(studyRights => studyRights
                .filter(studyRight => !studyRight.valid?.endDate || moment().isSameOrBefore(studyRight.valid.endDate))),
                  tap(studyRights => {
                      if (studyRights.length === 1) {
                          this.studyRight = studyRights[0];
                          this.onStudyRightChange(this.studyRight);
                      } else if (studyRights.length > 1) {
                          const id = this.localStorageService.getItem(this.localStorageKeyStudyRight);
                          const studyRight = id ? _.find(studyRights, { id }) : undefined;
                          if (studyRight) this.onStudyRightChange(studyRight);
                      }
                  }));
    }

    onStudyRightChange(studyRight: StudyRight) {
        this.studyRight = studyRight;
        if (this.studyRight) {
            this.localStorageService.setItem(this.localStorageKeyStudyRight, studyRight.id);
            this.education$ = this.getEducation(this.studyRight.educationId);
            this.getValidatablePlan();
        }
    }

    getValidatablePlan() {
        this.validatablePlan$.next(undefined);

        return this.planEntityService.getByUserIdAndEducationIdAndLearningOpportunityId(
            this.studyRight.educationId,
            this.studyRight.studentId,
            this.studyRight.learningOpportunityId).pipe(
            this.errorHandler.defaultErrorHandler(),
            map(plans => plans.filter(plan => plan.primary)),
            first(),
            mergeMap(plan => plan),
            switchMap(plan => from(convertAJSPromiseToNative(this.planLoader.createValidatablePlan(plan, true))) as Observable<ValidatablePlan>),
            defaultIfEmpty(null),
        ).subscribe(plan => {
            this.validatablePlan$.next(plan);
            this.changeDetectorRef.markForCheck();
        });
    }

    getEducation(educationId: OtmId) {
        return this.educationEntityService.getById(educationId);
    }
}
