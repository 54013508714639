import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { AUTH_SERVICE } from 'sis-common/ajs-upgraded-modules';
import { ConfigService } from 'sis-common/config/config.service';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

interface IdentityProvider {
    name: string;
    navigate: Function;
}

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-login-provider-menu',
    templateUrl: './login-provider-menu.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class LoginProviderMenuComponent implements OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.topNav.loginProviderMenu.downgraded',
        directiveName: 'sisLoginProviderMenu',
    };

    identityProviders: IdentityProvider[] = [];

    constructor(private configService: ConfigService,
                @Inject(AUTH_SERVICE) private authService: any) {}

    ngOnInit() {
        const universityConfig = this.configService.get();
        this.identityProviders = (universityConfig.identityProviderLogins || []).map(login => ({
            name: `Login ${login.abbr}`,
            navigate: () => this.authService.moveToAuthUrl(login.shibbolethUrl, window.location.href),
        }));
    }
}
