import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CompletionMethod } from 'common-typescript/types';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-completion-method-assessment-item-info',
    templateUrl: './completion-method-assessment-item-info.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class CompletionMethodAssessmentItemInfoComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.courseUnit.completionMethods.completionMethodAssessmentItemInfo',
        directiveName: 'sisCompletionMethodAssessmentItemInfo',
    };

    @Input() completionMethod: CompletionMethod;
    @Input() language: string;

}
