customAttainmentApplicationController.$inject = ["$scope", "$state", "$timeout", "$translate", "alertsService", "commonStudentApplicationService", "cancelApplicationModalService", "workflowDataChangeService", "applicationDecisionJSDataModel", "STUDENT_APPLICATION_STATE", "appErrorHandler"];
import angular from 'angular';
import _ from 'lodash';
import { ApplicationFilesComponent } from 'sis-components/file-upload/application-files.component.ts';
import { WorkflowDataChangeService } from 'sis-components/service/workflow-data-change.service.ts';
import { STUDENT_WORKFLOW_STATE } from 'sis-components/model/student-workflow-constants.ts';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler.ts';
import { take } from 'rxjs/operators';
import customAttainmentApplicationTpl from './customAttainmentApplication.tpl.html';
angular.module('student.profile.applications.customAttainmentApplication', ['pascalprecht.translate', 'student.common.components', 'student.profile.applications.cancelApplicationModal', 'sis-components.applications.customApplicationAttainmentSummary', 'sis-components.model.applicationDecision', 'sis-components.model.studentApplication', 'sis-components.service.studentApplicationService', AppErrorHandler.downgrade.moduleName, ApplicationFilesComponent.downgrade.moduleName, WorkflowDataChangeService.downgrade.moduleName]).component('customAttainmentApplication', {
  template: customAttainmentApplicationTpl,
  bindings: {
    applicationId: '<'
  },
  controller: customAttainmentApplicationController
});
function customAttainmentApplicationController($scope, $state, $timeout, $translate, alertsService,
// NOSONAR
commonStudentApplicationService, cancelApplicationModalService, workflowDataChangeService, applicationDecisionJSDataModel, STUDENT_APPLICATION_STATE, appErrorHandler) {
  $scope.STUDENT_APPLICATION_STATE = STUDENT_APPLICATION_STATE;
  const ctrl = this;
  let ready = false;
  ctrl.$onInit = function () {
    commonStudentApplicationService.findById(ctrl.applicationId, {
      bypassCache: true
    }).then(application => {
      if (application.decision) {
        return applicationDecisionJSDataModel.loadRelations(application.decision).then(() => application);
      }
      return application;
    }).then(application => {
      ready = true;
      ctrl.application = application;
      ctrl.applicationCollapsed = ctrl.shouldShowDecision();
      ctrl.decisionCollapsed = !ctrl.applicationCollapsed;
      ctrl.getActiveSupplementRequestDescription();
    });
  };
  ctrl.shouldShowCancelButton = function () {
    return ready && _.includes([STUDENT_APPLICATION_STATE.REQUESTED, STUDENT_APPLICATION_STATE.IN_HANDLING, STUDENT_APPLICATION_STATE.SUPPLEMENT_REQUESTED], ctrl.application.state);
  };
  ctrl.shouldShowDecision = function () {
    return ready && _.includes([STUDENT_APPLICATION_STATE.ACCEPTED, STUDENT_APPLICATION_STATE.REJECTED, STUDENT_APPLICATION_STATE.ACCEPTANCE_REVOKED], ctrl.application.state) && _.isObject(ctrl.application.decision);
  };
  ctrl.shouldShowAttachmentDeliveryPossible = function () {
    return ready && _.includes([STUDENT_APPLICATION_STATE.REQUESTED], ctrl.application.state);
  };
  ctrl.openCancelApplicationModal = function () {
    cancelApplicationModalService.open(ctrl.application).then(() => {
      $state.reload();
      alertsService.success($translate.instant('PROFILE.APPLICATIONS.APPLICATION_CANCELLED_ALERT'), 6000);
    }).catch(angular.noop);
  };
  ctrl.getActiveSupplementRequestDescription = function () {
    if (ctrl.shouldShowSupplementRequestRationale()) {
      workflowDataChangeService.getActiveSupplementRequestDescription(ctrl.applicationId).pipe(appErrorHandler.defaultErrorHandler(), take(1)).subscribe(response => {
        $scope.$evalAsync(() => {
          ctrl.rationale = response;
        });
      });
    }
  };
  ctrl.shouldShowSupplementRequestRationale = function () {
    return ready && _.includes([STUDENT_WORKFLOW_STATE.SUPPLEMENT_REQUESTED], ctrl.application.state);
  };
  ctrl.showSupplementApplicationButton = function () {
    return ready && ctrl.application.state === STUDENT_APPLICATION_STATE.SUPPLEMENT_REQUESTED;
  };
}