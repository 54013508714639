import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CourseUnit, CourseUnitRealisation, Education, Enrolment, EnrolmentQuestionnaire, EnrolmentQuestionnaireAnswers, Plan, StudyRight } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';

export interface EnrolmentValues {
    enrolment: Enrolment;
    enrolmentQuestionnaire: EnrolmentQuestionnaire;
    enrolmentQuestionnaireAnswers: EnrolmentQuestionnaireAnswers;
    courseUnit: CourseUnit;
    courseUnitRealisation: CourseUnitRealisation;
    myPlans?: Plan[];
    myStudyRightEducations: Education[];
    myStudyRights: StudyRight[];
    update: boolean;
}

@Component({
    selector: 'app-enrolment-modal',
    template: '<enrolment-modal (close)="activeModal.close($event.$value)" (dismiss)="activeModal.dismiss()" [resolve]="values"></enrolment-modal>',
    encapsulation: ViewEncapsulation.None,
})
export class EnrolmentModalComponent {
    constructor(@Inject(ModalService.injectionToken) public values: EnrolmentValues,
                public activeModal: NgbActiveModal) {}
}
