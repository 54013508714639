<h2 translate="STUDENT_MESSAGES.TITLE.NEWEST_MESSAGES_AND_NOTIFICATIONS"></h2>
<sis-generic-container>
  <ul class="frontpage-notifications" *ngIf="result && result.total > 0">
    <li *ngFor="let messageConversation of result.searchResults" data-cy="frontpage-messages">
      <a href="javascript:void(0);" class="message row anchor-button"
         (click)="goToMessageConversation(messageConversation.conversationId)"
         [ngClass]="{'unread-message': messageConversation.messagesUnread > 0}"
         [attr.aria-label]="((messageConversation.selectedMessage.senderName | localizedString) || '') + ' ' + (getSenderFullName(messageConversation.selectedMessage)|| '') + ' ' +
           ((getMessageTypeTranslationKey(messageConversation.selectedMessage) | translate) || '') + ' ' + (getMessageTargetName(messageConversation.selectedMessage) || '') + ' ' +
           (messageConversation.selectedMessage.title || '') + ' ' + ((messageConversation.selectedMessage.localizedTitle|localizedString) || '') + '. ' +
           ('ARIA_LABEL.UNREAD_MESSAGES' | translate) + ' ' + messageConversation.messagesUnread + ', ' + ('ARIA_LABEL.OPEN_MESSAGE_CONVERSATION' | translate) + '.'">
        <div class="col-8 col-sm-9 col-md-8 col-lg-7 col-xl-8">
          <div class="message-preview-header">
            <span class="message-sender" *ngIf="messageConversation.selectedMessage.type === 'AutomaticCourseUnitRealisationMessage' || messageConversation.selectedMessage.type === 'EnrolmentReminderMessage'">{{messageConversation.selectedMessage.senderName | localizedString }}</span>
            <span class="message-sender" *ngIf="messageConversation.selectedMessage.type !== 'AutomaticCourseUnitRealisationMessage' && messageConversation.selectedMessage.type !== 'EnrolmentReminderMessage'">{{ getSenderFullName(messageConversation.selectedMessage) }}</span>
            <span class="message-type">&nbsp;|&nbsp;{{ getMessageTypeTranslationKey(messageConversation.selectedMessage) | translate}} {{getMessageTargetName(messageConversation.selectedMessage)}}</span>
          </div>
          <div class="message-snipped">
            <span *ngIf="messageConversation.selectedMessage.title" class="message-title">{{messageConversation.selectedMessage.title}}</span>
            <span *ngIf="messageConversation.selectedMessage.localizedTitle" class="message-title">{{messageConversation.selectedMessage.localizedTitle|localizedString}}</span>
            <span class="message-comment">
                        <span *ngIf="messageConversation.selectedMessage.title||messageConversation.selectedMessage.localizedTitle"> |&nbsp;</span><!--
                    -->{{messageConversation.selectedMessage.comment ? messageConversation.selectedMessage.comment:''}}<!--
                    -->{{messageConversation.selectedMessage.localizedComment? (messageConversation.selectedMessage.localizedComment|localizedString):''}}
                    </span>
          </div>
        </div>
        <div class="notification-additional col-4 col-sm-3 col-md-4 col-lg-5 col-xl-4">
          <div class="message-conversation-info">
            <sis-tooltip
              *ngIf="(messageConversation.messagesUnread <= 1 && messageConversation.messagesTotal > 1)"
              [tooltipText]="'STUDENT_MESSAGES.TOOLTIP_TEXT.MESSAGE_CONVERSATION_NUMBER_OF_TOTAL_MESSAGES' | translate:{totalMessageCount: messageConversation.messagesTotal}">
                    <span class="badge badge-secondary sis-tooltip-trigger-content">
                        {{messageConversation.messagesTotal}}
                    </span>
            </sis-tooltip>
            <sis-tooltip
              *ngIf="messageConversation.messagesUnread >= 2"
              [tooltipText]="'STUDENT_MESSAGES.TOOLTIP_TEXT.MESSAGE_CONVERSATION_NUMBER_OF_UNREAD_MESSAGES' | translate:{unreadMessageCount: messageConversation.messagesUnread}">
                    <span class="badge badge-danger sis-tooltip-trigger-content">
                        {{messageConversation.messagesUnread}}
                    </span>
            </sis-tooltip>
          </div>
          <span class="message-date">{{messageConversation.selectedMessage.messageTime | localDateFormat }}</span>
          <span class="message-time">{{messageConversation.selectedMessage.messageTime | localTimeFormat }}</span>
        </div>
      </a>
    </li>
    <li class="button-holder">
      <button
         class="btn btn-secondary btn-hollow btn-sm"
         translate="STUDENT_MESSAGES.BUTTON.MOVE_TO_RECEIVED_MESSAGES"
         (click)="moveToReceivedMessages()">
      </button>
    </li>
  </ul>
  <p *ngIf="result &&result.total === 0" class="no-messages" translate="MESSAGES.NO_MESSAGES"></p>
</sis-generic-container>
