import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { StudyRight, TermRegistration, TermRegistrationPeriod } from 'common-typescript/types';
import _ from 'lodash';
import moment from 'moment';
import { combineLatest, from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AUTH_SERVICE, DEFAULT_PROMISE_HANDLER } from 'sis-common/ajs-upgraded-modules';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';
import { COMMON_STUDY_RIGHT_SERVICE, COMMON_TERM_REGISTRATION_PERIOD_SERVICE } from 'sis-components/ajs-upgraded-modules';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { StudyRightEntityService } from 'sis-components/service/study-right-entity.service';
import { getStudyTermLocator, getStudyTermStartDate } from 'sis-components/study-terms/study-year-utils';
import { convertAJSPromiseToNative } from 'sis-components/util/utils';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'app-student-term-registration-period-info',
    templateUrl: './student-term-registration-period-info.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class StudentTermRegistrationPeriodInfoComponent implements OnInit {

    constructor(private appErrorHandler: AppErrorHandler,
                private studyRightEntityService: StudyRightEntityService,
                @Inject(AUTH_SERVICE) private authService: any,
                @Inject(COMMON_STUDY_RIGHT_SERVICE) private studyRightService: any,
                @Inject(COMMON_TERM_REGISTRATION_PERIOD_SERVICE) private termRegistrationPeriodService: any,
                @Inject(DEFAULT_PROMISE_HANDLER) private defaultPromiseHandler: any) { }

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.frontpage.studentTermRegistrationPeriodInfo',
        directiveName: 'appStudentTermRegistrationPeriodInfo',
    };

    ongoingTermRegistrationPeriods$: Observable<TermRegistrationPeriod[]>;
    neglectedTermRegistrationPeriods$: Observable<TermRegistrationPeriod[]>;

    ngOnInit(): void {
        this.fetchStudyRights()
            .pipe(
                map(studyRights => studyRights.flatMap(studyRight => studyRight.termRegistrations)),
            )
            .subscribe({
                next: (allTermRegistrations) => {
                    if (allTermRegistrations?.length > 0) {
                        this.ongoingTermRegistrationPeriods$ =
                            this.findOngoingTermRegistrationPeriodsWithMissingRegistrations(allTermRegistrations);
                        this.neglectedTermRegistrationPeriods$ = this.findNeglectedTermRegistrationPeriods(allTermRegistrations);
                    }
                },
                error: this.defaultPromiseHandler.loggingRejectedPromiseHandler,
            });
    }

    /**
     * Returns an observable of study rights whose state is ACTIVE or PASSIVE, whose validity period is ongoing, and
     * where the corresponding education has been configured to require term registrations.
     */
    fetchStudyRights(): Observable<StudyRight[]> {
        return this.studyRightEntityService.getStudyRightsByStudentId(this.authService.personId())
            .pipe(
                this.appErrorHandler.defaultErrorHandler(),
                map(studyRights => studyRights.filter(studyRight => this.studyRightService.isValid(moment(), studyRight))),
                this.studyRightEntityService.filterByTermRegistrationRequirement(true),
            );
    }

    /**
     * Find ongoing term registration periods that match with any term registration with type MISSING in the given array.
     */
    findOngoingTermRegistrationPeriodsWithMissingRegistrations(termRegistrations: TermRegistration[]): Observable<TermRegistrationPeriod[]> {
        return from(convertAJSPromiseToNative(this.termRegistrationPeriodService.findOngoingForCurrentUniversity() as Promise<TermRegistrationPeriod[]>))
            .pipe(
                switchMap(periods => of(this.findPeriodsWithMissingRegistrations(termRegistrations, periods))),
                catchError(this.defaultPromiseHandler.loggingRejectedPromiseHandler),
            );
    }

    /**
     * Find missing or neglected term registrations for current study term and onwards from the given array, and check if
     * there are expired term registration periods that match with any of the registrations. The study right recovery text
     * will then be displayed from these term registration periods.
     */
    findNeglectedTermRegistrationPeriods(termRegistrations: TermRegistration[]): Observable<TermRegistrationPeriod[]> {
        const currentTermStartDate = getStudyTermStartDate(getStudyTermLocator());
        const studyTermsWithMissingOrNeglectedTermRegistrations = termRegistrations
            .filter(({ termRegistrationType }) => termRegistrationType === 'NEGLECTED' || termRegistrationType === 'MISSING')
            .filter(({ studyTerm }) => currentTermStartDate.isSameOrBefore(getStudyTermStartDate(studyTerm)))
            .map(registration => registration.studyTerm);

        const registrationPeriodPromises: Promise<TermRegistrationPeriod>[] =
            _.uniqWith(studyTermsWithMissingOrNeglectedTermRegistrations, _.isEqual)
                .map(studyTerm => this.termRegistrationPeriodService.findPastRegistrationPeriodForStudyTerm(studyTerm));
        if (registrationPeriodPromises.length === 0) {
            return of([]);
        }

        return combineLatest(registrationPeriodPromises)
            .pipe(
                map(neglectedPeriods => _.uniqBy(neglectedPeriods.filter(period => period), 'id')),
                catchError(this.defaultPromiseHandler.loggingRejectedPromiseHandler),
            );
    }

    /**
     * Returns a filtered version of the given `periods` array which contains only those periods for which there is a
     * corresponding term registration with type MISSING in the given `registrations` array.
     */
    private findPeriodsWithMissingRegistrations(registrations: TermRegistration[], periods: TermRegistrationPeriod[]): TermRegistrationPeriod[] {
        return periods.filter(period => registrations
            .some(registration => registration.termRegistrationType === 'MISSING' &&
                (_.isEqual(registration.studyTerm, period.term1) || _.isEqual(registration.studyTerm, period.term2))));
    }
}
