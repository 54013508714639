import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { UniversitySettings } from 'common-typescript/types';
import _ from 'lodash';
import { ConfigService } from 'sis-common/config/config.service';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { UniversityService } from '../../service/university.service';

const locations = {
    STUDENT: '/student',
    TEACHER: '/teacher',
    // Navigating in staff context from teacher to staff role doesn't work unless full path given
    STAFF: '/staff/studies/staff/frontpage',
    ADMIN: '/admin',
};

export const navUrls = {
    STUDENT_URL: locations.STUDENT,
    STUDENT_PLAN_URL: `${locations.STUDENT}/plan`,
    TEACHER_URL: locations.TEACHER,
    ADMIN_URL: locations.ADMIN,
    STAFF_URL: locations.STAFF,
};

@StaticMembers<DowngradedService>()
@Injectable({
    providedIn: 'root',
})
export class NavUtilsService {

    static downgrade: ServiceDowngradeMappings = {
        serviceName: 'navUtilsService',
        moduleName: 'sis-components.topNav.nav-utils.downgraded',
    };

    private window: Window;

    constructor(private configService: ConfigService,
                private universityService: UniversityService,
                private localeService: LocaleService,
                // TODO: Change the any type to HTMLDocument when https://github.com/angular/angular/issues/15640 is fixed
                @Inject(DOCUMENT) private document: any) {
        this.window = document.defaultView;
    }

    moveToMessagesUrl(): void {
        this.navigateToHref(this.getMessagesHref());
    }

    getMessagesHref(): string {
        const url = this.getRouteRoleContextPath();
        if (url === '/teacher/role/teacher' || url === '/teacher/role/staff') {
            return `${url}/message-conversations/all`;
        }
        if (url === '/tutor/role/teacher' || url === '/tutor/role/staff') {
            return `${url}/message-conversations/all`;
        }
        if (url === '/staff/studies/staff' || url === '/staff/studies/teacher') {
            return `${url}/message-conversations/all`;
        }
        if (url === '/student/plan/preview') {
            return '/staff/studies/staff/message-conversations/all';
        }
        if (_.startsWith(url, '/student')) {
            return '/student/message-conversations/all';
        }
        return `${this.getContextPath()}/message-conversations/all`;
    }

    moveToCourseCartUrl(): void {
        this.navigateToHref(`${locations.STUDENT}/course-cart`);
    }

    moveToHelpUrl(): void {
        this.universityService.getCurrentUniversitySettings().subscribe((settings: UniversitySettings) => {
            const currentApp = _.split(this.getSelectedContextPath().substring(1), '/');
            const baseUrl = _.get(settings.helpUrls, currentApp) || _.get(settings.helpUrls, 'default');
            const lang = this.localeService.getCurrentLanguage();
            const view = _.split(this.window.location.pathname.substring(1), '/')[0];
            const url = `${baseUrl}?role=${currentApp}&view=${view}&lang=${lang}`;
            this.window.open(url, '_blank');
        });
    }

    getContextPath(): string {
        return this.window.location.pathname.substring(0, this.window.location.pathname.indexOf('/', 2));
    }

    getRouteRoleContextPath(): string {
        return this.window.location.pathname.split('/').slice(0, 4).join('/');
    }

    getSelectedContextPath(): string {
        const url = this.getRouteRoleContextPath();
        if (url === '/teacher/role/teacher' || url === '/staff/studies/teacher' || url === '/tutor/role/teacher') {
            return locations.TEACHER;
        }
        if (url === '/teacher/role/staff' || url === '/staff/studies/staff' || url === '/tutor/role/staff') {
            return locations.STAFF;
        }
        if (url === '/student/plan/preview') {
            return locations.STAFF;
        }

        return this.getContextPath();
    }

    getHomeUrl(): string {
        let homeUrl = this.getSelectedContextPath();

        if (_.startsWith(homeUrl, locations.STUDENT)) {
            const studentFrontpageRedirectUrl = _.get(this.configService.get(), 'studentFrontpageRedirectUrl');
            if (studentFrontpageRedirectUrl) {
                homeUrl = studentFrontpageRedirectUrl;
            }
        }

        return homeUrl;
    }

    navigateToHref(path: string): void {
        this.window.location.href = path;
    }
}
