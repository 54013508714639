courseUnitSubstitutionInfoController.$inject = ["$scope", "courseUnitInfoModal"];
import angular from 'angular';
import _ from 'lodash';
import courseUnitSubstitutionInfoTpl from './courseUnitSubstitutionInfo.tpl.html';
angular.module('student.common.components.courseUnitBox.courseUnitSubstitutionInfo', ['student.common.components.courseUnitInfoModal']).component('courseUnitSubstitutionInfo', {
  template: courseUnitSubstitutionInfoTpl,
  bindings: {
    courseUnit: '<',
    validatablePlan: '<'
  },
  controller: courseUnitSubstitutionInfoController
});
function courseUnitSubstitutionInfoController($scope, courseUnitInfoModal) {
  const $ctrl = this;
  $ctrl.$onInit = () => {
    $ctrl.substitutingCourseUnits = _.map($ctrl.validatablePlan.getSubstitutedBy($ctrl.courseUnit), courseUnitId => $ctrl.validatablePlan.getCourseUnit(courseUnitId));
  };
  $ctrl.substitutionCredits = substituteCourseUnit => $ctrl.validatablePlan.getSubstitutionCredits($ctrl.courseUnit, substituteCourseUnit);
  $ctrl.openCourseUnitInfo = () => courseUnitInfoModal.showCourseUnit($ctrl.courseUnit.id, $ctrl.validatablePlan);
}