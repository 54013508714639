import { Inject, Injectable } from '@angular/core';
import { ValidatablePlan } from 'common-typescript/src/plan/validation/validatablePlan';
import { CourseUnit, CurriculumPeriod } from 'common-typescript/types';
import _ from 'lodash';
import { from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';
import { CURRICULUM_PERIOD_SERVICE } from 'sis-components/ajs-upgraded-modules';
import { CourseUnitEntityService } from 'sis-components/service/course-unit-entity.service';
import { convertAJSPromiseToNative } from 'sis-components/util/utils';

@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
export class StudentPlanOutdatedCourseUnitsService {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'student.common.service.studentPlanOutdatedCourseUnitsService.downgraded',
        serviceName: 'studentPlanOutdatedCourseUnitsService',
    };

    constructor(private courseUnitEntityService: CourseUnitEntityService,
                @Inject(CURRICULUM_PERIOD_SERVICE) private commonCurriculumPeriodService: any,
    ) {}

    checkLatestCourseUnitsAreInSelectedPlan(validatablePlan: ValidatablePlan): Observable<[CourseUnit, CourseUnit][]> {
        return from(convertAJSPromiseToNative(this.commonCurriculumPeriodService.getCurrentCurriculumPeriod())).pipe(
            switchMap((currentCurriculumPeriod: CurriculumPeriod) => {
                const currentCurriculumPeriodId: string = currentCurriculumPeriod.id;
                // Returns all course units in the plan. For course units that have been substituted, only the substituting course units will be returned.
                const courseUnitsInPlan: CourseUnit[] = validatablePlan.getAllCourseUnitsInPlan();

                if (_.isEmpty(courseUnitsInPlan)) {
                    return of([]);
                }
                const courseUnitsNotYetAttained: CourseUnit[] =
                    courseUnitsInPlan.filter(courseUnit => !validatablePlan.isCourseUnitAttained(courseUnit.id));

                if (_.isEmpty(courseUnitsNotYetAttained)) {
                    return of([]);
                }
                const courseUnitGroupIds = courseUnitsNotYetAttained.map(courseUnit => courseUnit.groupId);

                return this.courseUnitEntityService.getByGroupIdsCurriculumPeriodIdDocumentState(
                    courseUnitGroupIds,
                    currentCurriculumPeriodId,
                    ['ACTIVE'],
                ).pipe(
                    map((courseUnitsInCurrentCurriculumPeriod: CourseUnit[]) =>
                        this.getListOfOutdatedCourseUnits(courseUnitsNotYetAttained, courseUnitsInCurrentCurriculumPeriod)),
                );
            }),
        );

    }

    getListOfOutdatedCourseUnits(courseUnitsInPlan: CourseUnit[], currentCurriculumPeriodCourseUnits: CourseUnit[]): [CourseUnit, CourseUnit][] {
        const outdatedCourseUnits: [CourseUnit, CourseUnit][] = [];
        if (_.isEmpty(currentCurriculumPeriodCourseUnits)) {
            return [];
        }
        _.forEach(courseUnitsInPlan, (planCourseUnit) => {
            const matchingCourseUnitFromCurrentCurriculumPeriod =
                currentCurriculumPeriodCourseUnits.find(courseUnit => courseUnit.groupId === planCourseUnit.groupId);
            if (matchingCourseUnitFromCurrentCurriculumPeriod) {
                if (matchingCourseUnitFromCurrentCurriculumPeriod.id !== planCourseUnit.id) {
                    outdatedCourseUnits.push([planCourseUnit, matchingCourseUnitFromCurrentCurriculumPeriod]);
                }
            }
        });
        return outdatedCourseUnits;
    }
}
