<div *ngIf="!loading" class="search-page container sis-pt-md">
  <div class="search-title row">
    <div class="col-12">
      <h2 data-cy="page-secondary-title" translate>SEARCH_PAGE_OPEN_UNIVERSITY_TITLE</h2>
    </div>
  </div>
  <p class="guidance" translate>SEARCH_PAGE_SECONDARY_TITLE</p>
  <div class="search-and-results scroll-target">
    <div class="col-xl-10 search-bar-content">
      <div class="row">
        <div class="col search-main-input-group">
          <div class="form-group">
            <label id="search-main-university-input-label"
                   for="search-main-university-input">{{'SEARCH_COURSE_UNIT_PLACEHOLDER' | translate}}</label>
            <div class="search-group">
              <input #textQuery
                     class="form-control"
                     aria-describedby="search-main-university-input-guide"
                     id="search-main-university-input"
                     data-cy="course-unit-search-input"
                     (keyup.enter)="onFullTextSearch(textQuery.value)"
                     [value]="searchParameters.searchString.value">
              <sis-button (clicked)="onFullTextSearch(textQuery.value)"
                          [dataCy]="'search-main-university-button'"
                          [ariaLabel]="'ARIA_LABEL.SEARCH_CU' | translate">
                <sis-icon color="white" icon="search" aria-hidden="true"></sis-icon>
                <ng-container>{{'SEARCH_COURSE_UNIT_BUTTON' | translate}}</ng-container>
              </sis-button>
            </div>
            <p id="search-main-university-input-guide"
               class="guidance">{{'COURSE_UNIT_SEARCH_PLACEHOLDER' | translate}}</p>
          </div>
        </div>
      </div>
    </div>

    <sis-expandable [variant]="'link'" [closed]="isMobileView" data-cy="expandable-toggle" [title]="getFilterTitleLabel()">
      <ng-template sisExpandableContent>
        <div class="row search-filter">
          <div class="col-lg-3 search-filter-item">
            <sis-combobox
              (optionChange)="organisationChange($event)"
              [placeholder]="'SEARCH_FILTERS_PLACEHOLDER_SEARCH' | translate"
              predictive=true
              (predictiveSearch)="searchOrganisations($event)"
              [selected]="searchParams.organisations.selected"
              [options]="searchParams.organisations.options"
              [label]="getLabel(searchParameterOptions.organisations)"
            ></sis-combobox>
            <sis-button class="search-filter-more-button" link="true" (clicked)="openOrganisationSelectDialog()">
              <sis-icon icon="picker"></sis-icon>
              {{'SEARCH.OPEN_ORGANISATION_MODAL' | translate}}
            </sis-button>
          </div>
          <div class="col-lg-3 search-filter-item">
            <sis-combobox
              (optionChange)="curriculumPeriodsChange($event)"
              [placeholder]="'SEARCH_FILTERS_PLACEHOLDER_SELECT' | translate"
              [selected]="searchParams.curriculumPeriods.selected"
              [options]="searchParams.curriculumPeriods.options"
              [label]="getLabel(searchParameterOptions.curriculumPeriods)">
            </sis-combobox>
          </div>
          <div class="col-lg-3 search-filter-item">
            <sis-combobox
              (optionChange)="studyLevelUrnsChange($event)"
              [placeholder]="'SEARCH_FILTERS_PLACEHOLDER_SELECT' | translate"
              [selected]="searchParams.studyLevelUrns.selected"
              [options]="searchParams.studyLevelUrns.options"
              [label]="getLabel(searchParameterOptions.studyLevelUrns)">
            </sis-combobox>
          </div>
          <div class="col-lg-3 search-filter-item">
            <sis-combobox
              (optionChange)="attainmentLanguageUrnsChange($event)"
              [placeholder]="'SEARCH_FILTERS_PLACEHOLDER_SELECT' | translate"
              [selected]="searchParams.attainmentLanguageUrns.selected"
              [options]="searchParams.attainmentLanguageUrns.options"
              [label]="getLabel(searchParameterOptions.attainmentLanguageUrns)">
            </sis-combobox>
          </div>
          <div class="col-lg-3 search-filter-item">
            <sis-combobox
              (optionChange)="assessmentItemTypeChange($event)"
              [placeholder]="'SEARCH_FILTERS_PLACEHOLDER_SELECT' | translate"
              [selected]="searchParams.assessmentItemType.selected"
              [options]="searchParams.assessmentItemType.options"
              [label]="getLabel(searchParameterOptions.assessmentItemType)">
            </sis-combobox>
          </div>
          <div class="col-lg-3 search-filter-item">
            <sis-combobox
              (optionChange)="curTeachingLanguageUrnsChange($event)"
              [placeholder]="'SEARCH_FILTERS_PLACEHOLDER_SELECT' | translate"
              [selected]="searchParams.curTeachingLanguageUrns.selected"
              [options]="searchParams.curTeachingLanguageUrns.options"
              [label]="getLabel(searchParameterOptions.curTeachingLanguageUrns)">
            </sis-combobox>
          </div>
          <div class="col-lg-3 search-filter-item">
            <sis-combobox
              (optionChange)="studyPeriodsChange($event)"
              [placeholder]="'SEARCH_FILTERS_PLACEHOLDER_SELECT' | translate"
              [selected]="searchParams.studyPeriods.selected"
              [options]="searchParams.studyPeriods.options"
              [label]="getLabel(searchParameterOptions.studyPeriods)">
            </sis-combobox>
          </div>
          <div class="col-lg-3 search-filter-item">
            <sis-combobox
              (optionChange)="salesPeriodChange($event)"
              [placeholder]="'SEARCH_FILTERS_PLACEHOLDER_SELECT' | translate"
              [selected]="searchParams.salesPeriodValidity.selected"
              [options]="searchParams.salesPeriodValidity.options"
              [label]="getLabel(searchParameterOptions.salesPeriodValidity)">
            </sis-combobox>
          </div>
          <div *ngIf="activeFilters > 0" class="col-lg-3 offset-lg-9 search-filter-clear-button">
            <sis-button (clicked)="removeFilters()" naked="true" data-cy="empty-filters-button">
              <sis-icon icon="close"></sis-icon>
              {{ 'SEARCH_FILTERS_EMPTY' | translate }}
            </sis-button>
          </div>
        </div>
      </ng-template>
    </sis-expandable>

    <div class="below-searchbar row">
      <!-- this visually hidden div block is only to provide search result information for screen reader -->
      <div class="visually-hidden" aria-atomic="true" aria-live="polite">
        <p *ngIf="!searching && noResultsMessage">
          {{ noResultsMessage }}
        </p>
        <p *ngIf="!searching && !tooManyResults && searchResult?.total > 0">
          {{searchResult.total}}
          <span *ngIf="searchResult.total === 1" translate>SEARCH_RESULTS_TITLE_ONE</span>
          <span *ngIf="searchResult.total > 1" translate>SEARCH_RESULTS_TITLE_MANY</span>
        </p>
      </div>

      <div class="query-notifications col-12">
        <p *ngIf="noResultsMessage"
           class="results-no-show guidance">
          {{ noResultsMessage }}
        </p>
      </div>
      <div *ngIf="!tooManyResults && searchResult?.total > 0" class="results-info col-12">
        <div class="row">
          <h2 class="col-12 col-sm-6" id="results-show-guide" tabindex="-1"
              data-cy="courseunit-search-resultrow">
            {{searchResult.total}}
            <span *ngIf="searchResult.total === 1" translate>SEARCH_RESULTS_TITLE_ONE</span>
            <span *ngIf="searchResult.total > 1" translate>SEARCH_RESULTS_TITLE_MANY</span>
          </h2>
          <div class="col-12 col-sm-6">
            <div class="float-start float-sm-end">
              <label id="sort-method-dropdown-label" class="choose-sort-type" translate>SEARCH.SORT_SELECTOR.CHOOSE_SORT_METHOD</label>
              <sis-dropdown-select [ariaLabelledBy]="'sort-method-dropdown-label'"
                                   [options]="searchSortOptions"
                                   [selection]="initialQuerySort"
                                   (selectionChange)="sort($event)"
                                   [alignRight]="true"
                                   data-cy="sort-search-results">
              </sis-dropdown-select>
            </div>
          </div>
        </div>
      </div>
      <div class="results col-12">
        <div *ngFor="let result of searchResult?.searchResults" data-cy="student-courseunit-search-resultrow">
          <app-search-result-row
            [studyPeriods]="studyPeriods"
            [curriculumPeriods]="curriculumPeriods"
            [result]="result"
            [term]="searchParameters.searchString.value"
            [searchFilterCurriculumPeriods]="searchParameters.curriculumPeriods.value"
            [courseCartCourseUnitIds]="courseCartCourseUnitIds"
            [loggedIn]="isLoggedIn()"
            (addCourseUnitToCourseCart)="addCourseUnitToCourseCart($event)"
            (removeCourseUnitFromCourseCart)="removeCourseUnitFromCourseCart($event)"
          ></app-search-result-row>
        </div>
      </div>
    </div>

    <div class="search-result-pagination row"
         *ngIf="!tooManyResults && searchResult?.total > this.messagesPerPage">
      <div class="col-12"
           data-cy="pagination-controls">
        <sis-pagination
          sisScrollIntoViewOnPageChange="div.scroll-target"
          [totalItems]="searchResult.total"
          [maxSize]="maxSize"
          [itemsPerPage]="messagesPerPage"
          [(currentPage)]="currentPage"
          (currentPageChange)="onPaginationChange()">
        </sis-pagination>
      </div>
    </div>
  </div>
</div>

