<sis-study-box [id]="customCourseUnitAttainment?.id"
               [name]="customCourseUnitAttainment?.name"
               [gradeScaleId]="customCourseUnitAttainment?.gradeScaleId"
               [gradeId]="customCourseUnitAttainment?.gradeId"
               [code]="customCourseUnitAttainment?.code"
               [attainedCredits]="customCourseUnitAttainment?.credits"
               [type]="studyBoxType"
               [credits]="visibleCredits"
               [level]="headingLevel"
               (nameClickAction)="openCustomCourseUnitAttainmentInfoModal()">
</sis-study-box>
