import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import {
    AssessmentItem,
    CompletionMethod,
    CourseUnit,
    CourseUnitSelection,
    OtmId,
} from 'common-typescript/types';
import _ from 'lodash';
import { Observable, pairwise, startWith } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalizedStringPipe } from 'sis-common/l10n/localized-string.pipe';
import { ModalService } from 'sis-common/modal/modal.service';
import { SisFormBuilder } from 'sis-components/form/sis-form-builder.service';
import { CreditRangePipe } from 'sis-components/number/credit-range.pipe';
import { AssessmentItemEntityService } from 'sis-components/service/assessment-item-entity.service';

export interface SelectCompletionMethodDialogValues {
    courseUnitSelection: CourseUnitSelection;
    assessmentItemSelections: AssessmentItem[];
    courseUnit: CourseUnit;
}

@Component({
    selector: 'app-select-completion-method',
    templateUrl: './select-completion-method.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectCompletionMethodComponent implements OnInit {
    _values: SelectCompletionMethodDialogValues;
    form: FormGroup;

    constructor(
        @Inject(ModalService.injectionToken) private values: SelectCompletionMethodDialogValues,
        public activeModal: NgbActiveModal,
        private fb: SisFormBuilder,
        private translocoService: TranslocoService,
        private creditRangePipe: CreditRangePipe,
        private localizedString: LocalizedStringPipe,
        private assessmentItemEntityService: AssessmentItemEntityService,
    ) {
        this._values = values;
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            selectCompletionMethod: this.fb.sisFormControl(this._values.courseUnitSelection?.completionMethodId),
            selectedAssessmentItems: this.fb.array(this._values.assessmentItemSelections.map(ai => this.fb.control(ai.id))),
        });

        this.form.controls['selectCompletionMethod'].valueChanges
            .pipe(startWith(this._values.courseUnitSelection?.completionMethodId), pairwise())
            .subscribe(([prev, next]: [any, any]) => this.completionMethodChanged(prev, next));
    }

    completionMethodChanged(prev: any, next: any) {
        if (prev !== next) {
            this.selectedAssessmentItems().clear();
        }

        const completionMethod = this.getCompletionMethods().find((cm => cm.localId === next && cm.typeOfRequire === 'ALL_SELECTED_REQUIRED'));

        if (completionMethod) {
            const selectedAssessmentItems = this.selectedAssessmentItems();
            completionMethod.assessmentItemIds.forEach(aii => selectedAssessmentItems.push(this.fb.control(aii)));
        }
    }

    isAssessmentItemSelectedInCompletionMethod(assessmentItemId: OtmId, completionMethod: CompletionMethod) {
        if (this.selectCompletionMethod().value !== completionMethod.localId) return false;

        const assessmentItemSelection = _.find(this.selectedAssessmentItems().value, aii => aii === assessmentItemId);
        return !!assessmentItemSelection;
    }

    getCompletionMethodLabel(completionMethod: CompletionMethod, index: number): Observable<string> {
        const translatedLabel = this.translocoService.translate('COMPLETION_METHOD');

        return this.calculateCompletionMethodAssessmentItemCreditsMinMax(completionMethod).pipe(
            map((minMax) => `${translatedLabel} ${index + 1} (${this.creditRangePipe.transform(minMax)})`),
        );
    }

    getAssessmentItemLabel(assessmentItem: AssessmentItem): string {
        return `${this.localizedString.transform(assessmentItem.name)} (${this.creditRangePipe.transform(assessmentItem.credits)})`;
    }

    assessmentItemCheckboxClicked(event: boolean, completionMethod: CompletionMethod, assessmentItemId: OtmId) {
        if (this.selectCompletionMethod().value !== completionMethod.localId) {
            this.updateCompletionMethodSelection(completionMethod);
        }

        const selectedAssessmentItems = this.selectedAssessmentItems();
        event ? selectedAssessmentItems.push(this.fb.control(assessmentItemId)) :
            selectedAssessmentItems.removeAt(selectedAssessmentItems.value.findIndex((aii: any) => aii === assessmentItemId));
    }

    updateCompletionMethodSelection(completionMethod: CompletionMethod) {
        this.selectCompletionMethod().setValue(completionMethod.localId);
    }

    getCompletionMethods() {
        return this._values.courseUnit?.completionMethods.filter((cm: CompletionMethod) => cm.studyType !== 'OPEN_UNIVERSITY_STUDIES');
    }

    selectCompletionMethod(): FormControl {
        return this.form.get('selectCompletionMethod') as FormControl;
    }

    getAriaDescribedByIndex(index: number): string {
        return `range-help-${index} description-help-${index}`;
    }

    selectedAssessmentItems(): FormArray {
        return this.form.get('selectedAssessmentItems') as FormArray;
    }

    submit() {
        const selectedCompletionMethod = this.getCompletionMethods().find(cm => cm.localId === this.selectCompletionMethod().value);

        this.activeModal.close({
            selectedCompletionMethod,
            selectedAssessmentItemIds: this.selectedAssessmentItems().value,
        });
    }

    calculateCompletionMethodAssessmentItemCreditsMinMax(completionMethod: CompletionMethod): Observable<{ min: number, max: number }> {
        return this.assessmentItemEntityService.getByIds(completionMethod.assessmentItemIds).pipe(
            map(assessmentItems => {
                let requireMin;
                let requireMax;

                if (completionMethod.typeOfRequire === 'OPTIONAL_WITH_REQUIRE_RANGE') {
                    requireMin = _.get(completionMethod, 'require.min');
                    requireMax = _.get(completionMethod, 'require.max');
                } else if (completionMethod.typeOfRequire === 'OPTIONAL_WITH_DESCRIPTION') {
                    requireMin = 1;
                    requireMax = assessmentItems.length;
                } else {
                    requireMin = assessmentItems.length;
                    requireMax = assessmentItems.length;
                }

                const sortedMinCredits = _.chain(assessmentItems)
                    .map(item => _.get(item, 'credits.min'))
                    .compact()
                    .sortBy()
                    .value();

                const minCredits = _.take(sortedMinCredits, requireMin).reduce((acc, val) => acc + val, 0);

                const sortedMaxCredits = _.chain(assessmentItems)
                    .map(item => _.get(item, 'credits.max'))
                    .compact()
                    .sortBy()
                    .reverse()
                    .value();

                const maxCredits = _.take(sortedMaxCredits, requireMax).reduce((acc, val) => acc + val, 0);

                return { min: minCredits, max: maxCredits };
            }),
        );
    }

    cancel() {
        this.activeModal.dismiss();
    }
}
