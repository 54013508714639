<div class="row event-detail-row">
  <div class="col-12">
    <table class="study-event-table" [attr.aria-label]="'STUDY_EVENT_SUMMARY.TABLE_ARIA' | translate">
      <thead class="hide-headers" aria-hidden="true">
      <tr>
        <th>{{ 'STUDY_SUB_GROUP_EVENT_INFO.TIME' | translate }}</th>
        <th>{{ 'STUDY_SUB_GROUP_EVENT_INFO.PLACE' | translate }}</th>
        <th>{{ 'STUDY_SUB_GROUP_EVENT_INFO.TEACHER' | translate }}</th>
        <th></th>
      </tr>
      </thead>
      <tbody class="study-event-body">
      <tr class="study-event-row">
        <td class="col-3 table-justify-left">
          <span>{{ 'STUDY_SUB_GROUP_EVENT_INFO.TIME' | translate }} </span>
          <span class="day">{{ studyEvent | studyEventWeekdayOrInterval }} </span>
          <span class="date">{{ studyEvent | studyEventDateRange }} </span>
          <span class="time">{{ studyEvent | studyEventTimeRange }} </span>
        </td>
        <td class="locations col-3">
          <div>{{'STUDY_SUB_GROUP_EVENT_INFO.PLACE' | translate }}</div>
          <div>
            <div *ngFor="let locationId of studyEvent.locationIds">
              <div *ngIf="locationId | location | async as location" class="building-name">
                {{location.building.name | localizedString:language}}, {{location.name | localizedString:language}}
              </div>
            </div>
            <div *ngIf="hasNoLocations" class="no-location">
              {{'STUDY_EVENT_SUMMARY.NO_LOCATION' | translate}}
            </div>
            <div *ngIf="hasIrregularLocations" class="irregularities">
              ({{'STUDY_EVENT_SUMMARY.IRREGULAR_LOCATIONS' | translate}})
            </div>
          </div>
        </td>
        <td class="teachers col-2">
          <div>{{'STUDY_SUB_GROUP_EVENT_INFO.TEACHER' | translate }}</div>
          <div>
            <div *ngFor="let teacherId of allStudyEventTeacherIds" class="teacher-summary">
              {{teacherId | publicPerson | async | personFullName}}
            </div>
          </div>
        </td>
        <td class="col-3">
          <div *ngIf="cancelledOrExcludedEventCount > 0 && !allEventsCancelledOrExcluded" class="cancelled-events">
            <sis-icon [icon]="'alert'" [color]="'danger'"></sis-icon>
            <span class="cancelled-events">{{'STUDY_EVENT_SUMMARY.CANCELLED_EVENTS' | translate}}</span>
          </div>
          <div *ngIf="allEventsCancelledOrExcluded" class="cancelled-events">
            <sis-icon [icon]="'alert'" [color]="'danger'"></sis-icon>
            <span>{{'STUDY_EVENT_SUMMARY.CANCELLED' | translate}}</span>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
