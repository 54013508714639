import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ValidatablePlan } from 'common-typescript';
import {
    AssessmentItem, AssessmentItemAttainment,
    AttainmentType,
    CompletionMethod,
    CourseUnit,
    CourseUnitRealisation,
    Enrolment,
    OtmId,
} from 'common-typescript/types';
import _ from 'lodash';
import { forkJoin, Observable, of, switchMap, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'sis-common/auth/auth-service';
import { LocalizedStringPipe } from 'sis-common/l10n/localized-string.pipe';
import { ModalService } from 'sis-common/modal/modal.service';
import { ENROLMENT_VALIDATION_SERVICE } from 'sis-components/ajs-upgraded-modules';
import { LocalDateRangePipe } from 'sis-components/date/pipes/local-date-range/local-date-range.pipe';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { SisFormBuilder } from 'sis-components/form/sis-form-builder.service';
import { AssessmentItemEntityService } from 'sis-components/service/assessment-item-entity.service';
import { AttainmentEntityService } from 'sis-components/service/attainment-entity.service';
import { CourseUnitRealisationEntityService } from 'sis-components/service/course-unit-realisation-entity.service';

import { EnrolmentStudentService } from '../../../common/service/enrolment-student.service';

export interface SelectCourseUnitRealisationDialogValues {
    validatablePlan: ValidatablePlan;
    courseUnit: CourseUnit;
}

@Component({
    selector: 'app-select-course-unit-realisation',
    templateUrl: './select-course-unit-realisation.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectCourseUnitRealisationComponent implements OnInit {
    _values: SelectCourseUnitRealisationDialogValues;
    assessmentItemsWithRealisations: Map<AssessmentItem, CourseUnitRealisation[]>;
    enrolmentForCourseUnitRealisations: { [key: OtmId]: Enrolment };
    completionMethod: CompletionMethod;
    courseUnit: CourseUnit;
    attainments: AssessmentItemAttainment[];
    form: FormGroup;

    constructor(
        @Inject(ModalService.injectionToken) private values: SelectCourseUnitRealisationDialogValues,
        @Inject(ENROLMENT_VALIDATION_SERVICE) private enrolmentValidationService: any,
        private appErrorHandler: AppErrorHandler,
        public activeModal: NgbActiveModal,
        private courseUnitRealisationEntityService: CourseUnitRealisationEntityService,
        private assessmentItemEntityService: AssessmentItemEntityService,
        private attainmentEntityService: AttainmentEntityService,
        private enrolmentStudentService: EnrolmentStudentService,
        private authService: AuthService,
        private cdr: ChangeDetectorRef,
        private localizedString: LocalizedStringPipe,
        private localDateRange: LocalDateRangePipe,
        private fb: SisFormBuilder,
    ) {
        this._values = values;
    }

    ngOnInit(): void {
        this.initForm();
        this.initData();
    }

    private initForm(): void {
        this.form = this.fb.group({
            selectedCourseUnitRealisations: this.fb.array([]),
        });
    }

    private initData(): void {
        this.courseUnit = this._values.courseUnit;
        this.completionMethod = this._values.validatablePlan.getSelectedCompletionMethod(this._values.courseUnit);
        const selectedAssessmentItemIds = this._values.validatablePlan.getSelectedAssessmentItems(this._values.courseUnit).map(ai => ai.id);
        const assessmentItemIds: OtmId[] = this.completionMethod.assessmentItemIds.filter(assessmentItemId => _.includes(selectedAssessmentItemIds, assessmentItemId));

        this.attainmentEntityService.findForPerson(this.authService.personId(), {
            assessmentItemId: assessmentItemIds,
            attainmentType: AttainmentType.ASSESSMENT_ITEM_ATTAINMENT,
            documentState: 'ACTIVE',
            primary: true,
        }).pipe(map(attainments => attainments as AssessmentItemAttainment[])).pipe(
            switchMap(attainments => {
                this.attainments = attainments;
                return this.fetchAssessmentItemsAndRealisations(assessmentItemIds).pipe(
                    switchMap(({ assessmentItems, courseUnitRealisations }) =>
                        this.enrolmentStudentService.findEnrolments(courseUnitRealisations.map(cur => cur.id), true).pipe(
                            map(enrolments => ({ assessmentItems, courseUnitRealisations, enrolments })),
                        ),
                    ),
                );
            }),
        ).subscribe(({ assessmentItems, courseUnitRealisations, enrolments }) => {
            this.processAssessmentItemsAndRealisations(assessmentItems, courseUnitRealisations);
            this.enrolmentForCourseUnitRealisations = _.keyBy(enrolments, 'courseUnitRealisationId');
            this.form = this.fb.group({
                selectedCourseUnitRealisations: this.fb.array(
                    courseUnitRealisations.filter(cur => this.isEnrolmentInCalendar(cur.id)).map(cur =>
                        this.fb.group({
                            courseUnitRealisationId: this.fb.control(cur.id),
                            assessmentItemId: this.fb.control(this.getAssessmentItemIdForCur(cur)),
                        }),
                    ),
                ),
            });
            this.cdr.detectChanges();
        });
    }

    fetchAssessmentItemsAndRealisations(assessmentItemIds: OtmId[]): Observable<{ assessmentItems: AssessmentItem[], courseUnitRealisations: CourseUnitRealisation[] }> {
        if (assessmentItemIds.length === 0) {
            return of({ assessmentItems: [], courseUnitRealisations: [] });
        }

        return this.assessmentItemEntityService.getByIds(assessmentItemIds).pipe(
            switchMap(assessmentItems =>
                this.courseUnitRealisationEntityService.getPublishedAndActiveCourseUnitRealisationsByAssessmentItemIds(assessmentItemIds).pipe(
                    map(courseUnitRealisations => ({ assessmentItems, courseUnitRealisations })),
                )),
        );
    }

    processAssessmentItemsAndRealisations(assessmentItems: AssessmentItem[], courseUnitRealisations: CourseUnitRealisation[]): void {
        const assessmentItemMap = new Map<AssessmentItem, CourseUnitRealisation[]>();
        assessmentItems.forEach(assessmentItem => {
            const courseUnitRealisationsForAssessmentItem = courseUnitRealisations.filter(
                courseUnitRealisation => courseUnitRealisation.assessmentItemIds.includes(assessmentItem.id),
            );
            assessmentItemMap.set(assessmentItem, _.orderBy(courseUnitRealisationsForAssessmentItem, 'activityPeriod.startDate'));
        });

        this.assessmentItemsWithRealisations = assessmentItemMap;
    }

    isEnrolmentInCalendar(courseUnitRealisationId: OtmId): boolean {
        const enrolment = this.enrolmentForCourseUnitRealisations[courseUnitRealisationId];
        return enrolment ? _.get(enrolment, 'isInCalendar', false) : false;
    }

    getAssessmentItemIdForCur(courseUnitRealisation: CourseUnitRealisation): OtmId {
        return this.enrolmentForCourseUnitRealisations[courseUnitRealisation.id].assessmentItemId;
    }

    isEnrolledToFromAnotherEntity(courseUnitRealisation: CourseUnitRealisation, assessmentItem: AssessmentItem) {
        const enrolment = this.enrolmentForCourseUnitRealisations[courseUnitRealisation.id];
        return enrolment && _.includes(['NOT_ENROLLED', 'PROCESSING', 'ENROLLED'], enrolment.state) &&
            (enrolment.assessmentItemId !== assessmentItem.id || enrolment.courseUnitId !== this._values.courseUnit.id);
    }

    getCompletionMethodIndex() {
        return this._values.courseUnit.completionMethods.findIndex((cm) => cm.localId === this.completionMethod.localId) + 1;
    }

    getCourseUnitRealisationLabel(courseUnitRealisation: CourseUnitRealisation) {
        return `${this.localizedString.transform(courseUnitRealisation.name)} ${this.localDateRange.transform(courseUnitRealisation.activityPeriod)}`;
    }

    isCourseUnitRealisationSelected(courseUnitRealisation: CourseUnitRealisation, assessmentItem: AssessmentItem) {
        const courseUnitRealisationSelection = _.find(this.selectedCourseUnitRealisations().value, formValue =>
            formValue.courseUnitRealisationId === courseUnitRealisation.id && formValue.assessmentItemId === assessmentItem.id);
        return !!courseUnitRealisationSelection;
    }

    courseUnitRealisationCheckboxClicked(event: boolean, courseUnitRealisationId: OtmId, assessmentItemId: OtmId) {
        const selectedCourseUnitRealisations = this.selectedCourseUnitRealisations();
        event ? this.addValueToSelectedCourseUnitRealisations(courseUnitRealisationId, assessmentItemId) :
            selectedCourseUnitRealisations.removeAt(selectedCourseUnitRealisations.value.findIndex((formValue: any) => formValue.courseUnitRealisationId === courseUnitRealisationId));
    }

    private addValueToSelectedCourseUnitRealisations(courseUnitRealisationId: OtmId, assessmentItemId: OtmId) {
        const formArray: FormArray = this.form.get('selectedCourseUnitRealisations') as FormArray;

        // Remove same realisation selections before pushing
        for (let i = formArray.length - 1; i >= 0; i -= 1) {
            if (formArray.at(i).get('courseUnitRealisationId').value === courseUnitRealisationId) {
                formArray.removeAt(i);
            }
        }

        this.selectedCourseUnitRealisations().push(
            this.fb.group({
                courseUnitRealisationId: this.fb.control(courseUnitRealisationId),
                assessmentItemId: this.fb.control(assessmentItemId),
            }),
        );
    }

    isSelectionDisabled(courseUnitRealisation: CourseUnitRealisation, assessmentItem: AssessmentItem) {
        return courseUnitRealisation.flowState === 'CANCELLED' ||
            this.isAlreadyEnrolled(courseUnitRealisation) ||
            this.isEnrolledToFromAnotherEntity(courseUnitRealisation, assessmentItem);
    }

    isAlreadyEnrolled(courseUnitRealisation: CourseUnitRealisation) {
        const enrolment = this.enrolmentForCourseUnitRealisations[courseUnitRealisation.id];
        return enrolment && enrolment.state === 'ENROLLED';
    }

    hasAttainment(courseUnitRealisation: CourseUnitRealisation, assessmentItem: AssessmentItem) {
        return !!this.attainments.find((a) => a.courseUnitRealisationId === courseUnitRealisation.id && a.assessmentItemId === assessmentItem.id);
    }

    private selectedCourseUnitRealisations(): FormArray {
        return this.form.get('selectedCourseUnitRealisations') as FormArray;
    }

    submit() {
        const allObservables: Observable<any>[] = [];

        this.assessmentItemsWithRealisations.forEach((curs, assessmentItem) => {
            curs.forEach((cur) => {
                const selectedCur = this.selectedCourseUnitRealisations().value.find((formValue: any) =>
                    formValue.courseUnitRealisationId === cur.id && formValue.assessmentItemId === assessmentItem.id);

                const enrolment = this.enrolmentForCourseUnitRealisations[cur.id];

                if (selectedCur && !enrolment) {
                    const addObservable = this.enrolmentStudentService.add({
                        assessmentItemId: assessmentItem.id,
                        courseUnitRealisationId: cur.id,
                        courseUnitId: this._values.courseUnit.id,
                        personId: this.authService.personId(),
                        state: 'NOT_ENROLLED',
                        isInCalendar: true,
                        studySubGroups: this.enrolmentValidationService.createStudySubGroupsForEnrolment(cur),
                    } as Enrolment);
                    allObservables.push(addObservable);
                } else if (this.shouldRemoveEnrolment(selectedCur, enrolment, assessmentItem.id)) {
                    const deleteObservable = this.enrolmentStudentService.delete(enrolment.id);
                    allObservables.push(deleteObservable);
                }
            });
        });

        if (allObservables.length > 0) {
            forkJoin(allObservables).pipe(
                take(1),
                this.appErrorHandler.defaultErrorHandler())
                .subscribe(() => {
                    this.activeModal.close();
                });
        } else {
            this.activeModal.close();
        }
    }

    private shouldRemoveEnrolment(cur: CourseUnitRealisation, enrolment: Enrolment, assessmentItemId: OtmId) {
        return !cur && enrolment && enrolment.assessmentItemId === assessmentItemId && enrolment.state === 'NOT_ENROLLED';
    }

    cancel() {
        this.activeModal.dismiss();
    }
}
