import angular from 'angular';
import _ from 'lodash';
import { SisServiceBreakBannerComponent } from 'sis-components/service-break-banner/sis-service-break-banner.component';
import { ContextualNotificationComponent } from 'sis-components/contextual-notification/contextual-notification.component';
import { commonTermRegistrationPeriodServiceModule } from 'sis-components/service/termRegistrationPeriod.service';
import { commonEducationServiceModule } from 'sis-components/service/education.service';
import { IconComponent } from 'sis-components/icon/icon.component';
import { MessageBannerComponent } from 'sis-components/message-banner/message-banner.component';
import { StudentTermRegistrationPeriodInfoComponent } from './student-term-registration-period-info/student-term-registration-period-info.component.ts';
import frontpageTpl from './frontpage.tpl.html';
import { MyStudiesComponent } from './my-studies/my-studies.component';
(function () {
  frontPageController.$inject = ["$q", "$state", "studyRightExtensionService", "commonStudyRightService", "AuthService", "commonTermRegistrationPeriodService", "studyRightExtensionRequirementsService", "defaultPromiseHandler", "universityService", "commonEducationService"];
  angular.module('student.frontpage', ['ui.router', 'sis-components.string.sortByLocalizedValueFilter', 'student.frontpage.studyProgressGraph', 'student.frontpage.studentBadge', 'student.frontpage.timetable', 'sis-components.service.studyRightService', 'sis-components.service.studyRightExtensionService', 'sis-common.auth', 'student.common.service.studyRightExtensionRequirementsService', 'sis-common.errorhandler.errorService', 'sis-common.university', 'student.frontpage.studentFrontpageMessageConversations', MyStudiesComponent.downgrade.moduleName, IconComponent.downgrade.moduleName, ContextualNotificationComponent.downgrade.moduleName, StudentTermRegistrationPeriodInfoComponent.downgrade.moduleName, SisServiceBreakBannerComponent.downgrade.moduleName, MessageBannerComponent.downgrade.moduleName, commonTermRegistrationPeriodServiceModule, commonEducationServiceModule]).component('frontpage', {
    template: frontpageTpl,
    bindings: {
      userHasPlans: '<',
      enrolments: '<'
    },
    controller: frontPageController
  });
  function frontPageController($q, $state, studyRightExtensionService, commonStudyRightService, AuthService, commonTermRegistrationPeriodService, studyRightExtensionRequirementsService, defaultPromiseHandler, universityService, commonEducationService) {
    const $ctrl = this;
    $ctrl.educationsById = {};
    const findExpiringStudyRightInfos = function () {
      const bypassCache = true;
      return commonStudyRightService.getStudyRightExpirationInfosForStudent(AuthService.personId(), bypassCache);
    };
    $ctrl.$onInit = function () {
      $ctrl.studyRightExtensionApplicationEnabledByUniversity = false;
      $ctrl.studentId = AuthService.personId();
      universityService.getCurrentUniversitySettings().then(universitySettings => {
        if (_.get(universitySettings, 'frontendFeatureToggles.studyRightExtensionApplicationEnabled') !== false) {
          $ctrl.studyRightExtensionApplicationEnabledByUniversity = true;
        }
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
      findExpiringStudyRightInfos().then(studyRightExpirationInfos => {
        $ctrl.studyRightExpirationInfos = studyRightExpirationInfos;
        const ids = studyRightExpirationInfos.map(info => info.studyRight.educationId);
        return commonEducationService.findByIds(ids);
      }).then(educations => {
        $ctrl.educationsById = _.keyBy(educations, 'id');
      });
    };
    $ctrl.goToMessages = () => {
      $state.go('student.logged-in.message-conversations.list.all');
    };
    $ctrl.initExtensionApplication = function (studyRightExpirationInfo) {
      studyRightExtensionRequirementsService.checkRequirementsAndProceedToApplication(AuthService.personId(), studyRightExpirationInfo).then(result => {
        switch (result.target) {
          case 'CREATE_APPLICATION':
            $state.go('student.logged-in.profile.applications.create-study-right-extension-application', {
              planId: result.planId,
              studyRightId: result.studyRightId
            });
            break;
          case 'PLAN':
            $state.go('student.logged-in.structure', {
              planId: result.planId
            });
            break;
          case 'TIMING':
            $state.go('student.logged-in.plan.timing', {
              planId: result.planId
            });
            break;
          default:
            angular.noop();
        }
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
  }
})();