import angular from 'angular';
import _ from 'lodash';
import referredObjectSelectEditorTpl from './referredObjectSelectEditor.tpl.html';
import { highlightResultFilterModule } from './highlightResult.filter';
export const referredObjectSelectEditorModule = 'sis-components.object.referredObjectSelectEditor';

/**
    Note: There are some old cases, where this component is used to select urn/code. When translating
    those components from ngjs -> ng, you should prefer to use the <code-select-editor> instead of this.

    For other situations where you need to search object by its name and output the whole object, you
    can use this component. There is also a new angular version of this component named: <sis-referred-object-select-editor>
*/
(function () {
  referredObjectSelectEditorController.$inject = ["$scope", "$q", "$translate", "localeService"];
  angular.module(referredObjectSelectEditorModule, ['pascalprecht.translate', 'js-data', 'sis-common.l10n.localeService', 'sis-common.l10n.localizedStringFilter', highlightResultFilterModule]).directive('referredObjectSelectEditor', referredObjectSelectEditor).controller('referredObjectSelectEditorController', referredObjectSelectEditorController);

  /**
   * @ngInject
   */
  function referredObjectSelectEditor() {
    return {
      restrict: 'E',
      require: '^^form',
      scope: {
        name: '@',
        disabledIds: '=?',
        includedIds: '<?',
        onSelect: '&',
        placeholder: '@',
        id: '=',
        model: '=',
        required: '@',
        showAllUniversitiesParam: '@showAllUniversities',
        showShortNameParam: '@showShortName',
        universityOrgId: '=',
        allowClear: '=?',
        maxResultsParam: '@maxResults',
        reversedOrder: '<?'
      },
      template: referredObjectSelectEditorTpl,
      controller: 'referredObjectSelectEditorController as ctrl',
      bindToController: true,
      link: function (scope, element, attrs, formCtrl) {
        var ctrl = scope.ctrl;
        ctrl.form = formCtrl;
      }
    };
  }

  /**
   * @ngInject
   */
  function referredObjectSelectEditorController($scope, $q, $translate, localeService) {
    // NOSONAR

    var ctrl = this;
    ctrl.groupingEnabled = false;
    ctrl.localeOrder = localeService.getLocaleOrder();
    ctrl.$onInit = function () {
      if (_.isEmpty(ctrl.name)) {
        throw 'referredObjectSelectEditor directive: name attribute is required';
      }
      if (_.isEmpty(ctrl.model)) {
        throw 'referredObjectSelectEditor directive: model attribute is required';
      }
      ctrl.allowClear = angular.isDefined(ctrl.allowClear) ? ctrl.allowClear : true;
      ctrl.showAllUniversities = ctrl.showAllUniversitiesParam === "true";
      ctrl.showShortName = ctrl.showShortNameParam === "true";
      ctrl.maxResults = ctrl.maxResultsParam ? ctrl.maxResultsParam : 21;
      $scope.$watch('ctrl.universityOrgId', function () {
        if (ctrl.filterResults) {
          ctrl.filterData(''); //reset filtered data when universityId is changed.
        }

        ctrl.setTitles();
      });
      $scope.$watch('ctrl.id', function () {
        if (notNull(ctrl.id)) {
          ctrl.value = ctrl.model.get(ctrl.id);
        } else {
          ctrl.value = undefined;
        }
        ctrl.validate();
      });
    };
    function notNull(value) {
      return angular.isDefined(value) && value !== null;
    }
    function getId(jsDataObject) {
      return jsDataObject === undefined ? undefined : jsDataObject[ctrl.model.idAttribute];
    }
    ctrl.setTitles = function () {
      ctrl.myUniversity = $translate.instant("SIS_COMPONENTS.OBJECT.REFERRED_OBJECT_SELECT_EDITOR.MY_UNIVERSITY");
      ctrl.otherUniversities = $translate.instant("SIS_COMPONENTS.OBJECT.REFERRED_OBJECT_SELECT_EDITOR.OTHER_UNIVERSITIES");
    };
    ctrl.isDisabled = function (item) {
      if (!_.isNil(item.more) || item.deprecated) {
        return true;
      }
      return getId(item) !== ctrl.id && ctrl.disabledIds && _.includes(ctrl.disabledIds, getId(item));
    };
    ctrl.getSelectedName = function (selected) {
      if (_.isNil(selected)) {
        return '';
      }
      let name = localeService.getLocalizedValue(selected.name);
      if (ctrl.showShortName) {
        name += ` (${localeService.getLocalizedValue(selected.shortName)})`;
      }
      if (selected.deprecated) {
        name += ` (${$translate.instant('SIS_COMPONENTS.SELECT.DEPRECATED')})`;
      }
      return name;
    };
    ctrl.selectItem = function (item) {
      if (!ctrl.allowClear && ctrl.id === getId(item)) {
        return;
      }
      if (notNull(item)) {
        _.pull(ctrl.disabledIds, ctrl.id);
        ctrl.id = getId(item);
        if (angular.isDefined(ctrl.disabledIds)) {
          ctrl.disabledIds.push(ctrl.id);
        }
      } else {
        ctrl.id = undefined;
      }
      if (ctrl.onSelect) {
        ctrl.onSelect({
          obj: item
        });
      }
      ctrl.validate();
    };
    ctrl.validate = function () {
      if (ctrl.required) {
        ctrl.form.$setValidity(ctrl.name + '.required', ctrl.value !== undefined);
      }
    };
    ctrl.lazyGetAll = function () {
      if (ctrl.model.lazyGetAll) {
        return ctrl.model.lazyGetAll();
      } else {
        return $q.when(true);
      }
    };
    ctrl.groupBy = function (item) {
      if (!ctrl.groupingEnabled || !ctrl.showAllUniversities || !angular.isDefined(ctrl.universityOrgId)) {
        return undefined;
      }
      if (!_.isNil(item.more)) {
        return "";
      } else if (_.includes(item.universityOrgIds, ctrl.universityOrgId)) {
        return ctrl.myUniversity;
      } else {
        return ctrl.otherUniversities;
      }
    };
    ctrl.groupFilter = function () {
      if (!ctrl.groupingEnabled || !ctrl.showAllUniversities || !angular.isDefined(ctrl.universityOrgId)) {
        return undefined;
      } else {
        return [ctrl.myUniversity, ctrl.otherUniversities, ""];
      }
    };
    ctrl.orderBy = function (currentLang) {
      const order = ctrl.reversedOrder ? 'DESC' : 'ASC';
      var orderBy = _.map(_.filter(ctrl.localeOrder, function (locale) {
        return locale !== currentLang;
      }), function (locale) {
        return ['name.' + locale, order];
      });
      orderBy.splice(0, 0, ['name.' + currentLang, order]);
      return orderBy;
    };
    ctrl.filterData = function (filterString) {
      var currentLang = localeService.getCurrentLocale();
      let query = {
        limit: ctrl.maxResults,
        where: {}
      };
      query.where['name.' + currentLang] = {
        'likei': '%' + filterString + '%'
      };
      query.orderBy = ctrl.orderBy(currentLang);
      if (angular.isDefined(ctrl.universityOrgId) && !ctrl.showAllUniversities) {
        query.where.universityOrgId = {
          '==': ctrl.universityOrgId
        };
      }
      ctrl.lazyGetAll().then(function () {
        let filterResults = ctrl.model.filter(query);
        if (ctrl.includedIds) {
          filterResults = _.filter(filterResults, result => _.includes(ctrl.includedIds, getId(result)));
        }
        ctrl.filterResults = filterResults;
        if (ctrl.showAllUniversities) {
          ctrl.groupingEnabled = _.find(ctrl.filterResults, function (item) {
            return _.includes(item.universityOrgIds, ctrl.universityOrgId);
          });
        }
        if (ctrl.filterResults.length > ctrl.maxResults - 1) {
          ctrl.filterResults = _.take(ctrl.filterResults, ctrl.maxResults - 1);
          ctrl.filterResults.push({
            name: {
              fi: ""
            },
            skipShortName: true,
            more: $translate.instant("SIS_COMPONENTS.OBJECT.REFERRED_OBJECT_SELECT_EDITOR.TOO_MANY_SEARCH_RESULTS")
          });
        }
      });
    };
  }
})();