import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { FudisErrorSummaryService, FudisValidators } from '@funidata/ngx-fudis';
import { TranslocoService } from '@ngneat/transloco';
import { MaxLength } from 'common-typescript/constants';
import { DeepPartial, FinnishAddress, GenericAddress, PrivatePerson, Urn } from 'common-typescript/types';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CONSTRAINT_SERVICE } from 'sis-common/ajs-upgraded-modules';
import { SisFormBuilder } from 'sis-components/form/sis-form-builder.service';
import { setEventHandlersForEditMode } from 'sis-components/util/edit-mode/utils';

@Component({
    selector: 'app-edit-contact-information',
    templateUrl: './edit-contact-information.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditContactInformationComponent implements OnInit, OnDestroy {

    @Input() studentProfile: PrivatePerson;
    @Output() saveEvent = new EventEmitter<any>();

    countryCodebookUrns = 'urn:code:country';
    countryUrnFI = 'urn:code:country:246';
    countryUrnAX = 'urn:code:country:248';
    form: FormGroup;
    privatePersonConstraints: any;
    finnishAddressConstraints: any;
    genericAddressConstraints: any;
    validationGroups = ['Draft', 'Active'];
    isPrimaryAddressEditable: boolean;
    copyStudentProfile: PrivatePerson;
    errorSummaryVisible = false;

    /** Used to unsubscribe from the status and value change observables hooked up to the form */
    destroyed$ = new Subject<void>();
    constructor(private fb: SisFormBuilder,
                private errorSummaryService: FudisErrorSummaryService,
                private translocoService: TranslocoService,
                @Inject(CONSTRAINT_SERVICE) private constraintService: any) {
        setEventHandlersForEditMode();
    }

    ngOnInit(): void {
        this.copyStudentProfile = { ...this.studentProfile };
        this.isPrimaryAddressEditable = _.get(this.copyStudentProfile, 'primaryAddress.isUserEditable') ?? true;

        this.finnishAddressConstraints = this.constraintService.getTypeConstraints('FinnishAddress', this.validationGroups);
        this.genericAddressConstraints = this.constraintService.getTypeConstraints('GenericAddress', this.validationGroups);
        this.privatePersonConstraints = this.constraintService.getTypeConstraints('PrivatePerson', this.validationGroups);

        this.form = this.buildForm(this.copyStudentProfile);
        this.setupValueChangeSubscriptions();
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
    }

    buildForm(person: DeepPartial<PrivatePerson> = {}): FormGroup {
        const primaryAddress: Partial<FinnishAddress> = person.primaryAddress || {};
        const finnishSecondaryAddress: Partial<FinnishAddress> = person.secondaryAddress?.type === 'FinnishAddress' ?
            person.secondaryAddress as Partial<FinnishAddress> : null;
        const genericSecondaryAddress: Partial<GenericAddress> = person.secondaryAddress?.type === 'GenericAddress' ?
            person.secondaryAddress as Partial<GenericAddress> : null;
        return this.fb.group({
            phoneNumber: this.fb.control(
                person.phoneNumber,
                FudisValidators.maxLength(this.privatePersonConstraints.phoneNumber.size.max, this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MAX_LENGTH')),
            ),
            secondaryEmail: this.fb.control(
                person.secondaryEmail,
                ([
                    FudisValidators.email(this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.EMAIL')),
                    FudisValidators.maxLength(this.privatePersonConstraints.secondaryEmail.size.max, this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MAX_LENGTH')),
                ]),
            ),
            secondaryAddressType: this.fb.control(person?.secondaryAddress?.type || 'FinnishAddress'),
            primaryAddress: this.fb.group({
                streetAddress: this.fb.control(
                    primaryAddress.streetAddress,
                    ([
                        FudisValidators.maxLength(this.finnishAddressConstraints.streetAddress.size.max, this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MAX_LENGTH')),
                        FudisValidators.required(this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.REQUIRED')),
                    ]),
                ),
                postalCode: this.fb.control(
                    primaryAddress.postalCode,
                    ([
                        FudisValidators.maxLength(this.finnishAddressConstraints.postalCode.size.max, this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MAX_LENGTH')),
                        FudisValidators.required(this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.REQUIRED')),
                    ]),
                ),
                city: this.fb.control(
                    primaryAddress.city,
                    ([
                        FudisValidators.maxLength(this.finnishAddressConstraints.city.size.max, this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MAX_LENGTH')),
                        FudisValidators.required(this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.REQUIRED')),
                    ]),
                ),
                countryUrn: this.fb.control(this.countryUrnFI),
                type: this.fb.control('FinnishAddress'),
            },
            ),
            finnishSecondaryAddress: this.fb.group({
                streetAddress: this.fb.control(
                    { value: finnishSecondaryAddress?.streetAddress, disabled: !finnishSecondaryAddress },
                    ([
                        FudisValidators.maxLength(this.finnishAddressConstraints.streetAddress.size.max, this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MAX_LENGTH')),
                        FudisValidators.required(this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.REQUIRED')),
                    ]),
                ),
                postalCode: this.fb.control(
                    { value: finnishSecondaryAddress?.postalCode, disabled: !finnishSecondaryAddress },
                    ([
                        FudisValidators.maxLength(this.finnishAddressConstraints.postalCode.size.max, this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MAX_LENGTH')),
                        FudisValidators.required(this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.REQUIRED')),
                    ]),
                ),
                city: this.fb.control(
                    { value: finnishSecondaryAddress?.city, disabled: !finnishSecondaryAddress },
                    ([
                        FudisValidators.maxLength(this.finnishAddressConstraints.city.size.max, this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MAX_LENGTH')),
                        FudisValidators.required(this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.REQUIRED')),
                    ]),
                ),
                countryUrn: this.fb.control(this.countryUrnFI),
                type: this.fb.control('FinnishAddress'),
            },
            ),
            genericSecondaryAddress: this.fb.group({
                countryUrn: this.fb.control(
                    { value: genericSecondaryAddress?.countryUrn, disabled: !genericSecondaryAddress },
                    FudisValidators.required(this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.REQUIRED')),
                ),
                address: this.fb.control(
                    { value: genericSecondaryAddress?.address, disabled: !genericSecondaryAddress },
                    ([
                        FudisValidators.maxLength(MaxLength.MAX_MEDIUM_STRING_LENGTH, this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MAX_LENGTH')),
                        FudisValidators.required(this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.REQUIRED')),
                    ]),
                ),
                type: this.fb.control('GenericAddress'),
            },
            ),
        });
    }

    private setupValueChangeSubscriptions(): void {
        this.secondaryAddressType.valueChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe({
                next: (result) => {
                    if (result === 'FinnishAddress') {
                        this.finnishSecondaryAddress.enable();
                        this.finnishSecondaryAddress.updateValueAndValidity();
                        this.genericSecondaryAddress.disable();
                    } else if (result === 'GenericAddress') {
                        this.genericSecondaryAddress.enable();
                        this.genericSecondaryAddress.updateValueAndValidity();
                        this.finnishSecondaryAddress.disable();
                    } else {
                        this.finnishSecondaryAddress.disable();
                        this.genericSecondaryAddress.disable();
                    }
                },
            });
    }

    isSecondaryAddressInFinland(): boolean {
        return this.secondaryAddressType.value === 'FinnishAddress';
    }

    addSecondaryAddress(): void {
        this.copyStudentProfile.secondaryAddress = {} as any;
        this.secondaryAddressType.setValue('FinnishAddress');
    }

    removeSecondaryAddress(): void {
        this.copyStudentProfile.secondaryAddress = null;
        this.secondaryAddressType.setValue(null);
        this.clearSecondaryAddressInputFields();

    }

    clearSecondaryAddressInputFields() {
        this.finnishSecondaryAddressStreetAddress.reset();
        this.finnishSecondaryAddressCity.reset();
        this.finnishSecondaryAddressPostalCode.reset();

        this.genericSecondaryAddressAddress.reset();
        this.genericSecondaryAddressCountryUrn.reset();
    }

    onSelectCodeUrn(control: AbstractControl, urn: Urn): void {
        if (urn !== control.value) {
            control.setValue(urn);
            control.markAsTouched();
            control.markAsDirty();
        }
    }

    saveContactInformationForm(): void {
        if (this.form.valid) {
            const {
                primaryAddress: primaryAddressFields,
                secondaryAddressType,
                finnishSecondaryAddress: finnishSecondaryAddressFields,
                genericSecondaryAddress: genericSecondaryAddressFields,
                ...otherFields
            } = this.form.value;

            let secondaryAddress = null;
            if (this.secondaryAddressType.value !== null) {
                secondaryAddress = secondaryAddressType === 'FinnishAddress' ? { ...finnishSecondaryAddressFields, address: null } :
                    { ...genericSecondaryAddressFields, streetAddress: null, city: null, postalCode: null };
            }

            const updatedStudentProfile: PrivatePerson = _.merge({}, this.copyStudentProfile, {
                ...otherFields,
                primaryAddress: primaryAddressFields,
                secondaryAddress,
            });
            this.saveEvent.emit(updatedStudentProfile);
        }
    }

    get phoneNumber(): FormControl {
        return this.form.get('phoneNumber') as FormControl;
    }

    get secondaryEmail(): FormControl {
        return this.form.get('secondaryEmail') as FormControl;
    }

    get primaryAddressStreetAddress(): FormControl {
        return this.form.get('primaryAddress.streetAddress') as FormControl;
    }

    get primaryAddressPostalCode(): FormControl {
        return this.form.get('primaryAddress.postalCode') as FormControl;
    }

    get primaryAddressCity(): FormControl {
        return this.form.get('primaryAddress.city') as FormControl;
    }

    get finnishSecondaryAddress(): FormControl {
        return this.form.get('finnishSecondaryAddress') as FormControl;
    }

    get finnishSecondaryAddressStreetAddress(): FormControl {
        return this.form.get('finnishSecondaryAddress.streetAddress') as FormControl;
    }

    get finnishSecondaryAddressPostalCode(): FormControl {
        return this.form.get('finnishSecondaryAddress.postalCode') as FormControl;
    }

    get finnishSecondaryAddressCity(): FormControl {
        return this.form.get('finnishSecondaryAddress.city') as FormControl;
    }

    get secondaryAddressType(): FormControl {
        return this.form.get('secondaryAddressType') as FormControl;
    }

    get genericSecondaryAddress(): FormControl {
        return this.form.get('genericSecondaryAddress') as FormControl;
    }

    get genericSecondaryAddressAddress(): FormControl {
        return this.form.get('genericSecondaryAddress.address') as FormControl;
    }

    get genericSecondaryAddressCountryUrn(): FormControl {
        return this.form.get('genericSecondaryAddress.countryUrn') as FormControl;
    }

}
