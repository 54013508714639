import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

export type NotificationVariant = 'warning' | 'danger' | 'success' | 'light';

/**
 * Sis-contextual-notification component has 4 main variants: warning, danger, success, light.
 * Example: <sis-contextual-notification variant="success">Write message here</sis-contextual-notification>
 * These four types have their own default icons. If some other icon is preferred, this can be achieved by calling sis-icon identifier name.
 * Example: icon="lock". Complete list of icons can be found from icons.less
 */

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-contextual-notification',
    templateUrl: './contextual-notification.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ContextualNotificationComponent implements OnChanges, OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sisComponents.ContextualNotification',
        directiveName: 'sisContextualNotification',
    };

    @Input() content?: string;
    /* The content of the contextual-notification. Using content projection for defining the content is preferable, since it's
* more flexible. This input property mainly exists to make writing Storybook stories easier.*/
    @Input() icon: string;
    @Input() variant?: NotificationVariant;

    ariaVariantText: string;

    ngOnInit(): void {
        if (!this.icon) {
            this.getClasses();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.icon) {
            this.getClasses();
        }
    }

    private getClasses(): void {
        switch (this.variant) {
            case 'warning':
                this.icon = 'exclamation-mark-circle';
                this.ariaVariantText = 'ARIA_LABEL.NOTIFICATION_WARNING';
                break;
            case 'danger':
                this.icon = 'alert';
                this.ariaVariantText = 'ARIA_LABEL.NOTIFICATION_DANGER';
                break;
            case 'success':
                this.icon = 'checkmark-circle';
                break;
            case 'light':
                this.icon = 'info-circle';
                break;
            default:
                break;
        }
    }
}
