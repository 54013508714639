import { Transition } from '@uirouter/core';
import { OPEN_UNIVERSITY_FEATURES_ENABLED_FOR_STUDENT_DEFAULT } from 'common-typescript/constants';
import { CourseUnit, OpenUniversityCart, OpenUniversityProduct, SuomiFiUserBasicDetails, UniversitySettings } from 'common-typescript/types';
import { get } from 'lodash';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { ConfirmDialogService } from 'sis-components/confirm/confirm-dialog.service';
import { resolveTransitionParameter } from 'sis-components/router/router-utils';
import { SisStateDeclaration } from 'sis-components/router/types';
import { CourseUnitEntityService } from 'sis-components/service/course-unit-entity.service';
import { OpenUniversityCartCustomerService } from 'sis-components/service/open-university-cart-customer.service';
import { OpenUniversityProductEntityService } from 'sis-components/service/open-university-product-entity.service';
import { SuomiFiService } from 'sis-components/service/suomi-fi.service';
import { UniversityService } from 'sis-components/service/university.service';

import { CartErrorComponent } from './cart/cart-error/cart-error.component';
import { CeeposPaymentCompleteComponent } from './cart/ceepos-payment-complete/ceepos-payment-complete.component';
import { CartConfirmationComponent } from './cart/confirmation/cart-confirmation.component';
import { OpenUniversityCartComponent } from './cart/open-university-cart.component';
import { PaymentFailedComponent } from './cart/payment-failed/payment-failed.component';
import { PaymentProcessingComponent } from './cart/payment-processing/payment-processing.component';
import { PaytrailPaymentCompleteComponent } from './cart/paytrail-payment-complete/paytrail-payment-complete.component';
import { OpenUniversityEnrolmentWizardComponent } from './open-university-enrolment-wizard/open-university-enrolment-wizard.component';
import { OpenUniversityStudiesActivateWizardComponent } from './open-university-studies-activate-wizard/open-university-studies-activate-wizard.component';
import { SuomiFiErrorComponent } from './suomi-fi-error/suomi-fi-error.component';

const REDIRECT_STATE = 'student.course-unit.open-university';

const REDIRECT_LOGIN_STATE = 'student.login';

const isProductFoundInsideTheCart = (openUniCart: OpenUniversityCart, openUniProduct: OpenUniversityProduct, cu: CourseUnit) =>
    openUniCart?.items.some(item => item.openUniversityProductId === openUniProduct.id && openUniProduct.courseUnitId === cu.id);

const redirectWhenOpenUniProductMissingFromOpenUniCart = (trans: Transition) => {
    const openUniCartPromise = trans.injector().getAsync('openUniversityCart');
    const openUniProductPromise = trans.injector().getAsync('openUniversityProduct');
    const courseUnitPromise = trans.injector().getAsync('courseUnit');
    return Promise.all([openUniCartPromise, openUniProductPromise, courseUnitPromise])
        .then(data => {
            if (isProductFoundInsideTheCart(data[0], data[1], data[2])) {
                // All is good, do not redirect
                return undefined;
            }
            throw new Error(`Open-university-cart: ${data[0].id} does not contain open-university-product: ${data[1].id} with selected course-unit: ${data[2].id}`);
        })
        .catch(err => {
            console.error(err.message);
            return { state: REDIRECT_STATE };
        });
};

const redirectWhenSuomiFiSessionMissing = async (trans: Transition) => {
    const suomiFiSessionPromise = trans.injector().getAsync('suomiFiUserBasicDetails');
    // If there is a error creating the suomi.fi session then prevent accessing the state.
    return suomiFiSessionPromise
        .then(() => undefined)
        .catch((err: any) => {
            console.error(err.message);
            return { state: 'student.open-university-studies-activate' };
        });
};

const cartFocusedNavConfig = {
    active: true,
    title: 'OPEN_UNIVERSITY_CART.CART',
    from: 'student.search.open-university',
    loginRequired: false,
    forceFocusedNavFromState: true,
};

export const redirectToStateIfOpenUniversityFeaturesAreNotEnabledForStudentOrAlt = (state: string, altFunction?: Function) =>
    ($transition$: Transition) => firstValueFrom(
        $transition$.injector().get(UniversityService)
            .getCurrentUniversitySettings())
        .then((settings: UniversitySettings) => {
            const openUniversityFeaturesEnabledForStudent = get(
                settings,
                'frontendFeatureToggles.openUniversityFeaturesEnabledForStudent',
                OPEN_UNIVERSITY_FEATURES_ENABLED_FOR_STUDENT_DEFAULT,
            );
            if (openUniversityFeaturesEnabledForStudent) {
                return altFunction ? altFunction($transition$) : undefined; // undefined means no redirect
            }
            return { state };
        })
        .catch((err: any) => {
            if (err?.message) {
                console.error(err.message);
            }
            return { state };
        });

export const openUniversityStates: SisStateDeclaration[] = [
    {
        name: 'student.course-unit.open-university-enrolment-wizard',
        url: '/open-uni-cart/:openUniversityCartId/open-uni-product/:openUniversityProductId/open-university-enrolment-wizard',
        params: {
            focusedNav: {
                active: true,
                title: 'OPEN_UNIVERSITY.ENROLMENT_WIZARD.SELECT_TEACHING',
                from: REDIRECT_STATE,
                loginRequired: false,
                forceFocusedNavFromState: true,
            },
        },
        data: {
            showHeader: false,
            headerWithoutBreadcrumbs: true,
        },
        views: {
            '@': { component: OpenUniversityEnrolmentWizardComponent }, // '@' overrides parent <ui-view>, which in this case contains the course unit info brochure
        },
        resolve: {
            courseUnit: courseUnitResolver,
            openUniversityCart: openUniversityCartResolver,
            openUniversityProduct: openUniversityProductResolver,
        },
        redirectTo: redirectToStateIfOpenUniversityFeaturesAreNotEnabledForStudentOrAlt('student.course-unit.brochure', redirectWhenOpenUniProductMissingFromOpenUniCart),
        onExit: onOpenUniversityEnrolmentWizardExit,
    },
    {
        name: 'student.open-university-cart',
        // DON'T CHANGE THIS URL (unless you know what you're doing). Users will return to children states from external payment systems.
        url: '/open-university-cart',
        redirectTo: 'student.open-university-cart.current-cart',
    },
    {
        name: 'student.open-university-cart.current-cart',
        url: '/current',
        component: OpenUniversityCartComponent,
        params: {
            focusedNav: cartFocusedNavConfig,
        },
        data: {
            showHeader: false,
            headerWithoutBreadcrumbs: true,
        },
        redirectTo: redirectToStateIfOpenUniversityFeaturesAreNotEnabledForStudentOrAlt('student.logged-in.frontpage'),
    },
    {
        // DON'T CHANGE THIS URL (unless you know what you're doing). Backend redirect the zero payments here.
        name: 'student.open-university-cart.payment-successful',
        url: '/payment-successful?paymentId',
        component: CartConfirmationComponent,
        params: {
            focusedNav: cartFocusedNavConfig,
        },
        data: {
            showHeader: false,
            headerWithoutBreadcrumbs: true,
            browserTabTitle: 'OPEN_UNIVERSITY_CART.WIZARD.CONFIRMATION.PAGE_TAB_TITLE',
        },
        resolve: {
            paymentId: resolveTransitionParameter('paymentId'),
        },
        redirectTo: redirectToStateIfOpenUniversityFeaturesAreNotEnabledForStudentOrAlt('student.logged-in.frontpage'),
    },
    {
        name: 'student.open-university-cart.payment-failed',
        url: '/payment-failed',
        component: PaymentFailedComponent,
        params: {
            focusedNav: cartFocusedNavConfig,
        },
        data: {
            showHeader: false,
            headerWithoutBreadcrumbs: true,
        },
        redirectTo: redirectToStateIfOpenUniversityFeaturesAreNotEnabledForStudentOrAlt('student.logged-in.frontpage'),
    },
    {
        name: 'student.open-university-cart.payment-processing',
        url: '/payment-processing',
        component: PaymentProcessingComponent,
        params: {
            focusedNav: cartFocusedNavConfig,
        },
        data: {
            showHeader: false,
            headerWithoutBreadcrumbs: true,
        },
        redirectTo: redirectToStateIfOpenUniversityFeaturesAreNotEnabledForStudentOrAlt('student.logged-in.frontpage'),
    },
    {
        name: 'student.open-university-cart.error',
        url: '/cart-error?errorType',
        component: CartErrorComponent,
        params: {
            focusedNav: cartFocusedNavConfig,
        },
        data: {
            showHeader: false,
            headerWithoutBreadcrumbs: true,
        },
        redirectTo: redirectToStateIfOpenUniversityFeaturesAreNotEnabledForStudentOrAlt('student.logged-in.frontpage'),
    },
    {
        name: 'student.open-university-cart.ceepos-return-address',
        url: '/ceepos-confirmation?Id&Status&Reference&Payments&PaymentMethod&PaymentSum&Timestamp&PaymentDescription&Hash',
        component: CeeposPaymentCompleteComponent,
        params: {
            focusedNav: cartFocusedNavConfig,
        },
        data: {
            showHeader: false,
            headerWithoutBreadcrumbs: true,
        },
        redirectTo: redirectToStateIfOpenUniversityFeaturesAreNotEnabledForStudentOrAlt('student.logged-in.frontpage'),
    },
    {
        name: 'student.open-university-cart.paytrail-return-success',
        url: '/paytrail-confirmation',
        component: PaytrailPaymentCompleteComponent,
        params: {
            focusedNav: cartFocusedNavConfig,
        },
        data: {
            showHeader: false,
            headerWithoutBreadcrumbs: true,
        },
        redirectTo: redirectToStateIfOpenUniversityFeaturesAreNotEnabledForStudentOrAlt('student.logged-in.frontpage'),
    },
    {
        name: 'student.open-university-cart.paytrail-return-cancel',
        url: '/paytrail-cancel',
        component: PaytrailPaymentCompleteComponent,
        params: {
            focusedNav: cartFocusedNavConfig,
        },
        data: {
            showHeader: false,
            headerWithoutBreadcrumbs: true,
        },
        redirectTo: redirectToStateIfOpenUniversityFeaturesAreNotEnabledForStudentOrAlt('student.logged-in.frontpage'),
    },
    {
        name: 'student.open-university-studies-activate',
        //  DON'T CHANGE THIS URL! /open-university-studies-activate route is referenced in open university emails, check OpenUniversityOrderConfirmationEmailContentBuilder.ACTIVATION_LINK_TEMPLATE
        url: '/open-uni-cart/:openUniversityCartId/activation-code/:activationCode/open-university-studies-activate',
        params: {
            focusedNav: {
                active: true,
                title: 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.TITLE',
                from: 'student.logged-in.frontpage',
                loginRequired: false,
                forceFocusedNavFromState: true,
            },
        },
        data: {
            showHeader: false,
            headerWithoutBreadcrumbs: true,
            browserTabTitle: 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP1.PAGE_TAB_TITLE',
        },
        views: {
            '': { component: OpenUniversityStudiesActivateWizardComponent },
        },
        resolve: {
            openUniversityCart: openUniversityCartWithActivationCodeResolver,
            currentStep: openUniversityStudiesActivateWizardStepResolver,
        },
        redirectTo: redirectToStateIfOpenUniversityFeaturesAreNotEnabledForStudentOrAlt('student.logged-in.frontpage'),
        onExit: onOpenUniversityActivateWizardExit,
    },
    {
        name: 'student.logged-in.open-university-studies-activate',
        url: '/open-uni-cart/:openUniversityCartId/activation-code/:activationCode/open-university-studies-activate-authenticated',
        params: {
            focusedNav: {
                active: true,
                title: 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.TITLE',
                from: 'student.logged-in.frontpage',
                forceFocusedNavFromState: true,
            },
        },
        data: {
            showHeader: false,
            headerWithoutBreadcrumbs: true,
            browserTabTitle: 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP1.PAGE_TAB_TITLE',
        },
        views: {
            '': { component: OpenUniversityStudiesActivateWizardComponent },
        },
        resolve: {
            openUniversityCart: openUniversityCartWithActivationCodeResolver,
            currentStep: openUniversityStudiesActivateWizardStepResolver,
        },
        redirectTo: redirectToStateIfOpenUniversityFeaturesAreNotEnabledForStudentOrAlt('student.logged-in.frontpage'),
        onExit: onOpenUniversityActivateWizardExit,
    },
    {
        name: 'student.open-university-studies-activate-suomi-fi',
        url: '/open-uni-cart/:openUniversityCartId/activation-code/:activationCode/open-university-studies-activate-authenticated-suomi-fi',
        params: {
            focusedNav: {
                active: true,
                title: 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.TITLE',
                from: REDIRECT_LOGIN_STATE,
                loginRequired: false, // means that sisu-login is not required, but suomi.fi session is required.
                forceFocusedNavFromState: true,
            },
        },
        data: {
            showHeader: false,
            headerWithoutBreadcrumbs: true,
            browserTabTitle: 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP2.PAGE_TAB_TITLE',
        },
        views: {
            '': { component: OpenUniversityStudiesActivateWizardComponent },
        },
        resolve: {
            openUniversityCart: openUniversityCartWithActivationCodeResolver,
            suomiFiUserBasicDetails: suomiFiSessionResolver,
            currentStep: openUniversityStudiesActivateWizardStepResolver,
        },
        redirectTo: redirectToStateIfOpenUniversityFeaturesAreNotEnabledForStudentOrAlt('student.logged-in.frontpage', redirectWhenSuomiFiSessionMissing),
        onExit: onOpenUniversityActivateWizardExit,
    },
    {
        name: 'student.logged-in.open-university-studies-activate-confirmation-completed',
        url: '/open-uni-cart/open-university-studies-activate/confirmation-completed',
        params: {
            cartParam: null,
            isStaffUserParam: false,
            focusedNav: {
                active: true,
                title: 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.TITLE',
                from: 'student.logged-in.frontpage',
                forceFocusedNavFromState: true,
            },
        },
        data: {
            showHeader: false,
            headerWithoutBreadcrumbs: true,
            browserTabTitle: 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP3.PAGE_TAB_TITLE',
        },
        views: {
            '': { component: OpenUniversityStudiesActivateWizardComponent },
        },
        resolve: {
            openUniversityCart: openUniversityCartFromParamsResolver,
            currentStep: openUniversityStudiesActivateWizardStepResolver,
            isStaffUser: isStaffUserFromParamsResolver,
        },
        redirectTo: redirectToStateIfOpenUniversityFeaturesAreNotEnabledForStudentOrAlt('student.logged-in.frontpage'),
    },
    {
        name: 'student.open-university-studies-activate-confirmation-completed-suomi-fi',
        url: '/open-uni-cart/open-university-studies-activate/confirmation-completed-suomi-fi',
        params: {
            cartParam: null,
            emailParam: null,
            focusedNav: {
                active: true,
                title: 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.TITLE',
                from: REDIRECT_LOGIN_STATE,
                loginRequired: false,
                forceFocusedNavFromState: true,
            },
        },
        data: {
            showHeader: false,
            headerWithoutBreadcrumbs: true,
            browserTabTitle: 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP3.PAGE_TAB_TITLE',
        },
        views: {
            '': { component: OpenUniversityStudiesActivateWizardComponent },
        },
        resolve: {
            openUniversityCart: openUniversityCartFromParamsResolver,
            currentStep: openUniversityStudiesActivateWizardStepResolver,
            secondaryEmail: emailFromParamsResolver,
        },
        redirectTo: redirectToStateIfOpenUniversityFeaturesAreNotEnabledForStudentOrAlt('student.logged-in.frontpage'),
        onExit: onOpenUniversityActivateWizardExit,
    },
    {
        name: 'student.suomi-fi',
        url: '/suomi-fi',
        component: SuomiFiErrorComponent,
        data: {
            showHeader: false,
            headerWithoutBreadcrumbs: true,
        },
    },
];

export function onOpenUniversityEnrolmentWizardExit($transition$: Transition) {
    const confirmDialogService = $transition$.injector().get(ConfirmDialogService);
    if ($transition$.$to().name === $transition$.$from().name) {
        return;
    }
    if ($transition$.options().custom.skipConfirmationDialog) {
        return;
    }
    const texts = {
        title: 'OPEN_UNIVERSITY.ENROLMENT_WIZARD.DISMISS_MODAL_TITLE',
        descriptions: ['OPEN_UNIVERSITY.ENROLMENT_WIZARD.DISMISS_MODAL_DESC_1', 'OPEN_UNIVERSITY.ENROLMENT_WIZARD.DISMISS_MODAL_DESC_2'],
        confirmText: 'OPEN_UNIVERSITY.ENROLMENT_WIZARD.DISMISS_MODAL_BUTTON',
    };
    return confirmDialogService.confirm(texts);
}
onOpenUniversityEnrolmentWizardExit.$inject = ['$transition$'];

export function onOpenUniversityActivateWizardExit($transition$: Transition) {
    const confirmDialogService = $transition$.injector().get(ConfirmDialogService);
    const suomiFiService = $transition$.injector().get(SuomiFiService);
    if ($transition$.$to().name === $transition$.$from().name) {
        return;
    }
    if ($transition$.options().custom.skipConfirmationDialog) {
        return;
    }

    if ($transition$.$from().name.includes('studies-activate-suomi-fi')) {
        const suomiFitexts = {
            title: 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.DISMISS_MODAL_TITLE',
            descriptions: [
                'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.DISMISS_MODAL_DESC_SUOMI_FI',
                'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.DISMISS_MODAL_DESC2',
            ],
            confirmText: 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.DISMISS_MODAL_BUTTON',
        };
        return confirmDialogService.confirm(suomiFitexts)
            .then(() => suomiFiService.logout($transition$.$to().url.pattern));
    }

    if ($transition$.$from().name.includes('open-university-studies-activate-confirmation-completed')) {
        const confirmTexts = {
            title: 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.DISMISS_MODAL_TITLE',
            descriptions: [
                'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.DISMISS_MODAL_DESC_SUOMI_FI_STEP_3',
                'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.DISMISS_MODAL_DESC2',
            ],
            confirmText: 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.DISMISS_MODAL_BUTTON',
        };
        return confirmDialogService.confirm(confirmTexts);
    }

    const defaultTexts = {
        title: 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.DISMISS_MODAL_TITLE',
        descriptions: [
            'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.DISMISS_MODAL_DESC_DEFAULT',
            'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.DISMISS_MODAL_DESC2',
        ],
        confirmText: 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.DISMISS_MODAL_BUTTON',
    };
    return confirmDialogService.confirm(defaultTexts);
}
onOpenUniversityActivateWizardExit.$inject = ['$transition$'];

export function courseUnitResolver($transition$: Transition): Promise<CourseUnit> {
    return lastValueFrom($transition$.injector().get(CourseUnitEntityService)
        .get($transition$.params().courseUnitId));
}
courseUnitResolver.$inject = ['$transition$'];

export function openUniversityCartResolver($transition$: Transition): Promise<OpenUniversityCart> {
    return lastValueFrom($transition$.injector().get(OpenUniversityCartCustomerService)
        .getCurrentCart()
        .pipe(take(1)));
}
openUniversityCartResolver.$inject = ['$transition$'];

export function openUniversityProductResolver($transition$: Transition): Promise<OpenUniversityProduct[]> {
    return lastValueFrom($transition$.injector().get(OpenUniversityProductEntityService)
        .get($transition$.params().openUniversityProductId));
}
openUniversityProductResolver.$inject = ['$transition$'];

export function openUniversityCartWithActivationCodeResolver($transition$: Transition): Promise<OpenUniversityCart> {
    return lastValueFrom($transition$.injector().get(OpenUniversityCartCustomerService)
        .getWithActivationCode($transition$.params().openUniversityCartId, $transition$.params().activationCode)
        .pipe(take(1)));
}
openUniversityCartWithActivationCodeResolver.$inject = ['$transition$'];

export function openUniversityCartFromParamsResolver($transition$: Transition): Promise<OpenUniversityCart> {
    return new Promise((resolve) => {
        resolve($transition$.params().cartParam);
    });
}
openUniversityCartFromParamsResolver.$inject = ['$transition$'];

export function emailFromParamsResolver($transition$: Transition): string {
    return $transition$.params().emailParam;
}
emailFromParamsResolver.$inject = ['$transition$'];

export function isStaffUserFromParamsResolver($transition$: Transition): boolean {
    return $transition$.params().isStaffUserParam;
}
isStaffUserFromParamsResolver.$inject = ['$transition$'];

export function suomiFiSessionResolver($transition$: Transition): Promise<SuomiFiUserBasicDetails> {
    return lastValueFrom($transition$.injector().get(SuomiFiService).login().pipe(take(1)));
}
suomiFiSessionResolver.$inject = ['$transition$'];

export function openUniversityStudiesActivateWizardStepResolver($transition$: Transition): number {
    const to = $transition$.$to().name;
    if (to.includes('open-university-studies-activate-confirmation-completed')) {
        return 2;
    }
    if (to.includes('student.logged-in.') || to.includes('suomi-fi')) {
        return 1;
    }
    return 0;
}
openUniversityStudiesActivateWizardStepResolver.$inject = ['$transition$'];
