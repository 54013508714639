import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { dateUtils } from 'common-typescript';
import { ServiceBreak, UniversityConfig } from 'common-typescript/types';
import * as _ from 'lodash';
import moment, { Moment } from 'moment';
import { interval, Observable, ReplaySubject, Subscription } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { ConfigService } from 'sis-common/config/config.service';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { Alert, AlertsService, AlertType } from '../alerts/alerts-ng.service';

/**
 * Provides the service break data from nginx.
 */
@Injectable({
    providedIn: 'root',
})
@StaticMembers<DowngradedService>()
export class ServiceBreakService implements OnDestroy {
    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'sis-components.service.serviceBreakService',
        serviceName: 'serviceBreakService',
    };

    // Url path for getting the breaks.json served by nginx
    readonly breaksJsonLocation = '/unavailable/api/breaks';
    private readonly updateInterval = Subscription.EMPTY;
    private readonly langChange = Subscription.EMPTY;
    private readonly unavailableDataUrl: string;
    private readonly updateIntervalMS: number;
    private alertIds: string[];
    private serviceBreaks: ServiceBreak[];
    private currentBreaksSource = new ReplaySubject(1);
    private criticalBreaksSource = new ReplaySubject(1);
    private pollIntervalMinutes = 5; // 5 minutes by default
    private lastUpdateMoment: number;

    constructor(private alertsService: AlertsService, private http: HttpClient, private configService: ConfigService,
                private translateService: TranslateService) {
        const universityConfig = this.configService.get() as UniversityConfig;
        if (universityConfig.serviceBreakUpdateIntervalMinutes) {
            this.pollIntervalMinutes = universityConfig.serviceBreakUpdateIntervalMinutes;
        }
        if (universityConfig.unavailableDataUrl) {
            this.unavailableDataUrl = universityConfig.unavailableDataUrl;
        } else {
            this.unavailableDataUrl = this.breaksJsonLocation;
        }
        this.updateIntervalMS = this.getPollIntervalMinutes() * 60000; // milliseconds in X minutes
        this.updateInterval = interval(this.updateIntervalMS).pipe(
            startWith(0),
            switchMap(() => this.loadServiceBreaksData()))
            .subscribe({
                next: (breaks: ServiceBreak[]) => {
                    const filtered = this.filterServiceBreaksByOrigin(breaks, this.getHostOrigin());
                    filtered.forEach(this.setDefaults);
                    this.updateServiceBreaks(filtered);
                },
                error: (error: any) => {
                    // The system error dialog is intentionally blocked, but something should be logged just in case bad things did happen
                    let errorMessage;
                    if (error instanceof HttpErrorResponse) {
                        errorMessage = error.message;
                    } else {
                        errorMessage = JSON.stringify(error);
                    }
                    console.warn(`Could not load service breaks data. ${errorMessage}`);
                    this.updateServiceBreaks([]);
                },
            });
        this.langChange = this.translateService.onLangChange
            .subscribe((event: LangChangeEvent) => {
                this.updateServiceBreaks(this.getServiceBreaks());
            });
    }

    ngOnDestroy(): void {
        this.langChange.unsubscribe();
        this.updateInterval.unsubscribe();
    }

    /**
     * Refresh the service break info that should be currently displayed.
     */
    public refresh(): void {
        this.updateServiceBreaks(this.getServiceBreaks());
    }

    /**
     * Get the host origin (url) to filter the service breaks according to their affect.
     *
     * @return The origin url used for the services.
     */
    getHostOrigin(): string {
        return window.location.origin;
    }

    /**
     * Subscribe to this to listen updates in current service breaks data in general.
     *
     * @return Observable of the current service breaks.
     */
    getObservableOfCurrentServiceBreaks(): Observable<any> {
        return this.currentBreaksSource.asObservable();
    }

    /**
     * Subscribe to this for listening updates in current critical service breaks data.
     *
     * @return Observable of the critical service breaks.
     */
    getObservableOfCriticalServiceBreaks(): Observable<any> {
        return this.criticalBreaksSource.asObservable();
    }

    getPollIntervalMinutes(): number {
        return this.pollIntervalMinutes;
    }

    private dismissAlerts(ids: string[]) {
        let filtered: string[];
        if (ids) {
            filtered = ids.filter((id) => id != null);
        }

        _.forEach(filtered, (id) => {
            this.alertsService.dismissAlertIfExists(id);
        });
    }

    /**
     * Set the service breaks data and update what is displayed. Fills in default values when needed.
     *
     * @param breaks The data for service breaks.
     */
    private updateServiceBreaks(breaks: ServiceBreak[]) {
        const lastUpdateMillis = this.lastUpdateMoment ? moment.now() - this.lastUpdateMoment : 100;
        if (lastUpdateMillis < 100) {
            // Prevent from firing updates too rapidly after previous one (happens occasionally)
            return;
        }
        const alertsToRemove = this.alertIds; // ids of alerts that are (plausibly) visible at the moment
        this.alertIds = [];
        setTimeout(() => {
            if (breaks) {
                this.serviceBreaks = breaks;
            } else {
                this.serviceBreaks = [];
            }
            const criticalBreaks = this.getCriticalServiceBreaks();
            this.currentBreaksSource.next(this.getActiveServiceBreaks());
            this.criticalBreaksSource.next(criticalBreaks);
            this.alertIds = [];
            if (!_.isEmpty(criticalBreaks)) {
                _.forEach(criticalBreaks, (criticalBreak: ServiceBreak) => {
                    const alertMessage = this.getServiceInfoText(criticalBreak);
                    const alert: Alert = {
                        type: AlertType.DANGER,
                        message: alertMessage,
                        identifier: `serviceBreak_${this.stringToHashCode(criticalBreak.serviceType + criticalBreak.serviceTime.startDateTime + criticalBreak.serviceTime.endDateTime)}`,
                    };
                    this.alertsService.addAlert(alert);
                    this.alertIds.push(alert.identifier);
                    _.remove(alertsToRemove, key => key === alert.identifier);
                });
            }
            this.dismissAlerts(alertsToRemove);
        });
        this.lastUpdateMoment = moment.now();
    }

    /**
     * Sets default values for a service break object when it has some required data missing.
     *
     * @param serviceBreak The object to set default values for.
     * @return The modified object.
     */
    private setDefaults(serviceBreak: ServiceBreak): ServiceBreak {
        if (!serviceBreak.serviceType) {
            serviceBreak.serviceType = 'MAINTENANCE';
        }
        return serviceBreak;
    }

    /**
     * @return The host to load service breaks data from.
     */
    getUnavailableDataUrl(): string {
        return this.unavailableDataUrl;
    }

    /**
     * @return The frequently updating data about service breaks. Empty array if data is not yet loaded.
     */
    getServiceBreaks(): ServiceBreak[] {
        if (this.serviceBreaks) {
            return this.serviceBreaks;
        }
        return [];
    }

    /**
     * Filters the given set of service breaks by their affected origin.
     *
     * @params breaks The list of service breaks to filter the ones having origin as one of their affected origins.
     * @param origin The origin url of the host used (e.g. 'https://sis-demo.funidata.fi'). If not defined, returns all.
     */
    filterServiceBreaksByOrigin(breaks: ServiceBreak[], origin?: string): ServiceBreak[] {
        if (breaks) {
            if (!origin) {
                return breaks;
            }
            return _.filter(breaks, ((serviceBreak: ServiceBreak) =>
                (_.isEmpty(serviceBreak.affectedOrigins) || _.includes(serviceBreak.affectedOrigins, origin))));
        }
        return [];
    }

    /**
     * Get all the service breaks which displayTime is ongoing.
     *
     * @return A list of service break objects. May be empty if none has its displayTime active.
     */
    private getActiveServiceBreaks(): ServiceBreak[] {
        return this.getActiveServiceBreaksOfDate(this.getServiceBreaks(), new Date());
    }

    /**
     * Get all the service breaks which displayTime contains the given moment.
     *
     * @param breaks The service breaks to get the critical ones from.
     * @param now The current date and time which should be contained by each breaks displayTime.
     * @return A list of service break objects. May be empty if none has its displayTime active.
     */
    getActiveServiceBreaksOfDate(breaks: ServiceBreak[], now: Date): ServiceBreak[] {
        if (!now) {
            return [];
        }

        return _.filter(breaks, ((serviceBreak: ServiceBreak) => {
            const displayStart = _.get(serviceBreak, 'displayTime.startDateTime', false);
            const displayEnd = _.get(serviceBreak, 'displayTime.endDateTime', false);
            const serviceStart = _.get(serviceBreak, 'serviceTime.startDateTime', false);
            const serviceEnd = _.get(serviceBreak, 'serviceTime.endDateTime', false);

            if (!displayStart && !displayEnd) {
                // Do not show notification at all
                return false;
            }

            if (displayStart && displayEnd) {
                return (now >= new Date(displayStart) && now < new Date(displayEnd));
            }
            if (displayStart && serviceEnd) {
                // Show notification until we can assume the break should be ended
                // case: No need to show notification after the service ends
                return (now > new Date(displayStart)) && now < new Date(serviceEnd);
            }
            if (displayStart && serviceStart) {
                // There was no known end time, so only until we know the break should begin
                // (frontend should be unavailable anyway)
                // case: The break is ongoing end the end times are plausibly updated later
                return (now > new Date(displayStart)) && now < new Date(serviceStart);
            }
            if (serviceEnd && displayEnd) {
                // There was no known start time for displaying the notification, but we known when we should not notify about it anymore
                // case: There was some sort of event and this is post notification
                return (now > new Date(serviceEnd)) && now < new Date(displayEnd);
            }
            if (serviceStart && displayEnd) {
                // There was no known start time for displaying the notification, but we known when we should not notify about it anymore
                // case: There was some sort of event and this is post notification, but the break is still ongoing and we have no known end time
                return (now > new Date(serviceStart)) && now < new Date(displayEnd);
            }
            return false;
        }));
    }

    /**
     * Gives a listing of the upcoming breaks that have their 'criticalAlert' time "active".
     *
     * @return A list of service break objects. May be empty if none has its displayTime active.
     */
    private getCriticalServiceBreaks(): ServiceBreak[] {
        return this.getCriticalServiceBreaksOfDate(this.getServiceBreaks(), moment());
    }

    /**
     * Gives a listing of the upcoming breaks that have their 'criticalAlert' time "active".
     *
     * @param breaks The service breaks to get the critical ones from.
     * @param now The current moment time.
     * return The service breaks that have their critical period ongoing.
     */
    getCriticalServiceBreaksOfDate(breaks: ServiceBreak[], now: Moment): ServiceBreak[] {
        if (!now) {
            return [];
        }

        return _.filter(breaks, ((serviceBreak: ServiceBreak) => {
            const serviceStart = moment(serviceBreak?.serviceTime?.startDateTime, moment.ISO_8601);
            if (!serviceStart.isValid() || !serviceBreak?.criticalAlert) {
                return false;
            }

            let criticalAlert = moment.duration(serviceBreak.criticalAlert);
            if (criticalAlert.asMilliseconds() < 1) {
                // If the syntax was off, default to 30 minutes
                criticalAlert = moment.duration('PT30M');
            }

            const criticalStart = serviceStart.subtract(criticalAlert);
            const serviceEnd = moment(serviceBreak?.serviceTime?.endDateTime, moment.ISO_8601);
            if (serviceEnd.isValid()) {
                return now.isSameOrAfter(criticalStart) && now.isBefore(serviceEnd);
            }
            return now.isSameOrAfter(criticalStart); // If there is no end time, then it's shown eternally
        }));
    }

    /**
     * Loads a fresh set of service breaks data from nginx.
     *
     * @return Observable of the ServiceBreaks data.
     */
    private loadServiceBreaksData(): Observable<ServiceBreak[]> {
        return this.http.get<ServiceBreak[]>(this.getUnavailableDataUrl());
    }

    /**
     * Returns `true` if the given service break has passed. If the service break has no `serviceTime` set, `false` is returned.
     *
     * @param serviceBreak The service break to check
     * @param now The current moment of time. Leaving undefined will create a new date for checking the current time as needed.
     */
    hasServiceBreakPassed(serviceBreak: ServiceBreak, now?: Date): boolean {
        const currentDate = now || new Date();
        return serviceBreak.serviceTime && currentDate > new Date(serviceBreak.serviceTime.endDateTime);
    }

    /**
     * Builds a localized info text about the service break, based on its type and time period of occurrence.
     * The 'service upgrade' and 'incident'-breaks have different text to show after the end of the service time has passed.
     * The 'degraded performance' notification contains no duration information since it is irrelevant when the issue
     * has started and it is unknown when it will be fixed. Also, when it's fixed the notification should not been shown
     * anymore.
     *
     * @param serviceBreak The service break to get the info text about.
     * @param now The current moment of time. Leaving undefined will create a new date for checking the current time as needed.
     * @return Localized info text about the service break.
     */
    getServiceInfoText(serviceBreak: ServiceBreak, now?: Date): string {
        const textKey = `UNAVAILABLE.${serviceBreak.serviceType}`;
        if (serviceBreak.serviceType === 'DEGRADED_PERFORMANCE') {
            return this.translateService.instant(textKey);
        }
        if ((serviceBreak.serviceType === 'SERVICE_UPGRADE' || serviceBreak.serviceType === 'INCIDENT')
            && this.hasServiceBreakPassed(serviceBreak, now)) {
            return this.translateService.instant(`${textKey}_AFTER`);
        }
        return this.translateService.instant(textKey) + this.getServicePeriodText(serviceBreak);
    }

    /**
     * Gets a proper time period info text to show for the service break.
     * Cases: Start and end both know, only start known, only end known, neither known.
     *
     * @param serviceBreak The service break to get the time period info.
     * @return Time period info text about the service break.
     */
    getServicePeriodText(serviceBreak: ServiceBreak): string {
        let text;
        if (serviceBreak.serviceTime?.startDateTime && serviceBreak.serviceTime?.endDateTime) {
            const start = dateUtils.extractDateAndTime(serviceBreak.serviceTime.startDateTime);
            const startDate = dateUtils.convertIsoLocalDateToDisplayFormat(start.date);
            const startTime = dateUtils.convertIsoLocalTimeToDisplayFormat(start.time);
            const end = dateUtils.extractDateAndTime(serviceBreak.serviceTime.endDateTime);
            const endDate = dateUtils.convertIsoLocalDateToDisplayFormat(end.date);
            const endTime = dateUtils.convertIsoLocalTimeToDisplayFormat(end.time);
            text = this.translateService.instant('UNAVAILABLE.PERIOD_KNOWN', { startDate, startTime, endDate, endTime });
        } else if (serviceBreak.serviceTime?.startDateTime) {
            const start = dateUtils.extractDateAndTime(serviceBreak.serviceTime.startDateTime);
            const startDate = dateUtils.convertIsoLocalDateToDisplayFormat(start.date);
            const startTime = dateUtils.convertIsoLocalTimeToDisplayFormat(start.time);
            text = this.translateService.instant('UNAVAILABLE.PERIOD_START_KNOWN', { startDate, startTime });
        } else if (serviceBreak.serviceTime?.endDateTime) {
            const end = dateUtils.extractDateAndTime(serviceBreak.serviceTime.endDateTime);
            const endDate = dateUtils.convertIsoLocalDateToDisplayFormat(end.date);
            const endTime = dateUtils.convertIsoLocalTimeToDisplayFormat(end.time);
            text = this.translateService.instant('UNAVAILABLE.PERIOD_END_KNOWN', { endDate, endTime });
        } else {
            text = this.translateService.instant('UNAVAILABLE.PERIOD_UNKNOWN');
        }
        return text;
    }

    stringToHashCode(text: string): number {
        let hash = 0;
        const textLength = text.length;
        let i = 0;
        if (textLength > 0) {
            while (i < textLength) {
                // eslint-disable-next-line no-bitwise
                hash = (hash << 5) - hash + text.charCodeAt(i) | 0;
                i += 1;
            }
        }
        return hash;
    }
}
