<ng-container *transloco="let t">
        <h2 class="fudis-heading__xl">
            {{ 'ENROLMENTS.SELECT_REALISATIONS' | transloco }}
        </h2>
        <div class="modal-header sis-mb-md">
            <div>
                <p>
                    {{ 'ENROLMENTS.SELECT_REALISATIONS_HELP' | transloco }}
                </p>
            </div>
            <sis-modal-close-button/>
        </div>
        <div class="modal-body">
            <div class="sis-mb-md">
                <div>
        <span class="control-label form-control-static tiny-static-form-group ng-scope">
          {{t('ENROLMENTS.ENROLMENT_DIALOG.COURSE_UNIT')}}: <span
                class="description-text">{{courseUnit.code}}, {{courseUnit.name | localizedString}}</span>
        </span>
                </div>
                <div>
          <span class="control-label form-control-static tiny-static-form-group ng-scope">
            {{t('ENROLMENTS.PLANNED_COMPLETION_METHOD')}}: <span class="description-text">{{t('COMPLETION_METHOD')}}
              : {{ getCompletionMethodIndex() }}</span>
          </span>
                    <sis-tooltip
                            [tooltipText]="t('ENROLMENTS.SELECT_COMPLETION_METHOD_INFO')"
                            [tooltipBlockElement]="true"
                            tooltipPlacement="bottom">
                        <sis-icon icon="info-circle" color="primary" class="sis-tooltip-trigger-content"></sis-icon>
                    </sis-tooltip>
                </div>
            </div>
            <ng-container *ngFor="let entry of assessmentItemsWithRealisations | keyvalue">
                <div class="sis-mb-md" *ngIf="entry.value.length > 0">
                    <h4>{{ entry.key.name | localizedString }} ({{ entry.key.credits | creditRange }})</h4>
                    <div class="sis-pl-sm">
                        <ng-container *ngFor="let realisation of entry.value">
                            <div class="flex-container">
                                <sis-checkbox [label]="getCourseUnitRealisationLabel(realisation)"
                                              [checked]="isCourseUnitRealisationSelected(realisation, entry.key)"
                                              (checkedChange)="courseUnitRealisationCheckboxClicked($event, realisation.id, entry.key.id)"
                                              [ariaDescribedBy]=""
                                              [disabled]="isSelectionDisabled(realisation, entry.key)">
                                </sis-checkbox>
                                <div class="badge-container">
                                    <span *ngIf="realisation.flowState === 'CANCELLED'"
                                          class="tiny-badge danger">{{t('COURSE_UNIT_REALISATION.FLOWSTATE.CANCELLED')}}</span>
                                    <span *ngIf="isAlreadyEnrolled(realisation) && !hasAttainment(realisation, entry.key)"
                                          class="tiny-badge primary">{{t('ENROLMENT.ENROLMENT_SUCCEEDED')}}</span>
                                    <span *ngIf="hasAttainment(realisation, entry.key)">
                                        <sis-icon icon="achievement" color="success"></sis-icon>
                                        <span class="grade-text">{{t('ATTAINMENT_STATE.ATTAINED')}}</span>
                                    </span>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <div>
                <p>
                    {{ 'ENROLMENTS.REMEMBER_TO_ENROL' | transloco }}
                </p>
            </div>
        </div>
        <div class="modal-footer confirm-dialog-footer">
            <sis-button (clicked)="cancel()" [hollow]="true">{{ t('BUTTON.CANCEL') }}</sis-button>
            <sis-button dataCy="course-unit-realisation-selection-button"  (clicked)="submit()">{{ t('BUTTON.CONFIRM') }}</sis-button>
        </div>
</ng-container>
