<div *ngFor="let priorLearning of priorLearnings; index as i" class="form-group-mimic application-attributes">
  <sis-expandable *ngIf="showAsExpandable; else defaultTemplate" [level]="level" [variant]="'naked-dotted'">
    <ng-template sisExpandableCustomTitle>
      <ng-container [ngTemplateOutlet]="title" [ngTemplateOutletContext]="{priorLearning, i}"></ng-container>
    </ng-template>
    <ng-template sisExpandableContent>
      <ng-container [ngTemplateOutlet]="priorLearningContent" [ngTemplateOutletContext]="{priorLearning}"></ng-container>
    </ng-template>
  </sis-expandable>
  <ng-template #defaultTemplate>
    <div role="heading" class="fudis-heading__md" [attr.aria-level]="level"><ng-container [ngTemplateOutlet]="title" [ngTemplateOutletContext]="{priorLearning, i}"></ng-container></div>
    <ng-container [ngTemplateOutlet]="priorLearningContent" [ngTemplateOutletContext]="{priorLearning}"></ng-container>
  </ng-template>
</div>

<ng-template #priorLearningContent let-priorLearning="priorLearning">
  <sis-prior-studies-summary *ngIf="priorLearning.type === PRIOR_LEARNING_TYPE.STUDIES" [priorStudies]="priorLearning"></sis-prior-studies-summary>
  <sis-prior-competence-summary *ngIf="priorLearning.type === PRIOR_LEARNING_TYPE.COMPETENCE" [priorCompetence]="priorLearning"></sis-prior-competence-summary>
</ng-template>

<ng-template #title let-priorLearning="priorLearning" let-i="i">
  {{priorLearning | priorLearningName:applicationType:(priorLearnings.length > 1 ? i : null)}}
</ng-template>
