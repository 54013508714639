import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { SisCommonModule } from 'sis-common/sis-common.module';
import { SisComponentsModule } from 'sis-components/sis-components.module';

import { SearchCooperationNetworkResultRowComponent } from './search-cooperation-network-result-row/search-cooperation-network-result-row.component';
import { SearchCooperationNetworkComponent } from './search-cooperation-network/search-cooperation-network.component';
import { SearchMainComponent } from './search-main/search-main.component';
import { SearchOpenUniversityComponent } from './search-open-university/search-open-university.component';
import { SearchResultRowComponent } from './search-result-row/search-result-row.component';
import { SearchTabsComponent } from './search-tabs/search-tabs.component';
import { searchStates } from './search.route';

@NgModule({
    declarations: [
        SearchMainComponent,
        SearchResultRowComponent,
        SearchCooperationNetworkComponent,
        SearchTabsComponent,
        SearchOpenUniversityComponent,
        SearchCooperationNetworkResultRowComponent,
    ],
    imports: [
        BrowserModule,
        UIRouterUpgradeModule.forChild({ states: searchStates }),
        SisCommonModule,
        SisComponentsModule,
        TranslateModule.forChild(),
    ],
})
export class SearchModule {
}
