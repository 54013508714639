import sisuExpandableTwoTransclusionsTpl from './sisuExpandableTwoTransclusions.tpl.html';
export const sisuExpandableTwoTransclusionsModule = 'sis-components.expandable.sisuExpandableTwoTransclusions';
(function () {
  sisuExpandableTwoTransclusionsController.$inject = ["$log"];
  angular.module(sisuExpandableTwoTransclusionsModule, []).component('sisuExpandableTwoTransclusions', {
    template: sisuExpandableTwoTransclusionsTpl,
    controller: sisuExpandableTwoTransclusionsController,
    transclude: {
      'expandableTitle': '?expandableTitle',
      'expandableBody': 'expandableBody'
    },
    bindings: {
      headingId: '<',
      initiallyCollapsed: '<',
      toggleContentCallback: '&?',
      showContentCallback: '&?',
      ariaLabelText: '<?',
      specialExpandableClass: '<?'
    }
  });

  /**
   * This component allows transcluding custom template to header in addition to transcluding custom template as
   * concealable body.
   *
   * toggleContentCallback is optional function that can be used to lazy load content data and the load may be async
   * showContentCallback is optional function that can be used to hide content data during loading for example
   */
  function sisuExpandableTwoTransclusionsController($log) {
    var ctrl = this;
    var toggleButtonEnabled = true;
    var openedOnce = false;
    ctrl.$onInit = function () {
      ctrl.collapsed = _.isBoolean(ctrl.initiallyCollapsed) ? ctrl.initiallyCollapsed : ctrl.initiallyCollapsed === 'true';
      openedOnce = !ctrl.collapsed;
      ctrl.toggleContent = _.isFunction(ctrl.toggleContentCallback) ? toggleContentCallbackDefined : toggleContentCallbackNotDefined;
      if (isDefinedButNotFunction(ctrl.toggleContentCallback)) {
        $log.warn("toggleContentCallback is defined but not a function");
      }
      ctrl.showContent = _.isFunction(ctrl.showContentCallback) ? showContentCallbackDefined : showContentCallbackNotDefined;
      if (isDefinedButNotFunction(ctrl.showContentCallback)) {
        $log.warn("showContentCallback is defined but not a function");
      }
    };
    function isDefinedButNotFunction(callBackFunction) {
      return callBackFunction && !_.isFunction(callBackFunction);
    }
    function toggleContentCallbackDefined() {
      toggleContentCallbackNotDefined();
      var toggleContentCallbackResponse = ctrl.toggleContentCallback();
      if (_.get(toggleContentCallbackResponse, 'then')) {
        //Hide toggle button during async call
        toggleButtonEnabled = false;
        return toggleContentCallbackResponse.then(function () {
          toggleButtonEnabled = true;
        });
      }
    }
    function toggleContentCallbackNotDefined() {
      ctrl.collapsed = !ctrl.collapsed;
      openedOnce = true;
    }
    function showContentCallbackDefined() {
      return ctrl.showContentCallback && ctrl.showContentCallback() && !ctrl.collapsed;
    }
    function showContentCallbackNotDefined() {
      return !ctrl.collapsed;
    }
    ctrl.toggleButtonEnabled = function () {
      return toggleButtonEnabled;
    };
    ctrl.openedOnce = function () {
      return openedOnce;
    };
  }
})();