<div *ngIf="totalItems > 0">
  <table [attr.aria-label]="'ARIA_LABEL.STUDENT_WORKFLOWS' | translate" class="table styled-table">
    <thead>
    <tr>
      <th class="untouchable" [attr.aria-hidden]="column === 'initiatorType'" *ngFor="let column of columns">
        <ng-container *ngIf="column !== 'initiatorType'">
          <sis-sortable-table-column-header *ngIf="isSortColumn(column); else defaultColumn"
                                            [name]="('STUDENT_APPLICATIONS.' + column)"
                                            [column]="column"
                                            [(reverse)]="reverse"
                                            [orderKey]="sortColumn"
                                            (orderKeyChange)="setOrder(column)"
                                            ariaLabelType="ALPHABETICAL_ORDER">
          </sis-sortable-table-column-header>
          <ng-template #defaultColumn>{{('STUDENT_APPLICATIONS.' + column) | translate}}</ng-template>
        </ng-container>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let workflow of studentWorkflowSearchResult.searchResults; trackBy:workflowById"
        data-cy="workflow-search-result">
      <td *ngFor="let column of columns"
          [ngSwitch]="column"
          (click)="this.openWorkflow.emit(workflow)"
          class="clickable"
          [ngClass]="{'sorted-cell': sortColumn === column}"
          [attr.aria-hidden]="column === 'initiatorType'">
        <span *ngSwitchCase="'name'">
          <a href="javascript:void(0);" (click)="this.openWorkflow.emit(workflow); $event.stopPropagation();" class="clickable-rows-link">
            <span class="student-workflow-type">{{('STUDENT_APPLICATIONS.TYPE.' + workflow.type) | translate}}:</span>
            <span>{{ $any(workflow).name || (workflow | workflowRelatedStudy | async | entityName | localizedString) || '-' }}</span>
          </a>
          <span *ngIf="workflow.initiatorType === 'STAFF'" class="visually-hidden">
            <ng-container [ngTemplateOutlet]="initiatorText"
                          [ngTemplateOutletContext]="{personId:workflow?.createdByPersonId}"></ng-container>
          </span>
        </span>
        <span *ngSwitchCase="'initiatorType'">
          <ng-container *ngIf="workflow.initiatorType === 'STAFF'">
            <sis-icon color="primary" icon="info-circle"></sis-icon>
            <span class="student-initiator">
              <ng-container [ngTemplateOutlet]="initiatorText"
                            [ngTemplateOutletContext]="{personId:workflow?.createdByPersonId}"></ng-container>
            </span>
          </ng-container>
        </span>
        <span *ngSwitchCase="'studyRight'">
          <ng-container *ngIf="$any(workflow)?.studyRightId | studyRightName | async as educationName">
            {{educationName | localizedString}}
          </ng-container>
        </span>
        <span *ngSwitchCase="'studentName'">{{workflow.personLastName}}, {{workflow.personFirstNames}}</span>
        <span *ngSwitchCase="'code'">
          <span>{{workflow.code}}</span>
        </span>
        <span *ngSwitchCase="'state'" class="workflow-status workflow-status__{{this.isOnStudentPage ? 'student' : 'staff'}}">
          <sis-icon icon="{{getStatusIcon(workflow.state)}}"
                    iconClass="{{getStatusIconClass(workflow.state)}}"></sis-icon>
          <span class="field-value">{{('STUDENT_APPLICATIONS.STATE.' + workflow.state) | translate}}</span>
        </span>
        <span *ngSwitchCase="'lastHandledTime'">{{workflow.lastHandledTime | localDateFormat}}</span>
        <span *ngSwitchCase="'lastHandlerPerson'" class="student-workflow-handler">
          <span *ngIf="workflow.lastHandlerPersonId | publicPerson | async as publicPerson">
            <span class="notranslate">{{ publicPerson.lastName }}, {{publicPerson.firstName }}</span>
               <sis-button link="true"
                           *ngIf="(workflow.state === 'REQUESTED' || workflow.state === 'IN_HANDLING' || workflow.state === 'SUPPLEMENT_REQUESTED') && !isOnStudentPage"
                           (clicked)="this.editHandler.emit(workflow.id)"
                           [attr.aria-label]="'STUDENT_APPLICATIONS.EDIT_HANDLER' | translate">
                 <sis-tooltip [tooltipBlockElement]="true"
                              [tooltipText]="'STUDENT_APPLICATIONS.EDIT_HANDLER' | translate"
                              [attr.aria-label]="'STUDENT_APPLICATIONS.EDIT_HANDLER' | translate">
                   <sis-icon [icon]="'edit'" [color]="'primary'" class="sis-tooltip-trigger-content"></sis-icon>
                 </sis-tooltip>
               </sis-button>
          </span>
          <span
            *ngIf="!workflow.lastHandlerPersonId && (workflow.state === 'REQUESTED' || workflow.state === 'IN_HANDLING' || workflow.state === 'SUPPLEMENT_REQUESTED') && !isOnStudentPage">
            <sis-button link="true"
                        (clicked)="this.editHandler.emit(workflow.id)">{{'STUDENT_APPLICATIONS.SET_HANDLER' | translate}}
            </sis-button>
          </span>
        </span>
        <span *ngSwitchCase="'creationTime'">{{workflow.creationTime | localDateFormat}}</span>
        <span *ngSwitchDefault>{{workflow[column]}}</span>
      </td>
    </tr>
    </tbody>
  </table>

  <div class="row scroll-target">
    <div class="col-12"
         *ngIf="totalItems > itemsPerPage"
         data-cy="pagination-controls">
      <sis-pagination
        sisScrollIntoViewOnPageChange="div.scroll-target"
        [totalItems]="totalItems"
        [currentPage]="currentPage"
        [itemsPerPage]="itemsPerPage"
        [maxSize]="maxSize"
        (currentPageChange)="onPageChange($event)">
      </sis-pagination>
    </div>
  </div>
</div>

<ng-template #initiatorText let-personId="personId">
  {{ personId && !this.isOnStudentPage
  ? ('STUDENT_APPLICATIONS.INITIATOR_PUBLIC' | translate:{personFullName: (personId | publicPerson | async | personFullName)})
  : ('STUDENT_APPLICATIONS.INITIATOR_ANON' | translate) }}
</ng-template>
