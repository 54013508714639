import angular from 'angular';
import _ from 'lodash';
import { customCodeValuesModule } from 'sis-components/codes/customCodeValues.component';
import { IconComponent } from 'sis-components/icon/icon.component.ts';
import { LabelWithInfoPopoverComponent } from 'sis-components/label-with-info-popover/label-with-info-popover.component.ts';
import { OrganisationRoleShareComponent } from 'sis-components/organisation/organisation-role-share/organisation-role-share.component.ts';
import { organisationRoleModelModule } from 'sis-components/model/organisationRole.model';
import { localDateTimeRangeFilterModule } from 'sis-components/date/filters/localDateTimeRange/localDateTimeRange.filter';
import { localizedUrnNameDisplayModule } from 'sis-components/codes/localizedUrnNameDisplay.component';
import { ExternalLinkComponent } from 'sis-components/external-link/external-link.component.ts';
import { TabContentSwitchComponent } from 'sis-components/tabs/tab-content-switch/tab-content-switch.component.ts';
import { jsDataRelationHelperModule } from 'sis-components/service/jsDataRelationHelper.service';
import { CourseUnitInfoModalTextFieldComponent } from 'sis-components/courseUnitInfoModal/course-unit-info-modal-text-field/course-unit-info-modal-text-field.component.ts';
import { CustomCodeUrnsComponent } from 'sis-components/custom-code-urns/custom-code-urns/custom-code-urns.component.ts';
import { CourseUnitRealisationSeatsComponent } from 'sis-components/courseUnitInfoModal/course-unit-realisation-seats/course-unit-realisation-seats.component.ts';
import { LiteratureComponent } from 'sis-components/literature/literature.component.ts';
import { studentStudyEventServiceModule } from '../../../service/studyEvent.service';
import { learningEnvironmentUrlModule } from '../../learningEnvironmentUrl/learningEnvironmentUrl.component';
import { StudentCourseUnitRealisationStructureComponent } from './student-course-unit-realisation-structure/student-course-unit-realisation-structure.component';
import courseUnitRealisationInfoTpl from './courseUnitRealisationInfo.tpl.html';
(function () {
  CourseUnitRealisationInfoController.$inject = ["$scope", "$q", "defaultPromiseHandler", "courseUnitService", "studyEventService", "assessmentItemService", "enrolmentCalculationService", "personRuleService", "jsDataRelationHelperService"];
  angular.module('student.common.components.courseUnitInfoModal.courseUnitRealisationInfo', ['sis-common.l10n', 'sis-common.errorhandler.errorService', 'sis-components.rangeFilter', 'sis-components.service.enrolmentCalculation', 'sis-components.service.personRule', 'student.common.service.courseUnitService', 'student.common.service.calendarService', 'student.common.service.assessmentItemService', 'student.common.components', IconComponent.downgrade.moduleName, learningEnvironmentUrlModule, customCodeValuesModule, studentStudyEventServiceModule, organisationRoleModelModule, OrganisationRoleShareComponent.downgrade.moduleName, LabelWithInfoPopoverComponent.downgrade.moduleName, localDateTimeRangeFilterModule, localizedUrnNameDisplayModule, CourseUnitRealisationSeatsComponent.downgrade.moduleName, ExternalLinkComponent.downgrade.moduleName, TabContentSwitchComponent.downgrade.moduleName, jsDataRelationHelperModule, StudentCourseUnitRealisationStructureComponent.downgrade.moduleName, CourseUnitInfoModalTextFieldComponent.downgrade.moduleName, CustomCodeUrnsComponent.downgrade.moduleName, LiteratureComponent.downgrade.moduleName]).component('courseUnitRealisationInfo', {
    bindings: {
      courseUnitRealisation: '<',
      courseUnit: '<',
      language: '<',
      level: '<',
      openTab: '<'
    },
    controller: CourseUnitRealisationInfoController,
    template: courseUnitRealisationInfoTpl
  });

  /**
   * There is so much data to load that parent component should not create many instances of this component at the same time without
   * doing data loading itself.
   */
  function CourseUnitRealisationInfoController(
  // NOSONAR
  $scope, $q, defaultPromiseHandler, courseUnitService, studyEventService, assessmentItemService, enrolmentCalculationService, personRuleService, jsDataRelationHelperService) {
    const $ctrl = this;
    $ctrl.scope = $scope;
    $ctrl.arrayHasValue = arrayHasValue;
    $ctrl.hasCustomCodeUrns = hasCustomCodeUrns;
    $ctrl.alternativeCourseUnits = [];
    $ctrl.assessmentItemsByCourseUnitId = {};
    $ctrl.requirementPersonRulesText = [];
    $ctrl.orderingPersonRulesText = [];
    $ctrl.customCodeUrns = [];
    $ctrl.$onInit = () => {
      const promises = [jsDataRelationHelperService.loadOrganisationRelations($ctrl.courseUnitRealisation.organisations), jsDataRelationHelperService.loadCourseUnitRealisationResponsibilityInfoRelations($ctrl.courseUnitRealisation.responsibilityInfos)];
      promises.push(getAlternativeCourseUnitsAndAssessmentItems());
      promises.push(getEnrolmentConfig($ctrl.courseUnitRealisation));
      updateResponsibilityInfos();
      $q.all(promises).catch(defaultPromiseHandler.loggingRejectedPromiseHandler).finally(() => {
        $ctrl.loaded = true;
      });
      if (!$ctrl.openTab) {
        $ctrl.openTab = 0;
      }
      $ctrl.tabs = [{
        title: 'SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.TAB_HEADINGS.BASIC_INFO',
        id: `course-unit-info-modal-cur-basic-info-tab-${$ctrl.courseUnitRealisation.id}`
      }, {
        title: 'SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.TAB_HEADINGS.ENROLMENT_INFO',
        id: `course-unit-info-modal-cur-enrolment-info-tab-${$ctrl.courseUnitRealisation.id}`
      }, {
        title: 'SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.TAB_HEADINGS.STUDY_GROUPS',
        id: `course-unit-info-modal-cur-study-groups-tab-${$ctrl.courseUnitRealisation.id}`
      }];
    };
    function hasCustomCodeUrns() {
      return !!_.get($ctrl.courseUnitRealisation, 'customCodeUrns');
    }

    // Does the array have a truthy element with a truthy value in any property
    function arrayHasValue(arr) {
      if (_.isArray(arr)) {
        return _.some(arr, arrItem => _.some(_.values(arrItem)));
      }
      return false;
    }
    function getAlternativeCourseUnitsAndAssessmentItems() {
      const assessmentItemIds = _.get($ctrl.courseUnitRealisation, 'assessmentItemIds');
      return courseUnitService.findByAssessmentIds(assessmentItemIds).then(courseUnits => {
        $ctrl.alternativeCourseUnits = _.reject(courseUnits, {
          id: $ctrl.courseUnit.id
        });
        return assessmentItemService.findByIds(assessmentItemIds).then(assessmentItems => {
          _.each(courseUnits, courseUnit => {
            $ctrl.assessmentItemsByCourseUnitId[courseUnit.id] = getCourseUnitAssessmentItems(courseUnit, assessmentItems);
          });
        });
      });
    }
    function getCourseUnitAssessmentItems(courseUnit, assessmentItems) {
      const assessmentItemIds = _.flattenDeep(_.map(courseUnit.completionMethods, 'assessmentItemIds'));
      return _.filter(assessmentItems, assessmentItem => _.indexOf(assessmentItemIds, assessmentItem.id) > -1);
    }
    function getEnrolmentConfig(courseUnitRealisation) {
      enrolmentCalculationService.getEnrolmentCalculationConfigs([courseUnitRealisation.id]).then(result => {
        $ctrl.enrolmentCalculationConfig = _.get(result, 'data[0]');
        const requirementPersonRules = _.get(result, 'data[0].requirementPersonRules');
        const orderingPersonRules = _.get(result, 'data[0].orderingPersonRules');
        const requirementPersonRulesText = _.map(requirementPersonRules, rule => personRuleService.fetchRuleInfoText(rule));
        const orderingPersonRulesText = _.map(orderingPersonRules, rule => personRuleService.fetchRuleInfoText(rule));
        return $q.all(requirementPersonRulesText).then(requirementRulesText => $q.all(orderingPersonRulesText).then(orderingRulesText => {
          $ctrl.requirementPersonRulesText = requirementRulesText;
          $ctrl.orderingPersonRulesText = orderingRulesText;
        }));
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    }
    function updateResponsibilityInfos() {
      // sort responsibility infos by contact-info > responsible-teacher > teacher first and then within each role responsibility infos with personId reference first
      $ctrl.sortedByNameResponsibilityInfos = _.chain($ctrl.courseUnitRealisation.responsibilityInfos).filter(info => _.get(info, 'roleUrn') !== 'urn:code:course-unit-realisation-responsibility-info-type:administrative-person').orderBy(info => {
        if (info.roleUrn === 'urn:code:course-unit-realisation-responsibility-info-type:contact-info') {
          return 1;
        }
        if (info.roleUrn === 'urn:code:course-unit-realisation-responsibility-info-type:responsible-teacher') {
          return 2;
        }
        if (info.roleUrn === 'urn:code:course-unit-realisation-responsibility-info-type:teacher') {
          return 3;
        }
        if (!info.personId) {
          return 4;
        }
        const fullName = _.get(info, 'person.fullName');
        return fullName ? _.upperCase(fullName) : 5;
      }).value();
    }
    $ctrl.customCodeUrnsHasChild = function () {
      return _.flatMap($ctrl.courseUnitRealisation.customCodeUrns).length !== 0;
    };
    $ctrl.hasCoordinatingOrganisations = () => _.some($ctrl.courseUnitRealisation.organisations, {
      roleUrn: 'urn:code:organisation-role:coordinating-organisation'
    });
  }
})();