import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Workflow } from 'common-typescript/types';
import { STUDENT_WORKFLOW_TYPE } from 'sis-components/model/student-workflow-constants';

@Injectable({
    providedIn: 'root',
})
export class StudentApplicationRoutingService {

    constructor(
        private stateService: StateService,
        @Inject(DOCUMENT) private document: Document,
    ) { }

    goToApplication(workflow: Workflow) {
        switch (workflow.type) {
            case STUDENT_WORKFLOW_TYPE.PRIOR_LEARNING_SUBSTITUTION_WORKFLOW:
            case STUDENT_WORKFLOW_TYPE.PRIOR_LEARNING_INCLUSION_WORKFLOW:
                this.document.defaultView?.scrollTo(0, 0);
                this.stateService.go(
                    'student.logged-in.profile.applications.prior-learning-application',
                    { applicationId: workflow.id },
                    { reload: true },
                );
                break;
            case STUDENT_WORKFLOW_TYPE.MODULE_ATTAINMENT_WORKFLOW:
                this.document.defaultView?.scrollTo(0, 0);
                this.stateService.go(
                    'student.logged-in.profile.applications.module-attainment-application',
                    { applicationId: workflow.id },
                    { reload: true },
                );
                break;
            case STUDENT_WORKFLOW_TYPE.DEGREE_PROGRAMME_ATTAINMENT_WORKFLOW:
                this.document.defaultView?.scrollTo(0, 0);
                this.stateService.go(
                    'student.logged-in.profile.applications.degree-programme-attainment-application',
                    { applicationId: workflow.id },
                    { reload: true },
                );
                break;
            case STUDENT_WORKFLOW_TYPE.CUSTOM_ATTAINMENT_WORKFLOW:
                this.document.defaultView?.scrollTo(0, 0);
                this.stateService.go(
                    'student.logged-in.profile.applications.custom-attainment-application',
                    { applicationId: workflow.id },
                    { reload: true },
                );
                break;
            case STUDENT_WORKFLOW_TYPE.CUSTOM_MODULE_CONTENT_WORKFLOW:
            case STUDENT_WORKFLOW_TYPE.REQUIRED_MODULE_CONTENT_WORKFLOW:
                this.document.defaultView?.scrollTo(0, 0);
                this.stateService.go(
                    'student.logged-in.profile.applications.module-content-application',
                    { applicationId: workflow.id },
                    { reload: true },
                );
                break;
            case STUDENT_WORKFLOW_TYPE.STUDY_RIGHT_EXTENSION_WORKFLOW:
                this.document.defaultView?.scrollTo(0, 0);
                this.stateService.go(
                    'student.logged-in.profile.applications.study-right-extension-application',
                    { applicationId: workflow.id },
                    { reload: true },
                );
                break;
            default:
                break;
        }
    }
}
