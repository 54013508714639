import { Injectable } from '@angular/core';
import { dateUtils } from 'common-typescript';
import {
    LocalDateRange,
    LocalDateString,
    OngoingRegistrationType,
    OtmId,
    StudyRight,
    StudyRightExpirationRule,
    StudyRightExtension,
    StudyRightSemesterData,
    StudyTermLocator,
    TermRegistration,
} from 'common-typescript/types';
import _ from 'lodash';
import moment from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { getStudyTermEndDate, getStudyTermLocator, getStudyTermStartDate } from '../study-terms/study-year-utils';

import { StudyRightEntityService } from './study-right-entity.service';
import { StudyRightExpirationRuleDetailsService } from './study-right-expiration-rule-details.service';
import { StudyRightTermRegistrationsEntityService } from './study-right-term-registrations-entity.service';

@Injectable({ providedIn: 'root' })
export class StudyRightSemesterCalculationService {

    constructor(
        private studyRightExpirationRuleDetailsService: StudyRightExpirationRuleDetailsService,
        private studyRightEntityService: StudyRightEntityService,
        private studyRightTermRegistrationsEntityService: StudyRightTermRegistrationsEntityService,
    ) {}

    getStudyRightSemesterDataByStudyRightId(studyRightId: OtmId): Observable<StudyRightSemesterData> {
        return combineLatest([
            this.studyRightEntityService.getById(studyRightId),
            this.studyRightTermRegistrationsEntityService.getById(studyRightId),
            this.studyRightExpirationRuleDetailsService.getRules(),
        ])
            .pipe(
                map(([studyRight, srtr, rules]) =>
                    this.getStudyRightSemesterData(studyRight, srtr.termRegistrations, rules)),
            );
    }

    getStudyRightSemesterDataByStudyRight(studyRight: StudyRight): Observable<StudyRightSemesterData> {
        return this.studyRightExpirationRuleDetailsService.getRules()
            .pipe(
                map(rules =>
                    this.getStudyRightSemesterData(studyRight, studyRight.termRegistrations, rules),
                ),
            );
    }

    getStudyRightSemesterData(studyRight: StudyRight, termRegistrations: TermRegistration[], expirationRules: StudyRightExpirationRule[]): StudyRightSemesterData {
        const expirationRule: StudyRightExpirationRule = _.find(expirationRules, { studyRightExpirationRulesUrn: studyRight.studyRightExpirationRulesUrn });
        const extensions = (studyRight.studyRightExtensions || []);

        const nonMissingTermRegistrationCount = termRegistrations
            .filter(reg => reg.termRegistrationType !== 'MISSING' && reg.termRegistrationType !== 'NEGLECTED').length;
        const currentRegistration = termRegistrations.find(reg => this.isCurrent(reg));
        const pastAndCurrentTermRegistrations = termRegistrations.filter(reg => this.isPastOrCurrent(reg));
        const activeExtensions = extensions
            .filter(extension => extension.state === 'ACTIVE')
            .sort((a, b) => (a.extensionStartDate || '').localeCompare(b.extensionStartDate || ''));
        const firstActiveExtension = activeExtensions.length > 0 ? activeExtensions[0] : undefined;

        let usedSemesters = 0;
        let usedAbsentSemesters = 0;
        let usedStatutoryAbsentSemesters = 0;
        let usedStatutoryAbsentSemestersBeforeExtensions = 0;
        let usedExtensionSemesters = 0;
        let termsWithoutRegistration = 0;
        const totalAvailableSemesters =
            expirationRule.phase1Terms + expirationRule.phase2Terms + expirationRule.graceTerms;
        const totalAvailableAbsentSemesters = expirationRule.allowedAbsenceTerms;
        const totalAvailableExtensionSemesters = activeExtensions.reduce((sum, extension) => sum + extension.extensionCount, 0);

        if (studyRight.studyRightTransfer) {
            usedSemesters = studyRight.studyRightTransfer.usedTerms;
            usedAbsentSemesters = studyRight.studyRightTransfer.usedAbsenceTerms;
            usedStatutoryAbsentSemesters = studyRight.studyRightTransfer.usedStatutoryAbsenceTerms;
            usedStatutoryAbsentSemestersBeforeExtensions = studyRight.studyRightTransfer.usedStatutoryAbsenceTerms;
        }

        const activeExtensionTermLocators: StudyTermLocator[] = this.extensionsToTermLocators(activeExtensions);
        const studyYearExtensionInfoMap: Map<number, Array<boolean>> = new Map();
        activeExtensionTermLocators.forEach(locator => {
            const y = locator.studyYearStartYear;
            const i = locator.termIndex;
            const mapValue = studyYearExtensionInfoMap.get(y);
            const currExtensionInfo = mapValue ? mapValue : [false, false];
            currExtensionInfo[i] = true;
            studyYearExtensionInfoMap.set(y, currExtensionInfo);
        });

        pastAndCurrentTermRegistrations.forEach((registration) => {
            const studyTermStart: moment.Moment = getStudyTermStartDate(registration.studyTerm);
            const mapValue = studyYearExtensionInfoMap.get(registration.studyTerm.studyYearStartYear);
            const matchesExtensionTerm = mapValue && mapValue[registration.studyTerm.termIndex];
            if (matchesExtensionTerm) {
                usedExtensionSemesters += 1;
            } else if (registration.statutoryAbsence) {
                if (!firstActiveExtension || studyTermStart.isBefore(firstActiveExtension.extensionStartDate)) {
                    usedStatutoryAbsentSemestersBeforeExtensions += 1;
                }
                usedStatutoryAbsentSemesters += 1;
            } else {
                // Missing/neglected term registrations count against the total amount of available study terms
                if (registration.termRegistrationType === 'ATTENDING' ||
                    registration.termRegistrationType === 'MISSING' ||
                    registration.termRegistrationType === 'NEGLECTED' ||
                    usedAbsentSemesters >= totalAvailableAbsentSemesters) {
                    usedSemesters += 1;
                }
                if (registration.termRegistrationType === 'NONATTENDING') {
                    usedAbsentSemesters += 1;
                }
                if (registration.termRegistrationType === 'MISSING' ||
                    registration.termRegistrationType === 'NEGLECTED') {
                    termsWithoutRegistration += 1;
                }
            }
        });

        return {
            firstActiveExtension,
            totalAvailableSemesters,
            totalAbsent: totalAvailableAbsentSemesters,
            totalExtension: totalAvailableExtensionSemesters,
            activeExtensions: !firstActiveExtension,
            neverExpires: expirationRule.neverExpires,
            setEndDateManually: expirationRule.setEndDateManually,
            unused: Math.max(totalAvailableSemesters - usedSemesters, 0),
            used: Math.min(usedSemesters, totalAvailableSemesters),
            absentUnused: Math.max(totalAvailableAbsentSemesters - usedAbsentSemesters, 0),
            absentUsed: Math.min(usedAbsentSemesters, totalAvailableAbsentSemesters),
            extensionUnused: Math.max(totalAvailableExtensionSemesters - usedExtensionSemesters, 0),
            extensionUsed: Math.min(usedExtensionSemesters, totalAvailableExtensionSemesters),
            statutoryAbsentUsed: usedStatutoryAbsentSemesters,
            termsWithoutRegistration,
            originalEndOfStudyRight: getOriginalEndOfStudyRight(
                studyRight.valid.startDate,
                totalAvailableSemesters,
                usedAbsentSemesters,
                usedStatutoryAbsentSemestersBeforeExtensions,
            ),
            ongoing: getOngoingType(
                currentRegistration,
                nonMissingTermRegistrationCount > 0,
                usedExtensionSemesters > 0,
                usedAbsentSemesters > totalAvailableAbsentSemesters,
                studyRight.valid,
            ),
        };
    }

    private isCurrent(registration: TermRegistration): boolean {
        return moment().isBetween(
            getStudyTermStartDate(registration.studyTerm),
            getStudyTermEndDate(registration.studyTerm),
            'day',
            '[)',
        );
    }

    private isPastOrCurrent(registration: TermRegistration): boolean {
        return moment().isSameOrAfter(getStudyTermStartDate(registration.studyTerm), 'day');
    }

    private extensionsToTermLocators(extensions: StudyRightExtension[]) {
        const extensionTermLocators: StudyTermLocator[] = [];
        extensions.forEach((e) => {
            const startLocator: StudyTermLocator = getStudyTermLocator(e.extensionStartDate);
            extensionTermLocators.push(startLocator);
            let prevLocator = startLocator;
            for (let i = 1; i < e.extensionCount; i += 1) {
                const nextLocator = prevLocator.termIndex === 0 ?
                    { studyYearStartYear: prevLocator.studyYearStartYear, termIndex: 1 } :
                    { studyYearStartYear: prevLocator.studyYearStartYear + 1, termIndex: 0 };
                extensionTermLocators.push(nextLocator);
                prevLocator = nextLocator;
            }
        });
        return extensionTermLocators;
    }
}

function getOriginalEndOfStudyRight(studyRightStartDate: LocalDateString, totalAvailableSemesters: number, usedAbsentSemesters: number,
                                    usedStatutoryAbsentSemestersBeforeExtensions: number): moment.Moment | null {
    const semestersAvailable = totalAvailableSemesters + usedAbsentSemesters + usedStatutoryAbsentSemestersBeforeExtensions;
    if (semestersAvailable === 0) {
        return null;
    }

    const date = moment(studyRightStartDate);
    const evenSemesters = semestersAvailable % 2 === 0;
    if (evenSemesters) {
        return date.add(semestersAvailable / 2, 'years').subtract(1, 'days');
    }

    date.add((semestersAvailable - 1) / 2, 'years');
    date.month() === 0 ? date.month(7) : date.add(1, 'years').month(0);
    return date.subtract(1, 'days');
}

function getOngoingType(currentRegistration: TermRegistration,
                        hasTermRegistrations: boolean,
                        firstActiveExtensionInThePast: boolean,
                        tooManyAbsentSemestersSelected: boolean,
                        studyRightValidityPeriod: LocalDateRange): OngoingRegistrationType {
    const currentDate = moment();

    if (!dateUtils.dateRangeContains(currentDate, studyRightValidityPeriod.startDate, studyRightValidityPeriod.endDate)
        && currentDate.isSameOrAfter(studyRightValidityPeriod.startDate)) {
        return null;
    }
    if (!hasTermRegistrations) {
        return 'FIRSTREGISTRATION';
    }
    if (firstActiveExtensionInThePast) {
        return 'EXTENSION';
    }
    if (!currentRegistration) {
        return 'MISSING';
    }
    if (currentRegistration.statutoryAbsence) {
        return 'STATUTORYABSENCE';
    }
    if (tooManyAbsentSemestersSelected && currentRegistration.termRegistrationType === 'NONATTENDING') {
        return 'ATTENDING';
    }

    return currentRegistration.termRegistrationType;
}
