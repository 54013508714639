import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { PriorLearningWorkflow } from 'common-typescript/types';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { STUDENT_WORKFLOW_TYPE } from '../../../model/student-workflow-constants';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-prior-learning-workflow-summary',
    templateUrl: './prior-learning-workflow-summary.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriorLearningWorkflowSummaryComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.applications.prior-learning-workflow.prior-learning-workflow-summary',
        directiveName: 'sisPriorLearningWorkflowSummary',
    };

    readonly studentApplicationType = STUDENT_WORKFLOW_TYPE;

    @Input() studentLink?: string;
    @Input() studyPlanLink?: string;

    @Input() workflow: PriorLearningWorkflow;
    @Output() openCourseUnitInfoModal = new EventEmitter();
}
