<div class="notification-box">
  <div class="notification-info" [class.no-content]="!notificationContent.textContent && !notificationContent.children.length">
    <sis-icon *ngIf="this.type !== 'none'" [icon]="getIcon()" [color]="getIconColor()"></sis-icon>
    <div class="text-wrapper" #notificationContent>
      <ng-content></ng-content>
      <sis-button *ngIf="infoClickAction.observed" link="true" (clicked)="infoClickAction.emit()">{{'SIS_COMPONENTS.STUDY.SHOW_INFO' | translate}}</sis-button>
    </div>
  </div>
  <div class="notification-buttons" *ngIf="menuOptions || primaryButtonText || secondaryButtonText">
    <div class="button-wrapper">
        <sis-menu-button *ngIf="menuOptions" [options]="menuOptions"></sis-menu-button>
      <div class="float-right-preserve-tab-order">
        <sis-button *ngIf="secondaryButtonText" size="sm" hollow="true" (clicked)="secondaryClickAction.emit()">{{secondaryButtonText | translate}}</sis-button>
        <sis-button *ngIf="primaryButtonText" size="sm" (clicked)="primaryClickAction.emit()">{{primaryButtonText | translate}}</sis-button>
      </div>
    </div>
  </div>
</div>
