<ng-container *transloco="let t">
  <app-widget-container [title]="t('FRONTPAGE.ACTIVE_STUDIES.TITLE')" [subtitle]="t('FRONTPAGE.ACTIVE_STUDIES.SUBTITLE')">
    <ng-container *ngIf="activeStudies$ | async as activeStudies; else loading">
      <sis-study-realisation-box
        *ngFor="let study of activeStudies; trackBy: courseUnitRealisationId; last as last"
        [level]="3"
        [id]="study.courseUnitRealisation.id"
        [realisationName]="study.courseUnitRealisation.name"
        [activityPeriod]="study.courseUnitRealisation.activityPeriod"
        [code]="study.courseUnit?.code"
        [name]="study.courseUnit?.name"
        [credits]="study.assessmentItem?.credits"
        [disableBoxMaxWidth]="true"
        (nameClickAction)="openCourseUnitInfoModal(study.courseUnit?.id, study.courseUnitRealisation)"
        [class.sis-mb-sm]="!last"
      >
        <sis-study-notification-box *ngIf="study.courseUnitRealisation.learningEnvironments?.length"
                                    type="none"
                                    study-box="notifications">
          <app-learning-environments
            [learningEnvironments]="study.courseUnitRealisation.learningEnvironments"
            class="d-block sis-pl-xs"
          />
        </sis-study-notification-box>
      </sis-study-realisation-box>
      <p *ngIf="!activeStudies?.length && !searchFailed()" class="app-widget-container__content__info-text sis-mb-sm">
        {{t('FRONTPAGE.ACTIVE_STUDIES.NO_ITEMS')}}
      </p>
    </ng-container>

    <p *ngIf="searchFailed()" class="app-widget-container__content__info-text sis-mb-sm">
      {{t('FRONTPAGE.ACTIVE_STUDIES.FETCHING_STUDIES_FAILED')}}
    </p>
  </app-widget-container>
</ng-container>

<ng-template #loading>
  <div aria-busy="true" class="d-flex justify-content-center">
    <sis-small-spinner />
  </div>
</ng-template>
