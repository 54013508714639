import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgxFudisModule } from '@funidata/ngx-fudis';
import { NgbActiveModal, NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgIdleModule } from '@ng-idle/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpBatchLink } from 'apollo-angular/http';
import { ClipboardModule } from 'ngx-clipboard';
import { LoggerModule, NGXLogger } from 'ngx-logger';
import {
    authServiceProvider,
    defaultPromiseHandlerProvider,
    logServiceProvider,
    tokenStorageProvider,
    translationLoaderProvider,
} from 'sis-common/ajs-upgraded-modules';
import { HybridInjectorService } from 'sis-common/angular-hybrid/hybrid-injector.service';
import { ServiceBootstrapComponent } from 'sis-common/angular-hybrid/service-bootstrap.component';
import { appAuthInitializer } from 'sis-common/auth/app-auth-initializer';
import { EnvironmentService } from 'sis-common/environmentService/environment.service';
import { httpInterceptorProviders } from 'sis-common/http-interceptors/index';
import { IdleService } from 'sis-common/idle/idle-ng.service';
import { InjectorService } from 'sis-common/injector/injector.service';
import { initialLanguageProvider } from 'sis-common/l10n/initial-language';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { HIGHEST_LOGGING_LEVEL, setUpLogging } from 'sis-common/logging/logging';
import { SisCommonModule } from 'sis-common/sis-common.module';
import { TranslocoCommonModule } from 'sis-common/transloco/transloco-common.module';
import {
    calendarEventServiceProvider,
    commonEducationServiceProvider,
    commonEnrolmentServiceProvider,
    commonStudyRightServiceProvider,
    commonTermRegistrationPeriodServiceProvider,
    courseUnitRealisationNameServiceProvider,
    courseUnitServiceProvider,
    curriculumPeriodServiceProvider,
    editModeServiceProvider, legacyTranslateServiceProvider,
    planSelectorServiceProvider,
    studyEventServiceProvider,
    validAttainmentFilterServiceProvider,
} from 'sis-components/ajs-upgraded-modules';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { apolloFactory } from 'sis-components/graphql/front-end-graphql-initializer';
import { ConnectionStatusService } from 'sis-components/service/connection-status.service';
import { JsDataSyncService } from 'sis-components/service/js-data-sync.service';
import {
    SessionTimeoutWarningService,
} from 'sis-components/session-timeout-warning/session-timeout-warning.service';
import { SisComponentsModule } from 'sis-components/sis-components.module';

import { environment } from '../environments/environment';

import {
    attainmentServiceProvider,
    choosePlanServiceProvider,
    courseUnitInfoModalProvider,
    enrolmentModalServiceProvider,
    organisationJSDataModelProvider,
    planSeekerProvider,
    previewModeProvider,
    studentProfileServiceProvider,
    studyPeriodServiceProvider,
    studyProgressGraphDropDownContentFetcherProvider, uiContextProvider,
} from './ajs-upgraded-modules';
import { StudentAuthRouteModule } from './auth/student-auth-route.module';
import { StudentAuthRouteService } from './auth/student-auth-route.service';
import { CalendarModule } from './calendar/calendar.module';
import { StudentCommonModule } from './common/common.module';
import { CourseUnitSelectionInfoComponent } from './common/components/course-unit-selection-info/course-unit-selection-info.component';
import { StudentTranslateLoader } from './common/translate/student-translate-loader';
import { TranslocoStudentLoader } from './common/transloco/transloco-student-loader';
import { CourseCartComponent } from './course-cart/course-cart.component';
import { EnrolmentsModule } from './enrolments/enrolments.module';
import {
    SelectCourseUnitRealisationComponent,
} from './enrolments/teaching-not-selected/select-course-unit-realisation/select-course-unit-realisation.component';
import { FrontpageModule } from './frontpage/frontpage.module';
import { MessageConversationsModule } from './message-conversations/message-conversations.module';
import { OpenUniversityModule } from './open-university/open-university.module';
import { PlanNgModule } from './plan-ng/plan-ng.module';
import { ModuleContentWorkflowModalComponent } from './plan/module-content-workflow-modal/module-content-workflow-modal.component';
import { OutdatedCourseUnitsModalComponent } from './plan/outdated-course-units-modal/outdated-course-units-modal.component';
import { PlanModule } from './plan/plan.module-ng';
import { SelectCompletionMethodComponent } from './plan/select-completion-method/select-completion-method.component';
import { BasicInfoPersonalInfoDetailsComponent } from './profile/basic-info/basic-info-personal-info-details/basic-info-personal-info-details.component';
import { BasicInfoPersonalInfoHistoryComponent } from './profile/basic-info/basic-info-personal-info-history/basic-info-personal-info-history.component';
import { BasicInfoPersonalInfoComponent } from './profile/basic-info/basic-info-personal-info/basic-info-personal-info.component';
import { BasicInfoComponent } from './profile/basic-info/basic-info.component';
import { ContactInformationComponent } from './profile/basic-info/contact-information/contact-information.component';
import { EditContactInformationComponent } from './profile/basic-info/edit-contact-information/edit-contact-information.component';
import { ProfileModule } from './profile/profile.module-ng';
import { SearchModule } from './search/search.module';
import { TimingModule } from './timing/timing.module';

@NgModule({
    declarations: [
        ServiceBootstrapComponent,
        BasicInfoPersonalInfoComponent,
        BasicInfoPersonalInfoDetailsComponent,
        BasicInfoPersonalInfoHistoryComponent,
        BasicInfoComponent,
        CourseCartComponent,
        EditContactInformationComponent,
        ContactInformationComponent,
        ModuleContentWorkflowModalComponent,
        OutdatedCourseUnitsModalComponent,
        CourseUnitSelectionInfoComponent,
        SelectCompletionMethodComponent,
        SelectCourseUnitRealisationComponent,
    ],
    imports: [
        PlanNgModule,
        environment.production ? [] : AkitaNgDevtools.forRoot(),
        BrowserModule,
        CalendarModule,
        ClipboardModule,
        EnrolmentsModule,
        FrontpageModule,
        HttpClientModule,
        LoggerModule.forRoot({
            level: HIGHEST_LOGGING_LEVEL,
        }),
        MessageConversationsModule,
        PlanModule,
        ProfileModule,
        SisComponentsModule,
        StudentCommonModule,
        TimingModule,
        OpenUniversityModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: StudentTranslateLoader,
            },
        }),
        UIRouterUpgradeModule.forRoot(),
        SisCommonModule,
        NgIdleModule.forRoot(),
        ApolloModule,
        SearchModule,
        TranslocoCommonModule.forRoot({
            loader: TranslocoStudentLoader,
        }),
        ReactiveFormsModule,
        FormsModule,
        FullCalendarModule,
        NgxFudisModule,
        StudentAuthRouteModule,
    ],
    providers: [
        NgbActiveModal,
        uiContextProvider,
        organisationJSDataModelProvider,
        authServiceProvider,
        choosePlanServiceProvider,
        commonEducationServiceProvider,
        commonEnrolmentServiceProvider,
        commonStudyRightServiceProvider,
        commonTermRegistrationPeriodServiceProvider,
        courseUnitServiceProvider,
        courseUnitInfoModalProvider,
        curriculumPeriodServiceProvider,
        defaultPromiseHandlerProvider,
        httpInterceptorProviders,
        editModeServiceProvider,
        planSeekerProvider,
        planSelectorServiceProvider,
        studyProgressGraphDropDownContentFetcherProvider,
        translationLoaderProvider,
        tokenStorageProvider,
        studentProfileServiceProvider,
        enrolmentModalServiceProvider,
        studyPeriodServiceProvider,
        curriculumPeriodServiceProvider,
        studyEventServiceProvider,
        previewModeProvider,
        attainmentServiceProvider,
        logServiceProvider,
        validAttainmentFilterServiceProvider,
        { provide: LOCALE_ID, useValue: 'fi' },
        { provide: ErrorHandler, useExisting: AppErrorHandler },
        {
            provide: NG_ENTITY_SERVICE_CONFIG,
            useValue: {},
        },
        initialLanguageProvider,
        calendarEventServiceProvider,
        appAuthInitializer,
        courseUnitRealisationNameServiceProvider,
        {
            provide: APOLLO_OPTIONS,
            useFactory: apolloFactory,
            deps: [HttpBatchLink],
        },
        JsDataSyncService,
        EnvironmentService.forRoot(environment),
        { provide: InjectorService, useClass: HybridInjectorService },
        legacyTranslateServiceProvider,
    ],
})
export class AppModule {
    constructor(
        private logger: NGXLogger,
        private ngbDropdownConfig: NgbDropdownConfig,
        private localeService: LocaleService,
        private idleService: IdleService,
        private sessionTimeoutWarningService: SessionTimeoutWarningService,
        private connectionStatusService: ConnectionStatusService,
        private jsDataSyncService: JsDataSyncService,
        private studentLoginRouteService: StudentAuthRouteService,
    ) {
        setUpLogging(this.logger);
        this.ngbDropdownConfig.autoClose = 'outside';
    }

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method,@angular-eslint/use-lifecycle-interface
    ngDoBootstrap() {}
}

