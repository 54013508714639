import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HashMap } from '@ngneat/transloco';
import { LocalizedMarkupString } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';

export interface ReadMoreValues {
    options: {
        contentHtml: LocalizedMarkupString;
        contentTranslateKey: string
        contentTranslateParameters: HashMap
        title: string;
    };
}
/**
 * @deprecated This component is not advised to be used in the future.
 */
@Component({
    selector: 'sis-read-more-ng-modal',
    templateUrl: './read-more-ng-modal.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ReadMoreNgModalComponent implements OnInit {
    contentHtml: LocalizedMarkupString;
    contentTranslateKey: string;
    contentTranslateParameters: HashMap;
    title: string;

    constructor(@Inject(ModalService.injectionToken) public values: ReadMoreValues,
                public activeModal: NgbActiveModal) {}

    ngOnInit(): void {
        const { options } = this.values;
        this.title = options.title;
        this.contentHtml = options.contentHtml;
        this.contentTranslateKey = options.contentTranslateKey;
        this.contentTranslateParameters = options.contentTranslateParameters;

        if (this.contentHtml && this.contentTranslateKey) {
            throw new Error('contentHtml and contentTranslateKey cannot be use at the same time.');
        }
        if (this.contentHtml && this.contentTranslateParameters) {
            throw new Error('contentHtml and contentTranslateParameters cannot be use at the same time.');
        }
        if (!this.contentTranslateKey && this.contentTranslateParameters) {
            throw new Error('contentTranslateParameters cannot be used without contentTranslateKey.');
        }
    }
}
