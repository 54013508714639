import _ from 'lodash';
import angular from 'angular';
import angularTranslate from 'angular-translate';
import { authModule } from 'sis-common/auth/auth.module';
import { errorHandlerModule } from 'sis-components/error-handler/legacy/errorhandler.module';
import { localeServiceModule } from 'sis-common/l10n/localeService';
import { TooltipComponent } from 'sis-components/tooltip/tooltip.component.ts';
import { IconComponent } from '../icon/icon.component.ts';
import { FeedbackButtonComponent } from '../feedback-modal/feedback-button.component.ts';
import { OpenUniversityCartButtonComponent } from './open-university-cart-button/open-university-cart-button.component.ts';
import { NavUtilsService } from './nav-utils/nav-utils.service.ts';
import navMenuIconsTpl from './navMenuIcons.tpl.html';
import { OPEN_UNIVERSITY_FEATURES_ENABLED_FOR_STUDENT_DEFAULT } from 'common-typescript/constants';

// jshint unused:vars
(function () {
  navMenuIconsCtrl.$inject = ["$scope", "$location", "$state", "$injector", "$rootScope", "universityService", "defaultPromiseHandler", "localeService"];
  angular.module('sis-components.navMenuIcons', [angularTranslate, authModule, errorHandlerModule, localeServiceModule, TooltipComponent.downgrade.moduleName, FeedbackButtonComponent.downgrade.moduleName, NavUtilsService.downgrade.moduleName, IconComponent.downgrade.moduleName, OpenUniversityCartButtonComponent.downgrade.moduleName]).controller('navMenuIconsCtrl', navMenuIconsCtrl).directive('navMenuIcons', navMenuIcons);

  /**
   * @ngInject
   */
  function navMenuIcons() {
    return {
      restrict: 'E',
      scope: {
        currentApp: '@'
      },
      controller: 'navMenuIconsCtrl',
      controllerAs: 'ctrl',
      bindToController: true,
      template: navMenuIconsTpl,
      require: {
        TopNavController: '^^topNav'
      }
    };
  }

  /**
   * @ngInject
   */
  function navMenuIconsCtrl($scope, $location, $state, $injector, $rootScope, universityService,
  // NOSONAR
  defaultPromiseHandler, localeService) {
    const ctrl = this;
    ctrl.$onInit = () => {
      universityService.getCurrentUniversitySettings().then(settings => {
        // show cart icon if open university features are enabled for student in current university settings or fall back to default
        ctrl.openUniversityFeaturesEnabledForStudent = _.get(settings, 'frontendFeatureToggles.openUniversityFeaturesEnabledForStudent', OPEN_UNIVERSITY_FEATURES_ENABLED_FOR_STUDENT_DEFAULT);
      }).catch(error => {
        ctrl.openUniversityFeaturesEnabledForStudent = false;
        defaultPromiseHandler.loggingRejectedPromiseHandler(error);
      });
      if ($rootScope.NgAppInitialized) {
        ctrl.shouldShowFeedbackModalButton = () => $injector.get('ngAuthService').loggedIn();
      } else {
        $rootScope.$on('NgAppInitialized', () => {
          ctrl.shouldShowFeedbackModalButton = () => $injector.get('ngAuthService').loggedIn();
        });
      }
    };
    const updateHelpUrl = function () {
      universityService.getCurrentUniversitySettings().then(settings => {
        const baseUrl = _.get(settings.helpUrls, ctrl.currentApp) || _.get(settings.helpUrls, 'default');
        const lang = localeService.getCurrentLocale();
        const view = $location.path() && _.split($location.path().substring(1), '/')[0];
        ctrl.helpUrl = `${baseUrl}?role=${ctrl.currentApp}&view=${view}&lang=${lang}`;
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
    const updateOnPathChange = function () {
      updateHelpUrl();
    };
    ctrl.openMessages = function () {
      $injector.get('navUtilsService').moveToMessagesUrl();
    };
    ctrl.getMessagesHref = function () {
      return $injector.get('navUtilsService').getMessagesHref();
    };
    $scope.$watch(() => $location.path(), updateOnPathChange);
    $scope.$on('$localeChangeSuccess', updateOnPathChange);
    ctrl.messagesIconActive = () => $state.includes('*.logged-in.message-conversations.**') || $state.includes('*.logged-in.messages.**');
    ctrl.courseCartIconActive = () => $state.includes('student.logged-in.course-cart');
    ctrl.openUniCartIconActive = () => $state.includes('student.open-university-cart');
  }
})();