import angular from 'angular';

import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { PageTitleComponent } from './page-title/page-title.component';
// @ts-ignore ('cannot find module' for HTML template)
import pageHeaderTplHtml from './pageHeader.tpl.html';

export const pageHeaderModule = 'sis-components.header.pageHeader';

class PageHeaderComponent {

    constructor(private $rootScope: any) {}

    get collapsed(): boolean {
        return this.$rootScope?.scrolledDown ?? false;
    }

    get isInsideContainer(): boolean {
        return this.$rootScope?.isInsideContainer ?? false;
    }
}
PageHeaderComponent.$inject = ['$rootScope'];

angular.module(pageHeaderModule, [
    BreadcrumbsComponent.downgrade.moduleName,
    PageTitleComponent.downgrade.moduleName,
]).component('pageHeader', {
    template: pageHeaderTplHtml,
    controller: PageHeaderComponent,
    controllerAs: 'ctrl',
});
