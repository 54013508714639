<article class="substitution-box" [class.multiple-substitutions]="substituteCourseUnits.length > 1">
  <ng-container *ngFor="let courseUnit of substituteCourseUnits">
    <sis-study-box [level]="level" [id]="courseUnit.id" [name]="courseUnit.name" [code]="courseUnit.code" [credits]="courseUnit.credits"
                   [attainedCredits]="courseUnit.attainedCredits" [gradeId]="courseUnit.gradeId" [gradeScaleId]="courseUnit.gradeScaleId"
                   (nameClickAction)="nameClickAction.emit(courseUnit.id)" [studyState]="courseUnit.studyState !== 'ACTIVE' ? courseUnit.studyState : null">
    <ng-container study-box="notifications">
        <ng-container *ngTemplateOutlet="substituteCourseUnits.length === 1 ? substitutionInfo: null; context: {courseUnits: [courseUnit]}"></ng-container>
        <sis-study-notification-box *ngIf="substituteCourseUnits.length > 1 && courseUnit.isPartialSubstitute" type="substitution">
          <ng-container *ngTemplateOutlet="creditInfo; context: {courseUnits: [courseUnit]}"></ng-container>
        </sis-study-notification-box>
      </ng-container>
    </sis-study-box>
  </ng-container>
  <ng-container *ngTemplateOutlet="substituteCourseUnits.length > 1 ? substitutionInfo: null; context: {courseUnits: substituteCourseUnits, plural: true}"></ng-container>
</article>

<ng-template #substitutionInfo let-courseUnits="courseUnits" let-plural="plural">
  <sis-study-notification-box type="substitution">
    {{'SIS_COMPONENTS.STUDY.SELECTED_SUBSTITUTION' + (plural ? 'S' : '') | translate}}
    <ng-container *ngTemplateOutlet="isPartialSubstitute(courseUnits) ? creditInfo : null; context: {courseUnits: substituteCourseUnits, plural: plural, isSecondary: true}"></ng-container>
  </sis-study-notification-box>
</ng-template>

<ng-template #creditInfo let-courseUnits="courseUnits" let-plural="plural" let-isSecondary="isSecondary">
  <br *ngIf="isSecondary">
  {{'SIS_COMPONENTS.STUDY.CREDITS_USED_FOR_SUBSTITUTION' + (plural ? 'S' : '') | translate}} {{getSubstituteCredits(courseUnits) | creditRange}}
</ng-template>
