import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SisCommonModule } from 'sis-common/sis-common.module';
import { SisComponentsHybridModule } from 'sis-components/sis-components.module.hybrid';
import { StudyRightStatusBadgeModule } from 'sis-components/studyRight/study-right-status-badge/study-right-status-badge.module';

import { AssessmentItemAttainmentDetailsComponent } from './attainment-details/assesment-item-attainment-details/assessment-item-attainment-details.component';
import { AttainmentDetailsModalComponent } from './attainment-details/attainment-details-modal/attainment-details-modal.component';
import { CourseUnitAttainmentDetailsComponent } from './attainment-details/course-unit-attainment-details/course-unit-attainment-details.component';
import { CustomCourseUnitAttainmentDetailsComponent } from './attainment-details/custom-course-unit-attainment-details/custom-course-unit-attainment-details.component';
import { CustomModuleAttainmentDetailsComponent } from './attainment-details/custom-module-attainment-details/custom-module-attainment-details.component';
import { DegreeProgrammeAttainmentDetailsComponent } from './attainment-details/degree-programme-attainment-details/degree-programme-attainment-details.component';
import { ModuleAttainmentDetailsComponent } from './attainment-details/module-attainment-details/module-attainment-details.component';
import { PrintTranscriptModalComponent } from './print-transcript-modal/print-transcript-modal.component';

@NgModule({
    declarations: [
        PrintTranscriptModalComponent,
        AssessmentItemAttainmentDetailsComponent,
        AttainmentDetailsModalComponent,
        CourseUnitAttainmentDetailsComponent,
        CustomCourseUnitAttainmentDetailsComponent,
        CustomModuleAttainmentDetailsComponent,
        DegreeProgrammeAttainmentDetailsComponent,
        ModuleAttainmentDetailsComponent,
    ],
    imports: [
        CommonModule,
        SisComponentsHybridModule,
        SisCommonModule,
        StudyRightStatusBadgeModule,
        TranslateModule.forChild(),
    ],
})
export class AttainmentModule { }
