import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgxFudisModule } from '@funidata/ngx-fudis';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { SisCommonModule } from 'sis-common/sis-common.module';
import { EnrolmentModule as CommonEnrolmentModule } from 'sis-components/enrolment/enrolment.module';
import { SisComponentsModule } from 'sis-components/sis-components.module';

import { enrolmentServiceProvider } from '../ajs-upgraded-modules';
import { StudentCommonModule } from '../common/common.module';
import { EnrolmentModule } from '../common/components/enrolment/enrolment.module';

import { CourseUnitEnrolmentComponent } from './course-unit-enrolment/course-unit-enrolment.component';
import { EnrolmentAbortModalComponent } from './enrolment-abort-modal/enrolment-abort-modal.component';
import { EnrolmentBasicInfoComponent } from './enrolment-basic-info/enrolment-basic-info.component';
import { EnrolmentBoxComponent } from './enrolment-box/enrolment-box.component';
import { SortRequirementRulesPipe } from './enrolment-box/sort-requirement-rules.pipe';
import {
    EnrolmentCancellationModalComponent,
} from './enrolment-cancellation-modal/enrolment-cancellation-modal.component';
import { EnrolmentDialogComponent } from './enrolment-dialog/enrolment-dialog.component';
import { EnrolmentHeaderInfoComponent } from './enrolment-dialog/enrolment-header-info/enrolment-header-info.component';
import {
    EnrolmentQuestionnaireAnswersComponent,
} from './enrolment-dialog/enrolment-questionnaire-answers/enrolment-questionnaire-answers.component';
import {
    EnrolmentRequirementRulesComponent,
} from './enrolment-dialog/enrolment-requirement-rules-list/enrolment-requirement-rules.component';
import {
    EnrolmentStateCourseUnitsComponent,
} from './enrolment-state-course-units/enrolment-state-course-units.component';
import { EnrolmentStudyGroupsInfoComponent } from './enrolment-study-groups-info/enrolment-study-groups-info.component';
import { EnrolmentWizardComponent } from './enrolment-wizard/enrolment-wizard.component';
import { StudyGroupContainerComponent } from './enrolment-wizard/study-group-container/study-group-container.component';
import {
    StudySubGroupContainerComponent,
} from './enrolment-wizard/study-group-container/study-sub-group-container/study-sub-group-container.component';
import {
    StudySubGroupInfoComponent,
} from './enrolment-wizard/study-group-container/study-sub-group-container/study-sub-group-info/study-sub-group-info.component';
import { StudyRightSelectComponent } from './enrolment-wizard/study-right-select/study-right-select.component';
import { EnrolmentsMainContainerComponent } from './enrolments-main-container/enrolments-main-container.component';
import { enrolmentStates } from './enrolments.route';
import { OpenUniversityEnrolmentModule } from './open-university-enrolment/open-university-enrolment.module';
import { RequirementRulesComponent } from './requirement-rules/requirement-rules.component';
import { StudySubGroupViewComponent } from './study-sub-group-view/study-sub-group-view.component';
import {
    DegreeStudiesUntimedComponent,
} from './teaching-not-selected/degree-studies-untimed/degree-studies-untimed.component';
import { OpenStudiesUntimedComponent } from './teaching-not-selected/open-studies-untimed/open-studies-untimed.component';
import { TeachingNotSelectedComponent } from './teaching-not-selected/teaching-not-selected.component';

@NgModule({
    declarations: [
        EnrolmentsMainContainerComponent,
        TeachingNotSelectedComponent,
        CourseUnitEnrolmentComponent,
        EnrolmentStateCourseUnitsComponent,
        DegreeStudiesUntimedComponent,
        OpenStudiesUntimedComponent,
        EnrolmentAbortModalComponent,
        EnrolmentBoxComponent,
        EnrolmentCancellationModalComponent,
        EnrolmentWizardComponent,
        StudyGroupContainerComponent,
        StudySubGroupContainerComponent,
        StudyRightSelectComponent,
        StudySubGroupInfoComponent,
        SortRequirementRulesPipe,
        EnrolmentDialogComponent,
        RequirementRulesComponent,
        EnrolmentBasicInfoComponent,
        EnrolmentHeaderInfoComponent,
        EnrolmentRequirementRulesComponent,
        EnrolmentStudyGroupsInfoComponent,
        StudySubGroupViewComponent,
        EnrolmentQuestionnaireAnswersComponent,
    ],
    imports: [
        UIRouterUpgradeModule.forChild({ states: enrolmentStates }),
        TranslateModule.forChild(),
        SisComponentsModule,
        BrowserModule,
        SisCommonModule,
        CommonEnrolmentModule,
        FormsModule,
        ReactiveFormsModule,
        SisComponentsModule,
        OpenUniversityEnrolmentModule,
        SisComponentsModule,
        StudentCommonModule,
        EnrolmentModule,
        NgxFudisModule,
    ],
    exports: [
        CourseUnitEnrolmentComponent,
    ],
    providers: [
        enrolmentServiceProvider,
    ],
})
export class EnrolmentsModule {
}
