import priorLearningApplicationSummaryTpl from './priorLearningApplicationSummary.tpl.html';
export const priorLearningApplicationSummaryModule = 'sis-components.applications.priorLearningApplicationSummary';
'use strict';
(function () {
  /**
   * Use Angular component PriorLearningWorkflowSummary instead in future development.
   *
   * @deprecated
   */
  angular.module('sis-components.applications.priorLearningApplicationSummary', ['sis-components.applications.textWithDefaultMissingValue', 'sis-components.model.language', 'sis-components.model.preferredLanguage', 'sis-components.service.courseUnitService', 'sis-components.service.curriculumPeriodService', 'sis-components.service.moduleService', 'sis-components.service.studyRightService', 'sis-components.model.studentApplication']).component('priorLearningApplicationSummary', {
    template: priorLearningApplicationSummaryTpl,
    bindings: {
      application: '<',
      openCourseUnitInfoModalCallback: '&?',
      showLinksForStaff: '<'
    },
    controller: ["$scope", "$q", "commonCourseUnitService", "commonCurriculumPeriodService", "commonModuleService", "commonStudyRightService", "preferredLanguageJSDataModel", "STUDENT_APPLICATION_TYPE", function ($scope, $q, commonCourseUnitService, commonCurriculumPeriodService,
    // NOSONAR
    commonModuleService, commonStudyRightService, preferredLanguageJSDataModel, STUDENT_APPLICATION_TYPE) {
      $scope.STUDENT_APPLICATION_TYPE = STUDENT_APPLICATION_TYPE;
      const ctrl = this;
      ctrl.$onInit = function () {
        preferredLanguageJSDataModel.lazyGetAll().then(function (languages) {
          ctrl.preferredLanguages = languages;
        });
        if (ctrl.showLinksForStaff) {
          ctrl.studentLink = getStudentLink();
          ctrl.studyPlanLink = getStudyPlanLink();
        }
        return $q.all([resolveEducation(ctrl.application), resolveCourseUnitVersionName(ctrl.application), resolvePlannedParentModule(ctrl.application)]);
      };
      ctrl.getLanguageName = function (languageUrn) {
        return _.get(_.find(ctrl.preferredLanguages, {
          urn: languageUrn
        }), 'name');
      };
      ctrl.openCourseUnitInfoModal = function () {
        if (ctrl.openCourseUnitInfoModalCallback) {
          ctrl.openCourseUnitInfoModalCallback();
        }
      };
      function resolveEducation(application) {
        return commonStudyRightService.findById(application.studyRightId, false, true).then(function (studyRight) {
          ctrl.education = studyRight.education;
        });
      }
      function resolveCourseUnitVersionName(application) {
        if (!application.courseUnit) {
          return $q.when();
        }
        return commonCurriculumPeriodService.findCurriculumPeriodsByIds(application.courseUnit.curriculumPeriodIds).then(function (curriculumPeriods) {
          return commonCourseUnitService.getDisplayName(curriculumPeriods);
        }).then(function (displayName) {
          ctrl.courseUnitVersionName = displayName;
        });
      }
      function resolvePlannedParentModule(application) {
        if (!application.plannedParentModuleId) {
          return $q.when();
        }
        commonModuleService.findById(application.plannedParentModuleId).then(function (module) {
          ctrl.plannedParentModule = module;
        });
      }
      function getStudentLink() {
        if (!ctrl.application.student) {
          return null;
        }
        const studentId = ctrl.application.student.id;
        return `/tutor/role/staff/student/${studentId}/basic/basic-info`;
      }
      function getStudyPlanLink() {
        if (!ctrl.application.student || !ctrl.application.planId) {
          return null;
        }
        const studentId = ctrl.application.student.id;
        const studyPlanId = ctrl.application.planId;
        return `/tutor/role/staff/student/${studentId}/tutoring/plan/${studyPlanId}/structure`;
      }
    }]
  });
})();