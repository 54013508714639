<ng-container *transloco="let t" >
  <div class="modal-header">
    <h2 class="modal-title" tabindex="0">{{ t('PLAN.OUTDATED_COURSE_UNITS_MODAL.TITLE') }}</h2>
    <sis-modal-close-button/>
  </div>
  <div class="modal-body">
    <p>
      {{ t('PLAN.OUTDATED_COURSE_UNITS_MODAL.INFO_TEXT') }}
    </p>
    <div class="table-scroll" *ngIf="values.courseUnits?.length > 0">
      <table aria-labelledby="outDatedCourseUnits"
             class="table styled-table">
        <caption>{{ t('PLAN.OUTDATED_COURSE_UNITS_MODAL.TABLE_CAPTION') }}</caption>
        <thead>
        <tr>
          <th class="col-5">{{ t('PLAN.OUTDATED_COURSE_UNITS_MODAL.COURSE_UNIT_COL') }}</th>
          <th class="col-3">{{ t('PLAN.OUTDATED_COURSE_UNITS_MODAL.CURRENT_VERSION_IN_PLAN_COL') }}</th>
          <th class="col-3">{{ t('PLAN.OUTDATED_COURSE_UNITS_MODAL.VERSION_TO_BE_UPDATED_COL') }}</th>
          <th class="col-1">{{ t('PLAN.OUTDATED_COURSE_UNITS_MODAL.ACTION_COL') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let courseUnitPairOldNew of values.courseUnits">
          <td class="col-5">
            <span>
              {{ courseUnitPairOldNew[0].name | localizedString}} ({{ courseUnitPairOldNew[0].credits | creditRange }})
            </span>
            <br aria-hidden="true">
            <span>
              {{ courseUnitPairOldNew[0].code }}
            </span>
          </td>
          <td class="col-3">
            {{courseUnitPairOldNew[0].curriculumPeriodIds | curriculumPeriodName: true | async}}
          </td>
          <td class="col-3">
            {{courseUnitPairOldNew[1].curriculumPeriodIds | curriculumPeriodName: true | async}}
          </td>
          <td class="col-1">
            <sis-button size="sm"
                        hollow="true"
                        [label]="t('PLAN.OUTDATED_COURSE_UNITS_MODAL.ACTION_BUTTON_TEXT')"
                        [attr.aria-label]="t('PLAN.OUTDATED_COURSE_UNITS_MODAL.ACTION_BUTTON_TEXT')"
                        (clicked)="openCourseUnitInfoModal(courseUnitPairOldNew[0])">
            </sis-button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="values.courseUnits?.length === 0">
      <span class="guidance">{{ t('PLAN.OUTDATED_COURSE_UNITS_MODAL.ALL_COURSE_UNITS_ARE_UP_TO_DATE') }}</span>
    </div>
  </div>
  <div class="modal-footer">
    <sis-button (clicked)="modal.dismiss()">{{ t('COMMON.CLOSE') }}</sis-button>
  </div>
</ng-container>

