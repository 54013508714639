import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AUTH_SERVICE } from 'sis-common/ajs-upgraded-modules';
import { AlertsService, AlertType } from 'sis-components/alerts/alerts-ng.service';
import { PainoRequestService } from 'sis-components/service/paino-request.service';

@Component({
    selector: 'app-create-study-certificate-modal',
    templateUrl: './create-study-certificate-modal.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CreateStudyCertificateModalComponent implements OnInit {

    // Hard-code the available file languages, since that's how all the other file generation modals in STUDENT work (PO decision)
    readonly availableLocales: string[] = ['fi', 'sv', 'en'];

    selectedLocales: { [locale: string]: boolean } = {};
    showValidationErrors = false;

    constructor(private alertsService: AlertsService,
                private modal: NgbActiveModal,
                private translate: TranslateService,
                @Inject(AUTH_SERVICE) private authService: any,
                private painoRequestService: PainoRequestService) {}

    ngOnInit(): void {
        this.availableLocales.forEach(locale => this.selectedLocales[locale] = false);
    }

    createCertificate(): void {
        if (this.isAnyLanguageSelected()) {
            this.painoRequestService.sendStudyCertificateRequest(
                {
                    type: 'STUDY_CERTIFICATE',
                    withSignature: true,
                    subjectUserId: this.authService.personId(),
                    useDateOfBirth: true,
                },
                { ...this.selectedLocales },
            );
            this.modal.close();
        } else {
            this.showValidationErrors = true;
            this.alertsService.addAlert({
                type: AlertType.DANGER,
                message: this.translate.instant('PROFILE.CREATE_STUDY_CERTIFICATE_MODAL.VALIDATION_ERROR_ALERT'),
            });
        }
    }

    cancel(): void {
        this.modal.dismiss();
    }

    getLabelState(): string {
        if (this.isAnyLanguageSelected()) {
            return 'valid';
        }
        return this.showValidationErrors ? 'invalid' : 'required';
    }

    isAnyLanguageSelected(): boolean {
        return Object.values(this.selectedLocales).some(isSelected => isSelected);
    }
}
