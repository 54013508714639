import tagsTpl from './tags.tpl.html';
(function () {
  'use strict';

  angular.module('student.common.components').directive('tags', tags).controller('TagsController', TagsController);
  function tags() {
    return {
      element: 'E',
      controller: 'TagsController as ctrl',
      bindToController: true,
      scope: {
        onRemove: '&',
        tags: '=list'
      },
      template: tagsTpl
    };
  }
  function TagsController() {
    var ctrl = this;
    ctrl.remove = remove;
    function remove(index) {
      ctrl.onRemove({
        tag: _.first(ctrl.tags.splice(index, 1))
      });
    }
  }
})();