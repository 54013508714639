import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StateService } from '@uirouter/angular';
import { Plan, StudyRight } from 'common-typescript/types';
import { combineLatestWith, Observable, Subscription, tap } from 'rxjs';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { PlanEntityService } from 'sis-components/service/plan-entity.service';
import { StudyRightEntityService } from 'sis-components/service/study-right-entity.service';

import { PlanSeekerService } from '../../common/service/plan-seeker.service';
import { createPlanModalOpener } from '../create-plan-modal/create-plan-modal.component';
import { STUDENT_PLAN_STRUCTURE } from '../plan-ng.route.constant';

@Component({
    selector: 'app-create-first-plan',
    templateUrl: './create-first-plan.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateFirstPlanComponent implements OnInit {

    planEntityService = inject(PlanEntityService);
    studyRightEntityService = inject(StudyRightEntityService);
    planSeekerService = inject(PlanSeekerService);
    stateService = inject(StateService);
    appErrorHandler = inject(AppErrorHandler);
    destroyRef = inject(DestroyRef);

    createPlanModalOpener = createPlanModalOpener();

    // Redirects student to the created plan, or a plan that already exists
    redirectionSubscription$: Subscription;

    ngOnInit() {
        this.redirectionSubscription$ = this.createRedirectionObservable().subscribe();
    }

    createRedirectionObservable(): Observable<[Plan[], StudyRight[]]> {
        return this.planEntityService.getMyPlans().pipe(
            combineLatestWith(this.studyRightEntityService.getStudyRightsForCurrentUser()),
            tap(([studentPlans, studentStudyRights]) => {
                const planId = this.planSeekerService.resolvePlan(studentPlans, studentStudyRights);
                if (planId) {
                    this.stateService.go(STUDENT_PLAN_STRUCTURE, { planId });
                }
            }),
            this.appErrorHandler.defaultErrorHandler(),
            takeUntilDestroyed(this.destroyRef),
        );
    }
}
