import * as _ from 'lodash';

import { ValidatablePlan } from './validatablePlan';

export class PlanValidationResult {

    validatablePlan: ValidatablePlan | null = null;
    rootValidationResult: any = null;
    moduleValidationResults: { [id: string]: any } = {};
    courseUnitValidationResults: { [id: string]: any } = {};
    customModuleAttainmentValidationResults: { [id: string]: any } = {};
    customCourseUnitAttainmentValidationResults: { [id: string]: any } = {};
    ruleValidationResults: { [id: string]: { [id: string]: any } } = {};
    moduleIndexes: { [id: string]: number | null } = {};
    courseUnitIndexes: { [id: string]: number | null } = {};
    customModuleAttainmentIndexes: { [id: string]: number | null } = {};
    customCourseUnitAttainmentIndexes: { [id: string]: number | null } = {};
    matchingModuleContentApprovalsByModuleId: { [id: string]: any } = {};

}
