<sis-description-list>
  <dl>
    <ng-container *ngIf="formalRecommendation">
      <ng-template #listItem>
        <dt class="sis-label">{{('RECOMMENDATION.' + approvalRequestType + '.FORMAL_STATE') | translate}}</dt>
        <dd [ngSwitch]="formalRecommendation.state">
          <ng-container
            *ngSwitchCase="recommendationState.NOT_HANDLED">{{('RECOMMENDATION.' + approvalRequestType + '.NOT_HANDLED') | translate}}</ng-container>
          <ng-container
            *ngSwitchCase="recommendationState.RECOMMENDED">{{('RECOMMENDATION.' + approvalRequestType + '.RECOMMENDED') | translate}}</ng-container>
          <ng-container
            *ngSwitchCase="recommendationState.NOT_RECOMMENDED">{{('RECOMMENDATION.' + approvalRequestType + '.NOT_RECOMMENDED') | translate}}</ng-container>
        </dd>
      </ng-template>
      <ng-template #listItem *ngIf="formalRecommendation.responsiblePerson || formalRecommendation.additionalInformation">
        <dt class="sis-label">{{('RECOMMENDATION.' + approvalRequestType + '.FORMAL_PERSON') | translate}}</dt>
        <dd>{{formalRecommendation.responsiblePerson | publicPerson | async | personFullName}},
            {{formalRecommendation.responsiblePersonTitle | localizedString}}
          <ng-container *ngIf="formalRecommendation.additionalInformation">{{formalRecommendation.additionalInformation}}</ng-container>
        </dd>
      </ng-template>
      <ng-template #listItem *ngIf="formalRecommendation.comment">
        <dt class="sis-label">{{('RECOMMENDATION.' + approvalRequestType + '.FORMAL_COMMENT') | translate}}</dt>
        <dd class="text-area-static">{{contentRecommendation.comment}}</dd>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="contentRecommendation">
      <ng-template #listItem>
        <dt class="sis-label">{{('RECOMMENDATION.' + approvalRequestType + '.CONTENT_STATE') | translate}}</dt>
        <dd [ngSwitch]="contentRecommendation.state">
          <ng-container
            *ngSwitchCase="recommendationState.NOT_HANDLED">{{('RECOMMENDATION.' + approvalRequestType + '.NOT_HANDLED') | translate}}</ng-container>
          <ng-container
            *ngSwitchCase="recommendationState.RECOMMENDED">{{('RECOMMENDATION.' + approvalRequestType + '.RECOMMENDED') | translate}}</ng-container>
          <ng-container
            *ngSwitchCase="recommendationState.NOT_RECOMMENDED">{{('RECOMMENDATION.' + approvalRequestType + '.NOT_RECOMMENDED') | translate}}</ng-container>
        </dd>
      </ng-template>
      <ng-container>
        <ng-template #listItem *ngIf="contentRecommendation.responsiblePerson || contentRecommendation.additionalInformation">
          <dt class="sis-label">{{'RECOMMENDATION.' + approvalRequestType + '.CONTENT_PERSON' | translate}}</dt>
          <dd>
            {{contentRecommendation.responsiblePerson | publicPerson | async | personFullName}},
            {{contentRecommendation.responsiblePersonTitle | localizedString}}
            <ng-container *ngIf="contentRecommendation.additionalInformation">{{contentRecommendation.additionalInformation}}</ng-container>
          </dd>
        </ng-template>
      </ng-container>
      <ng-container>
        <ng-template #listItem *ngIf="contentRecommendation.comment">
          <dt class="sis-label">{{'RECOMMENDATION.' + approvalRequestType + '.CONTENT_COMMENT' | translate}}</dt>
          <dd class="text-area-static">{{contentRecommendation.comment}}</dd>
        </ng-template>
      </ng-container>
    </ng-container>
  </dl>
</sis-description-list>
