import studyProgressGraphTpl from './studyProgressGraph.tpl.html';
'use strict';
angular.module('student.frontpage.studyProgressGraph').component('studyProgressGraph', {
  template: studyProgressGraphTpl,
  controller: ["$log", "$scope", "studyProgressGraphConfig", "uiContext", "studyProgressGraphResults", "uiStateStore", function ($log, $scope, studyProgressGraphConfig, uiContext, studyProgressGraphResults, uiStateStore) {
    const $ctrl = this;
    $ctrl.$onInit = function () {
      $ctrl.scope = $scope;
      $ctrl.state = {
        type: studyProgressGraphConfig.viewStatus.NOT_SELECTED,
        selected: uiStateStore.readField('', studyProgressGraphConfig.stateStore.KEY)
      };
      $ctrl.plannedExpanded = false;
      $ctrl.attainedExpanded = false;
      $ctrl.popoverExpanded = false;
    };
    $ctrl.onSelect = function (item) {
      $log.debug('select item', item);
      const state = angular.copy($ctrl.state);
      state.type = studyProgressGraphConfig.viewStatus.LOADING;
      state.item = item;
      setState(state);
      uiStateStore.storeField('', studyProgressGraphConfig.stateStore.KEY, {
        planId: item.plan.id,
        moduleId: item.module.id
      });
      return uiContext.loadPlan(item.plan.id).then(onPlanLoaded, errorHandler);
    };
    $ctrl.hasAverage = function () {
      return _.isNumber(_.get($ctrl, 'results.average'));
    };
    function onPlanLoaded() {
      const {
        planContext
      } = uiContext;
      const state = angular.copy($ctrl.state);
      state.type = studyProgressGraphConfig.viewStatus.LOADED;
      state.planContext = planContext;
      setState(state);
      updateValues();
    }
    function setState(state) {
      $ctrl.state = state;
    }
    function updateValues() {
      $ctrl.results = studyProgressGraphResults.getResults($ctrl.state.planContext, $ctrl.state.item.module);
    }
    function errorHandler(err) {
      $log.error('studyProgressGraph load plan error', err);
    }
  }]
});