<ng-container *ngIf="identityProviders?.length > 0">
  <a href="javascript:void(0)"
     *ngIf="identityProviders.length === 1"
     (click)="identityProviders[0].navigate()"
     class="login-link">
    {{'LOGIN' | translate}}
  </a>
  <div *ngIf="identityProviders.length > 1" ngbDropdown class="nav-menu">
    <button type="button" ngbDropdownToggle class="non-button" data-cy="login-button">
      {{'LOGIN' | translate}}
      <sis-icon icon="chevron" iconClass="cw-90" color="white"></sis-icon>
    </button>
    <div ngbDropdownMenu class="dropdown-menu-right" role="menu">
      <a href="javascript:void(0)"
         ngbDropdownItem
         *ngFor="let provider of identityProviders"
         (click)="provider.navigate()">
        {{provider.name}}
      </a>
    </div>
  </div>
</ng-container>
