<div class="row">
  <div class="col-12 row-align-left">
    <div class="irregular-events">
      <sis-expandable [variant]="'link'"
                      [title]="('STUDY_SUB_GROUP_EVENT_INFO.EXCEPTIONS_AND_ADDITIONAL_INFO' | translate) + ' (' + this.irregularEvents.length + ')'"
                      class="exceptions-expandable-link-button">
        <ng-template sisExpandableContent>
          <table class="event-detail-table"
                 [attr.aria-label]="'STUDY_SUB_GROUP_EVENT_INFO.EXCEPTIONS_AND_ADDITIONAL_INFO' | translate">
            <thead class="hide-headers" aria-hidden="true">
            <tr>
              <th>{{ 'STUDY_SUB_GROUP_EVENT_INFO.TIME' | translate }}</th>
              <th>{{ 'STUDY_SUB_GROUP_EVENT_INFO.PLACE' | translate }}</th>
              <th>{{ 'STUDY_SUB_GROUP_EVENT_INFO.TEACHER' | translate }}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let event of this.irregularEvents">
              <td class="col-3">
                <div class="event-content" [ngClass]="{'cancelled-event': event.cancelled || event.excluded}">
                  <span>{{ 'STUDY_SUB_GROUP_EVENT_INFO.TIME' | translate }} </span>
                  <span>{{ event.start | date: 'EEE' }} </span>
                  <span>{{ event.start | date: 'dd.MM.yyyy'}} </span>
                  <span>{{ event | eventTimeRange }} </span>
                </div>
              </td>
              <td class="locations col-3" [ngClass]="{'cancelled-event': event.cancelled || event.excluded}">
                <div>{{'STUDY_SUB_GROUP_EVENT_INFO.PLACE' | translate }}</div>
                <ng-container *ngIf="event.irregularLocationIds !== null">
                  <ng-container
                    *ngTemplateOutlet="locationTemplate;context:{ ids: event.irregularLocationIds }"></ng-container>
                </ng-container>
                <ng-container *ngIf="event.irregularLocationIds === null">
                  <ng-container
                    *ngTemplateOutlet="locationTemplate;context:{ ids: studyEventLocationIds}"></ng-container>
                </ng-container>
                <ng-template #locationTemplate let-locationIds="ids">
                  <div>
                    <div *ngFor="let locationId of locationIds">
                      <div *ngIf="locationId | location | async as location" class="location">
                        {{location.building.name | localizedString:language}}
                        , {{location.name | localizedString:language}}
                      </div>
                    </div>
                  </div>
                </ng-template>
              </td>
              <td class="teachers col-2" [ngClass]="{'cancelled-event': event.cancelled || event.excluded}">
                <div>{{'STUDY_SUB_GROUP_EVENT_INFO.TEACHER' | translate }}</div>
                <ng-container *ngIf="event.irregularTeacherIds !== null">
                  <ng-container
                    *ngTemplateOutlet="teacherTemplate;context:{ ids: event.irregularTeacherIds }"></ng-container>
                </ng-container>
                <ng-container *ngIf="event.irregularTeacherIds === null">
                  <ng-container *ngTemplateOutlet="teacherTemplate;context:{ ids: studyEventTeacherIds}"></ng-container>
                </ng-container>
                <ng-template #teacherTemplate let-teacherIds="ids">
                  <div>
                    <div *ngFor="let teacherId of teacherIds" class="teacher">
                      {{teacherId | publicPerson | async | personFullName}}
                    </div>
                  </div>
                </ng-template>
              </td>
              <td class="col-3">
                <sis-tiny-badge *ngIf="event.cancelled"
                                [variant]="'danger'">{{'STUDY_SUB_GROUP_EVENT_INFO.CANCELLED' | translate}}</sis-tiny-badge>
                <sis-tiny-badge *ngIf="event.excluded"
                                [variant]="'primary'">{{'STUDY_SUB_GROUP_EVENT_INFO.EXCLUDED' | translate}}</sis-tiny-badge>
                <sis-tiny-badge *ngIf="event.irregularLocationIds?.length > 0 && !event.cancelled && !event.excluded"
                                [variant]="'primary'">{{'STUDY_SUB_GROUP_EVENT_INFO.IRREGULAR_LOCATION' | translate}}</sis-tiny-badge>
                <span *ngIf="event.irregularLocationIds?.length === 0 && !event.cancelled && !event.excluded"
                      class="no-location">
                  <span class="location">{{'STUDY_SUB_GROUP_EVENT_INFO.LOCATIONS_REMOVED' | translate }}</span>
                </span>
                <div *ngIf="event.notice" class="notice">
                  <sis-icon [icon]="'info-circle'" [color]="'primary'"></sis-icon>
                  <span class="event-notice">{{ event.notice | localizedString:language }}</span>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </ng-template>
      </sis-expandable>
    </div>
  </div>
</div>
