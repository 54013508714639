<div class="validation-errors" [attr.aria-live]="ariaLive ? 'assertive' : 'off'" data-cy="validation-errors">
  <div class="row" *ngIf="(errors?.length > 0 || hasChildErrors) && (!control || (control.invalid && (control.touched || control.dirty)))">
    <div class="col-12">
      <div sisFocusableError class="error-list" [id]="formFieldId ? formFieldId + '_errors' : null">
        <p class="error-list__item" *ngFor="let error of errors" data-cy="validation-error">
          {{ error.translationKey | translate:error.translationParams }}
        </p>
        <ng-content select="p"></ng-content>
      </div>
    </div>
  </div>
</div>

