import angular from 'angular';
import { PopoverComponent } from '../../popover/popover.component.ts';
import { searchComponentUtilsServiceModule } from '../searchComponentUtils.service';
import searchFilterTpl from './searchFilterTag.tpl.html';
import { searchFilterModule } from './searchFilter.component';
export const searchFilterTagModule = 'sis-components.search.searchFilterTag';
angular.module(searchFilterTagModule, [searchComponentUtilsServiceModule, searchFilterModule, PopoverComponent.downgrade.moduleName]).component('searchFilterTag', {
  bindings: {
    onSelect: '&',
    option: '<',
    parameterModel: '<',
    removeSelection: '&?',
    // Necessary only if searchFilterType is 'Education', 'Organisation' or 'OrganisationRoots'
    searchAgain: '&?',
    searchParameter: '<',
    // Necessary only if searchFilterType is 'Education', 'Organisation' or 'OrganisationRoots'
    searchParams: '<?',
    university: '<',
    isPermanent: '<?'
  },
  template: searchFilterTpl,
  controller: ["$timeout", "SearchFilterTypes", "searchComponentUtilsService", function ($timeout, SearchFilterTypes, searchComponentUtilsService) {
    const ctrl = this;
    ctrl.popoverIsOpen = false;
    ctrl.getParameterValue = () => searchComponentUtilsService.getDisplayValueString(ctrl.parameterModel);
    ctrl.submitFilter = (values, searchParameter) => {
      ctrl.onSelect(values, searchParameter);
      ctrl.onHidden();
    };
    ctrl.onHidden = () => {
      $timeout(() => {
        ctrl.popoverIsOpen = false;
      });
    };
    ctrl.onShown = () => {
      $timeout(() => {
        ctrl.popoverIsOpen = true;
      });
    };
    ctrl.isPopoverDisabled = searchParameter => {
      return searchParameter === 'searchString';
    };
  }]
});