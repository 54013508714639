<div class="modal-header">
  <h2 translate>ENROLMENT.MODIFY_GROUPS_MODAL.TITLE</h2>
</div>

<div class="modal-body" *ngIf="courseUnitRealisation">
  <div class="row">
    <div class="col-12">
      <p [translate]="'ENROLMENT.MODIFY_GROUPS_MODAL.DESCRIPTION'" [translateParams]="{dateTime: endDateTime|localDateTimeFormat}"></p>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <h4 [translate]="'ENROLMENT.MODIFY_GROUPS_MODAL.COURSE_UNIT_REALISATION'"></h4>
      <p>{{courseUnitRealisation|courseUnitRealisationFullName|localizedString}}</p>
    </div>
  </div>

  <form *ngIf="form" [formGroup]="form">
    <div class="row" *ngFor="let studyGroupSet of courseUnitRealisation.studyGroupSets; index as i">
      <div class="col-12">
      <sis-basic-grouping-panel [heading]="studyGroupSet.name | localizedString"
                                [control]="getControlForStudyGroupSet(i)">
          <p class="guidance">{{studyGroupSet | allocationRuleDescription}}</p>
          <table class="table styled-table table-condensed">
            <thead>
            <tr>
              <th class="col-3">{{'GROUP_SELECTION' | translate }}</th>
              <th class="col-6">{{'FIELD_NAMES.studySubGroup' | translate }}</th>
              <th class="col-3">{{'ENROLMENT.MODIFY_GROUPS_MODAL.SEAT' | translate }}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let studySubGroup of studyGroupSet.studySubGroups" data-cy="study-sub-group-row">
              <td class="checkbox-cell"><sis-form-checkbox [control]="getControlForStudySubGroup(i, studySubGroup.id)" [name]="studySubGroup.id"></sis-form-checkbox></td>
              <td class="col-6 sorted-cell">
                {{studySubGroup.name|localizedString}}
                <sis-tiny-badge [variant]="'success'" *ngIf="confirmedGroupsBySsgId.get(studySubGroup.id)">
                  {{'ENROLMENT.MODIFY_GROUPS_MODAL.CONFIRMED_GROUP'|translate}}
                </sis-tiny-badge>
                <sis-tiny-badge [variant]="'danger'" *ngIf="studySubGroup.cancelled">
                  {{'FLOW_STATE.CANCELLED'|translate}}
                </sis-tiny-badge>
              </td>
              <td class="col-3 sorted-cell">{{enrolledCountsBySsgId.get(studySubGroup.id)}}/{{studySubGroup.size}}</td>
            </tr>
            </tbody>
          </table>
          <sis-validation-errors [control]="getControlForStudyGroupSet(i)"></sis-validation-errors>
      </sis-basic-grouping-panel>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer" *ngIf="form">
  <button class="btn btn-primary btn-hollow btn-lg" type="button" (click)="modal.dismiss()" translate>BUTTON.CANCEL</button>
  <button class="btn btn-primary btn-lg" type="button" (click)="save()" [disabled]="!form.dirty || saving" translate>BUTTON.SAVE</button>
</div>
