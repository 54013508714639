<sis-tooltip tooltipText="{{ 'ARIA_LABEL.OPEN_UNI_CART' | translate }}" tooltipPlacement="bottom auto" [tooltipBlockElement]="true" class="shopping-cart-tooltip" [appendToBody]="true">
    <button (click)="goToOpenUniversityCart()"
            [ngClass]="{ 'active': isStateActive }"
            class="menu-icon-open-uni-cart non-button sis-tooltip-trigger-content"
            [attr.aria-label]="'ARIA_LABEL.OPEN_UNI_CART' | translate"
            [attr.aria-current]="isStateActive ? 'page' : 'false'">
        <sis-icon icon="shopping-cart"></sis-icon>
        <span *ngIf="itemsLength > 0" class="badge">{{ itemsLength }}</span>
    </button>
</sis-tooltip>
