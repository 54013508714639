<ng-container *transloco="let t">
  <fudis-dialog [size]="'sm'">
    <fudis-dialog-content>
      <fudis-form [title]="t('STUDY_PLAN_CREATE_NEW_LONG')" [titleLevel]="2" [errorSummaryHelpText]="">
        <ng-template fudisActions [type]="'form'">
          <fudis-button fudisDialogClose [label]="t('SIS_COMPONENTS.BUTTON.CANCEL')" [variant]="'secondary'" />
          <fudis-button [label]="t('CREATE_PLAN')" (handleClick)="onSubmit()" />
        </ng-template>
      </fudis-form>
    </fudis-dialog-content>
  </fudis-dialog>
</ng-container>
