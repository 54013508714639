<div [hidden]="editing" class="application-files-preview">
  <div class="row">
    <div class="col-4">
      <h3 translate>FILE_UPLOAD.FILE_TITLE</h3>
    </div>
    <div class="col-8 col-sm-6 col-lg-5 col-xl-4 button-holder ">
      <button *ngIf="attachments && attachments.length > 0" (click)="downloadZip()" type="button" class="btn btn-primary btn-naked btn-sm">
        <sis-icon icon="place" [iconClass]="'flip-180'"></sis-icon>
        <span translate>FILE_UPLOAD.DOWNLOAD_ALL</span></button>
      <button [disabled]="sendingFiles || editing" *ngIf="editable" (click)="addAttachments()" type="button" class="btn btn-primary btn-hollow btn-sm" translate>FILE_UPLOAD.ADD_MORE_FILES</button>
      <button [disabled]="sendingFiles || editing" *ngIf="editAllFiles" (click)="editAttachments()" type="button" class="btn btn-primary btn-sm" translate>BUTTON.EDIT</button>
    </div>
  </div>
  <p class="guidance" *ngIf="!attachments || attachments.length === 0" translate="FILE_UPLOAD.ZERO_FILES"></p>
  <div class="object file-preview" *ngFor="let attachment of  staffEditMode && attachments ? attachments.slice().reverse() : attachments">
    <fudis-link [externalLink]="attachment.preSignedGetUrl" data-cy="attachment" [title]="attachment.name + readableFileSizeString(attachment.size)"></fudis-link>
    <p class="file-comment">{{attachment.comment}}</p>
  </div>
</div>
<div class="row">
  <div class="col-12 col-md-8">
    <sis-edit-application-attachments *ngIf="editing" (newFiles)="uploadAttachments($event)" [previouslyAddedFiles]="previouslyAddedFiles" (editMode)="setEditMode($event)"></sis-edit-application-attachments>
  </div>
</div>
