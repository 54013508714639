import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageBannerData } from 'common-typescript/types';
import { MarkdownService } from 'ngx-markdown';
import { Observable } from 'rxjs';
import { EnvironmentService } from 'sis-common/environmentService/environment.service';
import { LocalizedString } from 'sis-common/generated/graphql';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { AppErrorHandler } from '../error-handler/app-error-handler';
import { InfoDialogService } from '../info-dialog/info-dialog.service';
import { MessageBannerService } from '../service/message-banner/message-banner.service';
import { ignoreHttpErrorsByStatus } from '../util/utils';

@Component({
    selector: 'sis-message-banner',
    templateUrl: './message-banner.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
@StaticMembers<DowngradedComponent>()
export class MessageBannerComponent implements OnInit {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.message-banner.sisMessageBanner',
        directiveName: 'sisMessageBanner',
    };

    data$: Observable<MessageBannerData>;

    /**
     * Override chosen UI language. The underlying reason for adding this prop was admin frontend's behavior when
     * switching languages that does not trigger initialization of child components which is how it works in other
     * frontends.
     */
    @Input() language?: string;

    constructor(
        private messageBannerService: MessageBannerService,
        private cdr: ChangeDetectorRef,
        private localeService: LocaleService,
        private dialogService: InfoDialogService,
        private translateService: TranslateService,
        private markdownService: MarkdownService,
        private appErrorHandler: AppErrorHandler,
        private environmentService: EnvironmentService,
    ) {}

    ngOnInit() {
        this.data$ = this.messageBannerService.loadMessageBannerData().pipe(
            // Fail silently when message data does not exist.
            ignoreHttpErrorsByStatus(502, 503, 504),
            this.appErrorHandler.defaultErrorHandler(),
        );
    }

    getLocalizedBannerContent(content: LocalizedString) {
        // N.B.: This is used instead of just `<markdown [data]="data.content | localizedString">` because admin
        // frontend will not update the content on language switch when using the pipe.
        return this.localeService.localize(content);
    }

    messageBannerVisible(data: MessageBannerData): boolean {
        const hasBeenClosed = this.messageBannerService.bannerHasBeenClosed(data.id);
        return !hasBeenClosed
            && this.displayTimeIsNow(data.displayTime)
            && this.contentExistsForCurrentLanguage(data.content)
            && this.bannerEnabledForThisFrontend(data.frontends);
    }

    closeMessageBanner(id: string) {
        this.messageBannerService.markBannerAsClosed(id);
    }

    openInfoDialog(info: LocalizedString) {
        const title = this.translateService.instant('UNAVAILABLE.ADDITIONAL_INFO');
        const localizedInfo = this.localeService.localize(info);
        const html = this.markdownService.parse(localizedInfo);
        const descriptions = [html];
        this.dialogService.open({ title, descriptions });
    }

    private contentExistsForCurrentLanguage(content: LocalizedString): boolean {
        const lang = this.localeService.getCurrentLanguage() as keyof LocalizedString;
        const keyExists = Object.keys(content).includes(lang);
        return keyExists && !!content[lang];
    }

    private displayTimeIsNow(
        { startDateTime, endDateTime }: MessageBannerData['displayTime'],
    ): boolean {
        const start = new Date(startDateTime).getTime();
        const end = new Date(endDateTime).getTime();
        const now = Date.now();
        return start < now && now < end;
    }

    private bannerEnabledForThisFrontend(enabledFrontends: string[]): boolean {
        return enabledFrontends
            .map(s => s.toLowerCase())
            .includes(
                this.environmentService.frontendName.toLowerCase(),
            );
    }
}
