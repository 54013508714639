import { ChangeDetectionStrategy, Component, inject, OnInit, signal, ViewEncapsulation } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Attainment, AttainmentType } from 'common-typescript/types';
import { map, Observable, of, shareReplay, switchMap, take } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'sis-common/auth/auth-service';
import { isAssessmentItemAttainment, isCourseUnitAttainment, isCustomCourseUnitAttainment } from 'sis-components/attainment/AttainmentUtil';
import { AttainmentEntityService } from 'sis-components/service/attainment-entity.service';

import {
    getAttainmentDetailsModalOpener,
} from '../../../profile/attainment/attainment-details/attainment-details-modal/attainment-details-modal.component';

@Component({
    selector: 'app-recent-attainments',
    templateUrl: './recent-attainments.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecentAttainmentsComponent implements OnInit {

    readonly attainmentService = inject(AttainmentEntityService);
    private readonly authService = inject(AuthService);
    private readonly openAttainmentDetailsModal = getAttainmentDetailsModalOpener();

    readonly profileAttainmentsHref = inject(StateService).href('student.logged-in.profile.attainment');
    readonly searchFailed = signal(false);

    recentAttainments$: Observable<Attainment[]>;
    allAttainments$: Observable<Attainment[]>;
    private isDetailsModalOpen = false;

    readonly isAssessmentItemAttainment = isAssessmentItemAttainment;
    readonly isCourseUnitAttainment = isCourseUnitAttainment;
    readonly isCustomCourseUnitAttainment = isCustomCourseUnitAttainment;

    ngOnInit(): void {
        this.recentAttainments$ = this.findRecentAttainments();
        this.allAttainments$ = this.attainmentService.getByPersonId(this.authService.personId()).pipe(shareReplay(1));
    }

    onAttainmentClick(clickedAttainment: Attainment): void {
        if (!this.isDetailsModalOpen) {
            this.isDetailsModalOpen = true;
            this.allAttainments$
                .pipe(
                    take(1),
                    map(allStudentAttainments => this.openAttainmentDetailsModal({
                        attainment: clickedAttainment,
                        secondaryAttainments: this.attainmentService.getSecondaryAttainments(clickedAttainment, allStudentAttainments)
                            .filter(({ misregistration }) => !misregistration),
                        hasValidParentAttainment: this.attainmentService.isAttached(clickedAttainment, allStudentAttainments),
                        isAboutToExpire: this.attainmentService.isAboutToExpire(clickedAttainment),
                    })),
                    switchMap(modalRef => modalRef.hidden),
                )
                .subscribe(() => this.isDetailsModalOpen = false);
        }
    }

    private findRecentAttainments(): Observable<Attainment[]> {
        return this.attainmentService.search(
            {
                personId: this.authService.personId(),
                attainmentTypes: [
                    AttainmentType.ASSESSMENT_ITEM_ATTAINMENT,
                    AttainmentType.COURSE_UNIT_ATTAINMENT,
                    AttainmentType.CUSTOM_COURSE_UNIT_ATTAINMENT,
                ],
                misregistration: false,
            },
        )
            .pipe(
                map(result => {
                    const sortedAttainments = this.sortSearchResults(result.searchResults);
                    return sortedAttainments.slice(0, 5);
                }),
                catchError(() => {
                    this.searchFailed.set(true);
                    return of([]);
                }),
            );
    }

    /*
    * SortOrder is:
    * - attainmentDate
    *   if attainments have same date:
    *   - type
    *       if attainments have same date & type, e.g.: 'AssessmentItemAttainment':
    *       - metadata.createdOn
    * */
    private sortSearchResults(attainments: Attainment[]): Attainment[] {
        if (attainments.length === 0) {
            return attainments;
        }

        const typeOrder: { [key: string]: number } = {
            [AttainmentType.COURSE_UNIT_ATTAINMENT]: 1,
            [AttainmentType.CUSTOM_COURSE_UNIT_ATTAINMENT]: 2,
            [AttainmentType.ASSESSMENT_ITEM_ATTAINMENT]: 3,
        };

        return attainments.sort((a, b) =>
            b.attainmentDate.localeCompare(a.attainmentDate) ||
                                    typeOrder[a.type] - typeOrder[b.type] ||
                                    b.metadata.createdOn.localeCompare(a.metadata.createdOn),
        );
    }
}
