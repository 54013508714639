import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import angularTranslate from 'angular-translate';
import { commonFormModule } from 'sis-common/form/constraintService';
import { localizedStringFilterModule } from 'sis-common/l10n/localizedStringFilter';
import { errorServiceModule } from 'sis-components/error-handler/legacy/errorService';
import { commonEnrolmentPeriodDateServiceModule } from 'sis-components/service/enrolmentPeriodDate.service';
import { commonEnrolmentQuestionnaireServiceModule } from 'sis-components/service/enrolmentQuestionnaire.service';
import { commonEducationServiceModule } from 'sis-components/service/education.service';
import { commonEnrolmentServiceModule } from 'sis-components/service/enrolment.service';
import { dropdownSelectModule } from 'sis-components/select/dropdownSelect.component';
import { localDateFormatFilterModule } from 'sis-components/date/filters/localDateFormat/localDateFormat.filter';
import { MarkupStringComponent } from 'sis-components/string/markup-string/markup-string.component.ts';
import { commonUniversityServiceModule } from 'sis-common/university/university.service.ts';
import { enrolmentServiceModule } from '../../../service/enrolment.service';
import enrolmentModalTpl from './enrolmentModal.tpl.html';
export const enrolmentModalModule = 'student.common.components.course-unit-realisation.enrolmentModal';
(function () {
  enrolmentModalController.$inject = ["$q", "constraintService", "enrolmentPeriodDateService", "commonEnrolmentQuestionnaireService", "commonEducationService", "$filter", "commonEnrolmentService", "enrolmentService", "defaultPromiseHandler", "universityService"];
  angular.module(enrolmentModalModule, [angularTranslate, localDateFormatFilterModule, localizedStringFilterModule, 'sis-common.form', commonEnrolmentPeriodDateServiceModule, commonFormModule, commonEducationServiceModule, commonEnrolmentQuestionnaireServiceModule, commonUniversityServiceModule, dropdownSelectModule, commonEnrolmentServiceModule, enrolmentServiceModule, errorServiceModule, MarkupStringComponent.downgrade.moduleName]).component('enrolmentModal', {
    template: enrolmentModalTpl,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: enrolmentModalController
  });
  function enrolmentModalController($q, constraintService, enrolmentPeriodDateService, commonEnrolmentQuestionnaireService, commonEducationService, $filter, commonEnrolmentService, enrolmentService, defaultPromiseHandler, universityService) {
    const ctrl = this;
    ctrl.okForEnrolmentPressed = false;
    ctrl.agreementCheck = false;
    ctrl.$onInit = () => {
      ctrl.constraints = {
        enrolmentQuestionAnswer: constraintService.getTypeConstraints('EnrolmentQuestionAnswer', ['Active'])
      };
      ctrl.title = ctrl.resolve.update ? 'ENROLMENT.ENROLMENT_MODAL.UPDATE_TITLE' : 'ENROLMENT.ENROLMENT_MODAL.ENROL_TITLE';
      ctrl.courseUnitRealisation = ctrl.resolve.courseUnitRealisation;
      ctrl.courseUnit = ctrl.resolve.courseUnit;
      ctrl.enrolment = ctrl.resolve.enrolment;
      if (!ctrl.resolve.update) {
        setStudyRightsForEnrolment();
      } else {
        ctrl.showStudyRight = !_.isNil(ctrl.enrolment.studyRightId);
      }
      ctrl.enrolmentPeriodDates = enrolmentPeriodDateService.getEnrolmentDates(ctrl.courseUnitRealisation);
      ctrl.collapsed = true;
      ctrl.credits = enrolmentService.getEnrolmentCredits(ctrl.enrolment);
      ctrl.isCreditRange = ctrl.credits && ctrl.credits.min !== ctrl.credits.max;
      ctrl.enrolmentQuestionnaireAnswers = ctrl.resolve.enrolmentQuestionnaireAnswers;
      ctrl.enrolmentQuestionnaire = ctrl.resolve.enrolmentQuestionnaire;
      ctrl.questionsHaveBeenUpdated = _.get(ctrl.enrolmentQuestionnaire, 'metadata.lastModifiedOn') ? moment(ctrl.enrolmentQuestionnaire.metadata.lastModifiedOn).isAfter(moment(ctrl.enrolmentPeriodDates.startDateTime)) : false;
      const languageQuestion = _.find(_.get(ctrl, 'enrolmentQuestionnaire.enrolmentQuestions', []), {
        questionType: 'LANGUAGE_SELECT'
      });
      ctrl.languageQuestion = commonEnrolmentQuestionnaireService.constructLanguageSelectQuestionForStudent(languageQuestion, ctrl.courseUnit);
      ctrl.otherQuestionIds = _.map(_.filter(_.get(ctrl, 'enrolmentQuestionnaire.enrolmentQuestions', []), question => question.questionType !== 'LANGUAGE_SELECT'), 'localId');
    };
    function setStudyRightsForEnrolment() {
      // ids of degree educations that are referenced by some of students ongoing (state active or passive) study rights
      const degreeEducationIds = _.chain(ctrl.resolve.myStudyRightEducations).filter(commonEducationService.isDegreeEducation).map('id').value();
      const activeAndPassiveDegreeStudyRights = _.filter(ctrl.resolve.myStudyRights, studyRight => ['NOT_STARTED', 'ACTIVE', 'ACTIVE_NONATTENDING', 'PASSIVE'].includes(studyRight.state) && degreeEducationIds.includes(studyRight.educationId));
      const activeNonDegreeStudyRights = _.filter(ctrl.resolve.myStudyRights, studyRight => ['NOT_STARTED', 'ACTIVE', 'ACTIVE_NONATTENDING'].includes(studyRight.state) && !_.includes(degreeEducationIds, studyRight.educationId));
      ctrl.possibleStudyRightsForEnrolment = _.concat(activeAndPassiveDegreeStudyRights, activeNonDegreeStudyRights);
      ctrl.studyRightSelectionOptions = ctrl.possibleStudyRightsForEnrolment.map(studyRight => ({
        value: studyRight.id,
        label: $filter('localizedString')(ctrl.getEducationNameForStudyRightId(studyRight.id)),
        info: getStudyRightSelectionInfo(studyRight.state)
      }));
      ctrl.enrolment.studyRightId = resolveStudyRightIdForEnrolment(activeAndPassiveDegreeStudyRights, activeNonDegreeStudyRights);

      // check if course unit has course unit in primary plan rule and if study right is selected for enrolment
      if (ctrl.hasCourseUnitInPrimaryPlanRule() && !_.isNil(ctrl.enrolment.studyRightId)) {
        ctrl.courseUnitNotInPrimaryPlanNotification = ctrl.courseUnitNotInPrimaryPlan();
      }
      ctrl.showStudyRight = !_.isNil(ctrl.enrolment.studyRightId) && _.size(ctrl.possibleStudyRightsForEnrolment) === 1;
      ctrl.showStudyRightSelection = _.size(ctrl.possibleStudyRightsForEnrolment) > 1;
    }
    function getStudyRightSelectionInfo(studyRightState) {
      const stateTranslation = $filter('translate')(`STUDY_RIGHT_STATE.${studyRightState}`);
      return `(${stateTranslation})`;
    }
    function resolveStudyRightIdForEnrolment(activeAndPassiveDegreeStudyRights, activeNonDegreeStudyRights) {
      if (_.size(activeAndPassiveDegreeStudyRights) === 1) {
        return _.get(_.head(activeAndPassiveDegreeStudyRights), 'id');
      }
      if (_.size(activeAndPassiveDegreeStudyRights) === 0 && _.size(activeNonDegreeStudyRights) === 1) {
        return _.get(_.head(activeNonDegreeStudyRights), 'id');
      }

      // primary plans with course unit selection that references enrolment.courseUnitId
      const plansWithCourseUnitSelection = _.chain(ctrl.resolve.myPlans).filter('primary').filter(plan => _.includes(_.map(_.get(plan, 'courseUnitSelections'), 'courseUnitId'), _.get(ctrl, 'resolve.enrolment.courseUnitId'))).value();

      // if only one primary plan with cu selection, then related study right should be selected for enrolment
      if (_.size(plansWithCourseUnitSelection) === 1) {
        return _.chain(_.concat(activeAndPassiveDegreeStudyRights, activeNonDegreeStudyRights)).find({
          educationId: _.get(_.head(plansWithCourseUnitSelection), 'rootId')
        }).get('id').value();
      }

      // if study right cannot be resolved, return undefined
      return undefined;
    }
    function realisationOrgIdsIncludeCurrentUniversity() {
      return _.includes(_.get(ctrl.courseUnitRealisation, 'universityOrgIds'), universityService.getCurrentUniversityOrgId());
    }
    ctrl.courseUnitNotInPrimaryPlan = () => {
      // check if my primary plan include course unit with selected study right
      const primaryPlanWithCourseUnit = _.chain(ctrl.resolve.myPlans).filter('primary').filter(plan => _.includes(_.map(_.get(plan, 'courseUnitSelections'), 'courseUnitId'), _.get(ctrl, 'resolve.enrolment.courseUnitId'))).filter(plan => plan.rootId === _.find(ctrl.resolve.myStudyRights, {
        id: ctrl.enrolment.studyRightId
      }).educationId).value();
      return _.size(primaryPlanWithCourseUnit) === 0;
    };
    ctrl.hasCourseUnitInPrimaryPlanRule = () => {
      const requirementPersonRules = _.get(ctrl.resolve.calculationConfig, 'requirementPersonRules');
      const orderingPersonRules = _.get(ctrl.resolve.calculationConfig, 'orderingPersonRules');
      return _.some(requirementPersonRules, {
        type: 'CourseUnitInPrimaryPlan'
      }) || _.some(orderingPersonRules, {
        type: 'CourseUnitInPrimaryPlan'
      });
    };
    ctrl.getQuestionIndex = question => {
      if (!_.includes(ctrl.otherQuestionIds, _.get(question, 'localId'))) {
        return undefined;
      }
      return ctrl.otherQuestionIds.indexOf(question.localId) + 1;
    };
    ctrl.updateLanguageAnswer = (question, value) => {
      const answer = _.find(ctrl.enrolmentQuestionnaireAnswers.answers, {
        questionId: question.localId
      });
      answer.selections = [value];
    };
    ctrl.requiredQuestionsAnswered = () => {
      if (ctrl.hasQuestions()) {
        const answers = _.get(ctrl.enrolmentQuestionnaireAnswers, 'answers');
        let answered = true;
        _.forEach(ctrl.enrolmentQuestionnaire.enrolmentQuestions, question => {
          const answer = _.find(answers, {
            questionId: question.localId
          });
          if (question.required && answered) {
            if (question.questionType === 'FREE_TEXT') {
              answered = !!_.get(answer, 'answerText');
            } else {
              answered = !!_.head(_.get(answer, 'selections'));
            }
          }
        });
        return answered;
      }
      return true;
    };
    ctrl.getAnswerIndex = questionId => {
      if (questionId) {
        return _.findIndex(ctrl.enrolmentQuestionnaireAnswers.answers, {
          questionId
        });
      }
      return -1;
    };
    ctrl.hasQuestions = () => !_.isEmpty(_.get(ctrl.enrolmentQuestionnaire, 'enrolmentQuestions'));
    ctrl.getEducationNameForStudyRightId = function (id) {
      const studyRight = _.find(ctrl.resolve.myStudyRights, {
        id
      });
      return _.chain(ctrl.resolve.myStudyRightEducations).find({
        id: _.get(studyRight, 'educationId')
      }).get('name').value();
    };
    ctrl.studyRightSelectionChanged = function (selection) {
      ctrl.enrolment.studyRightId = _.chain(ctrl.resolve.myStudyRights).find({
        id: selection
      }).get('id').value();
      // check if course unit has course unit in primary plan rule and if study right is selected for enrolment
      if (ctrl.hasCourseUnitInPrimaryPlanRule() && !_.isNil(ctrl.enrolment.studyRightId)) {
        ctrl.courseUnitNotInPrimaryPlanNotification = ctrl.courseUnitNotInPrimaryPlan();
      }
    };
    ctrl.showAgreementCheck = () => !realisationOrgIdsIncludeCurrentUniversity();
    ctrl.hasStudyRightIdIfRequired = () => _.size(ctrl.possibleStudyRightsForEnrolment) === 0 || !_.isNil(ctrl.enrolment.studyRightId);
    ctrl.answerLengthValid = () => !_.get(ctrl.questionAnswerForm, '$error.maxlength') && !_.get(ctrl.questionAnswerForm, '$error.minlength');
    ctrl.collapse = () => {
      ctrl.collapsed = !ctrl.collapsed;
    };
    ctrl.validEnrolment = () => {
      const enrolmentHas = _.partial(_.get, ctrl.enrolment, _, false);
      return !!enrolmentHas('courseUnitRealisationId') && !!enrolmentHas('courseUnitId') && !!enrolmentHas('assessmentItemId');
    };
    ctrl.okEnabled = () => ctrl.validEnrolment() && ctrl.hasStudyRightIdIfRequired() && ctrl.requiredQuestionsAnswered() && ctrl.answerLengthValid() && (ctrl.agreementCheck || !ctrl.showAgreementCheck());
    ctrl.ok = () => {
      if (ctrl.okForEnrolmentPressed) {
        return;
      }
      if (ctrl.resolve.update) {
        ctrl.okForUpdate();
      } else {
        ctrl.okForEnrolment();
      }
    };
    ctrl.okForEnrolment = function () {
      ctrl.okForEnrolmentPressed = true;
      ctrl.enrolToCur().then(() => {
        const modalResponse = {
          enrolment: ctrl.enrolment,
          enrolmentQuestionnaireAnswers: ctrl.enrolmentQuestionnaireAnswers
        };
        ctrl.close({
          $value: modalResponse
        });
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler).finally(() => {
        ctrl.okForEnrolmentPressed = false;
      });
    };
    ctrl.okForUpdate = function () {
      ctrl.updateEnrolment().then(ctrl.createOrUpdateQuestionnaireAnswers).then(() => {
        const modalResponse = {
          enrolment: ctrl.enrolment,
          enrolmentQuestionnaireAnswers: ctrl.enrolmentQuestionnaireAnswers
        };
        ctrl.close({
          $value: modalResponse
        });
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
    ctrl.updateEnrolment = function () {
      return commonEnrolmentService.updateEnrolment(ctrl.enrolment).then(enrolment => {
        ctrl.enrolment = enrolment;
      }, error => {
        ctrl.errorOnStudySubGroupSave = true;
        return $q.reject(error);
      });
    };
    ctrl.enrolToCur = function () {
      return commonEnrolmentService.enrol(ctrl.enrolment, ctrl.enrolmentQuestionnaireAnswers).then(enrolment => {
        ctrl.enrolment = enrolment;
      }, error => {
        ctrl.errorOnEnrol = true;
        return $q.reject(error);
      });
    };
    ctrl.createOrUpdateQuestionnaireAnswers = () => {
      if (_.get(ctrl.enrolmentQuestionnaireAnswers, 'metadata')) {
        return ctrl.updateQuestionnaireAnswers();
      }
      return ctrl.createQuestionnaireAnswers();
    };
    ctrl.createQuestionnaireAnswers = () => {
      if (!ctrl.enrolmentQuestionnaireAnswers) {
        return $q.when();
      }
      return commonEnrolmentQuestionnaireService.createQuestionnaireAnswers(ctrl.enrolmentQuestionnaireAnswers).then(enrolmentQuestionnaireAnswers => {
        ctrl.enrolmentQuestionnaireAnswers = enrolmentQuestionnaireAnswers;
      }, error => {
        ctrl.errorOnAnswerSave = true;
        return $q.reject(error);
      });
    };
    ctrl.updateQuestionnaireAnswers = () => {
      if (!ctrl.enrolmentQuestionnaireAnswers) {
        return $q.when();
      }
      return commonEnrolmentQuestionnaireService.updateQuestionnaireAnswers(ctrl.enrolmentQuestionnaireAnswers).then(enrolmentQuestionnaireAnswers => {
        ctrl.enrolmentQuestionnaireAnswers = enrolmentQuestionnaireAnswers;
      }, error => {
        ctrl.errorOnAnswerSave = true;
        return $q.reject(error);
      });
    };
    ctrl.enrolmentPeriodOngoing = () => enrolmentPeriodDateService.enrolmentPeriodOngoing(ctrl.courseUnitRealisation);
    ctrl.lateEnrolmentPeriodOngoing = () => enrolmentPeriodDateService.lateEnrolmentPeriodOngoing(ctrl.courseUnitRealisation);
    ctrl.continuousEnrolmentPeriodOngoing = () => !!ctrl.courseUnitRealisation.continuousEnrolment && ctrl.enrolmentPeriodOngoing();
    ctrl.lateOrContinuousPeriodOngoing = () => ctrl.lateEnrolmentPeriodOngoing() || ctrl.continuousEnrolmentPeriodOngoing();
  }
})();