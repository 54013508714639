import { computed, Injectable, Signal, signal } from '@angular/core';
import { LocalId } from 'common-typescript/types';

export interface RuleErrorState {
    /**
     * LocalId of the rule
     */
    ruleLocalId: LocalId;
    /**
     * Id for moving the focus to the rule in the UI
     */
    ruleFocusId: string;
    /**
     * Name that should be displayed in the UI for this rule
     */
    ruleDisplayName: string;
    errors: RuleError[];
}
export interface RuleError {
    errorId: string;
    errorType: RuleErrorType;
}

type RuleErrorType = 'LESS_SELECTIONS_REQUIRED';
@Injectable()
export class RuleErrorStateService {

    private readonly state = {
        ruleErrorStates$: signal<RuleErrorState[]>([]),
    } as const;

    public readonly ruleErrorStates$: Signal<RuleErrorState[]> = this.state.ruleErrorStates$.asReadonly();

    public readonly hasAnyErrors$: Signal<boolean> = computed(() => this.ruleErrorStates$().some((state) => state.errors?.length > 0));

    /**
     * Updates or adds the rule error state to the list of rule error states
     *
     * @param ruleErrorState Rule error state to update or add
     */
    public updateRuleErrorState(ruleErrorState: RuleErrorState): void {
        this.state.ruleErrorStates$.update((ruleErrorStates) => {
            const index = ruleErrorStates.findIndex((state) =>
                state.ruleLocalId === ruleErrorState.ruleLocalId);
            if (index === -1) {
                return [...ruleErrorStates, ruleErrorState];
            }
            return [...ruleErrorStates.slice(0, index),
                ruleErrorState,
                ...ruleErrorStates.slice(index + 1)];
        });
    }

    /**
     * Get errors of a specific rule by its localId as a signal.
     *
     * @param ruleLocalId the localId of the rule
     */
    getRuleErrors(ruleLocalId: LocalId): Signal<RuleError[]> {
        return computed(() => {
            const ruleErrorState = this.state.ruleErrorStates$().find((state) => state.ruleLocalId === ruleLocalId);
            return ruleErrorState?.errors ?? [];
        });
    }
}
