<ng-container *transloco="let t">
  <dl class="d-flex flex-wrap">
    <div class="tiny-static-form-group sis-mr-xs">
      <dt class="label colon">{{ t('PLAN_EDIT.SELECTION_MODAL.RULE_HEADER.FIELDS.RULE') }}</dt>
      <dd *ngIf="allMandatory()"
            class="value"
            [attr.id]="ruleLegendId">{{ t('PLAN_EDIT.SELECTION_MODAL.RULE_HEADER.RULE_CLAUSES.CHOOSE_ALL') }}
      </dd>
      <dd *ngIf="!allMandatory() && ruleRequireRange()"
          class="value"
          [attr.id]="ruleLegendId">{{
          t('PLAN_EDIT.SELECTION_MODAL.RULE_HEADER.RULE_CLAUSES.CHOOSE') + ' '
          + (ruleRequireRange() | translateRange) + ' '
          + t('PLAN_EDIT.SELECTION_MODAL.RULE_HEADER.RULE_CLAUSES.PCS')
        }}
      </dd>
    </div>
    <div *ngIf="ruleRequireRange()" class="tiny-static-form-group sis-mr-xs">
      <dt class="label colon">{{t('PLAN_EDIT.SELECTION_MODAL.RULE_HEADER.FIELDS.CHOSEN')}}</dt>
      <dd class="value">{{ selectedCount() + '/' + (ruleRequireRange() | translateRange) + ' '
      + t('PLAN_EDIT.SELECTION_MODAL.RULE_HEADER.RULE_CLAUSES.PCS')}}</dd>
    </div>
    <div class="tiny-static-form-group">
      <dt class="label colon">{{t('PLAN_EDIT.SELECTION_MODAL.RULE_HEADER.FIELDS.STATE')}}</dt>
      <dd class="value">{{ rangeValidationResultState() ?? '-' }}</dd>
    </div>
  </dl>
</ng-container>

