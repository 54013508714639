import { ServiceBootstrapComponent } from 'sis-common/angular-hybrid/service-bootstrap.component';
import { AuthService } from 'sis-common/auth/auth-service';
import { TokenStorageService } from 'sis-common/auth/token-storage.service';
import { AuthTokenInterceptorService } from 'sis-common/http-interceptors/auth-token-interceptor.service';
import { ModalService } from 'sis-common/modal/modal.service';
import { DowngradedComponent, DowngradedService } from 'sis-common/types/angular-hybrid';
import { UuidService } from 'sis-common/uuid/uuid.service';
import { AboutComponent } from 'sis-components/about/about.component';
import { AlertsService } from 'sis-components/alerts/alerts-ng.service';
import { AlertsComponent } from 'sis-components/alerts/alerts.component';
import { OpenUniversityCartTimerAlertService } from 'sis-components/alerts/open-university-cart-timer-alert.service';
import {
    AttainedCreditsByStudyTermComponent,
} from 'sis-components/applications/attained-credits-by-study-term/attained-credits-by-study-term.component';
import { AttainmentApplicationStructureComponent } from 'sis-components/applications/attainment-application-structure/attainment-application-structure.component';
import { AttainmentWorkflowDecisionSummaryComponent } from 'sis-components/applications/attainment-workflow-decision-summary/attainment-workflow-decision-summary.component';
import { DegreeProgrammeAttainmentApplicationAnswersComponent } from 'sis-components/applications/degree-programme-attainment-application/degree-programme-attainment-application-answers/degree-programme-attainment-application-answers.component';
import { PlannedStudiesByStudyTermComponent } from 'sis-components/applications/planned-studies-by-study-term/planned-studies-by-study-term.component';
import { PriorCompetenceSummaryComponent } from 'sis-components/applications/prior-competence-summary/prior-competence-summary.component';
import { PriorLearningListComponent } from 'sis-components/applications/prior-learning-list/prior-learning-list.component';
import { PriorLearningWorkflowSummaryComponent } from 'sis-components/applications/prior-learning-workflow/prior-learning-workflow-summary/prior-learning-workflow-summary.component';
import { PriorStudiesSummaryComponent } from 'sis-components/applications/prior-studies-summary/prior-studies-summary.component';
import { StudentWorkflowListComponent } from 'sis-components/applications/student-workflow-list/student-workflow-list.component';
import { AttainmentTreeAttainmentComponent } from 'sis-components/attainment/editableTree/attainment-tree-attainment.component';
import {
    AttainmentRowMenuButtonComponent,
} from 'sis-components/attainment/tree/attainment-row-menu-button/attainment-row-menu-button.component';
import { TinyBadgeComponent } from 'sis-components/badge/tiny-badge/tiny-badge.component';
import { CheckboxComponent } from 'sis-components/checkbox/checkbox.component';
import { ConfirmDialogService } from 'sis-components/confirm/confirm-dialog.service';
import { ContextualNotificationComponent } from 'sis-components/contextual-notification/contextual-notification.component';
import { CourseUnitBoxComponent } from 'sis-components/courseUnit/course-unit-box/course-unit-box.component';
import { CourseUnitCrossStudyComponent } from 'sis-components/courseUnit/course-unit-cross-study/course-unit-cross-study.component';
import { CourseUnitSearchComponent } from 'sis-components/courseUnit/course-unit-search/course-unit-search.component';
import { PriorLearningSubstitutionsComponent } from 'sis-components/courseUnit/prior-learning-substitutions/prior-learning-substitutions.component';
import { CompletionMethodAssessmentItemInfoComponent } from 'sis-components/courseUnitInfoModal/completion-methods/completion-method-assessment-item-info/completion-method-assessment-item-info.component';
import { CompletionMethodAssessmentItemsDetailsComponent } from 'sis-components/courseUnitInfoModal/completion-methods/completion-method-assessment-items-details/completion-method-assessment-items-details.component';
import { CompletionMethodRepeatsComponent } from 'sis-components/courseUnitInfoModal/completion-methods/completion-method-repeats/completion-method-repeats.component';
import { CourseUnitInfoModalTextFieldComponent } from 'sis-components/courseUnitInfoModal/course-unit-info-modal-text-field/course-unit-info-modal-text-field.component';
import { CourseUnitRealisationSeatsComponent } from 'sis-components/courseUnitInfoModal/course-unit-realisation-seats/course-unit-realisation-seats.component';
import { CourseUnitVersionSelectorComponent } from 'sis-components/courseUnitInfoModal/course-unit-version-selector/course-unit-version-selector.component';
import { CustomCodeUrnsComponent } from 'sis-components/custom-code-urns/custom-code-urns/custom-code-urns.component';
import { LegacyLocalDateEditorComponent } from 'sis-components/date/local-date-editor/legacy-local-date-editor/legacy-local-date-editor.component';
import { DisabledLinkComponent } from 'sis-components/disabled-link/disabled-link.component';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { ValidationErrorHandlerService } from 'sis-components/error-handler/validation-error-handler.service';
import { ExternalLinkComponent } from 'sis-components/external-link/external-link.component';
import { FeedbackButtonComponent } from 'sis-components/feedback-modal/feedback-button.component';
import { ApplicationAttachmentUploadService } from 'sis-components/file-upload/application-attachment-upload.service';
import { ApplicationFilesComponent } from 'sis-components/file-upload/application-files.component';
import { FileUploadInfoModalService } from 'sis-components/file-upload/file-upload-info-modal.service';
import { FileUploadComponent } from 'sis-components/file-upload/file-upload.component';
import { BasicGroupingPanelComponent } from 'sis-components/grouping-panel/basic-grouping-panel.component';
import { BreadcrumbsComponent } from 'sis-components/header/breadcrumbs/breadcrumbs.component';
import { HeaderService } from 'sis-components/header/header.service';
import { PageTitleComponent } from 'sis-components/header/page-title/page-title.component';
import { IconComponent } from 'sis-components/icon/icon.component';
import { LanguageChangeService } from 'sis-components/l10n/language-change.service';
import { LabelWithInfoPopoverComponent } from 'sis-components/label-with-info-popover/label-with-info-popover.component';
import { LanguagePillsComponent } from 'sis-components/language-pills/language-pills.component';
import { LiteratureComponent } from 'sis-components/literature/literature.component';
import { LocalizedMarkupStringViewComponent } from 'sis-components/localized-markup-string-view/localized-markup-string-view.component';
import { MarkupStringViewComponent } from 'sis-components/markup-string-view/markup-string-view.component';
import { MenuButtonComponent } from 'sis-components/menuButton/menu-button.component';
import { MessageBannerComponent } from 'sis-components/message-banner/message-banner.component';
import { SisMessageConversationComponent } from 'sis-components/messenger/sis-message-conversation.component';
import { SisMessageConversationsComponent } from 'sis-components/messenger/sis-message-conversations.component';
import { ModuleBoxComponent } from 'sis-components/module/module-box/module-box.component';
import { ModuleSearchComponent } from 'sis-components/module/module-search/module-search.component';
import { OrganisationRoleShareComponent } from 'sis-components/organisation/organisation-role-share/organisation-role-share.component';
import { PaginationComponent } from 'sis-components/pagination/pagination.component';
import { ContactInfoForGraduationComponent } from 'sis-components/person/contact-info-for-graduation/contact-info-for-graduation.component';
import { PersonInfoForGraduationComponent } from 'sis-components/person/person-info-for-graduation/person-info-for-graduation.component';
import { InlineSearchAdditionalDataRowComponent } from 'sis-components/plan/inline-search/inline-search-additional-data-row/inline-search-additional-data-row.component';
import { PopoverComponent } from 'sis-components/popover/popover.component';
import { ReadMoreNgComponent } from 'sis-components/read-more-ng/read-more-ng.component';
import { UiRouterService } from 'sis-components/router/ui-router.service';
import { MarkStringComponent } from 'sis-components/search/mark-string/mark-string.component';
import { SearchFilterOptionsMenuComponent } from 'sis-components/search/search-filter-options-menu/search-filter-options-menu.component';
import { SearchFilterCourseUnitComponent } from 'sis-components/search/searchFilters/search-filter-course-unit.component';
import { SearchFilterEducationComponent } from 'sis-components/search/searchFilters/search-filter-education.component';
import { SearchFilterModuleComponent } from 'sis-components/search/searchFilters/search-filter-module.component';
import { SearchFilterOrganisationComponent } from 'sis-components/search/searchFilters/search-filter-organisation.component';
import { CodeSelectEditorComponent } from 'sis-components/select/code-select-editor.component';
import { CodeSelectionEditorComponent } from 'sis-components/select/code-selection-editor/code-selection-editor.component';
import { DropdownSelectButtonComponent } from 'sis-components/select/dropdown-select-button/dropdown-select-button.component';
import { DropdownSelectComponent } from 'sis-components/select/dropdown-select/dropdown-select.component';
import { SisServiceBreakBannerComponent } from 'sis-components/service-break-banner/sis-service-break-banner.component';
import { ApplicationAttachmentEntityService } from 'sis-components/service/application-attachment-entity.service';
import { AttainmentEntityService } from 'sis-components/service/attainment-entity.service';
import { CommonCodeService } from 'sis-components/service/common-code.service';
import { CommonGradeAverageService } from 'sis-components/service/common-grade-average.service';
import {
    CourseUnitEntityService,
} from 'sis-components/service/course-unit-entity.service';
import { DegreeProgrammeEntityService } from 'sis-components/service/degree-programme-entity.service';
import { InternationalContractualDegreeAgreementEntityService } from 'sis-components/service/international-contractual-degree-agreement-entity.service';
import { MarkupService } from 'sis-components/service/markup.service';
import { MobilityPeriodEntityService } from 'sis-components/service/mobilityPeriod-entity.service';
import { ModuleEntityService } from 'sis-components/service/module-entity.service';
import { NotificationsService } from 'sis-components/service/notifications/notifications.service';
import { OrganisationSettingsService } from 'sis-components/service/organisation-settings.service';
import { PrivatePersonBasicInfoEntityService } from 'sis-components/service/private-person-basic-info-entity.service';
import { PrivatePersonEntityService } from 'sis-components/service/private-person-entity.service';
import { SearchInOrderService } from 'sis-components/service/search-in-order.service';
import { SearchUtilsService } from 'sis-components/service/search-utils.service';
import { ServiceBreakService } from 'sis-components/service/service-break.service';
import { StudyModuleEntityService } from 'sis-components/service/study-module-entity.service';
import { StudySelectorService } from 'sis-components/service/study-selector.service';
import { TermRegistrationRequirementsEntityService } from 'sis-components/service/term-registration-requirements-entity.service';
import { TuitionFeeObligationPeriodEntityService } from 'sis-components/service/tuition-fee-obligation-period.entity.service';
import { WorkflowDataChangeService } from 'sis-components/service/workflow-data-change.service';
import { WorkflowEntityService } from 'sis-components/service/workflow-entity.service';
import { ShowMoreComponent } from 'sis-components/show-more/show-more.component';
import { SkipLinkComponent } from 'sis-components/skip-link/skip-link.component';
import { SortByIndicatorComponent } from 'sis-components/sort/sort-by-indicator.component';
import { SortableTableColumnHeaderComponent } from 'sis-components/sortable-table-column-header/sortable-table-column-header.component';
import { MarkupStringComponent } from 'sis-components/string/markup-string/markup-string.component';
import { ExtensionInfoComponent } from 'sis-components/studyRight/extension-info/extension-info.component';
import { MinorSelectionsComponent } from 'sis-components/studyRight/minor-selection/minor-selections/minor-selections.component';
import { StudyRightStatusBadgeComponent } from 'sis-components/studyRight/study-right-status-badge/study-right-status-badge.component';
import { TabContentSwitchComponent } from 'sis-components/tabs/tab-content-switch/tab-content-switch.component';
import { TabNavigationComponent } from 'sis-components/tabs/tab-navigation/tab-navigation.component';
import { TooltipComponent } from 'sis-components/tooltip/tooltip.component';
import { LanguageSelectionMenuComponent } from 'sis-components/topNav/language-selection-menu/language-selection-menu.component';
import { LoginProviderMenuComponent } from 'sis-components/topNav/login-provider-menu/login-provider-menu.component';
import { NavUtilsService } from 'sis-components/topNav/nav-utils/nav-utils.service';
import { OpenUniversityCartButtonComponent } from 'sis-components/topNav/open-university-cart-button/open-university-cart-button.component';
import { TopNavigationComponent } from 'sis-components/topNav/top-navigation/top-navigation.component';
import { UserSettingsMenuComponent } from 'sis-components/topNav/user-settings-menu/user-settings-menu.component';
import { TranspileComponent } from 'sis-components/transpile/transpile.component';
import { TypeaheadComponent } from 'sis-components/typeahead/typeahead.component';
import { UnauthorizedComponent } from 'sis-components/unauthorized/unauthorized.component';
import { UniversitySelectEditorComponent } from 'sis-components/university/university-select-editor/university-select-editor.component';
import { VersionSelectorComponent } from 'sis-components/version-selector/version-selector.component';

import { FullCalendarMessageService } from './calendar/full-calendar/full-calendar-message.service';
import { FullCalendarComponent } from './calendar/full-calendar/full-calendar.component';
import { ChoosePlanComponent } from './calendar/right-panel/choose-plan/choose-plan.component';
import { CourseUnitInfoHeaderComponent } from './common/components/course-unit-info-as-page/course-unit-info-header/course-unit-info-header.component';
import {
    CourseUnitInfoModalHeaderComponent,
} from './common/components/course-unit-info-modal/course-unit-info-modal-header/course-unit-info-modal-header.component';
import { CourseUnitInfoModalSubstitutionsComponent } from './common/components/course-unit-info-modal/course-unit-info-modal-substitutions/course-unit-info-modal-substitutions.component';
import { StudentCourseUnitRealisationStructureComponent } from './common/components/course-unit-info-modal/course-unit-realisation/student-course-unit-realisation-structure/student-course-unit-realisation-structure.component';
import { CourseUnitInfoBrochureComponent } from './common/components/course-unit-info/course-unit-info-brochure/course-unit-info-brochure.component';
import { CourseUnitSelectionInfoComponent } from './common/components/course-unit-selection-info/course-unit-selection-info.component';
import { EnrolmentStateIndicatorComponent } from './common/components/enrolment/enrolment-state-indicator/enrolment-state-indicator.component';
import { ProcessingStateIndicatorComponent } from './common/components/enrolment/processing-state-indicator/processing-state-indicator.component';
import {
    GraduationInstructionsUrlComponent,
} from './common/components/graduation/graduation-instructions-url/graduation-instructions-url.component';
import { ModuleInfoModalService } from './common/components/module-info/module-info-modal.service';
import { PlanSelectComponent } from './common/components/plan-select/plan-select.component';
import { ApplyForModuleContentWorkflowComponent } from './common/components/show-view-module-content-application-link/apply-for-module-content-workflow/apply-for-module-content-workflow.component';
import { CourseCartEntityService } from './common/service/course-cart-entity.service';
import { EnrolmentStudentService } from './common/service/enrolment-student.service';
import { NewPlanSubstitutionService } from './common/service/new-plan-substitution.service';
import { PlanSelectService } from './common/service/plan-select.service';
import { StudentPlanOutdatedCourseUnitsService } from './common/service/student-plan-outdated-course-units.service';
import { CourseCartComponent } from './course-cart/course-cart.component';
import { EnrolmentWizardComponent } from './enrolments/enrolment-wizard/enrolment-wizard.component';
import { EnrolmentsMainContainerComponent } from './enrolments/enrolments-main-container/enrolments-main-container.component';
import { MyStudiesComponent } from './frontpage/my-studies/my-studies.component';
import { StudentFrontpageMessageConversationsComponent } from './frontpage/student-frontpage-message-conversations/student-frontpage-message-conversations.component';
import { StudentTermRegistrationPeriodInfoComponent } from './frontpage/student-term-registration-period-info/student-term-registration-period-info.component';
import { StudyProgressGraphDropdownComponent } from './frontpage/studyProgressGraph/study-progress-graph-dropdown/study-progress-graph-dropdown.component';
import { StudentMessageConversationsListComponent } from './message-conversations/student-message-conversations-list.component';
import { OpenUniversityOfferingComponent } from './open-university/study-offering/open-university-offering/open-university-offering.component';
import { OutdatedCourseUnitsModalService } from './plan/outdated-course-units-modal/outdated-course-units-modal.service';
import { AnyCourseUnitRuleRipaCheckboxComponent } from './plan/rules/any-course-unit-rule-ripa-checkbox/any-course-unit-rule-ripa-checkbox.component';
import { ModuleAttainmentApplicationComponent } from './profile/applications/module-attainment-application/module-attainment-application.component';
import { PriorLearningWorkflowContentComponent } from './profile/applications/prior-learning-workflow-content/prior-learning-workflow-content.component';
import {
    AttainmentDetailsModalComponent,
} from './profile/attainment/attainment-details/attainment-details-modal/attainment-details-modal.component';
import { ProfileSecondaryNavigationComponent } from './profile/profile-secondary-navigation/profile-secondary-navigation.component';
import { OpenUniversityStudentStudyRightComponent } from './profile/studyRights/open-university-student-study-right/open-university-student-study-right.component';
import { SearchMainComponent } from './search/search-main/search-main.component';
import { TimelineNoteComponent } from './timing/line/note/timeline-note/timeline-note.component';
/**
 * Angular -> AngularJS downgraded components for the student frontend.
 *
 * If you need to downgrade a new component, just add it to this list. We only have one downgraded root module, and all components
 * are registered to it regardless of which Angular module they belong to.
 */
export const downgradedComponents: DowngradedComponent[] = [
    MyStudiesComponent,
    AboutComponent,
    AlertsComponent,
    OpenUniversityCartButtonComponent,
    ApplicationFilesComponent,
    AttainmentTreeAttainmentComponent,
    BasicGroupingPanelComponent,
    BreadcrumbsComponent,
    CheckboxComponent,
    ChoosePlanComponent,
    ContactInfoForGraduationComponent,
    ContextualNotificationComponent,
    CourseCartComponent,
    CourseUnitBoxComponent,
    CourseUnitSearchComponent,
    CourseUnitCrossStudyComponent,
    CourseUnitRealisationSeatsComponent,
    CourseUnitVersionSelectorComponent,
    DegreeProgrammeAttainmentApplicationAnswersComponent,
    DisabledLinkComponent,
    DropdownSelectButtonComponent,
    DropdownSelectComponent,
    EnrolmentStateIndicatorComponent,
    PriorLearningSubstitutionsComponent,
    ExtensionInfoComponent,
    ExternalLinkComponent,
    EnrolmentWizardComponent,
    FeedbackButtonComponent,
    IconComponent,
    LabelWithInfoPopoverComponent,
    LanguageSelectionMenuComponent,
    LegacyLocalDateEditorComponent,
    LiteratureComponent,
    LocalizedMarkupStringViewComponent,
    LoginProviderMenuComponent,
    MarkupStringViewComponent,
    MenuButtonComponent,
    MinorSelectionsComponent,
    ModuleAttainmentApplicationComponent,
    ModuleBoxComponent,
    ModuleSearchComponent,
    OpenUniversityOfferingComponent,
    OpenUniversityStudentStudyRightComponent,
    OrganisationRoleShareComponent,
    PageTitleComponent,
    CourseUnitSelectionInfoComponent,
    PaginationComponent,
    PersonInfoForGraduationComponent,
    PlanSelectComponent,
    PlannedStudiesByStudyTermComponent,
    PopoverComponent,
    PriorLearningListComponent,
    ProcessingStateIndicatorComponent,
    ProfileSecondaryNavigationComponent,
    SearchFilterEducationComponent,
    SearchFilterModuleComponent,
    SearchFilterOptionsMenuComponent,
    SearchFilterOrganisationComponent,
    ServiceBootstrapComponent,
    ShowMoreComponent,
    SisMessageConversationComponent,
    SisMessageConversationsComponent,
    SisServiceBreakBannerComponent,
    SkipLinkComponent,
    SortByIndicatorComponent,
    SortableTableColumnHeaderComponent,
    StudentFrontpageMessageConversationsComponent,
    StudentMessageConversationsListComponent,
    StudyProgressGraphDropdownComponent,
    StudyRightStatusBadgeComponent,
    TabContentSwitchComponent,
    TabNavigationComponent,
    TooltipComponent,
    TimelineNoteComponent,
    TypeaheadComponent,
    UnauthorizedComponent,
    UniversitySelectEditorComponent,
    UserSettingsMenuComponent,
    FileUploadComponent,
    AttainmentApplicationStructureComponent,
    SearchMainComponent,
    StudentTermRegistrationPeriodInfoComponent,
    PriorStudiesSummaryComponent,
    PriorCompetenceSummaryComponent,
    StudentWorkflowListComponent,
    CodeSelectEditorComponent,
    CodeSelectionEditorComponent,
    LanguagePillsComponent,
    StudentCourseUnitRealisationStructureComponent,
    PriorLearningWorkflowSummaryComponent,
    PriorLearningWorkflowContentComponent,
    AttainmentWorkflowDecisionSummaryComponent,
    VersionSelectorComponent,
    CompletionMethodAssessmentItemInfoComponent,
    CompletionMethodAssessmentItemsDetailsComponent,
    CompletionMethodRepeatsComponent,
    CourseUnitInfoBrochureComponent,
    CourseUnitInfoHeaderComponent,
    CourseUnitInfoModalHeaderComponent,
    CourseUnitInfoModalTextFieldComponent,
    CustomCodeUrnsComponent,
    EnrolmentsMainContainerComponent,
    MarkupStringComponent,
    AnyCourseUnitRuleRipaCheckboxComponent,
    InlineSearchAdditionalDataRowComponent,
    AttainmentDetailsModalComponent,
    MessageBannerComponent,
    CourseUnitInfoModalSubstitutionsComponent,
    TinyBadgeComponent,
    GraduationInstructionsUrlComponent,
    AttainmentRowMenuButtonComponent,
    SearchFilterCourseUnitComponent,
    MarkStringComponent,
    ApplyForModuleContentWorkflowComponent,
    ReadMoreNgComponent,
    TranspileComponent,
    FullCalendarComponent,
    TopNavigationComponent,
    AttainedCreditsByStudyTermComponent,
];

/**
 * Angular -> AngularJS downgraded service registrations for the student frontend.
 *
 * If you need to downgrade a new service, just add it to this list. All services should be registered in the app module.
 */
export const downgradedServices: DowngradedService[] = [
    NotificationsService,
    AlertsService,
    ApplicationAttachmentEntityService,
    ApplicationAttachmentUploadService,
    HeaderService,
    CommonCodeService,
    CommonGradeAverageService,
    ConfirmDialogService,
    SearchInOrderService,
    FileUploadInfoModalService,
    OrganisationSettingsService,
    MobilityPeriodEntityService,
    ModalService,
    NavUtilsService,
    InternationalContractualDegreeAgreementEntityService,
    PlanSelectService,
    PrivatePersonEntityService,
    SearchUtilsService,
    ServiceBreakService,
    TermRegistrationRequirementsEntityService,
    LanguageChangeService,
    TuitionFeeObligationPeriodEntityService,
    UiRouterService,
    UuidService,
    OpenUniversityCartTimerAlertService,
    WorkflowDataChangeService,
    WorkflowEntityService,
    PrivatePersonBasicInfoEntityService,
    AttainmentEntityService,
    ValidationErrorHandlerService,
    StudentPlanOutdatedCourseUnitsService,
    StudyModuleEntityService,
    ModuleEntityService,
    DegreeProgrammeEntityService,
    CourseUnitEntityService,
    MarkupService,
    StudySelectorService,
    AppErrorHandler,
    NewPlanSubstitutionService,
    CourseCartEntityService,
    EnrolmentStudentService,
    ModuleInfoModalService,
    FullCalendarMessageService,
    OutdatedCourseUnitsModalService,
    TokenStorageService,
    AuthService,
    AuthTokenInterceptorService,
];
