import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { StateService } from '@uirouter/core';
import { of, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { OpenUniversityCartCustomerService } from '../../service/open-university-cart-customer.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-open-university-cart-button',
    templateUrl: './open-university-cart-button.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class OpenUniversityCartButtonComponent implements OnInit, OnDestroy {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sisComponents.openUniversityCartButton',
        directiveName: 'sisOpenUniversityCartButton',
    };

    @Input() isStateActive: boolean;
    itemsLength = 0;
    subscription: Subscription;

    constructor(private stateService: StateService,
                private openUniversityCartCustomerService: OpenUniversityCartCustomerService) { }

    ngOnInit(): void {
        // This should receive updated cart -object every time when akita store is updated
        this.subscription = this.openUniversityCartCustomerService.getCurrentCart()
            .pipe(
                catchError(err => of(null)),
            )
            .subscribe(cart => {
                if (cart?.state === 'RESERVED' || cart?.state === 'TENTATIVE') {
                    this.itemsLength = cart?.items?.length;
                } else {
                    this.itemsLength = undefined;
                }
            });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    goToOpenUniversityCart() {
        this.stateService.go('student.open-university-cart');
    }
}
