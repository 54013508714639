<div role="group"
     [attr.aria-labelledby]="ruleLegendId()">
  <sis-plan-structure-count-rule-header [rule]="_rule()"
                                        [ruleLegendId]="ruleLegendId()"
                                        [ruleValidationResults]="ruleValidationResults()">
  </sis-plan-structure-count-rule-header>
  <sis-plan-structure-select-rule [parentModule]="_parentModule()"
                                  [headingLevel]="headingLevel"
                                  [rule]="_rule().rule"
                                  [planData]="planData"
                                  [planRuleData]="planRuleData"
                                  [planStateObject]="planStateObject"
                                  [planValidationResult]="_planValidationResult()"
                                  [validatablePlan]="validatablePlan"
                                  [groupPrefix]="groupPrefix"
                                  [ruleDepthLevel]="ruleDepthLevel + 1"
                                  [selectionDisabled]="selectionsDisabled">
  </sis-plan-structure-select-rule>
</div>

