<label *ngIf="label" id="combobox-select-label">{{ label }}</label>
<div [ngClass]="[open ? 'open' : '', 'combo select']" #parent>
  <div aria-controls="sis-listbox"
        data-cy="select-combobox"
        aria-haspopup="listbox"
        [attr.aria-labelledby]="[label ? 'combobox-select-label' : ariaLabelledBy]"
        [attr.aria-activedescendant]="ariaActiveDescendant"
        [attr.aria-expanded]="open"
        id="sis-combo-select"
        class="combobox-select-input form-control"
        role="combobox"
        tabindex="0"
        (click)="updateMenuState(!open, false)"
        (keydown)="onComboKeyDown($event)"
        (blur)="onComboBlur()"
        #combobox>
    <div class="info-container">
      <span *ngIf="!selected" id="placeholder" class="form-placeholder">{{'SIS_COMPONENTS.SELECT.CHOOSE' | translate}}</span>
      <span *ngIf="selected" class="input-label">{{selected.label}}</span>
      <span *ngIf="selected?.info" class="additional-info input">
            <span class="additional-info-text">{{selected.info}}</span>
      </span>
    </div>
    <span aria-hidden="true" class="select-combobox-chevron"></span>
  </div>
  <div *ngIf="open"
        class="combo-menu"
        role="listbox"
        id="sis-listbox"
        [attr.aria-labelledby]="[label ? 'dropdown-menu-label' : ariaLabelledBy]"
        tabindex="-1"
        #listbox>
    <div *ngFor="let option of options, let i = index"
        [attr.id]="'sis-combo-select-' + i"
        [ngClass]="[activeIndex === i ? 'option-current': '', 'combo-option']"
        [attr.role]="'option'"
        [attr.aria-selected]="selected?.value === option.value"
        (click)="onOptionClick(i)"
        (mousedown)="onOptionMouseDown()">
      <div class="info-container">
        <span>{{option.label}}</span>
          <span class="additional-info">
            {{option.info}}
          </span>
      </div>
    </div>
  </div>
</div>
