import { ChangeDetectionStrategy, Component, inject, OnInit, signal, ViewEncapsulation } from '@angular/core';
import { ValidatablePlan } from 'common-typescript/src/plan/validation/validatablePlan';
import { Education, Module, OtmId, Plan, StudyProgressFrontpageComponentSetting, StudyRight, UserSettings } from 'common-typescript/types';
import _ from 'lodash';
import { combineLatest, from, Observable, of, switchMap, take, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalizedStringPipe } from 'sis-common/l10n/localized-string.pipe';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { PlanLoaderService } from 'sis-components/plan/plan-loader.service';
import { StudyProgressGraphResults, StudyProgressGraphResultService } from 'sis-components/plan/study-progress-graph-result.service';
import { Option } from 'sis-components/select/dropdown-select/dropdown-select.component';
import { EducationEntityService } from 'sis-components/service/education-entity.service';
import { ModuleEntityService } from 'sis-components/service/module-entity.service';
import { PlanEntityService } from 'sis-components/service/plan-entity.service';
import { StudyRightEntityService } from 'sis-components/service/study-right-entity.service';
import { UserSettingsEntityService } from 'sis-components/service/user-settings-entity.service';

@Component({
    selector: 'app-study-progress-graph',
    templateUrl: './study-progress-graph.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudyProgressGraphComponent implements OnInit {
    private readonly studyRightEntityService = inject(StudyRightEntityService);
    private readonly educationEntityService = inject(EducationEntityService);
    private readonly planEntityService = inject(PlanEntityService);
    private readonly appErrorHandler = inject(AppErrorHandler);
    private readonly userSettingsEntityService = inject(UserSettingsEntityService);
    private readonly localizedStringPipe = inject(LocalizedStringPipe);
    private readonly moduleEntityService = inject(ModuleEntityService);
    private readonly planLoaderService = inject(PlanLoaderService);
    private readonly studyProgressGraphResultService = inject(StudyProgressGraphResultService);

    private userSettings: UserSettings;
    private educations: Education[];
    private plans: Plan[];
    studyRights: StudyRight[];
    selectedStudyRightIdAndModuleId: SelectedStudyRightIdAndModuleId = {
        studyRightId: null,
        moduleId: null,
    };

    moduleValidationResults: { [id: string]: any } = {};

    studyRightIdOptions: Option[];
    selectedPlan: Plan;
    studyProgressGraphResults: StudyProgressGraphResults;

    private studyRightsByEducationId = new Map<OtmId, StudyRight[]>();
    private planByStudyRightId = new Map<OtmId, Plan>();
    private modulesByPlanId = new Map<OtmId, Module[]>();

    readonly ready = signal(false);
    readonly readyFetchingStudyProgress = signal(false);
    readonly searchFailed = signal(false);

    ngOnInit() {
        combineLatest([this.userSettingsEntityService.getOwnSettings(), this.getPlansByStudyRightAndEducation()])
            .pipe(
                take(1),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe({
                next: ([userSettings, fetchedStudyRightsEducationsAndPlans]) => {
                    this.userSettings = _.cloneDeep(userSettings);

                    this.studyRights = fetchedStudyRightsEducationsAndPlans.map(item => item?.sr).filter(Boolean);
                    this.educations = fetchedStudyRightsEducationsAndPlans.map(item => item?.education).filter(Boolean);
                    this.plans = fetchedStudyRightsEducationsAndPlans.map(item => item?.plan).filter(Boolean);

                    this.createStudyRightIdOptions();
                    this.ready.set(true);
                },
                error: () => {
                    this.ready.set(true);
                    this.searchFailed.set(true);
                },
            });
    }

    private getPlansByStudyRightAndEducation(): Observable<{ sr: StudyRight, education: Education, plan: Plan }[]> {
        return this.studyRightEntityService.getStudyRightsForCurrentUser()
            .pipe(
                switchMap((studyRights: StudyRight[]) => {
                    if (studyRights?.length) {
                        return combineLatest(studyRights.map(studyRight => this.educationsAndPlans(studyRight)));
                    }
                    return of([]);
                }));
    }

    private educationsAndPlans(studyRight: StudyRight): Observable<{ sr: StudyRight, education: Education, plan: Plan }> {
        return combineLatest([
            of(studyRight),
            this.getEducation(studyRight),
            this.getPlan(studyRight),
        ])
            .pipe(
                map(([sr, education, plan]) => ({ sr, education, plan })),
            );
    }

    private getEducation(studyRight: StudyRight): Observable<Education> {
        return this.educationEntityService.getById(studyRight.educationId)
            .pipe(
                tap(education => {
                    if (education) {
                        this.studyRightsByEducationId.get(education.id) ?
                            this.studyRightsByEducationId.set(education.id, [...this.studyRightsByEducationId.get(education.id), studyRight]) :
                            this.studyRightsByEducationId.set(education.id, [studyRight]);
                    }
                }),
            );
    }

    private getPlan(studyRight: StudyRight): Observable<Plan> {
        return this.planEntityService.getByUserIdAndEducationIdAndLearningOpportunityId(
            studyRight.educationId,
            studyRight.studentId,
            studyRight.learningOpportunityId)
            .pipe(
                map(plans => plans?.find(plan => plan.primary) ?? null),
                map(plan => this.filterDuplicatePlan(plan)),
                tap(plan => plan ? this.planByStudyRightId.set(studyRight.id, plan) : null),
                switchMap((plan: Plan) => combineLatest([
                    of(plan),
                    this.getModulesForPlan(plan),
                ])
                    .pipe(
                        map(([studyPlan]) => studyPlan),
                    )),
            );
    }

    // If plan already exists in the map, then don't add it anymore to be part of selectable options.
    // This can happen when plans are fetched with 'educationId' & 'studentId' & 'learningOpportunityId'.
    // Plan object doesn't have studyRight reference, it just assumes that, when conditions are met it relates to specific studies.
    private filterDuplicatePlan(plan: Plan): Plan {
        if (plan) {
            for (const [key, value] of this.planByStudyRightId) {
                if (value.id === plan.id) {
                    return null;
                }
            }
            return plan;
        }
        return null;
    }

    private getModulesForPlan(plan: Plan): Observable<void> {
        if (!plan) {
            return of(null);
        }

        // This will get all modules one level lower than root, e.g.:
        // education1:
        //   bachelorX  <- this
        //   mastersY <- this
        const moduleSelectionIds: string[] = plan.moduleSelections.filter(selection => selection?.parentModuleId === plan.rootId) // rootId = educationId
            .map(selection => selection.moduleId);

        return this.moduleEntityService.getByIds(moduleSelectionIds)
            .pipe(
                map((modules) => {
                    this.modulesByPlanId.set(plan.id, modules);
                }),
            );
    }

    private getSelectedStudyRightIdOption() {
        if (this.getAppStudyProgressGraphSettings() && this.getAppStudyProgressGraphSettings().studyRightId && this.getAppStudyProgressGraphSettings().moduleId) {
            this.selectedStudyRightIdAndModuleId = {
                studyRightId: this.getAppStudyProgressGraphSettings().studyRightId,
                moduleId: this.getAppStudyProgressGraphSettings().moduleId,
            };
        } else {
            this.getFirstExistingPlanAndModuleCombo();
        }
        this.getSelectedPlanForStudyRightAndModule();
    }

    private getFirstExistingPlanAndModuleCombo() {
        for (const studyRight of this.studyRights) {
            const plan = this.planByStudyRightId.get(studyRight.id);
            // There can be plan without modules declared underneath it, e.g.:
            // education1:
            //  <degreeProgrammeMissing>
            const moduleId = this.modulesByPlanId.get(plan?.id)?.[0]?.id;

            if (moduleId) {
                this.selectedStudyRightIdAndModuleId = {
                    studyRightId: studyRight.id,
                    moduleId,
                };
                break;
            }
        }
    }

    private createStudyRightIdOptions() {
        this.getSelectedStudyRightIdOption();
        this.studyRightIdOptions = [];

        this.studyRightsByEducationId.forEach((studyRights, educationIdKey) => {
            studyRights.forEach((studyRight, index) => {
                const plan = this.planByStudyRightId.get(studyRight.id);
                const modules = this.modulesByPlanId.get(plan?.id);

                if (plan && modules?.length) {
                    if (index === 0) {
                        this.createHeader(educationIdKey);
                    }

                    modules.forEach(module => {
                        this.createOption(studyRight, module);
                    });
                }
            });
        });
    }

    private createHeader(educationId: OtmId) {
        this.studyRightIdOptions.push({
            value: educationId,
            label: this.localizedStringPipe.transform(this.educations.find(edu => edu.id === educationId).name),
            header: true,
        });
    }

    private createOption(studyRight: StudyRight, module: Module) {
        const studyRightIdAndModuleIdValue: { studyRightId: string, moduleId: string } = {
            studyRightId: studyRight.id,
            moduleId: module.id,
        };

        this.studyRightIdOptions.push({
            value: studyRightIdAndModuleIdValue,
            label: this.localizedStringPipe.transform(module.name),
        });
    }

    onStudyRightChange(selectedStudyRightIdAndModuleId: SelectedStudyRightIdAndModuleId) {
        this.readyFetchingStudyProgress.set(false);
        this.userSettingsEntityService.getOwnSettings()
            .pipe(
                take(1),
                switchMap(settings => {
                    // When user navigates to frontpage first time, 'select-widgets.component#firstTimeOpenSave' saves default selections.
                    // This logic will trust, that StudyProgressFrontpageComponentSetting exists inside userSettings.componentSettings,
                    // when user does changes to moduleId selection.
                    this.userSettings = _.cloneDeep(settings);
                    this.getAppStudyProgressGraphSettings().studyRightId = selectedStudyRightIdAndModuleId.studyRightId;
                    this.getAppStudyProgressGraphSettings().moduleId = selectedStudyRightIdAndModuleId.moduleId;
                    return this.userSettingsEntityService.saveOwnSettings(this.userSettings);
                }),
                take(1),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe((userSettings: UserSettings) => {
                this.userSettings = _.cloneDeep(userSettings);
                this.selectedStudyRightIdAndModuleId = selectedStudyRightIdAndModuleId;

                this.getSelectedPlanForStudyRightAndModule();
            });
    }

    private getSelectedPlanForStudyRightAndModule() {
        const plan: Plan = this.planByStudyRightId.get(this.selectedStudyRightIdAndModuleId.studyRightId);
        const modules: Module[] = this.modulesByPlanId.get(plan?.id);
        const moduleSelection: Module = modules?.find(module => module.id === this.selectedStudyRightIdAndModuleId.moduleId);
        this.selectedPlan = moduleSelection ? plan : null;

        if (this.selectedPlan) {
            this.getValidatablePlanAndCountProgress(this.selectedPlan, moduleSelection);
        }
    }

    private getAppStudyProgressGraphSettings(): StudyProgressFrontpageComponentSetting {
        return this.userSettings?.componentSettings?.['app-study-progress-graph'] as StudyProgressFrontpageComponentSetting;
    }

    getValidatablePlanAndCountProgress(plan: Plan, module: Module) {
        this.planLoaderService.createValidatablePlan(plan.id, this.selectedStudyRightIdAndModuleId.studyRightId)
            .pipe(
                switchMap((validatablePlan: ValidatablePlan) => from(this.studyProgressGraphResultService.createResults(validatablePlan, module, true))),
                take(1),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe((studyProgressGraphResults: StudyProgressGraphResults) => {
                this.studyProgressGraphResults = studyProgressGraphResults;
                this.getModuleValidationResult(module);
                this.readyFetchingStudyProgress.set(true);
            });
    }

    getModuleValidationResult(module: Module) {
        this.moduleValidationResults = this.studyProgressGraphResults?.planValidationResult?.moduleValidationResults[module.id];
    }
}

export interface SelectedStudyRightIdAndModuleId {
    studyRightId: OtmId;
    moduleId: OtmId;
}
