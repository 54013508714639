import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ModalService } from 'sis-common/modal/modal.service';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { FeedbackModalComponent } from '../feedback-modal/feedback-modal.component';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-feedback-button',
    templateUrl: './feedback-button.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class FeedbackButtonComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.feedback-button.downgraded',
        directiveName: 'sisFeedbackButton',
    };

    @Input() buttonInDropdownMenu: boolean;

    constructor(private modalService: ModalService) { }

    public open() {
        return this.modalService.open(FeedbackModalComponent, null, { windowClass: 'feedback-modal', closeWithOutsideClick: true }).result;
    }
}
