<div class="completion-method-repeats">
    <p *ngFor="let repeatPossibility of displayableRepeatGroups; index as i">
        <span translate="STUDIES.COURSE_UNITS_COMPLETION_METHODS.COMPLETION_METHOD.TEACHING_TIMING" [translateParams]="{index: i + 1}"></span>
        <span class="period-indicator" *ngFor="let periodGroup of repeatPossibility">
            {{ periodGroup.startPeriod.name | localizedString }}
            <span *ngIf="periodGroup.startPeriod.defaultValid.endDate !== periodGroup.endPeriod.defaultValid.endDate">
                - {{ periodGroup.endPeriod.name | localizedString }}
            </span>
        </span>
    </p>
    <p class="guidance"
       *ngIf="!displayableRepeatGroups || displayableRepeatGroups.length === 0"
       translate="STUDIES.COURSE_UNITS_COMPLETION_METHODS.COMPLETION_METHOD.NO_STUDY_PERIOD_REPEAT">
    </p>
</div>
