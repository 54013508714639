import _ from 'lodash';
import angular from 'angular';
import moment from 'moment';
import { dateUtils } from 'common-typescript';
import { commonUniversityServiceModule } from 'sis-common/university/university.service.ts';
import { getNextStudyTermLocator, getStudyTermEndDate, getStudyTermLocator, getStudyTermsElapsedBetweenDates, getStudyTermStartDate } from '../study-terms/study-year-utils.ts';
import './studyPeriod.service';
export const commonStudyTermServiceModule = 'sis-components.service.studyTermService';
(function () {
  studyTermService.$inject = ["$q", "commonStudyPeriodService", "universityService"];
  angular.module(commonStudyTermServiceModule, ['sis-components.service.studyPeriodService', commonUniversityServiceModule]).factory('commonStudyTermService', studyTermService);

  /**
   * @ngInject
   */
  function studyTermService($q, commonStudyPeriodService, universityService) {
    return {
      getEditableStudyYearsAndTerms,
      getStudyYearsAndTerms,
      getStudyTermsElapsedBetweenDates,
      getStudyTermLocator,
      getNextStudyTermLocator,
      getTermStartDate: getStudyTermStartDate,
      getTermEndDate: getStudyTermEndDate,
      groupAttainedCreditsByStudyTerm,
      /**
       * Returns StudyTermLocator for today
       */
      getCurrentStudyTermLocator(universityOrgId) {
        return commonStudyPeriodService.getCurrentStudyTermLocator(universityOrgId);
      }
    };
    function countTotalCredits(attainments) {
      return attainments.reduce((sum, {
        credits
      }) => sum + (credits || 0), 0);
    }

    /**
     * Returns study years and terms whose term registrations can be currently edited for the given study right.
     * I.e. returns all study rights (and their corresponding study terms) starting from the study right validity
     * start date, up until EITHER 1) the current study year and the next upcoming one, OR 2) the study right
     * validity end date (whichever comes first). NOTE: Study terms that are filtered out are replaced with nulls
     * instead of being removed from the study year, as otherwise it would be impossible to match term
     * registrations to the corresponding study terms.
     */
    function getEditableStudyYearsAndTerms(studyRight) {
      const validityStart = moment(_.get(studyRight, 'valid.startDate'));
      const validityEnd = _.has(studyRight, 'valid.endDate') ? moment(studyRight.valid.endDate) : null;
      let endBoundary = moment().add(1, 'years').add(1, 'days');
      if (validityEnd && validityEnd.isValid()) {
        endBoundary = moment.min(endBoundary, validityEnd);
      }
      if (validityStart.isValid()) {
        const fetchStudyYearsFrom = validityStart.year() - 1;
        const fetchStudyYearsUntil = endBoundary.year();
        if (fetchStudyYearsFrom < fetchStudyYearsUntil) {
          const universityId = universityService.getCurrentUniversityOrgId();
          const numYears = fetchStudyYearsUntil - fetchStudyYearsFrom + 1;
          return commonStudyPeriodService.getStudyYears(universityId, fetchStudyYearsFrom, numYears).then(studyYears => filterStudyYearsAndTerms(_.cloneDeep(studyYears), validityStart, endBoundary));
        }
      }
      return $q.when([]);
    }

    /**
     * Returns the study years and study terms for the given study year start years. Fetches the study years with a single API request
     * that includes all study years between the first and the last of the given years; i.e. a continuous range is always returned.
     *
     * @param {number[]} studyYearStartYears array of study year start years
     * @return {Promise<StudyYear[]>} Promise that resolves to a list of study years and their study terms
     */
    function getStudyYearsAndTerms(studyYearStartYears) {
      if (!studyYearStartYears || studyYearStartYears.length === 0) {
        return $q.when([]);
      }
      const universityId = universityService.getCurrentUniversityOrgId();
      const firstYear = Math.min(...studyYearStartYears);
      const lastYear = Math.max(...studyYearStartYears);
      return commonStudyPeriodService.getStudyYears(universityId, firstYear, lastYear - firstYear + 1);
    }

    /**
     * Filter out all study years and study terms that end at or before the startBoundary, or that start at or
     * after the endBoundary. For study terms, filtering out means replacing with nulls (as the array length is
     * relevant for matching study terms to term registrations).
     */
    function filterStudyYearsAndTerms(studyYears, startBoundary, endBoundary) {
      _.remove(studyYears, studyYear => moment(studyYear.valid.endDate).isSameOrBefore(startBoundary, 'day') || moment(studyYear.valid.startDate).isSameOrAfter(endBoundary, 'day'));
      _.forEach(studyYears, (studyYear, i) => {
        if (i === 0 || i === studyYears.length - 1) {
          // The study term filtering only needs to be done for the first and last study years
          _.forEach(studyYear.studyTerms, (studyTerm, termIndex) => {
            if (_.isObject(studyTerm) && (moment(studyTerm.valid.endDate).isSameOrBefore(startBoundary, 'day') || moment(studyTerm.valid.startDate).isSameOrAfter(endBoundary, 'day'))) {
              studyYear.studyTerms[termIndex] = null;
            }
          });
        }
      });
      return studyYears;
    }

    /**
     * Groups attained credits from the given attainments by the study term when they were attained. Each entry also
     * contains the term registration for the corresponding study term.
     *
     * <code>fromDate</code> and <code>untilDate</code> control which study terms to fetch; the results will contain study
     * terms from / until the one ongoing on <code>fromDate</code> / <code>untilDate</code> (respectively). Credits attained
     * before the first study term are summed up and included in their own entry without study term or term registration info,
     * while credits attained after the last term are ignored.
     *
     * Example:
     * <pre>
     * [
     *   {
     *     studyTerm: {...},
     *     termRegistration: {...},
     *     credits: 29
     *   },
     *   ...
     * ]
     * </pre>
     *
     * @param {Array<Attainment>} attainments The attainments to group
     * @param {moment.Moment | string} fromDate Defines the first study term to fetch (the one ongoing on this date)
     * @param {moment.Moment | string} [untilDate] Defines the last study term to fetch (the on ongoing on this date)
     * @param {Array<TermRegistration>} [termRegistrations] The student's term registrations to map with the study terms
     * @returns {Array<Object>}
     */
    function groupAttainedCreditsByStudyTerm(attainments, fromDate, untilDate = moment(), termRegistrations = []) {
      if (!_.isArray(attainments) || _.isEmpty(attainments)) {
        return $q.when([]);
      }
      untilDate = _.isNil(untilDate) || !moment(untilDate).isValid() ? moment() : moment(untilDate);
      const universityOrgId = universityService.getCurrentUniversityOrgId();
      const fromYear = moment(fromDate).year();
      const firstYear = fromYear - 1;
      const numYears = untilDate.year() - fromYear + 2;
      return commonStudyPeriodService.getStudyYears(universityOrgId, firstYear, numYears).then(studyYears => {
        const termsAndRegistrations = studyYears.map(({
          studyTerms,
          startYear
        }) => studyTerms.map((studyTerm, index) => ({
          studyTerm,
          termRegistration: termRegistrations.find(reg => reg.studyTerm.studyYearStartYear === startYear && reg.studyTerm.termIndex === index)
        }))).reduce((entries, entry) => entries.concat(entry)) // Flatten the nested arrays
        .filter(({
          studyTerm: {
            valid: {
              startDate,
              endDate
            }
          }
        }) => dateUtils.dateRangesOverlap(startDate, endDate, fromDate, untilDate)).sort((entry1, entry2) => moment(entry1.studyTerm.valid.startDate).diff(moment(entry2.studyTerm.valid.startDate)));
        const firstTermStartDate = moment(_.first(termsAndRegistrations).studyTerm.valid.startDate);
        const attainmentsBeforeFirstTerm = attainments.filter(({
          attainmentDate
        }) => moment(attainmentDate).isBefore(firstTermStartDate, 'day'));
        const results = attainmentsBeforeFirstTerm.length > 0 ? [{
          studyTerm: {},
          termRegistration: undefined,
          credits: countTotalCredits(attainmentsBeforeFirstTerm)
        }] : [];
        return results.concat(termsAndRegistrations.map(({
          studyTerm,
          termRegistration
        }) => {
          const {
            startDate,
            endDate
          } = studyTerm.valid;
          const attainmentsForTerm = attainments.filter(({
            attainmentDate
          }) => moment(attainmentDate).isBetween(startDate, endDate, 'day', '[)'));
          return _.omitBy({
            studyTerm,
            termRegistration,
            credits: countTotalCredits(attainmentsForTerm)
          }, _.isNil);
        }));
      });
    }
  }
})();