import { commonPlanServiceModule } from 'sis-components/service/plan.service';
import { IconComponent } from 'sis-components/icon/icon.component.ts';
import { courseUnitRealisationModule } from '../../common/components/course-unit-realisation/courseUnitRealisation.component';
import unplannedTpl from './unplanned.tpl.html';
import '../selectCourseUnitAndCompletionMethodModal';
'use strict';
(function () {
  UnplannedController.$inject = ["unplannedService", "uiContext", "$log", "courseUnitModel", "commonCourseUnitService", "courseUnitService", "planContextEventType", "courseUnitInfoModal", "selectCourseUnitAndCompletionMethodModal", "uiContextEventType", "$scope", "courseUnitRealisationJSDataModel", "enrolmentServiceEventType", "planServiceEventType", "$state", "validAttainmentFilterService", "attainmentModel", "defaultPromiseHandler", "$timeout", "$anchorScroll"];
  angular.module('student.plan.unplanned', ['pascalprecht.translate', 'sis-components.string.sortByLocalizedValueFilter', 'sis-components.service.courseUnitService', 'student.common.service.courseUnitService', 'student.common.context.uiContext', 'student.common.context.plan-context', 'student.common.service.planService', 'student.common.model.courseUnit', 'student.common.model.attainment', 'sis-components.model.courseUnitRealisation', 'sis-components.model.enrolment', 'student.common.components.courseUnitBox', courseUnitRealisationModule, 'student.plan.unplanned.courseUnitRealisation', 'student.plan.selectCourseUnitAndCompletionMethodModal', commonPlanServiceModule, 'student.plan.unplannedService', 'sis-components.service.validAttainmentFilterService', 'sis-common.errorhandler.errorService', IconComponent.downgrade.moduleName]).component('unplanned', {
    bindings: {
      isOpen: '<',
      matchingStudyRight: '<',
      unplannedCount: '=' // this component is modifying outside scope which is not good
    },

    controller: UnplannedController,
    template: unplannedTpl
  });
  function UnplannedController(
  // NOSONAR
  unplannedService, uiContext, $log, courseUnitModel, commonCourseUnitService, courseUnitService, planContextEventType, courseUnitInfoModal, selectCourseUnitAndCompletionMethodModal, uiContextEventType, $scope, courseUnitRealisationJSDataModel, enrolmentServiceEventType, planServiceEventType, $state, validAttainmentFilterService, attainmentModel, defaultPromiseHandler, $timeout, $anchorScroll) {
    const $ctrl = this;
    const unplannedState = {};
    $ctrl.$onInit = function () {
      $ctrl.refreshCollections();
      $scope.$on(planContextEventType.planBuilt, $ctrl.refreshCollections);
      $scope.$on(uiContextEventType.planContextSet, $ctrl.refreshCollections);
      $scope.$on(enrolmentServiceEventType.updated, $ctrl.resetCollections);
      $scope.$on(planServiceEventType.planSaved, $ctrl.resetCollections);
      $scope.$on('courseCartChanged', $ctrl.resetCollections);
    };
    $ctrl.getValidatablePlan = function () {
      return _.get(uiContext, 'planContext.validatablePlan');
    };
    $ctrl.resetCollections = function () {
      return updateCollections();
    };
    $ctrl.refreshCollections = function () {
      return updateCollections(unplannedState.unfiltered);
    };
    function updateCollections(unfiltered) {
      return unplannedService.getUpdatedCollections(unfiltered).then(results => {
        unplannedState.unfiltered = results.unfiltered;
        unplannedState.filtered = results.filtered;
        $ctrl.unplannedCount = $ctrl.totalUnplannedCount();
        if ($state.params.unplannedCourseUnitId) {
          $timeout(() => {
            $anchorScroll(`anchor${$state.params.unplannedCourseUnitId}`);
            const unplannedCourse = _.find($ctrl.unplannedCourseUnitRealisations(), cur => $ctrl.getEnrolmentForCourseUnitRealisation(cur.id).courseUnitId === $state.params.unplannedCourseUnitId);
            $ctrl.activate(unplannedCourse);
          });
        }
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    }
    $ctrl.totalUnplannedCount = function () {
      let unplanned = 0;
      unplanned += _.size($ctrl.unplannedInCourseCart());
      unplanned += _.size($ctrl.unplannedWithAttainments());
      unplanned += _.size($ctrl.unplannedCourseUnitRealisations());
      unplanned += _.size($ctrl.unplannedCustomCourseUnitAttainments());
      return unplanned;
    };
    $ctrl.unplannedInCourseCart = function () {
      return _.get(unplannedState, 'filtered.courseCart');
    };
    $ctrl.unplannedWithAttainments = function () {
      return _.get(unplannedState, 'filtered.attainedCourseUnits');
    };
    $ctrl.unplannedCustomCourseUnitAttainments = function () {
      const studyRightId = _.get($ctrl.matchingStudyRight, 'id');
      const attainments = _.get(unplannedState, 'filtered.customCourseUnitAttainments');
      return _.filter(attainments, attainment =>
      // Consider attainments without studyRightId as not bound to any study right and include them
      !attainment.studyRightId || attainment.studyRightId === studyRightId);
    };
    $ctrl.unplannedCourseUnitRealisations = function () {
      return _.get(unplannedState, 'filtered.courseUnitRealisations');
    };

    // Cache the custom attainment expiry dates, as calculating them in each digest loop is expensive
    $ctrl.customCourseUnitAttainmentExpiryDates = {};
    $ctrl.getCustomCourseUnitAttainmentExpiryDate = function (attainment) {
      if ($ctrl.customCourseUnitAttainmentExpiryDates.hasOwnProperty(attainment.id)) {
        return $ctrl.customCourseUnitAttainmentExpiryDates[attainment.id];
      }
      if (validAttainmentFilterService.isAttainmentAboutToExpire(attainment)) {
        const {
          expiryDate
        } = attainment;
        $ctrl.customCourseUnitAttainmentExpiryDates[attainment.id] = expiryDate;
        return moment(expiryDate);
      }
      return null;
    };
    $ctrl.getEnrolmentForCourseUnitRealisation = function (courseUnitRealisationId) {
      return _.get(_.get(unplannedState, 'unfiltered.enrolmentsByCourseUnitRealisationId'), courseUnitRealisationId);
    };
    $ctrl.isActive = function (target) {
      if (target instanceof courseUnitRealisationJSDataModel.CourseUnitRealisation) {
        const cur = uiContext.unplannedCourseUnitRealisation;
        return cur && cur.id === target.id;
      }
      if (target instanceof attainmentModel.CustomCourseUnitAttainment) {
        return !!_.find(uiContext.unplannedCustomCourseUnitAttainments, {
          id: target.id
        });
      }
      return !!_.find(uiContext.unplannedCourseUnits, {
        id: target.id
      });
    };
    $ctrl.someOtherIsActive = function (target) {
      if (uiContext.unplannedCourseUnitRealisation === undefined && _.isEmpty(uiContext.unplannedCourseUnits) && _.isEmpty(uiContext.unplannedCustomCourseUnitAttainments)) {
        return false;
      }
      if (target instanceof courseUnitRealisationJSDataModel.CourseUnitRealisation) {
        return target.id !== _.get(uiContext, 'unplannedCourseUnitRealisation.id');
      }
      if (target instanceof attainmentModel.CustomCourseUnitAttainment) {
        return !_.find(uiContext.unplannedCustomCourseUnitAttainments, {
          id: target.id
        });
      }
      return !_.find(uiContext.unplannedCourseUnits, {
        id: target.id
      });
    };
    $ctrl.isAttainmentAlreadyAttached = function (courseUnit) {
      if (!$ctrl.getValidatablePlan()) {
        return false;
      }
      const attainment = $ctrl.getValidatablePlan().getCourseUnitAttainment(_.get(courseUnit, 'id'));
      return !!attainment && validAttainmentFilterService.isAttached(attainment, _.get(unplannedState, 'unfiltered.allAttainments'));
    };
    function placeCourseUnitIntoPlan(enrolmentCourseUnitId, courseUnits, target) {
      const courseUnitIdsToBePlanned = enrolmentCourseUnitId ? [enrolmentCourseUnitId] : _.map(courseUnits, 'id');
      // Note that uiContext and planContext still use old models, not js-data
      return courseUnitService.findByIds(courseUnitIdsToBePlanned).then(courseUnitsToBePlanned => uiContext.planCourseUnitRealisation(target, courseUnitsToBePlanned));
    }
    function openCompletionMethodFor(courseUnit, target) {
      return courseUnitInfoModal.showCourseUnitRealisationForCourseUnit(courseUnit.id, target, $ctrl.getValidatablePlan());
    }
    $ctrl.activate = function (target) {
      if (!target) {
        $ctrl.deActivate();
        return;
      }
      if (target instanceof courseUnitModel.CourseUnit) {
        uiContext.planCourseUnit(target);
      } else if (target instanceof attainmentModel.CustomCourseUnitAttainment) {
        uiContext.planCustomCourseUnitAttainment(target);
      } else if (target instanceof courseUnitRealisationJSDataModel.CourseUnitRealisation) {
        uiContext.unplannedCourseUnits = [];
        return commonCourseUnitService.findAssociatedCourseUnitsForCourseUnitRealisation(target, false).then(availableTargetCourseUnits => {
          const matchingCourseUnitsInPlan = _.intersectionBy(availableTargetCourseUnits, uiContext.planContext.getPlannedCourseUnits(), 'id');
          const enrolment = $ctrl.getEnrolmentForCourseUnitRealisation(target.id);
          const enrolmentCourseUnitId = _.get(enrolment, 'courseUnitId');
          if (matchingCourseUnitsInPlan.length === 1 && availableTargetCourseUnits.length === 1) {
            // If only one option and CU already in plan, just open completion method tab directly
            return openCompletionMethodFor(matchingCourseUnitsInPlan[0], target);
          }
          if (availableTargetCourseUnits.length === 1) {
            // If exactly one possible target course unit, place it into plan
            return placeCourseUnitIntoPlan(enrolmentCourseUnitId, availableTargetCourseUnits, target);
          }
          // In every other case, provide a selection modal containing all possible target course units
          return openCourseUnitSelectionModalFor(availableTargetCourseUnits, target, matchingCourseUnitsInPlan, enrolmentCourseUnitId);
        });
      } else {
        $log.warn('Trying to activate unrecognised target.', target);
      }
    };
    function openCourseUnitSelectionModalFor(availableTargetCourseUnits, target, matchingCourseUnitsInPlan, enrolmentCourseUnitId) {
      return selectCourseUnitAndCompletionMethodModal.open(availableTargetCourseUnits, $ctrl.getValidatablePlan(), target, matchingCourseUnitsInPlan, enrolmentCourseUnitId).then(selectedTargetCourseUnit => {
        const selectedTargetCourseUnitId = _.get(selectedTargetCourseUnit, 'id');
        if (selectedTargetCourseUnitId) {
          if (_.some(matchingCourseUnitsInPlan, ['id', selectedTargetCourseUnitId])) {
            return openCompletionMethodFor(selectedTargetCourseUnit, target);
          }
          return placeCourseUnitIntoPlan(selectedTargetCourseUnitId, availableTargetCourseUnits, target);
        }
      });
    }
    $ctrl.deActivate = function () {
      uiContext.cancelPlanningOfCourseUnit();
    };
  }
})();