readMoreModalService.$inject = ["modalService"];
import angular from 'angular';
import { ModalService } from 'sis-common/modal/modal.service.ts';
import { ReadMoreModalComponent } from './readMoreModal.component.ts';
import readMoreModalTpl from './readMoreModal.tpl.html';
angular.module('sis-components.readMoreModal', [ModalService.downgrade.moduleName]).factory('readMoreModal', readMoreModalService).component('readMoreModal', {
  bindings: {
    close: '&',
    resolve: '<'
  },
  template: readMoreModalTpl
});
function readMoreModalService(modalService) {
  return {
    open: options => modalService.open(ReadMoreModalComponent, {
      options
    }, {
      closeWithOutsideClick: true,
      size: 'sm'
    }).result.then(angular.noop, angular.noop)
  };
}