import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ValidatablePlan } from 'common-typescript/src/plan/validation/validatablePlan';
import { CourseUnit, Plan } from 'common-typescript/types';
import { catchError, from, Observable, of, take } from 'rxjs';
import { DEFAULT_PROMISE_HANDLER } from 'sis-common/ajs-upgraded-modules';
import { ModalService } from 'sis-common/modal/modal.service';
import { COMMON_PLAN_SERVICE } from 'sis-components/ajs-upgraded-modules';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { convertAJSPromiseToNative } from 'sis-components/util/utils';

import { COURSE_UNIT_INFO_MODAL } from '../../ajs-upgraded-modules';
import { StudentPlanOutdatedCourseUnitsService } from '../../common/service/student-plan-outdated-course-units.service';

import { OutdatedCourseUnitsModalValues } from './outdated-course-units-modal.service';

// This component is used to display a modal that shows the outdated course units in the student plan.
// TODO: This component should be refactored to use validatablePlanSubject when it is available.
@Component({
    selector: 'app-outdated-course-units-modal',
    templateUrl: './outdated-course-units-modal.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OutdatedCourseUnitsModalComponent {

    validatablePlan$: Observable<ValidatablePlan>;

    constructor(@Inject(ModalService.injectionToken) public values: OutdatedCourseUnitsModalValues,
                @Inject(COURSE_UNIT_INFO_MODAL) private courseUnitInfoModal: any,
                @Inject(DEFAULT_PROMISE_HANDLER) private defaultPromiseHandler: any,
                @Inject(COMMON_PLAN_SERVICE) private commonPlanService: any,
                private studentPlanOutdatedCourseUnitsService: StudentPlanOutdatedCourseUnitsService,
                private readonly changeDetectorRef: ChangeDetectorRef,
                private appErrorHandlingService: AppErrorHandler,
                public modal: NgbActiveModal) {
    }

    openCourseUnitInfoModal(courseUnit: CourseUnit) {
        this.courseUnitInfoModal.showCourseUnit(
            courseUnit.id,
            this.values.validatablePlan,
        ).then((curModified: boolean) => {
            // if the course unit was modified somehow, we need to get updated validatablePlan and outdatedCourseUnits
            if (curModified) {
                this.validatablePlan$ = this.getUpdatedValidatablePlan();
                this.validatablePlan$
                    .pipe(take(1))
                    .subscribe((validatablePlan: ValidatablePlan) => {
                        this.values.validatablePlan = validatablePlan;
                        this.getUpdatedOutdatedCourseUnits();
                    });
            }
        }).catch(this.defaultPromiseHandler.loggingRejectedPromiseHandler);
    }

    getUpdatedValidatablePlan(): Observable<ValidatablePlan> {
        return from(convertAJSPromiseToNative(this.commonPlanService.findById(this.values.validatablePlan.plan.id).then((plan: Plan) => this.commonPlanService.getValidatablePlan(plan))))
            .pipe(catchError((err) => {
                this.appErrorHandlingService.handleError(err);
                return of(null);
            })) as Observable<ValidatablePlan>;
    }

    getUpdatedOutdatedCourseUnits() {
        this.studentPlanOutdatedCourseUnitsService.checkLatestCourseUnitsAreInSelectedPlan(this.values.validatablePlan).pipe(
            take(1),
            this.appErrorHandlingService.defaultErrorHandler(),
        )
            .subscribe((outdatedCourseUnits: [CourseUnit, CourseUnit][]) => {
                this.values.courseUnits = outdatedCourseUnits;
                // manually fire change detection as it seemed slow when using in angularjs
                this.changeDetectorRef.markForCheck();
            });
    }
}
