<ng-container *ngIf="planRuleData.ruleModuleVersionsByGroupId[rule.moduleGroupId] as module">
  <ng-container *ngIf="module.type === 'StudyModule'">
    <sis-plan-structure-study-module-box [module]="$any(module)"
                                         [planStateObject]="planStateObject"
                                         [planData]="planData"
                                         [headingLevel]="headingLevel">
    </sis-plan-structure-study-module-box>
  </ng-container>
  <ng-container *ngIf="module.type === 'DegreeProgramme'">
    <sis-plan-structure-degree-programme-box [module]="$any(module)"
                                         [planStateObject]="planStateObject"
                                         [planData]="planData"
                                         [headingLevel]="headingLevel">
    </sis-plan-structure-degree-programme-box>
  </ng-container>
</ng-container>
