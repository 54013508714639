import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxFudisModule } from '@funidata/ngx-fudis';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { SisCommonModule } from 'sis-common/sis-common.module';
import { SisComponentsModule } from 'sis-components/sis-components.module';

import { StudentCommonModule } from '../common/common.module';
import {
    CourseUnitRealisationDirective,
} from '../common/components/course-unit-realisation/course-unit-realisation.dir.upgraded';
import { EnrolmentModule } from '../common/components/enrolment/enrolment.module';

import { FrontpageBetaComponent } from './frontpage-beta/frontpage-beta.component';
import { SelectWidgetsDropdownComponent } from './frontpage-beta/select-widgets/select-widgets-dropdown.component';
import { SelectWidgetsComponent } from './frontpage-beta/select-widgets/select-widgets.component';
import { FrontpageWrapperComponent } from './frontpage-wrapper.component';
import { FrontpageDirective } from './frontpage.component.upgraded';
import { frontpageRoutes } from './frontpage.route';
import { MyStudiesComponent } from './my-studies/my-studies.component';
import { StudentFrontpageMessageConversationsComponent } from './student-frontpage-message-conversations/student-frontpage-message-conversations.component';
import { StudentTermRegistrationPeriodInfoComponent } from './student-term-registration-period-info/student-term-registration-period-info.component';
import { StudyProgressGraphDropdownComponent } from './studyProgressGraph/study-progress-graph-dropdown/study-progress-graph-dropdown.component';
import { StudyRightExtensionRequirementsModalComponent } from './studyRightExtensionRequirementsModal/studyRightExtensionRequirementsModal.component';
import { StudyRightExtensionRequirementsModalDirective } from './studyRightExtensionRequirementsModal/studyRightExtensionRequirementsModal.dir.upgraded';
import { ActiveStudiesComponent } from './widgets/active-studies/active-studies.component';
import { AttainmentInfoComponent } from './widgets/recent-attainments/attainment-info/attainment-info.component';
import { RecentAttainmentsComponent } from './widgets/recent-attainments/recent-attainments.component';
import { StudyProgressGraphComponent } from './widgets/study-progress-graph/study-progress-graph.component';
import { UpcomingStudiesComponent } from './widgets/upcoming-studies/upcoming-studies.component';
import { WidgetContainerComponent } from './widgets/widget-container/widget-container.component';

@NgModule({
    declarations: [
        FrontpageBetaComponent,
        FrontpageDirective,
        FrontpageWrapperComponent,
        StudentFrontpageMessageConversationsComponent,
        StudyProgressGraphDropdownComponent,
        StudyRightExtensionRequirementsModalComponent,
        StudyRightExtensionRequirementsModalDirective,
        StudentTermRegistrationPeriodInfoComponent,
        MyStudiesComponent,
        CourseUnitRealisationDirective,
        RecentAttainmentsComponent,
        AttainmentInfoComponent,
        WidgetContainerComponent,
        SelectWidgetsComponent,
        SelectWidgetsDropdownComponent,
        ActiveStudiesComponent,
        StudyProgressGraphComponent,
        UpcomingStudiesComponent,
    ],
    imports: [
        CommonModule,
        NgbDropdownModule,
        SisCommonModule,
        SisComponentsModule,
        StudentCommonModule,
        TranslateModule.forChild(),
        UIRouterUpgradeModule.forChild({
            states: frontpageRoutes,
        }),
        ReactiveFormsModule,
        EnrolmentModule,
        NgxFudisModule,
    ],
})
export class FrontpageModule {}
