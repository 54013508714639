<ng-container *ngIf="moduleOrEducationStateObject.type === 'EducationStateObject' ||
(moduleOrEducationStateObject.type === 'ModuleStateObject' && !moduleOrEducationStateObject.attainmentId)">
  <ul *ngIf="!isImplicit"
      class="list-group-horizontal row sis-plan-structure-module-content-list">
    <li *ngFor="let courseUnitId of moduleOrEducationStateObject.selectedCourseUnitIds"
        class="list-group-item col-sm-12 col-md-6 col-lg-4">
      <div class="sis-mb-sm sis-mr-sm">
        <sis-plan-structure-course-unit *ngIf="!planStateObject.courseUnits[courseUnitId].isSubstituted"
                                        [courseUnit]="planData.courseUnitsById[courseUnitId]"
                                        [planStateObject]="planStateObject"
                                        [planData]="planData"
                                        [headingLevel]="headingLevel">
        </sis-plan-structure-course-unit>
        <ng-container *ngIf="planStateObject.courseUnits[courseUnitId].isSubstituted">
          <sis-plan-structure-course-unit-substitutions
            [substitutedCourseUnit]="planData.courseUnitsById[courseUnitId]"
            [headingLevel]="headingLevel"
            [planValidationResult]="planValidationResult"
            [planStateObject]="planStateObject"
            [planData]="planData"></sis-plan-structure-course-unit-substitutions>
        </ng-container>
      </div>
    </li>
    <li *ngFor="let customStudyDraftId of moduleOrEducationStateObject.selectedCustomStudyDraftIds"
        class="list-group-item col-sm-12 col-md-6 col-lg-4">
      <div class="sis-mb-sm sis-mr-sm">
        <sis-plan-structure-custom-study-draft
          [customStudyDraft]="planData.customStudyDraftsById[customStudyDraftId]"
          [planStateObject]="planStateObject"
          [headingLevel]="headingLevel">
        </sis-plan-structure-custom-study-draft>
      </div>
    </li>
    <li
      *ngFor="let customCourseUnitAttainmentId of moduleOrEducationStateObject.selectedCustomCourseUnitAttainmentIds"
      class="list-group-item col-sm-12 col-md-6 col-lg-4">
      <div class="sis-mb-sm sis-mr-sm">
        <sis-plan-structure-custom-course-unit-attainment
          [customCourseUnitAttainment]="getCustomCourseUnitAttainment(customCourseUnitAttainmentId)"
          [planStateObject]="planStateObject"
          [headingLevel]="headingLevel">
        </sis-plan-structure-custom-course-unit-attainment>
      </div>
    </li>
  </ul>
  <sis-plan-structure-module *ngFor="let moduleId of moduleOrEducationStateObject.selectedModuleIds"
                             [module]="getModule(moduleId)"
                             [planValidationResult]="planValidationResult"
                             [planStateObject]="planStateObject"
                             [planData]="planData"
                             [isImplicit]="false"
                             [headingLevel]="headingLevel"></sis-plan-structure-module>
  <sis-plan-structure-custom-module-attainment
    *ngFor="let customModuleAttainmentId of moduleOrEducationStateObject.selectedCustomModuleAttainmentIds"
    [customModuleAttainment]="getCustomModuleAttainment(customModuleAttainmentId)"
    [planStateObject]="planStateObject"
    [planData]="planData"
    [headingLevel]="headingLevel">
  </sis-plan-structure-custom-module-attainment>
</ng-container>
<sis-plan-structure-attainment-node-structure
  *ngIf="moduleOrEducationStateObject.type === 'ModuleStateObject'
  && moduleOrEducationStateObject.attainmentId"
  [attainmentNodes]="getModuleAttainment(moduleOrEducationStateObject.attainmentId)?.nodes"
  [planStateObject]="planStateObject"
  [planData]="planData"
  [headingLevel]="headingLevel">
</sis-plan-structure-attainment-node-structure>
