import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input, OnChanges,
    OnInit,
    Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ValidatablePlan } from 'common-typescript/src/plan/validation/validatablePlan';
import {
    Attainment,
    CourseUnit,
    OtmId,
} from 'common-typescript/types';
import _ from 'lodash';
import { SisFormBuilder } from 'sis-components/form/sis-form-builder.service';
import { OrganisationNameService } from 'sis-components/organisation/organisation-name.service';
import { AttainmentEntityService } from 'sis-components/service/attainment-entity.service';
import { UniversityService } from 'sis-components/service/university.service';

import {
    CourseUnitSubstitutionWithLocalId,
} from '../course-unit-info-modal-substitution-correspondences/course-unit-info-modal-substitution-correspondences.component';

interface SubstitutionForm {
    selectedSubstitutionsLocalId: FormControl<string>;
}

@Component({
    selector: 'app-course-unit-info-modal-substitutions-correspondences-select-form',
    templateUrl: './course-unit-info-modal-substitutions-correspondences-select-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseUnitInfoModalSubstitutionsCorrespondencesSelectFormComponent implements OnInit, OnChanges {

    @Input() courseUnit: CourseUnit;
    @Input() validatablePlan: ValidatablePlan;
    @Input() versionChangeInProgress: boolean;
    @Input() isLoggedIn: boolean;
    @Input() selected: OtmId;
    @Input() substitutionOptions: CourseUnitSubstitutionWithLocalId[];
    @Input() substituteCourseUnitsByGroupId: { [key: OtmId]: CourseUnit };
    @Input() attainmentsByCourseUnitGroupId: { [key: OtmId]: Attainment };
    @Input() allAttainments: Attainment[];
    @Output() selectSubstitution = new EventEmitter<Partial<{ selectedSubstitutionsLocalId: string }>>();
    @Output() openCourseUnitInfoModal = new EventEmitter<OtmId>();

    form: FormGroup<SubstitutionForm>;
    hasSubstitutes: boolean;
    isSubstitute: boolean;
    isEditable: boolean;
    showNoEquivalentCourseRadioButton: boolean;
    showRadioButtonInfo: boolean;
    private currentUniversityOrgId: string;
    universityName: string;
    constructor(
        private fb: SisFormBuilder,
        private attainmentEntityService: AttainmentEntityService,
        private universityService: UniversityService,
        private organisationNameService: OrganisationNameService,
    ) {

    }

    ngOnInit() {
        this.form = this.buildForm();
        this.form.valueChanges.subscribe(localId => this.selectSubstitution.emit(localId));
        this.updateHasSubstitutes();
        const courseUnitSelectionWithAttainment = this.validatablePlan ? this.validatablePlan.getCourseUnitSelection(this.courseUnit.id) : undefined;
        this.isSubstitute = courseUnitSelectionWithAttainment && !_.isEmpty(_.get(courseUnitSelectionWithAttainment, 'substituteFor', []));
        this.currentUniversityOrgId = this.universityService.getCurrentUniversityOrgId();

        this.isEditable = this.validatablePlan?.plan &&
            this.isLoggedIn &&
            this.validatablePlan.isCourseUnitInPlan(this.courseUnit) &&
            !this.validatablePlan.isCourseUnitAttained(_.get(this.courseUnit, 'id'));

        this.updateNoEquivalentCourseRadioButtonVisibility();
        this.updateShowRadioButtonInfo();
        this.updateUniversityName();
    }

    ngOnChanges() {
        this.updateHasSubstitutes();
        this.updateNoEquivalentCourseRadioButtonVisibility();
        this.updateShowRadioButtonInfo();
    }

    private updateHasSubstitutes() {
        this.hasSubstitutes = !_.isEmpty(this.courseUnit.substitutions) || !_.isEmpty(_.get(this.courseUnit, 'equivalentCoursesInfo'));
    }

    private updateUniversityName() {
        this.universityName = null;
        if (this.courseUnit.universityOrgIds.length > 0) {
            this.organisationNameService.getOrgName(this.courseUnit.universityOrgIds[0]).subscribe((name: string) => {
                this.universityName = name;
            });
        }
    }

    private updateShowRadioButtonInfo() {
        this.showRadioButtonInfo =
            this.courseUnit.universityOrgIds.includes(this.universityService.getCurrentUniversityOrgId())
            && _.some(this.substitutionOptions, substitutionOption => this.showRadioButtonFor(substitutionOption));
    }

    buildForm(): FormGroup {
        return this.fb.group({
            selectedSubstitutionsLocalId: this.fb.control(this.selected ? this.selected : null),
        });
    }

    attainmentAlreadyAttached(substitutionOption: CourseUnitSubstitutionWithLocalId) {
        return _.some(substitutionOption.substitution, (courseUnitSubstitution) => {
            const attainment = _.get(this.attainmentsByCourseUnitGroupId, courseUnitSubstitution.courseUnitGroupId);
            return !!attainment && this.attainmentEntityService.isAttached(attainment, this.allAttainments);
        });
    }

    substituteCourseUnitAlreadyInPlan(substitutionOption: CourseUnitSubstitutionWithLocalId) {
        return _.some(substitutionOption.substitution, (courseUnitSubstitution) => {
            const courseUnitInPlan = this.validatablePlan.getCourseUnitInPlanByGroupId(courseUnitSubstitution.courseUnitGroupId);
            return !!courseUnitInPlan && !this.validatablePlan.isCourseUnitInPlanAsSubstitute(courseUnitInPlan);
        });
    }

    handleOpenCourseUnitInfoModal(courseUnitId: OtmId) {
        this.openCourseUnitInfoModal.emit(courseUnitId);
    }

    substitutionOptionByLocalId(index: number, substitutionOption: CourseUnitSubstitutionWithLocalId) {
        return substitutionOption.localId;
    }

    getDisabledSubstitutions() {
        return this.substitutionOptions.filter(substitutionOption =>
            this.attainmentAlreadyAttached(substitutionOption) ||
            this.substituteCourseUnitAlreadyInPlan(substitutionOption));
    }

    get selectedSubstitutionsLocalId(): FormControl {
        return this.form.get('selectedSubstitutionsLocalId') as FormControl;
    }

    getAttainmentByCourseUnitGroupId(courseUnitGroupId: OtmId) {
        return this.validatablePlan.getCourseUnitAttainmentByGroupId(courseUnitGroupId);
    }

    private updateNoEquivalentCourseRadioButtonVisibility() {
        this.showNoEquivalentCourseRadioButton =
            !_.isEmpty(this.courseUnit.substitutions) &&
            this.isLoggedIn &&
            this.isEditable &&
            !this.versionChangeInProgress &&
            this.substituteCourseUnitsByGroupId &&
            this.courseUnit.universityOrgIds.includes(this.currentUniversityOrgId) &&
            this.substitutionOptions.length > this.getDisabledSubstitutions().length;
    }

    showRadioButtonFor(substitutionOption: CourseUnitSubstitutionWithLocalId): boolean {
        return this.isLoggedIn && this.isEditable && !this.versionChangeInProgress && !!this.substituteCourseUnitsByGroupId && !this.attainmentAlreadyAttached(substitutionOption) && !this.substituteCourseUnitAlreadyInPlan(substitutionOption);
    }
}
