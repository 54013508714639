import { ApplicationFilesComponent } from 'sis-components/file-upload/application-files.component.ts';
import { priorLearningApplicationSummaryModule } from 'sis-components/applications/priorLearningApplicationSummary.component';
import { WorkflowEntityService } from 'sis-components/service/workflow-entity.service.ts';
import { AttainmentEntityService } from 'sis-components/service/attainment-entity.service.ts';
import { PriorLearningWorkflowSummaryComponent } from 'sis-components/applications/prior-learning-workflow/prior-learning-workflow-summary/prior-learning-workflow-summary.component.ts';
import { AttainmentWorkflowDecisionSummaryComponent } from 'sis-components/applications/attainment-workflow-decision-summary/attainment-workflow-decision-summary.component.ts';
import { map, mergeMap, take, tap } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { STUDENT_WORKFLOW_STATE, STUDENT_WORKFLOW_TYPE } from 'sis-components/model/student-workflow-constants.ts';
import { localizedMarkupStringFilterModule } from 'sis-components/string/localizedMarkupString.filter';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler.ts';
import { WorkflowDataChangeService } from 'sis-components/service/workflow-data-change.service.ts';
import { LocalizedMarkupStringViewComponent } from 'sis-components/localized-markup-string-view/localized-markup-string-view.component.ts';
import { PriorLearningWorkflowContentComponent } from './prior-learning-workflow-content/prior-learning-workflow-content.component.ts';
import priorLearningApplicationTpl from './priorLearningApplication.tpl.html';
'use strict';
(function () {
  priorLearningApplicationController.$inject = ["$scope", "$timeout", "$translate", "$state", "alertsService", "commonStudentApplicationService", "applicationDecisionJSDataModel", "courseUnitInfoModal", "cancelApplicationModalService", "defaultPromiseHandler", "workflowEntityService", "workflowDataChangeService", "attainmentEntityService", "appErrorHandler"];
  angular.module('student.profile.applications.priorLearningApplication', ['pascalprecht.translate', 'student.common.components', 'student.common.components.courseUnitInfoModal', 'sis-components.applications.attainmentApplicationDecisionSummary', 'sis-components.model.applicationDecision', 'sis-components.model.studentApplication', 'sis-components.service.studentApplicationService', 'student.profile.applications.cancelApplicationModal', 'sis-components.applications.studentApplicationPrint', priorLearningApplicationSummaryModule, ApplicationFilesComponent.downgrade.moduleName, WorkflowEntityService.downgrade.moduleName, WorkflowDataChangeService.downgrade.moduleName, AttainmentEntityService.downgrade.moduleName, PriorLearningWorkflowSummaryComponent.downgrade.moduleName, PriorLearningWorkflowContentComponent.downgrade.moduleName, AttainmentWorkflowDecisionSummaryComponent.downgrade.moduleName, AppErrorHandler.downgrade.moduleName, localizedMarkupStringFilterModule, LocalizedMarkupStringViewComponent.downgrade.moduleName]).component('priorLearningApplication', {
    template: priorLearningApplicationTpl,
    bindings: {
      applicationId: '<',
      showCancellationMessage: '<'
    },
    controller: priorLearningApplicationController
  });
  function priorLearningApplicationController($scope, $timeout, $translate, $state,
  // NOSONAR
  alertsService, commonStudentApplicationService, applicationDecisionJSDataModel, courseUnitInfoModal, cancelApplicationModalService, defaultPromiseHandler, workflowEntityService, workflowDataChangeService, attainmentEntityService, appErrorHandler) {
    const ctrl = this;
    ctrl.ready = false;
    ctrl.$onInit = function () {
      const attainmentObs = ctrl.createActiveAttainmentObs(ctrl.applicationId);
      const workflowObs = ctrl.createWorkflowObs(ctrl.applicationId);
      combineLatest([workflowObs, attainmentObs]).pipe(mergeMap(() => ctrl.getActiveSupplementRequestDescription()), appErrorHandler.defaultErrorHandler(), take(1)).subscribe(() => {
        $scope.$evalAsync();
      });
      if (ctrl.showCancellationMessage) {
        $timeout(() => {
          alertsService.success($translate.instant('PROFILE.APPLICATIONS.APPLICATION_CANCELLED_ALERT'), 6000);
        });
      }
    };
    ctrl.shouldShowAttachmentDeliveryPossible = function () {
      return ctrl.ready && _.includes([STUDENT_WORKFLOW_STATE.REQUESTED], ctrl.application.state);
    };
    ctrl.shouldShowDecision = function () {
      return ctrl.ready && _.includes([STUDENT_WORKFLOW_STATE.ACCEPTED, STUDENT_WORKFLOW_STATE.REJECTED, STUDENT_WORKFLOW_STATE.ACCEPTANCE_REVOKED], ctrl.application.state) && _.isObject(ctrl.application.decision);
    };
    ctrl.shouldShowCancelButton = function () {
      return ctrl.ready && _.includes([STUDENT_WORKFLOW_STATE.REQUESTED, STUDENT_WORKFLOW_STATE.IN_HANDLING, STUDENT_WORKFLOW_STATE.SUPPLEMENT_REQUESTED], ctrl.application.state);
    };
    ctrl.shouldShowInclusionSupplementButton = function () {
      return ctrl.ready && _.includes([STUDENT_WORKFLOW_STATE.SUPPLEMENT_REQUESTED], ctrl.application.state) && ctrl.application.type === STUDENT_WORKFLOW_TYPE.PRIOR_LEARNING_INCLUSION_WORKFLOW;
    };
    ctrl.shouldShowSubstitutionSupplementButton = function () {
      return ctrl.ready && _.includes([STUDENT_WORKFLOW_STATE.SUPPLEMENT_REQUESTED], ctrl.application.state) && ctrl.application.type === STUDENT_WORKFLOW_TYPE.PRIOR_LEARNING_SUBSTITUTION_WORKFLOW;
    };
    ctrl.isCancelled = function () {
      return ctrl.ready && _.includes([STUDENT_WORKFLOW_STATE.CANCELLED], ctrl.application.state);
    };
    ctrl.showCourseUnitInfo = function () {
      if (ctrl.application.courseUnitId) {
        courseUnitInfoModal.showCourseUnit(ctrl.application.courseUnitId);
      }
    };
    ctrl.openCancelApplicationModal = function () {
      cancelApplicationModalService.open(ctrl.application).then(() => {
        // Refresh the breadcrumb and show the cancellation message
        $state.go($state.current, {
          showCancellationMessage: true
        }, {
          reload: true
        });
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
    ctrl.createWorkflowObs = function (applicationId) {
      return workflowEntityService.getById(applicationId).pipe(take(1), tap(application => {
        ctrl.application = application;
        ctrl.ready = true;
        ctrl.inclusionApplicationInstruction = _.get(application, 'degreeProgramme.inclusionApplicationInstruction', _.get(application, 'courseUnit.inclusionApplicationInstruction'));
        ctrl.applicationCollapsed = ctrl.shouldShowDecision();
        ctrl.decisionCollapsed = !ctrl.applicationCollapsed;
      }));
    };
    ctrl.createActiveAttainmentObs = function (applicationId) {
      return attainmentEntityService.getByStudentApplicationId(applicationId).pipe(take(1), map(attainments => attainments.find(ctrl.isActive)), tap(attainment => {
        ctrl.activeAttainment = attainment;
      }));
    };
    ctrl.isActive = function isActive(attainment) {
      return !attainment.misregistration && !!attainment.primary;
    };
    ctrl.getActiveSupplementRequestDescription = function () {
      if (ctrl.shouldShowSupplementRequestRationale()) {
        return workflowDataChangeService.getActiveSupplementRequestDescription(ctrl.applicationId).pipe(appErrorHandler.defaultErrorHandler(), take(1), tap(response => {
          ctrl.rationale = response;
        }));
      }
      return of({});
    };
    ctrl.shouldShowSupplementRequestRationale = function () {
      return ctrl.ready && _.includes([STUDENT_WORKFLOW_STATE.SUPPLEMENT_REQUESTED], ctrl.application.state);
    };
  }
})();