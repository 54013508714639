'use strict';

(function () {
  angular.module('sis-common.dropdown', []).directive('dropdownForceOnScreen', ["$timeout", function ($timeout) {
    return {
      element: 'A',
      link: function (scope, element, attrs) {
        let dropdownMenu = element.find('.dropdown-menu');
        scope.$watchGroup([attrs.isOpen, attrs.scrolledDown], function (newValues) {
          let visible = newValues[0];
          let appendedToBody = typeof attrs.dropdownAppendToBody !== undefined && attrs.dropdownAppendToBody === 'true';
          if (visible) {
            // timeouts because position is not set until the menu is rendered (even if opacity 0)
            $timeout(function () {
              let dropdownMenuBoundingClientRect = dropdownMenu[0].getBoundingClientRect(); // location and dimensions of the dropdown menu
              let viewportWidth = Number(document.documentElement.clientWidth); // width of the user's visible viewport
              let dropdownToggle = element.find('.dropdown-toggle'); // the button etc. we clicked, for positioning the menu
              let dropdownToggleBoundingClientRect = dropdownToggle[0].getBoundingClientRect(); // location and dimensions of the button clicked
              // case 1: dropdownMenu goes out of the viewport on the left - coordinate x is less than zero
              if (typeof dropdownMenuBoundingClientRect !== undefined && dropdownMenuBoundingClientRect.x < 0) {
                // remove the builtin class dropdown-menu-right
                dropdownMenu[0].classList.remove('dropdown-menu-right');
                // take new location for the dropdown menu
                // and move it to align dropdown left edge with viewport left edge
                // (the distance between toggle and viewport left edge)
                if (appendedToBody) {
                  dropdownMenu[0].style.left = 0;
                  dropdownMenu[0].style.right = null;
                } else {
                  dropdownMenu[0].style.left = '-' + dropdownToggleBoundingClientRect.x + 'px';
                }
                // case 2: dropdownMenu goes out of the viewport on the right and only the right (need to see the beginning of the options' text takes precedence from seeing the end)
              } else if (typeof (dropdownMenuBoundingClientRect !== undefined) && dropdownMenuBoundingClientRect.x > 0 && dropdownMenuBoundingClientRect.right > viewportWidth) {
                if (dropdownMenuBoundingClientRect.width > viewportWidth) {
                  // the dropdownMenu will not completely fit in this viewport in any position, se we align its left edge with the viewport's left edge
                  if (appendedToBody) {
                    dropdownMenu[0].style.left = 0;
                    dropdownMenu[0].style.right = null;
                  } else {
                    dropdownMenu[0].style.left = '-' + dropdownToggleBoundingClientRect.x + 'px';
                  }
                } else {
                  // the dropdownMenu will fit in the viewport with the builtin dropdown-menu-right. remove our forced 'left' position, if any
                  dropdownMenu[0].style.left = null;
                  dropdownMenu[0].classList.add('dropdown-menu-right');
                }
              }
            });
            // 5 msec delay in actually making the menu visible to the eye, so it has time to position itself.
            // the opacity only comes to play when opened/closed, not when positioning after collapse
            // because the menu stays open during the transition
            $timeout(function () {
              dropdownMenu[0].style.opacity = '1';
            }, 5);
          } else {
            // dropdown was changed from open to closed, so set its opacity to 0
            dropdownMenu[0].style.opacity = '0';
          }
        }, true);
      }
    };
  }]);
})();