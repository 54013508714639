import { CommonCodeService } from '../service/common-code.service.ts';
import '../model/codebook.model';
import codeMultiSelectEditorTpl from './codeMultiSelectEditor.tpl.html';
import { controlsFactoryModule } from './controls.service';
import { CodeSelectionEditorComponent } from './code-selection-editor/code-selection-editor.component.ts';
export const codeMultiSelectEditorModule = 'sis-components.select.codeMultiSelectEditor';
(function () {
  codeSelectorController.$inject = ["$q", "$translate", "codebookJSDataModel", "commonCodeService", "controlsFactory", "TranslationKey"];
  angular.module(codeMultiSelectEditorModule, ['pascalprecht.translate', 'js-data', 'sis-common.l10n.localizedStringFilter', 'sis-components.model.codebook', CommonCodeService.downgrade.moduleName, CodeSelectionEditorComponent.downgrade.moduleName, controlsFactoryModule]).component('codeMultiSelectEditor', {
    require: {
      form: '?^^form'
    },
    bindings: {
      name: '@',
      codebookUrn: '<',
      maxCount: '@',
      minCount: '@',
      required: '@',
      selectedUrns: '<',
      onSelectCode: '&'
    },
    controller: codeSelectorController,
    template: codeMultiSelectEditorTpl
  }).constant('TranslationKey', {
    MY_UNIVERSITY: 'SIS_COMPONENTS.OBJECT.REFERRED_OBJECT_SELECT_EDITOR.MY_UNIVERSITY',
    OTHER_UNIVERSITIES: 'SIS_COMPONENTS.OBJECT.REFERRED_OBJECT_SELECT_EDITOR.OTHER_UNIVERSITIES',
    TOO_MANY_RESULTS: 'SIS_COMPONENTS.OBJECT.REFERRED_OBJECT_SELECT_EDITOR.TOO_MANY_SEARCH_RESULTS'
  });
  function codeSelectorController($q, $translate, codebookJSDataModel, commonCodeService, controlsFactory, TranslationKey) {
    const ctrl = this;
    ctrl.$onInit = initController;
    ctrl.addSelect = addCodeSelectionEditor;
    ctrl.selectCode = selectCode;
    ctrl.setSelectedCodes = setSelectedCodes;
    ctrl.unselectCode = unselectCode;
    function addCodeSelectionEditor() {
      if (ctrl.addButton.enabled()) {
        addToSelectedCodes(null);
        ctrl.addButton.disable();
      }
    }
    function addToSelectedCodes(code) {
      setSelectedCodes(_.concat(getSelectedCodes(), code));
    }
    function filterSelectedCodes(codes, selectedUrns) {
      return _.filter(codes, code => _.indexOf(selectedUrns, code.urn) > -1);
    }
    function getCodes() {
      if (ctrl.otherCodes) {
        return ctrl.otherCodes.concat(ctrl.universityCodes);
      }
      return [];
    }
    function getSelectedCodes() {
      return ctrl.selectedCodes;
    }
    function hasNoNullsSelectedCodes() {
      return !_.includes(getSelectedCodes(), null);
    }
    function hasSelectedValue(selectedCodes) {
      return selectedCodes.length > 0;
    }
    function hasSelectedValueIfRequired() {
      return !isRequired() || hasValidSelection(getSelectedCodes());
    }
    function hasOnlySelectedValueNull(selectedCodes) {
      return selectedCodes.length === 1 && _.includes(selectedCodes, null);
    }
    function hasValidSelection(selectedCodes) {
      return hasSelectedValue(selectedCodes) && !hasOnlySelectedValueNull(selectedCodes);
    }
    function hasCodesThatNotSelected() {
      return _.difference(getCodes(), getSelectedCodes()).length > 0;
    }
    function initController() {
      const groupPromise = commonCodeService.getGroupedCodes(ctrl.codebookUrn);
      $q.resolve(groupPromise).then(groupedCodes => {
        initCtrlProperties(groupedCodes);
      });
    }
    function initCtrlProperties(groupedCodes) {
      ctrl.validators = {
        noNullValues: {
          errorName: makeErrorId('code_required'),
          isValid: hasNoNullsSelectedCodes
        },
        maxCount: {
          errorName: makeErrorId('too_many'),
          isValid: isValidMaxCount
        },
        minCount: {
          errorName: makeErrorId('too_few'),
          isValid: isValidMinCount
        },
        required: {
          errorName: makeErrorId('required'),
          isValid: hasSelectedValueIfRequired
        }
      };
      ctrl.filterTranslations = {
        myUniversity: $translate.instant(TranslationKey.MY_UNIVERSITY),
        otherUniversities: $translate.instant(TranslationKey.OTHER_UNIVERSITIES),
        tooManyResults: $translate.instant(TranslationKey.TOO_MANY_RESULTS)
      };
      ctrl.addButton = controlsFactory.buildButton();
      ctrl.otherCodes = _.chain(_.values(groupedCodes.otherCodes)).value();
      ctrl.universityCodes = _.chain(_.values(groupedCodes.universityCodes)).value();
      ctrl.hideOtherCodes = groupedCodes.hideOtherCodes;
      setSelectedCodes(filterSelectedCodes(getCodes(), ctrl.selectedUrns));
      ctrl.ready = true;
    }
    function isRequired() {
      return !_.isEmpty(ctrl.required);
    }
    function isValidMaxCount() {
      let valid = true;
      if (ctrl.maxCount && getSelectedCodes().length > ctrl.maxCount) {
        valid = false;
      }
      return valid;
    }
    function isValidMinCount() {
      let valid = true;
      if (ctrl.minCount && getSelectedCodes().length < ctrl.minCount) {
        valid = false;
      }
      return valid;
    }
    function makeErrorId(name) {
      return _.join([ctrl.name, name], '.');
    }
    function removeFromSelectedCodes(code) {
      if (code) {
        setSelectedCodes(_.filter(getSelectedCodes(), c => c.urn !== code.urn));
      } else {
        setSelectedCodes(_.without(getSelectedCodes(), code));
      }
    }
    function replaceInSelectedCodes(code, previous) {
      const selectedCodes = getSelectedCodes();
      selectedCodes[_.indexOf(selectedCodes, previous)] = code;
      setSelectedCodes(selectedCodes);
    }
    function selectCode(code, previous) {
      replaceInSelectedCodes(code, previous);
      if (hasCodesThatNotSelected()) {
        ctrl.addButton.enable();
      }
    }
    function setSelectedCodes(selectedCodes) {
      ctrl.selectedCodes = selectedCodes;
      if (validate()) {
        ctrl.onSelectCode({
          selectedUrns: _.map(getSelectedCodes(), getCodeUrn)
        });
      }
      function getCodeUrn(code) {
        return code.urn;
      }
    }
    function setValidity(validator) {
      const isValid = validator.isValid();
      if (ctrl.form) {
        ctrl.form.$setValidity(validator.errorName, isValid);
      }
      return isValid;
    }
    function unselectCode(code) {
      removeFromSelectedCodes(code);
      ctrl.addButton.enable();
      if (ctrl.form) {
        ctrl.form.$setDirty();
      }
    }
    function validate() {
      return _.every(_.map(ctrl.validators, setValidity));
    }
  }
})();