import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxFudisModule } from '@funidata/ngx-fudis';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { SisCommonModule } from 'sis-common/sis-common.module';
import { EnrolmentModule } from 'sis-components/enrolment/enrolment.module';
import { SisComponentsHybridModule } from 'sis-components/sis-components.module.hybrid';

import { CartPurchaseWizardModule } from './cart/cart-purchase-wizard.module';
import { OpenUniversityCommonModule } from './common/open-university-common.module';
import { OpenUniversityCurSelectionFinalStepComponent } from './open-university-enrolment-wizard/open-university-cur-selection-final-step/open-university-cur-selection-final-step.component';
import { OpenUniversityCurSelectionStepComponent } from './open-university-enrolment-wizard/open-university-cur-selection-step/open-university-cur-selection-step.component';
import { OpenUniversityEnrolmentWizardComponent } from './open-university-enrolment-wizard/open-university-enrolment-wizard.component';
import { OpenUniversityErrorDialogComponent } from './open-university-error-dialog/open-university-error-dialog.component';
import { OpenUniversityStudiesActivateErrorComponent } from './open-university-studies-activate-error/open-university-studies-activate-error.component';
import { OpenUniversityStudiesActivateWizardStep1Component } from './open-university-studies-activate-wizard/open-university-studies-activate-wizard-step1/open-university-studies-activate-wizard-step1.component';
import { OpenUniversityStudiesActivateWizardStep2Component } from './open-university-studies-activate-wizard/open-university-studies-activate-wizard-step2/open-university-studies-activate-wizard-step2.component';
import { OpenUniversityEnrolledItemInfoComponent } from './open-university-studies-activate-wizard/open-university-studies-activate-wizard-step3/open-university-enrolled-item-info/open-university-enrolled-item-info.component';
import { OpenUniversityStudiesActivateWizardStep3Component } from './open-university-studies-activate-wizard/open-university-studies-activate-wizard-step3/open-university-studies-activate-wizard-step3.component';
import { OpenUniversityStudiesActivateWizardComponent } from './open-university-studies-activate-wizard/open-university-studies-activate-wizard.component';
import { openUniversityStates } from './open-university.route-ng';
import { StudyOfferingModule } from './study-offering/study-offering.module';
import { SuomiFiErrorComponent } from './suomi-fi-error/suomi-fi-error.component';

@NgModule({
    declarations: [
        OpenUniversityEnrolmentWizardComponent,
        OpenUniversityCurSelectionStepComponent,
        OpenUniversityCurSelectionFinalStepComponent,
        OpenUniversityStudiesActivateErrorComponent,
        OpenUniversityStudiesActivateWizardComponent,
        OpenUniversityStudiesActivateWizardStep1Component,
        OpenUniversityStudiesActivateWizardStep2Component,
        OpenUniversityStudiesActivateWizardStep3Component,
        OpenUniversityEnrolledItemInfoComponent,
        SuomiFiErrorComponent,
        OpenUniversityErrorDialogComponent,
    ],
    imports: [
        CartPurchaseWizardModule,
        CommonModule,
        EnrolmentModule,
        ReactiveFormsModule,
        OpenUniversityCommonModule,
        SisCommonModule,
        SisComponentsHybridModule,
        StudyOfferingModule,
        TranslateModule.forChild(),
        UIRouterModule,
        UIRouterUpgradeModule.forChild({ states: openUniversityStates }),
        NgxFudisModule,
    ],
    exports: [
        StudyOfferingModule,
    ],
})
export class OpenUniversityModule {
}
