<sis-description-list [columnCount]="2">
  <dl>
    <ng-template #listItem>
      <dt>{{'STUDENT_APPLICATIONS.STUDENT' | translate }}
        <fudis-link *ngIf="studentLink" [externalLink]="studentLink" [title]="'STUDENT_APPLICATIONS.OPEN_STUDENT_INFO' | translate"></fudis-link>
      </dt>
      <dd>
        <ng-container *ngIf="workflow.studentId | privatePersonBasicInfo | async as student">
          {{student | personFullName }} ({{student.studentNumber}})
        </ng-container>
      </dd>
    </ng-template>
    <ng-template #listItem>
      <dt>{{'STUDENT_APPLICATIONS.STUDY_RIGHT' | translate}}</dt>
      <dd>{{workflow.studyRightId | studyRightName | async | localizedString }}</dd>
    </ng-template>
    <ng-container *ngIf="$any(workflow)?.courseUnitId | courseUnit | async as courseUnit; else noCourseUnit">
      <ng-template #listItem>
        <dt>{{'STUDENT_APPLICATIONS.SUBSTITUTED_COURSE_UNIT' | translate}}
          <fudis-link *ngIf="studyPlanLink" [externalLink]="studyPlanLink" [title]="'STUDENT_APPLICATIONS.OPEN_STUDENT_STUDY_PLAN' | translate"></fudis-link>
        </dt>
        <dd>
          <ng-container *ngIf="workflow.type !== studentApplicationType.PRIOR_LEARNING_SUBSTITUTION_WORKFLOW">
            <span>{{courseUnit.name | localizedString }} </span>
            <span>({{'STUDENT_APPLICATIONS.VERSION' | translate}} {{courseUnit.curriculumPeriodIds | curriculumPeriodName:true | async}}
              )</span>
          </ng-container>
          <ng-container *ngIf="workflow.type === studentApplicationType.PRIOR_LEARNING_SUBSTITUTION_WORKFLOW">
            <sis-button [link]="true" (clicked)="openCourseUnitInfoModal.emit()">
              <sis-icon [icon]="'brochure'"></sis-icon>
              <span>{{courseUnit.name | localizedString }} </span>
              <span>({{'STUDENT_APPLICATIONS.VERSION' | translate}} {{courseUnit.curriculumPeriodIds | curriculumPeriodName:true | async}}
                )</span>
            </sis-button>
          </ng-container>
        </dd>
      </ng-template>
      <ng-template #listItem>
        <dt>{{'STUDENT_APPLICATIONS.APPLICATION_ARRIVED' | translate}}</dt>
        <dd>{{workflow.creationTime | localDateFormat}}</dd>
      </ng-template>
    </ng-container>
    <ng-template #noCourseUnit>
      <ng-template #listItem>
        <dt>{{'STUDENT_APPLICATIONS.SUGGESTED_ATTAINMENT_NAME' | translate}}</dt>
        <dd>{{$any(workflow)?.name}}</dd>
      </ng-template>
    </ng-template>
    <ng-container *ngIf="workflow.type === studentApplicationType.PRIOR_LEARNING_INCLUSION_WORKFLOW">
      <ng-template #listItem>
        <dt>{{'STUDENT_APPLICATIONS.DEGREE_PROGRAMME' | translate}}</dt>
        <dd>{{($any(workflow)?.degreeProgrammeId | module | async)?.name | localizedString}}</dd>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="workflow.studentId | privatePersonBasicInfo | async as student">
      <ng-template #listItem>
        <dt>{{'STUDENT_APPLICATIONS.PREFERRED_LANGUAGE' | translate}}</dt>
        <dd>{{(student?.preferredLanguageUrn | codeLocalizedName | async) ?? '-'}}</dd>
      </ng-template>
      <ng-template #listItem>
        <dt>{{'STUDENT_APPLICATIONS.CONTACT_INFO.EMAIL' | translate}}</dt>
        <dd>{{student.primaryEmail}}</dd>
      </ng-template>
    </ng-container>
  </dl>
</sis-description-list>
<ng-container *ngIf="$any(workflow)?.customStudyDraft as customStudyDraft">
  <div class="row">
    <div class="col-12">
      <h3>{{'STUDENT_APPLICATIONS.CUSTOM_STUDY_DRAFT_THAT_APPLICATION_IS_BASED_ON' | translate}}</h3>
    </div>
  </div>
  <sis-description-list [columnCount]="2">
    <dl>
      <ng-template #listItem>
        <dt>{{'CUSTOM_STUDY_DRAFT.NAME' | translate}}</dt>
        <dd>{{customStudyDraft.name}}</dd>
      </ng-template>
      <ng-template #listItem>
        <dt>{{'CUSTOM_STUDY_DRAFT.CREDITS' | translate}}</dt>
        <dd>{{customStudyDraft.credits}} {{'CREDITS' | translate}}</dd>
      </ng-template>
      <ng-template #listItem>
        <dt>{{'CUSTOM_STUDY_DRAFT.LOCATION' | translate}}</dt>
        <dd>{{customStudyDraft.location}}</dd>
      </ng-template>
      <ng-template #listItem>
        <dt>{{'CUSTOM_STUDY_DRAFT.DESCRIPTION' | translate}}</dt>
        <dd>{{ customStudyDraft.description }}</dd>
      </ng-template>
    </dl>
  </sis-description-list>
</ng-container>
