import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { dateUtils } from 'common-typescript';
import { OtmId, StudyRight, StudyRightState, TermRegistration, TuitionFeeObligationPeriod } from 'common-typescript/types';
import _ from 'lodash';
import moment from 'moment';
import { combineLatest, from, Observable, of, Subject } from 'rxjs';
import { map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { COMMON_TERM_REGISTRATION_PERIOD_SERVICE } from '../../ajs-upgraded-modules';
import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { EducationEntityService } from '../../service/education-entity.service';
import { StudyRightEntityService } from '../../service/study-right-entity.service';
import { StudyRightTermRegistrationsEntityService } from '../../service/study-right-term-registrations-entity.service';
import { TermRegistrationRequirementsEntityService } from '../../service/term-registration-requirements-entity.service';
import { TuitionFeeObligationPeriodEntityService } from '../../service/tuition-fee-obligation-period.entity.service';
import { getStudyTermLocator } from '../../study-terms/study-year-utils';
import { convertAJSPromiseToNative } from '../../util/utils';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-study-right-status-badge',
    templateUrl: './study-right-status-badge.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudyRightStatusBadgeComponent implements OnInit, OnDestroy {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.studyRight.studyRightStatusBadge',
        directiveName: 'sisStudyRightStatusBadge',
    };

    @Input() studyRightId: OtmId;
    @Input() reload$?: Observable<void>;
    tuitionFeeObligationPeriods: TuitionFeeObligationPeriod[] = [];
    isRegistrationRequired = false;
    hasRegistrationPeriodEnded = false;
    educationType: string;
    currentTermRegistration: TermRegistration;
    destroyed$ = new Subject<void>();
    studyRight: StudyRight;
    currentState: StudyRightState;
    loaded$: Observable<boolean>;

    constructor(
        private studyRightEntityService: StudyRightEntityService,
        private educationEntityService: EducationEntityService,
        @Inject(COMMON_TERM_REGISTRATION_PERIOD_SERVICE) private commonTermRegistrationPeriodService: any,
        private termRegistrationRequirementsService: TermRegistrationRequirementsEntityService,
        private studyRightTermRegistrationsEntityService: StudyRightTermRegistrationsEntityService,
        private tuitionFeeObligationPeriodEntityService: TuitionFeeObligationPeriodEntityService,
        private appErrorHandler: AppErrorHandler,
    ) {
    }

    private getAndSetStudyRight(bypassCache?: boolean): Observable<StudyRight> {
        return this.studyRightEntityService.getById(this.studyRightId, bypassCache)
            .pipe(
                tap((studyRight) => {
                    this.studyRight = studyRight;
                }),
                this.appErrorHandler.defaultErrorHandler(),
            );
    }

    private setEducationTypeAndGetIsTermRegistrationRequired(studyRight: StudyRight): Observable<boolean> {
        return this.educationEntityService.getById(studyRight.educationId)
            .pipe(
                tap((education) => {
                    this.educationType = education.educationType;
                }),
                mergeMap(education => this.termRegistrationRequirementsService.isTermRegistrationRequired(education.educationType)),
                this.appErrorHandler.defaultErrorHandler(),
            );
    }

    ngOnInit(): void {
        this.fetchAndSetBaseData(false);
        if (this.reload$) {
            this.reload$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
                this.fetchAndSetBaseData(true);
            });
        }
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
    }

    fetchAndSetBaseData(bypassCache: boolean): void {
        this.loaded$ = combineLatest([
            this.getAndSetStudyRight(bypassCache),
            this.studyRightTermRegistrationsEntityService.getById(this.studyRightId, bypassCache),
        ]).pipe(
            switchMap(([studyRight, srtr]) => combineLatest([
                of(srtr),
                this.setEducationTypeAndGetIsTermRegistrationRequired(studyRight),
                from(convertAJSPromiseToNative(this.commonTermRegistrationPeriodService.hasRegistrationPeriodEndedForCurrentStudyTerm() as Promise<boolean>)),
                this.tuitionFeeObligationPeriodEntityService.findForStudyRight(this.studyRightId),
            ])))
            .pipe(
                tap(([
                    srtr,
                    isTermRegistrationRequired,
                    hasRegistrationPeriodEnded,
                    periods,
                ]) => {
                    const statePeriod = srtr.statePeriods.find(period => dateUtils.rangeContains(moment(), period));
                    this.currentState = statePeriod?.state;
                    this.isRegistrationRequired = isTermRegistrationRequired;
                    this.currentTermRegistration = _.find(srtr.termRegistrations, { studyTerm: getStudyTermLocator() });
                    this.hasRegistrationPeriodEnded = hasRegistrationPeriodEnded;
                    this.tuitionFeeObligationPeriods = periods;
                }),
                map(() => true),
                this.appErrorHandler.defaultErrorHandler(),
            );
    }

    /**
     * Returns a translation key that adds extra information to the study right state (e.g. the state of the current
     * term registration for active study rights, or information about a neglected term registration). Returns null
     * if there is no extra state information to display.
     */
    getStateSpecifier(): string | null {
        const state = this.currentState;
        if (this.hasStudyRightStarted()) {
            if ((state === 'ACTIVE' || state === 'ACTIVE_NONATTENDING') && this.currentTermRegistration) {
                return `PROFILE.REGISTRATION.${this.currentTermRegistration.termRegistrationType}`;
            }
            if ((state === 'ACTIVE' || state === 'PASSIVE') && this.isTermRegistrationMissing() && this.isRegistrationRequired &&
                !this.hasRegistrationPeriodEnded) {
                return 'PROFILE.REGISTRATION.MISSING';
            }
            if (state === 'PASSIVE' && !this.studyRightValidityDateExpired() && this.isTermRegistrationPeriodNeglected()) {
                return 'PROFILE.TERM_REGISTRATION_PERIOD_NEGLECTED';
            }
        }
        if (state === 'RESCINDED' && this.hasBeenTransferredOutUniversity()) {
            return 'PROFILE.TRANSFERRED_OUT_UNIVERSITY';
        }
        if (state === 'PASSIVE' && this.studyRightValidityDateExpired()) {
            return 'PROFILE.VALIDITY_DATE_EXPIRED';
        }

        return null;
    }

    hasBeenTransferredOutUniversity() {
        return !!this.studyRight.transferOutUniversityUrn &&
            !!this.studyRight.transferOutDate &&
            moment(this.studyRight.transferOutDate).isBefore(moment(), 'days');
    }

    studyRightValidityDateExpired() {
        return this.studyRightTermRegistrationsEntityService.studyRightValidityDateExpired(this.educationType, this.studyRight);
    }

    isTermRegistrationPeriodNeglected() {
        return this.isRegistrationRequired && this.hasRegistrationPeriodEnded &&
            (this.isTermRegistrationMissing() || this.currentTermRegistration.termRegistrationType === 'NEGLECTED');
    }

    getStatusBadgeColor() {
        return this.studyRightTermRegistrationsEntityService.getStatusBadgeColor(
            this.studyRight, this.currentTermRegistration, this.isRegistrationRequired, this.educationType, this.currentState,
        );
    }

    hasUpcomingTransferOutDate() {
        return !!this.studyRight.transferOutDate && moment().isBefore(this.studyRight.transferOutDate);
    }

    hasStudyRightStarted(): boolean {
        return dateUtils.isRangeStarted(moment(), this.studyRight?.valid);
    }

    private isTermRegistrationMissing(): boolean {
        return (this.currentTermRegistration?.termRegistrationType ?? 'MISSING') === 'MISSING';
    }
}
