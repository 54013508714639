import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { StateService } from '@uirouter/core';
import { CourseUnit, OtmId } from 'common-typescript/types';
import _ from 'lodash';
import { Observable, take } from 'rxjs';
import { AUTH_SERVICE } from 'sis-common/ajs-upgraded-modules';
import { ComponentDowngradeMappings } from 'sis-common/types/angular-hybrid';
import { CourseUnitDisplayNamesById, CourseUnitInfoService, CourseUnitInfoVersion,
} from 'sis-components/courseUnitInfo/course-unit-info.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { SelectOption } from 'sis-components/select/select-combobox/select-combobox.component';

import { CourseCartEntityService } from '../../../service/course-cart-entity.service';

@Component({
    selector: 'app-course-unit-info-header',
    templateUrl: './course-unit-info-header.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CourseUnitInfoHeaderComponent implements OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.downgraded.courseUnitInfoHeader.sisCourseUnitInfoHeader',
        directiveName: 'appCourseUnitInfoHeader',
    };

    constructor(private courseUnitInfoService: CourseUnitInfoService,
                private appErrorHandler: AppErrorHandler,
                private stateService: StateService,
                private courseCartEntityService: CourseCartEntityService,
                @Inject(AUTH_SERVICE) private authService: any) { }

    @Input() courseUnit: CourseUnit;
    @Output() versionSelected = new EventEmitter<CourseUnit>();

    courseUnitVersionIds: OtmId[];
    options: SelectOption[] = [];
    inCart: boolean;
    isLoggedIn = false;

    ngOnInit() {
        this.isLoggedIn = this.authService.loggedIn();
        this.getCourseCartStatus();
        this.getSelectableVersionsAndNames()
            .pipe(this.appErrorHandler.defaultErrorHandler())
            .subscribe({
                next: ([courseUnitVersions, displayNamesByCourseUnitId]) => {
                    this.courseUnitVersionIds = _.map(courseUnitVersions, 'id');
                    this.options = this.courseUnitInfoService.createSelectOptions(courseUnitVersions, displayNamesByCourseUnitId);
                },
            });

    }

    goToCourseUnitVersion(courseUnitId: OtmId): void {
        this.stateService.go('.', { courseUnitId });
    }

    addCourseUnitToCourseCart() {
        this.courseCartEntityService.addCurToCourseCart(this.courseUnit.id)
            .pipe(take(1), this.appErrorHandler.defaultErrorHandler())
            .subscribe(() => this.inCart = true);
    }

    removeCourseUnitFromCourseCart() {
        this.courseCartEntityService.deleteCurFromCourseCart(this.courseUnit.id)
            .pipe(take(1), this.appErrorHandler.defaultErrorHandler())
            .subscribe(() => this.inCart = false);
    }

    getCourseCartStatus() {
        if (this.isLoggedIn) {
            this.courseCartEntityService.getCourseCart()
                .pipe(take(1), this.appErrorHandler.defaultErrorHandler())
                .subscribe((ids) => this.inCart = _.includes(ids, this.courseUnit.id));
        }
    }

    getSelectableVersionsAndNames(): Observable<[CourseUnitInfoVersion[], CourseUnitDisplayNamesById]> {
        return this.courseUnitInfoService.getSelectableVersionsAndNamesByCu(this.courseUnit);
    }
}
