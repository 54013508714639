import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { PriorLearning } from 'common-typescript/types';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { PRIOR_LEARNING_TYPE } from '../../model/student-application-constants';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-prior-learning-list',
    templateUrl: './prior-learning-list.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriorLearningListComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sisComponents.applications.priorLearningList',
        directiveName: 'sisPriorLearningList',
    };

    readonly PRIOR_LEARNING_TYPE = PRIOR_LEARNING_TYPE;

    /** Determines the naming of the prior learning entries (depending on the type of the containing application). */
    @Input() applicationType: string;
    @Input() priorLearnings: PriorLearning[];
    @Input() showAsExpandable: boolean;
    /**
     * Determines heading's semantic aria-level for screen readers, default is equivalent for h3
     */
    @Input() level?: number = 3;
}
