<sis-description-list [columnCount]="2">
  <dl>
    <ng-template #listItem>
      <dt>{{'STUDENT_APPLICATIONS.PRIOR_LEARNING.COMPETENCE_ORGANISATION' | translate}}</dt>
      <dd>{{this.priorCompetence.organisation}}</dd>
    </ng-template>
    <ng-template #listItem>
      <dt>{{'STUDENT_APPLICATIONS.PRIOR_LEARNING.COMPETENCE_ATTAINMENT_PERIOD' | translate}}</dt>
      <dd>{{this.priorCompetence.attainmentPeriod | localDateRange}}</dd>
    </ng-template>
    <ng-template #listItem>
      <dt>{{'STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_ATTAINMENT_LANGUAGE' | translate}}</dt>
      <dd>{{this.priorCompetence.attainmentLanguage | codeLocalizedName | async}}</dd>
    </ng-template>
    <ng-template #listItem>
      <dt>{{'STUDENT_APPLICATIONS.PRIOR_LEARNING.COMPETENCE_DESCRIPTION' | translate}}</dt>
      <dd>{{this.priorCompetence.description}}</dd>
    </ng-template>
  </dl>
</sis-description-list>

