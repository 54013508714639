import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MaxLength } from 'common-typescript/constants';
import { CustomStudyDraft, OtmId } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';
import { UuidService } from 'sis-common/uuid/uuid.service';
import { AlertsService } from 'sis-components/alerts/alerts-ng.service';
import { isNumber, max, maxLength, min, required } from 'sis-components/form/form-validators';
import { getLabelState } from 'sis-components/form/formUtils';
import { SisFormBuilder } from 'sis-components/form/sis-form-builder.service';

export interface CreateCustomStudyDraftModalValues {
    selectedModuleId: OtmId;
}

@Component({
    selector: 'app-create-new-custom-study-draft-modal',
    templateUrl: './create-custom-study-draft-modal.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateCustomStudyDraftModalComponent implements OnInit {

    customStudyDraftForm: FormGroup<{
        name: FormControl<string>;
        credits: FormControl<number>;
        location: FormControl<string>;
        description: FormControl<string>;
    }>;

    studyDraft: CustomStudyDraft;
    readonly getLabelState = getLabelState;
    popoverShown: boolean;

    constructor(private fb: SisFormBuilder,
                public modal: NgbActiveModal,
                private uuidService: UuidService,
                private alertsService: AlertsService,
                @Inject(ModalService.injectionToken) public values: CreateCustomStudyDraftModalValues) {
    }

    ngOnInit(): void {
        this.customStudyDraftForm = this.buildForm();
    }

    buildForm(): FormGroup {
        return this.fb.group({
            name: this.fb.control(null, [required(), maxLength(MaxLength.MAX_SHORT_STRING_LENGTH)]),
            credits: [null, [required(), isNumber(), min(0), max(999)]],
            location: this.fb.control(null, [required(), maxLength(MaxLength.MAX_TERSE_STRING_LENGTH)]),
            description: this.fb.control(null, [required(), maxLength(MaxLength.MAX_LONG_STRING_LENGTH)],
            ),
        });
    }

    cancel() {
        this.modal.dismiss();
    }

    addStudyDraft() {
        if (!this.customStudyDraftForm.valid) {
            this.alertsService.addFormSubmissionFailureAlert();
            this.customStudyDraftForm.markAllAsTouched();
            return;
        }
        this.studyDraft = {
            id: this.uuidService.randomUUID(),
            name: this.name.value,
            credits: this.credits.value,
            location: this.location.value,
            description: this.description.value,
            plannedPeriods: [],
            parentModuleId: this.values.selectedModuleId,
        };
        this.modal.close({ operation: 'ADD', customStudyDraft: this.studyDraft });

    }

    get name(): FormControl<string> {
        return this.customStudyDraftForm.controls.name;
    }

    get credits(): FormControl<number> {
        return this.customStudyDraftForm.controls.credits;
    }

    get location(): FormControl<string> {
        return this.customStudyDraftForm.controls.location;
    }

    get description(): FormControl<string> {
        return this.customStudyDraftForm.controls.description;
    }
}

